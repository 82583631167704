<div>
  <app-header callingfrom="chat"></app-header>
  <div class="sidebar-inner">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 col-xs-12 col-sm-12 col-md-12">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>
</div>
