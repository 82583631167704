<div class="d-flex flex-column align-items-center justify-content-center h-100">
  <div *ngIf="banner">
    <img [src]="banner" alt="banner">
  </div>
  <div *ngIf="paragraph" class="mt-3">
    <p class="paragraph text-center">
      {{paragraph}}
    </p>
  </div>
  <div *ngIf="btnText" class="mt-5">
    <button class="btn-design medium" [ngClass]="{'outline': isSecondary, 'filled': !isSecondary}" type="button"
      (click)="onBtn()">
      {{btnText}}
    </button>
  </div>
</div>
