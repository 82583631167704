  <div class="tutorial-popover" *ngIf="isTagUserTutorialEnabled && (callingFrom !== doctalkDetail && 
  callingFrom !== webinarDetail)">
    <div class="tutorial-popover-dim">    
        <div class="bg-dark-gray">          
            <div>
              <div class="bg-danger new-tag">
                 <span><img src="assets/images/icons/gift.svg" alt="gift"> 
                  <span>&nbsp;{{mls?.languageText?.tutorial?.key10}}!</span></span>
              </div>
              <div class="float-right cursor-pointer" (click)="hideModal()">
                  <img class="cross-link" src="assets/images/icons/cross-white.svg" alt="cross">
              </div>           
            </div>         
            <p class="tutorial-popover-header-text">
              {{tutorialTitle}}
            </p>
          <div class="tutorial-popover-body position-relative">          
            <p class="tutorial-popover-text">
              {{tutorialMsg}}.
            </p>
          </div>        
          <div class="tutorial-popover-triangle">
          </div>
      </div>
    </div> 
  </div>