// TODO: Remove Tslint disabled check.
import { Component, OnInit, Input, Renderer2, ElementRef, ViewChild, HostListener, OnDestroy, Inject, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { LocalStream } from '../models/localStream';
import { RemoteStream } from '../models/remoteStream';
import { Subscription, interval } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { AudioVideoStatusCache } from '../models/agoraClient';

/**
 * Constant imports.
 */
import { SCREEN_SHARING, SHOW_NEW_TAG, STREAMING_TYPE } from '../../constants/app.constants';

/**
 * Service import.
 */
import { DataStorageService } from './../../dataservices/data-storage.service';
import { PollSocketService } from '../../dataservices/poll-socket.service';

/**
 * //TODO Required AgoraRTC refactoring.
 * //TODO implement onDestroy and unsubscribe all events
 *
 * For now, We are using AgoraRTC directly here, to see its capabilities.
 * After its exploration we will move this in service or create its model.
 */

@Component({
  selector: 'app-lib-channel',
  templateUrl: './channel.component.html',
  styleUrls: ['./channel.component.scss'],

})
export class ChannelComponent implements OnInit, OnChanges, OnDestroy {
  /**
   * Agora AppId.
   */
  @Input() appId: string;

  /**
   * Name of the channel, that we want to create.
   */
  @Input() channel: string;

  /**
   * Role of the user, who is trying to connect the channel.
   */
  @Input() role: 'audience' | 'host';

  /**
   * UserId
   */
  @Input() uid?: string;

  /**
   * Agora security token
   */
  @Input() token?: string;

  /**
   * Get Agora rtm token.
   */
  @Input() rtmToken: string;

  /**
   * Get Agora rmt uid.
   */
  @Input() rtmUid: string;

  /**
   * Used to hold the logo.
   */
  @Input() logs?: string[];

  /**
   * Agora security token
   */
  @Input() thumbnailImage?: string;

  /**
   * Used to hold the live user count.
   */
  @Input() liveUserCount?: string;

  /**
   * Used to hold the device compatibility message.
   */
  @Input() deviceCompatibleMsg?: string;
  /**
   * used to hold the title.
   */
  @Input() title: string;

  /**
   * Unread live comment count.
   */
  @Input() unreadLiveCommentCount = 0;

  /**
   * Unread live comment count.
   */
  @Input() streamingType = '';

  /**
   * check for webinar status
   */
  @Output() checkWebinarStatus = new EventEmitter();

  @Output() fullscreenStatus = new EventEmitter();

  @Output() controlsTrigger = new EventEmitter();
  @Output() joinedStatus = new EventEmitter();
  @Output() sponsorLogoAnalytic = new EventEmitter();
  @Input() logo;
  @Input() sponsorsColorCode;
  @Input() ticker;
  /**
   * Open Live Poll Popup Event Emitter
   */
  @Output() openLivePollPopup = new EventEmitter();

  /**
   * Used to store error code.
   */
  public errorCode?: number;
  @Output() receiveSQSInterval = new EventEmitter();

  /**
   * Used to store error message.
   */
  public errorMsg?: string;

  /**
   * used for fullscreen flag.
   */
  public isfullscreen = false;

  /**
   * Channel container.
   */
  @ViewChild('channelContainer') channelContainer: ElementRef;

  /**
   * Fullscreen disabled flag.
   */
  public fullscreenDisabled = false;
  /**
   * Used for hiding the video controls bar
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public timeoutId: any;
  public duration = 2000;

  /**
   * Used to hold the name of speaker while speaking.
   */
  public activeSpeakerName: string;

  /**
   * Flag for screen share.
   */
  public screenShareEnable = false;

  /**
   * Contains details of all the streams
   */
  public remoteStreams: { [name: number]: RemoteStream } = {};

  /**
   * This flags is basically for user interaction, I tells that user want to
   * join this broadcast. This is helpful for browser's auto play policy, which
   * stops audio, specially in case of safari.
   */
  public channelJoined = false;

  /**
   * This property is used to set the volume of all stream. User can increase
   * or decrease this as per requirement.
   */
  public volumeLevel = 50;

  /**
   * Live used count.
   */
  public userCount = 0;

  /**
   * Subscription for video play
   */
  videoSubs: Subscription;

  /**
   * Temporary variable for support of Subscription for video play
   */
  tempvideoSubs: Subscription;

  /**
   * Local stream object, which is specific for this user and contains the RTM
   * and RTC clients.
   */
  private localStream: LocalStream;
  /**
   * Unsubscribe the subscribe events
   */

  @Input() controls?;
  private getUserSub: Subscription;
  private streamSubscribedSub: Subscription;
  private peerLeaveSub: Subscription;
  private muteAudioSub: Subscription;
  private unmuteAudioSub: Subscription;
  private muteVideoSub: Subscription;
  private unmuteVideoSub: Subscription;
  private volumeIndicatorSub: Subscription;
  private errorSub: Subscription;
  private statusCache: { [name: number]: AudioVideoStatusCache } = {};
  private screenPresenterUid: number;
  /**
   * Used to set time interval in which send data in SQS
   */
  public SQSTimeInterval = 60000;

  /**
   * Video play time.
   */
  public timeSpentCount = 0;

  /**
   * Used to hold the video play time temporary.
   */
  public tempTimeSpentCount = 0;
  public showNewTagConstant = SHOW_NEW_TAG;

  /**
   * Mini player enable/disable flag.
   * PIP is enable/disable flag.
   * Show the new tag on controls.
   * Receive the autoPlay flag for the webinar.
   * Emit mini player request.
   */
  @Input() isMiniPlayerVisible: boolean;
  @Input() isPipEnabled: boolean;
  @Input() showNewTag: string;
  @Input() autoPlayWebinar = false;
  @Output() emitMiniPlayerRequest: EventEmitter<void> = new EventEmitter();
  /**
   * Flag for live poll button.
   */
  @Input() showLivePollButton;
  @Input() webinarStatus;
  @Input() hasLivePoll;

  /**
   * Created all required instances.
   */
  constructor(
    public renderer2: Renderer2,
    private el: ElementRef,
    public dss: DataStorageService,
    public pollSocketSer: PollSocketService,
    @Inject(DOCUMENT) private document: Document) { }

  /**
   * AutoPlay the webinar in case of request pip tutorial.
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['autoPlayWebinar'] && changes['autoPlayWebinar'].currentValue) {
      this.joinChannel();
    }
  }

  /**
   * Initialize the stream.
   */
  ngOnInit(): void {
    this.fullscreenStatus.emit(this.fullscreenDisabled);
    this.localStream = new LocalStream(
      this.appId,
      {
        channel: this.channel,
        role: this.role,
        token: this.token,
        uid: this.uid
      },
      {
        uid: this.rtmUid,
        token: this.rtmToken,
        channel: this.channel
      }
    );

    this.getUserSub = this.localStream.getUserCount().subscribe((val) => {
      this.userCount = val;
    });

    this.localStream.loginToRTM().then(() => {
      this.bindEventListeners();

    }).catch(() => {
      // Handling Error
    });

    if (this.localStream.isCompatible === false) {
      this.errorCode = 102;
      this.errorMsg = this.deviceCompatibleMsg;
    } else {
      this.errorCode = 0;
      this.errorMsg = '';

    }
    this.checkBrowserCompatibility();
  }

  /**
   * Bind the events.
   */
  private bindEventListeners(): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.streamSubscribedSub = this.localStream.streamSubscribed.subscribe((evt: any) => {
      const id = evt.uid;
      if (this.streamingType === STREAMING_TYPE.OBS) {
        this.remoteStreams[id] = new RemoteStream(
          id,
          evt,
          false,
          Object.prototype.hasOwnProperty.call(this.statusCache, id) ?
            this.statusCache[id].audioMuted
            : false,
          Object.prototype.hasOwnProperty.call(this.statusCache, id) ?
            this.statusCache[id].videoMuted
            : false,
          '',
          id,
          true
        );
      } else {
        this.localStream.getUserAttributes(id.toString()).then((userInfo) => {
          if (userInfo.isPresenting === SCREEN_SHARING.OFF) {
            this.remoteStreams[id] = new RemoteStream(
              id,
              evt,
              false,
              Object.prototype.hasOwnProperty.call(this.statusCache, id) ?
                this.statusCache[id].audioMuted
                : false,
              Object.prototype.hasOwnProperty.call(this.statusCache, id) ?
                this.statusCache[id].videoMuted
                : false,
              userInfo.name,
              userInfo.customId,
              false
            );

            /**
             * Sometime screen share stream comes before the user stream, so
             * in that case we are setting the presenter later.
             */
            if (this.screenPresenterUid && userInfo.customId === this.screenPresenterUid) {
              this.remoteStreams[id].presenting = true;
            }
          } else {
            this.remoteStreams[id] = new RemoteStream(
              id,
              evt,
              true,
              Object.prototype.hasOwnProperty.call(this.statusCache, id) ?
                this.statusCache[id].audioMuted
                : true,
              Object.prototype.hasOwnProperty.call(this.statusCache, id) ?
                this.statusCache[id].videoMuted
                : true,
              userInfo.name,
              userInfo.customId,
              false
            );

            this.setPresenterFlag(userInfo.customId);
            this.shareScreen();
          }
        }, () => {
          console.error('Unable to get user info from RTM');
        });
      }
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.peerLeaveSub = this.localStream.peerLeave.subscribe((evt: any) => {
      const id = evt.uid;

      if (Object.prototype.hasOwnProperty.call(this.remoteStreams, id)) {
        if (this.remoteStreams[id].screenShare) {
          this.removePresenterFlag(this.remoteStreams[id].userId);
          this.disableShareScreen();
        }

        this.remoteStreams[id] = null;
        delete this.remoteStreams[id];
        setTimeout(() => {
          this.checkWebinarStatus.emit(0);
        }, 2000);
      }
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.muteAudioSub = this.localStream.muteAudio.subscribe((evt: any) => {
      if (!Object.prototype.hasOwnProperty.call(this.remoteStreams, evt.uid)) {
        this.cacheAudioStatus(evt.uid, true);
      } else {
        this.remoteStreams[evt.uid].audioMuted = true;
      }
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.unmuteAudioSub = this.localStream.unmuteAudio.subscribe((evt: any) => {
      if (!Object.prototype.hasOwnProperty.call(this.remoteStreams, evt.uid)) {
        this.cacheAudioStatus(evt.uid, false);
      } else {
        this.remoteStreams[evt.uid].audioMuted = false;
      }
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.muteVideoSub = this.localStream.muteVideo.subscribe((evt: any) => {
      if (!Object.prototype.hasOwnProperty.call(this.remoteStreams, evt.uid)) {
        this.cacheVideoStatus(evt.uid, true);
      } else {
        this.remoteStreams[evt.uid].videoMuted = true;
      }
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.unmuteVideoSub = this.localStream.unmuteVideo.subscribe((evt: any) => {
      if (!Object.prototype.hasOwnProperty.call(this.remoteStreams, evt.uid)) {
        this.cacheVideoStatus(evt.uid, false);
      } else {
        this.remoteStreams[evt.uid].videoMuted = false;
      }
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.volumeIndicatorSub = this.localStream.volumeIndicator.subscribe((evt: any) => {

      // tslint:disable-next-line: forin
      for (const item in this.remoteStreams) {
        this.remoteStreams[item].speaking = false;
      }
      for (const att of evt) {
        if (Object.prototype.hasOwnProperty.call(this.remoteStreams, att.uid)) {
          this.remoteStreams[att.uid].speaking = true;
        }
      }
    });

    this.errorSub = this.localStream.error.subscribe(evt => {
      this.errorCode = evt.code;
      this.errorMsg = evt.msg;
    });
  }

  /**
   * Receive and update the video status.
   */
  cacheVideoStatus(uid, val): void {
    if (Object.prototype.hasOwnProperty.call(this.statusCache, uid)) {
      this.statusCache[uid].videoMuted = val;
    } else {
      this.statusCache[uid] = {
        audioMuted: false,
        videoMuted: val
      };
    }
  }

  /**
   * Receive and update the audio status.
   */
  cacheAudioStatus(uid, val): void {
    if (Object.prototype.hasOwnProperty.call(this.statusCache, uid)) {
      this.statusCache[uid].audioMuted = val;
    } else {
      this.statusCache[uid] = {
        audioMuted: val,
        videoMuted: false
      };
    }
  }

  /**
   * Join the channel.
   */
  async joinChannel(): Promise<void> {
    this.dss.isVideoPlaying = true;
    const startedSqs = { timespent: 1, SQSTimeInterval: 0, seekTime: 0 };
    this.receiveSQSInterval.emit(startedSqs);
    await this.localStream.setRoleAndJoin();
    this.channelJoined = true;
    this.joinedStatus.emit(this.channelJoined);
    this.localStream.enableAudioVolumeIndicator();
    /**
     * Used to send time spent and seek time with interval
     */
    this.timeSpentCount = 0;
    const source = interval(this.SQSTimeInterval);
    this.videoSubs = source.subscribe(() => {
      this.timeSpentCount += (this.SQSTimeInterval / 1000);
      const data = { timespent: this.timeSpentCount, SQSTimeInterval: this.SQSTimeInterval, seekTime: this.timeSpentCount };
      this.receiveSQSInterval.emit(data);
      this.timeSpentCount = 0;
      this.tempTimeSpentCount = 0;
    });

    const tempSource = interval(1000);
    this.tempvideoSubs = tempSource.subscribe(() => {
      this.tempTimeSpentCount++;
    });
  }

  /**
   * Screen share flag true.
   */
  shareScreen(): void {
    this.screenShareEnable = true;
  }

  /**
   * Screen share flag false.
   */
  disableShareScreen(): void {
    this.screenShareEnable = false;
  }

  /**
   * Track the indexed stream.
   */
  trackMe(index, stream): void {
    return stream ? stream.key : undefined;
  }

  /**
   * Fullscreen.
   */
  fullscreenMode(): void {
    const fullscreen = this.channelContainer.nativeElement;
    if (this.fullscreenDisabled === false) {
      if (fullscreen.requestFullscreen) {
        fullscreen.requestFullscreen();
      } else if (fullscreen.mozRequestFullScreen) {
        /**
         * Firefox.
         */
        fullscreen.mozRequestFullScreen();
      } else if (fullscreen.webkitRequestFullscreen) {
        /**
         * Chrome, Safari & Opera.
         */
        fullscreen.webkitRequestFullscreen();
      } else if (fullscreen.msRequestFullscreen) {
        /**
         * IE/Edge.
         */
        fullscreen.msRequestFullscreen();
      }
      // this.showLivePollButton = true;
    } else {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document['mozCancelFullScreen']) {
        /**
         * Firefox.
         */
        this.document['mozCancelFullScreen']();
      } else if (this.document['webkitExitFullscreen']) {
        /**
         * Chrome, Safari & Opera.
         */
        this.document['webkitExitFullscreen']();
      } else if (this.document['msExitFullscreen']) {
        /**
         * IE/Edge.
         */
        this.document['msExitFullscreen']();
      }
      // this.showLivePollButton = false;
    }
  }

  /**
   * Listener for fullscreenchange on different browser.
   */
  @HostListener('document:fullscreenchange.out-zone', ['$event'])
  fullscreenchange(): void {
    this.fullscreenDisabled = !this.fullscreenDisabled;
    this.fullscreenStatus.emit(this.fullscreenDisabled);
  }
  @HostListener('document:mozfullscreenchange.out-zone', ['$event'])
  mozfullscreenchange(): void {
    this.fullscreenDisabled = !this.fullscreenDisabled;
    this.fullscreenStatus.emit(this.fullscreenDisabled);
    if (this.fullscreenDisabled === false) {
      this.controls.forEach(element => {
        element.display = true;
        this.eventsTrigger(element);
      });
    }

  }
  @HostListener('document:webkitfullscreenchange.out-zone', ['$event'])
  webkitfullscreenchange(): void {
    this.fullscreenDisabled = !this.fullscreenDisabled;
    this.fullscreenStatus.emit(this.fullscreenDisabled);
    if (this.fullscreenDisabled === false) {
      this.controls.forEach(element => {
        element.display = true;
        this.eventsTrigger(element);
      });
    }

  }
  @HostListener('document:msfullscreenchange', ['$event'])
  msfullscreenchange(): void {
    this.fullscreenDisabled = !this.fullscreenDisabled;
    this.fullscreenStatus.emit(this.fullscreenDisabled);
    if (this.fullscreenDisabled === false) {
      this.controls.forEach(element => {
        element.display = true;
        this.eventsTrigger(element);
      });
    }

  }

  /**
   * Mouse move.
   */
  onMouseMove(): void {
    const minimized = this.channelContainer.nativeElement;
    const videoControls = minimized.querySelector('#videoControls');
    const fullscreentitle = minimized.querySelector('#fullscreentitle');
    if (videoControls) {
      this.renderer2.setStyle(videoControls, 'display', 'block');
    }
    if (fullscreentitle && this.fullscreenDisabled === true) {
      this.renderer2.setStyle(fullscreentitle, 'display', 'block');

    }

    /**
     * Reset time.
     */
    clearTimeout(this.timeoutId);
    this.timeoutId = setTimeout(() => {
      if (videoControls) {
        this.renderer2.setStyle(videoControls, 'display', 'none');
      }
      if (fullscreentitle && this.fullscreenDisabled === true) {
        this.renderer2.setStyle(fullscreentitle, 'display', 'none');
      }
    }, this.duration);
  }

  /**
   * Unsubscribe the variables.
   */
  ngOnDestroy(): void {

    const data = { timespent: this.tempTimeSpentCount, SQSTimeInterval: this.SQSTimeInterval, seekTime: this.tempTimeSpentCount };
    this.receiveSQSInterval.emit(data);
    this.tempTimeSpentCount = 0;

    if (this.getUserSub) {
      this.getUserSub.unsubscribe();
    }
    if (this.streamSubscribedSub) {
      this.streamSubscribedSub.unsubscribe();
    }
    if (this.peerLeaveSub) {
      this.peerLeaveSub.unsubscribe();
    }
    if (this.muteAudioSub) {
      this.muteAudioSub.unsubscribe();
    }
    if (this.unmuteAudioSub) {
      this.unmuteAudioSub.unsubscribe();
    }
    if (this.muteVideoSub) {
      this.muteVideoSub.unsubscribe();
    }
    if (this.unmuteVideoSub) {
      this.unmuteVideoSub.unsubscribe();
    }
    if (this.volumeIndicatorSub) {
      this.volumeIndicatorSub.unsubscribe();
    }
    if (this.errorSub) {
      this.errorSub.unsubscribe();
    }
    if (this.localStream) {
      this.localStream.stopAgoraService();
    }

    if (this.videoSubs) {
      this.videoSubs.unsubscribe();
    }

    if (this.tempvideoSubs) {
      this.tempvideoSubs.unsubscribe();
    }

    this.dss.isVideoPlaying = false;
  }

  /**
   * set flag who is presenting.
   */
  setPresenterFlag(userId): void {
    this.screenPresenterUid = userId;
    for (const key in this.remoteStreams) {

      if (this.remoteStreams[key].userId === userId && !this.remoteStreams[key].screenShare) {
        this.remoteStreams[key].presenting = true;
        break;
      }
    }
  }

  /**
   * Remove presenter.
   */
  removePresenterFlag(userId): void {
    this.screenPresenterUid = undefined;
    for (const key in this.remoteStreams) {

      if (this.remoteStreams[key].userId === userId && !this.remoteStreams[key].screenShare) {
        this.remoteStreams[key].presenting = false;
        break;
      }
    }
  }

  /**
   * Reload page.
   */
  reloadPage(): void {
    window.location.reload();
  }

  /**
   * Add new stream.
   */
  addRemoteStream(id): void {
    this.remoteStreams[id] = new RemoteStream(id);
  }

  /**
   * Trigger events for controls.
   */
  eventsTrigger(data): void {
    data.display = !data.display;
    this.controlsTrigger.emit(data);
  }

  /**
   * Check the browser compatibility.
   */
  checkBrowserCompatibility(): void {
    const navUserAgent = navigator.userAgent;
    // tslint:disable-next-line: deprecation
    let browserName = navigator.appName;
    // tslint:disable-next-line: deprecation
    let browserVersion = '' + parseFloat(navigator.appVersion);
    // tslint:disable-next-line: deprecation
    // const majorVersion = parseInt(navigator.appVersion, 10);
    let tempNameOffset;
    let tempVersionOffset;
    let tempVersion;


    // tslint:disable-next-line: no-conditional-assignment
    // eslint-disable-next-line no-cond-assign
    if (tempVersionOffset = (navUserAgent.indexOf('Opera')) !== -1) {
      browserName = 'Opera';
      browserVersion = navUserAgent.substring(tempVersionOffset + 6);
      // tslint:disable-next-line: no-conditional-assignment
      if ((tempVersionOffset = navUserAgent.indexOf('Version')) !== -1) {
        browserVersion = navUserAgent.substring(tempVersionOffset + 8);
      }
      // tslint:disable-next-line: no-conditional-assignment
    } else if ((tempVersionOffset = navUserAgent.indexOf('MSIE')) !== -1) {
      browserName = 'Microsoft Internet Explorer';
      browserVersion = navUserAgent.substring(tempVersionOffset + 5);
      // tslint:disable-next-line: no-conditional-assignment
    } else if ((tempVersionOffset = navUserAgent.indexOf('Chrome')) !== -1) {
      browserName = 'Chrome';
      browserVersion = navUserAgent.substring(tempVersionOffset + 7);
      // tslint:disable-next-line: no-conditional-assignment
    } else if ((tempVersionOffset = navUserAgent.indexOf('Safari')) !== -1) {
      browserName = 'Safari';
      browserVersion = navUserAgent.substring(tempVersionOffset + 7);
      // tslint:disable-next-line: no-conditional-assignment
      if ((tempVersionOffset = navUserAgent.indexOf('Version')) !== -1) {
        browserVersion = navUserAgent.substring(tempVersionOffset + 8);
      }
      // tslint:disable-next-line: no-conditional-assignment
    } else if ((tempVersionOffset = navUserAgent.indexOf('Firefox')) !== -1) {
      browserName = 'Firefox';
      browserVersion = navUserAgent.substring(tempVersionOffset + 8);
      // tslint:disable-next-line: no-conditional-assignment
    } else if ((tempNameOffset = navUserAgent.lastIndexOf(' ') + 1)
      // tslint:disable-next-line: no-conditional-assignment
      < (tempVersionOffset = navUserAgent.lastIndexOf('/'))) {
      browserName = navUserAgent.substring(tempNameOffset, tempVersionOffset);
      browserVersion = navUserAgent.substring(tempVersionOffset + 1);
      if (browserName.toLowerCase() === browserName.toUpperCase()) {
        // tslint:disable-next-line: deprecation
        browserName = navigator.appName;
      }
    }

    // tslint:disable-next-line: no-conditional-assignment
    if ((tempVersion = browserVersion.indexOf(';')) !== -1) {
      browserVersion = browserVersion.substring(0, tempVersion);
    }
    // tslint:disable-next-line: no-conditional-assignment
    if ((tempVersion = browserVersion.indexOf(' ')) !== -1) {
      browserVersion = browserVersion.substring(0, tempVersion);
    }

    const temp = browserVersion.split('.');
    browserVersion = temp[0];
    if (browserName === 'Safari' && Number(browserVersion) < 13) {
      this.errorCode = 102;
      this.errorMsg = this.deviceCompatibleMsg;
    }
  }

  /**
   * set volume mute
   */
  setVolumeMute(): void {
    this.volumeLevel = 0;
  }

  /**
   * set volume unmute
   */
  setVolumeUnmute(): void {
    this.volumeLevel = 50;
  }

  /**
  * send clicked sponsor data on webinar detail page
  */
  sponsorLogoAction(data): void {
    this.sponsorLogoAnalytic.emit(data);
  }

  /**
   * Request for the mini player.
   */
  requestMiniPlayer(): void {
    this.emitMiniPlayerRequest.emit();
  }
  /**
   * Open Live Poll Popup
   */
  openLivePollPopupEvent(): void {
    this.openLivePollPopup.emit(true);
  }
}
