/**
 * Angular imports.
*/
import { Component, EventEmitter, Output, Input, OnInit, OnDestroy } from '@angular/core';
/**
 * Service imports.
*/
import { NewLanguageService } from './../../dataservices/new-language/new-language.service';
import { AnalyticsEventService } from '../../analytics-event/analytics-event.service';
import { PostApiService } from '../../dataservices/post-api.service';
import { DataStorageService } from '../../dataservices/data-storage.service';
/**
 * Constant imports.
 */
import { TUTORIAL_FOR } from './../../constants/app.constants';
/**
 * rxjs import
 */
import { Subscription } from 'rxjs';

/**
 * constant imports
 */
import { SCREEN_NAME } from './../../constants/app.constants';

@Component({
  selector: 'app-repost-popover',
  templateUrl: './repost-popover.component.html',
  styleUrls: ['./repost-popover.component.scss']
})
export class RepostPopoverComponent implements OnInit, OnDestroy {

  /**
   * Tutorial is for.
   */
  @Input() screenName:string;

  /**
   * Emit start request outside.
   * Emit hide request outside.
   */
  @Output() emitStartRequest: EventEmitter<void> = new EventEmitter();
  @Output() emitHideModal: EventEmitter<void> = new EventEmitter();

  /**
   * Flag for the tutorial constant.
   */
  public tutorialFor = TUTORIAL_FOR;

  /**
   * flag variabele for tag info card
   */
  public isTagUserTutorialEnabled = false;

  /**
   * screen name for doctalk detail and webinar detail
   */
  public webinarDetail = SCREEN_NAME.WEBINAR_DETAIL;
  /**
   * to unsubscribe data
   */
  private subscriptions: Subscription = new Subscription();
  /**
   * Necessary instances
   */
  constructor(
    public mls: NewLanguageService,
    private analyticsEventService: AnalyticsEventService,
    private postService: PostApiService,
    private dss: DataStorageService
  ) { }

  /**
   * hide Modal
   */
  hideModal(): void {
    this.emitHideModal.emit();
    this.isTagUserTutorialEnabled = false;
  }
  /**
   * Request for pip.
   */
  startTutorial(): void {
    this.emitStartRequest.emit();
  }
  /**
   * Get the current time in string.
   */
  getTimeString(): string {
    return new Date().getTime().toString();
  }

  ngOnInit(): void {
    this.isTagUserTutorialEnabled = true;
  }
  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }


}
