/**
 * ViewChild is for binding tags of html to be accessible for this file
 * Input is for receiving inputs from smart component
 */
import { Component, AfterViewInit, ElementRef, ViewChild, Input, Output, EventEmitter, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { Platform } from '@angular/cdk/platform';

/**
 * Constant imports.
 */
import { MILLISECOND_TIME, NUMBERS, PLAYER_STATUS, PRODUCT_TYPE_CONSTANT } from '../../constants/app.constants';

/**
  * Interface for UI configuration of shaka player
  */
import { Config } from '../shakaPlayer.interface';

/**
 * Rxjs imports.
 */
import { of } from 'rxjs';
import { delay, takeWhile, tap } from 'rxjs/operators';

/**
 * variable for shaka player
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let shaka: any;

/** Can be used as a dumb component by using app-shaka-audio selector
 * templateUrl is path to html template
 * styleUrls is path to css
 */
@Component({
  selector: 'app-shaka-audio',
  templateUrl: './audio.component.html',
  styleUrls: ['./audio.component.scss']
})
export class AudioComponent implements AfterViewInit, OnDestroy, OnChanges {

  /**
   * The view using the given selectors
   * audioElementRef contains a reference to the video element
   */
  @ViewChild('audioPlayer') public audioElementRef: ElementRef;

  /**
   * audioContainerRef includes a reference to the div that wraps the video element.
   */
  @ViewChild('audioContainer') public audioContainerRef: ElementRef;

  /**
   * emit SQS event outside the component
   */
  @Output() receiveSQSInterval = new EventEmitter();

  @Output() playerStatus: EventEmitter<string> = new EventEmitter();

  /**
   * Status of the player play, pause , ended.
   * Auto play flag.
   * Flag to enable aur disabled the cookies.
   */
  @Input() public audioPlayerStatus = PLAYER_STATUS.PLAY;
  @Input() autoplay = false;
  @Input() enabledCookies = false;

  /**
   * URL for video, receiving as input
   */
  @Input() public videoSrc: string;

  /**
   * recieve the css classes for shaka player
   */
  @Input() public shakaStyleCss = 'shakaAudioCss';

  /**
   *UI configuration for video, receiving as input
   */
  @Input() public config: Config = {
    fadeDelay: 90000,
    addSeekBar: true,
    'controlPanelElements': ['play_pause', 'spacer', 'time_and_duration'],
    seekBarColors: {
      base: 'rgba(115, 116, 117, 1)',
      buffered: 'rgba(115, 116, 117, 1)',
      played: 'rgb(238 59 76)'
    },

    /* Mendatory parameter */
    castReceiverAppId: ''
  };

  /**
   * recieve the product type
   */
  @Input() pType: number;

  /**
   * Audio element and container for our Audio element
   */
  private audioElement: HTMLVideoElement;
  private audioContainerElement: HTMLDivElement;

  /**
   * Reference variable for our shaka player object
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private player: any;

  /**
   * flag for detecting if there is error in URL
   */
  public errorInPlaying = false;

  /**
   *error message to be displayed on browser if URL not found
   */
  public errorMsg: string;

  /**
   * reference the instance of event manager
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private eventManager: any;

  /**
   * store the object which contains timespent,SQSTimeInterval,seekTime
   * timespent : the amount of time user spends streaming the audio
   * SQSTimeInterval : default value 0
   * seekTime : timeline of the seek bar
   */
  private startedSqs = { timespent: 1, SQSTimeInterval: 0, seekTime: 1 };
  /**
   * store the value of previously emitted startedSqs.
   */
  private previousStartedSqs = -1;

  /**
   * Interval after which we are sending data
   */
  private SQSTimeInterval = 30000;

  /**
   * stored value for clearing the SQSTimeInterval
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private sqsInterval: any;

  /**
   * stored value for clearing the perSecondInterval
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private perSecondInterval: any;

  /**
   * increasing the value of time spent in every second
   */
  private secondInterval = 1000;

  /**
   * Store the instance of ui controls of shaka
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private ui: any;

  /**
   * difference in orignal audio duration and seek value at the end of audio
   */
  private TIME_DIFFERENCE = 1.5;

  /**
   * maximum range for time spent
   */
  private TIME_SPENT_RANGE = 30;

  /**
   * SQS data to be send when audio start from beginning
   */
  specialSQS: { timespent: number; SQSTimeInterval: number; seekTime: number; };

  /**
   * To unsubscribe data.
   */
  private componentActive = true;

  /**
   * Inject dependencies in our component.
   */
  constructor(private platform: Platform) { }

  /**
   * Play, pause on the basis of the flag.
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['audioPlayerStatus']) {
      switch (changes['audioPlayerStatus'].currentValue) {
        case PLAYER_STATUS.PLAY:
          of(true).pipe(
            takeWhile(() => this.componentActive),
            delay(100),
            tap(() => {
              this.ui.video_.play();
            })).subscribe();
          break;
        case PLAYER_STATUS.PAUSE:
          this.ui.video_.pause();
          break;
        default:
          break;
      }
    }
  }

  /**
   *Installing built-in polyfills and checking browser incompatibilities and then
   *loading the video on player
   */
  ngAfterViewInit(): void {
    this.init();
    this.runPlayer();
  }

  /**
   *Install built-in polyfills to patch browser incompatibilities and then
   *calling initPlayer function if everything looks good!
   */
  private init(): void {
    shaka.polyfill.installAll();
    if (shaka.Player.isBrowserSupported()) {
      this.audioElement = this.audioElementRef.nativeElement;
      this.audioContainerElement = this.audioContainerRef.nativeElement;
      this.initPlayer();
    } else {
      console.error('Browser not supported!');
    }
  }

  /**
   *setting up all the configuration and controls on the UI and
   *Adding container for custom video controls
   */
  private initPlayer(): void {
    this.player = new shaka.Player(this.audioElement);

    this.ui = new shaka.ui.Controls(
      this.player,
      this.audioContainerElement,
      this.audioElement,
      this.config
    );
    this.eventManager = new shaka.util.EventManager();
    this.shakaEvents();

    if (this.enabledCookies && this.platform.SAFARI) {
      this.appendSignedURL();
    } else if (this.enabledCookies) {
      this.withCredentialTrue();
    }
  }

  /**
   * Append signed URL.
   */
  appendSignedURL(): void {
    this.player.getNetworkingEngine().registerRequestFilter((type, request) => {
      switch (type) {
        case shaka.net.NetworkingEngine.RequestType.MANIFEST:
        case shaka.net.NetworkingEngine.RequestType.SEGMENT:
        case shaka.net.NetworkingEngine.RequestType.TIMING:
        case shaka.net.NetworkingEngine.RequestType.APP:
        case shaka.net.NetworkingEngine.RequestType.LICENSE:
          if (request && request.uris && request.uris.length) {
            if (request.uris[0].endsWith('.m3u8') || request.uris[0].endsWith('.ts')) {
              const cookies = JSON.parse(sessionStorage.getItem('signedInCookies'));
              request.uris[0] = request.uris[0] + '?Key-Pair-Id=' + cookies['CloudFront-Key-Pair-Id'] + '&Policy=' + cookies['CloudFront-Policy'] + '&Signature=' + cookies['CloudFront-Signature'];
            }
          }
          request.allowCrossSiteCredentials = true;
          break;

        default:
          break;
      }
    });
  }

  /**
   * If browser is other than SAFARI then allow Cross-Site-Credential and no need to append signed URL.
   */
  withCredentialTrue(): void {
    this.player.getNetworkingEngine().registerRequestFilter((type, request) => {
      switch (type) {
        case shaka.net.NetworkingEngine.RequestType.MANIFEST:
        case shaka.net.NetworkingEngine.RequestType.SEGMENT:
        case shaka.net.NetworkingEngine.RequestType.TIMING:
        case shaka.net.NetworkingEngine.RequestType.APP:
        case shaka.net.NetworkingEngine.RequestType.LICENSE:
          request.allowCrossSiteCredentials = true;
          break;

        default:
          break;
      }
    });
  }

  /**
   * loading the video on player
   * event for catching error
   */
  private runPlayer(): void {
    this.player
      .load(this.videoSrc)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((e: any) => {

        if (e.code === 1001) {
          this.errorInPlaying = true;
          this.errorMsg = 'URL not found';
        }
        console.error('error in playing', e);
      });

  }

  /**
   * includes all the events used for shaka player
   */
  private shakaEvents(): void {
    this.eventManager.listen(this.audioElement, 'play', () => {
      this.play();
      this.playerStatus.emit(PLAYER_STATUS.PLAY);
    });

    this.eventManager.listen(this.audioElement, 'pause', () => {
      this.pause();
      this.playerStatus.emit(PLAYER_STATUS.PAUSE);
    });

    this.eventManager.listen(this.audioElement, 'ended', () => {
      this.ended();
      this.playerStatus.emit(PLAYER_STATUS.ENDED);
    });
  }

  /**
   * function is called after pause is hit
   */
  private pause(): void {
    if (this.startedSqs.seekTime !== NUMBERS.ZERO && this.startedSqs.seekTime + this.TIME_DIFFERENCE < this.round(this.audioElement.duration)) {
      this.clearingIntervals();
      this.emittingSQSInterval();
    }
  }

  /**
   * function for clearing the interval time
   */
  private clearingIntervals(): void {
    clearInterval(this.sqsInterval);
    clearInterval(this.perSecondInterval);
    this.startedSqs.seekTime = this.round(this.ui.seekBar_.getValue());
  }

  /**
   * For emitting SQS data
   */
  private emittingSQSInterval(): void {
    if (this.startedSqs.seekTime !== this.previousStartedSqs || this.startedSqs.timespent === NUMBERS.ONE) {
      this.startedSqs = {
        timespent: this.startedSqs.timespent > NUMBERS.ONE ? this.startedSqs.timespent : NUMBERS.ONE,
        SQSTimeInterval: this.startedSqs.SQSTimeInterval,
        seekTime: this.startedSqs.seekTime > NUMBERS.ONE ? this.startedSqs.seekTime : NUMBERS.ONE
      };
      this.startedSqs.timespent = this.startedSqs.timespent > this.TIME_SPENT_RANGE ? this.TIME_SPENT_RANGE : this.startedSqs.timespent;
      this.startedSqs.SQSTimeInterval = this.startedSqs.timespent === NUMBERS.ONE ? NUMBERS.ZERO : ((this.startedSqs.timespent === this.TIME_SPENT_RANGE) ? MILLISECOND_TIME.SIXTY_SECOND : this.startedSqs.timespent * NUMBERS.ONE_THOUSAND);
      this.receiveSQSInterval.emit(this.startedSqs);
      this.previousStartedSqs = this.startedSqs.seekTime;
      this.startedSqs.timespent = NUMBERS.ONE;
    }
  }

  /**
   * function is called when video state is changed from pause to play
   */
  private play(): void {
    if (this.pType === PRODUCT_TYPE_CONSTANT.POLL || this.pType === PRODUCT_TYPE_CONSTANT.ARTICLE) {
      this.sqsBuilder();
    }
  }

  /**
  * For sending data after specific time intervals
  */
  private sqsBuilder(): void {
    this.clearingIntervals();

    this.emittingSQSInterval();

    this.sqsInterval = setInterval(() => {

      this.emittingSQSInterval();
    }, this.SQSTimeInterval);

    this.perSecondInterval = setInterval(() => {
      this.startedSqs.seekTime = this.round(this.ui.seekBar_.getValue());
      this.startedSqs.timespent++;
    }, this.secondInterval);
  }

  /**
   * function for rounding off the number
   */
  private round(time): number {
    return Math.round(time);
  }

  /**
   * function is called when audio ends
   */
  private ended(): void {
    this.clearingIntervals();
    this.emittingSQSInterval();
    this.startedSqs = {
      timespent: NUMBERS.ONE,
      SQSTimeInterval: NUMBERS.ZERO,
      seekTime: NUMBERS.ONE
    };
  }

  /**
   * destroying SQSInterval emission
   */
  ngOnDestroy(): void {
    if (this.startedSqs.seekTime !== NUMBERS.ZERO) {
      this.clearingIntervals();
      this.emittingSQSInterval();
    }
    this.componentActive = false;
  }
}
