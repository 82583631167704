<div class="repost-popover text-left" *ngIf="isTagUserTutorialEnabled">
      <div class="tutorial-popover-dim">    
          <div class="bg-dark-gray">          
              <div>
                <div class="chip small filled error ml-3 mt-3">
                   <span>
                    <span>&nbsp;{{mls?.languageText?.tutorial?.key10}}!</span></span>
                </div>
                <div class="float-right cursor-pointer" (click)="hideModal()">
                    <img class="mt-3 mr-3" src="assets/images/cross-white-popover.svg" alt="cross">
                </div>           
              </div>         
              <p class="px-3 pt-3 font-weight-bold">
                <span class="text-white">{{mls?.languageText?.feed_card_screen?.key19}}</span>
              </p>
            <div class="tutorial-popover-body position-relative">          
              <p class="secondary-paragraph px-3 pt-1 pb-2">
                <span class="text-white">{{mls?.languageText?.feed_card_screen?.key20}}</span>
              </p>
            </div>        
            <div class="tutorial-popover-triangle">
            </div>
        </div>
      </div> 
    </div>