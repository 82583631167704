/**
 * Angular imports.
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

/**
 * Component imports.
 */
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { ChatErrorModalComponent } from '../share/components/chat-error-modal/chat-error-modal.component';

import { ModalModule } from 'ngx-bootstrap/modal';

@NgModule({
  declarations: [FileUploadComponent, ChatErrorModalComponent],
  imports: [
    CommonModule,
    ModalModule
  ],
  exports: [FileUploadComponent, ChatErrorModalComponent]
})
export class FileUploadModule { }
