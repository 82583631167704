/**
 * Angular imports.
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

/**
 * Component imports.
 */
import { UserPofilePicComponent } from './user-pofile-pic.component';
import { ConnUserForTagComponent } from '../../web/posting/tag-connection/conn-user-for-tag/conn-user-for-tag.component';

/**
 * Module imports.
 */
import { PipesModule } from './../../pipes/pipes.module';
import { AnalyticsEventModule } from '../../analytics-event/analytics-event.module';



@NgModule({
  declarations: [
    UserPofilePicComponent,
    ConnUserForTagComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    PipesModule,
    AnalyticsEventModule
  ],
  exports: [
    UserPofilePicComponent,
    ConnUserForTagComponent
  ]
})
export class UserProfilePicModule { }
