/**
 * Angular imports.
 */
import {
  Directive,
  ElementRef,
  EventEmitter,
  OnDestroy,
  Output,
} from '@angular/core';

/**
 * Rxjs imports.
 */
import { fromEvent, merge, of, Subscription, timer } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

/**
 * This directive is used to get the long press event.
 */
@Directive({
  selector: '[longPress]',
})
export class LongPressDirective implements OnDestroy {

  /**
   * Event subscription.
   * Threshold for the press.
   */
  private eventSubscribe: Subscription;
  private threshold = 200;

  /**
   * Emit the long press event
   */
  @Output() mouseLongPress = new EventEmitter();

  /**
   * ElementRef instance.
   * Make the combination of mousedown, touchstart, touchend, mouseup.
   */
  constructor(private elementRef: ElementRef) {
    const mousedown = fromEvent<MouseEvent>(
      elementRef.nativeElement,
      'mousedown'
    ).pipe(
      filter((event) => event.button == 0), // Only allow left button (Primary button)
      map((event) => true) // turn on threshold counter
    );
    const touchstart = fromEvent(elementRef.nativeElement, 'touchstart').pipe(
      map(() => true)
    );
    const touchEnd = fromEvent(elementRef.nativeElement, 'touchend').pipe(
      map(() => false)
    );
    const mouseup = fromEvent<MouseEvent>(window, 'mouseup').pipe(
      filter((event) => event.button == 0), // Only allow left button (Primary button)
      map(() => false) // reset threshold counter
    );
    this.eventSubscribe = merge(mousedown, mouseup, touchstart, touchEnd)
      .pipe(
        switchMap((state) => (state ? timer(this.threshold, 100) : of(null))),
        filter((value) => value)
      )
      .subscribe(() => this.mouseLongPress.emit());
  }

  /**
   * Unsubscribe the subscription.
   */
  ngOnDestroy(): void {
    if (this.eventSubscribe) {
      this.eventSubscribe.unsubscribe();
    }
  }
}
