/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Angular imports.
 */
import { Pipe, PipeTransform } from "@angular/core";

/**
 * Constant imports.
 */
import { CHAT_TYPE, MSG_STATUS } from "../lib/constants/chat.constants";

/**
 * Modal imports.
 */
import { ChatList } from "../modal/chat.interface";

/**
 * Pipe for the seq sorting and status marking.
 */
@Pipe({
  name: "sortedBy",
  pure: true
})
export class SortByPipe implements PipeTransform {
  transform(array: any, field: string, chatDetail: ChatList, reverse): any[] {
    if (!Array.isArray(array)) {
      return;
    }
    array.sort((a: any, b: any) => {
      if (reverse) {
        if (new Date(a[field]) > new Date(b[field])) {
          return -1;
        } else if (new Date(a[field]) < new Date(b[field])) {
          return 1;
        } else {
          return 0;
        }
      } else {
        if (a[field] < b[field]) {
          return -1;
        } else if (a[field] > b[field]) {
          return 1;
        } else {
          return 0;
        }
      }

    });
    if (chatDetail.type === CHAT_TYPE.P2P) {
      let read = false;
      let recv = false;
      for (let i = array?.length - 1; i >= 0; i--) {
        if (array[i]?._status !== MSG_STATUS.READ && array[i]?._status !== MSG_STATUS.TO_ME && array[i]?._status !== MSG_STATUS.QUEUED) {
          array[i]._status = MSG_STATUS.SENT;
        }
        if (!read && array[i]?.seq <= chatDetail?.read || array[i]?._status === MSG_STATUS.READ || array[i]?._status === MSG_STATUS.TO_ME) {
          read = true;
          recv = false;
        }
        if (!read && array[i]?.seq <= chatDetail?.recv) {
          recv = true;
        }
        if (read && array[i]?._status !== MSG_STATUS.TO_ME && array[i]?._status !== MSG_STATUS.READ && array[i]?.blocked !== MSG_STATUS.BLOCKED) {
          array[i]._status = MSG_STATUS.READ;
        }
        if (!read && recv && array[i]?._status !== MSG_STATUS.RECEIVED && array[i]?.blocked !== MSG_STATUS.BLOCKED && array[i]?._status !== MSG_STATUS.READ && array[i]?._status !== MSG_STATUS.TO_ME) {
          array[i]._status = MSG_STATUS.RECEIVED;
        }
        if (array[i]?.blocked === MSG_STATUS.BLOCKED) {
          array[i]._status = MSG_STATUS.SENT;
        }
      }
    }

    if (chatDetail.type === CHAT_TYPE.GROUP) {
      let read = false;
      let recv = false;
      for (let i = array?.length - 1; i >= 0; i--) {
        if (array[i]?._status !== MSG_STATUS.QUEUED) {
          array[i]._status = MSG_STATUS.SENT;
        }
        if (!read && array[i]?.seq <= chatDetail?.minRead) {
          read = true;
          recv = false;
        }
        if (!read && array[i]?.seq <= chatDetail?.minRecv) {
          recv = true;
        }
        if (read && array[i]?._status !== MSG_STATUS.TO_ME && array[i]?._status !== MSG_STATUS.READ) {
          array[i]._status = MSG_STATUS.TO_ME;
        }
        if (!read && recv && array[i]?._status !== MSG_STATUS.RECEIVED && array[i]?._status !== MSG_STATUS.READ) {
          array[i]._status = MSG_STATUS.RECEIVED;
        }
      }
    }
    return array;
  }
}
