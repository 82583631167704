<div id="SubscribeBoxModal" class="modal fade success-popup" tabindex="-1" role="dialog" bsModal>
    <div class="custom-theme-modal modal-dialog modal-md modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header d-block text-right pt-3 pb-2">
                <span class="cursor-pointer">
                    <img src="assets/themes/icons/cancel.svg" alt="cross">
                </span>
            </div>
            <div class="modal-body p-3 mt-3 text-center">
                <div>
                    <img src="assets/themes/icons/public/business-enquiry-success.svg" alt="cross">
                </div>
                <div>
                    <span class="heading-3">Your inquiry has been successfully submitted!</span>
                    <br>
                    <span class="paragraph">
                        <span class="color-grey-700">Thank you for submitting your query. We will look into it and get
                            back to you soon.</span></span>
                </div>
            </div>
            <div class="modal-footer d-block text-center btn-list">
                <ng-container class="">
                    <button class="btn-design outline small" type="button">
                        Back to Article
                    </button>
                </ng-container>
            </div>
        </div>
    </div>
</div>