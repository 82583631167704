/**
 * Angular imports.
 */
import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router,ActivatedRoute} from '@angular/router';


/**
 * Service providing common utility functions.
 */
@Injectable({
  providedIn: 'root'
})
export class CommonFunctionService {

  /**
   * Constructor to inject Document and Router.
   */
  constructor(@Inject(DOCUMENT) private document: any, private router: Router) { }

  /**
   * Get a custom UUID from the browser's cookies.
   */
  getCustomUUID() {
    return this.getCookieData('browserUniqueId');
  }

  /**
   * Get a fingerprint UUID from the browser's cookies.
   */
  getFingerPrintUUID() {
    return this.getCookieData('browserFingerprintId');
  }

  /**
   * Get cookie data by name.
   */
  getCookieData(name) {
    let pairs = document.cookie.split('; '),
      count = pairs.length, parts;
    while (count--) {
      parts = pairs[count].split('=');
      if (parts[0] === name)
        return parts[1];
    }
    return false;
  }
  }
