/* eslint-disable no-useless-escape */
/**
 * Angular imports.
 */
import { Pipe, PipeTransform } from '@angular/core';

/**
 * To generate the link from the plain text.
 */
@Pipe({
  name: 'linkify'
})
export class LinkifyPipe implements PipeTransform {

  transform(link: string): string {
    if (link != null) {

      return this.linkify(link);
    }
  }

  private linkify(plainText): string {

    let replacedText;

    replacedText = plainText
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&#39;');
    // URLs starting with http://, https://, or ftp://
    // URLs starting with http://, https://, or ftp://
    const replacePattern1 =
      /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
    if (replacePattern1.test(plainText)) {
      replacedText = replacedText.replace(
        replacePattern1,
        '<a href="$1" target="_blank">$1</a>'
      );
      return replacedText
        .replace(/<((?!a )[^>]*)>/g, '&lt;$1&gt;')
        .replace(/&lt;\/a&gt;/g, '</a>');
    }

    // URLs starting with "www." (without // before it, or it'd re-link the ones done above).
    const replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
    if (replacePattern2.test(plainText)) {
      replacedText = replacedText.replace(
        replacePattern2,
        '$1<a href="http://$2" target="_blank">$2</a>'
      );
      return replacedText
        .replace(/<((?!a )[^>]*)>/g, '&lt;$1&gt;')
        .replace(/&lt;\/a&gt;/g, '</a>');
    }


    // Change email addresses to mailto:: links.
    const replacePattern3 =
      /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
    if (replacePattern3.test(plainText)) {
      replacedText = replacedText.replace(
        replacePattern3,
        '<a href="mailto:$1">$1</a>'
      );
      return replacedText
        .replace(/<((?!a )[^>]*)>/g, '&lt;$1&gt;')
        .replace(/&lt;\/a&gt;/g, '</a>');
    }

    if (replacedText == '') {
      return plainText;
    }

    return replacedText
      .replace(/<((?!a )[^>]*)>/g, '&lt;$1&gt;')
      .replace(/&lt;\/a&gt;/g, '</a>');
  }
}
