import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-public-user-profile-detail',
  templateUrl: './public-user-profile-detail.component.html',
  styleUrls: ['./public-user-profile-detail.component.scss']
})
export class PublicUserProfileDetailComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
