/**
 * Angular imports.
 */
import { Component, Input } from '@angular/core';

/**
 * Progress bar for the chat panel.
 */
@Component({
  selector: 'app-chat-progress',
  templateUrl: './chat-progress.component.html',
  styleUrls: ['./chat-progress.component.scss']
})
export class ChatProgressComponent {

  /**
   * Show the small loading or big loading gif.
   * Show the overlay loading for the whole screen.
   * Width of the gif.
   * Height of the gif.
   */
  @Input() isSmallLoading: boolean;
  @Input() isFullScreen: number;
  @Input() width: number;
  @Input() height: number;

}
