<div *ngIf="isSpecialityShown==true" class="modal fade" [config]="{ show: true,backdrop: 'static' }"
  (onHidden)="onHidden()" id="specialityModal" bsModal #autoShownModal="bs-modal" aria-hidden="true"
  data-backdrop="static" data-keyboard="false">
  <div class="custom-theme-modal modal-dialog  modal-dialog-top"
    [ngClass]="{'modal-md':speciality_select_type=='single', 'modal-lg':speciality_select_type=='multiple'}">
    <div class="modal-content modal-scrollable-content" style="min-height: 85vh;">
      <div class="modal-header pt-4">
        <div class="row">
          <div class="col-7 pr-0">
            <h6 class="heading-4">{{(speciality_select_type=='single')?
              mls.languageText.onboardspeciality_screen.key1 : mls.languageText.onboardspeciality_screen.key2}}
              <span *ngIf="mls?.is_lskey_display==1">( {{(speciality_select_type=='single')?
                "onboardspeciality_screen.key1" : "onboardspeciality_screen.key2"}} )</span>
            </h6>
            <label *ngIf="err_msg!=''" class="color-error">
              {{err_msg}}
            </label>
            <label *ngIf="dss.user_profile_data.profile.user_type === 'student'" class="secondary-paragraph">
              {{mls.languageText.onboardspeciality_screen.key7}}
            </label>
          </div>

          <div class="col-5 text-right button-auto">
            <h6 *ngIf="speciality_select_type ==='multiple' && dss.user_profile_data.profile.user_type !== 'student'"
              class="paragraph"
              [eventTracker]='{"category":mls.googleEventText.onboardspeciality_screen.category,"action":mls.googleEventText.onboardspeciality_screen.key1,"product_type":"", "product_id":""}'>
              <span class="cursor-pointer" (click)="skipInterest()">
                {{mls?.languageText?.onboardspeciality_screen?.key8}} <img src="/assets/images/arrow-right-gray.svg"
                  alt="arrow">
              </span>
            </h6>
          </div>
        </div>
      </div>
      <div class="modal-body">
        <div class="row">
          <div [ngClass]="{'col-md-12':speciality_select_type=='single', 'col-md-8':speciality_select_type=='multiple'}">
            <div class="custom-dropdown">
              <div>
                  <span class="icon-left">
                    <img src="assets/themes/icons/search-icon.svg" alt="search">
                  </span>
                  <span class="icon-right" *ngIf="userFilter.speciality_name.length > 0" (click)="resetSearchBox()">
                    <img src="assets/themes/icons/cancel.svg" width="16" alt="cross">
                  </span>
                  <input id="searchSpe" [(ngModel)]="userFilter.speciality_name"
                  (ngModelChange)="removeWhiteSpace($event)" placeholder="{{mls?.languageText?.onboardspeciality_screen?.key4}}{{(mls?.is_lskey_display==1)? '(onboardspeciality_screen.key4)':''}}..." />
              </div>
            </div>

            <div id="divToScroll" class="ps px-1 pt-2 position-relative modal-max-height" [perfectScrollbar]="config">
              <!--  new design -->
              <div *ngIf="loading_data_in_process==true" class='text-center w-100'>
                <img src="./assets/images/loader-small.svg" width="40" height="100" id="loadmore_post">
              </div>
              <div *ngFor="let spelist of speciality_list  | filterBy: userFilter">
                <label class="list-item w-100" [ngClass]="speciality_select_type === 'multiple' ? 'checkbox' : 'radio'">
                  <input [type]="speciality_select_type === 'multiple' ? 'checkbox' : 'radio'"
                  [disabled]="speciality_select_type === 'multiple' && spelist?.speciality_id === user_speciality[0]?.speciality_id && dss.user_profile_data.profile.user_type !== 'student'"
                  (change)="select_speciality($event, spelist)" [checked]="makeSelected(spelist)"/>
                  <span>
                    <div class="d-none asstn-image-div float-left rounded mr-2">
                      <img alt="" class="asstn-image" [src]="spelist?.logo"
                        onerror="this.src='assets/images/grandround/image_placeholder.svg'">
                    </div>
                    <div>
                      <p class="secondary-paragraph">
                        {{spelist?.speciality_name}}
                      </p>
                    </div>
                  </span>
                </label>
              </div>
            </div>
            <div
              *ngIf="!loading_data_in_process && (!speciality_list || (speciality_list | filterBy: userFilter)?.length === 0)"
              class="text-center my-5 paragraph">
              <img src="assets/themes/icons/no-search-result.svg" alt="">
              <p class="mt-3">{{mls?.languageText?.onboardspeciality_screen?.key5}}<span
                  *ngIf="mls?.is_lskey_display==1">(onboardspeciality_screen.key5)</span></p>
            </div>
          </div>
          <div *ngIf="speciality_select_type=='multiple'" class="col-4 d-none d-md-block selected-interest">

            <p *ngIf="user_speciality.length>=1" class="mb-3 pt-3 pararaph-bold">
              <b>
                {{mls?.languageText?.onboardspeciality_screen?.key6}}
                <span *ngIf="mls?.is_lskey_display==1">(onboardspeciality_screen.key6)</span>
              </b>
            </p>
            <span class="chip large filled mb-2 h-auto" [ngClass]="i === 0 && dss.user_profile_data.profile.user_type !== 'student' ? '' : 'icon-position-right'" *ngFor="let selectedspelist of user_speciality; let i = index;">
              <img src="assets/images/cross.svg" alt="Lock Icon" class="cursor-pointer" (click)="removespec(i)">
              {{selectedspelist.speciality_name}}
            </span>
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-between">
        <h6 *ngIf="speciality_select_type === 'multiple' && user_speciality.length > 0" class="secondary-paragraph-bold">
          {{user_speciality.length}} {{mls?.languageText?.onboardspeciality_screen?.key6}}
        </h6>
        <button class="btn-design medium" type="button" (click)="addSpeciality()"
          [disabled]="user_speciality.length==0 || (dss.user_profile_data.profile.user_type !== 'student' && user_speciality.length == 1 && speciality_select_type === 'multiple' )"
          [ngClass]="( (user_speciality.length>0 && speciality_select_type !== 'multiple') || (user_speciality.length>1 && dss.user_profile_data.profile.user_type !== 'student' && speciality_select_type === 'multiple') || (user_speciality.length>0 && dss.user_profile_data.profile.user_type === 'student' && speciality_select_type === 'multiple')  )? 'filled' : 'filled-disabled'"
          [eventTracker]='{"category":mls.googleEventText.onboardspeciality_screen.category,"action":mls.googleEventText.onboardspeciality_screen.key1,"product_type":"", "product_id":""}'>
            <span *ngIf="api_in_process==true" style="left: 8px;" class="spinner-border spinner-border-xs position-absolute" role="status" aria-hidden="true">
            </span>
            {{mls?.languageText?.onboardspeciality_screen?.key3}}
            <span *ngIf="mls?.is_lskey_display==1">(onboardspeciality_screen.key3)</span>
        </button>
      </div>
    </div>
  </div>
</div>
