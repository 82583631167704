/**
 * Angular imports.
 */
import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe for the object keys transform.
 */
@Pipe({
  name: 'keys'
})
export class KeysPipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transform(value: any): string[] {
    return Object.keys(value);
  }
}
