import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { NewLanguageService } from '../../../dataservices/new-language/new-language.service';
import { NUMBERS } from '../../../constants/app.constants';
@Component({
  selector: 'app-reply-suggestion-list-for-tag-users',
  templateUrl: './reply-suggestion-list-for-tag-users.component.html',
  styleUrls: ['./reply-suggestion-list-for-tag-users.component.scss']
})
export class ReplySuggestionListForTagUsersComponent implements OnChanges {
  @Input() fromCommentUpdate;
  @Input() suggList;
  @Input() offSet;
  @Input() finished;
  @Output() getOffSetUserList = new EventEmitter();
  @Output() saveTaggedUserDataEmit = new EventEmitter();

  connection_pic_image = {
    prflclass: 'user-40 text-center rounded-circle text-white d-table-cell align-middle',
    prflpic: '',
    prflfname: '',
    prflmname: '',
    prfllname: '',
    prflrname: '',
    prflcid: '',
    prfltype: '',
    prflpicclass: 'c-profile-pic rounded-circle user-40 post-pic',
    badge: { is_verified: 0, is_show: false },
    full_name: '',
    is_action: false
  };
  showTagProcessed = false;
  public config = {};
  constructor(public mls: NewLanguageService) { }

  /**
   * On Changes
   */
  ngOnChanges(): void {
    for (let i = 0; i < this.suggList.length; i++) {
      this.suggList[i]['connection_pic_image'] = {
        prflclass: 'user-40 text-center rounded-circle text-white d-table-cell align-middle',
        prflpic: this.suggList[i].profile_pic ? this.suggList[i].profile_pic : '',
        prflfname: this.suggList[i].first_name ? this.suggList[i].first_name : this.suggList[i].full_name,
        prflmname: '',
        prfllname: this.suggList[i].last_name ? this.suggList[i].last_name : this.suggList[i].full_name,
        prflrname: this.suggList[i].registered_name,
        prflcid: this.suggList[i].custom_id,
        prfltype: '',
        prflpicclass: 'c-profile-pic rounded-circle user-40 post-pic',
        badge: { is_verified: (this.suggList[i].permission == 'all') ? 1 : 0, is_show: false },
        full_name: this.suggList[i].full_name,
        is_action: false
      };
    }
  }

  /**
   * Save Tag User Data after Selection
   */
  saveTaggedUserData(sugg): void {
    this.saveTaggedUserDataEmit.emit(sugg);
  }

  /**
   * set offset after scroll
   */
  onUserScroll(): void {
    if (this.offSet > NUMBERS.ONE) {
      this.getOffSetUserList.emit(this.offSet);
    }
  }

}
