/**
 * ViewChild is for binding tags of html to be accessible for this file
 * Input is for receiving inputs from smart component
 */
import { Component, AfterViewInit, ElementRef, ViewChild, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
/* Interface for UI configuration of shaka player */
import { Config } from '../shakaPlayer.interface';

/* variable for shaka player */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let shaka: any;

/** Can be used as a dumb component by using app-shaka-basic selector
 * templateUrl is path to html template
 * styleUrls is path to css
*/
@Component({
  selector: 'app-shaka-basic',
  templateUrl: './basic.component.html',
  styleUrls: ['./basic.component.scss']
})

export class BasicComponent implements AfterViewInit, OnChanges {
  /**  @ViewChild to query the view using the given selectors
   * videoElementRef contains a reference to the video element
   */
  @ViewChild('videoPlayer') public videoElementRef: ElementRef;

  /**
  * videoContainerRef includes a reference to the div that wraps the video element.
  */
  @ViewChild('videoContainer') public videoContainerRef: ElementRef;


  /**
   * URL for video, receiving as input
   */
  @Input() public videoSrc: string;

  /**
   * URL for banner of video
   */
  @Input() public videoBanner: string;

  /**
   * recieve the css classes for shaka player
   */
  @Input() public shakaStyleCss: string;

  /**
  *UI configuration for video, receiving as input
  */
  @Input() public config: Config = {
    addSeekBar: true,
    addBigPlayButton: true,
    'controlPanelElements': ['play_pause', 'spacer', 'time_and_duration',
      'mute', 'fullscreen'
    ],
    seekBarColors: {
      base: 'rgba(255, 255, 255, 0.3)',
      buffered: 'rgba(255, 255, 255, 0.54)',
      played: 'rgb(255, 255, 255)'
    },

    /* Mendatory parameter */
    castReceiverAppId: ''
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }; ui: any;

  /**
   * error message to be displayed on browser.
   * Auto play flag to start video automatic byDefault its false.
   * Resume the video accept time in seconds.
   */
  @Input() errorMsg = '';
  @Input() autoplay = false;
  @Input() resumeTime = 0;

  /**
   * Emit the ended flag outside
   * Emit the player duration in milliseconds.
   */
  @Output() emitEndedFlag: EventEmitter<void> = new EventEmitter();
  @Output() emitLiveTimeDuration: EventEmitter<number> = new EventEmitter();

  /**
    * Video element and container for our video element
    */
  private videoElement: HTMLVideoElement;
  private videoContainerElement: HTMLDivElement;

  /**
   * Reference variable for our shaka player object
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private player: any;

  /**
  * flag for detecting if there is error in URL
  */
  public errorInPlaying = false;


  /**
  *Installing built-in polyfills and checking browser incompatibilities and then
  *loading the video on player
  */
  ngAfterViewInit(): void {
    this.init();
    this.runPlayer();
  }

  /**
   * Detects the changes in the video src.
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.videoSrc && !changes.videoSrc.firstChange) {
      this.runPlayer();
    }
  }

  /**
    *Install built-in polyfills to patch browser incompatibilities and then
    *calling initPlayer function if everything looks good!
  */
  private init(): void {
    console.log(this.videoSrc,'acddd basic');
    shaka.polyfill.installAll();
    if (shaka.Player.isBrowserSupported()) {
      this.videoElement = this.videoElementRef.nativeElement;
      this.videoContainerElement = this.videoContainerRef.nativeElement;
      this.initPlayer();
    } else {
      console.error('Browser not supported!');
    }
  }

  /**
  *setting up all the configuration and controls on the UI and
  *Adding container for custom video controls
  */
  private initPlayer(): void {
    this.player = new shaka.Player(this.videoElement);

    this.ui = new shaka.ui.Controls(
      this.player,
      this.videoContainerElement,
      this.videoElement,
      this.config
    );

    /**
     * Adding banner for the video.
     */
    this.videoElement.poster = this.videoBanner || '';

    const event = new shaka.util.EventManager();
    event.listen(this.videoElement, 'play', () => {
      this.videoContainerElement.appendChild(this.ui.spinnerContainer_);
    });
    event.listen(this.videoElement, 'ended', () => {
      this.emitEndedFlag.emit();
    });
    event.listen(this.videoElement, 'timeupdate', () => {
      this.emitLiveTimeDuration.emit(this.videoElement.currentTime);
    });
  }

  /**
   * loading the video on player
   * event for catching error
   */
  private runPlayer(): void {
    this.player
      .load(this.videoSrc, this.resumeTime)
      .then(() => {
        this.videoContainerElement.removeChild(this.ui.spinnerContainer_);
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((e: any) => {
        this.errorInPlaying = true;
        console.error('error in playing', e);
      });
  }
}

