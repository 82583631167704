/**
 * Angular Imports
 */
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

/**
 * rxjs imports
 */
import { Subscription } from 'rxjs';

/**
 * Service Imports
 */
import { OpenAccessService } from '../open-access.service';
import { NewLanguageService } from 'src/app/dataservices/new-language/new-language.service';
import { FunCollectionService } from '../../common/common-functions/fun-collection.service';
import { PublicAnalyticsEventService } from '../public-analytics/public-analytics-event.service';
import { PurpleOnboarding } from '../purple-onboarding.service';


/**
 * Const Imports
 */
import { NUMBERS, PRODUCT_TYPE_CONSTANT, OPEN_ACCESS, LOGIN_SDK, SDK_PAGE_TYPE, LOCAL_STORAGE } from 'src/app/constants/app.constants';

/**
 * Global model imports.
 */
import { LoginSdkParameter } from '../../onboarding/interface/global.model';
/**
 * Constant Imports
 */
import { IEventName, IPublicAnalyticsData, SCREEN_NAME as screenName  } from '../public-analytics/public-analytics.model';
import { EVENT_NAME } from '../public-analytics/public-analytics.constant';


/**
 * Component for the public webinar detail page
 */
@Component({
  selector: 'app-public-webinar-detail',
  templateUrl: './public-webinar-detail.component.html',
  styleUrls: ['./public-webinar-detail.component.scss']
})
export class PublicWebinarDetailComponent implements OnInit, OnDestroy{

  /**
   * Details of the webinar
   * Flag to determine whether to show all speakers or not
   * Flag to hide the "View All" button
   * id for webinar
   */

  public webinarDetail: any;
  public showAll = false;
  public hideViewAll = false;
  public webinarId : string;
  public callingFrom= '';
  /**
   * Constants
   */
   public numbers = NUMBERS;
   public openAccessConst = OPEN_ACCESS;

    /**
   * object for passing open article data
   */
    public suggestionList: any;
    public apiInProcess = false;
    public restrictContent = false;
    public restrictData : any;
    private readonly eventName: Map<number,IEventName> = new Map([
      [OPEN_ACCESS.productlist.community, EVENT_NAME.COMMUNITY_ICON_CLICK],
      [OPEN_ACCESS.productlist.doctalk, EVENT_NAME.DOCTALKS_ICON_CLICK],
      [OPEN_ACCESS.productlist.article, EVENT_NAME.ARTICLES_ICON_CLICK],
      [OPEN_ACCESS.productlist.webinar, EVENT_NAME.WEBINAR_ICON_CLICK]
    ]);
    public record: IPublicAnalyticsData;
    public webinarRecord: IPublicAnalyticsData;
    public signInRecord: IPublicAnalyticsData;
    public subscription: Subscription;
    
  /**
   * Webinar element reference for SDK
   */
    @ViewChild('webinarloginContainer') webinarloginContainer!: ElementRef;


  /**
   * Constructor
   * @param oas - OpenAccessService instance
   * @param mls - NewLanguageService instance
   */
  constructor(
    public oas: OpenAccessService,
    public mls: NewLanguageService,
    public route: ActivatedRoute,
    public fcs: FunCollectionService,
    private paes: PublicAnalyticsEventService,
    private login : PurpleOnboarding
  ) {}

  /**
   * Lifecycle hook - ngOnInit
   * Initialize the component
   */
  ngOnInit(): void {
    this.oas.selectedTab = '27';
    this.route.paramMap.subscribe(params => {
      this.webinarId = params.get('id');
      this.getOpenWebinarDetail();
      this.getWebinarSuggList();
      this.setAnalyticEvent();
      this.sendEntryEvent();
    });
    this.subscription = this.login.login$.subscribe(() => {
      this.fcs.navigateToWebinarDetail(this.webinarId);
    })
  }



  /**
   * Method to fetch the details of the open article from the OpenAccessService.
   */
  getOpenWebinarDetail(): void {
    this.apiInProcess = true;
    this.oas.getOpenArticleDetail(this.webinarId, PRODUCT_TYPE_CONSTANT.WEBINAR).subscribe(
      (response: any) => {
        this.apiInProcess =false;
        const res = response;
        if (res.status === NUMBERS.ONE && res.code === NUMBERS.TWO_THOUSAND) {
          this.webinarDetail = res.data.response;
          if(this.webinarDetail?.login_required == true){
            this.login.callLoginSdk(this.webinarloginContainer);
          }
        }
        if (res.status === NUMBERS.ONE && res.code === NUMBERS.TWO_THOUSAND_ONE) {
          this.restrictData = res.data.message;
          this.restrictContent = true;
        }
      }
    );
  }

  /**
   * Toggle the display of all speakers
   */
  toggleShowAll() :void {
    this.showAll = !this.showAll;
    this.hideViewAll = this.showAll;
  }

  /**
   * Method to fetch the list of open webinar from the OpenAccessService.
   */
    getWebinarSuggList(): void {
      this.oas.getOpenArticleList(OPEN_ACCESS.productlist.webinar).subscribe(
        (response: any) => {
          const res = response;
          if (res.status === NUMBERS.ONE && res.code === NUMBERS.TWO_THOUSAND) {
            res.data.response.forEach(element => {
              element.list = element.list.filter( (filterData) => filterData.id !== Number(this.webinarId))
              this.suggestionList = element;
            });
          }
        }
      );
    }

    /**
     * Navigate user to list page.
     */
    viewAllClick(event): void {
      this.fcs.navigateToOpenProductList(event);
    }

  /**
   * Navigate user to webinar detail page.
   */
   navigateTologgedInWebinarDetail(): void {
    let mcToken = localStorage.getItem(LOCAL_STORAGE.TOKEN_DETAIL);
    mcToken = mcToken ? JSON.parse(mcToken) : undefined; 
    if (this.oas.toCallLoginSDK() && !mcToken) {
      this.login.callLoginSdk(this.webinarloginContainer);
    }
    else {
      this.fcs.navigateToWebinarDetail(this.webinarId);
    }
  }
  /**
   * function to open login sdk
   */
  onCardClick(): void {
    this.login.callLoginSdk(this.webinarloginContainer);
  }
  register(): void {
    this.fcs.navigateToWebinarDetail(this.webinarId);
  }

  /**
   * sends share event
   * @param eventName
   */
  sendShareEvent(eventName: string): void {
    const record: IPublicAnalyticsData = {
      product_type: PRODUCT_TYPE_CONSTANT.WEBINAR,
      product_id: +this.webinarId,
      screen_name: screenName.PRODUCT_DETAIL_PAGE,
      event_name: ''
    };
    this.oas.sendEvent(eventName, record);
  }

  /**
   * sends header menu click event
   * @param pType
   * @returns
   */
  sendEvent(pType: number): void {
    if(!this.eventName.has(pType)) {
      return;
    }
    const record: IPublicAnalyticsData = {
      product_type: OPEN_ACCESS.productlist.webinar,
      product_id: +this.webinarId,
      screen_name: screenName.PRODUCT_DETAIL_PAGE,
      event_name: '',
      eventName: this.eventName.get(pType)
    };
    this.paes.sendAnalytics(record);
  }

  ngOnDestroy(): void {
    this.sendExitEvent();
    if(this.subscription){
      this.subscription.unsubscribe();
    }
  }

  /**
   * sends entry event
   */
  private sendEntryEvent(): void {
    const record: IPublicAnalyticsData = {
      product_type: OPEN_ACCESS.productlist.webinar,
      product_id: +this.webinarId,
      screen_name: screenName.PRODUCT_DETAIL_PAGE,
      event_name: '',
      eventName: EVENT_NAME.PRODUCT_DETAIL_PAGE_ENTRY,
      extra: JSON.stringify({unique_id: this.fcs.openAccessWelcomeScreenData?.extras?.id})
    };
    this.paes.sendAnalytics(record);
  }

  /**
   * sends exit event
   */
  private sendExitEvent(): void {
    const record: IPublicAnalyticsData = {
      product_type: OPEN_ACCESS.productlist.webinar,
      product_id: +this.webinarId,
      screen_name: screenName.PRODUCT_DETAIL_PAGE,
      event_name: '',
      eventName: EVENT_NAME.PRODUCT_DETAIL_PAGE_EXIT
    };
    this.paes.sendAnalytics(record);
  }

  /**
   * sets analytic event
   */
  private setAnalyticEvent(): void {
    this.record = {
      product_type: OPEN_ACCESS.productlist.webinar,
      product_id: +this.webinarId,
      screen_name: screenName.PRODUCT_DETAIL_PAGE,
      event_name: '',
      eventName: EVENT_NAME.COUNTRY_DROPDOWN_CLICK
    };
    this.webinarRecord = {
      product_type: OPEN_ACCESS.productlist.webinar,
      product_id: +this.webinarId,
      screen_name: screenName.PRODUCT_DETAIL_PAGE,
      event_name: '',
      eventName: EVENT_NAME.REGISTER_CLICK
    };
    this.signInRecord = {
      product_type: OPEN_ACCESS.productlist.webinar,
      product_id: +this.webinarId,
      screen_name: screenName.PRODUCT_DETAIL_PAGE,
      event_name: '',
      eventName: EVENT_NAME.SIGNIN_CLICK
    };
  }
}
