/* eslint-disable @angular-eslint/no-output-on-prefix */
/**
 * Angular imports.
 */
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';

/**
 * Rxjs imports.
 */
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

/**
 * Constant imports.
 */
import { CHAT_VIEW, MAX_GROUP_MEMBERS_LIMIT, ONE, SCROLL_OFFSET_SEARCH, SEARCH_USER_DEBOUNCE, ZERO } from '../../../constants/chat.constants';

/**
 * Search users for the selection.
 */
@Component({
  selector: 'app-search-users',
  templateUrl: './search-users.component.html',
  styleUrls: ['./search-users.component.scss']
})
export class SearchUsersComponent implements OnInit, OnDestroy {

  /**
   * Get the search input field.
   */
  @ViewChild('searchBody', { static: false }) searchBody!: ElementRef;
  @ViewChild('input', { static: false }) input: ElementRef;
  @ViewChild('widgetsContent') widgetsContent: ElementRef;

  /**
   * Show/Hide the search user modal.
   * Title text for the modal heading
   * End src for the individual box.
   * Show/Hide the checkBox.
   * Button text for the closing of modal.
   * Connection List for the search.
   * Suggestion List for the search.
   * Offset for the search list.
   * Selected list for the search users.
   * Show the loading for the api response.
   */
  @Input() isSearchUser: boolean;
  @Input() title = 'Select user';
  @Input() endSrc = 'assets/samvaad-assets/images/icons/search-user-list-select.svg';
  @Input() isCheckBox: boolean;
  @Input() btnText = 'Close';
  @Input() connectionsList = [];
  @Input() suggestionsList = [];
  @Input() offset: number;
  @Input() selectedList = [];
  @Input() noDataFoundLabel = 'No Result Found';
  @Input() selectedLabel = 'selected';
  @Input() isLoading: boolean;
  @Input() showFooter: boolean;
  @Input() chatView: number;
  @Input() key: string;
  @Input() backKey = true;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() samvaadLanguageText: any = {};
  @Input() maxLimitUsers = MAX_GROUP_MEMBERS_LIMIT;
  @Input() alreadyExistLength = 0;

  /**
   * Emit the single select for the search user.
   * Emit the search keyword for the search user.
   * Emit the View all click for the search user.
   * Emit the Hide event for the search user.
   * Emit the submit event for the search user selection.
   */
  @Output() onSingleSelect: EventEmitter<void> = new EventEmitter();
  @Output() onSearchKeyword: EventEmitter<{ keyword: string, key: string, offset: number }> = new EventEmitter();
  @Output() onViewAll: EventEmitter<{ keyword: string, key: string, offset: number }> = new EventEmitter();
  @Output() onHide: EventEmitter<void> = new EventEmitter();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() onSubmit: EventEmitter<any> = new EventEmitter();
  @Output() onVisit: EventEmitter<Date> = new EventEmitter();
  @Output() onLeave: EventEmitter<Date> = new EventEmitter();
  @Output() onMaxLimitEvent: EventEmitter<number> = new EventEmitter();

  /**
   * Search Subject for the debounce typing.
   * Search Subscription for the unsubscribe.
   */
  private readonly searchSubject = new Subject<string | undefined>();
  private searchSubscription?: Subscription;
  private searchUserStartTime: Date;

  /**
   * Keyword for the search user input box.
   * Key for the selection of view all.
   */
  public keyword = '';
  public chatViewConstant = CHAT_VIEW;

  /**
   * Get the debounce for the user searching.
   * Decrease z-index of header.
   */
  ngOnInit(): void {
    this.emitOnVisit();
    this.selectedList = [...this.selectedList];
    this.searchSubscription = this.searchSubject
      .pipe(
        debounceTime(SEARCH_USER_DEBOUNCE),
        distinctUntilChanged(),
        switchMap(async (searchQuery) => this.search(searchQuery))
      )
      .subscribe();
  }

  /**
   * Emit the entry event outside.
   */
  emitOnVisit(): void {
    this.searchUserStartTime = new Date();
    this.onVisit.emit(new Date());
  }

  /**
   * Emit the entry event outside.
   */
  emitOnLeave(): void {
    this.onLeave.emit(this.searchUserStartTime);
  }

  /**
   * Select the single user.
   */
  selectUser(user): void {
    this.onSingleSelect.emit(user);
  }

  /**
   * Send the search key to the debounce subscription.
   */
  search(keyword: string): void {
    this.keyword = keyword;
    this.onSearchKeyword.emit({ keyword: this.keyword, key: this.key, offset: ONE })
  }

  /**
   * Emit the search key to the debounce subscription
   */
  onSearchQueryInput(event: Event): void {
    const searchQuery = (event.target as HTMLInputElement).value;
    this.searchSubject.next(searchQuery?.trim());
  }

  /**
   * Emit the hide event and close the search user.
   */
  onHidden(): void {
    this.isSearchUser = false;
    this.onHide.emit();
  }

  /**
   * Close the component.
   */
  onClose(): void {
    this.isSearchUser = false;
    this.onHidden();
  }

  /**
   * Emit the view all event for the list.
   */
  viewAll(key: string): void {
    this.key = key;
    this.onViewAll.emit({ keyword: this.keyword, key, offset: ONE });
    if (this.searchBody?.nativeElement?.scrollTop) {
      this.searchBody.nativeElement.scrollTop = ZERO;
    }
  }

  /**
   * Reset the search keyword.
   */
  goBack(): void {
    this.key = null;
    this.onSearchKeyword.emit({ keyword: this.keyword, key: this.key, offset: ONE })
  }

  /**
   * Reset the search keyword.
   */
  resetSearch(): void {
    this.input.nativeElement.value = '';
    this.keyword = '';
    this.onSearchKeyword.emit({ keyword: this.keyword, key: this.key, offset: ONE })
  }

  /**
   * On scroll call next offset.
   */
  onScroll(): void {
    if (this.key && this.offset) {
      this.onViewAll.emit({ keyword: this.keyword, key: this.key, offset: this.offset });
    }
  }

  /**
   * Add the check box for the user.
   */
  add(event, suggestion): void {
    const index = this.selectedList?.length > ZERO ? this.selectedList.find(value => suggestion.custom_id === value.custom_id) : '';
    if (index) {
      this.remove(suggestion);
    } else {
      if (this.checkForMaxUsers()) {
        this.selectedList.push(suggestion);
      } else {
        event.preventDefault();
        event.stopPropagation();
        this.emitMaxLimitEvent();
      }
    }
  }

  /**
   * Check if length is with the max limit
   */
  checkForMaxUsers(): boolean {
    if ((this.selectedList.length + (this.alreadyExistLength - ONE)) < this.maxLimitUsers) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Emit the max limit reached event.
   */
  emitMaxLimitEvent(): void {
    this.onMaxLimitEvent.emit(this.maxLimitUsers);
  }

  /**
   * check is selected or not.
   */
  isSelected(selectedData: { custom_id: string | number; name: string }): boolean {
    const selected = [...this.selectedList.filter(selected => (selected.custom_id) === (selectedData.custom_id))];
    return (selected.length > ZERO) ? true : false;
  }

  /**
   * Remove the selected suggestion.
   */
  remove(suggestion): void {
    this.selectedList = [...this.selectedList.filter(sugg => sugg.custom_id !== suggestion.custom_id)];
  }

  /**
   * Emit the selected list.
   */
  submit(): void {
    this.onSubmit.emit(this.selectedList);
  }

  /**
   * Scroll the selected users to left.
   */
  scrollLeft(): void {
    this.widgetsContent.nativeElement.scrollLeft -= SCROLL_OFFSET_SEARCH;
  }

  /**
   * Scroll the selected users to right.
   */
  scrollRight(): void {
    this.widgetsContent.nativeElement.scrollLeft += SCROLL_OFFSET_SEARCH;
  }

  /**
   * Track by for the message sequence.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  trackByCustomId(index: number, user): any {
    return user ? user.custom_id : undefined;
  }

  /**
   * Unsubscribe the subscription.
   * Increase z-index of header.
   */
  ngOnDestroy(): void {
    this.emitOnLeave();
    this.searchSubscription?.unsubscribe();
  }
}
