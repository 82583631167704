import { Pipe, PipeTransform } from '@angular/core';

/**
 * Constant imported.
 */
import { USER_TAG_REGX } from '../constants/app.constants';

/**
 * This pipe is used to find and replace the users object with tag .
 */
@Pipe({
  name: 'tagUser'
})
export class TagUserPipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transform(cmt: any): unknown {
    const paramsPattern = USER_TAG_REGX.removeBracesRegex;
    const extractParams = cmt.match(paramsPattern);
    if (extractParams) {
      extractParams.forEach(element => {
        const x = cmt.indexOf(element);
        if (x !== -1) {
          const username = element.split(':')[1];
          const customId = element.split(':')[0];
          const y = '<b><span class="tag-user tag-user__' + customId + '" >@' + username + '</span></b>';
          cmt = cmt.replace('{' + element + '}', y);
        }
      });
    }
    return cmt;
  }
}
