/**
 * Component imports.
 */
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

/**
 * Module imports.
 */
import { ModalModule } from 'ngx-bootstrap/modal';
import { PipesModule } from '../../pipes/pipes.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { DirectivesModule } from '../../directives/directives.module';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { SearchingModule } from '../searching/searching.module';
import { ProductGetSampleModule } from '../product-get-sample/product-get-sample.module';
import { UserProfilePicModule } from '../user-pofile-pic/user-profile-pic.module';
import { CustomTooltipModule } from '../custom-tooltip/custom-tooltip.module';
import { FilterPipeModule } from 'ngx-filter-pipe';

/**
 * Component imports.
 */
import { SpecialityComponent } from '../speciality/speciality.component';
import { HeaderComponent } from './header.component';
import { VerificationSuccessComponent } from '../verification-success/verification-success.component';
import { WalkthroughModule } from 'src/app/walkthrough/walkthrough.module';


@NgModule({
  declarations: [
    HeaderComponent,
    SpecialityComponent,
    VerificationSuccessComponent
  ],
  imports: [
    CommonModule,
    InfiniteScrollModule,
    DirectivesModule,
    PerfectScrollbarModule,
    SearchingModule,
    ProductGetSampleModule,
    UserProfilePicModule,
    CustomTooltipModule,
    PipesModule,
    ModalModule,
    FormsModule,
    FilterPipeModule,
    WalkthroughModule
  ],
  exports: [HeaderComponent]
})
export class AppHeaderModule { }
