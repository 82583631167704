/**
 * Angular imports.
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

/**
 * Module imports.
 */
import { PipesModule } from './../../pipes/pipes.module';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { DropDownModule } from '../drop-down/drop-down.module';

/**
 * Component imports.
 */
import { ProductGetSampleComponent } from './product-get-sample.component';



@NgModule({
  declarations: [
    ProductGetSampleComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    InfiniteScrollModule,
    ModalModule,
    BsDatepickerModule,
    AccordionModule,
    PipesModule,
    DropDownModule
  ],
  exports: [
    ProductGetSampleComponent
  ]
})
export class ProductGetSampleModule { }
