/**
 * Angular imports.
 */
import { Injectable } from '@angular/core';

/**
 * MultiLang Service
 */
@Injectable({
  providedIn: 'root'
})


export class SamvaadLangService {
  public languageText =
    {
      'samvaad': {
        'key1': 'You',
        'key2': 'Exchange messages and medias to a connection or a group.',
        'key3': 'Start a chat',
        'key4': 'Cancel',
        'key5': 'View Profile',
        'key6': 'Report User',
        'key7': 'View Group',
        'key8': 'Report Group',
        'key9': 'Suspend group',
        'key10': 'Online',
        'key11': 'typing...',
        'key12': 'Done',
        'key13': 'Update Group image',
        'key14': 'Chat',
        'key15': 'Chats',
        'key16': 'Search chat...',
        'key17': 'Type your message...',
        'key18': 'Send files',
        'key19': 'Upload Media',
        'key20': 'Select user',
        'key21': 'No Result Found',
        'key22': 'Success',
        'key23': 'You have no active group yet',
        'key24': 'Preview',
        'key25': 'Close',
        'key26': 'to',
        'key27': 'and',
        'key28': 'others',
        'key29': 'members',
        'key35': 'Upload',
        'key36': 'Type message...',
        'key37': 'files'
      },
      'samvaad_floating_button': {
        'key1': 'Start a personal chat',
        'key2': 'Start a group chat'
      },
      'samvaad_system_msg': {
        'key1': 'created this group',
        'key2': 'Removed',
        'key3': 'Added',
        'key4': 'left the group',
        'key5': 'changed the group image ',
        'key6': 'changed the group name from',
        'key7': 'You Have Blocked this Person',
        'key8': 'You can\'t send message to this group because you\'re no longer a participant',
        'key9': 'Sent you a post',
        'key10': 'You sent a post',
        'key11': 'Admin removed you from the group',
        'key12': 'You left the group',
        'key13': 'Group Successfully Created',
        'key14': 'Your connection created this group',
        'key15': 'You removed a member from the group',
        'key16': 'Admin removed a member from the group',
        'key17': 'You added member to the group',
        'key18': 'Admin added you in the group',
        'key19': 'Admin added a member into the group',
        'key20': 'A Member left this group',
        'key21': 'You updated the group icon',
        'key22': 'Admin updated the group icon',
        'key23': 'You updated the group name',
        'key24': 'Admin updated the group name'
      },
      'samvaad_chat_detail': {
        'key1': 'Invalid Content',
        'key2': 'Unblock chat ',
        'key3': 'Added',
        'key4': 'left the group',
        'key5': 'changed the group image ',
        'key6': 'changed group name to',
        'key7': 'You have blocked this user',
        'key8': 'You can no longer exchange messages',
        'key9': 'To chat, unblock this user',
        'key10': 'You can\'t send message to this group because you\'re no longer a participant',
        'key11': 'Say hi to ',
        'key12': 'hi'
      },
      'samvaad_block_unblock_modal': {
        'key1': 'Block this User?',
        'key2': 'Unblock this user?',
        'key3': 'If you block this user,',
        'key4': 'you can no longer exchange messages in the group.',
        'key5': 'If you unblock this user, you can exchange messages',
        'key6': 'Block chat',
        'key7': 'Unblock chat',
        'key8': 'Block chat',
        'key9': 'UnBlock chat'
      },
      'samvaad_mute_unmute_modal': {
        'key1': 'Mute notifications for this chat?',
        'key2': 'unMute notifications for this chat?',
        'key3': 'If you unmute this chat, you will be able to get notifications for new messages including attachments',
        'key4': 'If you mute this chat, you will no longer receive notifications for new messages.',
        'key5': 'Mute',
        'key6': 'Unmute',
        'key7': 'Mute notification',
        'key8': 'unMute notification'
      },
      'samvaad_exit_modal': {
        'key1': 'Are you sure want to exit the group?',
        'key2': 'If you exit this group, you can no longer exchange messages in the group.',
        'key3': 'Exit Group'
      },
      'samvaad_create_edit_group': {
        'key1': 'Change Group Image',
        'key2': 'Group name',
        'key3': 'Enter group name',
        'key4': 'Group members',
        'key5': 'Admin',
        'key6': 'Selected',
        'key7': 'Add Members',
        'key8': 'Add Members',
        'key9': 'Are you sure want to remove this person from group?',
        'key10': 'If you remove user from group, he will no longer able to send or receive message in group. ',
        'key11': 'Remove from Group',
        'key12': 'Update Group info',
        'key13': 'Group Information ',
        'key14': 'Create group chat',
        'key15': 'Groups',
        'key16': 'You have no active chat yet',
        'key17': 'Group Successfully Created',
        'key18': 'Group image changed Successfully',
        'key19': 'User removed Successfully',
        'key20': 'Group information updated Successfully',
        'key21': 'Create group chat',
        'key22': 'Max 30 characters',
        'key23': 'Name'
      },
      'samvaad_validation': {
        'key1': 'Please upload png / jpeg file upto 30 MB',
        'key2': 'Only png, jpeg are allowed',
        'key3': 'You can only select a maximum of 10 images at a time',
        'key4': 'Something went Wrong',
        'key5': 'User not in samvaad',
        'key6': 'Not Able to Update',
        'key7': 'Samvaad Can not initiate',
        'key8': 'This users are not part of samvaad',
        'key9': 'You can select maximun',
        'key10': 'Allow only'
      },
      'samvaad_tutorial': {
        'key1': 'New',
        'key2': 'Introducing Chat',
        'key3': 'Stay in touch, discuss and share content with your peers',
        'key4': 'Start a chat'
      },
      'samvaad_tooltips': {
        'key1': 'Minimize',
        'key2': 'Maximize',
        'key3': 'Close',
        'key4': 'Options',
        'key5': 'Back',
        'key6': 'Clear',
        'key7': 'Search',
        'key8': 'Expand',
        'key9': 'Start new chat',
        'key10': 'Emoji\'s',
        'key11': 'Send images',
        'key12': 'Send message',
        'key13': 'Add more images',
        'key14': 'Already a member, You can remove the user from the group list',
        'key15': 'Remove'
      }
    };
}
