import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpEventType } from '@angular/common/http';
import {FILE_UPLOAD_FILE_TYPE, MAX_UPLOAD_TRY, MIME_TYPES, MODULE_NAME } from '../constants/app.constants';
import { API } from '../constants/api.constants';
/**
 * Rxjs imports.
 */
import {Subscription, Subject, Observable } from 'rxjs';

/**
 * constant imports
 */
import { IMAGE_FILES_TYPE } from '../constants/app.constants';
import { map } from 'rxjs/operators';
import { fileUploadResponse, FileUploadStatus, FILE_TYPE, customErrorRes, Progress, MAX_SIZE_EACH_CHUNK } from '../interface/keeperResponseConstant/responseConst';

/**
 * Services imports.
 */
import { AuthService } from '../auth/auth.service';
import { PublicFileUploadKeeperService } from './public-file-upload-keeper.service';

@Injectable({
  providedIn: 'root'
})
export class FileUploadKeeperService {

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
    private publicFileUploadService: PublicFileUploadKeeperService,
  ) { }

  /**
   * Subscription for file upload time.
   */
  public fileUpoadProgessSubs = new Subject<any>();
  /**
   * Subscription for presigned url.
   */
  public presignedUrlSub!: Subscription;
  /**
   * Subscription for internal presigned url.
   */
  public presignedUrlInSub!: Subscription;

  public fileProgressStatusArray = new Map();

  /**
   * file upload progress status
   */
  public maxUploadChunkSize = MAX_SIZE_EACH_CHUNK;

  public maxAttempts = MAX_UPLOAD_TRY;


  public fileUploadData: Map<string, { progress: number, completeStatus, attempts }> = new Map<string, { progress: number, completeStatus, attempts }>();

  /**
   * file upload states
   */
  public fileUploadStatus = FileUploadStatus;


  /**
   * progress
   */
  public fileProgress = new Subject<[]>();



  public async uploadFileOnKeeper(file: File, fileName: string,
    module: string, country: string, accessLevel, fileType): Promise<any> {
    if (this.publicFileUploadService.isPublicRequest) {
      return await this.publicFileUploadService.uploadFileOnKeeper(file, fileName, module, country, accessLevel, fileType);
    }
    let result = { fileCode: '', signedUrl: '', status: false };
    const progressObject = { 'fileName': fileName, 'progress': 0 };

    return new Promise(async (resolve, reject) => {
      await this.uploadFileToKeeper(file, fileName, module, country, accessLevel, fileType).then(async res => {
        result.fileCode = res;
        result.status = true;
        await this.loadPreSignedUrl(res, module).then(res => {
          result.signedUrl = res
          resolve(result);
        },
          msg => {
            reject();
          }
        ).catch(error => {
          reject();
        });
      }, msg => {
        reject(msg);
      }).catch(
        error => {
        }
      )
    });
  }
  /**
   * global function to upload file on aws through keeper services
   * @param file
   * @param cloudFolderPath
   * @param fileName
   * @param module
   * @param country
   * @returns
   */
  public async uploadFileToKeeper(file: File, fileName: string,
    module: string, country: string, accessLevel: string, fileType: string): Promise<any> {
    const type = this.getContentType(file.type);
    let fileCode = '';
    let result: fileUploadResponse;
    if (file.size > MAX_SIZE_EACH_CHUNK) {
      return await this.fetchPresignedUrl(fileName, module, country, file.type, file.size, accessLevel, fileType).then(res => {
        return new Promise(async (resolve, reject) => {
          return await this.uploadServiceForLargeFile(file, fileName, module, country, res, file.size).then(
            async res => {
              resolve(res);
            },
            msg => {
              reject(msg);
            }
          ).catch(error => {
            reject(error);
          });
        })
      });
    }
    else {
      return await this.fetchPresignedUrl(fileName, module, country, file.type, file.size, accessLevel, fileType).then(async res => {
        return new Promise(async (resolve, reject) => {
          return await this.uploadServiceForSmallFile(file, res.item.fileCode, res.item.preSignedUrl, fileName, module, country, 1, file.size).then(async res => {
            resolve(res);
          },
          msg => {
            reject(msg);
          },
          ).catch(error => {
            reject(error);
          });
        });
      });
    }
  }

  /**
   * function to upload files not greater than 100mb
   * @param file
   * @param fileCode
   * @param cloudFolderPath
   * @param fileName
   * @param module
   * @param country
   * @param attempts
   * @returns
   */
  async uploadServiceForSmallFile(file: File, fileCode, cloudFolderPath: string, fileName: string,
    module: string, country: string, attempts: number, fileSize: number): Promise<string> {
    return new Promise<string>(async (resolve, reject) => {
      if (attempts <= this.maxAttempts) {
        await this.uploadFileToCloud(file, cloudFolderPath, 0, fileCode, 1, '', module, fileSize, fileName).then(async res => {
          resolve(fileCode)
        }, async error => {
          return await this.recreateSinglePresignedUrl(fileCode, module).then(async res => {
            resolve(await this.uploadServiceForSmallFile(file, fileCode, res.item.preSignedUrl, fileName, module, country, attempts + 1, fileSize))
          },
          ).catch(error => {
            reject(error);
          })
        }).catch(async error => {
          reject(error);
        })
      }
      else {
        reject();
      }
    });
  }

  /**
   * function to upload large files of size greater than 100mb in chunks
   * @param file
   * @param cloudFolderPath
   * @param fileName
   * @param module
   * @param country
   * @param putPresignedUrl
   * @returns
   */
  async uploadServiceForLargeFile(file: File, fileName: string,
    module: string, country: string, putPresignedUrl, fileSize): Promise<any> {
    const fileCode = putPresignedUrl.item.fileCode;
    const totalParts = parseInt(putPresignedUrl.pagination.totalItems);
    const totalPages = parseInt(putPresignedUrl.pagination.totalPages);
    this.fileUploadData.set(fileName, { progress: 0, completeStatus: this.fileUploadStatus.pending, attempts: 1 });
    return new Promise<any>(async (resolve, reject) => {
      await this.uploadEachFilePartToCloud(file, putPresignedUrl.item, module, 1, totalPages, totalParts, 0, 0, fileCode, fileName, fileSize)
        .then(res => {
          resolve(res);
        }, msg => {
          reject(msg)
        }).catch(error => {
          reject();
        });
    })
  }
  /**
   * upload file in chunks, on the basis of startbyte and endbyte
   * @param file
   * @param preSignedUrls
   */
  async uploadEachFilePartToCloud(file: File, preSignedUrls: any, module, pageNumber, totalPages, totalParts, startIndex, lastUploadedPart, fileCode, fileName, fileSize): Promise<any> {
    const totalPartsPerPage = preSignedUrls.createMultipartResponse.length;
    return new Promise<string | void>(async (resolve, reject) => {
      for (let index = startIndex; index < preSignedUrls.createMultipartResponse.length; index++) {
        if (this.fileUploadData.has(fileName) && (this.fileUploadData.get(fileName).completeStatus != this.fileUploadStatus.pending)) {
          this.fileUploadData.delete(fileCode);
          break;
        }
        if (this.fileUploadData.get(fileName).attempts > this.maxAttempts) {
          break;
        }
        let element = preSignedUrls.createMultipartResponse[index];
        let cloudFolderPath = element.preSignedUrl;
        await this.uploadFileToCloud(file.slice(Number(element.startByte), (Number(element.endByte))), cloudFolderPath, index + 1, fileCode, totalPartsPerPage, preSignedUrls.uploadId, module, fileSize, fileName)
          .then(
            async res => {
              lastUploadedPart = (pageNumber - 1) * 10 + index + 1;
              await this.updatePartStatus(fileCode, res, (pageNumber - 1) * 10 + index + 1).then(res => {
                this.fileUploadData.get(fileName).attempts = 1;
              },
                error => {
                  this.fileUploadData.get(fileName).completeStatus = this.fileUploadStatus.fail;
                  reject(error);
                }).catch(error => {
                  reject(error);
                  this.fileUploadData.get(fileName).completeStatus = this.fileUploadStatus.fail;
                });
              if (((pageNumber - 1) * 10 + index + 1) == totalParts) {
                return await this.updateMultipartCompleteStatus(fileCode).then(
                  res => {
                    if (this.fileUploadData.has(fileName)) {
                      this.fileUploadData.get(fileName).completeStatus = this.fileUploadStatus.completed;
                    }
                    resolve(fileCode);
                  },
                  error => {
                    this.fileUploadData.get(fileName).completeStatus = this.fileUploadStatus.fail;
                    reject(error);
                  }).catch(error => {
                    this.fileUploadData.get(fileName).completeStatus = this.fileUploadStatus.fail;
                    reject(error);
                  });
              }
              if (index + 1 == 10 && pageNumber < totalPages) {
                await this.recreateMultiPartPreSignedUrl(fileCode, module, pageNumber + 1).
                  then(
                    async res => {
                      return await this.uploadEachFilePartToCloud(file, res.item, module, pageNumber + 1, res.pagination.totalPages, totalParts, 0,
                        ((pageNumber - 1) * 10 + index + 1), fileCode, fileName, fileSize).then(res => {
                          resolve(fileCode);
                        }).catch(err => {
                          reject(err);
                        });
                    }, error => {
                      reject(error);
                    }).catch(error => {
                      reject(error);
                    })
              }
            }).catch(async error => {
              if (this.fileUploadData.has(fileName)) {
                this.fileUploadData.get(fileName).attempts = this.fileUploadData.get(fileName).attempts + 1;
              }
              if (this.fileUploadData.get(fileName).attempts <= this.maxAttempts) {
                await this.recreateMultiPartPreSignedUrl(fileCode, module, pageNumber).then(
                  async res => {
                    return await this.uploadEachFilePartToCloud(file, res.item, module, pageNumber, res.pagination.totalPages, totalParts, index, ((pageNumber - 1) * 10 + index), fileCode, fileName, fileSize).then(res => {
                      resolve(fileCode);
                    }, msg => {
                    })
                  }, error => {
                    reject(error);
                    this.fileUploadData.get(fileName).completeStatus = this.fileUploadStatus.fail;
                  })
              } else {
                reject(error);
              }
            })
      }
    })

  }
  /**
   * @param cloudFolderPath path of the you want to upload. eg prescription, id-card.
   * we don't have doctor cloudFolderPath for doctor document and doctor signature. So you need to
   * send 'null' in that case.
   * @param fileName name of the file.
   * @param module module of file. eg. pap, na
   * @param country country for upload. eg. philippines, india
   * @param isPut method for presigned url. for file view isPut=false, for uploading file isPut=true
   * @param contentType content type of file. eg. image/png
   * @param time upload file or view file time in milliseconds
   * @returns Promise with presigned url.
   */
  fetchPresignedUrl(fileName: string, module: string,
    country: string, contentType: string, fileSize, accessLevel: string, fileType: string): Promise<any> {
    const time = new Date().getMilliseconds();
    const body =
    {
      "accessLevel": accessLevel,
      "contentType": contentType,
      "country": "IND",
      "createdBy": "1233",
      "fileName": fileName,
      "fileSize": fileSize,
      "fileType": fileType,
      "module": module
    }
    return new Promise((resolve, reject) => {
      this.httpClient.post<any>(API.CREATE_FILE, body)
        .toPromise()
        .then(
          res => { // Success
            resolve(res)
          },

        ).catch((error: any) => {
          reject(error);
        });
    });
  }


  /**
   * @param file file name
   * cloudFolderPath cloud url wher to be uploaded
   * Uploading file to cloud from presigned url.
   */
  uploadFileToCloud(file: any, cloudFolderPath: string, partNumber: number, fileCode: string, totalParts: number, uploadId: string, module: string, fileSize: number, fileName: string): Promise<any> {
    const time = new Date().getMilliseconds();
    let etag = '';
    let headerResponse = { status: 0, statusText: '' };
    return new Promise((resolve, reject) => {
      this.httpClient.put<any>(cloudFolderPath, file, {
        reportProgress: true,
        observe: 'events',
      }).pipe(map(event => {
        if (event.type === HttpEventType.UploadProgress) {
          const progress = ((partNumber - 1) * this.maxUploadChunkSize + event.loaded) / fileSize;
          this.fileProgressStatusArray.set(fileName, progress);
          this.fileUpoadProgessSubs.next(this.fileProgressStatusArray);
        }
        if (event.type === HttpEventType.ResponseHeader) {
          headerResponse.status = event.status;
          headerResponse.statusText = event.statusText;
        }
        if (event.type === HttpEventType.Response) {
          etag = event.headers.get('etag')!.replace(/["']/g, "");
        }
      })).toPromise()
        .then(
          res => { // Success
            resolve(etag);
          },
        ).catch(error => {
          reject(headerResponse);
        });
    });
  }


  /**
   * read file api
   * fetching files signedUrl
   *  @param FileCode filecode to read
   *  @param module   module in which file is present
   */
  loadPreSignedUrl(FileCode, module): Promise<string> {
    if (this.publicFileUploadService.isPublicRequest) {
      return this.publicFileUploadService.loadPreSignedUrl(FileCode, module);
    }
    const endPoint = 'https://id.dev.docquity.com/api/v2/readFile'
    const params = { fileCode: FileCode, module: module }
    return new Promise((resolve, reject) => {
      this.httpClient.get<any>(API.READ_FILE, { params: params })
        .toPromise().then(
          res => { // Success
            if (res?.item?.preSignedUrl) {
              resolve(res.item.preSignedUrl);
            }
            else {
              reject(res);
            }
          },
          msg => { // Error
            reject(msg);
          }
        ).catch(error => {
        })
    });
  }
  /**
   * returns multiple signed urls
   * @param fileCodes
   * @param module
   * @returns
   */
  readMultipleFiles(fileCodes: string[], module): Promise<any> {
    if (this.publicFileUploadService.isPublicRequest) {
      return this.publicFileUploadService.readMultipleFiles(fileCodes, module);
    }
    return new Promise((resolve, reject) => {
      const params = { fileCodes }
      this.httpClient.post<any>(API.READ_FILES, params)
        .toPromise().then(
          res => { // Success
            if (res?.item?.preSignedUrl) {
              resolve({ preSignedUrl: res.item.preSignedUrl, fileDetails: res.item.fileDetails });
            }
            else {
              reject(res);
            }
          },
          msg => { // Error
            reject(msg);
          }
        ).catch(error => {
        })
    });
  }

  /**
   * complete file status of multipart api upload
   * @param fileCode
   */
  updateMultipartCompleteStatus(fileCode: string) {
    const endPoint = 'https://id.dev.docquity.com/api/v2/completeMultipart';
    const params =
    {
      "fileCode": fileCode
    }
    return new Promise((resolve, reject) => {
      this.httpClient.get<any>(API.COMPLETE_MULTIPART_STATUS, { params: params })
        .toPromise().then(
          res => { // Success
            resolve(res)
          },
          error => { // Error
            reject(error);
          }
        ).catch(error => {
          reject(error)
        })
    });
  }
  /**
   * recreate multiplartPresignedUrl
   */
  recreateMultiPartPreSignedUrl(fileCode: string, module: string, pageNumber): Promise<any> {
    const endPoint = 'https://id.dev.docquity.com/api/v2/recreateMultipartUrl';
    const body = { fileCode: fileCode, module: module, pageNumber };
    return new Promise((resolve, reject) => {
      this.httpClient.get<any>(API.RECREATE_MULTIPART_URL, { params: body })
        .toPromise().then(
          res => { // Success
            resolve(res)
          }
        ).catch(error => {
          reject(error)
        })
    });
  }
  /**
   *
   * @param fileCode
   * @param module
   * @param pageNumber
   * @returns
   */
  recreateSinglePresignedUrl(fileCode: string, module: string): Promise<any> {
    const endPoint = 'https://id.dev.docquity.com/api/v2/recreateUrl';
    const body = { fileCode: fileCode, module: module };
    return new Promise((resolve, reject) => {
      this.httpClient.get<any>(API.RECREATE_SINGLE_URL, { params: body })
        .toPromise().then(
          res => { // Success
            resolve(res)
          },
        ).catch(error => {
          reject(error);
        })
    });
  }

  /**
   * update status of each file upload
   * @param fileCode
   * @param etag
   * @param partNumber
   * @returns
   */
  updatePartStatus(fileCode: string, etag: string, partNumber: number): Promise<any> {
    const endPoint = 'https://id.dev.docquity.com/api/v2/updatePartUploadStatus';
    const body =
    {
      "fileCode": fileCode,
      "etag": etag,
      "partNumber": partNumber
    }
    return new Promise((resolve, reject) => {
      this.httpClient.put<any>(API.UPDATE_PART_STATUS, body)
        .toPromise().then(
          res => { // Success
            resolve(res)
          },
        ).catch(error => {
          reject(error)
        })
    });

  }

  /**
  * function to get content type of file
  */
  getContentType(extension: string): string {
    let contentType = '';
    switch (extension?.toLowerCase()) {
      case IMAGE_FILES_TYPE.JPEG:
        contentType = IMAGE_FILES_TYPE.JPEG;
        break;
      case IMAGE_FILES_TYPE.JPG:
        contentType = IMAGE_FILES_TYPE.JPG;
        break;
      case IMAGE_FILES_TYPE.PNG:
        contentType = IMAGE_FILES_TYPE.PNG;
        break;
      default: break;
    }
    return contentType;
  }

  /**
   * function to get valid file name
   */
  getValidFileName(fileName: string): string {
    fileName = fileName.replace(/[^a-zA-Z^0-9.]+/g, '-');
    return fileName;
  }

  getValidFileType(fileType: string): boolean {
    if (FILE_TYPE.ACCEPTED_FILE.indexOf(fileType) !== -1) {
      return true;
    }
    else {
      return false;
    }
  }
  /**
   *
   * @param dataURL data URL to blob
   * @returns
   */
  dataURLtoBlob(dataURI): Blob {
    const binary = atob(dataURI.split(',')[1]);
    const array = [];
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], {
      type: 'image/jpg'
    });

  }
  /**
   *
   * @param dataurl
   * @param fileName
   * @param format
   * @returns
   * convert data url directly to file
   */
  dataUrlToFile(dataurl: any, fileName: string): File {
    const imageBlob = this.dataURLtoBlob(dataurl);
    const imageFile = new File([imageBlob], fileName, { type: 'image/png' });
    return imageFile;
  }
  async getFileFromUrl(url, name, defaultType = 'image/jpeg') {
    const response = await fetch(url, {
      mode: 'cors',
    });
    const data = await response.blob();
    return new File([data], name, {
      type: data.type || defaultType,
    });
  }

  deleteFile(fileCode: string, module): Promise<any> {
    if (this.publicFileUploadService.isPublicRequest) {
      return this.publicFileUploadService.deleteFile(fileCode, module);
    }
    return new Promise((resolve, reject) => {
      const params = { fileCode, module, userId: this.authService.custom_id };
      this.httpClient.delete<any>(API.DELETE_FILE, { params: params })
        .toPromise().then(
          res => { // Success
            console.log('res', res);
            if (res?.success) {
              resolve(res);
            }
            else {
              reject(res);
            }
          },
          msg => { // Error
            reject(msg);
          }
        ).catch(error => {
        })
    });
  }

  multipleFileDelete(fileCodes: string[], module: string): void {
    Promise.all(
      fileCodes.map((string) => {
        return this.deleteFile(string, module);
      })
    )
      .then((results) => {
        console.log(results);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  
  async getImage(url): Promise<string> {
    return await fetch(url, {
       mode: 'cors',
     })
   .then(response => response.blob())
   .then(blob => {
     const url = URL.createObjectURL(blob);
     return url;
   });
   }
    toDataUrl(url, callback) {
     const xhr = new XMLHttpRequest();
     xhr.onload = function() {
         const reader = new FileReader();
         reader.onloadend = function() {
             callback(reader.result);
         };
         reader.readAsDataURL(xhr.response);
     };
     xhr.open('GET', url);
     xhr.responseType = 'blob';
     xhr.send();
 }
   imageUrlToBase64(urL: string) {
     return this.httpClient.get(urL, {
         observe: 'body',
         responseType: 'arraybuffer',
       })
       .pipe(
         map((arrayBuffer) =>
           btoa(
             Array.from(new Uint8Array(arrayBuffer))
             .map((b) => String.fromCharCode(b))
             .join('')
           )
         ),
       );
   }

  /**
   * set file type wrt content type and access level
   */
  public setFileType(contentType: string, accessLevel: string): string {
    if (contentType === MIME_TYPES.MP4 || contentType === MIME_TYPES.WEBM || contentType.indexOf('video')) {
      return FILE_UPLOAD_FILE_TYPE.VIDEO;
    } else if (accessLevel === MODULE_NAME.PUBLIC) {
      return FILE_UPLOAD_FILE_TYPE.LOGO;
    } else {
      return FILE_UPLOAD_FILE_TYPE.IDCARD;
    }
  }
}
