<div *ngIf="errorCode !== 0">
    <div class="channel d-block channel__error-background text-center"
        [ngClass]="{'channel__mini-player-height': isMiniPlayerVisible}">
        <div class="d-table w-100 h-100 table-layout-fixed">
            <div class="d-table-cell w-100 h-100 align-middle">
                <img src="assets/images/error-icon.svg" width="110px">
                <p class="paragraph ml-1">
                    {{errorMsg}}
                </p>
                <button *ngIf="errorCode === 101" class="btn-design small outline secondary-paragraph mt-3"
                    (click)="reloadPage()">Reload</button>
            </div>
        </div>
    </div>
</div>
<div *ngIf="errorCode === 0">
    <div *ngIf="channelJoined; then thenBlock else elseBlock"></div>
</div>
<ng-template #thenBlock>
    <div #channelContainer id="channel__fullscreen" class="channel position-relative"
        [ngClass]="{'channel__mini-player-height': isMiniPlayerVisible}" (mousemove)="onMouseMove($event)">
        <!-- live count -->
        <div *ngIf="liveUserCount!=''"
            class="position-absolute channel-live-count text-white fnt-size12px bg-black-50 rounded">
            <div class="rounded-left pt-1 pb-1 pl-2 pr-2 d-inline-block text-white main-red-bg-color">
                <div class="user-5 rounded-circle bg-white mr-1 d-inline-block"></div>Live
            </div>
            <div class="rounded-right pt-1 pb-1 pl-2 pr-2 d-inline-block">
                <svg width="11px" viewBox="0 0 10.18 10.18">
                    <defs>
                        <style>
                            .channel__live-eye {
                                fill: #fff
                            }
                        </style>
                    </defs>
                    <g id="prefix__Group_1900" data-name="Group 1900">
                        <g id="prefix__Group_1881" data-name="Group 1881">
                            <path id="prefix__Rectangle_432" fill="none" d="M0 0h10.18v10.18H0z"
                                data-name="Rectangle 432" />
                            <g id="prefix__Group_1901" data-name="Group 1901" transform="translate(0 2)">
                                <path id="prefix__Subtraction_1"
                                    d="M4.886 7.107A5.081 5.081 0 0 1 1.277 5.33 8.519 8.519 0 0 1 0 3.554a8.531 8.531 0 0 1 1.277-1.778A5.08 5.08 0 0 1 4.886 0a5.08 5.08 0 0 1 3.609 1.776 8.5 8.5 0 0 1 1.277 1.777 8.526 8.526 0 0 1-1.277 1.776 5.08 5.08 0 0 1-3.609 1.778zm0-5.44a1.887 1.887 0 1 0 1.887 1.887 1.887 1.887 0 0 0-1.887-1.888z"
                                    class="channel__live-eye" data-name="Subtraction 1" />
                                <path id="prefix__Path_5292"
                                    d="M16.166 14.832a1.332 1.332 0 1 1-1.333-1.332 1.332 1.332 0 0 1 1.333 1.332z"
                                    class="channel__live-eye" data-name="Path 5292"
                                    transform="translate(-9.947 -11.279)" />
                            </g>
                        </g>
                    </g>
                </svg>
                {{liveUserCount}}
            </div>
        </div>
        <!-- end live count -->
        <div class="channel__content_container">
            <div class="channel__logo_stream"
                [style.background-image]="'linear-gradient(to bottom, ' + sponsorsColorCode.headerColor + ', ' + sponsorsColorCode.footerColor + ')'"
                [ngClass]="screenShareEnable ? 'channel__logo_stream--with_share_screen' : 'channel__logo_stream--without_share_screen'">
                <div class="channel__logo text-center pl-2 pr-2"
                    [ngClass]="screenShareEnable ? 'channel__logo--with_share_screen channel__sponsor-logo-max-height' : 'channel__logo--without_share_screen'"
                    *ngIf="logo?.length > 0">
                    <app-sponsor [sponsorsLogo]="logo" (sponsorLogoAction)="sponsorLogoAction($event)"></app-sponsor>
                </div>
                <div class="channel__stream_list p-2 justify-content-center"
                    [ngClass]="screenShareEnable ? 'channel__stream_list--with_share_screen align-content-end channel__stream-share-min-height' : 'channel__stream_list--without_share_screen align-items-center flex-wrap py-5'">

                    <lib-stream attr.id="stream_{{item.key}}"
                        *ngFor="let item of (remoteStreams| withoutScreenShare) | keyvalue; trackBy:trackMe"
                        class="remotestream channel__stream bg-very-dark-grey position-relative" [ngClass]="{
                              'channel__stream--with_share_screen m-1': screenShareEnable == true,
                              'channel__stream--without_share_screen m-1': screenShareEnable == false,
                              'inner-outline': item.value.speaking == true && !item.value.isObsStreaming,
                              'channel__stream--presenter': item.value.presenting == true,
                              'stream_1':(((remoteStreams | keyvalue)?.length ) == (1) && screenShareEnable == false),
                              'stream_2': (((remoteStreams | keyvalue)?.length) > (1) && screenShareEnable == false),
                              'stream_3': (((remoteStreams | keyvalue)?.length) > (4) && screenShareEnable == false),
                              'stream_4': (((remoteStreams | keyvalue)?.length) > (6) && screenShareEnable == false)}"
                        [audioMuted]="item.value.audioMuted" [videoMuted]="item.value.videoMuted"
                        [channelJoined]="channelJoined" [remoteStream]="item.value.stream" [volumeLevel]="volumeLevel"
                        [name]="item.value.name" [initials]="item.value.initials" [screenShare]="item.value.screenShare"
                        [presenting]="item.value.presenting" [screenShareEnable]="screenShareEnable"
                        [streamId]="item.value.id" [isObsStreaming]="item.value.isObsStreaming">
                    </lib-stream>

                    <ng-content></ng-content>

                </div>
            </div>
            <div *ngIf="screenShareEnable" class="remoteStream channel__share_screen p-2 d-flex">
                <lib-stream attr.id="stream_{{item.key}}"
                    *ngFor="let item of (remoteStreams | screenShare) | keyvalue; trackBy:trackMe"
                    class="channel__stream channel__logo--with_share_screen bg-very-dark-grey position-relative"
                    [audioMuted]="item.value.audioMuted" [videoMuted]="item.value.videoMuted"
                    [channelJoined]="channelJoined" [remoteStream]="item.value.stream" [volumeLevel]="volumeLevel"
                    [name]="item.value.name" [initials]="item.value.initials" [screenShare]="item.value.screenShare"
                    [screenShareEnable]="screenShareEnable" [streamId]="item.value.id"
                    [isObsStreaming]="item.value.isObsStreaming">
                </lib-stream>

            </div>
        </div>

        <div *ngIf="!isMiniPlayerVisible" id="videoControls" class="position-absolute pl-2 pr-2 player-controls w-100">
            <!-- bottom bar -->

            <!-- controls -->
            <div class="row pt-1 pb-1">
                <div class="col-6 text-left">
                    <div class="d-flex">
                        <!-- pause -->
                        <!-- volume -->
                        <span id="setmutevolume" class="cursor-pointer mr-3" (click)="setVolumeMute()"
                            *ngIf="volumeLevel != 0">
                            <svg width="20" height="20" viewBox="0 0 31 30">
                                <g id="prefix__Group_2847" data-name="Group 2847" transform="translate(-.083 -.491)">
                                    <path id="prefix__Rectangle_850" fill="none" d="M0 0H31V30H0z"
                                        data-name="Rectangle 850" transform="translate(.083 .491)" />
                                    <path id="prefix__Icon_awesome-volume-up" fill="#fff"
                                        d="M10.362 3.778L6.075 8.064H1.157A1.156 1.156 0 0 0 0 9.221v6.939a1.157 1.157 0 0 0 1.157 1.157h4.918l4.287 4.283a1.157 1.157 0 0 0 1.974-.818V4.6a1.157 1.157 0 0 0-1.974-.822zm11.244-2.462a1.166 1.166 0 1 0-1.28 1.95 11.259 11.259 0 0 1 0 18.85 1.166 1.166 0 1 0 1.28 1.949 13.592 13.592 0 0 0 0-22.749zm1.525 11.374A8.9 8.9 0 0 0 19 5.161a1.152 1.152 0 0 0-1.6.359 1.17 1.17 0 0 0 .357 1.608 6.586 6.586 0 0 1 0 11.124 1.17 1.17 0 0 0-.357 1.608 1.154 1.154 0 0 0 1.6.359 8.9 8.9 0 0 0 4.131-7.529zM16.3 8.986a1.157 1.157 0 1 0-1.116 2.027 1.9 1.9 0 0 1 0 3.355 1.157 1.157 0 0 0 1.116 2.027 4.213 4.213 0 0 0 0-7.409z"
                                        data-name="Icon awesome-volume-up" transform="translate(2.597 2.327)" />
                                </g>
                            </svg>
                        </span>
                        <span class="cursor-pointer mr-3" (click)="setVolumeUnmute()" *ngIf="volumeLevel == 0">
                            <svg width="20" height="20" viewBox="0 0 24 24">
                                <path id="Icon_awesome-volume-mute" data-name="Icon awesome-volume-mute" fill="#fff"
                                    d="M11,5.17,7.66,8.45H3.89A.89.89,0,0,0,3,9.34H3v5.32a.89.89,0,0,0,.89.89H7.66L11,18.83a.88.88,0,0,0,1.25,0,.85.85,0,0,0,.26-.62V5.79a.88.88,0,0,0-.89-.88A.85.85,0,0,0,11,5.17Z" />
                                <path fill="#fff"
                                    d="M15.74,14.85a.73.73,0,0,1-.52-.21.72.72,0,0,1,0-1l4.24-4.25a.73.73,0,1,1,1,1l-4.24,4.24A.73.73,0,0,1,15.74,14.85Z" />
                                <path fill="#fff"
                                    d="M20,14.85a.73.73,0,0,1-.52-.21L15.22,10.4a.73.73,0,1,1,1-1l4.24,4.25a.72.72,0,0,1,0,1A.73.73,0,0,1,20,14.85Z" />
                            </svg>
                        </span>
                        <!-- volume -->
                        <span class="align-self-center"><input type="range" min="0" max="100" [(ngModel)]="volumeLevel"
                                class="slider channel__volume-slider mt-1" id="myRange"></span>
                    </div>
                </div>
                <div class="col-6 text-right">
                    <span *ngIf="!isMiniPlayerVisible && isPipEnabled" class="cursor-pointer pr-3 position-relative"
                        (click)="requestMiniPlayer()">
                        <img src="assets/images/pip.svg" alt="PIP" [width]="fullscreenDisabled ? 28 : 21">
                        <img *ngIf="showNewTag === showNewTagConstant.PIP" class="webinar-new-tag"
                            src="assets/images/profile-explore/new_tag.gif" alt="new">
                    </span>
                    <span *ngIf="fullscreenDisabled == true && showLivePollButton"
                        class="cursor-pointer mr-3 progress-icon" [ngClass]="hasLivePoll ? 'bg-white':''">
                        <img *ngIf="hasLivePoll" src="assets/images/live-poll/progress.svg" class="live-poll"
                            (click)="openLivePollPopupEvent($event)" alt="progress" />
                        <img *ngIf="!hasLivePoll" src="assets/images/live-poll/ended-poll-full-screen.svg"
                            (click)="openLivePollPopupEvent($event)" alt="ended-poll" />
                        <span *ngIf="hasLivePoll">
                            <img class="agora-live-circle" src="assets/images/live-poll/pulse.gif" alt="pulse" />
                        </span>
                    </span>
                    <span *ngIf="fullscreenDisabled == true" class="cursor-pointer mr-3">
                        <img *ngFor="let fullscreencontrols of controls" [src]=fullscreencontrols?.url
                            (click)="eventsTrigger(fullscreencontrols)">
                    </span>
                    <span class="cursor-pointer" (click)="fullscreenMode()">
                        <svg width="20" height="20" viewBox="0 0 20 20" *ngIf="fullscreenDisabled == false">
                            <defs>
                                <style>
                                    .player-controls__fullscreen {
                                        fill: none;
                                        stroke: #fff;
                                        stroke-linecap: round;
                                        stroke-linejoin: round;
                                        stroke-width: 2px
                                    }
                                </style>
                            </defs>
                            <g id="prefix__Group_2340" data-name="Group 2340" transform="translate(-786 -421)">
                                <path id="prefix__Rectangle_850" fill="none" d="M0 0H20V20H0z" data-name="Rectangle 850"
                                    transform="translate(786 421)" />
                                <g id="prefix__Group_2341" data-name="Group 2341"
                                    transform="translate(789.246 424.246)">
                                    <path id="prefix__Path_5288" d="M447.7 308.017v-4.347h4.347"
                                        class="player-controls__fullscreen" data-name="Path 5288"
                                        transform="translate(-447.702 -303.67)" />
                                    <path id="prefix__Path_5289" d="M462.049 313.67v4.347H457.7"
                                        class="player-controls__fullscreen" data-name="Path 5289"
                                        transform="translate(-449.503 -305.471)" />
                                    <path id="prefix__Path_5290" d="M452.049 318.017H447.7v-4.347"
                                        class="player-controls__fullscreen" data-name="Path 5290"
                                        transform="translate(-447.702 -305.471)" />
                                    <path id="prefix__Path_5291" d="M457.7 303.67h4.347v4.347"
                                        class="player-controls__fullscreen" data-name="Path 5291"
                                        transform="translate(-449.503 -303.67)" />
                                </g>
                            </g>
                        </svg>
                        <svg width="31" height="30" viewBox="0 0 31 30" *ngIf="fullscreenDisabled == true">
                            <defs>
                                <style>
                                    .player-controls__fullscreen_exit {
                                        fill: none;
                                        stroke: #fff;
                                        stroke-linecap: round;
                                        stroke-linejoin: round;
                                        stroke-width: 4px
                                    }
                                </style>
                            </defs>
                            <g id="prefix__Group_2845" data-name="Group 2845" transform="translate(.354 -.491)">
                                <path id="prefix__Rectangle_850" fill="none" d="M0 0H31V30H0z" data-name="Rectangle 850"
                                    transform="translate(-.354 .491)" />
                                <g id="prefix__Group_2849" data-name="Group 2849" transform="translate(3.646 3.491)">
                                    <path id="prefix__Path_5288" d="M0 8.238V0h8.238"
                                        class="player-controls__fullscreen_exit" data-name="Path 5288"
                                        transform="rotate(180 4.119 4.119)" />
                                    <path id="prefix__Path_5289" d="M8.238 0v8.238H0"
                                        class="player-controls__fullscreen_exit" data-name="Path 5289"
                                        transform="rotate(180 11.888 11.889)" />
                                    <path id="prefix__Path_5290" d="M8.238 8.238H0V0"
                                        class="player-controls__fullscreen_exit" data-name="Path 5290"
                                        transform="rotate(180 4.119 11.889)" />
                                    <path id="prefix__Path_5291" d="M0 0h8.238v8.238"
                                        class="player-controls__fullscreen_exit" data-name="Path 5291"
                                        transform="rotate(180 11.888 4.119)" />
                                </g>
                            </g>
                        </svg>
                    </span>

                </div>
            </div>
            <!-- </div> -->
            <!-- <button type="button">Join</button> -->

        </div>

        <div *ngIf="ticker.length > 0" class="bg-very-dark-grey text-white position-absolute w-100 bottom-0 px-2">
            <app-ticker [ticker]="ticker"></app-ticker>
        </div>
    </div>
</ng-template>
<ng-template #elseBlock>
    <div class="channel">
        <div class="channel__thumbnail">
            <img class="w-100 h-100" *ngIf="thumbnailImage" [src]="thumbnailImage"
                onerror="this.src='assets/images/agora/default_webinar.png'">
        </div>
        <div class="channel_controls text-center" (click)="joinChannel()">
            <!-- <button type="button" class="btn btn-primary" (click)="joinChannel()">Join</button> -->
            <div class="d-table w-100 h-100 table-layout-fixed">
                <div class="d-table-cell align-middle">
                    <span class="cursor-pointer">
                        <svg width="50" height="50" viewBox="0 0 20 20">
                            <g id="prefix__Group_2803" data-name="Group 2803" transform="translate(-785.6 -420.6)">
                                <path id="prefix__Rectangle_850" fill="none" d="M0 0H20V20H0z" data-name="Rectangle 850"
                                    transform="translate(785.6 420.6)" />
                                <path id="prefix__Icon_awesome-play" fill="#fff"
                                    d="M13.9 7.033L2.371.217A1.563 1.563 0 0 0 0 1.57V15.2a1.571 1.571 0 0 0 2.371 1.353L13.9 9.739a1.571 1.571 0 0 0 0-2.705z"
                                    data-name="Icon awesome-play" transform="translate(788.6 422.598)" />
                            </g>
                        </svg>
                    </span>
                </div>
            </div>
        </div>
    </div>
</ng-template>