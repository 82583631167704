/**
 * Angular imports.
 */
import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';

/**
 * Service imports.
 */
import { MultiLangService } from '../../dataservices/master-service/multi-lang.service';
import { MasterserviceService } from '../../dataservices/master-service/masterservice.service';

/**
 * InfoModal Component
 */
@Component({
  selector: 'app-info-modal',
  templateUrl: './info-modal.component.html',
  styleUrls: ['./info-modal.component.scss']
})
export class InfoModalComponent implements OnChanges {
  @Output() resetInfoModal = new EventEmitter();
  @Input() isInfoModalShown: boolean;
  @Input() info_msg: string;

  /**
   * Necessary instances
   */
  constructor(
    public mls: MultiLangService,
    public masterService: MasterserviceService
  ) { }

  /**
   * ngOnChanges
   */
  ngOnChanges(): void {

    if (this.isInfoModalShown == true) {
      if (this.info_msg == '' || this.info_msg == null) {
        this.info_msg = 'Oops Something went wrong';
      }
      this.showModal();
    }
  }

  /**
   * reset Info Msg Modal
   */
  resetInfoMsgModal(): void {
    this.resetInfoModal.emit(false);
    document.querySelector('header')?.classList?.remove('header-popup-index');
  }

  /**
   * show Modal
   */
  showModal(): void {
    this.isInfoModalShown = true;
    document.querySelector('header')?.classList?.add('header-popup-index');
  }

  /**
   * hide Modal
   */
  hideModal(): void {
    this.isInfoModalShown = false;
    this.resetInfoMsgModal();
    document.querySelector('header')?.classList?.remove('header-popup-index');
  }

  /**
   * onHidden
   */
  onHidden(): void {
    this.isInfoModalShown = false;
    this.resetInfoMsgModal();
    document.querySelector('header')?.classList?.remove('header-popup-index');
  }

}
