
/**
 * Angular imports.
 */
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AdditionalHeadersInterceptor } from './additional-headers.interceptor';

/**
 * Interceptor imports.
 */
import { AuthInterceptor } from './auth.interceptor';
import { BaseUrlInterceptor } from './base-url.interceptor';
import { ServerErrorInterceptor } from './server-error.interceptor';
import { VersionHeadersInterceptor } from './version-headers.interceptor';
import { AccessTokenHeaderInterceptor } from './access-token-header.interceptor';
import { AccessTokenAdditionalHeadersInterceptor } from './access-token-additional-headers.interceptor';

/**
 * Used to combine the interceptors.
 */
export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: VersionHeadersInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: AdditionalHeadersInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: AccessTokenHeaderInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: AccessTokenAdditionalHeadersInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: BaseUrlInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ServerErrorInterceptor, multi: true },
  // { provide: ErrorHandler, useClass: GlobalErrorHandler },
];
