<div class="row">
  <div class="col-md-5 p-0">
    <app-left-section></app-left-section>
  </div>
  <div class="col-md-7 p-0">
    <div class="position-relative px-3 px-sm-4 py-sm-5 pb-3 pt-5">
      <a class="back-page position-absolute" (click)="back_screen()"
        [appOnboardingEventTracker]='{"category":mls.googleEventText.otp_screen.category,"action":mls.googleEventText.otp_screen.key4,"product_type":"", "product_id":""}'>
        <img src="assets/themes/icons/back-arrow.svg" width="20px" />
      </a>
      <h5 class="mb-3 heading-3"><span class="color-deep-green">{{mls?.languageText?.otp_screen.key1}}<span
          *ngIf="mls?.is_lskey_display==1">(otp_screen.key1)</span></span></h5>
      <p class="paragraph"><span class="color-grey-700">{{req_data?.msg}}<!--Enter the OTP recieved by SMS --></span></p>
      <!-- form -->
      <form>
        <p class="mt-3 paragraph"> {{user_mob_data?.country_code}} {{user_mob_data?.mobile_no}} - <a
            style="cursor:pointer;" class="paragraph" (click)="navigateToMobInput()"
            [appOnboardingEventTracker]='{"category":mls.googleEventText.otp_screen.category,"action":mls.googleEventText.otp_screen.key1,"product_type":"", "product_id":""}'>
            <span class=" color-blue">{{mls?.languageText.otp_screen?.key2}}
            <span *ngIf="mls?.is_lskey_display==1">(otp_screen.key2)</span></span></a></p>
        <div class="my-3 row">
          <label class="text-danger secondary-paragraph">{{err_msg}}</label>
          <div class="col-12">
            <app-otp-input [(isResend)]="isResend" id="verifyOtp" (onInputChange)="password = $event; setFocus2($event)"></app-otp-input>
          </div>
        </div>
        <p class="mb-4 primary-small"><span class="text-light-grey"
            [ngClass]="{'cursor-pointer secondary-paragraph color-blue':timeLeft<=0}" ngHcaptchaInvisibleButton
            (verify)="onVerify($event, testResend)"
            [appOnboardingEventTracker]='{"category":mls.googleEventText.otp_screen.category,"action":mls.googleEventText.otp_screen.key2,"product_type":"", "product_id":""}'>
            <span [ngClass]="{'color-blue':timeLeft<=0}">{{mls?.languageText?.otp_screen?.key4}}
            <span *ngIf="mls?.is_lskey_display==1">(otp_screen.key4)</span></span> <span class="ml-1"
            *ngIf="timeLeft>0">{{timeLeft}}</span></span>
        </p>

        <div class="form-input error mb-2" *ngIf="api_err_msg!=''" >
          <p class="help-text">{{api_err_msg}}</p>
        </div>

        <button class="btn-design w-100" [disabled]="showColor==false" type="submit" [ngClass]="[showColor ? 'filled' : 'filled-disabled',fcs.device_detail.is_mobile ? 'medium' : 'large']"
          id="requestOtpClick" (click)="verifyMobile()" [appOnboardingEventTracker]='{"category":mls.googleEventText.otp_screen.category,"action":mls.googleEventText.otp_screen.key3,"product_type":"", "product_id":""}'>
        <span *ngIf="api_in_process==true" class="spinner-border spinner-border-sm" role="status"
            aria-hidden="true">
        </span>
          {{mls?.languageText?.otp_screen?.key5}}
          <span *ngIf="mls?.is_lskey_display==1">
            (otp_screen.key5)
          </span>
        </button>
      </form>
      <!-- end form -->
    </div>
  </div>
</div>
