import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FileUploadKeeperService } from '../file-upload-keeper.service';
import { UploadfileWithForkService } from '../uploadfile-with-fork.service';

/**
 * rxjs imports
 */
import { BehaviorSubject, Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class UserStoryService {

  create_Story = new Subject();
  create_story_productImage: string;


  public UserStoryDetail : Map<string, any> = new Map<string, {value: any, expired: boolean}>();

  public storyThumbnail: any[] = [];

  public storyDataChanged = new Subject<number>();

  public currentStoryUuid;

  public currentStoryIndex;

  public deleteStoryProcess;

  public storyLoadingState: Map<string, string> = new Map<string, string>();

  public fileCodeUrlMap : Map<string, string> = new Map<string, string>();

  public repostStoryData: any;

  public user_story_created = new Subject<any>();

  public isStoryTutorialEnabled = new Subject<any>();

  public storyTagList = [];

  public endAnimation = new Subject<boolean>();

  public longPressEvent = new Subject<any>();

  private loadedImages: { [url: string]: boolean } = {};



  public creation_view_update = new Subject<number>();

  public StoryshowLoader =  new BehaviorSubject<boolean>(false);


  /**
   * validate url function
   */
  validateUrl(url: any): boolean {
    try {
      new URL(url);
      return true;
    } catch (err) {
      return false;
    }
  }

  /**
   * function to handle updates of story lists and details
   * @param uuid
   * @param data
   */
  public handleStoryDataState(uuid: any, data: any): void {
    if (this.UserStoryDetail.has(uuid) && this.UserStoryDetail.get(uuid).value) {
      data.viewed =  0,
      this.UserStoryDetail.get(uuid).value.list.push(data);
    }
    this.storyDataChanged.next(2);
    this.user_story_created.next(1);
    this.navigateToHome();
    this.navigateToTop();
  }
  /**
   * function to map file code with its corresponding ulr
   * @param data
   * @param keyName
   */
  public async mapFileCodeWithUrl(data: [any], keyName: string): Promise<void> {
    const outerLoop = data.length % 10 === 0 ? data.length / 10 : Math.floor(data.length / 10) + 1;
    for (let i = 0; i < outerLoop; i++) {
      const fileCodes = [];
      for (let j = i * 10; j < i * 10 + 10 && j < data.length; j++) {
        if (data[j][keyName]) {
          if (data[j][keyName]?.indexOf('http') !== -1) {
            //  no code
          }
          else {
            fileCodes.push(data[j][keyName]);
          }
        }
      }
      if (fileCodes?.length > 0) {
        await this.fileUpload.readMultipleFiles(fileCodes, 'DQ').then(res => {
          for (const key in res) {
            this.fileCodeUrlMap.set(key, res[key]);
          }
          this.storyDataChanged.next(3);
        }).catch(error => {
          //  no code
        });
      }
    }
  }


  preloadImages(imageUrls: string[]): Promise<void> {
    const preloadPromises: Promise<void>[] = [];
    for (const imageUrl of (imageUrls)) {
      if (!this.loadedImages[imageUrl]) {
        const preloadPromise = new Promise<void>((resolve, reject) => {
          const img = new Image();
          img.src = imageUrl;
          if (imageUrl) {
            img.onload = () => {
              this.loadedImages[imageUrl] = true;
              resolve();
            };
            img.onerror = () => reject(`Failed to preload image: ${imageUrl}`);
          }
        });
        preloadPromises.push(preloadPromise);

      }
    }
    return;
  }


  /**
   *
   */
  navigateToTop(): void {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }
  /**
   * navigate to grand round
   */
  navigateToHome(): void {
    this.router.navigateByUrl('/web/list').catch(e => {
      // no code
    });
  }
  /**
   *
   * @param key
   * @returns is all story of that key is viewed
   */
  public isAllStoryViewed(key): boolean {
    if (this.UserStoryDetail.has(key) && this.UserStoryDetail.get(key).value) {
      const list = this.UserStoryDetail.get(key).value.list;
      if (list?.length > 0) {
        const index = list?.findLastIndex(el => el?.viewed === 1);
        if (index === list.length - 1) {
          return true;
        }
      }
    }
    return false;
  }

  getStoryIndexForStoryCode(storyCode: string, list: any): any {
    const value = list?.findIndex(el => el?.storyCode === storyCode);
    return value >= 0 ? value : 0;
  }

  public resetStoryView(): void {
    this.currentStoryUuid = '';
    this.currentStoryIndex = null;
    this.activatedRoute.queryParams
    .subscribe(params => {
      if (params.storySafeUrl) {
        this.router.navigate([], {
          queryParams: {
            'storySafeUrl': null,
            'storyCode': null,
            'viewerList': null
          },
          queryParamsHandling: 'merge'
        });
      }
    });
    for (const item of this.UserStoryDetail) {
      this.UserStoryDetail.set(item[0], { value: item[1].value, expired: true });
    }
  }
  /**
   * 
   * @param videoUrl fetch video as blob
   * @returns 
   */
  async  fetchVideoAsBlob(videoUrl: string): Promise<Blob> {
    const response = await fetch(videoUrl);
    const videoData = await response.blob();
    return videoData;
  }
    /**
   * get video thumbnail
   */
    async getVideoThumbnail(videoFile): Promise<any> {
      return await this.fileUsFork.generateThumbnail(videoFile).then(data => {
          data = JSON.parse(data);
          return data;
      });
    }
  constructor(
    private router: Router,
    private fileUpload: FileUploadKeeperService,
    private fileUsFork: UploadfileWithForkService,
    private activatedRoute: ActivatedRoute
  ) {
  }
}
