/**
 * Angular imports.
 */
import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpRequest, HttpHandler,
  HttpInterceptor, HttpErrorResponse
} from '@angular/common/http';

/**
 * Rxjs imports.
 */
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

/**
 * Service imports.
 */
import { MasterserviceService } from '../dataservices/master-service/masterservice.service';
import { ERROR_CODES, GATEWAY_STATUS_CODE } from '../constants/app.constants';
import { AuthService } from '../auth/auth.service';

/**
 * handle the API failed requests.
 */
@Injectable()
export class ServerErrorInterceptor implements HttpInterceptor {

  private displayObj = {
    isShow: false,
    display_section: '',
    data: [],
    errObj: {}
  };

  constructor(private masterService: MasterserviceService, private authService: AuthService) { }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if(!request.url.includes('device-token-generate')) {
          if(error.error?.mc == GATEWAY_STATUS_CODE.GATEWAY_1010) {
            error.error.m = 'Dear user, your login credentials have expired. Please login again using your old mobile number to start using Docquity.';
          }
          if(!request.url.includes('user-stories/story')){
            this.errorHandeler(error);
          }
        
        }
        if (error.status === ERROR_CODES.UNAUTHORIZED) {
          //Logout the user when API returns unauthorized
          if(error.error?.mc == GATEWAY_STATUS_CODE.GATEWAY_1010){
              this.authService.setLogoutRequest({callAPI: false, reload: true});
          }
        }
        return throwError(error);
      })
    );
  }

  /**
   * api error handeler
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  errorHandeler(data: HttpErrorResponse, screen_name = ''): void {
    this.displayObj.isShow = true;
    let smsg = '';
    if(data?.error?.m) {
      smsg = data?.error?.m;
      smsg = smsg && smsg.slice(smsg.indexOf('-')+1);
    }
    const msg = data?.error?.error?.msg || smsg || "Oops Something went wrong";
    this.displayObj.display_section = 'infoModal';
    this.displayObj.data = msg;
    this.displayObj.errObj = { msg };
    this.masterService.showWebModal.next(this.displayObj);
  }
}

