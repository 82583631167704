/**
 * Angular imports
 */
import { Injectable } from '@angular/core';

/**
 * Library imports
 */
import io from 'socket.io-client';
/**
 * Environment imports
 */
import { environment } from '../../environments/environment';

/**
 * Service imports
 */
import { AuthService } from '../auth/auth.service';
import { DataStorageService } from '../dataservices/data-storage.service';
 

/**
 * Rxjs imports
 */
import { Subject, BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

/**
 * Modal imports
 */
import { WebinarDownScale } from '../interface/webinar-downscale/webinar-downscale.interface';


/**
 * Web socket for the webinar detail to get comment, poll, reload status.
 */
@Injectable({
  providedIn: 'root'
})

// TODO:Pavan; remove console.

export class WebsocketService {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public socket: any;
  public roomName = '';
  public nameSpace = '/docquity_v3';
  public token = '';
  public comment_list = [];
  public webinar_live_comment_list = {};
  public live_comment_offset = 1;
  public live_user_count = '';
  public like_user_count = '';
  public product_type = 0;
  public product_type_id = 0;
  public is_user_sent_msg = false;
  public current_comment_obj = {
    comment: '',
    comment_id: 0,
    comment_by: '',
    product_type: 0,
    product_id: 0,
    date_of_creation: '',
    date_of_updation: '',
    unique_code: '',
    user_profile: {},
    has_send: true,
    is_reported: false
  };
  /**
   * Flag for socket connection.
   */
  public isConnected = false;

  /**
   * Used to hold the join room unique code.
   */
  public joinRoomUniqueCode: number;

  public recall_comment_list_time = 0;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public recall_list = new BehaviorSubject<any>('');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public pollData = new Subject<any>();
  public newJoinRequest = 'yes';
  public pollNameSpace = 'docquity_v3/ns_poll';
  public newStreamingData = new Subject<{ webinarDownScale: WebinarDownScale, reload: boolean }>();

  /**
   * Used to hold the live user count value in integer.
   */
  public liveUserCount = 0;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public uniqueCmtList: Subject<any> = new Subject();

  /**
   * Created all required instances.
   */
  constructor(public dss: DataStorageService, private authService: AuthService) {
    const dt = new Date();
    this.joinRoomUniqueCode = dt.getTime();
    this.recall_comment_list_time = environment.recall_live_webinar_comment_list_time;
    this.token = this.authService.getToken();
    this.connectToSocket();

    this.uniqueCmtList
      .pipe(debounceTime(100))
      .subscribe(() => {
        this.comment_list = this.comment_list.filter((idx, index, self) => self.findIndex(t => t.comment_id === idx.comment_id) === index);
      }
      );
  }

  /**
   * Socket connection.
   */
  connectToSocket(): void {
    if (this.isConnected === false) {
      this.socket = io.connect(
        environment.API_URLS.socket_comment + this.nameSpace,
        {
          upgrade: false,
          transports: ['websocket'],
          forceNew: false
        });
      this.isConnected = true;

    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.socket.on('disconnect', (reason: any) => {
      console.log('this.disconnect....', reason);
    });

    this.socket.on('connect', () => {
      console.log('this.connect....');
    });

    this.socket.on('reconnect', (reconnect) => {
      console.log('this.reconnect', reconnect);
      this.isConnected = false;
      this.joinRoom(this.roomName, this.product_type, this.product_type_id);
    });


    // live user count
    this.socket.on('live_global_emit_v2', data => {
      if (data.S2 == this.product_type && data.S3 == this.product_type_id) {

        if (this.liveUserCount <= data.V2) {
          this.live_user_count = data.V1;
          this.liveUserCount = data.V2;
          this.recall_comment_list_time = environment.recall_live_webinar_comment_list_time;
        }
      }
    });

    // like count
    this.socket.on('like_global', data => {
      if (data.S2 == this.product_type && data.S3 == this.product_type_id) {
        this.like_user_count = data.V1;
      }
    });

    this.socket.on(this.product_type + '_comment_screening_' + this.product_type_id, (data) => {
      if (data.product_type == this.product_type && data.product_id == this.product_type_id) {
        if (data.action == 'hide' && this.webinar_live_comment_list[data.unique_code] !== undefined) {
          this.webinar_live_comment_list[data.unique_code].is_reported = true;
        } else if (data.action == 'show' && this.webinar_live_comment_list[data.unique_code] !== undefined) {
          this.webinar_live_comment_list[data.unique_code].is_reported = false;
        }
      }
    });

    /**
     * Poll State.
     */
    this.socket.on(this.product_type + '_poll_state_' + this.product_type_id, (data) => {
      if (data.product_type == this.product_type && data.product_id == this.product_type_id) {
        this.pollData.next(data);
      }
    });

    /**
     * Switch the webinar from d-stream to wowza and vice versa.
     */
    this.socket.on('switch_' + this.product_type + '_stream_' + this.product_type_id, (data) => {
      if (data.code == 200 && data.status && data.data.product_type == this.product_type && data.data.product_id == this.product_type_id) {
        this.newStreamingData.next({ webinarDownScale: data.data, reload: true });
      }
    });

    /**
     * Reload the webinar in case of failure.
     */
    this.socket.on('reload_' + this.product_type + '_stream_' + this.product_type_id, (data) => {
      if (data.code == 200 && data.status && data.data.product_type == this.product_type && data.data.product_id == this.product_type_id) {
        this.newStreamingData.next({ webinarDownScale: null, reload: true });
      }
    });

    /**
     * Enable locked cme.
     */
    this.socket.on('unlock_attach_product_'+this.product_type+'_'+this.product_type_id+'_'+this.dss.uuid, data => {
      const uuid = (data?.data[0] && data.data[0].uuid) ? data.data[0].uuid : "";
      if (this.dss.uuid === uuid) {       
        data.data.forEach(row => {
          this.dss.UnlockLinkedProduct.next(row);
        });
      }
    });

  }

  
  // join room function
  joinRoom(room_name, product_type, product_type_id): void {
    this.roomName = room_name;
    this.product_type = product_type;
    this.product_type_id = product_type_id;
    this.current_comment_obj.product_type = product_type;
    this.current_comment_obj.product_id = product_type_id;
    this.socket.emit('joinRoom', {

      'A1': this.token,
      'S1': room_name,
      'S2': product_type,
      'S3': product_type_id,
      'S4': this.newJoinRequest,
      'S5': true,
      'unique_code': this.joinRoomUniqueCode
    }, (db) => {
      this.newJoinRequest = 'no';
      if (db && db.data && db.data.V2) {
        if (this.liveUserCount <= db.data.V2) {
          this.liveUserCount = db.data.V2;
          this.live_user_count = db.data.V1;
        }
        if (db.like_data && db.like_data.V1 && db.like_data.V1 > 0) {
          this.like_user_count = db.like_data.V1;
        }
      }
    });
    this.socket.on('JoinRoomresponse', () => {
      // console.log(':::JoinRoomresponse', joinRoomResponse, joinRoomResponse.data.v2);
    });

    this.socket.on('comment_v2', (commentReceiveResponse) => {
      if (commentReceiveResponse.status === true) {
        commentReceiveResponse.data.forEach(element => {

          if (this.webinar_live_comment_list[element.unique_code] === undefined) {

            const recCmt = {
              comment_id: element.S1,
              comment: element.S4,
              has_send: true,
              comment_by: element.S9,
              is_reported: element.S11,
              product_id: element.S2,
              product_type_id: element.S3,
              unique_code: element.S5,
              user_profile: {
                full_name: element.S6,
                registered_name: element.S6,
                profile_pic: element.S8,
                specialities: [{ speciality_name: element.S10, is_primary: 1 }],
                permission: element.S12
              }
            };
            this.webinar_live_comment_list[element.S5] = recCmt;
            const commentListTemp = JSON.parse(JSON.stringify(this.comment_list));
            commentListTemp.unshift(recCmt);
            this.comment_list = [...new Set(commentListTemp)];
            this.uniqueCmtList.next();

          }
        });
        this.recall_comment_list_time = environment.recall_live_webinar_comment_list_time;
      }

    });
  }

  postComment(requestBody): void {
    this.is_user_sent_msg = false;
    this.current_comment_obj.comment = requestBody.comment;
    this.current_comment_obj.comment_id = requestBody.unique_code;
    this.current_comment_obj.unique_code = requestBody.unique_code;

    const newRequestObj = {
      'A1': requestBody.user_auth,
      'S1': requestBody.roomname,
      'S2': requestBody.product_type,
      'S3': requestBody.product_type_id,
      'S4': requestBody.comment,
      'S5': requestBody.unique_code
    };

    if (this.webinar_live_comment_list[this.current_comment_obj.unique_code] === undefined) {
      const commentListTemp = JSON.parse(JSON.stringify(this.comment_list));
      commentListTemp.unshift(this.current_comment_obj);
      this.comment_list = [...new Set(commentListTemp)];
      this.webinar_live_comment_list[this.current_comment_obj.unique_code] = this.current_comment_obj;
      this.is_user_sent_msg = true;
      this.current_comment_obj = {
        comment: '',
        comment_id: 0,
        comment_by: '',
        product_type: 0,
        product_id: 0,
        date_of_creation: '',
        date_of_updation: '',
        unique_code: '',
        user_profile: '',
        has_send: true,
        is_reported: false
      };
    }
    this.socket.emit('comment_post', newRequestObj, (db) => {
      if (db.success === true) {
        this.webinar_live_comment_list[db.unique_code].has_send = true;
      } else {
        this.webinar_live_comment_list[db.unique_code].has_send = false;
      }
    });
  }

  disconnectSocket(): void {
    this.socket?.close();
    this.isConnected = false;
    this.newJoinRequest = 'yes';
  }
}

