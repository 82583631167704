
export class SamvaadLanguages {

  public languageText = {};

  constructor(mls) {
    this.languageText = {
      samvaad: mls?.languageText?.samvaad,
      samvaad_floating_button: mls?.languageText?.samvaad_floating_button,
      samvaad_system_msg: mls?.languageText?.samvaad_system_msg,
      samvaad_chat_detail: mls?.languageText?.samvaad_chat_detail,
      samvaad_block_unblock_modal: mls?.languageText?.samvaad_block_unblock_modal,
      samvaad_mute_unmute_modal: mls?.languageText?.samvaad_mute_unmute_modal,
      samvaad_exit_modal: mls?.languageText?.samvaad_exit_modal,
      samvaad_create_edit_group: mls?.languageText?.samvaad_create_edit_group,
      samvaad_validation: mls?.languageText?.samvaad_validation,
      samvaad_tutorial: mls?.languageText?.samvaad_tutorial,
      samvaad_tooltips: mls?.languageText?.samvaad_tooltips,
    }
  }
}
