/**
 * Angular imports.
 */
import { Directive, ElementRef, Renderer2, Input, OnInit } from '@angular/core';

/**
 * Constant imports.
 */
import { DATA_TEST_ID_PREFIX } from '../constants/data-testId.constants';
import { NUMBERS } from './../constants/app.constants';

/**
 * This directive is used to add the data testId as the attribute.
 */
@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[dataTestId]'
})
export class DataTestIdDirective implements OnInit {

  /**
   * Unique Id coming in the api.
   * Unique Name coming from the constant file.
   */
  @Input() dataTestId: string | number;
  @Input() dataTestIdName: string;

  /**
   * Prefix for the test id.
   */
  private prefix = DATA_TEST_ID_PREFIX;

  /**
   * Necessary imports.
   */
  constructor(private _elRef: ElementRef, private _renderer: Renderer2) { }

  /**
   * Call the set attribute function.
   */
  ngOnInit(): void {
    this.setAttribute();
  }

  /**
   * Adding the data-testId attribute in the DOM.
   */
  setAttribute(): void {
    if (!this.dataTestId) {
      this.generateUniqueId();
    }
    this._renderer
      .setAttribute(this._elRef.nativeElement, this.prefix, `${this.prefix}-${this.dataTestIdName}-${this.dataTestId}`);
  }

  /**
   * In case of undefine id generate the unique id.
   */
  generateUniqueId(): number {
    return Math.floor(Math.random() * NUMBERS.HUNDRED) + new Date().getTime();
  }
}
