/**
 * Angular imports.
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

/**
 * Component imports.
 */
import { WalkthroughComponent } from './walkthrough/walkthrough.component';
import { TutorialPopoverComponent } from './tutorial-popover/tutorial-popover.component';
import { RepostPopoverComponent } from './repost-popover/repost-popover.component';
import { RepostTypeListMetaComponent } from './repost-type-list-meta/repost-type-list-meta.component';

/**
 * Modal imports.
 */
import { ModalModule } from 'ngx-bootstrap/modal';
import { NewChatTutorialComponent } from './new-chat-tutorial/new-chat-tutorial.component';
import { TagConnectionsPopOverComponent } from './tag-connections-pop-over/tag-connections-pop-over.component';



@NgModule({
  declarations: [
    WalkthroughComponent,
    TutorialPopoverComponent,
    NewChatTutorialComponent,
    RepostPopoverComponent,
    TagConnectionsPopOverComponent,
    RepostTypeListMetaComponent
  ],
  imports: [
    CommonModule,
    ModalModule
  ],
  exports: [
    WalkthroughComponent,
    TutorialPopoverComponent,
    NewChatTutorialComponent,
    RepostPopoverComponent,
    TagConnectionsPopOverComponent,
    RepostTypeListMetaComponent
  ]
})
export class WalkthroughModule { }
