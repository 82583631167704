<!-- <div *ngIf="see_more==false">
    <div style="display:inline;" *ngIf="cmt!=''" class="userPostText" innerHTML="{{ cmt  | slice:0:showChar|  linkify}}"></div>
    <span  style="cursor:pointer; color:#ba0000" *ngIf="cmt?.length>showChar" (click)="seeMore()"  >..{{mls.See_more}}</span>
</div>

<div  *ngIf="see_more==true">
    <p style="display:inline;" *ngIf="cmt!=''" class="userPostText" innerHTML="{{ cmt | linkify}}"></p>
    <span style="cursor:pointer; color:#8f4747" *ngIf="cmt?.length>showChar" (click)="seeLess()"  >..{{mls.See_less}}</span>
</div>  -->
    <p *ngIf="!showMessageToggle"
        class="user-post-text inline-display link-text"
        innerHTML="{{final_str | linkify}}"
        [appAnalyticsEvent]="analyticsFeedData">
    </p>
    <p *ngIf="showMessageToggle"
        class="user-post-text inline-display link-text"
        innerHTML="{{originalMessage | linkify}}"
        [appAnalyticsEvent]="analyticsFeedData">
    </p>
    <span *ngIf="callingfrom=='postdraft'">...</span>
    <ng-container *ngIf="callingfrom!='postdraft'">
        <span *ngIf="see_more==1"
            [class]="readMoreCss"
            (click)="seeMore()"
            [appAnalyticsEvent]="analyticsFeedViewMoreData">...{{readMoreText}}<span *ngIf="mls?.is_lskey_display==1">(content_card?.key1)</span>
        </span>
        <span *ngIf="see_more==2"
            [class]="readLessCss"
            [appAnalyticsEvent]="analyticsFeedViewLessData"
            (click)="seeLess()">...{{readLessText}}<span *ngIf="mls?.is_lskey_display==1">(content_card?.key2)</span>
        </span>
    </ng-container> 
