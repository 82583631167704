/**
 * Angular imports.
 */
import { Directive, HostListener, Input, OnDestroy } from '@angular/core';

/**
 * Service imports.
 */
import { DocquityAnalyticsService } from '../dataservices/docquity-analytics/docquity-analytics.service';
import { JwtDecodeService } from '../dataservices/jwt-decode.service';
import { MasterserviceService } from '../dataservices/master-service/masterservice.service';
import { FunCollectionService } from '../common/common-functions/fun-collection.service';
import { DataStorageService } from '../dataservices/data-storage.service';

/**
 * Environment imports.
 */
import { environment } from '../../environments/environment';

/**
 * Rxjs imports.
 */
import { Subscription } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
// TODO:Pavan: data typing.

/**
 * event Tracker directive.
 */
@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[eventTracker]'
})

export class ButtonDirective implements OnDestroy {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public eventdata: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public profileInfo: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public userProfileData: any;
  public user_id: number;
  public user_track_id = '';
  public api_exe_flag = false;
  public unique_id = '0';
  public session_id = '';

  /**
   * To unsubscribe data.
   */
  private subscriptions: Subscription = new Subscription();
  private componentActive = true;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input('eventTracker') option: any;

  @HostListener('click.out-zone', ['$event']) onClick(): void {
    if (! this.option.category) {
      return;
    }
    this.user_track_id = this.dss.user_profile_data.profile.track_id;
    this.unique_id = this.fcs.makenumericid();
    // this.dss.current_event_detail=this.option;
    sessionStorage.setItem('eventsdetail', JSON.stringify(this.option));
    sessionStorage.setItem('trackid', this.user_track_id);
    if (environment.event_tracking == 1 || environment.production == true) {
      if (this.option.product_type == 'CME' || this.option.product_type == 'cme') {
        this.option.product_type = 8;
      } else if (this.option.product_type == 'Home') {
        this.option.product_type = 1;
      } else if (this.option.product_type == 'Channel') {
        this.option.product_type = 10;
      }
      if (environment.api_debugger == 1) {
        // No Code
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (<any>window).ga('send', 'event', this.option.category, this.option.action, {
        hitCallback:  () => {
          // No Code
        }
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (<any>window).gtag('event',  this.option.category, {'event_category':  this.option.action});
    }

    this.subscriptions.add(this.masterService.profile_api_data.subscribe(data => {
      this.userProfileData = data;
      this.profileInfo = this.userProfileData.data['profile'];
      this.user_id = this.profileInfo.user_id;
    }));

    const d = new Date();

    const sessiontime = { 'start_time': d.getTime(), 'end_time': d.getTime() };
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let location;
    {
      location = { 'latitude': '', 'longitude': '', 'local': '', 'time_zone': timezone };
    }
    if (sessionStorage.getItem('event_session_id') != null && sessionStorage.getItem('event_session_id') != '') {
      this.session_id = sessionStorage.getItem('event_session_id');
    } else {
      const dd = new Date();
      this.session_id = 'web-' + dd.getTime() + this.makenumericid();
      sessionStorage.setItem('event_session_id', this.session_id);
    }
    const utmData = JSON.parse(localStorage.getItem('utmSourceData'))
    this.eventdata = {
      id: this.unique_id,
      event_name: this.option.action,
      product_type: this.option.product_type,
      product_type_id: this.option.product_id,
      session_id: this.session_id,
      local_id: '0',
      screen_name: this.option.category,
      user_id: this.user_id,
      untrack_user_identifier: '',
      session_time: sessiontime,
      location: location,
      track_id: this.user_track_id,
      utm: {
        source: utmData?.['UTM_Source'],
        campaign: utmData?.['UTM_Campaign'],
        medium:  utmData?.['UTM_Medium']
      }
    };


    this.docquityabalytics.all_event.push(this.eventdata);
    if (environment.event_tracking == 1 || environment.production == true) {
      if (this.api_exe_flag == true) { return; }
      this.api_exe_flag = true;
      this.setDocquityAnalytic();
    }
  }

  /**
   * Necessary instances.
   */
  constructor(
    private jwtSer: JwtDecodeService,
    private dss: DataStorageService,
    private fcs: FunCollectionService,
    private masterService: MasterserviceService,
    private docquityabalytics: DocquityAnalyticsService) {
  }


  /**
   * set Docquity Analytic
   */
  setDocquityAnalytic(actionUrl = ''): void {
    this.docquityabalytics.docquityAnalytics(this.docquityabalytics.all_event)
      .pipe(takeWhile(() => this.componentActive))
      .subscribe(resData => {
        this.jwtSer.decodeData(resData).then(data => {
          if (actionUrl !== '') {
            window.location.href = actionUrl;
          }
          if (data['status'] == 1) {
            if (environment.api_debugger == 1) {
              // No Code
            }
            this.docquityabalytics.all_event = [];
            this.api_exe_flag = false;
          }
        }, () => {
          this.api_exe_flag = false;
        });
      });
  }

  /**
   * product Detail Event
   */
  productDetailEvent(sessiontime, action, product_type, product_id, category, track_id, sessiontime_2?, actionUrl?): void {
    const eventsdetail = { action: action, product_type: product_type, product_id: product_id, category: category, track_id: track_id };
    sessionStorage.setItem('eventsdetail', JSON.stringify(eventsdetail));
    
    this.docquityabalytics.all_event = [];
    this.user_track_id = track_id;
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let location;
    {
      location = { 'latitude': '', 'longitude': '', 'local': '', 'time_zone': timezone };
    }

    if (sessionStorage.getItem('event_session_id') != null && sessionStorage.getItem('event_session_id') != '') {
      this.session_id = sessionStorage.getItem('event_session_id');
    } else {
      const d = new Date();
      this.session_id = 'web-' + d.getTime() + this.makenumericid();
      sessionStorage.setItem('event_session_id', this.session_id);
    }

    const utmData = JSON.parse(localStorage.getItem('utmSourceData'))
    this.unique_id = this.fcs.makenumericid();
    this.eventdata = {
      id: this.unique_id,
      event_name: action,
      product_type: product_type,
      product_type_id: product_id,
      session_id: this.session_id,  // this.docquityabalytics.event_session_id,
      local_id: '0',
      screen_name: category,
      user_id: this.user_id,
      untrack_user_identifier: '',
      session_time: sessiontime,
      location: location,
      track_id: this.user_track_id,
      utm: {
        source: utmData?.['UTM_Source'],
        campaign: utmData?.['UTM_Campaign'],
        medium:  utmData?.['UTM_Medium']
      }
    };

    if (environment.event_tracking == 1 || environment.production == true) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (<any>window).gtag('event',  category, {'event_category':  action});
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (<any>window).ga('send', 'event', category, action, {
        hitCallback: function () {
          // console.log('Tracking is successful');
        }
      });
      this.docquityabalytics.all_event.push(this.eventdata);
      setTimeout(() => {
        // console.log('sessiontime=', sessiontime, sessiontime.end_time-sessiontime.start_time)
        if (this.api_exe_flag == true) { return; }
        this.setDocquityAnalytic(actionUrl);
        this.api_exe_flag = true;
      }, 1000);
    }

  }

  /**
   * make numeric id
   */
  makenumericid(): string {
    let text = '';
    const possible = '0123456789';
    for (let i = 0; i < 10; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  /**
   * unsubscribe the subscription.
   */
  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
    this.componentActive = false;
  }
}
