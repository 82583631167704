import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ProgressBarMode } from '@angular/material/progress-bar';
import { NewLanguageService } from '../../dataservices/new-language/new-language.service';
/**
 * constant import
 */
import { NUMBERS, PRODUCT_TYPE_CONSTANT, WEBINAR_POLL_STATUS } from '../../constants/app.constants';
import { AnalyticsInterface } from '../../analytics-event/analytics.model';
import { EVENT_NAME } from '../../analytics-event/analytics.constant';
/**
 * Service import.
 */
import { AnalyticsEventService } from './../../analytics-event/analytics-event.service';
@Component({
  selector: 'app-live-poll-modal',
  templateUrl: './live-poll-modal.component.html',
  styleUrls: ['./live-poll-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LivePollModalComponent implements OnInit {
  /**
   * Receive Poll Data
   * Check for Loading
   * Progress Bar Mode for Attempt Live Poll Options
   * Buffered or Complete Value to 100 for Progress bar
   * Enable Live Poll Popup Flag
   * Analytics Interface
   * Webinar Poll Status
   * Poll Id
   * Numbers
   */
  public recievePollData;
  public isLoading = false;
  public mode: ProgressBarMode = 'determinate';
  public bufferValue1 = NUMBERS.HUNDRED;
  public enablePopup = false;
  public analyticsLivePollData: AnalyticsInterface;
  public webinarPollStatus = WEBINAR_POLL_STATUS;
  public poll_id: string;
  public numbers = NUMBERS;
  /**
   * Input variable for calling from page
   * Poll Data
   * Live Poll Index
   * Webinar Id
   * Has Live Poll Flag
   * Live Poll User Activity
   */
  @Input() callingFrom: string;
  @Input() pollData;
  @Input() livePollIndex;
  @Input() webinarId;
  @Input() webinarStatus;
  @Input() hasLivePoll;
  @Input() livePollUserActivity;
  @ViewChild('tabContent') widgetsContent: ElementRef;
  /**
   * Input to live poll popup overlay hide for agira player
   */
  @Input() livePollPopupOverlayHide;
  /**
   * Used to send the cross button click event.
   * Submit Live Poll Event
   * Open Live Poll Popup Event
   */
  @Output() pollcrossClick = new EventEmitter<boolean>();
  @Output() submitLivePoll = new EventEmitter();
  @Output() openLivePollPopup = new EventEmitter();

  constructor(public mls: NewLanguageService, private analyticsEventService : AnalyticsEventService, private cdRef: ChangeDetectorRef) { }

  /**
   * On Init
   */
  ngOnInit(): void {
    this.poll_id = this.livePollIndex;
    this.openLivePollPopup.emit();
    this.generatePollCloseAnalyticsData();
    this.cdRef.detectChanges();
  }

  /**
   * Function to Hide Modal
   */
  hideModal(): void {
    this.pollcrossClick.emit(true);
  }

  /**
   * Function to set poll Id , while tab change
   */
  selectedTabChanged(event): void {
    this.poll_id = event.index;
  }

  /**
   * Function to Set Answar as per poll
   */
  setAnswer(tab, optionIndex): void {
    if (tab.can_alter == NUMBERS.ONE && tab.state === this.webinarPollStatus.LIVE) {
      const submitData = {tab: tab, userSelectedOption: optionIndex};
      this.submitLivePoll.emit(submitData);
    }
  }

  /**
   * Function to generate Poll Close Analytics Data
   */
  generatePollCloseAnalyticsData(): void {
    const events = {
      'analyticsLivePollCloseData': EVENT_NAME.WEBINAR_LIVE_POLL_CLOSE_CLICK
    };
    // tslint:disable-next-line: forin
    for (const event in events) {
      this[event] = {
        'event_name': events[event],
        'product_type': PRODUCT_TYPE_CONSTANT.WEBINAR,
        'product_type_id': this.webinarId,
        'screen_name': this.callingFrom,
      };
    }
    this.enablePopup = true;
  }
}
