<div *ngIf="chatDetail && isMeTopic" [hidden]="hideDetailWindow" class="position-relative"
  [ngClass]="{'full-chat-detail-panel': chatView === chatViewConstant.FULL, 'mini-chat-detail-panel': chatView === chatViewConstant.MINI, 'h-unset': isChatDetailExpanded && chatView === chatViewConstant.MINI}">
  <div class="chat-detail-panel-header d-flex justify-content-between align-items-center"
    [ngClass]="{'cursor-pointer': chatView === chatViewConstant.MINI, 'py-2': chatView === chatViewConstant.MINI && isChatDetailExpanded}"
    (click)="chatView === chatViewConstant.MINI ? toggleExpandWindow() : null">
    <div class="d-flex flex-1 min-width-0 align-items-center icon-hover">
      <span class="icon chat-detail-back"
        (click)="$event.preventDefault();$event.stopPropagation();closeDetailWindow()">
        <img class="mr-3 cursor-pointer" src="assets/samvaad-assets/images/icons/search-user-left-arrow.svg"
          alt="back" />
      </span>
      <div class="text-truncate flex-1">
        <app-individual-info-box
          [info]="{pic: chatDetail.public ? chatDetail.type === chatType.P2P ? chatDetail.public?.dp?.data : dpSanitize(chatDetail?.public?.photo) : '',
          name: chatDetail.type === chatType.P2P ? (!chatDetail.public?.misc?.deleted ? chatDetail.public.fn + ' ' + chatDetail.public?.ln : deletedUserName) : chatDetail.public.name, badgePic: chatDetail.type === chatType.P2P ? getUserOnlineOfflineStatus(chatDetail, true) : ''}"
          [isCursorPointer]="chatView === chatViewConstant.MINI ? true : false"
          [defaultSrc]="chatDetail.type === chatType.GROUP ? 'assets/samvaad-assets/images/icons/group-default.svg' : 'assets/samvaad-assets/images/icons/user-profile-placeholder.svg'"
          nameCss="secondary-paragraph font-weight-bold" secondaryCss="primary-small"
          [profileSecondary]="getLastMsg(chatDetail, chatDetail.type)"
          (onAvatarClick)="emitAvatarClick(chatDetail.type)">
        </app-individual-info-box>
      </div>
      <span class="icon custom-tooltip top" [hidden]="chatView === chatViewConstant.FULL">
        <img class="chat-detail-arrow cursor-pointer mr-2"
          [src]="!isChatDetailExpanded ? 'assets/samvaad-assets/images/icons/chat-list-expand.svg' : 'assets/samvaad-assets/images/icons/chat-list-collpased.svg'"
          alt="expand">
        <span class="tooltip-content">{{!isChatDetailExpanded ? samvaadLanguageText?.samvaad_tooltips?.key1 : samvaadLanguageText?.samvaad_tooltips?.key2 }}</span>
      </span>
    </div>
    <div class="icon-hover d-flex align-items-center">
      <span class="icon custom-tooltip top" [hidden]="chatView === chatViewConstant.FULL">
        <img
          src="assets/samvaad-assets/images/icons/chat-window-cross.svg" alt="cross"
          class="cursor-pointer chat-detail-cross"
          (click)="$event.preventDefault();$event.stopPropagation();closeDetailWindow()">
        <span class="tooltip-content">{{samvaadLanguageText?.samvaad_tooltips?.key3}}</span>
      </span>
      <span *ngIf="!chatDetail?.public?.misc?.deleted" class="icon custom-tooltip top" [hidden]="chatView === chatViewConstant.MINI && isChatDetailExpanded"
        (click)="$event.preventDefault();$event.stopPropagation()">
        <app-more-options [moreOptions]="getMoreOptions(chatDetail)"
          (emitAction)="doMoreOptionAction($event, chatDetail.type)">
        </app-more-options>
        <span class="tooltip-content">{{samvaadLanguageText?.samvaad_tooltips?.key4}}</span>
      </span>
    </div>
  </div>
  <div #scrollMe class="chat-detail-panel-body" [hidden]="isChatDetailExpanded && chatView === chatViewConstant.MINI"
    infiniteScroll [infiniteScrollUpDistance]="0" [infiniteScrollThrottle]="0" (scrolledUp)="onScrollUp()"
    [scrollWindow]="false">
    <ng-container *ngTemplateOutlet="loading"></ng-container>
    <ul class="message-box d-flex flex-column"
      *ngIf="chatDetail.msg && chatDetail.msg.length && isChatDetailMembers;else noMsg">
      <div class="chat-top-arrow clearfix"
        [hidden]="(scrollMe?.scrollHeight < scrollMe?.scrollTop + scrollMe?.offsetHeight + EXTRA_OFFSET_HEIGHT)">
        <div
          *ngIf="!(scrollMe?.scrollHeight < scrollMe?.scrollTop + scrollMe?.offsetHeight + EXTRA_OFFSET_HEIGHT); else clearUnreadDot"
          class="position-relative shadow-sm rounded-circle float-right cursor-pointer"
          (click)="scrollToBottom()">
          <img src="assets/themes/icons/single-up-arrow.svg" width="35px" />
          <img *ngIf="isUnreadDot" class="not-dot" src="assets/samvaad-assets/images/icons/pulse.gif" alt="dot">
        </div>
      </div>
      <ng-template #clearUnreadDot>
        {{removeUnreadDot()}}
      </ng-template>
      <ng-container
        *ngFor="let group of chatDetail.msg | sortedBy: 'seq' : chatDetail | groupByDate;trackBy:trackByDate">
        <div class="primary-small line-seperator">
          <span class="color-grey-400 line bg-white">
            {{group.date | niceDateFormat}}
          </span>
        </div>
        <div class="d-flex flex-column" #commentDiv *ngFor="let msg of group.msg;trackBy:trackBySeq">
          <li class="system_message my-2" *ngIf="msg?.head?.system_message && chatDetail?.memberList">
            <p class="primary-small px-3 text-center line-break-anywhere">
              <span class="system_message_chip color-grey-700" *ngIf="(getSystemMsg(msg) | stringNullCheck); else skeleton">
                {{getSystemMsg(msg)}}
              </span>
            </p>
          </li>
          <li class="message"
            *ngIf="!msg?.head?.system_message && !(msg.from !== isMeTopic && msg.blocked === msgStatus.BLOCKED)"
            [ngClass]="{
              'send ml-auto': msg.from === isMeTopic,
              'receive mr-auto': msg.from !== isMeTopic,
              'error mb-1': (msg._status === msgStatus.QUEUED && msg?.seq?.toString()?.length >  msgStatus.STATUS_DEL_RANGE)
            }">
            <p class="primary-small line-break-anywhere">
              <span class="color-brown-900"
              *ngIf="((chatDetail?.memberList && chatDetail.type === chatType.GROUP ?
              (!chatDetail.memberList[msg.from]?.public?.misc?.deleted ? chatDetail.memberList[msg.from]?.public?.fn + ' ' +
              chatDetail?.memberList[msg.from]?.public?.ln : deletedUserName) : '') | stringNullCheck); else skeleton">
                {{chatDetail?.memberList && chatDetail.type === chatType.GROUP ?
                (!chatDetail.memberList[msg.from]?.public?.misc?.deleted ? chatDetail.memberList[msg.from]?.public?.fn + ' ' +
                chatDetail?.memberList[msg.from]?.public?.ln : deletedUserName) : ''}}
              </span>
            </p>
            <p *ngIf="msg.content;else noContent" class="secondary-paragraph line-break-anywhere">
              <img *ngIf="msg.content && msg.content.ent && msg.content.ent[0].data && msg.content.ent[0].tp === IMAGE"
                class="img-preview cursor-pointer my-3" [class.hidden]="!msg?.loaded" [class.loaded]="msg?.loaded"
                [src]="sanitizeSeq[msg?.id ? msg?.id : msg?.seq] ? sanitizeSeq[msg?.id ? msg?.id : msg?.seq] : sanetizeImage(msg)"
                alt="img" height="188" (click)="openImageMsg(msg)" (load)="onImageLoad(msg)">
              <img *ngIf="msg.content && msg.content.ent && msg.content.ent[0].data && msg.content.ent[0].tp === IMAGE"
                class="img-preview my-3" [class.hidden]="msg?.loaded"
                src="assets/samvaad-assets/images/icons/primary-loader.gif" alt="img" height="90" width="336">
              <span class="color-black innerContent" [innerHTML]="msg.content | linebreaks | linkify"></span>
            </p>
            <p class="secondary-small text-right">
              <span class="color-grey-500">
                {{msg.ts | date:'hh:mm a' }}
              </span>
              <ng-container *ngIf="msg.from === isMeTopic">
                <span class="tick"
                  *ngIf="(msg._status === msgStatus.NONE || msg._status === msgStatus.QUEUED || msg._status === msgStatus.SENDING) && !(msg._status === msgStatus.QUEUED && msg?.seq?.toString()?.length > msgStatus.STATUS_DEL_RANGE)">
                  <img src="assets/samvaad-assets/images/icons/clock.svg" height="16px">
                </span>
                <span class="tick" *ngIf="msg._status === msgStatus.SENT">
                  <img src="assets/samvaad-assets/images/icons/tick.svg" height="16px">
                </span>
                <span class="tick" *ngIf="msg._status === msgStatus.RECEIVED">
                  <img src="assets/samvaad-assets/images/icons/delivered.svg" height="16px">
                </span>
                <span class="tick" *ngIf="msg._status === msgStatus.READ || msg._status === msgStatus.TO_ME">
                  <img src="assets/samvaad-assets/images/icons/blue-tick.svg" height="16px">
                </span>
                <span class="tick"
                  *ngIf="msg._status === msgStatus.QUEUED && msg.seq?.toString()?.length >  msgStatus.STATUS_DEL_RANGE">
                  <img src="assets/samvaad-assets/images/icons/info.svg" height="16px">
                </span>
              </ng-container>
            </p>
            <ng-template #noContent>
              <p class="secondary-paragraph">
                {{samvaadLanguageText?.samvaad_chat_detail?.key1}}
              </p>
            </ng-template>
          </li>
          <span class="mb-2 mr-4 text-right"
            *ngIf="msg._status === msgStatus.QUEUED && msg?.seq?.toString()?.length > msgStatus.STATUS_DEL_RANGE">
            <small class="primary-small">
              <span class="color-error">
                Message not sent.
                <span class="cursor-pointer" (click)="resendMsg(msg, msg.topic)">
                  Please retry
                  <img src="assets/samvaad-assets/images/icons/retry.svg" height="16px">
                </span>
              </span>
            </small>
            <!-- <small class="primary-small ml-2 cursor-pointer" (click)="deleteMsg(msg, msg.topic)">
              Delete
            </small> -->
          </span>
        </div>
      </ng-container>
    </ul>
    <ul>
      <ng-template #noMsg>
        <app-chat-no-info *ngIf="chatDetail.acs.isJoiner() && !chatDetail?.seq && isChatDetailMembers"
          [hidden]="isLoading" isSecondary="true" banner="assets/samvaad-assets/images/icons/no-chat.svg"
          [btnText]="samvaadLanguageText?.samvaad_chat_detail?.key11 + (!chatDetail.public?.misc?.deleted ? chatDetail.public.fn : deletedUserName)"
          (onBtnClick)="sendMessage(samvaadLanguageText?.samvaad_chat_detail?.key12, chatDetail.topic)">
        </app-chat-no-info>
        <app-chat-no-info *ngIf="chatDetail.acs.isJoiner() && chatDetail?.seq && isChatDetailMembers"
          [hidden]="isLoading" isSecondary="true" banner="assets/themes/images/no-result.svg"
          paragraph="Failed to fetch messages" btnText="Retry" (onBtnClick)="retryFetchMessages(chatDetail.topic)">
        </app-chat-no-info>
        <app-chat-no-info *ngIf="chatDetail.acs.isJoiner() && !isChatDetailMembers" [hidden]="isLoading"
          isSecondary="true" banner="assets/themes/images/no-result.svg" paragraph="Something went wrong"
          btnText="Retry" (onBtnClick)="retryMembers(chatDetail.topic)">
        </app-chat-no-info>
      </ng-template>
    </ul>
  </div>
  <div class="chat-detail-footer" [hidden]="isChatDetailExpanded && chatView === chatViewConstant.MINI">
    <app-chat-creator *ngIf="chatDetail.acs.isJoiner() && !chatDetail?.public?.misc?.deleted" [isMobileVersion]="isMobileVersion" [textAreaPlaceholder]="samvaadLanguageText?.samvaad?.key17"
      [chatPreviewTitle]="samvaadLanguageText?.samvaad?.key18" [samvaadLanguageText]="samvaadLanguageText" (emitValueChange)="valueChange(chatDetail.topic)"
      (emitValueSubmit)="sendMessage($event, chatDetail.topic)" (emitFileUploadSubmit)="sendImageMessage($event)" (onAttachmentClick)="emitAttachmentClick()">
    </app-chat-creator>
    <div *ngIf="!chatDetail.acs.isJoiner()" class="text-center">
      <p class="primary-small mb-2" style="line-height: 14px;">
        {{
        chatDetail.type === chatType.P2P ?
        samvaadLanguageText?.samvaad_chat_detail?.key7 +'. '+ samvaadLanguageText?.samvaad_chat_detail?.key8 +
        '. '+ samvaadLanguageText?.samvaad_chat_detail?.key9
        :
        samvaadLanguageText?.samvaad_chat_detail?.key10
        }}
      </p>
      <div class="text-center" *ngIf="chatDetail.type === chatType.P2P">
        <button class="btn-design filled small" type="button"
          (click)="doMoreOptionAction(moreOptions.UNBLOCK_P2P, chatType.P2P)">
          {{samvaadLanguageText?.samvaad_chat_detail?.key2}}
        </button>
      </div>
    </div>
    <div *ngIf="chatDetail.type === chatType.P2P && chatDetail?.public?.misc?.deleted" class="text-center p-4">
      <p class="primary-small" style="line-height: 14px;">
        {{samvaadLanguageText?.samvaad?.key30}}
      </p>
    </div>
  </div>
</div>

<div *ngIf="chatView === chatViewConstant.FULL && ((!chatDetail && !isMeTopic) || hideDetailWindow)"
  class="no-chat-avail"
  [ngClass]="{'full-chat-detail-panel': chatView === chatViewConstant.FULL, 'h-unset': isChatDetailExpanded && chatView === chatViewConstant.MINI}">
  <app-chat-no-info banner="assets/samvaad-assets/images/icons/no-group-chat.svg"
    [paragraph]="samvaadLanguageText?.samvaad?.key2" [btnText]="samvaadLanguageText?.samvaad?.key3"
    (onBtnClick)="newJourney()">
  </app-chat-no-info>
</div>
<div *ngIf="isBlockModal">
  <app-chat-confirm-modal [isConfirmModal]="isBlockModal" [srcWidth]="60"
    [infoSrc]="isUserBlock ? 'assets/samvaad-assets/images/icons/block.svg' : 'assets/samvaad-assets/images/icons/unblock.svg'"
    [heading]="isUserBlock ? samvaadLanguageText?.samvaad_block_unblock_modal?.key1 : samvaadLanguageText?.samvaad_block_unblock_modal?.key2"
    [paragraph]="isUserBlock ? samvaadLanguageText?.samvaad_block_unblock_modal?.key3 :
    samvaadLanguageText?.samvaad_block_unblock_modal?.key5" [btnCancel]="samvaadLanguageText?.samvaad?.key4"
    [btnText]="isUserBlock ? samvaadLanguageText?.samvaad_block_unblock_modal?.key6: samvaadLanguageText?.samvaad_block_unblock_modal?.key7"
    (onSubmit)="onEmitAction(isUserBlock ? moreOptions.BLOCK_P2P : moreOptions.UNBLOCK_P2P, chatType.P2P)"
    (onHide)="isBlockModal = false">
  </app-chat-confirm-modal>
</div>
<div *ngIf="isNotificationModal">
  <app-chat-confirm-modal [isConfirmModal]="isNotificationModal" [srcWidth]="40"
    [infoSrc]="isNotificationMute ? 'assets/samvaad-assets/images/icons/bell.svg' : 'assets/samvaad-assets/images/icons/unmute.svg'"
    [heading]="isNotificationMute ? samvaadLanguageText?.samvaad_mute_unmute_modal?.key1 : samvaadLanguageText?.samvaad_mute_unmute_modal?.key2"
    [paragraph]="isNotificationMute ? samvaadLanguageText?.samvaad_mute_unmute_modal?.key4 : samvaadLanguageText?.samvaad_mute_unmute_modal?.key3"
    [btnCancel]="samvaadLanguageText?.samvaad?.key4"
    [btnText]="isNotificationMute ? samvaadLanguageText?.samvaad_mute_unmute_modal?.key5 : samvaadLanguageText?.samvaad_mute_unmute_modal?.key6"
    (onSubmit)="onEmitAction(isNotificationMute ? moreOptions.MUTE_NOTIFICATION : moreOptions.UNMUTE_NOTIFICATION, chatDetail.type === chatType.P2P ? chatType.P2P : chatType.GROUP)"
    (onHide)="isNotificationModal = false">
  </app-chat-confirm-modal>
</div>
<div *ngIf="isExitGroupModal">
  <app-chat-confirm-modal [isConfirmModal]="isExitGroupModal"
    infoSrc="assets/samvaad-assets/images/icons/delete-information-icon.svg"
    [heading]="samvaadLanguageText?.samvaad_exit_modal?.key1"
    [paragraph]="samvaadLanguageText?.samvaad_exit_modal?.key2" [btnCancel]="samvaadLanguageText?.samvaad?.key4"
    [btnText]="samvaadLanguageText?.samvaad_exit_modal?.key3"
    (onSubmit)="onEmitAction(moreOptions.EXIT_GROUP, chatType.GROUP)" (onHide)="isExitGroupModal = false">
  </app-chat-confirm-modal>
</div>
<ng-template #loading>
  <app-chat-progress *ngIf="isLoading" [isSmallLoading]="true" [width]="60" [height]="60"></app-chat-progress>
</ng-template>
<ng-template #defaultImage>
  <app-chat-progress [isSmallLoading]="true" [width]="60" [height]="60"></app-chat-progress>
</ng-template>
<ng-template #skeleton>
  <div class="skeleton"></div>
</ng-template>
