  <div *ngIf="isConfirmModalShown" 
    [config]="{ show: true, backdrop: masterService.modals_backdrop.confirm_modal }" (onHidden)="onHidden()"  
    bsModal 
    #autoShownModal="bs-modal" 
    class="modal fade confirm-modal-container" 
    tabindex="-1" 
    role="dialog" 
    aria-hidden="true">
    <div class="custom-theme-modal modal-dialog modal-md modal-dialog-centered" 
      role="document">
      <div class="modal-content">
        <div class="modal-header px-4 pt-4">
          <span class="cursor-pointer close-modal"  
            data-dismiss="modal" aria-label="Close" (click)="hideModal()" >
            <img src="assets/themes/icons/cancel.svg" alt="close icon">
          </span>
        </div>
        <div class="modal-body px-4 pb-4">
          <div>
            <h5 class="heading-3">{{mls?.languageText?.confirm_modal?.key1}}<span *ngIf="mls?.is_lskey_display==1">(confirm_modal?.key1)</span></h5>
            <p class="mt-2 paragraph">
              <span class="color-grey-700">{{confirmInfo}}</span></p>
            <div class="text-right clearfix mt-4">
              <button class="btn-design medium outline cursor-pointer mr-3" 
                (click)="hideModal()">{{mls?.languageText?.confirm_modal?.key3}}<span *ngIf="mls?.is_lskey_display==1">(confirm_modal?.key3)</span></button>
              <button class="btn-design medium filled cursor-pointer" 
                (click)="ActivateAction()">  {{mls?.languageText?.confirm_modal?.key2}}<span *ngIf="mls?.is_lskey_display==1">(confirm_modal?.key2)</span></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
    <div *ngIf="isDeleteAccountConfirmModalShown" [config]="{ show: true, backdrop: masterService.modals_backdrop.confirm_modal }" (onHidden)="onHidden()"  bsModal #autoShownModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" >
      <div class="custom-theme-modal modal-dialog modal-md modal-dialog-centered del-confirm-modal-container" role="document">
        <div class="modal-content">
          <div class="modal-header pt-3">
            <span class="cursor-pointer close-modal"  
              data-dismiss="modal" 
              aria-label="Close" 
              (click)="hideModal()">
              <img src="assets/themes/icons/cancel.svg" alt="close icon" />
            </span>
          </div>
          <div class="modal-body p-0">
            <p class="px-3 heading-3 text-center">{{mls.languageText.confirm_modal.key4}}<span *ngIf="mls?.is_lskey_display==1">(confirm_modal?.key4)</span></p>
            <div class="p-3 text-center">
              <img src="assets/images/profile/profile-delete-infographic.svg" alt="profile delete graphics" />
              <p class="mt-4 heading-4">
                <span class="color-grey-700">{{mls.languageText.confirm_modal.key5}}</span>
              </p>
              <div class="mt-4 mb-2 action-btn-container">
                <button
                  class="btn-design medium outline mr-2"
                  (click)="hideModal()">
                  {{mls.languageText.confirm_modal.key7}}
                </button>
                <button class="btn-design medium filled ml-2 mt-2 mt-sm-0"
                  (click)="navigateTo()">
                  {{mls.languageText.confirm_modal.key6}}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  
    <div *ngIf="isWithdrawRequestConfirmModalShown" [config]="{ show: true, backdrop: 'static' }" (onHidden)="onHidden()"  bsModal #autoShownModal="bs-modal" class="modal fade confirm-box" tabindex="-1" role="dialog" aria-hidden="true" >
      <div class="custom-theme-modal modal-dialog modal-md modal-dialog-centered px-2 withdraw-modal-container" role="document">
        <div class="modal-content">
          <div class="modal-header p-4">
            <span class="cursor-pointer close-modal"  
              data-dismiss="modal" 
              aria-label="Close" 
              (click)="hideModal()">
              <img src="assets/themes/icons/cancel.svg" alt="close icon">
            </span>
          </div>
           <div class="modal-body px-4 pb-4 pt-0">
              <div>
                <h5 class="heading-4 text-center">{{confirmationModalContent.headingText}}</h5>
                <p class="mt-3 text-center paragraph">{{confirmationModalContent.bodyText}}</p>
                <div class="text-right clearfix mt-4">
                  <span class="btn-design small outline mr-2 d-inline-block" (click)="allowAction(getListItem)">{{confirmationModalContent.allowButtonText}}</span>
                  <span class="btn-design small filled d-inline-block" (click)="hideModal()" >{{confirmationModalContent.cancelButtonText}}</span>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  
    <div *ngIf="isDeleteCommunityPostModalShown"
      [config]="{ show: true, backdrop: 'static' }"
      (onHidden)="onHidden()"
      bsModal
      #autoShownModal="bs-modal"
      class="modal fade confirm-box"
      tabindex="-1"
      role="dialog"
      aria-hidden="true">
      <div class="custom-theme-modal modal-dialog modal-md modal-dialog-centered px-2 delete-community-modal-container" role="document">
        <div class="modal-content">
          <div class="modal-header pt-4">
            <span class="cursor-pointer close-modal"  
              data-dismiss="modal" 
              aria-label="Close" 
              (click)="hideModal()">
              <img src="assets/themes/icons/cancel.svg" alt="close icon">
            </span>
          </div>
           <div class="modal-body p-sm-4 p-3">
              <p class="paragraph-bold">{{confirmInfo}}</p>
              <div class="text-right clearfix">
                <span class="btn-design small outline mr-2 d-inline-block"
                  (click)="deleteCommunityPostConfirmation()">{{deleteConfirm?.confirmBtnText}}</span>
                <span class="btn-design small filled d-inline-block"
                  (click)="hideModal()">{{deleteConfirm?.cancelBtnText}}</span>
              </div>
          </div>
        </div>
      </div>
    </div>