import { Pipe, PipeTransform } from '@angular/core';

// Services
import { MasterserviceService } from '../dataservices/master-service/masterservice.service';


@Pipe({
  name: 'fitInImg'
})
export class FitInImgPipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  masterData: any;

  constructor(private ms: MasterserviceService, ) {

  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transform(imgUrl: any, fitIn?: any): any {

      this.masterData = this.ms.masterApiData;
      if (this.masterData != undefined && imgUrl != null && imgUrl != '') {

    // let replace_by=this.masterData.data.media_source.image_optimization_url+fitIn+'/';
    //          imgUrl=imgUrl.replace(this.masterData.data.media_source.image_default_source_url,replace_by);

    //       return  imgUrl;

    if (imgUrl.indexOf('.gif') >= 0) {
          // console.log('this is gif image', imgUrl)
        } else {
            // console.log(this.masterData.data.media_source.image_optimization_url);
          const replace_by = this.masterData.data.media_source.image_optimization_url + fitIn + '/';
          imgUrl = imgUrl.replace(this.masterData.data.media_source.image_default_source_url, replace_by);
        }

        }
      return imgUrl;
    }


  }





