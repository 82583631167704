    <!-- modal-sm -->
    <div class="poll-card-outer">
        <div [class]="!livePollPopupOverlayHide ? 'poll-card-background ' : 'container-without-overlay'" *ngIf="enablePopup">
            <div class="poll-card">
                <!-- border-0 -->
                <div class="col-12 px-4">
                    <div>
                        <div class="pt-4 pb-2 border-bottom">
                            <div class="row">
                                <div class="col-9 heading-4">
                                    <p *ngIf="pollData">
                                        {{(this.pollData[this.poll_id]?.state === webinarPollStatus.LIVE)?
                                        mls.languageText.livePoll.key3: mls.languageText.livePoll.key4}}
                                    </p>

                                </div>
                                <div class="col-3 text-right cursor-pointer"
                                    [appAnalyticsEvent]="analyticsLivePollCloseData" (click)="hideModal()">
                                    <img src="assets/themes/icons/cancel.svg" alt="cross" />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 poll-card-mat-tab">
                                <mat-tab-group [(selectedIndex)]="livePollIndex"
                                    (selectedTabChange)="selectedTabChanged($event);">
                                    <mat-tab *ngFor="let tab of pollData; let i = index">
                                        <ng-template mat-tab-label>
                                            <div *ngIf="(tab.state === webinarPollStatus.LIVE && hasLivePoll)">
                                                <img class="poll-card-live-circle"
                                                    src="assets/images/live-poll/pulse.gif" />
                                            </div>
                                            <span>{{mls.languageText.livePoll.key1}} {{i+1}}

                                            </span>
                                        </ng-template>
                                        <span *ngIf="tab.state === webinarPollStatus.END "
                                            class="chip small filled icon-position-left mt-4 mb-2 mw-100 text-center text-overflow-handle">
                                            <img src="assets/images/live-poll/timer.svg" alt="timer" />
                                            <span class="px-2">{{mls.languageText.livePoll.key2}}</span>
                                        </span>
                                        <div [ngClass]="tab?.options?.length === numbers.TWO && tab.state === webinarPollStatus.END ? 'poll-card-container-body' : (tab?.options?.length === numbers.TWO && tab.state !== webinarPollStatus.END) ? 'poll-card-container-body-with-two-options' : 
                                        tab?.options?.length >  numbers.TWO && tab.state !== webinarPollStatus.END ? 'pop-container-body-with-multiple-options' : 'poll-card-container-body-with-multiple-options-with-end'
                                        ">
                                            <p class="mt-2 mb-2 paragraph"><span>{{tab?.title}}</span></p>
                                            <div class="mt-2 mb-2"
                                                *ngFor="let option of tab?.options; let optionIndex = index">
                                                <mat-progress-bar
                                                    class="poll-progress-bar"
                                                    [ngClass]="tab.user_selected_option === option.option_id ? 'progress-answer-two' : 'progress-answer-one'"
                                                    [mode]="mode"
                                                    [value]="(tab.state === webinarPollStatus.END || tab.user_selected_option !== numbers.ZERO)? option?.percentage:numbers.ZERO"
                                                    [bufferValue]="bufferValue1">
                                                </mat-progress-bar>
                                                <div class="position-relative">
                                                    <div [ngClass]="{'cursor-pointer': tab.can_alter === '1' && tab.state === webinarPollStatus.LIVE}"
                                                        class="poll-options-text"
                                                        (click)="setAnswer(tab, option.option_id)">
                                                        <span class="poll-option-text poll-options-text-right paragraph text-overflow-handle">
                                                            {{option?.value}}
                                                            <img class="ml-2 mb-1"
                                                                *ngIf="(tab.user_selected_option === option.option_id)"
                                                                src="assets/themes/icons/roung-check.svg"
                                                                alt="right-sign" />
                                                        </span>
                                                        <span class="paragraph-bold poll-options-text-left"
                                                            *ngIf="(tab.user_selected_option !== numbers.ZERO && livePollIndex !== numbers.MINUS_ONE) || (tab.state === webinarPollStatus.END)">{{option?.percentage}}%
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </mat-tab>
                                </mat-tab-group>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>