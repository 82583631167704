/**
 * Angular imports.
 */
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

/**
 * Component imports.
 */
import { SearchingComponent } from './searching.component';

/**
 * Module imports.
 */
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { DirectivesModule } from '../../directives/directives.module';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { SearchHistorySuggestionListComponent } from '../search-history-suggestion-list/search-history-suggestion-list.component';



@NgModule({
  declarations: [
    SearchingComponent,
    SearchHistorySuggestionListComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    InfiniteScrollModule,
    CarouselModule,
    DirectivesModule,
    PerfectScrollbarModule
  ],
  exports: [
    SearchingComponent,
    SearchHistorySuggestionListComponent
  ]
})
export class SearchingModule { }
