/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Angular imports.
 */
import { Pipe, PipeTransform } from '@angular/core';

/**
 * Group by date filter.
 */
@Pipe({ name: 'groupByDate', pure: true })
export class GroupByPipe implements PipeTransform {
  transform(record: Array<any>, property = 'ts'): Array<any> {
    if (!record) {
      return null;
    }
    const groups = record.reduce((groups, msg) => {
      const date = new Date(msg[property]).toLocaleString('en-US', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      });
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(msg);
      return groups;
    }, {});

    const groupArrays = Object.keys(groups).map((date) => {
      return {
        date: groups[date][0].ts,
        msg: groups[date]
      };
    });

    groupArrays.sort((a, b) => {
      return new Date(a.date).getTime() - new Date(b.date).getTime();
    });
    return groupArrays;
  }
}
