import { Component, Input, OnChanges, SimpleChange, AfterViewInit } from '@angular/core';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'lib-stream',
  templateUrl: './stream.component.html',
  styleUrls: ['./stream.component.scss']
})
export class StreamComponent implements OnChanges, AfterViewInit {
  /**
   * Agora audio flag.
   */
  @Input() audioMuted: boolean;

  /**
   * Agora video flag.
   */
  @Input() videoMuted: boolean;

  /**
   * Agora join channel flag.
   */
  @Input() channelJoined: boolean;

  /**
   * Agora stream.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() remoteStream: any;

  /**
   * Agora stream id.
   */
  @Input() streamId: number;

  /**
   * Volume level.
   */
  @Input() volumeLevel: number;

  /**
   * User name.
   */
  @Input() name: string;

  /**
   * Agora share screen flag.
   */
  @Input() screenShare: boolean;

  /**
   * Agora presenting screen flag.
   */
  @Input() presenting: boolean;

  /**
   * Agora share screen flag.
   */
  @Input() screenShareEnable: boolean;

  /**
   * Initial of speaker.
   */
  @Input() initials: string;

  /**
   * Checking if streaming from OBS.
   */
  @Input() isObsStreaming: boolean;

  /**
   * Get the stream changes and update teh flags.
   */
  ngOnChanges(changes: { [propKey: string]: SimpleChange }): void {

    if (changes.volumeLevel) {
      const volume = parseInt(changes.volumeLevel.currentValue, 10);
      if (this.remoteStream.audioTrack) {
        this.remoteStream.audioTrack.setVolume(volume);
      }
    }
    if (changes.channelJoined
      && changes.channelJoined.previousValue === false
      && changes.channelJoined.currentValue === true
    ) {
      const remoteVideoTrack = this.remoteStream.videoTrack;
      const remoteAudioTrack = this.remoteStream.audioTrack;
      remoteAudioTrack.play();

      remoteVideoTrack.play('stream_' + this.streamId, { fit: 'contain' }, (errState) => {
        if (errState && errState.status !== 'aborted') {
          // Check for error state
        }
      });
    }
  }

  /**
   * Set audio and video track.
   */
  ngAfterViewInit(): void {
    const remoteVideoTrack = this.remoteStream.videoTrack;
    const remoteAudioTrack = this.remoteStream.audioTrack;
    if (this.channelJoined) {
      if (remoteVideoTrack) {
        remoteVideoTrack.play('stream_' + this.streamId, { fit: 'contain' }, (errState) => {
          if (errState && errState.status !== 'aborted') {
            // Check for error state
          }
        });
      }
      if (remoteAudioTrack) {
        remoteAudioTrack.play();
      }
    }
  }
}
