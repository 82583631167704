
<!-- <angular2-multiselect [data]="dropdownList" [(ngModel)]="selectedItems"
    [settings]="dropdownSettings"
    (onSelect)="onItemSelect($event)"
    (onDeSelect)="OnItemDeSelect($event)"
    (onSelectAll)="onSelectAll($event)"
    (onDeSelectAll)="onDeSelectAll($event)">
</angular2-multiselect> -->
<div class="multi-dropdown">
<angular2-multiselect [data]="dropdownList" [(ngModel)]="selectedItems"
                      [settings]="dropdownSettings"
                      (onSelect)="onItemSelect($event)"
                      (onDeSelect)="OnItemDeSelect($event)"
                      (onSelectAll)="onSelectAll()"
                      (onDeSelectAll)="onSelectAll()"
                      (onDeSelectAll)="onDeSelectAll()">
                          <c-badge>
                           <ng-template let-item="item">
                            <img [src]="item.image" style="width: 24px; color:#000; margin-right: 5px;" onerror="this.src='assets/images/default-icon.jpg'" /> <span style="color: #333;">{{item.code}}</span>
                               </ng-template>
                           </c-badge>
                         <c-item>
                          <ng-template  let-item="item" class="new"><img [src]="item.image" style="width: 16px; color:#000" onerror="this.src='assets/images/default-icon.jpg'"  />
                            <span style="color: #333; font-size: 14px;" class="mr-1"> {{item.code}}</span>
                            <span style="color: #333;min-width: 150px; font-size: 14px;">{{item.itemName}}</span>

                          </ng-template>

                        </c-item>

</angular2-multiselect>
</div>
