/**
 * Angular imports.
 */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

/**
 * Constant imports.
 */
@Component({
  selector: 'app-floating-buttons',
  templateUrl: './floating-buttons.component.html',
  styleUrls: ['./floating-buttons.component.scss']
})
export class FloatingButtonsComponent implements OnInit {
  /**
   * Header information.
   */
  @Input() floatingOptions: { id: number, url: string }[];

  /**
   * Emit the dropdown actions.
   */
  @Output() emitAction: EventEmitter<number> = new EventEmitter();

  /**
   * Show/Hide the dropdown menu.
   * Index matching for the dropdown.
   */
  public isDropdown = false;
  public over: boolean[];

  /**
   * Adding the index for the floating.
   */
  ngOnInit(): void {
    this.over = new Array(this.floatingOptions?.length);
    this.over.fill(false);
  }

  /**
   * Emit the dropdown actions.
   */
  doAction(action: number): void {
    this.emitAction.emit(action);
    this.over.fill(false);
  }

  /**
   * Toggle progress bar status.
   */
  toggleFloating(status: boolean): void {
    this.isDropdown = status;
  }

}
