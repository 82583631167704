import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

/**
 * Service Imports.
 */
import { OpenAccessService } from '../public-module/open-access.service';
import { FunCollectionService } from '../common/common-functions/fun-collection.service';

@Component({
  selector: 'app-surl-token',
  templateUrl: './surl-token.component.html',
  styleUrls: ['./surl-token.component.scss']
})
export class SurlTokenComponent {

  /**
   * Flag for api status.
   */
  public apiInProcess = false;

  constructor(public route: ActivatedRoute, private OAS: OpenAccessService, private fcs: FunCollectionService) {
    this.route.paramMap.subscribe(params => {
      const token = params.get('token');
      this.apiInProcess = true;
      this.OAS.getsUrldata(token).subscribe(data => {
        this.apiInProcess = false;
        if (data['status'] === 1 && data['code'] === 2000) {
          if (data['data'].content_type === 'open') {
            this.fcs.openAccessWelcomeScreenData =
            {
              extras: data['data'].extras,
              pid: data['data'].product_id,
              ptype: data['data'].product_type,
            };
            this.fcs.navigateToOpenProductDetail(this.fcs.openAccessWelcomeScreenData.ptype, this.fcs.openAccessWelcomeScreenData.pid)
          }
        } else {
          this.fcs.navigateTo404();
        }
      }, (err) => {
        this.apiInProcess = false;
        this.fcs.navigateTo404();
      })
    });
  }
}
