/**
 * Keyboard shortcuts for the otp-field input.
 */
export class KeyboardUtil {

  static ifBackspaceOrDelete(event: KeyboardEvent): boolean {
    return this.ifKey(event, 'Backspace;Delete;Del');
  }
  static ifRightArrow(event: KeyboardEvent): boolean {
    return this.ifKey(event, 'ArrowRight;Right');
  }
  static ifLeftArrow(event: KeyboardEvent): boolean {
    return this.ifKey(event, 'ArrowLeft;Left');
  }
  static ifSpacebar(event: KeyboardEvent): boolean {
    /**
     * Don't remove the space after ; as this will check for space key
     */
    return this.ifKey(event, 'Spacebar; ');
  }
  static ifKey(event: KeyboardEvent, keys: string): boolean {
    const keysToCheck = keys.split(';');
    return keysToCheck.some(k => k === event.key);
  }
}
