

<!--***************welcome screen**********************-->

<div class="modal fade" tabindex="-1" role="dialog" [config]="{ show: true, backdrop: 'block' }"
  bsModal #autoShownModal="bs-modal">
  <div class="custom-theme-modal modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content">
      <div  class="position-absolute cursor-pointer" style="top:20px;right:20px; z-index: 1000;">
        <img src="assets/images/cross.svg" (click)="closePopup()"> </div>
      <div class="modal-body pt-0 px-0 pb-0">
        <div class="text-center d-flex flex-column align-item-center justify-content-center w-100 h-100" *ngIf="loading" style="min-height: 500px;">
          <div class="text-center mt-4">
            <img src="assets/themes/images/img-video-loader.gif" width="40" id="loadmore_post" alt="loader" />
          </div>
        </div>
        <div *ngIf="!loading" class="d-flex flex-column flex-lg-row align-items-center">
          <div class="d-block w-100 backgroundSlider">
            <div class="w-100 d-flex align-items-center w-100 carousel-height" style="border-radius: 24px 0 0 24px;">
        <!-- <div class="text-center d-flex flex-column align-items-center mb-4">
          <img src="assets/themes/images/without-login-bannner.svg" width="240" height="160" alt="banner"/>
          <div class="text-center mt-4 mx-4 paragraph">
            <span class="color-grey-700 d-none">Access exclusive Medical Content from across the World</span>
          </div>

        </div> -->
    <div  class="custom-owl-carousel bg-transparent shadow-none pt-4 p-0 carousel-wrapper w-100">

      <owl-carousel-o [options]="customOptionsForCarousal">
        <ng-template carouselSlide *ngFor="let card of carousaldata; let i= index">
          <!-- <div *ngIf="loader">loading</div> -->
          <div style="min-width: 100%;" class="text-center mb-4 w-100">
            <img   [src]="card?.image" width="240" height="160" alt="banner"/>
            <div class="text-center mt-4 px-2 paragraph w-100">
              <span *ngIf="i=== 0" class="color-grey-700">{{this.mls.languageText.open_article.key36}}</span>
              <span *ngIf="i=== 1" class="color-grey-700">{{this.mls.languageText.open_article.key37}}</span>
              <span *ngIf="i=== 2" class="color-grey-700">{{this.mls.languageText.open_article.key38}}</span>
            </div>
          </div>
        </ng-template>
      </owl-carousel-o>
    </div>


            </div>
          </div>
        <div>
        <div class="px-4 mx-3 mt-md-0 mt-5">

        <div class="mb-4 pb-3">
          <img src="assets/images/docuity-logo.png" width="120"/>
        </div>
        <div >
        <div class="mb-4 pb-4 heading-3">
         <div>{{greetingText}},<span class="color-red"> {{drName}}!</span></div>
         <div>{{welcomeMsg}}</div>
        </div>
        <div class="mb-4 pb-3 heading-4"><span class="color-grey-700">{{detailMsg}}</span></div>

        <!-- <div>
          <div class="mb-3 secondary-paragraph"><span class="color-grey-700">We have sent a verification mPIN to your Email ID</span></div>
          <button class="btn-design filled large w-100">Verify your ID</button>
          <div class="mt-4 text-center paragraph"><span class="color-blue">Continue without verification</span></div>
        </div> -->
        </div>
        </div>
      </div>
        </div>
      </div>
    </div>
  </div>
</div>

