
<div *ngIf="oas.businessPopUp" class="modal fade" tabindex="-1" role="dialog" [config]="{ show: true, backdrop: 'static' }"
  bsModal #autoShownModal="bs-modal">
  <div class="custom-theme-modal modal-dialog modal-md modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body pt-0">
        
        <div class="">
          
          <div class=" py-2 mt-4">
            <div class="float-right"> <img src="./assets/images/cross.svg"> </div>
            <p class="heading-4">Get in touch with us to learn more about 400,000+ Doctors</p>
            <div class="red-strip my-1"></div>
            <p class="paragraph"> </p>
            <div >
              
              

              <div class="row businesss-container mb-5" [perfectScrollbar]="config"
              infiniteScroll [infiniteScrollDistance] = "numbers.ONE" [infiniteScrollThrottle]= "numbers.ONE_THOUSAND" 
              [scrollWindow]="false">
                <div class="col-md-12 col-lg-12">
                  <div>
                    <form [formGroup]="communityForm">
                      <div class="form-row">
              

                        <div class="col-12 mb-2">
                          <div class="form-input col-12 px-0">
                          <label for="lookingFor">What are you looking for</label>
                          <div>
                          <select id="lookingFor" class="input-field cursor-pointer" formControlName="lookingFor">
                            <option value="option1">I have a business requirement</option>
                            <option value="option2">Other</option>
                           
                          </select>
                        </div>
                          <div *ngIf="lookingFor.invalid && lookingFor.touched" class="error-message">
                            Please select an option
                          </div>
                        </div>
                        </div>
                            



                        <div class="col-12 my-2">
                          <div class="form-input col-12 px-0 mb-3">
                            <label class="input-label">Name*</label>
                            <input type="text"
                              formControlName="username"
                              class="input-field"
                              placeholder="'Please Provide your Name'"
                               />
                          </div>
            
                          
                          
            
                          <div class="form-input with-icon col-12 px-0 my-3">
                            <label class="input-label">E-mail </label>
                            
                            
                                <input type="text"
                                  formControlName="email"
                                  class="input-field "
                                  placeholder="'Please provide your e-mail address'" />
                            
                          </div>

                          <div class="form-input col-12 with-icon px-0 my-3">
                            <label class="input-label">Contact Number*</label>
                              <input type="text"
                               
                                formControlName="members"
                                class="input-field"
                                placeholder="Enter Number" />
                            
                          </div>

                          <div class="form-textarea col-12 px-0">
                            <label class="textarea-label">How can Docquity help ?</label>
                            <textarea maxlength="500" formControlName="description" id="community-description" class="textarea-field"
                              placeholder="Enter your query"></textarea>
                          </div>

                        </div>
                
                      </div>
                    </form>
                  </div>
                </div>
                </div>
              

            </div>
            <div class="float-right my-1">
              <button class="btn-design small outline mx-2">
                <span>Back</span>
              </button>

              <button class="btn-design small filled ">
                <span>Next</span>
              </button>

            </div>
          </div>
  
        </div>
      </div>
      </div>
    </div>
  </div>
  