/**
 * Angular imports.
 */
 import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
 import { FormControl, FormGroup } from '@angular/forms';

 /**
  * Modal imports.
  */
 import { Config } from './config.model';

 /**
  * Helper imports.
  */
 import { KeyboardUtil } from './keyboard-util';

 /**
  * Input field for the passwords and OTP.
  */
 @Component({
   selector: 'app-otp-input',
   templateUrl: './otp-input.component.html',
   styleUrls: ['./otp-input.component.scss']
 })
 export class OtpInputComponent implements OnInit, AfterViewInit, OnChanges {

   /**
    * To show the error state.
    * Show the error message.
    * Disable the input.
    * Configuration of the input fields.
    */
   @Input() error = false;
   @Input() errorMsg = 'Please enter correct OTP';
   @Input() disabled = false;
   @Input() config: Config = {
     length: 4,
     allowNumbersOnly: true,
     isPasswordInput: false,
     disableAutoFocus: false,
     autocomplete: 'off',
     letterCase: 'Upper'
   };
   @Input() formControl: FormControl;
   @Input() isResend = false;
   @Output() isResendChange = new EventEmitter();


   /**
    * Emit the output result outside.
    */
   // tslint:disable-next-line: no-output-on-prefix
   // eslint-disable-next-line @angular-eslint/no-output-on-prefix
   @Output() onInputChange = new EventEmitter<string>();

   /**
    *  Create form group.
    *  componentKey to differentiate the input field.
    */
   public otpForm: FormGroup;
   public componentKey = Math.random()
     .toString(36)
     .substring(2) + new Date().getTime().toString(36);

   /**
    * Current value of the field.
    * InputControls with the specific length.
    */
   private currentVal: string;
   private inputControls: FormControl[] = new Array(this.config.length);

   /**
    * Get the input type based on the config.
    */
   get inputType(): string {
     return this.config?.isPasswordInput ? 'password' : this.config?.allowNumbersOnly ? 'tel' : 'text';
   }

   /**
    * Initialize the form.
    */
   ngOnInit(): void {
     this.otpForm = new FormGroup({});
     for (let index = 0; index < this.config.length; index++) {
       this.otpForm.addControl(this.getControlName(index), new FormControl());
     }
     this.otpForm.valueChanges.subscribe(() => {
       Object.keys(this.otpForm.controls).forEach((k) => {
         const val = this.otpForm.controls[k].value;
         if (val && val.length > 1) {
           if (val.length >= this.config.length) {
             this.setValue(val);
           } else {
             this.rebuildValue();
           }
         }
       });
     });
   }

   ngOnChanges(): void {
     if (this.isResend) {
       this.setValue('');
       this.isResendChange.emit(this.isResend = false);
     }
   }
   /**
    * Auto focus based on the configuration.
    */
   ngAfterViewInit(): void {
     if (!this.config.disableAutoFocus) {
       const containerItem = document.getElementById(`c_${this.componentKey}`);
       if (containerItem) {
         // eslint-disable-next-line @typescript-eslint/no-explicit-any
         const ele: any = containerItem.getElementsByClassName('input-field')[0];
         if (ele && ele.focus) {
           ele.focus();
         }
       }
     }
   }

   /**
    *  Get the unique control name.
    */
   getControlName(idx: number): string {
     return `ctrl_${idx}`;
   }

   /**
    * Remove the space bar on key down.
    */
   onKeyDown($event): boolean {
     if (KeyboardUtil.ifSpacebar($event)) {
       $event.preventDefault();
       return false;
     }
   }

   /**
    * Set the new value and check the validations.
    */
   onInput($event): void {
     const newVal = this.currentVal ? `${this.currentVal}${$event.target.value}` : $event.target.value;
     if (this.config.allowNumbersOnly && !this.validateNumber(newVal)) {
       $event.target.value = '';
       $event.stopPropagation();
       $event.preventDefault();
       return;
     }
   }

   /**
    * Append unique key.
    * Change the input field based on value, keyboard.
    */
   onKeyUp($event, inputIdx: number): string {
     const nextInputId = this.appendKey(`otp_${inputIdx + 1}`);
     const prevInputId = this.appendKey(`otp_${inputIdx - 1}`);
     if (KeyboardUtil.ifRightArrow($event)) {
       $event.preventDefault();
       this.setSelected(nextInputId);
       return;
     }
     if (KeyboardUtil.ifLeftArrow($event)) {
       $event.preventDefault();
       this.setSelected(prevInputId);
       return;
     }
     if (KeyboardUtil.ifBackspaceOrDelete($event) && !$event.target.value) {
       this.setSelected(prevInputId);
       this.rebuildValue();
       return;
     }

     if (!$event.target.value) {
       return;
     }

     if (this.ifValidKeyCode($event)) {
       this.setSelected(nextInputId);
     }
     this.rebuildValue();
   }

   /**
    *  Validation for only the number.
    */
   validateNumber(val: string): boolean {
     return val && /^\d*\.?\d*$/.test(val);
   }

   /**
    *  Append the unique key.
    */
   appendKey(id: string): string {
     return `${id}_${this.componentKey}`;
   }

   /**
    * Method sets the start and end positions of the current text selection.
    */
   setSelected(eleId: string): void {
     this.focusTo(eleId);
     // eslint-disable-next-line @typescript-eslint/no-explicit-any
     const ele: any = document.getElementById(eleId);
     if (ele && ele.setSelectionRange) {
       setTimeout(() => {
         ele.setSelectionRange(0, 1);
       }, 0);
     }
   }

   /**
    * Check the valid key code.
    */
   ifValidKeyCode($event): boolean {
     const inp = $event.key;
     const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
     return (
       isMobile ||
       /[a-zA-Z0-9-_]/.test(inp) ||
       (this.config.allowKeyCodes &&
         this.config.allowKeyCodes.includes($event.keyCode))
     );
   }

   /**
    * Set the focus.
    */
   focusTo(eleId: string): void {
     // eslint-disable-next-line @typescript-eslint/no-explicit-any
     const ele: any = document.getElementById(eleId);
     if (ele) {
       ele.focus();
     }
   }

   /**
    * method to set component value.
    */
   // eslint-disable-next-line @typescript-eslint/no-explicit-any
   setValue(value: any): void {
     if (this.config.allowNumbersOnly && isNaN(value)) {
       return;
     }
     this.otpForm.reset();
     if (!value) {
       this.rebuildValue();
       return;
     }
     value = value.toString().replace(/\s/g, '');
     Array.from(value).forEach((c, idx) => {
       if (this.otpForm.get(this.getControlName(idx))) {
         this.otpForm.get(this.getControlName(idx)).setValue(c);
       }
     });
     if (!this.config.disableAutoFocus) {
       const containerItem = document.getElementById(`c_${this.componentKey}`);
       const indexOfElementToFocus = value.length < this.config.length ? value.length : (this.config.length - 1);
       // eslint-disable-next-line @typescript-eslint/no-explicit-any
       const ele: any = containerItem.getElementsByClassName('input-field')[indexOfElementToFocus];
       if (ele && ele.focus) {
         ele.focus();
       }
     }
     this.rebuildValue();
   }

   /**
    * Rebuild the value and emit outside
    */
   rebuildValue(): void {
     let val = '';
     Object.keys(this.otpForm.controls).forEach(k => {
       if (this.otpForm.controls[k].value) {
         let ctrlVal = this.otpForm.controls[k].value;
         const isLengthExceed = ctrlVal.length > 1;
         let isCaseTransformEnabled = !this.config.allowNumbersOnly && this.config.letterCase && (this.config.letterCase.toLocaleLowerCase() == 'upper' || this.config.letterCase.toLocaleLowerCase() == 'lower');
         ctrlVal = ctrlVal[0];
         const transformedVal = isCaseTransformEnabled ? this.config.letterCase.toLocaleLowerCase() == 'upper' ? ctrlVal.toUpperCase() : ctrlVal.toLowerCase() : ctrlVal;
         if (isCaseTransformEnabled && transformedVal == ctrlVal) {
           isCaseTransformEnabled = false;
         } else {
           ctrlVal = transformedVal;
         }
         val += ctrlVal;
         if (isLengthExceed || isCaseTransformEnabled) {
           this.otpForm.controls[k].setValue(ctrlVal);
         }
       }
     });
     if (this.formControl?.setValue) {
       this.formControl.setValue(val);
     }
     this.onInputChange.emit(val);
     this.currentVal = val;
   }

   /**
    * Get pasted data via clipboard API
    * Stop data actually being pasted into div.
    */
   handlePaste($event): void {
     const clipboardData = $event.clipboardData || window['clipboardData'];
     let pastedData;
     if (clipboardData) {
        pastedData = clipboardData.getData('Text');
     }
     $event.stopPropagation();
     $event.preventDefault();
     if (!pastedData || (this.config.allowNumbersOnly && !this.validateNumber(pastedData))) {
       return;
     }
     this.setValue(pastedData);
   }
 }
