<!-- desktop header -->
<ng-container *ngIf="!fcs.device_detail.is_mobile">
  <header class="d-lg-block d-none fixed-top">
    <div>
      <div class="container">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="row">
              <div class="col-lg-4 col-9" style="padding-top:13px;">
                <div class="row align-items-center">
                  <div class="col-md-2 col-2 pr-0">
                    <a class="cursor-pointer">
                      <img src="assets/themes/icons/logo.svg" alt="docquity logo" width="46" height="40"
                        (click)="fcs.navigateToOpenProductList()" />
                    </a>
                  </div>
                  <div class="col-md-10 col-10 pr-0" (click)="loginSdk();" [pAppAnalyticsEvent]="searchEvent()">
                    <div class="custom-dropdown mb-2 cursor-pointer">
                      <div>
                        <span class="icon-left">
                          <img src="assets/themes/icons/search-icon.svg" class="mr-1 mb-1" alt="search" />
                        </span>

                        <input class="search-height" type="text" autocomplete="off" id="searchBox"
                          placeholder="{{mls.languageText.open_article.key9}}" value=""/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="hz-tabs col-lg-8 col-3 header-menu pr-0">
                <ul class="tab-items tabs mb-0 align-items-end justify-content-end pr-0">
                  <li *ngFor="let tab of tabs">
                    <label *ngIf="tab.fOnOff"
                      (click)="sendIconClickEvent(tab.pType);navigateProduct(tab.pType, tab.pType2)"
                      class="cursor-pointer d-flex py-2 px-3 hover-icon"
                      [ngClass]="{'active' : tab.pType == oas.selectedTab}">

                      <div *ngIf="tab.pType != oas.selectedTab" class="icon">
                        <img width="24" [src]="'assets/themes/icons/public/' + tab.icon" [alt]="tab.label" />
                      </div>
                      <div *ngIf="tab.pType == oas.selectedTab" class="selected-icon">
                        <img height="24" width="24" [src]="'assets/themes/icons/public/' + tab.selectedIcon"
                          [alt]="tab.label" />
                      </div>
                      <div class="secondary-paragraph ">
                        <span *ngIf="tab.pType == 47" class="color-grey-500">
                          {{mls.languageText.open_article.key10}}</span>

                        <span *ngIf="tab.pType == 3" class="color-grey-500">
                          {{mls.languageText.open_article.key12}}</span>

                        <span *ngIf="tab.pType == 24" class="color-grey-500">
                          {{mls.languageText.open_article.key11}}</span>

                        <span *ngIf="tab.pType == 27" class="color-grey-500">
                          {{mls.languageText.open_article.key13}}</span>
                      </div>
                    </label>
                  </li>
                  <li>

                    <div class="d-inline-block" (click)="isDropdownOpen = !isDropdownOpen;">
                      <button class="p-0 btn btn-design mb-4" id="dropdownBasic1" [pAppAnalyticsEvent]="analytic">
                        <img src="./assets/themes/icons/public/globe.svg" alt="globe"> <span
                          class="ml-2 paragraph-bold">{{selectedLanguage}}</span>
                        <img class="ml-2" src="assets/images/down-arrow.svg" alt="arrow">
                      </button>
                      <div *ngIf="isDropdownOpen" class="ngb-dropdown-list position-absolute mt-5 paragraph"
                        aria-labelledby="dropdownBasic1">
                        <button *ngFor="let country of oas.countryList" class="dropdown-item text-left"
                          (click)="selectLanguage(country.id, country.lang)">
                          {{ country.name }}
                        </button>
                      </div>
                    </div>
                  </li>
                  <li>
                    <label class="pl-1 pr-0">
                      <button class="btn-design small filled cursor-pointer" (click)="loginSdk()"
                        [pAppAnalyticsEvent]="loginEvent()">{{mls.languageText.open_article.key8}}
                      </button>
                    </label>
                  </li>
                </ul>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</ng-container>

<div *ngIf="fcs.device_detail.is_mobile">
  <header class="d-block d-lg-none pt-2 pb-2 fixed-top">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="row">
            <div class="col-lg-7 col-3 pr-0 d-flex align-items-center">
              <div>
              </div>
              <div>
                <a class="cursor-pointer mr-2" (click)="openExplore()">
                  <img src="assets/themes/icons/new-hamburger-icon.svg" width="20px" alt="hamburger">
                </a>

                <a class="cursor-pointer" (click)="fcs.navigateToOpenProductList()">
                  <img src="assets/themes/icons/logo.svg" alt="docquity logo" class="logo-size" />
                </a>
              </div>

            </div>

            <div class="nav-menu col-lg-5 col-9 text-right pr-4 pl-0 d-flex align-items-center">
              <span (click)="signIn()" class="mr-3 cursor-pointer" [pAppAnalyticsEvent]="searchEvent()">
                <img src="assets/themes/icons/search-icon.svg" alt="Search icon" />
              </span>

              <span class="d-inline-block" (click)="isDropdownOpen = !isDropdownOpen">
                <button class="btn btn-design-outline d-flex align-items-center" id="dropdownBasic1"
                  [pAppAnalyticsEvent]="analytic">
                  <img src="./assets/themes/icons/public/globe.svg" alt="globe"> <span
                    class="ml-2 paragraph-bold text-truncate">{{selectedLanguage}}</span>
                  <img class="ml-2" src="assets/images/down-arrow.svg" alt="arrow">
                </button>
                <div *ngIf="isDropdownOpen" class="ngb-dropdown-list mr-5 position-absolute mt-5 paragraph"
                  aria-labelledby="dropdownBasic1">
                  <button *ngFor="let country of oas.countryList" class="dropdown-item text-left"
                    (click)="selectLanguage(country.id, country.lang)">
                    {{ country.name }}
                  </button>
                </div>
              </span>

              <span class="pl-3">
                <button class="btn-design small filled cursor-pointer h-auto" (click)="loginSdk()"
                  [pAppAnalyticsEvent]="loginEvent()">{{mls.languageText.open_article.key8}}</button>
              </span>

            </div>



          </div>
        </div>
      </div>
    </div>
    <!-- mobile menu -->

    <div *ngIf="open_explore" [ngClass]="[open_explore == true ? 'explore-side-black' : '']" (click)="hideExpore()">
      <div class="explore-side-hidden ps">
        <div class="explore-user-part secondary-paragraph">
          <div class="list-mobile-menu">

            <!-- menu -->

            <!-- end menu -->
            <div class="p-3">
              <a class="secondary-paragraph d-block cursor-pointer" (click)="fcs.navigateToOpenProductList()">
                <img src="assets/themes/icons/logo.svg" alt="docquity logo" width="46" height="40" />
              </a>
            </div>

            <div *ngFor="let tab of tabs">
              <div *ngIf="tab.fOnOff"
                (click)="sendIconClickEvent(tab.pType);fcs.navigateToOpenProductList(tab.pType, tab.pType2)">
                <div class="secondary-paragraph p-3 d-block ">
                  <img width="24" [src]="'assets/themes/icons/public/' + tab.icon" [alt]="tab.label" />
                  <span class="p-3 secondary-paragraph "><span class="color-grey-500">{{tab.label}}</span></span>
                </div>
                <div class="separator-line-top"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </header>
</div>

<div #loginContainer></div>