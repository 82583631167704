/**
 * Angulare core imports
 */
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';

/**
 * service imports
 */
import { AccessTokenService } from '../auth/access-token.service';
/**
 * rxjs imports
 */
import { EMPTY, Observable } from 'rxjs';

/**
 * helper imports
 */
import { MatchUrlsHelper } from '../helper/matchUrls.helper';
import { mergeMap } from 'rxjs/operators';

@Injectable()
export class AccessTokenHeaderInterceptor implements HttpInterceptor {

  constructor(private tokenService: AccessTokenService) {}
  /**
   * intercept the request chain and add a signature header to the request for eligible APIs
   * @param request
   * @param next
   * @returns
   */
  //TODO - need to be enhanced/removed when all APIs start supporting access token in header
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    if(MatchUrlsHelper.isRtPurpleApi(request)) {
      const headers = request.headers;
      const authReq = request.clone({
        headers: headers.set(
          'signature', `Bearer ${this.tokenService.token.at}`)
          .set('refreshtoken', `${this.tokenService.token.rt}`)
        });
      const mcToken = this.tokenService.isAccessTokenAvailable();
      if( mcToken !== EMPTY) {
        return mcToken.pipe(
          mergeMap(response => {
            if(response?.at && response?.rt) {
              const authReq = request.clone({
              headers: headers.set('signature', `Bearer ${response.at}`)
                .set('refreshtoken', `${response.rt}`)
              });
              this.tokenService.tokenRequest$ = undefined;

              return next.handle(authReq);
            }
          }))
        } else {
          return next.handle(authReq);
        }
    } else {
      return next.handle(request);
    }
  }
}
