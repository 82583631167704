/**
 * Angular imports.
 */
import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe to hide the string if undefined or null.
 */
@Pipe({
  name: 'stringNullCheck'
})
export class StringNullCheckPipe implements PipeTransform {
  transform(value: string): boolean {
    if (value && (value.includes('null') || value.includes('undefined'))) {
      return false;
    }
    return true;
  }
}
