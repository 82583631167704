import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isTimeExpired'
})
export class IsTimeExpiredPipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transform(timestamp: any): any {

    const dt = new Date().getTime();
  //  alert(timestamp>dt)
    if (timestamp > dt) {
    return '';
    } else {
    return 'Ended';
    }
   }

}
