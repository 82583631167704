import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'linkify'
})
export class LinkifyPipe implements PipeTransform {

  transform(link: string): string {
   if (link != null) {

    return this.linkify(link);
   }
  }

  private linkify(plainText): string {


//       if(temp[0].includes(substring))
//       {  alert(temp[0])
//         var lastIndex = plainText.lastIndexOf(" ");

//         let str = plainText.substring(0, lastIndex);
//         plainText=str;
// alert(plainText)
//         if(plainText=='' || plainText==undefined || plainText==null)
//         {
//           plainText=temp[0];
//         }
//       }

     let replacedText;

    // URLs starting with http://, https://, or ftp://
     // eslint-disable-next-line no-useless-escape
     const replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
     replacedText = plainText.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');

    // URLs starting with "www." (without // before it, or it'd re-link the ones done above).
     // eslint-disable-next-line no-useless-escape
     const replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
     replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank">$2</a>');

    // Change email addresses to mailto:: links.
     // eslint-disable-next-line no-useless-escape
     const replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
     replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');

     if (replacedText == '') {
        return plainText;
      }
     return replacedText;


   }

}
