/**
 * Angular Imports
 */
import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

/**
 * Service imports
 */
import { FunCollectionService } from 'src/app/common/common-functions/fun-collection.service';
import { NewLanguageService } from 'src/app/dataservices/new-language/new-language.service';
import { OnboardingService } from 'src/app/dataservices/onboarding/onboarding.service';
import { OpenAccessService } from '../open-access.service';
import { PurpleOnboarding } from '../purple-onboarding.service';

/**
 * Constant imports
 */
import { COUNRY_CODE, LANG_ABBREVIATIONS, LOCAL_STORAGE, LOGIN_SDK, NUMBERS, OPEN_ACCESS, PRODUCT_TYPE_CONSTANT, SDK_PAGE_TYPE } from 'src/app/constants/app.constants';
import { IPublicAnalyticsData } from '../public-analytics/public-analytics.model';
import { EVENT_NAME } from '../public-analytics/public-analytics.constant';
import { LoginSdkParameter } from 'src/app/onboarding/interface/global.model';
import { Subscription } from 'rxjs';




/**
 * Component for the public page header
 */
@Component({
  selector: 'app-public-page-header',
  templateUrl: './public-page-header.component.html',
  styleUrls: ['./public-page-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PublicPageHeaderComponent implements OnInit {
  /**
   * Flag to track the state of explore menu
   */
  open_explore = false;

  /**
   * Flag to track the state of profile dropdown
   */
  profileDropdown: boolean;

  @Output() emitIconClick: EventEmitter<number> = new EventEmitter();
  @Input() communityText: string;
  @Input() doctalkText: string;
  @Input() articleText: string;
  @Input() webinarText: string;

  @ViewChild('loginContainer') loginContainer!: ElementRef;

  /**
 * Define an array of tabs with their respective data
 */
  public tabs = [
    { pType: OPEN_ACCESS.productlist.community, pType2: NUMBERS.ZERO, icon: 'Groups.svg', label: this.mls.languageText.open_article.key10, selectedIcon: 'Groups.svg', fOnOff: true },
    { pType: OPEN_ACCESS.productlist.doctalk, pType2: 36, icon: 'doctalk.svg', label: this.mls.languageText.open_article.key11, selectedIcon: 'doc.svg', fOnOff: true },
    { pType: OPEN_ACCESS.productlist.article, pType2: NUMBERS.ZERO, icon: 'article-void.svg', label: this.mls.languageText.open_article.key12, selectedIcon: 'articles.svg', fOnOff: true },
    { pType: OPEN_ACCESS.productlist.webinar, pType2: NUMBERS.ZERO, icon: 'webinar.svg', label: this.mls.languageText.open_article.key13, selectedIcon: 'webinar-black.svg', fOnOff: false },
  ];

  constructor(
    public mls: NewLanguageService,
    public fcs: FunCollectionService,
    private router: Router,
    public onboarding: OnboardingService,
    public oas: OpenAccessService,
    public login: PurpleOnboarding
  ) { }


  /**
   * Array of header icons with their respective data
   */
  public headerIcons = [
    { icon: 'Home.svg', label: 'HOME' },
    { icon: 'group.svg', label: 'group' },
    { icon: 'chat.svg', label: 'chat' },
    { icon: 'app.svg', label: 'app' },
    { icon: 'profile-grey.svg', label: 'profile' }
  ];

  /**
   * Default selected language
   */
  public selectedLanguage: any;

  /**
   * Constant for numbers
   */
  public numbers = NUMBERS;

  /**
   * Flag to track the state of language dropdown
   */
  public isDropdownOpen = false;

  /**
   * Variable to store client IP
   */
  public ip: string;

  /**
   * Variable to store the selected tab
   */
  public selectedTab: string;

  /**
   * Variable to store the language value
   */
  public lang_value: string;

  public countryCode: any;

  @Input() analytic: IPublicAnalyticsData;
  /**
   * subscriber for the login service
   */
  private loginServiceSub !: Subscription;


  ngOnInit(): void {
    this.tabs = [
      { pType: OPEN_ACCESS.productlist.community, pType2: NUMBERS.ZERO, icon: 'Groups.svg', label: this.mls.languageText.open_article.key10, selectedIcon: 'Groups.svg', fOnOff: true },
      { pType: OPEN_ACCESS.productlist.doctalk, pType2: 36, icon: 'doctalk.svg', label: this.mls.languageText.open_article.key11, selectedIcon: 'doc.svg', fOnOff: true },
      { pType: OPEN_ACCESS.productlist.article, pType2: NUMBERS.ZERO, icon: 'article-void.svg', label: this.mls.languageText.open_article.key12, selectedIcon: 'articles.svg', fOnOff: true },
      { pType: OPEN_ACCESS.productlist.webinar, pType2: NUMBERS.ZERO, icon: 'webinar.svg', label: this.mls.languageText.open_article.key13, selectedIcon: 'webinar-black.svg', fOnOff: false },
    ];
    const retrievedOpenAccess = JSON.parse(localStorage.getItem('openAccess'));
    if (retrievedOpenAccess) {
      this.selectedLanguage = this.getHeaderLanguage(retrievedOpenAccess.countryCode);
      if (retrievedOpenAccess.countryCode === COUNRY_CODE.BRAZIL || retrievedOpenAccess.countryCode === COUNRY_CODE.MEXICO) {
        this.tabs[3].fOnOff = true;
      }
    }
    this.oas.checkForTokenPresent();
    // this.login.login$.subscribe(() => {
    //   if (this.oas.isHeaderLoginButtonClicked) {
    //     this.router.navigateByUrl('/web/list');
    //   });
  }

  /**
   * Navigate to the home page
   */
  navigateToHome(): void {
    this.router.navigateByUrl('/web/list');
  }

  /**
   * Close hamburgur
   */
  closehamburgur(): void {
    this.open_explore = false;
  }

  /**
   * Open Explore
   */
  openExplore(): void {
    this.profileDropdown = true;
    this.open_explore = !this.open_explore;
  }

  /**
   * Hide Explore
   */
  hideExpore(): void {
    this.open_explore = false;
  }

  /**
   * Select Language
   */
  selectLanguage(val: number, lang: string): void {
    const openAccess = {
      countryCode: val,
      openLang: lang,
    };
    this.oas.countryId = val;
    localStorage.setItem('openAccess', JSON.stringify(openAccess));

    if (val === 0) {
      this.lang_value = this.mls.languageText.input_screen.key7;
      this.selectedLanguage = 'Global';
    } else if (val === 5) {
      this.lang_value = this.mls.languageText.input_screen.key8;
      this.selectedLanguage = 'Philippines';
    } else if (val === 2) {
      this.lang_value = this.mls.languageText.input_screen.key10;
      this.selectedLanguage = 'Malaysia';
    } else if (val === 4) {
      this.lang_value = this.mls.languageText.input_screen.key11;
      this.selectedLanguage = 'Indonesia';
    } else if (val === 6) {
      this.lang_value = this.mls.languageText.input_screen.key25;
      this.selectedLanguage = 'Thailand';
    } else if (val === 84) {
      this.lang_value = this.mls.languageText.input_screen.key26;
      this.selectedLanguage = 'Vietnam';
    } else if (val === 7) {
      this.lang_value = this.mls.languageText.input_screen.key25;
      this.selectedLanguage = 'Japan';
    } else if (val === 97) {
      this.lang_value = this.mls.languageText.input_screen.key25;
      this.selectedLanguage = 'Brazil';
    } else if (val === 111) {
      this.lang_value = this.mls.languageText.input_screen.key25;
      this.selectedLanguage = 'Mexico';
    }

    window.location.reload();
    this.isDropdownOpen = false;
  }

  /**
   * Get lang from service file
   */
  getLanguageByCountryCode(countryCode) {
    const country = this.oas.countryList.find((c) => c.id === countryCode);
    return country ? country.lang : 'en';
  }
  /**
   * Get country name
   */
  getHeaderLanguage(countryCode) {
    const country = this.oas.countryList.find((c) => c.id === countryCode);
    return country ? country.name : 'Global';
  }

  signIn(): void {
    this.router.navigateByUrl('/login');
  }

  /**
   * sends menu icon click event
   * @param ptype
   */
  sendIconClickEvent(ptype: number): void {
    this.emitIconClick.emit(ptype);
  }
  /**
   * sets search alaytic event
   * @returns
   */
  searchEvent(): IPublicAnalyticsData {
    return { ...this.analytic, eventName: EVENT_NAME.SEARCH_CLICK };
  }

  /**
   * sets login event
   * @returns
   */
  loginEvent(): IPublicAnalyticsData {
    return { ...this.analytic, eventName: EVENT_NAME.LOGIN_CLICK };
  }

  public loginSdk(): void {
    if (this.oas.toCallLoginSDK()) {
      this.oas.isHeaderLoginButtonClicked = true;
      this.getLoginData();
      if(this.oas.isUserLoggedIn){
        this.router.navigate(['/web/list']);
      }
      else{
      this.login.callLoginSdk(this.loginContainer);
      }
    }
    else {
      this.signIn();
    }
  }

  /**
   * get login data 
   */
  private getLoginData() {
    if (!this.loginServiceSub) {
      this.loginServiceSub = this.login.login$.subscribe(() => {
        if (this.oas.isHeaderLoginButtonClicked) {
          this.router.navigateByUrl('/web/list');
          console.log('123');
        }
      });
    }
  }

  /**
   * navigate to Product
   */
  public navigateProduct(type, type2 = 0) {
    if (type && type2 === 0 && type == PRODUCT_TYPE_CONSTANT.COMMUNITY) {
      this.loginSdk();
    } else {
      this.fcs.navigateToOpenProductList(type, type2);
    }
  }
}
