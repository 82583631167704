<div *ngIf="isConfirmModalShown" [config]="{ show: true, backdrop: masterService.modals_backdrop.confirm_modal }" (onHidden)="onHidden()"  bsModal #autoShownModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" >
  <div class="custom-theme-modal modal-dialog modal-md modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header text-center pt-4">
        <span>
          <img width="180px" src="assets/themes/icons/warning-svg.svg" alt="warning">
        </span>
        <img class="close-icon" width="24" src="assets/themes/icons/cancel.svg" alt="close">
      </div>
      <div class="modal-body pt-0 text-center">
        <h3 class="heading-3">
          {{mls?.languageText?.backscreen_modal?.key1}}<span
                *ngIf="mls?.is_lskey_display==1">(backscreen_modal.key1)</span>
        </h3>
        
        <p class="secondary-paragraph mt-2"><span class="color-gret-700">{{mls?.languageText?.backscreen_modal?.key2}}<span
          *ngIf="mls?.is_lskey_display==1">(backscreen_modal.key2)</span></span></p>
      </div>
      <div class="pb-4 text-center pt-2">
        <button class="btn-design medium outline" [appOnboardingEventTracker]='{"category":mls.googleEventText.backscreen_modal.category,"action":mls.googleEventText.backscreen_modal.key2,"product_type":"", "product_id":""}'
          (click)="hideModal()">{{mls?.languageText?.backscreen_modal?.key3}}
          <span *ngIf="mls?.is_lskey_display==1">(backscreen_modal.key3)</span>
        </button>
        <button class="btn-design medium filled ml-3" [appOnboardingEventTracker]='{"category":mls.googleEventText.backscreen_modal.category,"action":mls.googleEventText.backscreen_modal.key1,"product_type":"", "product_id":""}'
          (click)="ActivateAction()">{{mls?.languageText?.backscreen_modal?.key4}}
          <span *ngIf="mls?.is_lskey_display==1">(backscreen_modal.key4)</span>
        </button>
      </div>
    </div>
  </div>
</div>
