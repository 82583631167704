/* eslint-disable @angular-eslint/no-output-on-prefix */
/**
 * Angular imports.
 */
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

/**
 * Validator imports.
 */
import { NoSpaceValidator } from 'src/app/validators/noSpaceValidator/noSpace.validator';

/**
 * Constant imports.
 */
import { FILE_LIMITS, FILE_UPLOAD_EXTENSION, FILE_UPLOAD_SIZE, THUMBNAIL_IMAGE_DELAY, ZERO } from '../../../constants/chat.constants';
import { Subscription, of } from 'rxjs';
import { delay, tap } from 'rxjs/operators';

/**
 * Chat creator component for sending the message with emoji and file upload.
 */
@Component({
  selector: 'app-chat-creator',
  templateUrl: './chat-creator.component.html',
  styleUrls: ['./chat-creator.component.scss']
})
export class ChatCreatorComponent implements OnInit, AfterViewInit, OnDestroy {

  /**
   * Change upload image from Dom.
   * Send message field for the textarea.
   */
  @ViewChild('bannerInput') bannerInput;
  @ViewChild('sendMessageField') sendMessageField: ElementRef;

  /**
   * Show/hide submit button for msg.
   * Show/hide emoji button for msg.
   * Show/hide file upload button for msg.
   */
  @Input() showSubmitBtn = true;
  @Input() showEmoji = true;
  @Input() showFileUploader = true;
  @Input() isProfileUploadPreview: boolean;
  @Input() disableEnter: boolean;
  @Input() textAreaPlaceholder = 'Type message...';
  @Input() chatPreviewTitle = 'Send pic';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() samvaadLanguageText: any = {};
  @Input() isMobileVersion: boolean;

  /**
   * Emit the value changes.
   * Emit the value submit.
   * Emit the file upload submit.
   */
  @Output() emitValueChange: EventEmitter<string> = new EventEmitter();
  @Output() emitValueSubmit: EventEmitter<string> = new EventEmitter();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() emitFileUploadSubmit: EventEmitter<any> = new EventEmitter();
  @Output() onAttachmentClick: EventEmitter<void> = new EventEmitter();

  /**
   * Message formControl for input validation.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get message(): any | null { return this.chatForm.get('message'); }

  /**
   * Chat form for the chat creation.
   * Image src to show the preview of image.
   * Toggle the emoji.
   * Show/hide error modal.
   * Error msg to show.
   * Files store for the upload.
   * File upload limits per file default 10.
   * Loading When selecting the images.
   */
  public chatForm!: FormGroup;
  public imageSrc: string;
  public isEmoji: boolean;
  public isErrorModal: boolean;
  public errorMsg: string;
  public files = [];
  public filesLimits = FILE_LIMITS;
  public uploadingSize = FILE_UPLOAD_SIZE;
  public uploadingExtensions = FILE_UPLOAD_EXTENSION;
  public chatLoading: boolean;

  /**
   * To unsubscribe data.
   */
  private subscriptions: Subscription = new Subscription();

  /**
   * Create necessary instances.
   */
  constructor(
    private formBuilder: FormBuilder
  ) { }

  /**
   * Create chat form
   * get the comment list and start listening socket.
   */
  ngOnInit(): void {
    this.chatForm = this.formBuilder.group({
      message: ['', [Validators.required, NoSpaceValidator]],
      file: ['']
    });
  }

  /**
   * Add the focus on text area.
   */
  ngAfterViewInit(): void {
    if (!this.isMobileVersion) {
      this.focusOnTextArea();
    }
  }

  /**
   * Emit the send message.
   */
  sendMessage(): void {
    this.focusOnTextArea();
    this.chatForm.setValue({ message: this.chatForm.get('message').value.trim(), file: '' });
    /**
     * stop here if form is invalid
     */
    if (this.chatForm.invalid) {
      return;
    }
    this.isEmoji = false;
    this.emitValueSubmit.emit(this.chatForm.value.message);
    this.chatForm.setValue({ message: '', file: '' });
  }

  /**
   * Emit the ever change value in text area.
   */
  valueChange(value: string): void {
    this.emitValueChange.emit(value);
  }

  /**
   * Focus on the text area.
   */
  focusOnTextArea(): void {
    this.sendMessageField.nativeElement.focus();
  }

  /**
   * Used to receive the selected image file.
   */
  async setBanner(event): Promise<void> {
    this.emitAttachmentClick();
    if (event && event.length) {
      this.chatLoading = true;
      if (this.files.length > this.filesLimits) {
        this.errorMsg = this.samvaadLanguageText?.samvaad_validation?.key3;
        this.isErrorModal = true;
        this.chatLoading = false;
        return;
      }
      const promises = [];
      this.files = event;
      this.imageSrc = '';
      this.files.forEach((data) => {
        const file = data.selectedFile;
        const promise = this.asyncTask(file, data);
        promises.push(promise)
      });
      await Promise.all(promises);
      this.mapFiles();
    }
  }

  /**
   * Get the thumbnail for all the images.
   */
  async asyncTask(file, data): Promise<void> {
    const reader = new FileReader();
    const readerPromise = new Promise((resolve) => {
      reader.onload = (e) => {
        resolve(e.target.result);
      };
    })
    reader.readAsDataURL(file);
    const result = await readerPromise;
    this.imageSrc = result as string;
    data.banner = this.imageSrc;
    data.caption = '';
    data.file = file;
  }

  /**
   * Transform the file for the upload.
   */
  mapFiles(): void {
    this.subscriptions.add(of(true).pipe(
      delay(THUMBNAIL_IMAGE_DELAY),
      tap(() => {
        this.chatForm.controls.file.setValue(this.files[0].file);
        this.isProfileUploadPreview = true;
        this.chatLoading = false;
      })).subscribe());
  }

  /**
   * Get the entered emoji for the submit.
   */
  getEmoji(event: string): void {
    const cursor = this.sendMessageField.nativeElement;
    const startPosition = cursor.selectionStart;
    const patchedValue = this.chatForm.value.message.slice(ZERO, startPosition) + event + this.chatForm.value.message.slice(startPosition);
    this.chatForm.setValue({ message: patchedValue, file: '' });
    this.valueChange(this.chatForm.value.message);
    this.scrollToBottom();
    this.focusOnTextArea();
    const endPosition = startPosition + event.length;
    this.sendMessageField.nativeElement.setSelectionRange(endPosition, endPosition);
  }

  /**
   * To scroll to the bottom of the div.
   */
  scrollToBottom(): void {
    if (this.sendMessageField && this.sendMessageField.nativeElement) {
      this.sendMessageField.nativeElement.scrollTop = this.sendMessageField.nativeElement.scrollHeight;
    }
  }

  /**
   * Emit the file upload and clear the uploading files.
   */
  uploadPicForMessage(event): void {
    this.emitFileUploadSubmit.emit(event);
    this.files = [];
    this.closeImage(true);
    this.isProfileUploadPreview = false;
  }

  /**
   * Clear the same image from the upload input using view child bannerInput.
   */
  closeImage(removeSrc: boolean): void {
    if (this.bannerInput) {
      this.bannerInput.nativeElement.value = '';
    }
    if (removeSrc) {
      this.imageSrc = '';
    }
  }

  /**
   * Emit the attachment click event.
   */
  emitAttachmentClick(): void {
    this.onAttachmentClick.emit();
  }

  /**
   * Clear the subscriptions.
   */
  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
}
