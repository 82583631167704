/**
 * Angular imports.
 */
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

/**
 * Service imports.
 */
import { REUSABLE_COMPONENTS } from 'src/app/constants/app.constants';
import { DqChatPanelService } from '../dq-chat-panel.service';

/**
 * Show the routing full version of chat.
 */
@Component({
  selector: 'app-samvaad-chat-full-view',
  templateUrl: './samvaad-chat-full-view.component.html',
  styleUrls: ['./samvaad-chat-full-view.component.css']
})
export class SamvaadChatFullViewComponent implements OnInit {
  public reusableComponents = REUSABLE_COMPONENTS;

  /**
   * Necessary instances.
   */
  constructor(
    public dqChatPanelService: DqChatPanelService,
    private router: Router) {
  }

  /**
   * Visible chat full screen.
   * Redirect in case of chat maintenance.
   */
  ngOnInit(): void {
    this.dqChatPanelService.isChatPanelVisible = false;
    if (this.dqChatPanelService.isChatMaintenance) {
      this.router.navigateByUrl('/web/list');
    }
  }

}
