/**
 * Angular imports.
 */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

/**
 * Constant imports.
 */
import { API } from '.././constants/api.constants';
import { environment } from '../.././environments/environment';
import { EVENT_NAME } from './public-analytics/public-analytics.constant';
import { LOCAL_STORAGE, SOCIAL_NETWORK_SITE } from '../constants/app.constants';

/**
 * rxjs imports
 */
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

/**
 * Service imports
 */
import { AuthService } from '.././auth/auth.service';
import { PublicAnalyticsEventService } from './public-analytics/public-analytics-event.service';
/**
 * model import
 */
import { openArticleData, openArticleDetailData, CountryListData } from '../onboarding/interface/apiResponse.model';
import { IPublicAnalyticsData } from './public-analytics/public-analytics.model';



@Injectable({
  providedIn: 'root'
})
export class OpenAccessService {

  public userRole: string;

  public businessPopUp = false;

  public selectedTab = '';
  /**
   * Storing country id
   */
  public countryId;
  /**
   * For managing counrty list
   */
  public countryList = [
    //TODO: Countrycode for global to be discussed
    {
      id: 0,
      name: 'Global',
      lang: 'en',
      iso2: 'IN'
    },
    {
      id: 5,
      name: 'Philippines',
      lang: 'en',
      iso2: 'PH'
    },
    {
      id: 2,
      name: 'Malaysia',
      lang: 'en',
      iso2: 'MY'
    },
    {
      id: 4,
      name: 'Indonesia',
      lang: 'in',
      iso2: 'ID'
    },
    {
      id: 6,
      name: 'Thailand',
      lang: 'en',
      iso2: 'TH'
    },
    {
      id: 84,
      name: 'Vietnam',
      lang: 'vi',
      iso2: 'VN'
    },
    {
      id: 7,
      name: 'Japan',
      lang: 'ja',
      iso2: 'JP'
    },
    {
      id: 97,
      name: 'Brazil',
      lang: 'pt',
      iso2: 'BR'
    },
    {
      id: 111,
      name: 'Mexico',
      lang: 'es',
      iso2: 'MX'
    },
  ];
  public CountryListForSDK = [
    {
      id: 111,
      name: 'Mexico',
      lang: 'es',
      iso2: 'MX'
    }
  ]

  /**
   * Variable to store iso2 code
   */
  public iso2Code: string;

  /**
   * To store card data
   */
  public openArticle;

  public skipPopUp = false;
  /**
   * checks if header login button has been clicked 
   * if login successful navigate
   */
  public isHeaderLoginButtonClicked = false
  /**
   * is user logged in flag
   */
  public isUserLoggedIn = false;

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
    private paes: PublicAnalyticsEventService
  ) {

  }

  /**
   * Get api call for open-article list
   */
  getOpenArticleList(pType, offset = 1): Observable<HttpResponse<openArticleData>> {
    // pType = (pType === 0)? '': pType;
    const endPoint = `${API.OPEN_CONTENT}`;
    const id = JSON.parse(localStorage.getItem('openAccess'));
    return this.httpClient.get<any>(endPoint, {
      params: { country_id: JSON.parse(localStorage.getItem('openAccess'))?.countryCode ? JSON.parse(localStorage.getItem('openAccess')).countryCode : this.countryId, product_type: pType, offset: offset }
    }).pipe(
      map(response => response),
      catchError((error) => {
        console.log(error);
        return throwError(error);
      })
    );
  }

  /**
   * Get api call for open-article detail page
   */
  getOpenArticleDetail(product_id: string, pType: number): Observable<HttpResponse<openArticleDetailData>> {
    const headers = {
      ver: environment.versions.apiVer46,
    };
    const endPoint = `${environment.API_URLS.openContent}${API.OPEN_CONTENT_DETAIL}`;
    return this.httpClient.get<any>(endPoint, {
      params: { product_id: product_id, product_type: pType, country_id: JSON.parse(localStorage.getItem('openAccess'))?.countryCode ? JSON.parse(localStorage.getItem('openAccess')).countryCode : this.countryId, },
      headers,
    }).pipe(
      map(response => response),
      catchError((error) => {
        console.log(error);
        return throwError(error);
      })
    );
  }

  /**
   * Get api call for country code
   */
  getCountryCode(ipAddress): Observable<any> {
    const headers = {
      ver: environment.versions.apiVer46,
    };
    const endPoint = `${environment.API_URLS.openContent}${API.OPEN_CONTENT_COUNTRY}?ip_address=${ipAddress}`;
    return this.httpClient.get<any>(endPoint, {
      headers,
    }).pipe(
      map(response => response),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  /**
   * 
   * @param eventName 
   * @param record 
   */
  sendEvent(eventName: string, record: IPublicAnalyticsData): void {
    switch (eventName) {
      case SOCIAL_NETWORK_SITE.FACEBOOK:
        record = { ...record, eventName: EVENT_NAME.FB_SHARE_CLICK };
        break;
      case SOCIAL_NETWORK_SITE.TWITTER:
        record = { ...record, eventName: EVENT_NAME.TWITTER_SHARE_CLICK };
        break;
      case SOCIAL_NETWORK_SITE.LINKED_IN:
        record = { ...record, eventName: EVENT_NAME.LINKEDIN_SHARE_CLICK };
        break;
    }
    this.paes.sendAnalytics(record);
  }

  /**
   * Get api call for receiving from token(surl)
   */
  getsUrldata(token): Observable<HttpResponse<any>> {
    const endPoint = `${environment.API_URLS.openContent}${API.OPEN_CONTENT_TOKEN_DETAIL}`;
    const headers = {
      ver: environment.versions.apiVer46,
    };
    return this.httpClient.get<any>(endPoint, {
      params: { token: token },
      headers
    }).pipe(
      map(response => response),
      catchError((error) => {
        console.log(error);
        return throwError(error);
      })
    );
  }

  /**
   * 
   * @returns to check whether the current country is applicable for login sdk
   */
  toCallLoginSDK(): boolean {
    const retrievedOpenAccess = JSON.parse(localStorage.getItem('openAccess'));
    const currentCountryCode = retrievedOpenAccess?.countryCode ? retrievedOpenAccess?.countryCode : this.countryId;
    const country = this.CountryListForSDK.find((c) => c.id === currentCountryCode);
    if (country) {
      return true;
    }
    return false;
  }
  /**
   * Get Country List
   */
  getCountryList(ip_address): Observable<CountryListData> {
    const params = { ip_address: ip_address };
    return this.httpClient.get<CountryListData>(API.COUNTRY_LIST_V4, {
      params: params
    });
  }

  /**
   * check if the token is valid and present 
   */
  public checkForTokenPresent() {
    let mcToken = localStorage.getItem(LOCAL_STORAGE.TOKEN_DETAIL);
    mcToken = mcToken ? JSON.parse(mcToken) : undefined;
    if (!(mcToken && mcToken['at'] && mcToken['rt'])) {
      this.isUserLoggedIn = false;
    }else{
      this.isUserLoggedIn = true;
    }
  }
}
