/**
 * angular core import
 */
import { Pipe, PipeTransform } from '@angular/core';

/**
 * library import
 */
import * as moment from 'moment';
/**
 * a pipe to format the date using moment
 */
@Pipe({
  name: 'formatDate'
})
export class FormatDatePipe implements PipeTransform {

  transform(value = 1691406545000, format = 'dd MMM yyyy'): string {
    const date = moment(value);

    if (!date || !date.isValid()) {
      return 'Invalid Date';
    }

    return date.format(format);
  }
}
