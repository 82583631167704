export const EVENT_NAME = {
    GR_ENTRY: {
        name: 'GR_ENTRY',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    GR_VISIT: {
        name: 'GR_VISIT',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    GR_CONNECT_VIEWALL_CLICK: {
        name: 'GR_CONNECT_VIEWALL_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    GR_BANNER_CLICK: {
        name: 'GR_BANNER_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    GR_PROFILE_IMAGE_CLICK: {
        name: 'GR_PROFILE_IMAGE_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    PROFILE_NUDGE_CLICK: {
        name: 'PROFILE_NUDGE_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    WEBINAR_CARD_CLICK: {
        name: 'WEBINAR_CARD_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    CME_CARD_CLICK: {
        name: 'CME_CARD_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    DOCTALK_CARD_CLICK: {
        name: 'DOCTALK_CARD_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    REFERENCE_CARD_CLICK: {
        name: 'REFERENCE_CARD_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    POST_BAR_GALLERY_CLICK: {
        name: 'POST_BAR_GALLERY_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    POST_BAR_ASKQUESTION_CLICK: {
        name: 'POST_BAR_ASKQUESTION_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    POST_BAR_DOCUMENT_CLICK: {
        name: 'POST_BAR_DOCUMENT_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    POST_TAGPEOPLE_CLICK: {
        name: 'POST_TAGPEOPLE_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    POST_CASETOGGLE_CLICK: {
        name: 'POST_CASETOGGLE_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    POST_TAG_NEXT_CLICK: {
        name: 'POST_TAG_NEXT_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    POST_CASETYPE_NEXT_CLICK: {
        name: 'POST_CASETYPE_NEXT_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    POST_PUBLISH_CLICK: {
        name: 'POST_PUBLISH_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    POST_CONTENT_PAGE_ENTRY: {
        name: 'POST_CONTENT_PAGE_ENTRY',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    POST_SPECIALITY_SELECT_ENTRY: {
        name: 'POST_SPECIALITY_SELECT_ENTRY',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    POST_TAG_PEOPLE_ENTRY: {
        name: 'POST_TAG_PEOPLE_ENTRY',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    POST_ASSOCIATION_ENTRY: {
        name: 'POST_ASSOCIATION_ENTRY',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    POST_CONTENT_PAGE_VISIT: {
        name: 'POST_CONTENT_PAGE_VISIT',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    POST_SPECIALITY_SELECT_VISIT: {
        name: 'POST_SPECIALITY_SELECT_VISIT',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    POST_TAG_PEOPLE_VISIT: {
        name: 'POST_TAG_PEOPLE_VISIT',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    POST_ASSOCIATION_VISIT: {
        name: 'POST_ASSOCIATION_VISIT',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    NOTIFY_WEBINAR_CLICK: {
        name: 'NOTIFY_WEBINAR_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    POST_BAR_CLICK: {
        name: 'POST_BAR_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    FEED_DETAIL_ENTRY: {
        name: 'FEED_DETAIL_ENTRY',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    FEED_DETAIL_VISIT: {
        name: 'FEED_DETAIL_VISIT',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    REFERENCE_LIKE_CLICK: {
        name: 'REFERENCE_LIKE_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    REFERENCE_SHARE_CLICK: {
        name: 'REFERENCE_SHARE_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    EC_VIEWALL_CLICK: {
        name: 'EC_VIEWALL_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    PROFILE_CLICK: {
        name: 'PROFILE_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    REFERENCE_BOOKMARK_CLICK: {
        name: 'REFERENCE_BOOKMARK_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    FEED_CARD_CLICK: {
        name: 'FEED_CARD_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    FEED_BOOKMARK_CLICK: {
        name: 'FEED_BOOKMARK_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    FEED_TAGGED_SPECIALIZATION_CLICK: {
        name: 'FEED_TAGGED_SPECIALIZATION_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    FEED_VIEWMORE_CLICK: {
        name: 'FEED_VIEWMORE_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    FEED_SHARE_CLICK: {
        name: 'FEED_SHARE_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    FEED_LIKE_CLICK: {
        name: 'FEED_LIKE_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    NCP_SUBTYPE_ENTRY: {
        name: 'NCP_SUBTYPE_ENTRY',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    NCP_SUBTYPE_VISIT: {
        name: 'NCP_SUBTYPE_VISIT',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    GET_STARTED_CLICK: {
        name: 'GET_STARTED_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    CONTINUE_WATCH_CLICK: {
        name: 'CONTINUE_WATCH_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    CONTINUE_WATCH_VIEWALL_CLICK: {
        name: 'CONTINUE_WATCH_VIEWALL_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    CONTINUE_WATCH_VIEWALL_VISIT: {
        name: 'CONTINUE_WATCH_VIEWALL_VISIT',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    NCP_VIEW_ALL_CLICK: {
        name: 'NCP_VIEW_ALL_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    NCP_LIKE_CLICK: {
        name: 'NCP_LIKE_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    NCP_CARD_COMMENT_CLICK: {
        name: 'NCP_CARD_COMMENT_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    NCP_SHARE_CLICK: {
        name: 'NCP_SHARE_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    NCP_CARD_CLICK: {
        name: 'NCP_CARD_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    WEBVIEW_NCP_VISIT: {
        name: 'WEBVIEW_NCP_VISIT',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    HEALTH_CARD_SHARE_CLICK: {
        name: 'HEALTH_CARD_SHARE_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    EXPLORE_CME_CLICK: {
        name: 'EXPLORE_CME_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    EXPLORE_REFERENCE_CLICK: {
        name: 'EXPLORE_REFERENCE_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    EXPLORE_WEBINAR_CLICK: {
        name: 'EXPLORE_WEBINAR_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    EXPLORE_OTSUKA_CLICK: {
        name: 'EXPLORE_OTSUKA_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    EXPLORE_DOCTALKS_CLICK: {
        name: 'EXPLORE_DOCTALKS_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    EXPLORE_CLINICALCASE_CLICK: {
        name: 'EXPLORE_CLINICALCASE_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    EXPLORE_POLL_CLICK: {
        name: 'EXPLORE_POLL_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    EXPLORE_EVENT_CLICK: {
        name: 'EXPLORE_EVENT_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    EXPLORE_SURVEY_CLICK: {
        name: 'EXPLORE_SURVEY_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    EXPLORE_CHANNEL_CLICK: {
        name: 'EXPLORE_CHANNEL_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    EXPLORE_DOCQUITY_NEWS_CLICK: {
        name: 'EXPLORE_DOCQUITY_NEWS_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    EXPLORE_BOOKMARKS_CLICK: {
        name: 'EXPLORE_BOOKMARKS_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    HEALTH_CARD_DOWNLOAD_CLICK: {
        name: 'HEALTH_CARD_DOWNLOAD_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    EXPLORE_INSIGHT_COLLECTOR_CLICK: {
        name: 'EXPLORE_INSIGHT_COLLECTOR_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    EC_LISTING_VISIT: {
        name: 'EC_LISTING_VISIT',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    PIP_BUTTON_CLICK: {
        name: 'PIP_BUTTON_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    PIP_WALKTHROUGH_TRIGGERED: {
        name: 'PIP_WALKTHROUGH_TRIGGERED',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    NEXT_PIPWALKTHROUGH_CLICK: {
        name: 'NEXT_PIPWALKTHROUGH_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    PIP_WALKTHROUGH_CLOSE: {
        name: 'PIP_WALKTHROUGH_CLOSE',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    PIP_WALKTHROUGH_SKIP: {
        name: 'PIP_WALKTHROUGH_SKIP',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    PIP_WALKTHROUGH_COMPLETE: {
        name: 'PIP_WALKTHROUGH_COMPLETE',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    PIP_MODE_ENTRY: {
        name: 'PIP_MODE_ENTRY',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    PIP_MODE_VISIT: {
        name: 'PIP_MODE_VISIT',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    PIP_PLAY_CLICK: {
        name: 'PIP_PLAY_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    PIP_CLOSE_CLICK: {
        name: 'PIP_CLOSE_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    PIP_FULLSCREEN_CLICK: {
        name: 'PIP_FULLSCREEN_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    PLAYER_TOOLTIP_TRIGGERED: {
        name: 'PLAYER_TOOLTIP_TRIGGERED',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    TOOLTIP_CROSS_CLICK: {
        name: 'TOOLTIP_CROSS_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    TOOLTIP_LISTEN_CLICK: {
        name: 'TOOLTIP_LISTEN_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    PLAY_BUTTON_CLICK: {
        name: 'PLAY_BUTTON_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    PLAYER_TRIGGERED: {
        name: 'PLAYER_TRIGGERED',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    PLAYER_CROSS_CLICK: {
        name: 'PLAYER_CROSS_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    PLAYER_PAUSE_CLICK: {
        name: 'PLAYER_PAUSE_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    COMMUNITY_CREATIONPAGE_VISIT: {
        name: 'COMMUNITY_CREATIONPAGE_VISIT',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    FEED_MOREOPTION_CLICK: {
        name: 'FEED_MOREOPTION_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    COMMUNITY_CREATE_BUTTON_CLICK: {
        name: 'COMMUNITY_CREATE_BUTTON_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    FEED_IMAGE_CLICK: {
        name: 'FEED_IMAGE_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    COMMUNITY_SUBMIT_CLICK: {
        name: 'COMMUNITY_SUBMIT_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    POST_FLOATBUTTON_CLICK: {
        name: 'POST_FLOATBUTTON_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    COMMUNITY_DONE_CLICK: {
        name: 'COMMUNITY_DONE_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    CONNECTION_REQUEST_SENT_CLICK: {
        name: 'CONNECTION_REQUEST_SENT_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    NOTIFICATION_CARD_CLICK: {
        name: 'NOTIFICATION_CARD_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    CONNECTION_WITHDRAW_CLICK: {
        name: 'CONNECTION_WITHDRAW_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    FEED_VIEWCOMMENT_CLICK: {
        name: 'FEED_VIEWCOMMENT_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    FEED_POLL_ANSWER_CLICK: {
        name: 'FEED_POLL_ANSWER_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    FEED_DOCUMENT_CLICK: {
        name: 'FEED_DOCUMENT_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    COMMUNITY_DASHBOARD_VISIT: {
        name: 'COMMUNITY_DASHBOARD_VISIT',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    COMMUNITY_LISTINGS_VISIT: {
        name: 'COMMUNITY_LISTINGS_VISIT',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    COMMUNITY_DETAILS_VISIT: {
        name: 'COMMUNITY_DETAILS_VISIT',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    EXPLORE_COMMUNITY_CLICK: {
        name: 'EXPLORE_COMMUNITY_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    COMMUNITY_CARD_CLICK: {
        name: 'COMMUNITY_CARD_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    COMMUNITY_EDIT_CLICK: {
        name: 'COMMUNITY_EDIT_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    COMMUNITY_PAST_INACTIVE_CLICK: {
        name: 'COMMUNITY_PAST_INACTIVE_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    COMMUNITY_EXIT_CLICK: {
        name: 'COMMUNITY_EXIT_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    COMMUNITY_INACTIVATE_CLICK: {
        name: 'COMMUNITY_INACTIVATE_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    COMMUNITY_REACTIVATE_CLICK: {
        name: 'COMMUNITY_REACTIVATE_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    COMMUNITY_JOIN_CLICK: {
        name: 'COMMUNITY_JOIN_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    COMMUNITY_WITHDRAW_CLICK: {
        name: 'COMMUNITY_WITHDRAW_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    COMMUNITY_REQUEST_TO_JOIN_CLICK: {
        name: 'COMMUNITY_REQUEST_TO_JOIN_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    WEBINAR_LIVE_POLL_BUTTON_CLICK: {
        name: 'poll_button_click',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    WEBINAR_LIVE_POLL_ATTEMPT_CLICK: {
        name: 'poll_attempt_click',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    WEBINAR_LIVE_POLL_CLOSE_CLICK: {
        name: 'Poll_close_click',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    HCF_IN_PRODUCT_CARD_CLICK: {
        name: 'HCF_IN_PRODUCT_CARD_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    AUTO_SUGGESTION_CLICK: {
        name: 'AUTO_SUGGESTION_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    HCF_CLICK: {
        name: 'HCF_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    COMMUNITY_VIEW_CLICK: {
        name: 'COMMUNITY_VIEW_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    COMMUNITY_SHARE_CLICK: {
        name: 'COMMUNITY_SHARE_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    COMMUNITY_REQUEST_ACCEPT_CLICK: {
        name: 'COMMUNITY_REQUEST_ACCEPT_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    COMMUNITY_REQUEST_REJECT_CLICK: {
        name: 'COMMUNITY_REQUEST_REJECT_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    WEBINAR_SHARE_CLICK: {
        name: 'WEBINAR_SHARE_CLICK',
        hasGA: false,
        hasDQ: false,
        hasCL: false
    },
    TOOLTIP_CROSS_TU_CLICK: {
        name: 'TOOLTIP_CROSS_TU_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    WEBINAR_LIKE_CLICK: {
        name: 'WEBINAR_LIKE_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    WEBINAR_COMMENT_CLICK: {
        name: 'WEBINAR_COMMENT_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    COMMENT_POST_CLICK: {
        name: 'COMMENT_POST_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    REPOST_BUTTON_CLICK: {
        name: 'REPOST_BUTTON_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    REPOST_TOOLTIP_TRIGGERED: {
        name: 'REPOST_TOOLTIP_TRIGGERED',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    REPOST_SUCCESSFUL: {
        name: 'REPOST_SUCCESSFUL',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    SUGGESTED_CARD_CLICK: {
        name: 'Suggested_card_click',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    TAGGED_USER_CLICK: {
        name: 'TAGGED_USER_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    SEARCH_NO_RESULT_ENTRY: {
        name: 'Search_No_Result_Entry',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    CHATS_WINDOW_ENTRY : {
        name: 'CHATS_WINDOW_ENTRY',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    CHATS_SEARCH_DOCTORS_ENTRY : {
        name: 'CHATS_SEARCH_DOCTORS_ENTRY',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    CHAT_DETAIL_ENTRY : {
        name: 'CHAT_DETAIL_ENTRY',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    NEW_GROUP_ENTRY : {
        name: 'NEW_GROUP_ENTRY',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    NEW_GROUP_DETAILS_ENTRY : {
        name: 'NEW_GROUP_DETAILS_ENTRY',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    CHATS_WINDOW_VISIT : {
        name: 'CHATS_WINDOW_VISIT',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    CHATS_SEARCH_DOCTORS_VISIT : {
        name: 'CHATS_SEARCH_DOCTORS_VISIT',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    CHAT_DETAIL_VISIT : {
        name: 'CHAT_DETAIL_VISIT',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    NEW_GROUP_VISIT : {
        name: 'NEW_GROUP_VISIT',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    NEW_GROUP_DETAILS_VISIT : {
        name: 'NEW_GROUP_DETAILS_VISIT',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    CHAT_ICON_CLICK : {
        name: 'CHAT_ICON_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    CHATS_TAB_CLICK : {
        name: 'CHATS_TAB_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    START_MESSAGING_CLICK : {
        name: 'START_MESSAGING_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    CHAT_SEND_CLICK : {
        name: 'CHAT_SEND_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    NEW_GROUP_CREATE_CLICK : {
        name: 'NEW_GROUP_CREATE_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    CREATE_GROUP_DONE_CLICK : {
        name: 'CREATE_GROUP_DONE_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    CHAT_EXPANDED_VIEW_CLICK : {
        name: 'CHAT_EXPANDED_VIEW_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    ATTACH_MEDIA_CLICK : {
        name: 'ATTACH_MEDIA_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    MUTE_NOTIFICATION_CLICK : {
        name: 'MUTE_NOTIFICATION_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    BLOCK_CHAT_CLICK : {
        name: 'BLOCK_CHAT_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    UNMUTE_NOTIFICATION_CLICK : {
        name: 'UNMUTE_NOTIFICATION_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    UNBLOCK_CHAT_CLICK : {
        name: 'UNBLOCK_CHAT_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    EXIT_GROUP_CLICK : {
        name: 'EXIT_GROUP_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    REMOVE_USER_CLICK : {
        name: 'REMOVE_USER_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    GROUP_ADD_MEMBER_CLICK : {
        name: 'GROUP_ADD_MEMBER_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    MESSAGE_FAILURE : {
        name: 'MESSAGE_FAILURE',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    GR_PVS_CTA_CLICK: {
        name: 'GR_PVS_CTA_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    SAMVAAD_MAINTENANCE_POPUP_VISIT : {
        name: 'SAMVAAD_MAINTENANCE_POPUP_VISIT',
        hasGA: true,
        hasDQ: true,
        hasCL: false
    },
    REPOSTING_POPUP_TRIGGERED : {
        name: 'REPOSTING_POPUP_TRIGGERED',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    REPOST_ON_FEED_CLICK : {
        name: 'REPOST_ON_FEED_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    REPOST_ON_STORY_CLICK : {
        name: 'REPOST_ON_STORY_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    STORY_CREATION_ENTRY : {
        name: 'STORY_CREATION_ENTRY',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    STORY_CREATION_VISIT : {
        name: 'STORY_CREATION_VISIT',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    CREATE_STORY_CLICK : {
        name: 'CREATE_STORY_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    STORY_UPLOADING_TRIGGERED: {
        name: 'STORY_UPLOADING_TRIGGERED',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    STORY_TRY_AGAIN_CLICK : {
        name: 'STORY_TRY_AGAIN_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    STORY_TEXT_ADD_CLICK : {
        name: 'STORY_TEXT_ADD_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    STORY_TAG_CLICK : {
        name: 'STORY_TAG_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    STORY_CANCEL_CLICK : {
        name: 'STORY_CANCEL_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    STORY_CREATED : {
        name: 'STORY_CREATED',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    EXPLORE_BUTTON_CLICK : {
        name: 'EXPLORE_BUTTON_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    CONNECTION_DASHBOARD_ENTRY : {
        name: 'CONNECTION_DASHBOARD_ENTRY',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    PROFILE_ENTRY : {
        name: 'PROFILE_ENTRY',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    STORY_SELECT_TEMPLATE_CLICK : {
        name: 'STORY_SELECT_TEMPLATE_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    STORY_HANDLE_CLICK : {
        name: 'STORY_HANDLE_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    STORY_PLAY_ENTRY : {
        name: 'STORY_PLAY_ENTRY',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    STORY_PLAY_VISIT : {
        name: 'STORY_PLAY_VISIT',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    STORY_REACT_CLICK : {
        name: 'STORY_REACT_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    STORY_CTA_CLICK: {
      name: 'STORY_CTA_CLICK',
      hasGA: true,
      hasDQ: true,
      hasCL: true
    },
    STORY_REPOST_CLICK: {
        name: 'STORY_REPOST_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    },
    MODULE_CLICK : {
        name: 'MODULE_CLICK',
        hasGA: true,
        hasDQ: true,
        hasCL: true
    }
};

