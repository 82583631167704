/**
 * Angular imports.
 */
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

/**
 * Rxjs imports.
 */
import { Observable } from 'rxjs';


export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

/**
 * Can Deactivate Service
 */
@Injectable({
  providedIn: 'root'
})
export class CanDeactivateService {

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public nextUrl: any;

  /**
   * Can Deactivate
   */
  canDeactivate(component: CanComponentDeactivate, route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    this.nextUrl = nextState.url;
    return component.canDeactivate ? component.canDeactivate() : true;
  }
}
