<div *ngIf="(this.mls.featureOnOffList.moduleList.CHAT.value || !mls.userLoginFlow)">
<div (click)="permission !== 'all' ? checkVerification() : null">
  <h4 class="heading-4 cursor-pointer my-3" (click)="backToHistory()" *ngIf="!dqChatPanelService.isChatPanelVisible">
    <img class="mr-2" src="assets/themes/icons/back-arrow.svg" alt="back">
    {{samvaadLanguageText?.samvaad?.key14}}
  </h4>
  <lib-chat *ngIf="samvaadConfig && (samToken || authToken) && !closeChat && !dqChatPanelService.isChatMaintenance" #libChat [disableChat]="permission !== 'all'"
    [samvaadConfig]="samvaadConfig"
    [authToken]="authToken" [samvaadLanguageText]="samvaadLanguageText" [samToken]="samToken"
    [isChatListExpanded]="isChatListExpanded" [isChatListHide]="isChatListHide"
    [chatView]="dqChatPanelService.isChatPanelVisible ? 1 : 0" [isInternet]="masterService.isOnline"
    [isMobileVersion]="deviceService.isMobile()" (onChatExpand)="goToSamvaadDetail()"
    (emitReportUser)="onReportUser($event)" (emitUserDetail)="redirectToProfile($event)"
    (emitUnreadCount)="onUnreadCount($event)" (emitChatListExpanded)="onChatListExpanded($event)"
    (emitChatListHide)="OnChatListHide($event)" (emitChatClose)="setCloseChat($event)"
    (onChatDetailVisit)="onChatDetailVisit($event)" (onChatDetailLeave)="onChatDetailLeave($event)"
     (onChatListLeave)="onChatListLeave($event)" (onFailedMsg)="onFailedMsg()"
    (onGroupVisit)="onGroupVisit($event)" (onGroupLeave)="onGroupLeave($event)"
    (onNewChatVisit)="onNewChatVisit($event)" (onNewChatLeave)="onNewChatLeave($event)"
    (onNewMemberVisit)="onNewMemberVisit($event)" (onNewMemberLeave)="onNewMemberLeave($event)"
    (onStartNewChatClick)="onStartNewChatClick()" (onSendClick)="onSendClick()"
    (onCreateNewGroupClick)="onCreateNewGroupClick()" (onCreateGroupDoneClick)="onCreateGroupDoneClick()"
    (onAttachmentClick)="onAttachmentClick()" (onMuteNotificationClick)="onMuteNotificationClick()"
    (onUnmuteNotificationClick)="onUnmuteNotificationClick()" (onUnblockUserClick)="onUnblockUserClick()"
    (onBlockUserClick)="onBlockUserClick()" (onExitGroupClick)="onExitGroupClick()"
    (onMemberRemoveFromGroup)="onMemberRemoveFromGroup()" (onAddDoctorInGroup)="onAddDoctorInGroup()"
    (onChatListOpen)="onChatBarClick()" (onChatError)="resetChat($event)">
  </lib-chat>
  <ng-container *ngIf="closeChat">
    <div class="retry-box">
      <img src="assets/themes/images/no-connections.svg" alt="No Chat">
      <h4 class="heading-3 mt-4">
        Chat is not available due to some reason.
      </h4>
      <button class="btn-design medium filled mt-3" type="button" (click)="setCloseChat(false)">
        Retry
      </button>
    </div>
  </ng-container>
</div>
<div *ngIf="isReport" id="SubscribeBoxModal" class="modal fade upgrade-fade" tabindex="-1" role="dialog"
  style="z-index: 99999;" [config]="{ show: true, backdrop: 'relative' }" (onHidden)="onReportHidden()" bsModal
  #autoShownModal="bs-modal">
  <div class="custom-theme-modal modal-dialog modal-md modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header p-0">
        <span class="cursor-pointer p-3 float-right">
          <img src="assets/themes/icons/cancel.svg" alt="cross icon" (click)="onReportHidden()">
        </span>
      </div>
      <app-product-report [switchDesign]="true" [product_type]="reportProductType"
        [product_type_id]="reportProductType === productType.CHAT ? reportProductId : null"
        [uuid]="reportProductType === productType.USER ? reportProductId : null" (emitReportSubmit)="onReportHidden()">
      </app-product-report>
    </div>
  </div>
</div>
</div>