/**
 * Angular import.
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

/**
 * components import.
 */
import { BasicComponent } from './basic/basic.component';
import { AudioComponent } from './audio/audio.component';
import { HlsComponent } from './hls/hls.component';



@NgModule({
  declarations: [
    BasicComponent,
    AudioComponent,
    HlsComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    BasicComponent,
    AudioComponent,
    HlsComponent
  ]
})
export class ShakaPlayerModule { }
