/**
 * Simple change interface.
 */
export interface SimpleChange<T> {
  firstChange: boolean;
  previousValue: T;
  currentValue: T;
  isFirstChange: () => boolean;
}

/**
 * Simple call back function.
 */
export interface CallBackFunction<T> {
  // tslint:disable-next-line: callable-types
  (value: T, change?: SimpleChange<T>): void;
}

/**
 * Used the onchange as the decorator so that we can overcome some drawbacks of ngOnChanges.
 * Use this decorator above any declared variable to get the changes of that particular variable.
 * It also have the simpleChange so that u can record the first change, previous value, current value
 * without disturbing the ng cycle of the angular.
 * Hide _cachedValue from developer, no more ghost property
 * It’s not specific to Angular. So it can be used as long as it’s TypeScript such as React in TypeScript.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-function-return-type
export const OnChange = <T = any>(callback: CallBackFunction<T> | string) => {
  const cachedValueKey = Symbol();
  const isFirstChangeKey = Symbol();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (target: any, key: PropertyKey) => {
    const callBackFn: CallBackFunction<T> =
      typeof callback === 'string' ? target[callback] : callback;
    if (!callBackFn) {
      throw new Error(
        `Cannot find method ${callback} in class ${target.constructor.name}`
      );
    }

    Object.defineProperty(target, key, {
      set: function (value) {
        this[isFirstChangeKey] = this[isFirstChangeKey] === undefined;

        if (!this[isFirstChangeKey] && this[cachedValueKey] === value) {
          return;
        }

        const oldValue = this[cachedValueKey];
        this[cachedValueKey] = value;
        const simpleChange: SimpleChange<T> = {
          firstChange: this[isFirstChangeKey],
          previousValue: oldValue,
          currentValue: this[cachedValueKey],
          isFirstChange: () => this[isFirstChangeKey],
        };
        callBackFn.call(this, this[cachedValueKey], simpleChange);
      },
      get() {
        return this[cachedValueKey];
      },
    });
  };
};
