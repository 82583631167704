<div id="SubscribeBoxModal" class="modal fade success-popup" tabindex="-1" role="dialog" bsModal>
  <div class="custom-theme-modal modal-dialog modal-md modal-dialog-centered">
    <div class="row">
      <div class="col-md-7 p-0">
        <div class="position-relative px-3 px-sm-4 py-sm-5 pb-3 pt-5">
          <a class="back-page position-absolute">
            <img src="./assets/themes/icons/public/cancel.svg" width="20px" />
          </a>
          <h5 class="mb-3 heading-3">Enter OTP</h5>
          <p class="paragraph"><span class="color-grey-700">
              A One-Time Password (OTP) was sent to +91 98493 93393 <img src="./assets/themes/icons/public/edit.svg"
                width="20px" />
            </span></p>
          <!-- form -->
          <form>
            <!-- <public-user-otp-input [(isResend)]="isResend" id="verifyOtp" (onInputChange)="password = $event; setFocus2($event)"></public-user-otp-input> -->
            <!-- <div class="my-3 row">
            <label class="text-danger secondary-paragraph">{{err_msg}}</label>
            <div class="col-12">
              <app-otp-input [(isResend)]="isResend" id="verifyOtp" (onInputChange)="password = $event; setFocus2($event)"></app-otp-input>
            </div>
          </div>
          <p class="mb-4 primary-small"><span class="text-light-grey"
              [ngClass]="{'cursor-pointer secondary-paragraph color-blue':timeLeft<=0}" ngHcaptchaInvisibleButton
              (verify)="onVerify($event, testResend)"
              [appOnboardingEventTracker]='{"category":mls.googleEventText.otp_screen.category,"action":mls.googleEventText.otp_screen.key2,"product_type":"", "product_id":""}'>
              <span [ngClass]="{'color-blue':timeLeft<=0}">{{mls?.languageText?.otp_screen?.key4}}
              <span *ngIf="mls?.is_lskey_display==1">(otp_screen.key4)</span></span> <span class="ml-1"
              *ngIf="timeLeft>0">{{timeLeft}}</span></span>
          </p>
  
          <div class="form-input error mb-2" *ngIf="api_err_msg!=''" >
            <p class="help-text">{{api_err_msg}}</p>
          </div>
  
          <button class="btn-design w-100" [disabled]="showColor==false" type="submit" [ngClass]="[showColor ? 'filled' : 'filled-disabled',fcs.device_detail.is_mobile ? 'medium' : 'large']"
            id="requestOtpClick" (click)="verifyMobile()" [appOnboardingEventTracker]='{"category":mls.googleEventText.otp_screen.category,"action":mls.googleEventText.otp_screen.key3,"product_type":"", "product_id":""}'>
          <span *ngIf="api_in_process==true" class="spinner-border spinner-border-sm" role="status"
              aria-hidden="true">
          </span>
            {{mls?.languageText?.otp_screen?.key5}}
            <span *ngIf="mls?.is_lskey_display==1">
              (otp_screen.key5)
            </span>
          </button> -->
          </form>
          <!-- end form -->
        </div>
      </div>
    </div>
  </div>
</div>