  <div *ngIf="showLikeListModal" id="likeBoxModal" class="modal fade" tabindex="-1" role="dialog"
    [config]="{ show: true }" (onHidden)="ngOnDestroy()" bsModal #autoShownModal="bs-modal">
    <div class="custom-theme-modal modal-dialog modal-dialog-scrollable " role="document">
      <div class="modal-content">
        <div class="modal-header p-3">
          <span class="cursor-pointer float-right" data-dismiss="modal" aria-label="Close" (click)="ngOnDestroy()">
            <img src="assets/themes/icons/cancel.svg" alt="cross" />
          </span>
          <p class="heading-4"><span class="color-grey-500">{{doctalkLikes}} </span>
            <span *ngIf="doctalkLikes > 1">{{ mls.languageText.product_like.key1}}<span
                *ngIf="mls?.is_lskey_display==1">(product_like.key1)</span> </span>
            <span *ngIf="doctalkLikes == 1">{{mls.languageText.product_like.key2}}<span
                *ngIf="mls?.is_lskey_display==1">(product_like.key2)</span> </span>
          </p>
          <p class="heading-4"
            *ngIf="doctalkLikes == 0 || doctalkLikes == undefined || doctalkLikes == ''">
            {{mls.languageText.product_like.key3}} <span *ngIf="mls?.is_lskey_display==1">(product_like.key3)</span>
          </p>
        </div>
        <div class="modal-body pt-0 pb-2 pl-2" infiniteScroll [infiniteScrollDistance]="5"
          [infiniteScrollThrottle]="1000" [scrollWindow]="false" (scrolled)="onScrollDown();">
          <ul class="clearfix" [ngClass]="{'text-center': like_list_loader}">
            <li class="border-bottom no-last-border py-3 pl-2" *ngFor="let lL of likeList">
              <app-connection-card [callingfrom]="'like_card'" [usercardsize]="'m'" *ngIf="lL?.user_profile!=null"
                [suggObj]="lL?.user_profile" (resetSuggList)=resetSuggList($event) (closeParentModal)="ngOnDestroy()"></app-connection-card>
            </li>
            <img *ngIf="like_list_loader==true" src="assets/themes/images/img-video-loader.gif" width="40" height="40" id="loadmore_post">
          </ul>
        </div>
      </div>
    </div>
  </div>
