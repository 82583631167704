/**
 * Angular imports.
 */
import { Component, Input, Output, ElementRef, EventEmitter, OnChanges, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';

/**
 * Service imports.
 */
import { FunCollectionService } from '../../common/common-functions/fun-collection.service';
import { GetService } from '../../dataservices/get.service';
import { PostApiService } from '../../dataservices/post-api.service';
import { DataStorageService } from '../../dataservices/data-storage.service';
import { JwtDecodeService } from '../../dataservices/jwt-decode.service';
import { NewLanguageService } from '../../dataservices/new-language/new-language.service';
import { AnalyticsEventService } from '../../analytics-event/analytics-event.service';
/**
 * Constant imports.
 */
import { NUMBERS, TUTORIAL_FOR, USER_TAG_REGX, READ_KEY } from './../../constants/app.constants';

/**
 * Rxjs imports.
 */
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
/**
 * Constant imports.
 */
import { COMMENT } from '../../constants/data-testId.constants';
import { EVENT_NAME } from '../../analytics-event/analytics.constant';
import { PRODUCT_TYPE_CONSTANT, SCREEN_NAME } from '../../constants/app.constants';

/**
 * Model Imports
 */
import { AnalyticsInterface, EventDataKey } from '../../analytics-event/analytics.model';
/**
 * Product Comment Box Component
 */
@Component({
  selector: 'app-product-comment-box',
  templateUrl: './product-comment-box.component.html',
  styleUrls: ['./product-comment-box.component.scss']
})
export class ProductCommentBoxComponent implements AfterViewInit ,OnChanges, OnDestroy {
  public cmmnt_box_pic_image = {
    prflclass: 'user-40 text-center rounded-circle text-white d-table-cell align-middle fnt-size14px',
    prflpic: '',
    prflfname: '',
    prflmname: '',
    prfllname: '',
    prflrname: '',
    prflcid: '',
    prfltype: '',
    prflpicclass: 'rounded-circle user-40 post-pic',
    badge: { is_verified: 0, is_show: true },
    full_name: '',
    is_action: false,
    anonymous: '0',
    partial_permission_state: ''
  };
  @Input() comment_box_obj;
  @Input() callingFrom;
  /**
   * Input user comment data.
   */
  @Input() emitUserComment;
  @Output() resetComment = new EventEmitter();

  @Output() commentcount = new EventEmitter();
  @Output() emitHideModal = new EventEmitter();
  public comment_api_in_process = false;
  public postComment = [];
  public media_json = [];
  public comment = '';
  /**
   * Search Word For Tag
   * Actual Comment
   * Is Tag Connections Enabled
   * Is Suggestion List For Tag Enabled
   * Tutorial for Tag User
   * Tag User Suggestion Connect List
   * Tag User Suggestion Connection List offset
   * Tag User List for Iteration
   * Tag User Suggestion List Flag for API Call Finished
   * Comment Section Text as Html Element
   */
  public searchWordForTag = '';
  public actualComment = '';
  public isTagConnectionsEnabled = false;
  public isSuggestionListForTagEnabled = false;
  public tutorialFor = TUTORIAL_FOR;
  public tagSuggestionList = [];
  public tagSuggestionListOffSet = NUMBERS.ONE;
  public tagOffSet = NUMBERS.ONE;
  public tagUserList = [];
  public tagSuggestionListfinished = false;
  public commentSectionText: HTMLElement;
  public commentModelId;
  /**
   * View Child for Editable Div Element for Tagged User
   */
  @ViewChild('editableContent', { read: ElementRef }) editableContent: ElementRef;
  /**
   * Search Term to make a Switch Map call with Latest Search Term
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  searchTerm: Subject<any> = new Subject();

  /**
   * Constant for data test id.
   */
  public dataTestId = COMMENT;
  /**
   * To unsubscribe data.
   */
  private subscriptions: Subscription = new Subscription();
  /**
   * Analyticsdata object.
   */
  public analyticsData: AnalyticsInterface;
  /**
   * session time object.
   */
  public sessiontime = { start_time: '', end_time: '' };
  /**
  * Analytics object.
  */
  public analyticsWebinarComment: AnalyticsInterface;

  /**
   * Taguser search
   */
  public tagUserSearchWord = '';
  public selectAll = false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  subject: Subject<any> = new Subject();

  /**
   * Necessary instances.
   */
  constructor(
    public dss: DataStorageService,
    private postSer: PostApiService,
    public fcs: FunCollectionService,
    private jwtSer: JwtDecodeService,
    public mls: NewLanguageService,
    private analyticsEventService: AnalyticsEventService,
    public getSer: GetService) { }

  /**
   * ngOnChanges
   */
  ngOnChanges(): void {
    this.cmmnt_box_pic_image.prflcid = this.dss.user_profile_data.profile.custom_id;
    this.cmmnt_box_pic_image.prflpic = this.dss.user_profile_data.profile.profile_pic_path;
    this.cmmnt_box_pic_image.prflrname = this.dss.user_profile_data.profile.registered_name;
    this.cmmnt_box_pic_image.prflfname = this.dss.user_profile_data.profile.first_name;
    this.cmmnt_box_pic_image.prfllname = this.dss.user_profile_data.profile.last_name;
    this.cmmnt_box_pic_image.full_name = this.dss.user_profile_data.profile.first_name + ' ' + this.dss.user_profile_data.profile.middle_name + ' ' + this.dss.user_profile_data.profile.last_name;
    this.cmmnt_box_pic_image.badge.is_verified = (this.dss.user_profile_data.profile.permission == 'all') ? 1 : 0;
    if (this.emitUserComment) {
      this.comment = this.emitUserComment.title;
      this.commentSectionText.innerText = this.emitUserComment.title;
    }
    this.cmmnt_box_pic_image.partial_permission_state = this.dss.user_profile_data.profile.partial_permission_state;
    this.commentModelId = this.callingFrom?.replace('', '');
  }

  ngAfterViewInit(): void {
    this.editableContent.nativeElement.setAttribute('data-placeholder', this.mls.languageText.comment_card.key13);
    this.commentSectionText = this.editableContent.nativeElement;

    this.subject
      .pipe(debounceTime(300))
      .subscribe(() => {
        this.resetTagSuggestion();

        if (this.tagUserSearchWord.includes('@')) {
          this.openTagUserSuggestionList();
        } else {
          this.resetTagBox();
        }
        this.comment = this.commentSectionText.textContent;
        if (this.comment === '') {
          this.resetTagBox();
        }
      }
      );
  }

  /**
   * Post Comment
   */
  setComment(): void {
    this.comment = this.commentSectionText.textContent;
    let actualComment = JSON.parse(JSON.stringify(this.comment));
    for (let i = 0; i < this.tagUserList.length; i++) {
      let tagUserObj = '';
      if (actualComment.includes(this.tagUserList[i]['full_name'])) {
        tagUserObj = `{${this.tagUserList[i]['custom_id']}:${this.tagUserList[i]['full_name']}}`;
        actualComment = actualComment.replace(`@${this.tagUserList[i]['full_name']}`, tagUserObj);
      }
    }
    if (this.comment_api_in_process == true) { return; }
    this.comment_api_in_process = true;
    const product_id = this.comment_box_obj.product_id;
    const product_type = this.comment_box_obj.product_type_id;
    const file_json = [];
    if (actualComment.trim() === '') {
      this.comment_api_in_process = false;
      return;
    }
    this.postComment = [];
    this.subscriptions.add(this.postSer.productComment(product_type, product_id, actualComment.trim(), file_json, 'add').subscribe(resData => {
      this.comment_api_in_process = false;
      this.jwtSer.decodeData(resData).then(data => {
        this.media_json = [];

        if (data['status'] === NUMBERS.ONE) {
          this.fcs.showSuccess(data['msg'], 'Success');
          this.postComment.push(data['data']['comment']);
          data = { product_id: product_id, comment: data['data']['comment'] };
          setTimeout(() => {
            this.resetTagBox();
          }, 1000);
          this.comment = '';
          actualComment = '';
          document.getElementById('editableContent' + this.commentModelId + this.comment_box_obj.product_id).innerHTML = '';
          this.dss.product_comment.next(data);
          this.resetComment.emit(this.postComment);
          this.commentcount.emit(1);

          /**
           * Set analytics data for analytics event.
           */
          this.sendAnalytics(EVENT_NAME.COMMENT_POST_CLICK, product_type, product_id, this.callingFrom);

        } else if (data['status'] === NUMBERS.ZERO) {
          this.fcs.errorHandeler(data, 'generate_certificate');
        }
      }, () => {
        this.comment_api_in_process = false;
      }
      );
    }
    ));
  }

  /**
   * Check for Default Show Tutorial for Tagged User
   */
  checkToShowTutorial(): void {
    if (this.cmmnt_box_pic_image.badge.is_verified || (this.cmmnt_box_pic_image.partial_permission_state.toString() === NUMBERS.TWO.toString())) {
      if (localStorage.getItem('tagConnectionInformationCardFlag') !== 'true') {
        this.isTagConnectionsEnabled = true;
      } else {
        this.isTagConnectionsEnabled = false;
      }
    } else {
      this.isTagConnectionsEnabled = false;
    }

  }

  /**
   * Reset Tag Suggestion List
   */
  resetTagSuggestion(): void {
    this.tagSuggestionList = [];
    this.tagSuggestionListOffSet = NUMBERS.ONE;
    this.isSuggestionListForTagEnabled = false;
    this.tagSuggestionListfinished = true;
  }
  /**
   * Save Selected Tagged User
   */
  saveTaggedUserDataEmit(sugg): void {
    this.tagUserList.push(sugg);
    this.isSuggestionListForTagEnabled = false;
    this.commentSectionText.innerHTML = this.commentSectionText.innerHTML + `##${sugg.full_name}##` ;
    this.commentSectionText.innerHTML = this.commentSectionText.innerHTML.replace(this.searchWordForTag+'##'+sugg.full_name+'##', '##'+sugg.full_name+'##');
    const tagUserClassName = 'tag-user';
    setTimeout(() => {
      for (let i = 0; i < this.tagUserList.length; i++) {
        this.commentSectionText.innerHTML = this.commentSectionText.innerHTML.replace(
          (`##${this.tagUserList[i]['full_name']}##` || this.tagUserList[i]['full_name']),
          `<span contenteditable=false class="${tagUserClassName}">@${this.tagUserList[i]['full_name']}</span>&nbsp;`
        );
      }
      // put the cursor to the end of field again...
      this.selectEnd();
    }, 0);

    this.tagSuggestionList = [];
    this.tagSuggestionListOffSet = NUMBERS.ONE;
    this.comment = this.commentSectionText.textContent;
    this.searchWordForTag = '';
    this.tagUserSearchWord = '';
  }



  /**
   * put the cursor to the end of field again...
   */
  selectEnd(): void {
    let range, selection;
    // eslint-disable-next-line prefer-const
    range = document.createRange();
    range.selectNodeContents(document.getElementById(`editableContent${this.commentModelId}${this.comment_box_obj.product_id}`));
    range.collapse(false);
    // eslint-disable-next-line prefer-const
    selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
  }
  /**
   * Get User List in case of Scroll down
   */
  getOffSetUserList(event): void {
    if (event) {
      this.tagSuggestionListOffSet = event;
      this.openTagUserSuggestionList();
    }
  }
  /**
   * Close Tag Tutorial
   */
  closeTagTutorial(): void {
    localStorage.setItem('tagConnectionInformationCardFlag', 'true');
    this.isTagConnectionsEnabled = false;
  }

  /**
   * Reset Tag Box
   */
  resetTagBox(): void {
    // document.getElementById(`editableContent${this.commentModelId}${this.comment_box_obj.product_id}`).innerHTML = "";
    this.searchWordForTag = '';
    this.isSuggestionListForTagEnabled = false;
    this.comment = '';
    this.tagSuggestionList = [];
    this.tagSuggestionListOffSet = NUMBERS.ONE;
    this.searchWordForTag = '';
    this.selectAll = false;
    this.tagUserSearchWord = '';
  }

  /**
   * Send analytics event.
   */
  sendAnalytics(eventName: EventDataKey, productType: number, productTypeId: number, screenName: string, startTime = this.getTimeString(), endTime = this.getTimeString()): void {
    this.analyticsData = {
      event_name: eventName,
      product_type: productType,
      product_type_id: productTypeId,
      screen_name: screenName,
      start_time: startTime,
      end_time: endTime
    };
    this.analyticsEventService.logAnalyticsEvent(this.analyticsData);
  }
  /**
   * Get the current time in string.
   */
  getTimeString(): string {
    return new Date().getTime().toString();
  }

  /**
   * Method for analytics
   */
  generateAnalytic(): void {
    if (this.callingFrom === SCREEN_NAME.WEBINAR_DETAIL) {
      this.analyticsEventService.sendAnalytics(EVENT_NAME.WEBINAR_COMMENT_CLICK,
        PRODUCT_TYPE_CONSTANT.WEBINAR,
        this.comment_box_obj.product_id,
        SCREEN_NAME.WEBINAR_DETAIL);
    }
  }


  /**
   * Receive the user input.
   */
  userInput(event): void {
    switch (event.inputType) {
      case 'insertText': {
          if (this.selectAll) {
            this.tagUserSearchWord = event.data || '';
            this.selectAll = false;
            this.comment = '';
          } else {
            this.tagUserSearchWord = this.tagUserSearchWord + event.data;
          }
          break;
        }
      case 'deleteContentBackward': {
          if (this.selectAll) {
            this.tagUserSearchWord = event.data || '';
            this.selectAll = false;
            this.comment = '';
          } else {
            this.tagUserSearchWord = this.tagUserSearchWord.substring(0, this.tagUserSearchWord.length - 1);
          }
          break;
        }
    }

    this.subject.next();
  }

  onKeyUp(event): void {
    if (event.ctrlKey && event.key === READ_KEY.A) {
      this.selectAll = true;
    }
    if (event.key === READ_KEY.ENTER) {
      this.setComment();
    }
  }

  onKeyDown(event): void {
    if (this.fcs.isSelectingAll(event)) {
      this.selectAll = true;
    }
  }

  openTagUserSuggestionList(): void {
    if (this.tagSuggestionListOffSet === NUMBERS.ONE) {
      this.tagSuggestionListOffSet = NUMBERS.ONE;
      this.tagSuggestionList = [];
    }
    let finalText = '';
   this.searchWordForTag = this.tagUserSearchWord;
   if (this.searchWordForTag && this.searchWordForTag.includes(USER_TAG_REGX.tag_char)) {
    finalText = this.searchWordForTag;

    }


    this.tagSuggestionListfinished = false;
    finalText = finalText.replace(USER_TAG_REGX.search_char_regex, '');

    this.subscriptions.add(this.getSer.searchUserTagEntries(this.comment_box_obj.product_type_id, this.comment_box_obj.product_id, finalText, this.tagSuggestionListOffSet).subscribe(resData => {
      if (resData && resData.data && resData.status === NUMBERS.ONE) {
        if (this.tagSuggestionListOffSet > NUMBERS.ONE) {
          this.tagSuggestionList = this.tagSuggestionList.concat(resData.data.list);
        } else {
          this.tagSuggestionList = resData.data.list;
        }
        this.tagOffSet = resData.data.offset;
        this.isSuggestionListForTagEnabled = true;
        this.tagSuggestionListfinished = true;
      } else {
        this.resetTagSuggestion();
      }
    }));
  }

  /**
   * Function for close tagUser list when user click on outside of the component.
   */
  outSideClicked(): void {
    this.resetTagBox();
  }

  /**
   * unsubscribe the subscription.
   */
  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
    this.resetTagBox();
  }
}
