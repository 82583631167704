/**
 * Angular imports.
 */
import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

/**
 * Modal imports.
 */
import { DropdownSettings } from './multiselect.interface';

/**
 * Basic generic implementation of the multiselect.
 */
@Component({
  selector: 'app-basic-multiselect-select',
  templateUrl: './basic-select.component.html',
  styleUrls: ['./basic-select.component.scss']
})
export class BasicSelectComponent {

  /**
   * Used to store the template suggestions list array for dropdown.
   * Used to store the template selected list array for dropdown.
   * Used to store the suggestions list array for dropdown.
   * Used to store the selected list array for dropdown.
   * Used to store the dropdown setting.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() suggestionList: TemplateRef<any[]>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() selectedItems: TemplateRef<any[]>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() suggestionValue: any[] = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() dropdownList: any[] = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() selectedValues: any[] = [];
  @Input() dropdownSettings: DropdownSettings = {};

  /**
   * Emit the itemSelect single and array.
   * Emit the itemDeSelect single and array.
   * Emit the select all single and array.
   * Emit the de select all single and array.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() public emitOnItemSelect: EventEmitter<any> = new EventEmitter();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() public emitOnAllSelectItems: EventEmitter<any> = new EventEmitter();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() public emitOnAllDeSelectItems: EventEmitter<any> = new EventEmitter();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() public emitOnItemDeSelect: EventEmitter<any> = new EventEmitter();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() public emitOnSelectAll: EventEmitter<any> = new EventEmitter();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() public emitOnDeSelectAll: EventEmitter<any> = new EventEmitter();

  /**
   * Emit the itemSelect single and array.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onItemSelect(item: any): void {
    this.emitOnAllSelectItems.emit(this.selectedValues);
    this.emitOnItemSelect.emit(item);
  }

  /**
   * Emit the itemDeSelect single and array.
   * Disable the deselect in case of singleSelection.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onItemDeSelect(item: any): void {
    this.emitOnAllDeSelectItems.emit(this.selectedValues);
    this.emitOnItemDeSelect.emit(item);
    if (this.dropdownSettings.singleSelection) {
      this.selectedValues = [item];
      this.emitOnItemSelect.emit(item);
    }
  }

  /**
   * Emit the select all single and array.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSelectAll(items: any): void {
    this.emitOnSelectAll.emit(items);
  }

  /**
   * Emit the de select all single and array.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onDeSelectAll(items: any): void {
    this.emitOnDeSelectAll.emit(items);
  }
}
