    <div class="p-1 mb-2 bg-black-50 rounded" *ngIf="primarySponsorList.length>0">
        <div class="p-1" [ngClass]="{'cursor-pointer':item.website!='' && item.website!=null}" 
        *ngFor="let item of primarySponsorList"  (click)="navigateToSponsorWebsite(item)">
          <a title="{{item.name}}" *ngIf="(item.startTime < item.endTime && 
            item.startTime < currentTime && item.endTime > currentTime) || (item.startTime === 0)" >
            <img class="channel__sponsored-logo" [src]="item.logo" 
            onerror="this.src='assets/images/default_sponsor_logo1.svg'">
          </a>
      </div>
    </div>

    <div class="p-1 bg-black-50 clearfix rounded" *ngIf="secondarySponsorList.length>0">
      <div class="float-left w-50 p-1" [ngClass]="{'cursor-pointer':item.website!='' && item.website!=null}" 
      *ngFor="let item of secondarySponsorList" (click)="navigateToSponsorWebsite(item)">
        <a title="{{item.name}}" *ngIf="(item.startTime < item.endTime && 
        item.startTime < currentTime && item.endTime > currentTime) || (item.startTime === 0)">
          <img class="channel__sponsored-logo mw-100" [src]="item.logo" 
          onerror="this.src='assets/images/default_sponsor_logo2.svg'">
        </a>
      </div>
    </div>
    