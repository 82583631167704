/**
 * Angular imports.
 */
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

/**
 * Service imports.
 */
import { OnboardingService } from '../../../dataservices/onboarding/onboarding.service';

import { MasterserviceService } from '../../../dataservices/master-service/masterservice.service';
import { MultiLangService } from '../../../dataservices/master-service/multi-lang.service';
import { DataStorageService } from '../../../dataservices/data-storage.service';

import { NewLanguageService } from '../../../dataservices/new-language/new-language.service';
import { JwtDecodeService } from '../../../dataservices/jwt-decode.service';
import { FunCollectionService } from '../../../common/common-functions/fun-collection.service';
import { ButtonDirective } from '../.././../directives/ga.directive';

/**
 * Rxjs imports.
 */
import { Subscription } from 'rxjs';

/**
 * This component contains the userdetail form for every type of new user.
 * All new users submit their personal details.
 */
@Component({
  selector: 'app-userdetails',
  templateUrl: './userdetails.component.html',
  styleUrls: ['./userdetails.component.scss']
})
export class UserdetailsComponent implements OnInit, OnDestroy {
  /**
  * Hold can Deatctivate flag
  */
  public canDeactive = true;

  /**
  * Flag for show/hide confirmation modal
  */
  public isConfirmModalShown = false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public verify_mobile: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public req_data: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public personal_information: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public controlsdata: any = [];
  public api_in_process = false;
  public is_skippable = 1;
  public showColor = false;
  public sessiontime = { 'start_time': '', 'end_time': '' };
  public page_from = this.mls.googleEventText.userdetail_screen.category;
  /**
   * To unsubscribe data.
   */
  private subscriptions: Subscription = new Subscription();


  /**
   * Necessary instances.
   */
  constructor(public route: ActivatedRoute,
              private jwtSer: JwtDecodeService,
              private masterService: MasterserviceService,
              public Dss: DataStorageService,
              public multiLangService: MultiLangService,
              private router: Router,
              public onboardingService: OnboardingService,
              public mls: NewLanguageService,
              public fcs: FunCollectionService,
              private directive_ser: ButtonDirective) {
    const d = new Date();
    this.sessiontime.start_time = d.getTime().toString();
  }


  /**
   * ngOnInit
   */
  ngOnInit(): boolean {
    if (sessionStorage.getItem('req_data') != null) {
      this.req_data = JSON.parse(sessionStorage.getItem('req_data'));
    }
    if (sessionStorage.getItem('personal_information') == null) {
      this.router.navigate(['/login/input-mobile'], {
        queryParams: {
          redirectUrl: this.route.snapshot.queryParams.redirectUrl
        }
      });
      return false;
    } else {
      this.verify_mobile = JSON.parse(sessionStorage.getItem('personal_information'));
      if (this.verify_mobile.status == 1) {
        this.is_skippable = this.verify_mobile.data.screen_data.is_skippable;
        this.personal_information = this.verify_mobile.data.screen_data['data'];
        this.personal_information = Object.entries(this.verify_mobile.data.screen_data['data']);
        for (let i = 0; i < this.personal_information.length; i++) {
          let temp;
          if (this.personal_information[i][0] == 'first_name') {
            temp = {
              label_value: this.mls.languageText.userdetail_screen.key4,
              label_key: this.personal_information[i][0],
              type: this.personal_information[i][1].type,
              is_optional: this.personal_information[i][1].is_optional,
              hide: this.personal_information[i][1].hide,
              place_holder: this.mls.languageText.userdetail_screen.key4,
              value: '',
              err_msg: this.mls.languageText.userdetail_screen.key8,
              show_err_msg: 0,
              input_for: ''
            };
          } else if (this.personal_information[i][0] == 'middle_name') {
            temp = {
              label_value: this.mls.languageText.userdetail_screen.key5,
              label_key: this.personal_information[i][0],
              type: this.personal_information[i][1].type,
              is_optional: this.personal_information[i][1].is_optional,
              hide: this.personal_information[i][1].hide,
              place_holder: this.mls.languageText.userdetail_screen.key5,
              value: '',
              err_msg: this.mls.languageText.userdetail_screen.key9,
              show_err_msg: 0,
              input_for: ''
            };
          } else if (this.personal_information[i][0] == 'last_name') {
            temp = {
              label_value: this.mls.languageText.userdetail_screen.key6,
              label_key: this.personal_information[i][0],
              type: this.personal_information[i][1].type,
              is_optional: this.personal_information[i][1].is_optional,
              hide: this.personal_information[i][1].hide,
              place_holder: this.mls.languageText.userdetail_screen.key6,
              value: '',
              err_msg: this.mls.languageText.userdetail_screen.key10,
              show_err_msg: 0,
              input_for: ''
            };
          } else if (this.personal_information[i][0] == 'email') {
            temp = {
              label_value: this.mls.languageText.userdetail_screen.key7,
              label_key: this.personal_information[i][0],
              type: this.personal_information[i][1].type,
              is_optional: this.personal_information[i][1].is_optional,
              hide: this.personal_information[i][1].hide,
              place_holder: this.mls.languageText.userdetail_screen.key7,
              value: '',
              err_msg: this.mls.languageText.userdetail_screen.key11,
              show_err_msg: 0,
              input_for: 'email'
            };
          }
          this.controlsdata.push(temp);
        }
      }
    }
    this.fcs.IsUserActiveOnApp(this.page_from);
    this.subscriptions.add(this.Dss.is_browser_tab_active_action.subscribe(data => {
      if (data.page_from == this.page_from && data.is_visible == 0) {
        const d = new Date();
        this.sessiontime.end_time = d.getTime().toString();
        this.directive_ser.productDetailEvent(Object.assign({}, this.sessiontime), this.mls.googleEventText.userdetail_screen.key3, '', '', this.page_from, '');
      } else if (data.page_from == this.page_from && data.is_visible == 1) {
        setTimeout(() => {
          const dd = new Date();
          this.sessiontime.start_time = dd.getTime().toString();
        }, 1000);
      }
    }));

    /**
     * Check if user is already on the userdetail component.
     * If true,set can deactivate for this component.
     */
    if (this.onboardingService.directToUserdetails == true) {
      this.canDeactive = false;
    }
  }

  /**
   * If user come directly in userdetail component then on back click show confirmation modal.
   */
  back_screen(): void {
    if (this.onboardingService.directToUserdetails == true) {
      this.isConfirmModalShown = true;
    }

    window.history.back();
  }

  /**
   * user Register
   */
  userRegister(): boolean {

    let has_err = 0;
    const user_input_json = [{
      'key': 'personal_information',
      'value': { 'first_name': '', 'middle_name': '', 'last_name': '', 'email': '' }
    }];

    for (let i = 0; i < this.controlsdata.length; i++) {
      this.controlsdata[i].show_err_msg = 0;
      if (this.controlsdata[i].is_optional == 0 && this.controlsdata[i].value == '' && this.controlsdata[i].hide == 0) {
        this.controlsdata[i].show_err_msg = 1;
        if (has_err == 0) {
          has_err = 1;
        }
      } else {
        if (this.controlsdata[i].input_for == 'email' && this.controlsdata[i].value != '') {
          // eslint-disable-next-line no-useless-escape
          const regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
          const x = regex.test(this.controlsdata[i].value);
          if (x == false) {
            this.controlsdata[i].show_err_msg = 1;
            if (has_err == 0) {
              has_err = 1;
            }
          }
        }
        user_input_json[0].value[this.controlsdata[i].label_key] = this.controlsdata[i].value;
      }
    }


    if (this.masterService.isOnline == false) {
      this.masterService.isConnectionModalShown.next('1');
      return false;
    }
    if (has_err == 1) {
      return false;
    }
    this.api_in_process = true;

    this.subscriptions.add(this.onboardingService.userRegister(this.req_data.token_id, user_input_json, this.verify_mobile.data.screen_data.data.is_skippable).subscribe(registerPromise => {
      this.api_in_process = false;
      registerPromise.then(data => {
        if (data['status'] == 1) {
          this.canDeactive = true;
          if (data['code'] == 2006) {
            sessionStorage.setItem('verify_mobile', JSON.stringify(data));
            if (data['data'].screen_data.identifier == 'select_profession') {
              this.router.navigate(['/login/usertype'], {
                queryParams: {
                  redirectUrl: this.route.snapshot.queryParams.redirectUrl
                }
              });
            }
            if (data['data'].screen_data.identifier == 'personal_information') {
              sessionStorage.setItem('personal_information', JSON.stringify(data));
              this.router.navigate(['/login/userdetail'], {
                queryParams: {
                  redirectUrl: this.route.snapshot.queryParams.redirectUrl
                }
              });
            }
            if (data['data'].screen_data.identifier == 'verify_user') {
              sessionStorage.setItem('verify_user', JSON.stringify(data));
              this.router.navigate(['/login/verify-user'], {
                queryParams: {
                  redirectUrl: this.route.snapshot.queryParams.redirectUrl
                }
              });
            }
            if (data['data'].screen_data.identifier == 'select_speciality') {
              // No Code
            }
            if (data['data'].screen_data.identifier == 'select_university') {
              sessionStorage.setItem('select_university', JSON.stringify(data));
              this.router.navigate(['/login/university'], {
                queryParams: {
                  redirectUrl: this.route.snapshot.queryParams.redirectUrl
                }
              });
            }
          }
        } else {
          this.api_in_process = false;
        }
      },
      () => {
        this.api_in_process = false;
      });
    }));
  }

  /**
   * user Input
   */
  userInput(event, idx): void {
    const value = event.target.value;
    this.controlsdata[idx].value = (value.trim());
    if (this.controlsdata[idx].value != '') {
      this.controlsdata[idx].show_err_msg = 0;
    } else {
      event.target.value = event.target.value.trim();
    }
    const x = this.controlsdata.find(obj => obj.is_optional == 0 && obj.value == '' && obj.hide == 0);
    if (x == undefined) {
      this.showColor = true;
    } else {
      this.showColor = false;
    }
  }

  /**
   * skip user Detail
   */
  skipuserDetail(): void {
    // No Code
  }

  /**
   * number Input
   */
  numberInput(e): void {
    if (e.which == 13) {
      this.userRegister();
      return;
    }
  }

  /**
   * ngOnDestroy
   */
  ngOnDestroy(): void {
    const d = new Date();
    this.sessiontime.end_time = d.getTime().toString();
    this.directive_ser.productDetailEvent(Object.assign({}, this.sessiontime), this.mls.googleEventText.userdetail_screen.key3, '', '', this.page_from, '');
    this.fcs.IsUserActiveOnApp('');
    this.onboardingService.directToUserdetails = false;
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  /**
   * Used to show confirmation modal and no internet popup
   */
  canDeactivate(): Promise<boolean> | boolean {
    if (this.masterService.isOnline == false) {
      this.masterService.isConnectionModalShown.next('1');
      return;
    }
    this.isConfirmModalShown = true;
    return this.canDeactive;
  }

  /**
   * Used to get the click events from confirmation modal.
   */
  resetConfirmModal(cnfrm_act): void {
    this.canDeactive = cnfrm_act;
    this.isConfirmModalShown = false;
  }
}
