<div *ngIf="isErrorModal" id="SubscribeBoxModal" class="modal fade success-popup" style="z-index: 999999;" tabindex="-1" role="dialog"
  [config]="{ show: true, backdrop: 'relative' }" (onHidden)="onHidden()" bsModal #autoShownModal="bs-modal">
  <div class="custom-theme-modal modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header p-0">
        <div class="d-flex justify-content-end align-items-center pt-3 px-3">
          <img class="cursor-pointer" src="assets/samvaad-assets/images/icons/chat-window-cross.svg" alt="cross"
            (click)="onHidden()">
        </div>
      </div>
      <div class="modal-body rounded bg-white position-relative px-3">
        <div class="text-center">
          <img [src]="infoSrc" alt="banner">
          <h3 class="heading-3 mt-3">
            {{samvaadLanguageText?.samvaad_validation?.key4 ?? heading}}
          </h3>
          <p class="paragraph mt-2" [innerHtml]="paragraph">
          </p>
        </div>
      </div>
      <div class="modal-footer pb-4 px-3 d-flex text-right mt-3 w-100 justify-content-end">
        <button class="btn-design filled medium bg-error ml-3" (click)="onHidden()">
          {{samvaadLanguageText?.samvaad?.key25 ?? btnText}}
        </button>
      </div>
    </div>
  </div>
</div>
