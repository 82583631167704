/* eslint-disable @typescript-eslint/ban-ts-comment */
/**
 * Angular imports.
 */
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

/**
 * Service imports.
 */
import { OnboardingService } from '../../../dataservices/onboarding/onboarding.service';
import { JwtDecodeService } from '../../../dataservices/jwt-decode.service';
import { AuthService } from '../../../auth/auth.service';
import { LocalstorageService } from '../../../auth/localstorage.service';
import { MasterserviceService } from '../../../dataservices/master-service/masterservice.service';
import { MultiLangService } from '../../../dataservices/master-service/multi-lang.service';
import { DataStorageService } from '../../../dataservices/data-storage.service';
import { NewLanguageService } from '../../../dataservices/new-language/new-language.service';
import { FunCollectionService } from '../../../common/common-functions/fun-collection.service';
import { ButtonDirective } from '../.././../directives/ga.directive';

/**
 * Rxjs imports.
 */
import { Subscription } from 'rxjs';

/**
 * Give user the options to select profession as doctor,dentist or student.
 */
@Component({
  selector: 'app-usertype',
  templateUrl: './usertype.component.html',
  styleUrls: ['./usertype.component.scss']
})
export class UsertypeComponent implements OnInit, OnDestroy {
  /**
  * Hold can Deatctivate flag
  */
  public canDeactive = true;

  /**
   * Flag for show/hide confirmation modal
   */
  public isConfirmModalShown = false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public verify_mobile: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public profession_data: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public user_mob_data: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public req_data: any;
  public err_msg = '';
  public api_err_msg = '';
  public api_in_process = false;
  public user_type_doc_api_in_process = false;
  public user_type_den_api_in_process = false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public masterData: any;
  public showColor = false;
  public sessiontime = { 'start_time': '', 'end_time': '' };
  public page_from = this.mls.googleEventText.usertype_screen.category;

  /**
   * To unsubscribe data.
   */
  private subscriptions: Subscription = new Subscription();


  /**
   * Necessary instances.
   */
  constructor(
    private authService: AuthService,
    private localStorage: LocalstorageService,
    private router: Router,
    private jwtSer: JwtDecodeService,
    public onboardingService: OnboardingService,
    private masterService: MasterserviceService,
    public multiLangService: MultiLangService,
    public Dss: DataStorageService,
    public mls: NewLanguageService,
    public fcs: FunCollectionService,
    private directive_ser: ButtonDirective,
    public route: ActivatedRoute
  ) {
    const d = new Date();
    this.sessiontime.start_time = d.getTime().toString();
  }


  /**
   * ngOnInit
   */
  ngOnInit(): boolean {
    if (sessionStorage.getItem('user_mob_data') != null) {
      this.user_mob_data = JSON.parse(sessionStorage.getItem('user_mob_data'));
    }
    if (sessionStorage.getItem('req_data') != null) {
      this.req_data = JSON.parse(sessionStorage.getItem('req_data'));
    }

    if (sessionStorage.getItem('select_profession') === null) {
      this.router.navigate(['/login/input-mobile'], {
        queryParams: {
          redirectUrl: this.route.snapshot.queryParams.redirectUrl
        }
      });
      return false;
    } else {
      this.verify_mobile = JSON.parse(sessionStorage.getItem('select_profession'));
      if (this.verify_mobile.status == 1) {
        this.profession_data = this.verify_mobile.data.screen_data;
      }
    }

    this.fcs.IsUserActiveOnApp(this.page_from);
    this.subscriptions.add(this.Dss.is_browser_tab_active_action.subscribe(data => {
      if (data.page_from == this.page_from && data.is_visible == 0) {
        const d = new Date();
        this.sessiontime.end_time = d.getTime().toString();
        this.directive_ser.productDetailEvent(Object.assign({}, this.sessiontime), this.mls.googleEventText.usertype_screen.key5, '', '', this.page_from, '');
      } else if (data.page_from == this.page_from && data.is_visible == 1) {
        setTimeout(() => {
          const dd = new Date();
          this.sessiontime.start_time = dd.getTime().toString();
        }, 1000);
      }
    }));
    this.canDeactive = false;
  }

  /**
   * back screen
   */
  back_screen(): void {
    this.isConfirmModalShown = true;
  }

  /**
   * select Profession
   */
  selectProfession(data, idx): boolean {
    this.canDeactive = true;
    if (this.masterService.isOnline == false) {
      this.masterService.isConnectionModalShown.next('1');
      return false;
    }
    this.user_mob_data.usertype_idx = idx;
    sessionStorage.setItem('user_mob_data', JSON.stringify(this.user_mob_data));
    if (data.sub_profession.length > 0) {
      this.router.navigate(['/login/subprofession'], {
        queryParams: {
          redirectUrl: this.route.snapshot.queryParams.redirectUrl
        }
      });
    } else {
      const user_input_json = [{
        'key': 'select_profession',
        'value': { 'user_type_id': data.id }
      }];
      if (data.id === 1) {
        this.user_type_doc_api_in_process = true;
      } else if (data.id === 4) {
        this.user_type_den_api_in_process = true;
      }
      this.subscriptions.add(this.onboardingService.userRegister(this.req_data.token_id, user_input_json, this.profession_data.is_skippable).subscribe(registerPromise => {
        this.user_type_doc_api_in_process = false;
        this.user_type_den_api_in_process = false;
        registerPromise.then(data => {
          if (data['status'] == 1) {
            if (data['code'] == 2006) {
              if (data['data'].screen_data.identifier == 'select_profession') {
                this.router.navigate(['/login/usertype'], {
                  queryParams: {
                    redirectUrl: this.route.snapshot.queryParams.redirectUrl
                  }
                });
              }
              if (data['data'].screen_data.identifier == 'personal_information') {
                sessionStorage.setItem('personal_information', JSON.stringify(data));
                this.router.navigate(['/login/userdetail'], {
                  queryParams: {
                    redirectUrl: this.route.snapshot.queryParams.redirectUrl
                  }
                });
              }
              if (data['data'].screen_data.identifier == 'verify_user') {
                this.router.navigate(['/login/verify'], {
                  queryParams: {
                    redirectUrl: this.route.snapshot.queryParams.redirectUrl
                  }
                });
              }
              if (data['data'].screen_data.identifier == 'select_speciality') {
                // No Code
              }
              if (data['data'].screen_data.identifier == 'select_university') {
                this.router.navigate(['/login/university'], {
                  queryParams: {
                    redirectUrl: this.route.snapshot.queryParams.redirectUrl
                  }
                });
              }
            } else if (data['code'] == 2007) {
              this.localStorage.setInLocalstorage('uProfile', this.onboardingService.encryptAes(JSON.stringify(data['data'])));

              // @ts-ignore
              this.localStorage.setInLocalstorage('userpermission', this.onboardingService.encryptAes(JSON.stringify(data['data'].profile_info.profile.permission)));
              // @ts-ignore
              this.localStorage.setInLocalstorage('token', this.onboardingService.encryptAes(JSON.stringify(data['data'].profile_info.profile.user_auth_key)));
              // @ts-ignore
              this.localStorage.setInLocalstorage('profile_obj', this.onboardingService.encryptAes(JSON.stringify(data['data'].profile_info.profile.custom_id)));

              setTimeout(() => {
                this.subscriptions.add(this.masterService.getMasterDetail().subscribe(resData => {
                  this.jwtSer.decodeData(resData).then(data => {
                    this.masterData = data;
                    this.localStorage.setInSessionstorage('masterData', this.onboardingService.encryptAes(JSON.stringify(this.masterData)));
                    this.router.navigateByUrl( this.route.snapshot.queryParams.redirectUrl);
                  });
                }));
              }, 300);
            }
          }
        }, () => {
          this.user_type_doc_api_in_process = false;
          this.user_type_den_api_in_process = false;
        });

      }));
    }
  }

  /**
   * invite User
   */
  inviteUser(): boolean {
    this.err_msg = '';
    this.api_err_msg = '';
    const invitecode = document.querySelector<HTMLInputElement>('#invitecode').value;
    if (invitecode == '' || invitecode == undefined) {
      this.err_msg = this.mls.languageText.usertype_screen.key7;
      return;
    }
    if (this.masterService.isOnline == false) {
      this.masterService.isConnectionModalShown.next('1');
      return false;
    }
    this.api_in_process = true;
    this.subscriptions.add(this.onboardingService.inviteProcess(this.req_data.token_id, 'invite_process', invitecode).subscribe(resData => {
      this.api_in_process = false;
      this.jwtSer.decodeData(resData).then(data => {
        if (data['status'] == 1) {
          this.canDeactive = true;
          if (data['code'] == 2000) {
            sessionStorage.setItem('invite_process', JSON.stringify(data));
            this.router.navigate(['/login/verify'], {
              queryParams: {
                redirectUrl: this.route.snapshot.queryParams.redirectUrl
              }
            });
          }
          if (data['code'] == 2006) {
            sessionStorage.setItem('verify_mobile', JSON.stringify(data));
            if (data['data'].screen_data.identifier == 'select_profession') {
              sessionStorage.setItem('select_profession', JSON.stringify(data));
              this.router.navigate(['/login/usertype'], {
                queryParams: {
                  redirectUrl: this.route.snapshot.queryParams.redirectUrl
                }
              });
            }
            if (data['data'].screen_data.identifier == 'personal_information') {
              sessionStorage.setItem('personal_information', JSON.stringify(data));
              this.onboardingService.directToUserdetails = true;
              this.router.navigate(['/login/userdetail'], {
                queryParams: {
                  redirectUrl: this.route.snapshot.queryParams.redirectUrl
                }
              });
            }
            if (data['data'].screen_data.identifier == 'verify_user') {
              sessionStorage.setItem('invite_process', JSON.stringify(data));
              this.router.navigate(['/login/verify'], {
                queryParams: {
                  redirectUrl: this.route.snapshot.queryParams.redirectUrl
                }
              });
            }
            if (data['data'].screen_data.identifier == 'select_speciality') {
              // No Code
            }
            if (data['data'].screen_data.identifier == 'select_university') {
              sessionStorage.setItem('select_university', JSON.stringify(data));
              this.router.navigate(['/login/university'], {
                queryParams: {
                  redirectUrl: this.route.snapshot.queryParams.redirectUrl
                }
              });
              return;
            }
          }
        } else if (data['status'] == 0) {
          if (data['error'].code == 1005) {
            this.api_err_msg = data['error'].msg;
            return;
          }
        }
      },
      () => {
        this.api_in_process = false;
      }
      );
    }
    ));
  }

  /**
   * reset ErrMsg
   */
  resetErrMsg(e): void {
    this.err_msg = '';
    this.api_err_msg = '';
    if (e.target.value.trim() != '') {
      this.showColor = true;
    } else {
      e.target.value = e.target.value.trim();
      this.showColor = false;
    }
  }

  /**
   * skip Profession
   */
  skipProfession(): void {
    // No Code
  }

  /**
   * skip Profession
   */
  invitecodeInput(e): void {
    if (e.target.value.length > 0) {
      if (e.which == 13) {
        this.inviteUser();
        return;
      }
    }
  }

  /**
   * ngOnDestroy
   */
  ngOnDestroy(): void {
    const d = new Date();
    this.sessiontime.end_time = d.getTime().toString();
    this.directive_ser.productDetailEvent(Object.assign({}, this.sessiontime), this.mls.googleEventText.usertype_screen.key5, '', '', this.page_from, '');
    this.fcs.IsUserActiveOnApp('');
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  /**
   * Used to show confirmation modal and no internet popup
   */
  canDeactivate(): Promise<boolean> | boolean {
    if (this.masterService.isOnline == false) {
      this.masterService.isConnectionModalShown.next('1');
      return;
    }
    this.isConfirmModalShown = true;
    return this.canDeactive;
  }

  /**
   * Used to get the click events from confirmation modal.
   */
  resetConfirmModal(cnfrm_act): void {
    this.canDeactive = cnfrm_act;
    this.isConfirmModalShown = false;
  }

}
