  <div class="overlay"></div>
  <div [class]="tutorialPositionCss" class="position-absolute tutorial-popover">
    <div class="custom-theme-modal modal-dialog modal-md modal-dialog-centered ">
    <div class="modal-content">
      <div class="modal-body">
        <div class="bg-white">
          <div class="tutorial-popover-header">
            <div class="text-right cursor-pointer" (click)="hideModal()">
              <img src="assets/themes/icons/cancel.svg" alt="cross">
            </div>
            <span class="chip small filled error">
                {{mls?.languageText?.tutorial?.key10}}!
            </span>
            <p class="heading-4 pb-3 pt-2">
              {{tutorialTitle}}
            </p>
          </div>
          <div [class]="arrowPositionCss" class="tutorial-popover-body position-relative">
            <img class="img-border" [src]="tutorialBanner" alt="banner">
            <p class="pt-2 secondary-paragraph">
              <span class="color-grey-700">{{tutorialMsg}}.</span>
            </p>
          </div>
          <div class="tutorial-popover-footer">
            <p class="paragraph-bold cursor-pointer pt-3" (click)="startTutorial()">
              <span class="color-blue">{{tutorialCta}} <span *ngIf="isTutorialFor === tutorialFor.PIP">?</span></span>
              <span class="pl-2" *ngIf="isTutorialFor === tutorialFor.ARTICLE">
                <img src="assets/themes/icons/right-arrow.svg" alt="arrow" width="7">
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
    </div>
    <div [ngSwitch]="isTutorialFor">
      <div *ngSwitchCase="tutorialFor.PIP" class="pip-popover-icon bg-white rounded-circle">
        <img src="assets/images/pip-black.svg" alt="pip">
      </div>
      <div *ngSwitchCase="tutorialFor.ARTICLE"  class="article-popover-icon">
        <img src="assets/images/pause.svg" alt="control">
        <p class="ml-2 primary-small">{{mls?.languageText?.tutorial?.key14}}</p>
      </div>
    </div>
  </div>