export interface HTTPHeaderResponse {
    status: number,
    statusText: string
}
export interface FileUploadError {
    success: false;
}
export interface fileUploadResponse {
    status: boolean;
    fileCode: string;
    signedUrl: string;
}

export const FileUploadStatus = {
    pending: 'pending',
    completed: 'completed',
    fail: 'fail' 
}
export const MIME_TYPES = {
    JPEG: 'image/jpeg',
    PNG: 'image/png',
    JPG: 'image/jpg',
    MP4: 'video/mp4',
    WEBM: 'video/webm',
    PDF : 'application/pdf'
  };
  
  export const FILE_TYPES = {
    jpeg: 'jpeg',
    jpg: 'jpg',
    png: 'png',
    mp4: 'mp4',
    webm: 'webm',
    pdf : 'pdf',
    gif: 'gif'
  };
  export const FILE_TYPE = {
    ACCEPTED_FILE: [MIME_TYPES.PDF , MIME_TYPES.JPG , MIME_TYPES.JPEG ,MIME_TYPES.PNG , MIME_TYPES.MP4],
    IMAGE_FILE: [ MIME_TYPES.JPG , MIME_TYPES.JPEG ,MIME_TYPES.PNG ],
    VIDEO_FILE: [ MIME_TYPES.MP4 ],
    PDF_FILE: [MIME_TYPES.PDF],
    ACCEPT_ALL_TYPES : [FILE_TYPES.jpeg,FILE_TYPES.jpg,FILE_TYPES.png,FILE_TYPES.mp4,FILE_TYPES.pdf],
    ACCEPT_IMAGE : [FILE_TYPES.jpeg,FILE_TYPES.jpg,FILE_TYPES.png, FILE_TYPES.gif],
    ACCEPT_VIDEO : [FILE_TYPES.mp4],
    ACCEPT_PDF : [FILE_TYPES.pdf],
    ACCEPT_RENDER: ".png,.jpg,.jpeg,.pdf"
  }

  export interface customErrorRes{
    status: number;
    statusText: string;
    message: string;
  }

  export interface Progress {
    fileName: string;
    progress: number
  }

  export const MAX_SIZE_EACH_CHUNK = 104857600;


  export interface CREATE_STORY {
    fileCode: string,
    fileType: string,
    carouselCode: string,
    templateCode: string,
    storyType: string,
    storyTitle: string,
    cta: any,
    backgroundCode: string,
    extra:{groupCode:{association:[],speciality:[],community:[],channel:[]},entityCode:number,entityType:number},
    entityType: string,
    deeplink: string
  }
