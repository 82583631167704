/**
 * Angular imports.
 */
import { Pipe, PipeTransform } from '@angular/core';

/**
 * Library imports.
 */
import { Topic } from '@samvaad/client';

/**
 * Interface imports.
 */
import { ChatList } from '../modal/chat.interface';

/**
 * Constant imports.
 */
import { CHAT_TEXT_TYPE } from '../lib/constants/chat.constants';

/**
 * Get only the p2p list.
 */
@Pipe({
  name: 'onlyUserList',
  pure: false
})
export class OnlyUserListPipe implements PipeTransform {
  transform(chatList: ChatList[]): ChatList[] {
    if (chatList) {
      const userLists = chatList.filter(value => Topic.topicType(value?.topic) !== CHAT_TEXT_TYPE.GROUP && value.lastMsg);
      return userLists;
    } else {
      return;
    }
  }
}
