/**
 * Used to create the object for sponsors color code.
 * If value is not correct from the backend shows error.
 * Used to change the values in camel case.
 */
export class SponsorsColorCode {

    /**
     * Used to store the header color code of sponsor panel.
     */
    private _headerColor: string;
    public get headerColor(): string {
        return this._headerColor;
    }

    public set headerColor(v: string) {
        if (v === '' || v === null || v === undefined) {
            console.error('No Header Color Code From Api');
        }
        this._headerColor = v;
    }

    /**
     * Used to store the footer color code of sponsor panel.
     */
    private _footerColor: string;
    public get footerColor(): string {
        return this._footerColor;
    }
    public set footerColor(v: string) {
        if (v === '' || v === null || v === undefined) {
            throw new Error('No Header Color Code From Api');
        }
        this._footerColor = v;
    }

    constructor(headerColor, footerColor) {
        this.headerColor = headerColor;
        this.footerColor = footerColor;
    }
}
