import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-public-user-otp',
  templateUrl: './public-user-otp.component.html',
  styleUrls: ['./public-user-otp.component.css']
})
export class PublicUserOtpComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
