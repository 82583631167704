import { Injectable } from '@angular/core';
import {Router, ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { AccessTokenService } from './access-token.service';
import { FunCollectionService } from '../common/common-functions/fun-collection.service';
import { AuthService } from './auth.service';


@Injectable({
  providedIn: 'root'
})
export class OpenAccessCheckPassGuard {
  public pType : any
  public pId : any;
  constructor(public router: Router, private accessTokenService: AccessTokenService, public fcs: FunCollectionService, private authSer: AuthService
    ) {
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      const url = state.url;

      if(url.includes('open-access/public-product-list')) {
        this.pType = (url.split('open-access/public-product-list')[1]).replace('/','');
        this.pType = (this.pType)? this.pType: 0;
      } else {
        if(url.includes('open-access/public-article')) {
          this.pType = 'journal';
          this.pId = (url.split('/').pop());
        } else if(url.includes('open-access/public-doctalk')) {
          this.pType = 'doctalk';
          this.pId = (url.split('/').pop());
        } else if(url.includes('open-access/public-webinar')) {
          this.pType = 'webinar';
          this.pId = (url.split('/').pop());
        } else if(url.includes('open-access/public-dt-series')) {
          this.pType = 'doctalkseries';
          this.pId = (url.split('/').pop());
        }
      }

      if(this.authSer.isAuthenticated()) {
        this.fcs.redirectOnSection(this.pType, this.pId);
      } else {

        return true;
      }
    return true;
  }

}
