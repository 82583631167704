/**
 * Angular imports.
 */
import { Component, OnInit, Input, Output, EventEmitter, OnChanges, TemplateRef, OnDestroy } from '@angular/core';

/**
 * Service imports.
 */
import { AuthService } from '../../auth/auth.service';
import { NewLanguageService } from './../../dataservices/new-language/new-language.service';
import { FunCollectionService } from '../../common/common-functions/fun-collection.service';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { ImageGroupService } from '../../dataservices/master-service/image-group.service';
import { JwtDecodeService } from '../../dataservices/jwt-decode.service';
import { GetService } from '../../dataservices/get.service';
import { PostApiService } from '../../dataservices/post-api.service';
import { MasterserviceService } from './../../dataservices/master-service/masterservice.service';

/**
 * Modal imports.
 */
import { BsModalService } from 'ngx-bootstrap/modal';

/**
 * Rxjs imports.
 */
import { Subscription } from 'rxjs';


/**
 * Product GetSample Component
 */
@Component({
  selector: 'app-product-get-sample',
  templateUrl: './product-get-sample.component.html',
  styleUrls: ['./product-get-sample.component.scss']
})
export class ProductGetSampleComponent implements OnInit, OnChanges, OnDestroy {

  @Output() resetProductGetsampleModal = new EventEmitter();
  @Input() isGetsampleModal: boolean;
  @Input() product_type: string;
  @Input() product_type_id: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() JournalData: any;
  @Input() drug_id;
  public offset = 1;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public sampleOrderList: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public sample_list_data: any;
  public drugDetail = '';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public drug_detail_data: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public drugOrder: any;
  public finished = false;
  public sampleOrderInfoModalShown = false;
  public isShowComposition = false;
  public isConfirmModalShown = false;
  public isErrorModalShown = false;
  public error_msg: string;
  public minDate: Date = new Date();
  public maxDate: Date = new Date();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public sample_order_data: any;
  public modalRef: BsModalRef;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public userProfileData: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public user_profile: any;
  public custome_id: string;
  public frm_name: string;
  public frm_mobile: string;
  public frm_email: string;
  public frm_address: string;
  public frm_pincode: string;
  public frm_date: string;
  public frm_time_range = 'Select Time Range';
  public frm_time_range_id = 0;
  public frm_comment: string;
  public errfrm_name = '';
  public errfrm_mobile = '';
  public errfrm_email = '';
  public errfrm_address = '';
  public errfrm_pincode = '';
  public errfrm_date = '';
  public errfrm_time_range = '';
  public errfrm_time_range_id = '';
  public errfrm_comment = '';
  public confirmInfo = '';
  public TimeRange_data = {
    placeholderL: 'Select Time Range',
    single_select: true,
    enable_search: false,
    selected_data: [],
    list_data: [],
    identifier: 'timerange'
  };

  /**
   * To unsubscribe data.
   */
   private subscriptions: Subscription = new Subscription();


  /**
   * Necessary instances created.
   */
  constructor(
    public igs: ImageGroupService,
    public mls: NewLanguageService,
    private getsampleService: GetService,
    public funCollectionService: FunCollectionService,
    private modalService: BsModalService,
    private masterService: MasterserviceService,
    private jwtSer: JwtDecodeService,
    private postservice: PostApiService,
    private authService: AuthService) {

    if (this.product_type === undefined) {
      this.product_type = '';
    }
    if (this.product_type_id === undefined) {
      this.product_type_id = '';
    }
  }

  /**
   * ngOnInit
   */
  ngOnInit(): void {
    this.minDate.setDate(this.minDate.getDate() + 14);
    this.maxDate.setDate(this.minDate.getDate() + 180);

    this.custome_id = this.authService.getbyName('profile_obj');
    this.subscriptions.add(this.masterService.getProfile()
      .subscribe(data => {
        this.userProfileData = data;
        this.user_profile = this.userProfileData.data.profile;
      }));

  }

  /**
   * openModal
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  openModal(template: TemplateRef<any>): void {
    this.modalRef = this.modalService.show(template);
  }

  /**
   * ngOnChanges
   */
  ngOnChanges(): void {
    if (this.isGetsampleModal == true) {
      this.sampleList(this.offset);
    }
  }

  /**
   * sample List
   */
  sampleList(offset): void {

    this.subscriptions.add(this.getsampleService.getSampleist(0, offset, this.drug_id, this.product_type, this.product_type_id).subscribe(data => {
      this.finished = true;
      if (data.status == 1) {
        if (this.offset == 1) {
          this.sample_list_data = data;
          this.sampleOrderList = this.sample_list_data;
          for (let i = 0; i < this.sampleOrderList.data.prefered_time.length; i++) {
            const temp = { id: this.sampleOrderList.data.prefered_time[i].id, itemName: this.sampleOrderList.data.prefered_time[i].slot_one + '-' + this.sampleOrderList.data.prefered_time[i].slot_two, image: '', index: i };
            this.TimeRange_data['list_data'].push(temp);
            // alert(this.sampleOrderList.data.prefered_time[i].slot_two);
          }
          this.offset = data.data.offset;
          this.showModal();
          if (this.sampleOrderList.data.drug_list.length == 1) {
            this.showDrug_detail(this.sampleOrderList.data.drug_list[0].safeUrl, '');
          }
        } else {
          this.sample_list_data = data;
          this.sampleOrderList.data.drug_list = this.sampleOrderList.data.drug_list.concat(this.sample_list_data.data.drug_list);
          this.offset = data.data.offset;
        }

      } else if (data['status'] === 9) {
        this.funCollectionService.logout();
      } else {
        this.isErrorModalShown = true;

        this.ErrorHandler(data['error'].msg);
      }
    },
      err => {
        this.httpErrorHandeler(err);
      }));
  }

  /**
   * on Scroll Down
   */
  onScrollDown(): boolean {

    if (this.offset > 1) {
      this.finished = false;
      this.sampleList(this.offset);
    } else {
      this.finished = true;
      return false;
    }

  }

  /**
   * call Accordian
   */
  callAccordian(str): void {
    this.isShowComposition = !str;
  }

  /**
   * validate User Mobile
   */
  validateUserMobile(evt): void {
    evt.target.value = evt.target.value.replace(/[^0-9.]/g, '');
    if (evt.target.value.length > 15) {
      evt.target.value = evt.target.value.substring(0, 15);
    }
  }

  /**
   * confirm Drug Order
   */
  confirmDrugOrder(): void {
    this.isConfirmModalShown = false;
    this.subscriptions.add(this.postservice.drugOrder(this.JournalData.target_id, this.drugOrder.drug_id, this.frm_name, this.frm_mobile, this.frm_email, this.frm_address, this.frm_pincode, this.frm_date, this.frm_time_range_id, this.frm_comment).subscribe(data => {
      this.sample_order_data = data;
      if (data.status == 1) {
        this.isGetsampleModal = false;
        if (data.code == 2000) {
          this.sampleOrderInfoModalShown = true;
        }
      } else {
        this.isErrorModalShown = true;
        this.error_msg = data.error.msg;
      }
    }));
  }

  /**
   * reset Error Modal
   */
  resetErrorModal(): void {
    this.isErrorModalShown = false;
  }

  /**
   * cancel confirm modal
   */
  cancel_confirm_modal(): void {
    this.isConfirmModalShown = false;
  }

  /**
   * drug Order Form
   */
  drugOrderForm(): boolean {
    this.frm_name = document.querySelector<HTMLInputElement>('#frm_name').value;
    this.frm_mobile = document.querySelector<HTMLInputElement>('#frm_mobile').value;
    this.frm_email = document.querySelector<HTMLInputElement>('#frm_email').value;
    this.frm_address = document.querySelector<HTMLInputElement>('#frm_address').value;
    this.frm_pincode = document.querySelector<HTMLInputElement>('#frm_pincode').value;
    this.frm_date = document.querySelector<HTMLInputElement>('#frm_date').value;
    this.frm_comment = document.querySelector<HTMLInputElement>('#frm_comment').value;


    if (this.frm_name == '' || this.frm_name == undefined) {
      this.errfrm_name = this.mls.languageText.prod_get_sample.key1;
      document.querySelector('#frm_name')?.classList?.add('help-required');
      return false;
    } else {
      document.querySelector('#frm_name')?.classList?.remove('help-required');
      this.errfrm_name = '';
    }


    if (this.frm_mobile == '' || this.frm_mobile == undefined) {
      this.errfrm_mobile = this.mls.languageText.prod_get_sample.key2;
      document.querySelector('#frm_mobile')?.classList?.add('help-required');
      return false;
    } else {
      document.querySelector('#frm_mobile')?.classList?.remove('help-required');
      this.errfrm_mobile = '';
    }

    if (this.frm_email == '' || this.frm_email == undefined) {
      this.errfrm_email = this.mls.languageText.prod_get_sample.key3;
      document.querySelector('#frm_email')?.classList?.add('help-required');
      return false;
    } else {
      const x = this.funCollectionService.isEmail(this.frm_email);
      if (x == true) {
        document.querySelector('#frm_email')?.classList?.remove('help-required');
        this.errfrm_email = '';
      } else {
        this.errfrm_email = 'Please enter valid email';
        document.querySelector('#frm_email')?.classList?.add('help-required');
        return false;
      }
    }


    if (this.frm_address == '' || this.frm_address == undefined) {
      this.errfrm_address = this.mls.languageText.prod_get_sample.key4;
      document.querySelector('#frm_address')?.classList?.add('help-required');
      return false;
    } else {
      document.querySelector('#frm_address')?.classList?.remove('help-required');
      this.errfrm_address = '';
    }

    if (this.frm_pincode == '' || this.frm_pincode == undefined) {
      this.errfrm_pincode = this.mls.languageText.prod_get_sample.key5;
      document.querySelector('#frm_pincode')?.classList?.add('help-required');
      return false;
    } else {
      document.querySelector('#frm_pincode')?.classList?.remove('help-required');
      this.errfrm_pincode = '';
    }

    if (this.frm_date == '' || this.frm_date == undefined) {
      this.errfrm_date = this.mls.languageText.prod_get_sample.key6;
      document.querySelector('#frm_date')?.classList?.add('help-required');
      return false;
    } else {
      document.querySelector('#frm_date')?.classList?.remove('help-required');
      this.errfrm_date = '';
    }


    if (this.frm_time_range_id == 0) {
      this.errfrm_time_range = this.mls.languageText.prod_get_sample.key7;
      document.querySelector('#err_help_timerange')?.classList?.add('help-required');
      return false;
    } else {
      document.querySelector('#err_help_timerange')?.classList?.remove('help-required');
      this.errfrm_time_range = '';
    }
    this.confirmInfo = this.mls.languageText.prod_get_sample.key8 + ' ' + this.JournalData.target_name;
    this.isConfirmModalShown = true;

  }

  /**
   * validate pincode
   */
  validate_pincode(event): void {
    const pincode = event.target.value;
    if (pincode.length > 10) {
      event.target.value = pincode.substring(0, 10);
    }
  }

  /**
   * select Time Range
   */
  selectTimeRange(time_range_id, time_range_one, time_range_two): void {
    this.frm_time_range = time_range_one + '-' + time_range_two;
    this.frm_time_range_id = time_range_id;
  }

  /**
   * show Drug detail
   */
  showDrug_detail(safeUrl, drug_id): void {
    this.drugOrder = '';
    this.subscriptions.add(this.getsampleService.getDrugDetailold(safeUrl, drug_id).subscribe(resData => {
      this.jwtSer.decodeData(resData).then(data => {
        this.drug_detail_data = data;
        if (this.drug_detail_data.status == 1) {
          this.drug_detail_data = data.data.drug_detail;
          this.drugDetail = data.data.drug_detail;
        } else if (data['status'] === 9) {
          this.funCollectionService.logout();
        } else {
          this.isErrorModalShown = true;

          this.ErrorHandler(data['error'].msg);
        }
      },
        err => {
          this.httpErrorHandeler(err);
        });
    }));
  }

  /**
   * get Sample
   */
  getSample(drugList): void {
    this.drugDetail = '';
    this.drugOrder = drugList;
    // console.log(	this.drugOrder);
  }

  /**
   * show Drug List
   */
  showDrugList(): void {
    this.drugDetail = '';
    this.drugOrder = '';
  }

  /**
   * reset Get sample Modal
   */
  resetGetsampleModal(): void {
    this.resetProductGetsampleModal.emit(false);
  }

  /**
   * show Modal
   */
  showModal(): void {
    this.isGetsampleModal = true;
  }

  /**
   * hide Modal
   */
  hideModal(): void {
    this.sampleOrderInfoModalShown = false;
    this.isGetsampleModal = false;
    this.drugDetail = '';
    this.drugOrder = '';
    this.resetGetsampleModal();

  }

  /**
   * on Hidden
   */
  onHidden(): void {
    this.isGetsampleModal = false;
  }

  /**
   * http Error Handeler
   */
  httpErrorHandeler(err): boolean {

    if (err.error.code == 2000) {
      // console.log(err.error.error.msg);
      this.ErrorHandler(err.error.error.msg);
      return false;
    } else if (err.error.error.code == 1002) {
      // this.ErrorHandler(err.error.error.msg);
      this.funCollectionService.logout();
    } else {
      this.ErrorHandler(err.error.error.msg);
      return false;
    }
  }

  /**
   * Error Handler
   */
  ErrorHandler(Err_msg): boolean {
    this.isErrorModalShown = true;
    this.error_msg = Err_msg;
    return false;
  }

  /**
   * reset DDL Data
   */
  resetDDLData(val): void {
    this.frm_time_range_id = 0;
    this.TimeRange_data.selected_data = [];
    this.TimeRange_data.selected_data.push(val.item);
    if (val.item.id != undefined) {
      this.frm_time_range_id = val.item.id;
    }
  }

  /**
   * unsubscribe the subscription.
   */
   ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
}
