/**
 * Angular imports.
 */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

/**
 * Environment imports.
 */
import { environment } from '../../../environments/environment';

/**
 * Service imports.
 */
import { AuthService } from '../../auth/auth.service';
import { GetService } from '../../dataservices/get.service';
import { PostApiService } from '../../dataservices/post-api.service';

/**
 * Rxjs imports.
 */
import { BehaviorSubject, Observable, Subject } from 'rxjs';

/**
 * Constant imports.
 */
import { API } from '../../constants/api.constants';

/**
 * Interface imports.
 */
import { MasterListData, ProfileDetailData } from '../../onboarding/interface/apiResponse.model';

/**
 * Master Service.
 */
@Injectable({
  providedIn: 'root'
})
export class MasterserviceService {

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public bookmark_index = new Subject<any>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public profile_api_data = new BehaviorSubject<any>({});
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public connection_selected_tab = new Subject<any>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public search_again = new Subject<any>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public showWebModal = new Subject<any>();
  public resetWebModal = new Subject<boolean>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public media_source = new Subject<any>();
  public scroll_position: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public newslist: any[];
  public isOnline = true;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public isConnectionModalShown = new Subject<any>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public masterApiData: any;
  public url_after_leave_cme = '/web';
  public monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];
  private token: string;

  public login_user_profile_pic = '';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public user_nameData: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public explore_array: any[] = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public modals_backdrop: any = {
    media_modal: 'relative',
    confirm_modal: 'relative',
    subscribe_modal: 'relative',
    success_modal: 'relative',
    error_modal: 'relative',
    doc_req_modal: 'relative',
    event_reg_modal: 'relative',
    info_modal: 'relative',
    isOnline: 'relative'
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private profileDetail = new BehaviorSubject<any>('');

  /**
   * Necessary instance.
   */
  constructor(
    private getSer: GetService,
    private http: HttpClient,
    private authService: AuthService,
    private postSer: PostApiService) {
    this.token = this.authService.getToken();
  }

  /**
   * Get ProfileDetail
   */
  getProfileDetail(custom_id, uuid?: string): Observable<ProfileDetailData> {
    localStorage.setItem("loggedInUserCustomId", custom_id);
    let params = {};
    if (uuid) {
      params = { uuid };
    } else {
      params = { custom_id };
    }
    return this.http.get<ProfileDetailData>(API.PROFILE_DETAIL, {
      params
    });
  }

  setProfile(profile: ProfileDetailData): void {
    this.profileDetail.next(profile);
  }

  clearProfileDetail(): void {
    this.profileDetail.next('');
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getProfile(): Observable<any> {
    return this.profileDetail.asObservable();
  }

  /**
   * Get Master Detail
   */
  getMasterDetail(): Observable<MasterListData> {
    this.token = this.authService.getToken();
    this.getSer.token = this.token;
    this.postSer.token = this.token;
    this.postSer.user_auth_key = this.token;

    const body = { is_encoded_response: environment.is_encoded_response };
    return this.http.post<MasterListData>(API.MASTER_LIST, body);
  }
}
