/**
 * Angular imports.
 */
import { Router, NavigationEnd } from '@angular/router';
import { Compiler, Component, OnInit, HostListener, AfterViewInit, OnDestroy, Renderer2 } from '@angular/core';

/**
 * Rxjs imports.
 */
import { timer as observableTimer, Subject, Subscription } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
/**
 * Service  imports.
 */
import { NewLanguageService } from './dataservices/new-language/new-language.service';
import { FunCollectionService } from './common/common-functions/fun-collection.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DocquityAnalyticsService } from './dataservices/docquity-analytics/docquity-analytics.service';
import { JwtDecodeService } from './dataservices/jwt-decode.service';
import { DataStorageService } from './dataservices/data-storage.service';
import { AuthService } from './auth/auth.service';
import { DqVideoPlayerService } from './dataservices/dqVideoPlayer/dq-video-player.service';
import { GetService } from './dataservices/get.service';
import { PostApiService } from './dataservices/post-api.service';
/**
 * Environment  imports.
 */
import { environment } from '../environments/environment';

/**
 * Constant imports.
 */
import { ACTION_ON, ACTION_TYPE, CTA_TYPE, DEVICES, NOTIFICATION_TYPE_SOURCE, PRODUCT_TYPE_CONSTANT_NAME, REUSABLE_COMPONENTS, SURVEY_ACCESS_TYPE, TRIGGER_SOURCE, WEBVIEW_MOBILE_METHOD_GET, WEBVIEW_MOBILE_PARAMS } from './constants/app.constants';

/**
 * Service imports.
 */
import { OnboardingService } from './dataservices/onboarding/onboarding.service';
import { MobileWebViewCommunicationService } from './dataservices/mobileWebViewCommunication/mobile-web-view-communication.service';
import { SurveyService } from './dataservices/survey/survey.service';

/**
 * Rxjs imports.
 */
import { PublicFileUploadKeeperService } from './dataservices/public-file-upload-keeper.service';
/**
 * sdk imports
 */
import { Analytics } from 'analytics2.0';
import { NOTIFICATION_RECEIVED } from 'analytics2.0/dist/core/constants/analytic.interface';
/**
 * AppComponent.
 */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  @HostListener('document:click', ['$event'])
  onActivity(event: Event): void {
    // Perform actions when user is active
    if (!(localStorage.getItem('triggerSource')?.length > 0)) {
      localStorage.setItem('triggerSource', 'organic');
    }
  }
  public sessiontime = { 'start_time': '', 'end_time': '' };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public profileData: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public masterData: any;
  public countryId = 'en';
  public custome_id: string;
  public loginUserCustomeId: string;
  public isErrorModalShown = false;
  public isCookies = true;
  public error_msg = '';
  public deviceInfo = null;
  public flag = false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public eventdata: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public profileInfo: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public userProfileData: any;
  public user_id: number;
  public masterapi_exeution = true;
  public isConnected = true;
  public isConnectionModalShown = false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public user_nameData: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public showPosition: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public geoLocation: any;
  private componentActive = true;
  public appConfigDataFlag = false;
  /**
   * Used for website URL.
   */
  public website_url = environment.baseUrls.main_website_url;

  public option = {
    action: 'user active on app',
    product_type: '',
    product_id: '',
    category: '',
  };
  public userActivity;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public userInactive: Subject<any> = new Subject();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public deviceType: any;
  public connectionList = [];
  public connectionRequestList = [];
  public connectionSuggestionList = [];
  public chatInitializeConfig = {};
  /**
   * Used to identify the component used to reuse.
   */
  public reusableComponents = REUSABLE_COMPONENTS;

  /**
   * To unsubscribe data.
   */
  private subscriptions: Subscription = new Subscription();

  /**
   * Necessary instances.
   */
  constructor(
    private _compiler: Compiler,
    private deviceService: DeviceDetectorService,
    private jwtSer: JwtDecodeService,
    private funCollectionService: FunCollectionService,
    private router: Router,
    private docquityabalytics: DocquityAnalyticsService,
    public dss: DataStorageService,
    public mls: NewLanguageService,
    private authService: AuthService,
    public dqVideoPlayerService: DqVideoPlayerService,
    public GetSer: GetService,
    private onboardingSer: OnboardingService,
    private renderer: Renderer2,
    public mvc: MobileWebViewCommunicationService,
    private postApiService: PostApiService,
    private surveyService: SurveyService,
    private publicFileKeeperService: PublicFileUploadKeeperService,
    private analyticSdk: Analytics
  ) {
    this.subscriptions.add(this.GetSer.getFeatureOnOffJSON().subscribe(data=>{
     // TODO: Pavan: uncomment the below code.
      // this.mls.featureOnOffList.moduleList = data
    }));


    this.mvc.sendInfoToBothMobile({ method: WEBVIEW_MOBILE_METHOD_GET, params: WEBVIEW_MOBILE_PARAMS.TOKEN, body: 'requesting for the token' });
    this.subscriptions.add(observableTimer(0, 5000)
      .subscribe(() => {
        if (environment.ideal_state.user_inactive_timer > environment.ideal_state.user_inactive_time) {
          this.docquityabalytics.setSessionId();
          environment.ideal_state.user_inactive_timer = 0;
        }
        environment.ideal_state.user_inactive_timer = environment.ideal_state.user_inactive_timer + 5000;
      }));


    this.setTimeout();
    this.subscriptions.add(this.userInactive.subscribe(() => {
      this.docquityabalytics.setSessionId();
      if (this.dss.isVideoPlaying === false) {
        window.location.reload();
      } else {
        environment.ideal_state.user_inactive_timer = 0;
      }
    }));
    this._compiler.clearCache(); // clear cashe issue

    this.custome_id = authService.getCustom();
    this.subscriptions.add(this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (<any>window).ga('set', 'page', event.urlAfterRedirects);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (<any>window).ga('send', 'pageview');
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (<any>window).gtag('event', 'page_view', {
          page_path: event.urlAfterRedirects
        });
      }
    }));
    this.subscriptions.add(
      this.mvc.authenticByPass$.subscribe(flag => {
        if (flag) {
          localStorage.setItem('isCookies', false.toString());
          this.isCookies = false;
          this.renderer.addClass(document.body, 'mobile-view');
        } else {
          this.renderer.removeClass(document.body, 'mobile-view');
        }
      })
    );
  }
  private broadcastChannel: BroadcastChannel;
  /**
   * ngOnInit.
   */
  ngOnInit(): void {

    this.broadcastChannel = new BroadcastChannel('firebase-messaging');
    this.broadcastChannel.addEventListener('message', this.handleBroadcastMessage.bind(this));
    if (this.mvc.isExternalSurvey) {
      this.mvc.externalSurveyDetail = JSON.parse(localStorage.getItem('externalSurveyDetail'));
      this.surveyService.surveyType = this.mvc.externalSurveyDetail.surveyType as SURVEY_ACCESS_TYPE;
      this.publicFileKeeperService.isPublicRequest = true;
      this.publicFileKeeperService.getPublicTokenForKeeper().then(value => this.publicFileKeeperService.publicToken = value?.at);
    }
    document.body.addEventListener('contextmenu', function () {
      return false;
    });
    this.checkAppConfigData();
    this.initData();
    this.epicFunction();
    this.IsUserActiveOnApp();
  }
  /**
   * 
   * @param event 
   * HANDLE web push notifications
   */

  handleBroadcastMessage(event: MessageEvent) {
    if (event.data && event.data.type === 'SET_IN_LOCAL_STORAGE') {
      const data = event.data.data;
      localStorage.setItem(data.key as string, data.value);
    }
    if (event.data && event.data.type === 'BACKGROUND_NOTIFICATION_TRIGGERED') {
      const data = event.data.data;
      const analyticData: NOTIFICATION_RECEIVED = {
        entity_type: PRODUCT_TYPE_CONSTANT_NAME.NOTIFICATION,
        entity_code: data?.data?.notification_id,
        start_time: new Date().getTime(),
        type: NOTIFICATION_TYPE_SOURCE.SANCHAR
      }
      this.analyticSdk.analyticService.NOTIFICATION_RECEIVED(analyticData);
    }
    if (event.data && event.data.type === 'BACKGROUND_NOTIFICATION_CLICKED') {
      const extra  = [
        {key: 'VIEW_TYPE',
        value: NOTIFICATION_TYPE_SOURCE.SANCHAR}
      ];
      const data =  event.data.data;
      this.analyticSdk.analyticService.CLICK(PRODUCT_TYPE_CONSTANT_NAME.NOTIFICATION, data.notification_id, this.funCollectionService.currentScreename, CTA_TYPE.CARD, ACTION_TYPE.CLICK, ACTION_ON.BACK_GROUND_NOTIFICATION, null, new Date().getTime(), extra);
    }
  }
  // TODO : Remove nested subscription
  /**
   * App config function.
   */
  checkAppConfigData(): void {
    this.subscriptions.add(this.onboardingSer.getClentIp().subscribe(ip => {
      localStorage.setItem('ipAddress', ip);
      this.subscriptions.add(this.onboardingSer.getBaseUrls(ip).subscribe(data => {
        if (data) {
          this.onboardingSer.client_ip_address = ip;
          Object.keys(data.lc[0].urls).forEach((key) => {
            if (key in environment.API_URLS) {
              if (data.lc[0].urls[key] !== '' || data.lc[0].urls[key] !== null) {
                environment.API_URLS[key] = data.lc[0].urls[key];
              }
            }
          });
          this.appConfigDataFlag = true;
          this.saveUniqueIdDetail(ip);
        } else {
          this.appConfigDataFlag = true;
          this.saveUniqueIdDetail(ip);
        }
      }, () => {
        this.appConfigDataFlag = true;
        this.saveUniqueIdDetail(ip);
      }));
    }, () => {
      this.appConfigDataFlag = true;
      this.saveUniqueIdDetail('');
    })
    );
  }
  /**
   * Save Unique Id Detail
   */
  saveUniqueIdDetail(ip): void {
    const browserFingerprintId = this.funCollectionService.getFingerPrintUUID();
    const browserUniqueId = this.funCollectionService.getCustomUUID();
    const trackId = localStorage.getItem('trackId') ? localStorage.getItem('trackId') : '';

    const browserInfo = {
      'userAgent': navigator.userAgent,
      'language': navigator.language,
      'colorDepth': screen.colorDepth,
      'deviceMemory': '',
      'hardwareConcurrency': navigator?.hardwareConcurrency ? navigator?.hardwareConcurrency : '',
      'pixelRatio': window.devicePixelRatio,
      'screenResolution': screen.width + 'X' + screen.height,
      'availableScreenResolution': screen.width + 'X' + screen.height,
      'timezoneOffset': new Date().getTimezoneOffset(),
      'timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
      'sessionStorage': window.sessionStorage,
      'localStorage': window.localStorage,
      'indexedDb': window.indexedDB,
      'addBehavior': '',
      'openDatabase': '',
      'cpuClass': '',
      'platform': navigator.platform,
      'doNotTrack': navigator?.doNotTrack ? navigator?.doNotTrack : '',
      'plugins': navigator.plugins,
      'canvas': '',
      'webgl': '',
      'webglVendorAndRenderer': '',
      'adBlock': '',
      'hasLiedLanguages': '',
      'hasLiedResolution': '',
      'hasLiedOs': '',
      'hasLiedBrowser': '',
      'touchSupport': '',
      'fonts': '',
      'fontsFlash': '',
      'audio': '',
      'enumerateDevices': navigator.mediaDevices?.enumerateDevices ? navigator.mediaDevices?.enumerateDevices : '',
      'longitude': '',
      'lattitude': ''
    };
    if (!trackId) {
      this.subscriptions.add(this.postApiService.recordBrowserDetails(browserFingerprintId, browserUniqueId, trackId, ip, browserInfo).subscribe(() => {
        // No Code
      }, () => {
        // No Code
      })
      );
    }
  }

  /**
   * ngAfterViewInit.
   */
  ngAfterViewInit(): void {
    if (this.funCollectionService.isCookie == false) {
      this.isCookies = false;
    }
    if (this.funCollectionService.isCookie == true) {
      const cook = JSON.parse(localStorage.getItem('isCookies'));
      if (cook == false) {
        this.isCookies = false;
      } else {
        this.isCookies = true;
      }
    }
  }

  /**
   * Reload_page.
   */
  Reload_page(): void {
    window.location.reload();
  }

  /**
   * Reload_page.
   */
  IsUserActiveOnApp(): void {
    const vis = (function () {
      // tslint:disable-next-line: prefer-const
      // eslint-disable-next-line prefer-const
      let stateKey, eventKey, keys = {
        hidden: 'visibilitychange',
        webkitHidden: 'webkitvisibilitychange',
        mozHidden: 'mozvisibilitychange',
        msHidden: 'msvisibilitychange'
      };

      for (stateKey in keys) {
        if (stateKey in document) {
          eventKey = keys[stateKey];
          break;
        }
      }
      return function (c) {
        if (c) {
          document.addEventListener(eventKey, c);
        }

        return !document[stateKey];
      };

    })();

    vis(function () {
      // const x = vis('') ? 'Visible' : 'Not visible';
    });

  }

  /**
   * device info function.
   */
  epicFunction(): void {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    environment.device_info = this.deviceInfo;
    if (this.deviceService.isMobile() == true) {
      this.deviceType = 'mobile';
    }
  }

  /**
   * initData.
   */
  initData(): void {
    if (localStorage.getItem('token') == null) {
      const absurl = window.location.href.slice(window.location.href.indexOf('#') + 1);
      let absurl2 = absurl.split('/course-detail/');
      if (absurl2.length < 2) {
        absurl2 = absurl.split('/detail/');
      }

      if (absurl2.length >= 2) {
        let ptype = absurl2[0];
        if (ptype == '/web') {
          ptype = '1';
        }

        if (ptype == '/cme') {
          ptype = '8';
        }

        if (ptype == '/channel') {
          ptype = '10';
        }

        if (ptype == '/event') {
          ptype = '7';
        }

        if (ptype == '/journal') {
          ptype = '3';
        }
        // const pid = absurl2[1];

        return;
      }
    }
  }

  /**
   * httpError Handler.
   */
  httpErrorHandeler(err): void {
    if (err.error.error.code == 1002) {
      this.ErrorHandler(err.error.error.msg);
      this.funCollectionService.logout();
    }
  }

  /**
   * Error Handler.
   */
  ErrorHandler(Err_msg): void {
    this.isErrorModalShown = true;
    this.error_msg = Err_msg;
  }

  /**
   * reset Error Modal
   */
  resetErrorModal(): void {
    this.isErrorModalShown = false;
  }

  /**
   * set Docquity Analytic
   */
  setDocquityAnalytic(): void {
    const d = new Date();
    const difference = d.getTime() - this.docquityabalytics.set_time;
    const minutesDifference = Math.floor(difference / 1000 / 60);
    if (minutesDifference > 10) {
      this.docquityabalytics.set_time = d.getTime();
      this.docquityabalytics.setSessionId();
    }

    const sessiontime = { 'start_time': d.getTime(), 'end_time': d.getTime() };
    let location;
    {
      location = { 'latitude': '', 'longitude': '', 'local': '', 'time_zone': d.getTimezoneOffset() };
    }

    this.eventdata = {
      event_name: this.option.action,
      product_type: this.option.product_type,
      product_type_id: this.option.product_id,
      session_id: this.docquityabalytics.event_session_id,
      local_id: '0',
      screen_name: this.option.category,
      user_id: this.user_id,
      untrack_user_identifier: '',
      session_time: sessiontime,
      location: location
    };
    this.docquityabalytics.all_event.push(this.eventdata);

    this.docquityabalytics.docquityAnalytics(this.docquityabalytics.all_event)
      .pipe(takeWhile(() => this.componentActive))
      .subscribe(resData => {
        this.jwtSer.decodeData(resData).then(data => {
          if (data['status'] == 1) {
            this.docquityabalytics.all_event = [];
          }
        });
      });
  }

  /**
   * reset Connection Modal
   */
  resetConnectionModal(): void {
    this.isConnectionModalShown = false;
  }

  /**
   * set Timeout
   */
  setTimeout(): void {
    this.userActivity = setTimeout(() => this.userInactive.next(undefined), environment.session_id_reset_time);
  }

  /**
   * window:mousemove.
   */
  @HostListener('window:mousemove') refreshUserState(): void {
    environment.ideal_state.ideal_state_timer = 0;
    environment.ideal_state.user_inactive_timer = 0;
    if (environment.ideal_state.is_ideal_state == true) {
      environment.ideal_state.is_ideal_state = false;
      const dd = new Date();
      this.funCollectionService.sessiontime.start_time = dd.getTime().toString();
      sessionStorage.setItem('sessionstarttime', dd.getTime().toString());
    }
    clearTimeout(this.userActivity);
    this.setTimeout();
  }

  /**
   * isCookies Close.
   */
  isCookiesClose(): void {
    this.isCookies = false;
    this.funCollectionService.isCookie = false;
  }

  /**
   * isCookies Accept.
   */
  isCookiesAccept(): void {
    this.isCookies = false;
    localStorage.setItem('isCookies', JSON.stringify(this.isCookies));
  }

  /**
   * loading icon for IOS device
   * @returns
   */
  get isLoading(): boolean {
    const deviceType = this.mvc.getAnalyticData()?.deviceType;
    if (!this.appConfigDataFlag && this.mvc.checkAuthenticByPass()) {
      if (this.mvc.checkForIOS() ||
        Object.values(DEVICES)[0] === deviceType) {
        return true;
      }
    }
    return false;
  }

  /**
   * unsubscribe the subscription.
   */
  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
    this.componentActive = false;
  }

}
