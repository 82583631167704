/**
 * Angular Imports
 */
import { Pipe, PipeTransform } from '@angular/core';

/**
 * This pipe is used to find and replace the users object with tag .
 */
@Pipe({
  name: 'customLink'
})
export class CustomLinkPipe implements PipeTransform {
  
    transform(text: string): string {
        const regex = /{([^}]+)~([^}]+)}/g;
        return text.replace(regex, '<a href="$2" target="_blank">$1</a>');
      }
  }

