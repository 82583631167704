/**
 * Angular imports.
 */
import { Pipe, PipeTransform } from '@angular/core';

/**
 * Service imports.
 */
import { NewLanguageService } from './../dataservices/new-language/new-language.service';

/**
 * custome Pipe DateTime
 */
@Pipe({
  name: 'customePipeDateTime'
})
export class CustomePipeDateTimePipe implements PipeTransform {

  /**
   * Necessary instances.
   */
  constructor(public mls: NewLanguageService) { }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public display_date: any;

  /**
   * custome Pipe DateTime
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transform(curr_msg_time: any): any {
    const todaydt = new Date();
    const curr_dt = new Date(curr_msg_time * 1000);

    if (curr_msg_time.toString().length > 10) {
      // tslint:disable-next-line: radix
      curr_msg_time = parseInt(curr_msg_time) / 1000;
    }
    //  console.log(curr_msg_time,curr_msg_time.toString().length);

    if (todaydt.getDate() == curr_dt.getDate()) {
      if (this.display_date != curr_dt.getDate()) {
        this.display_date = curr_dt.getDate();
        return this.mls.languageText.profile_notification.key2;
      } else {
        return '';

      }


    } else {
      if (this.display_date != curr_dt.getDate()) {
        this.display_date = curr_dt.getDate();
        return curr_dt.toDateString();
      } else {
        return '';

      }
    }

  }

}
