/**
 * Angular imports.
 */
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

/**
 * Rxjs imports.
 */
import { Observable } from 'rxjs';

/**
 * Constant Import.
 */
import { DELETE_REASON } from '../constants/app.constants';

/**
 * Service imports.
 */
import { AuthService } from './auth.service';
import { MobileWebViewCommunicationService } from '../dataservices/mobileWebViewCommunication/mobile-web-view-communication.service';

/**
 * Auth Guard.
 */
@Injectable({
  providedIn: 'root'
})
export class RouteParamGuard {

  /**
   * Necessary instances.
   */
  constructor(private authService: AuthService,
    public router: Router,
    private mvc: MobileWebViewCommunicationService) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.authService.isAuthenticated() && !this.mvc.checkAuthenticByPass()) {
      if (state.url == DELETE_REASON.url) {
        this.router.navigate(['/login/input-mobile'], {
          queryParams: {
            redirectUrl: '/web/list'
          }
        });
      } else {
       if(this.mvc.externalSurveyDetail) {
        return true;
       }
       /**
        * Redirect user to open access list after user logout
        */
       else if(localStorage.getItem('fromOpenAccess') == '1'){
        this.router.navigate(['/open-access/public-product-list'], {
          queryParams: {
            redirectUrl: state.url
          }
        });
       } else {
        this.router.navigate(['/login/input-mobile'], {
          queryParams: {
            redirectUrl: state.url
          }
        });
       }
      }
      return true;
    }
    return true;
  }

  canActivateChild(): boolean {
    if(this.mvc.checkAuthenticByPass()) {
      return false;
    } 
  }
}
