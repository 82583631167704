<div class="row">
  <div class="col-md-5 p-0">
    <app-left-section></app-left-section>
  </div>
  <div class="col-md-7 p-0">
    <div class="position-relative px-3 px-sm-4 pb-3 pt-5">
      <a (click)="back_screen()"
        [appOnboardingEventTracker]='{"category":mls.googleEventText.verifygroup_user.category,"action":mls.googleEventText.verifygroup_user.key4,"product_type":"", "product_id":""}'
        class="back-page position-absolute"><img src="assets/themes/icons/back-arrow.svg" width="20px" /></a>
      <span (click)="skipOnboardingModal()"
        [appOnboardingEventTracker]='{"category":mls.googleEventText.verifygroup_user.category,"action":mls.googleEventText.verifygroup_user.key3,"product_type":"", "product_id":""}'
        class="position-absolute secondary-paragraph cursor-pointer skip-onboarding"
        *ngIf="is_skippable==1">{{mls?.languageText?.verifygroup_user?.key1}}<span
          *ngIf="mls?.is_lskey_display==1">(verifygroup_user.key1)</span>
        <img class="ml-1" width="8px" src="assets/themes/icons/right-arrow.svg" alt="back">
        </span>
      <div class="position-relative">
        <h5 class="mb-1 heading-4">
          <span class="color-deep-green">{{verify_data.registration?.title}}</span>
        </h5>

        <div *ngIf="infoshow==true" id="mrn-info"
          class="p-2 border position-absolute common-dropdown-list d-block w-75">
          <img [src]="info_data?.url" onerror="this.src='assets/images/grandround/image_placeholder.svg';"
            class="w-100">
        </div>
      </div>
      <!-- form -->
      <div class="my-3 row" *ngIf="verify_data.registration.hasOwnProperty('hide_input') === false || verify_data.registration?.hide_input === '0'">
        <div class="col-12">
          <div class="form-input" [ngClass]="{ 'error' : err_msg }">
            <input class="input-field" (keypress)="numberInput($event)" [maxLength]="Dss.inputBoxMaxLimit" (keyup)="userInput($event)"
            autocomplete="off" id="registration_no" type="text" placeholder="{{verify_data.registration.inputHint}}" />
            <p class="help-block" *ngIf="err_msg!=''">
              <span>{{err_msg}}</span>
            </p>
          </div>
        </div>
      </div>

      <p class="or mb-3" *ngIf="verify_data.registration?.hide_input === '0' && verify_data.registration?.hide_upload === '0'"><span
          class="secondary-paragraph">{{mls?.languageText?.verifygroup_user?.key2}}<span
            *ngIf="mls?.is_lskey_display==1">(verifygroup_user.key2)</span></span></p>
      <label *ngIf="verify_data.registration?.hide_upload === '0'" for="college_id" class="file-upload pt-2 mt-4"
        [appOnboardingEventTracker]='{"category":mls.googleEventText.userdetail_screen.category,"action":mls.googleEventText.verifygroup_user.key1,"product_type":"", "product_id":""}'>
          <div class="file-upload-box">
            <img src="assets/themes/images/upload-cam.svg" />
            {{mls?.languageText?.verifygroup_user?.key3}}
            <span *ngIf="mls?.is_lskey_display==1">(verifygroup_user.key3)</span>
          </div>
      </label>
      <!-- upload preview -->
      <div class="position-relative university-preview mb-3" [hidden]="!upload_mrm_data">
        <a class="cursor-pointer close-icon"
          (click)="removeMrm()"
          [appOnboardingEventTracker]='{"category":mls.googleEventText.userdetail_screen.category,"action":mls.googleEventText.verifygroup_user.key5,"product_type":"", "product_id":""}'><img
            src="assets/themes/icons/gallery-close-icon.svg" /></a>
        <img [src]="this.mrm_preview" onerror="this.src='assets/themes/icons/banner-placeholder.svg';" />
      </div>
      <!-- end upload preview -->
      <div *ngIf="uploadFile_api_in_process==true"
        class="text-center" role="status" aria-hidden="true">
        <img width="24" src="assets/themes/images/primary-loader.gif" alt="">
      </div>
      <input (keypress)="numberInput($event)" id="college_id" type="file" (change)="onSelectFile($event)"
        name="college-id" class="d-none" accept=" image/jpeg, image/png,image/webp" />
        <div class="form-input error" *ngIf="err_msg_file!=''">
          <p class="help-block">{{err_msg_file}}</p>
        </div>
      <button class="btn-design w-100 my-4" [disabled]="showColor === false" [ngClass]=" [showColor ? 'filled' : 'filled-disabled',fcs.device_detail.is_mobile ? 'medium' : 'large']" type="button"
        (click)="userRegister()" [appOnboardingEventTracker]='{"category":mls.googleEventText.userdetail_screen.category,"action":mls.googleEventText.verifygroup_user.key2,"product_type":"", "product_id":""}'>
        <span *ngIf="api_in_process==true" class="spinner-border spinner-border-sm" role="status" aria-hidden="true">
        </span>
        {{mls?.languageText?.verifygroup_user?.key4}}
        <span *ngIf="mls?.is_lskey_display==1">(verifygroup_user.key4)</span>
      </button>
      <!-- end form -->
    </div>
    <div class="pb-sm-5 px-sm-4 px-3 pb-3">

      <!-- slider -->
      <div class="custom-mdb-carousel px-0 card-background">
        <mdb-carousel [isControls]="true" [animation]="" [noWrap]="false">
            <mdb-carousel-item *ngFor="let benefit of verify_data?.benefits | slice : 0:1">
              <div class="row">
                <div class="col-12">
                  <div class="p-3 px-2 px-sm-4">
                    <div class="row m-0">
                      <div class="col-9 d-table pr-0 pl-0" style="min-height: 85px; max-width: calc(100% - 85px);">
                        <div style="display: table-cell;vertical-align: middle;">
                          <p class="primary-small pb-2">
                            <span class="font-weight-bold color-grey-700">{{mls?.languageText?.verifygroup_user?.key5}}
                            <span *ngIf="mls?.is_lskey_display==1">(verifygroup_user.key5)</span></span>
                          </p>
                          <p class="paragraph-bold">{{benefit?.title}}</p>
                          <p class="desc secondary-paragraph"><span class="color-grey-500">{{benefit?.description}}</span></p>
                        </div>
                      </div>
                      <div class="col-3 pr-0 slider-img-max-width"><img [src]="benefit?.image"
                        onerror="this.src='assets/themes/icons/mrn-profile-success.svg'" width="100px" height="85px"
                        class="post-pic rounded-circle" /></div>
                    </div>
                  </div>
                </div>
              </div>
            </mdb-carousel-item>
          </mdb-carousel>
        </div>
    </div>
  </div>
</div>

<app-user-verify-hint *ngIf="isUserVerifyModalShown" (resetHintModal)="resetHintModal($event)"
  [isHintModalShown]="isUserVerifyModalShown" [info_data]="info_data"></app-user-verify-hint>

<app-backscreenmodal *ngIf="isConfirmModalShown==true" (resetConfirmModal)=resetConfirmModal($event)
  [isConfirmModalShown]=isConfirmModalShown [confirmInfo]=confirmInfo></app-backscreenmodal>

<app-skip-onboarding-modal *ngIf="isSkipConfirmModalShown==true" (resetSkipConfirmModal)=resetSkipConfirmModal($event)
  [isSkipConfirmModalShown]=isSkipConfirmModalShown [confirmInfo]=confirmInfo [benefits]="verify_data?.benefits">
</app-skip-onboarding-modal>
