export const CME_SHOW_RIGHT_PART_VIEW_TYPE = {
    VAULT : 'vault',
    REMOVE_CERTIFICATE: 'removeCertificate',
    LIST : 'list',
    DETAIL_LIST : 'detailList'
}

export const CME_LOCAL_STORAGE = {
    CME_DETAIL_MODULE : 'cmeDetailModule',
    NEXT_STEP_CME_MODULE_DATA :'nextStepCmeModuleData',
    CME_PARENT_DATA : 'cmeParentData',
    CME_DETAIL : 'cmeDetail',
    CME_RESULT : 'cmeResult',
    CME_PARENT_DETAIL_DATA : 'cmeParentDetailData'
}

export const CME_MODULE_TARGET = {
    CME : 'cme',
    SURVEY : 'survey',
    WEBINAR : 'webinar'
}

export const CME_REMARK = {
    PASSED : 'passed',
    FAILED : 'failed'
}