<app-public-page-header (emitIconClick)="sendEvent($event)" [analytic]="record"
[communityText]="mls.languageText.open_article.key10"
[doctalkText]="mls.languageText.open_article.key11"
[articleText]="mls.languageText.open_article.key12"
[webinarText]="mls.languageText.open_article.key13"></app-public-page-header>

<div class="container">
  <div class='text-center' *ngIf="login.loaderSwitch">
    <img src="assets/themes/images/img-video-loader.gif" width="40" id="loadmore_post">
</div>
  <!-- Section 1 start-->
  <div *ngIf="!login.loaderSwitch">
  <div *ngIf="productList?.list.length">
      <div class="clearfix mb-3 py-1 mt-4 ">
          <div class="d-flex align-items-center">
            <span class="heading-4">{{productList?.label}}</span>
          </div>
      </div>

    <div class="card-container" infiniteScroll [infiniteScrollDistance]="5" [infiniteScrollThrottle]="1000" (scrolled)="onScroll()"
    [scrollWindow]="true">

      <div *ngFor="let card of productList?.list" class="article-card">
        <app-public-article-login-card
          [analyticRecord]="merge(card.id)"
          [openArticle]="card"
          [callingFrom]="callingFrom"
          [renderType]="openAccessConst.prodListRenderType.product_list"
          [pType]="productType"
          [textContainer]="textContainer"
          (cardClicked)="onCardClick()"
          >
        </app-public-article-login-card>
      </div>
    </div>
  </div>
</div>

  <div *ngIf="!productList?.list.length && !apiInProcess" class="no-data-found text-center mt-4">
    {{mls.languageText.open_article.key29}}
  </div>

  <div *ngIf="apiInProcess" class="text-center mt-4">
    <img src="assets/themes/images/img-video-loader.gif" width="40" id="loadmore_post" alt="loader" />
  </div>
</div>

<app-public-skip-popup 
(loginClicked)="onCardClick()">
</app-public-skip-popup>

<div #articleloginContainer class="d-none"></div>
