/**
 * Angular core imports.
 */
import { Pipe, PipeTransform } from '@angular/core';
/**
 * Service imports.
 */
import { NewLanguageService } from './../dataservices/new-language/new-language.service';

@Pipe({
  name: 'likeOrLikesText'
})
export class LikeOrLikesTextPipe implements PipeTransform {
  constructor(public nls: NewLanguageService) {}
  transform(value: number): string {
    const visibleText = value > 1 ? ' ' + this.nls.languageText.clinical_resource_card.key3 :
    ' ' + this.nls.languageText.clinical_resource_card.key2;
    return visibleText;
  }

}
