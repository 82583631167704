import { DomSanitizer } from '@angular/platform-browser';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'safehtml'
})
export class SafehtmlPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) { }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transform(value: any): any {
    // console.log(value);
    return this.sanitized.bypassSecurityTrustHtml(value);
  }

}
