/* eslint-disable @angular-eslint/no-output-on-prefix */
/**
 * Angular Import.
 */
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

/**
 * Constant imports.
 */
import { HEADER_CLASS } from '../../../constants/chat.constants';

/**
 * Chat error modal Import.
 */
@Component({
  selector: 'app-chat-error-modal',
  templateUrl: './chat-error-modal.component.html',
  styleUrls: ['./chat-error-modal.component.scss']
})
export class ChatErrorModalComponent implements OnInit, OnDestroy {

  /**
   * Show/Hide error modal.
   * Information src for the error modal.
   * Heading text for the Error modal.
   * Paragraph text for the error modal.
   * Submit button text for the error modal.
   */
  @Input() isErrorModal: boolean;
  @Input() infoSrc: string;
  @Input() heading = 'Something went Wrong';
  @Input() paragraph: string;
  @Input() btnText = 'Close';
  @Input() samvaadLanguageText: any = {};

  /**
   * Emit on hide.
   */
  @Output() onHide: EventEmitter<void> = new EventEmitter();

  /**
   * Decrease z-index of header.
   */
  ngOnInit(): void {
    document?.body?.classList?.add(HEADER_CLASS);
  }

  /**
   * Emit on hide.
   */
  onHidden(): void {
    this.onHide.emit();
    this.isErrorModal = false;
  }

  /**
   * Increase z-index of header.
   */
  ngOnDestroy(): void {
    document.body?.classList?.remove(HEADER_CLASS);
  }
}
