  <div class="cmmnt-crd">
  <!-- commenter user profile-->
  <div class="clearfix" [ngClass]="{'highlightBox': cL?.comment_id === notificationCommentId }">
    <div class="float-left mr-2" [ngClass]="{'cursor-pointer':cL?.is_anonymous!='1'}"
      (click)="viewcmmntprfl()"
      [eventTracker]='{"category": callingfrom, "action": mls.googleEventText.comment_card.key3, "product_type": commentObj?.product_type, "product_id": cL?.user_profile?.custom_id}'>
      <div class="position-relative">
        <app-user-pofile-pic [isStoryAvailable]="isStoriesAvailable()" [profile_pic_image]="comment_card_image"></app-user-pofile-pic>
      </div>
    </div>
    <div class="float-left pl-0 d-table w-100 cmmnt-input-width min-height">
      <div class="cmmnt-box">
        <p class="text-capitalize">
          <span [ngClass]="{'cursor-pointer':cL?.is_anonymous!='1'}" (click)="viewcmmntprfl()"
            [eventTracker]='{"category": callingfrom, "action": mls.googleEventText.comment_card.key3, "product_type": commentObj?.product_type, "product_id": cL?.user_profile?.custom_id}'>
            <span class="secondary-paragraph"><span class="color-grey-700 pr-1">{{cL?.user_profile?.full_name}}</span></span>
            <span class="Accepted_text ml-1" *ngIf="cL?.is_anonymous!='1'">
              <span *ngIf="cL?.user_profile?.specialities?.length > 0" class="slash mx-2"></span>
              <span class="secondary-paragraph pl-1"
                *ngFor="let userSpe of cL?.user_profile?.specialities">
                <span class="color-grey-500">{{(userSpe?.is_primary==1)?userSpe?.speciality_name:''}}</span>
              </span>
            </span>
          </span>
        </p>
        <div *ngIf="cL?.is_expert" class="mt-1"></div>
      </div>
    </div>
    <div class="float-right d-table">
      <div class="edit-bx">
        <!-- edit comment -->
        <span *ngIf="cL.user_profile?.custom_id!=loginUserCustomeId && cL.user_profile?.custom_id != undefined "
          class="d-inline-block float-right mt-1">
          <app-ngb-drop-down *ngIf= "this.mls.featureOnOffList.featureList.report.value || !mls.userLoginFlow" [ngbDDL]="ngbDDL" (resetNgbDDLRecord)="resetNgbDDLRecord($event)"></app-ngb-drop-down>
        </span>
        <span *ngIf="cL.user_profile?.custom_id==loginUserCustomeId" ngbDropdown
          class="d-inline-block float-right">
          <span (click)="false" rel="tooltipTop" data-container="body" title="" id="dropdownBasic1" ngbDropdownToggle
            class="post-edit-link position-relative cursor-pointer">
            <img src="assets/themes/icons/ngbdot.svg">
          </span>
          <div ngbDropdownMenu class="ngb-dropdown-list paragraph" aria-labelledby="dropdownBasic1">
            <a class="cursor-pointer" ngbDropdownItem (click)="updateComment(cL);"
              [eventTracker]='{"category": callingfrom,"action":mls.googleEventText.comment_card.key3, "product_type": commentObj?.product_type, "product_id": cL?.comment_id}'>
              {{mls.languageText.comment_card.key1}}
              <span *ngIf="mls?.is_lskey_display==1">(comment_card.key1)</span>
            </a>
            <a class="cursor-pointer" ngbDropdownItem (click)="onDeleteComment()"
              [eventTracker]='{"category": callingfrom, "action": mls.googleEventText.comment_card.key3, "product_type": commentObj?.product_type, "product_id": cL?.comment_id}'>
              {{mls.languageText.comment_card.key2}}
              <span *ngIf="mls?.is_lskey_display==1">(comment_card.key2)</span>
            </a>
          </div>
        </span>
      </div>
    </div>
  </div>
  <!--  -->
  <div id="commentbox-1" class="text-left paragraph">
    <div class="pad-left">
      <div id="comment-1" [ngClass]="{'highlightBox': cL?.comment_id === notificationCommentId }">
        <!-- comment text -->
        <div class="py-1 cursor-pointer">
          <!-- <app-content-card [cmt]="cL?.comment" [showChar]="150" [isActionBlock]=false></app-content-card> -->
          <app-product-comment-content-card [cmt]="cL?.comment" [commentObject]="cL" [screenName]="callingfrom" [showChar]="200"></app-product-comment-content-card>
        </div>
        <div >
          <owl-carousel-o [options]="customOptionsNudge">
            <ng-template  carouselSlide *ngFor="let option of cL.auto_comment_options">
              <span [ngClass]="{'speciality-flter':number.ZERO === number.ZERO}"
                (click)="setAutoComment(option)">
                <span type="radio" name="radio1" id="male"></span>
                <label class="text-center text-truncate" title= {{option.title}}>
                  {{option.title | slice:number.ZERO:number.SIXTEEN}}<span *ngIf="option.title.length > number.FIFTEEN">...</span>
                </label>
              </span>
            </ng-template>
          </owl-carousel-o>
        </div>
        <!--Comment Activity-->
        <!-- check for event comment-->
        <div class="post-cmmnt-image"
          *ngIf="commentObj?.product_type==7 && cL?.file?.length>0 && cL?.file[0]?.file_type=='image' "> 
          <img [src]="cL?.file[0]?.file_url">
        </div>

        <div class="secondary-paragraph mt-1">
          <div class="row">
            <div class="col-12 img-container pr-0">
              <!--checks for cases-->
              <span *ngIf="cL.auto_comment_options?.length === number.ZERO">

              <a *ngIf="commentObj?.product_type===1 && commentObj?.kind=='cases' && dss.user_profile_data.profile.custom_id!=cL?.user_profile?.custom_id && (this.mls.featureOnOffList.featureList.upvote.value || !mls.userLoginFlow); else likecall;"
                class="secondary-paragraph cursor-pointer"
                [ngClass]="{'active color-black': cL?.comment_like_status===1}">

                <img class="animation-dur" width="15px" height="15px" *ngIf="comment_like_loader==true" src="assets/themes/images/primary-loader.gif">
                  <span (click)="productLike('upvote')" [eventTracker]='{"category":callingfrom,"action":mls.googleEventText.comment_card.key3,"product_type":commentObj?.product_type, "product_id":cL?.comment_id}'> 
                  <img height="16px" *ngIf="cL?.comment_like_status!=1 && comment_like_loader!=true" src="assets/themes/icons/upvote-white.svg" alt="upvote" />
                  <img height="16px" *ngIf="cL?.comment_like_status===1 && comment_like_loader!=true" src="assets/themes/icons/upvote-black.svg" alt="upvoted" />
                  </span>
                <span class="ml-1">
                  <span *ngIf="cL?.total_comment_like<2" [ngClass]="cL?.total_comment_like>0 ? 'color-black' : 'color-grey-700' ">
                    <span *ngIf="cL?.total_comment_like>0" (click)="getProductLikeList()" class="mr-4">{{cL?.total_comment_like}} {{mls.languageText.comment_card.key4}}</span> 
                   <span *ngIf="!(cL?.total_comment_like>0)" class="mr-4">{{mls.languageText.comment_card.key4}}</span>
                  </span> 
                  <span *ngIf="mls?.is_lskey_display==1">(comment_card.key4)</span>
                </span>
                <span (click)="getProductLikeList()">
                  <span *ngIf="cL?.total_comment_like>1" class="cursor-pointer color-black mr-4">
                    {{cL?.total_comment_like}} {{mls.languageText.comment_card.key7}}
                    <span *ngIf="mls?.is_lskey_display==1">(comment_card.key7)</span>
                  </span>
                </span>
              </a>


              <ng-template #likecall>
                <a *ngIf="commentObj?.kind!='cases'" class="cursor-pointer secondary-paragraph" (click)="productLike('like')"
                  [ngClass]="{'active color-black': cL?.comment_like_status===1}"
                  [eventTracker]='{"category": screen_name, "action": ems.ProductCommentComponent.like_cmt, "product_type": commentObj?.product_type, "product_id": commentOb?.product_id}'>

                  <img width="15px" height="15px" *ngIf="comment_like_loader==true" src="assets/themes/images/primary-loader.gif" alt="loader" />
                  
                  <span  *ngIf="cL?.comment_like_status!=1 && comment_like_loader!=true">
                    <img width="16px" height="16px" src="assets/themes/icons/like-white.svg" alt="like" /></span>
                  <span *ngIf="cL?.comment_like_status===1 && comment_like_loader!=true">
                    <img width="16px" height="16px" src="assets/themes/icons/like-black.svg" alt="liked" /></span>
                    <span class="ml-1 mr-4 color-grey-700" *ngIf="cL?.total_comment_like==0 && commentObj?.kind!='cases'">
                      {{mls.languageText.comment_card.key3}}
                      <span *ngIf="mls?.is_lskey_display==1">(comment_card.key3)</span>
                    </span>
                    <span (click)="getProductLikeList()" class="cursor-pointer ml-1 mr-4"  *ngIf="cL?.total_comment_like>0 && commentObj?.kind!='cases'"
                      [ngClass]="cL?.comment_like_status ? 'color-black' : 'color-grey-700' "
                      [eventTracker]='{"category":callingfrom,"action":mls.googleEventText.comment_card.key3,"product_type":commentObj?.product_type, "product_id":cL?.comment_id}'>
                      {{cL?.total_comment_like>1 ? cL?.total_comment_like + ' '+mls.languageText.comment_card.key5: '1 '+mls.languageText.comment_card.key3}}
                      <span *ngIf="mls?.is_lskey_display==1">(comment_card.key5)(comment_card.key3)</span>
                    </span>
                </a>
              </ng-template>
            </span>

              <a *ngIf="commentObj?.product_type!=7 && commentObj?.product_type!=21"
                class="cursor-pointer" >
                <!-- upvote-count -->
                <span (click)="getProductLikeList()" *ngIf="commentObj?.product_type===1 && commentObj?.kind=='cases' && dss.user_profile_data.profile.custom_id==cL?.user_profile?.custom_id">
                  <span class="mr-4" *ngIf="cL?.total_comment_like>0 && cL?.total_comment_like==1" [ngClass]="cL?.total_comment_like>0 ? 'color-black' : 'color-grey-700' ">
                    <span *ngIf="cL?.total_comment_like>0">{{cL?.total_comment_like}}</span> 
                   {{mls.languageText.comment_card.key4}}
                  </span> 
                  <span *ngIf="mls?.is_lskey_display==1">(comment_card.key4)</span>
                  <span *ngIf="cL?.total_comment_like>1" class="cursor-pointer color-black mr-4">
                    {{cL?.total_comment_like}} {{mls.languageText.comment_card.key7}}
                    <span *ngIf="mls?.is_lskey_display==1">(comment_card.key7)</span>
                  </span>
                </span>
                <span (click)="showreplyinbox()">
                <img width="16px" height="16px" src="assets/themes/icons/reply.svg">
                <span  class="ml-1 color-grey-700" *ngIf="cL?.total_comment_reply==0">
                  {{mls.languageText.comment_card.key6}}
                  <span *ngIf="mls?.is_lskey_display==1">(comment_card.key6)</span>
                </span>
                <span  class="ml-1" *ngIf="cL?.total_comment_reply>0">
                   {{cL?.total_comment_reply}} {{(cL?.total_comment_reply>1)? mls.languageText.comment_card.key8:mls.languageText.comment_card.key6}}
                  <span *ngIf="mls?.is_lskey_display==1">(comment_card.key8)(comment_card.key6)</span>
                </span>
               </span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <!--EOF Comment Activity-->

      <!--Reply Section-->
      <div id="commentbox-2" *ngFor="let rcL of cL.comment_reply; let rindx=index;">
        <app-reply-comment-card [callingfrom]="callingfrom" [rcL]="rcL" [commentObj]="commentObj" [index]="rindx"
          (resetReplyComment)="resetReplyComment($event)" (showReplyBox)="showReplyBox($event)"
          (deleteReplyComment)="deleteReplyComment($event)"></app-reply-comment-card>
      </div>
      <!-- reply comment box -->

      <div *ngIf="showReply==true" class="mt-3">
        <div class="row text-left align-items-center">
          <div class="col-2 pr-0 rply-bx">
            <div class="user-40 mr-2 position-relative w-40">
              <app-user-pofile-pic [profile_pic_image]="comment_reply_post_prfl_image"></app-user-pofile-pic>
            </div>
          </div>
          <div class="col-12 pl-0 calc-64 reply-comment-container">
            <div class="form-textarea with-icon w-100">
              <span class="color-black icon-in-field position-absolute">
                <span class="comment-box-arrow cursor-auto" *ngIf="replyVal==''">
                  <img src="assets/themes/icons/disabled-arrow.svg" alt="icon" />
                </span>
                <span *ngIf="replyVal!=''" class="cursor-pointer">
                  <span class="comment-box-arrow"
                    (click)="setComment($event, cL.comment_id,2,'',indx)">
                    <img src="assets/themes/icons/arrow.svg" alt="icon" />
                  </span>
                </span>
              </span>
              </div>
              <div #editableContent id="replycmt{{cL.comment_id}}"
                class="editable w-100"
                contenteditable="true"
                data-placeholder=""
                (keyup.enter)="setComment($event, cL.comment_id,2,'',indx)"
                (click)="checkToShowTutorial()"
                (input)="userInput($event)"
                (keydown)="onKeyDown($event)"
                (keyup)="onKeyUp($event)">
              </div>
              <app-tag-connections-pop-over *ngIf="isTagConnectionsEnabled"
                [isInfoModalShown]="isTagConnectionsEnabled"
                [tutorialTitle]="mls?.languageText?.tutorial?.key15"
                [tutorialMsg]="mls?.languageText?.tutorial?.key16"
                [tutorialCta]="mls?.languageText?.tutorial?.key17"
                [isTutorialFor]="tutorialFor.TAG_CONNECTION"
                (emitStartRequest)="closeTagTutorial()"
                (emitHideModal)="closeTagTutorial()"
                [callingFrom]="callingfrom"
                [screenName]="screenName"
                [comment_box_obj]="comment_box_obj">
              </app-tag-connections-pop-over>

              <div *ngIf="isSuggestionListForTagEnabled">
                <app-reply-suggestion-list-for-tag-users
                  [suggList]="tagSuggestionList"
                  [offSet]="tagOffSet"
                  [finished]="tagSuggestionListfinished"
                  (getOffSetUserList)="getOffSetUserList($event)"
                  (saveTaggedUserDataEmit)="saveTaggedUserDataEmit($event)">
                </app-reply-suggestion-list-for-tag-users>
              </div>
            <!-- </div> -->
          </div>
        </div>
      </div>
      <!-- End reply comment box -->
      <div *ngIf="cL?.comment_reply && (cL.total_comment_reply>cL.comment_reply.length)" class="clearfix mb-2"></div>
      <a *ngIf="cL?.comment_reply && (cL.total_comment_reply>cL.comment_reply.length) && offset>0"
        class="color-black secondary-paragraph" href="javascript:void(0);" (click)="replyLoadMore(cL);"
        [eventTracker]='{"category": callingfrom, "action": mls.googleEventText.reply_comment_card.key10, "product_type": commentObj?.product_type, "product_id": cL?.comment_id}'>
        {{mls?.languageText?.reply_comment_card?.key10}}
        <span *ngIf="mls?.is_lskey_display==1">(reply_comment_card.key10)</span>
       <img width="16px" class="pl-1" src="assets/themes/icons/down-arrow.svg" alt="down"/>

        <i *ngIf="load_more_reply==true" class="pad-left-5" id="loadmore_reply_cmt{{cL.comment_id}}">
          <img width="20px" height="20px" src="assets/themes/images/primary-loader.gif" alt="loader" /></i>
      </a>
    </div>
    <!--EoF Reply Section-->
  </div>

  <!--Comment edit-->
  <div *ngIf="isModalShown" class="modal fade edit-comment-popup" id="editCommentModal" tabindex="-1" role="dialog" [config]="{ show: true }" (onHidden)="onHidden()" bsModal #autoShownModal="bs-modal">
    <div class="custom-theme-modal modal-dialog sm modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header px-4 pt-4">
          <span class="cursor-pointer float-right" data-dismiss="modal" aria-label="Close" (click)="hideModal()"
          [eventTracker]='{"category":callingfrom,"action":mls.googleEventText.comment_card.key3,"product_type":commentObj?.product_type, "product_id":cL?.comment_id}'>
         <img src="assets/themes/icons/cancel.svg" alt="cross"/>
        </span>
          <p class="paragraph">{{mls.languageText.comment_card.key27}}<span
              *ngIf="mls?.is_lskey_display==1">(comment_card.key27)</span>
          </p>
        </div>
        <div class="modal-body text-center">
          <div class="form-textarea" *ngIf="product_type!=7">
            <div #editableCommentContent id="editComment"
              class="editable text-left h-auto overflow-hidden"
              contenteditable="true"
              data-placeholder=""
              [innerHtml]="edit_comment"
              [(ngModel)]="edit_comment"
              (click)="checkToShowTutorial()"
              (input)="userInput($event)"
              (keyup)="onKeyUp($event)"
              (keydown)="onKeyDown($event)">
            </div>
            <button [disabled]="showColor==false"
              [ngClass]=" showColor ? 'btn-design small filled' : 'btn-design small filled-disabled' "
              (click)="onUpdateComment()" class="btn-design small mt-3"
              [eventTracker]='{"category": callingfrom, "action": mls.googleEventText.comment_card.key3, "product_type": commentObj?.product_type, "product_id": cL?.comment_id}'>
              {{mls.languageText.comment_card.key11}}
              <span *ngIf="mls?.is_lskey_display==1">(comment_card.key11)</span>
            </button>
          </div>
          <app-tag-connections-pop-over *ngIf="isTagConnectionsEnabled"
            [isInfoModalShown]="isTagConnectionsEnabled"
            [tutorialTitle]="mls?.languageText?.tutorial?.key15"
            [tutorialMsg]="mls?.languageText?.tutorial?.key16"
            [tutorialCta]="mls?.languageText?.tutorial?.key17"
            [isTutorialFor]="tutorialFor.TAG_CONNECTION"
            (emitStartRequest)="closeTagTutorial()"
            (emitHideModal)="closeTagTutorial()"
            [callingFrom]="callingfrom">
          </app-tag-connections-pop-over>
          <div class="bg-white pl-3 pt-2 pr-3 mt-2" *ngIf="isSuggestionListForTagEnabled">
            <app-reply-suggestion-list-for-tag-users
              [fromCommentUpdate]=true
              [suggList]="tagSuggestionList"
              [offSet]="tagOffSet"
              [finished]="tagSuggestionListfinished"
              (getOffSetUserList)="getOffSetUserList($event)"
              (saveTaggedUserDataEmit)="saveTaggedUserDataEmit($event)">
            </app-reply-suggestion-list-for-tag-users>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--Eof Comment edit-->
  </div>
  <app-confirm-modal *ngIf="isConfirmModalShown==true" (resetConfirmModal)=resetConfirmModal($event)
    [isConfirmModalShown]=isConfirmModalShown [confirmInfo]=confirmInfo></app-confirm-modal>

  <!-- like list popup start**************************** -->
  <app-product-like *ngIf="showLikeListModal" (resetLikeModalList)=resetLikeModalList($event)
    (resetLike)=resetLike($event) [likeObj]=likeObj></app-product-like>
  <!-- like list popup end ***************************** -->