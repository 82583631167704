/* eslint-disable @typescript-eslint/no-unused-vars */
/**
 * Angular imports.
 */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

/**
 * Rxjs imports.
 */
import { Observable, Subject } from 'rxjs';
import { switchMap, debounceTime } from 'rxjs/operators';

/**
 * Environment imports.
 */
import { environment } from '../../../environments/environment';

/**
 * Constant imports.
 */
import { API } from '../../constants/api.constants';

/**
 * Search Data Service.
 */
@Injectable({
  providedIn: 'root'
})
export class SearchDataService {

  public queryUrl = '?search=';
  /**
   * Subject to handle focus on search field.
   */
  public focusSubject = new Subject<boolean>();
  public focuse$ = this.focusSubject.asObservable();

  /**
   * Necessary instances.
   */
  constructor(private httpClient: HttpClient) { }

  /**
   * Get Search Data.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getSearchData(keyword, offset): Observable<any> {
    const params = { keyword: keyword, offset: offset };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.httpClient.get<any>(API.SEARCH_DOCQUITY_LIST, {
      params: params
    });
  }

  /**
   * Get Search Term.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getSearchTerm(keyword): Observable<any> {
    const params = { 'term_keyword': keyword };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.httpClient.get<any>(API.SEARCH_TERMS, {
      params: params
    });
  }

  /**
   * Search.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  search(terms: Observable<string>): Observable<any> {
    return terms.pipe(debounceTime(300),
      switchMap(term => this.searchEntries(term)));
  }

  /**
   * Search Entries
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  searchEntries(term): Observable<any> {
    const params = { 'term_keyword': term };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.httpClient.get<any>(API.SEARCH_TERMS, {
      params: params
    });
  }

  /**
   * Will send confirmation for setting focus.
   */
  editSearch(): void {
    this.focusSubject.next(true);
  }

  /**
   * Post Search Key.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  postSearchKey(searchterm): Observable<any> {
    searchterm = [{ term: searchterm }];
    searchterm = JSON.stringify(searchterm);
    const body = { 'terms': searchterm };

    return this.httpClient.post(API.SEARCH_STORE_TERM, body);
  }
}
