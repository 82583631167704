/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Angular imports.
 */
import { Injectable } from '@angular/core';

/**
 * Service imports.
 */
import { DeviceDetectorService } from 'ngx-device-detector';

/**
 * Constant imports.
 */
import { DEVICES, NUMBERS, PRODUCT_TYPE_CONSTANT, SURVEY_ACCESS_TYPE, WEBVIEW_MOBILE_METHOD_GET, WEBVIEW_MOBILE_METHOD_POST, WEBVIEW_MOBILE_PARAMS } from '../../../app/constants/app.constants';

/**
 * Rxjs imports.
 */
import { Subject } from 'rxjs';
/**
 * model imports
 */
import { SurveyData } from '../../onboarding/interface/global.model';
/**
 * environment import
 */
import { environment } from '../../../environments/environment';

interface DataTransfer {
  method: 'POST' | 'GET' | 'UPDATE';
  body: any;
  params: any;
}

/**
 * This service is used to communicate between the mobile(ios, android) and webview imports.
 */
@Injectable({
  providedIn: 'root'
})
export class MobileWebViewCommunicationService {

  /**
   * Received data from the mobile.
   */
  public dataFromMobile: DataTransfer;
  public isAuthenticByPass: boolean;
  private tokenData: any;
  private analyticData: any;
  private deeplinkData: any;
  private comingFrom: string;
  public webLang = new Subject();
  public userAuthKey$ = new Subject();
  public authenticByPass$ = new Subject();
  public deeplinkRedirect$ = new Subject();
  public externalSurveyDetail: SurveyData = null;
  public internalSurveyDetail: SurveyData = null;
  public isExternalSurvey = false;
  public updateTokenRequest$ = new Subject<boolean>();
  public authenticatedExternalSurvey = false;


  /**
   * On initialization Request from the mobile for the data.
   */
  constructor(
    private deviceService: DeviceDetectorService
  ) {
    if (!this.checkForDesktop()) {
      (window as any).receiveEventFromMobileApp = this.receiveEventFromMobileWebView.bind(this);
    }
  }

  /**
   * Request data from the mobile according to the requesting data.
   */
  receiveEventFromMobileWebView(eventData: DataTransfer): void {
    console.log('Received event from mobile app:', eventData);
    this.dataFromMobile = eventData;
    this.filterData(eventData);
  }

  /**
   * captures events from mobile platform
   */
  filterData(eventData: DataTransfer): void {
    if (eventData.method === WEBVIEW_MOBILE_METHOD_POST && eventData.params === WEBVIEW_MOBILE_PARAMS.TOKEN) {
      this.isAuthenticByPass = true;
      this.authenticByPass$.next(true);
      this.tokenData = eventData.body;
      this.setWebLanguage(this.tokenData?.lang);
      this.sendInfoToBothMobile({method: WEBVIEW_MOBILE_METHOD_GET, params: WEBVIEW_MOBILE_PARAMS.ANALYTICS, body: 'Get the analytics data'});
      const {at, rt, expin, rexpin} = this.tokenData;
      localStorage.setItem('mcTokenDetail', JSON.stringify({at, rt, expin, rexpin}));
      localStorage.setItem('ip_addr', this.tokenData.ipAddress);
      localStorage.setItem('country_code', this.tokenData.countryCode);
      this.userAuthKey$.next(this.tokenData.userAuthKey);
    }
    if (eventData.method === WEBVIEW_MOBILE_METHOD_POST && eventData.params === WEBVIEW_MOBILE_PARAMS.ANALYTICS) {
      this.analyticData = eventData.body;
      this.sendInfoToBothMobile({method: WEBVIEW_MOBILE_METHOD_GET, params: WEBVIEW_MOBILE_PARAMS.COMING_FROM, body: 'Get the coming from data'});
      this.sendInfoToBothMobile({method: WEBVIEW_MOBILE_METHOD_GET, params: WEBVIEW_MOBILE_PARAMS.DEEPLINK_DATA, body: 'Get any deeplink info'});
    }
    if (eventData.method === WEBVIEW_MOBILE_METHOD_GET && eventData.params === WEBVIEW_MOBILE_PARAMS.COMING_FROM) {
      this.comingFrom = eventData.body;
    }
    if (eventData.method === WEBVIEW_MOBILE_METHOD_POST && eventData.params === WEBVIEW_MOBILE_PARAMS.DEEPLINK_DATA) {
      this.deeplinkData = eventData.body;
      this.deeplinkRedirect$.next(this.deeplinkData);
    }
  }

  /**
   * Set the website local language.
   */
  setWebLanguage(lang: string): void {
    localStorage.setItem('lang', lang ? lang : 'en');
    this.webLang.next(lang);
  }

  /**
   * Set the website local language.
   */
  getWebLanguagep(lang: string): void {
    localStorage.setItem('lang', lang ? lang : 'en');
  }

  /**
   * Send data to the both mobile ios and android.
   */
  sendInfoToBothMobile(data: DataTransfer): void {
    if (!this.checkForDesktop()) {
      this.sendEventToAndroidApp(data);
      this.sendEventToIosApp(data);
    }
  }

  /**
   * Send data to the android.
   */
  sendEventToAndroidApp(data: DataTransfer): void {
    if (!this.checkForDesktop()) {
      (window as any)?.AndroidWebViewInterface?.receiveDataFromAndroid(JSON.stringify(data));
    }
  }

  /**
   * Send data to the ios.
   */
  sendEventToIosApp(data: DataTransfer): void {
    if (!this.checkForDesktop()) {
      (window as any)?.webkit?.messageHandlers?.IosWebViewInterface?.postMessage(JSON.stringify(data));
    }
  }

  /**
   * Check if authentication should bypass or not.
   */
  checkAuthenticByPass(): boolean {
    return this.isAuthenticByPass;
  }

  /**
   * Check if requested from desktop.
   */
  checkForDesktop(): boolean {
    return this.deviceService.isDesktop();
  }

  /**
   * Get the analytic data.
   */
  getAnalyticData(): any {
    return this.analyticData ? this.analyticData : null;
  }

  /**
   * Get the analytic data.
   */
  getMobileUserComingFrom(): any {
    return this.comingFrom ? this.comingFrom : null;
  }

  /**
  * redirection in mobile
  * @param product_id
  * @param safe_url
  * @param redirect_url
  * @returns
  */
  backToSource(product_id: string, safe_url: string, deviceType: string, redirect_url = ''): void {
    if (this.deviceService.isMobile() || this.deviceService.isTablet()) {
      if (this.checkForMobilePlatform(deviceType)) {
        const action = 'discard_survey';
        const data = {
          product_id,
          product_type: PRODUCT_TYPE_CONSTANT.SURVEY,
          safe_url,
          safe: redirect_url,
          transaction: {
            status: 0,
            icon_url: '',
            title: '',
            description: ''
          }
        };
        const desUrl = `${environment.API_URLS.others}/action-end?action=${action}&data=${JSON.stringify(data)}`;
        window.location.href = desUrl;
        return;
      }
    }
  }

  redirectSurvey(): void {
    if(this.externalSurveyDetail || this.internalSurveyDetail) {
      const survey = this.externalSurveyDetail || this.internalSurveyDetail;

      if(this.checkForMobilePlatform(survey.deviceType)) {
        this.backToSource(survey.surveyId, survey.surveyId,survey.deviceType,'');
        return;
      } else {
        window.close();
      }
    }
    if(localStorage.getItem('isComingFromDeepLink') === '1'){
      window.close();
    }
  }
  /**
   * Check for request from mobile platform
   * @returns
   */
  checkForMobilePlatform(deviceType: string): boolean {
    return deviceType && Object.values(DEVICES).indexOf(deviceType) > NUMBERS.MINUS_ONE;
  }
  /**
   * Checks the platform the survey is accessed from
   */
  checkSurveyRequestedFrom(): boolean {
    if(this.externalSurveyDetail &&
      this.externalSurveyDetail.surveyType === SURVEY_ACCESS_TYPE.EXTERNAL) {
        if(this.checkForMobilePlatform(this.externalSurveyDetail?.deviceType)) {
          this.isAuthenticByPass = true;
          this.authenticByPass$.next(true);
          return true;
        }
        if(this.isExternalSurvey) {
          return true;
        }
        if(this.externalSurveyDetail?.deviceType == 'web' && !localStorage.getItem('mcTokenDetail')) {
          return false;
        }
    }
    return false;
  }

  /**
   * Check for IOS device
   * @returns
   */
  checkForIOS(): boolean {
    return (this.externalSurveyDetail &&
      this.externalSurveyDetail?.deviceType === DEVICES.IOS) || false;
  }
}
