/**
 * Angular Imports
 */
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

/**
 * Service imports
 */
import { FunCollectionService } from '../../common/common-functions/fun-collection.service';
import { NewLanguageService } from '../../dataservices/new-language/new-language.service';

/**
 * Constant Imports
 */
import { NUMBERS, SCREEN_NAME, OPEN_ACCESS } from '../../constants/app.constants';
import { IPublicAnalytics, IPublicAnalyticsData } from '../public-analytics/public-analytics.model';
import { PurpleOnboarding } from '../purple-onboarding.service';
import { Subscription } from 'rxjs';
import { OpenAccessService } from '../open-access.service';

@Component({
  selector: 'app-public-article-login-card',
  templateUrl: './public-article-login-card.component.html',
  styleUrls: ['./public-article-login-card.component.scss']
})
export class PublicArticleLoginCardComponent {

  @ViewChild('articleloginContainer') articleloginContainer!: ElementRef;

  /**
   * input data for calling from
   * getting data for card
   */
  @Input() callingFrom : string;
  @Input() openArticle : any;
  @Input() renderType: string;
  @Input() pType: number;
  @Input() textContainer;
  @Input() analyticRecord;
  @Input() isEpisode;
  @Input() isCarousel = false;
  @Output() cardClicked: EventEmitter<void> = new EventEmitter<void>();
  /**
   *  Constant for screen names
   *  constant for numbers
   */
  public screenName = SCREEN_NAME;
  public numbers = NUMBERS;
  public openAccessConst = OPEN_ACCESS;
  public subscription: Subscription;
  
  /**
   * Creating required objects.
   */
  constructor(
    public fcs : FunCollectionService,
    private router: Router, public mls: NewLanguageService,
    public login : PurpleOnboarding,
    public oas : OpenAccessService) {
  }

  ngOnInit(): void {

  }
  
  /**
   * Default Error Handler
   */
  errorHandler(event) {
    event.target.src = (this.pType === this.openAccessConst.productlist.article)? 'assets/themes/images/article-default.svg':(this.pType === this.openAccessConst.productlist.webinar)? 'assets/themes/images/webinar-default.svg':(this.pType === this.openAccessConst.productlist.doctalk)? 'assets/themes/images/doctalk-default.svg': (this.pType === this.openAccessConst.productlist.series)? 'assets/themes/images/doctalk-default.svg': '';
  }

  /**
   * navigate user on specific product detail page.
   */
  navigateToOpenProductdetail(): void {
    this.oas.openArticle = this.openArticle ;
    if(this.oas.openArticle.login_required){
    this.cardClicked.emit();
    }
    else{
     this.fcs.navigateToOpenProductDetail(this.openArticle.product_type, this.openArticle.id);
    }
  }

  private setAnalytics(callingFrom: string): void {
    switch(callingFrom) {
      case SCREEN_NAME.OPEN_ARTICLE_DETAIL:
        
      break;
    }
  }
}
