/* eslint-disable @angular-eslint/no-output-on-prefix */
/**
 * Angular imports.
 */
import { Component, EventEmitter, Input, Output } from '@angular/core';

/**
 * No information for the chat.
 */
@Component({
  selector: 'app-chat-no-info',
  templateUrl: './chat-no-info.component.html',
  styleUrls: ['./chat-no-info.component.scss']
})
export class ChatNoInfoComponent {

  /**
   * Banner for the no chat information.
   * Paragraph for the no chat information.
   * Submit button text for the no chat information.
   * Banner for the no chat information.
   * Flag for the secondary/primary button design.
   */
  @Input() banner: string;
  @Input() paragraph: string;
  @Input() btnText: string;
  @Input() isSecondary: boolean;

  /**
   * Emit the submit button click.
   */
  @Output() onBtnClick: EventEmitter<void> = new EventEmitter();

  /**
   * Emit the submit button click.
   */
  onBtn(): void {
    this.onBtnClick.emit();
  }
}
