/**
 * Angular imports.
 */
import { Component, OnInit, Input, Output, ElementRef, EventEmitter, OnChanges, OnDestroy, ViewChild } from '@angular/core';

/**
 * Service imports.
 */
import { EventMsgService } from '../../../dataservices/master-service/event-msg.service';
import { ImageGroupService } from '../../../dataservices/master-service/image-group.service';
import { AuthService } from '../../../auth/auth.service';
import { MasterserviceService } from '../../../dataservices/master-service/masterservice.service';
import { PostApiService } from '../../../dataservices/post-api.service';
import { FunCollectionService } from '../../../common/common-functions/fun-collection.service';
import { GetService } from '../../../dataservices/get.service';
import { JwtDecodeService } from '../../../dataservices/jwt-decode.service';
import { DataStorageService } from '../../../dataservices/data-storage.service';
import { NewLanguageService } from '../../../dataservices/new-language/new-language.service';
import { AnalyticsEventService } from '../../../analytics-event/analytics-event.service';
import { UserStoryService } from 'src/app/dataservices/userStory/user-story.service';
/**
 * Library imports.
 */
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';

/**
 * Rxjs imports.
 */
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

/**
 * Constant imported.
 */
import { CONNECTION_ENABLE, NUMBERS, TUTORIAL_FOR, USER_TAG_REGX, PRODUCT_TYPE_CONSTANT, READ_KEY } from '../../../constants/app.constants';
/**
 * Analytics Import
 */
import { EVENT_NAME } from '../../../analytics-event/analytics.constant';
/**
 * Owl Carousel Import.
 */
import { OwlOptions } from 'ngx-owl-carousel-o';
/**
 * Interface import.
 */
import { AnalyticsInterface, EventDataKey } from '../../../analytics-event/analytics.model';

/**
 * Comment Card Component
 */
@Component({
  selector: 'app-comment-card',
  templateUrl: './comment-card.component.html',
  styleUrls: ['./comment-card.component.scss']
})
export class CommentCardComponent implements OnInit, OnChanges, OnDestroy {
  public comment_card_image = {
    prflclass: 'user-40 text-center rounded-circle text-white d-table-cell align-middle',
    prflpic: '',
    prflfname: '',
    prflmname: '',
    prfllname: '',
    prflrname: '',
    prflcid: '',
    prfltype: '',
    prflpicclass: 'c-profile-pic rounded-circle user-40 post-pic',
    badge: { is_verified: 0, is_show: true },
    full_name: '',
    is_action: false,
    anonymous: '0',
    partial_permission_state: ''
  };
  // public newReply = '';
  /**
   * View Child for Editable Div Element for Tagged User
   */
  @ViewChild('editableContent', { read: ElementRef }) editableContent: ElementRef;
  @ViewChild('editableCommentContent', { read: ElementRef }) editableCommentContent: ElementRef;

  public comment_reply_post_prfl_image = {
    prflclass: 'user-40 text-center rounded-circle text-white d-table-cell align-middle',
    prflpic: '',
    prflfname: '',
    prflmname: '',
    prfllname: '',
    prflrname: '',
    prflcid: '',
    prfltype: '',
    prflpicclass: 'c-profile-pic rounded-circle user-40 post-pic',
    badge: { is_verified: 0, is_show: true },
    full_name: '',
    is_action: false,
    anonymous: '0',
    partial_permission_state: ''
  };
  @Input() cL;
  @Input() index;
  @Input() commentObj;
  @Input() callingfrom;
  @Input() screenName;
  @Input() comment_box_obj;
  @Input() uuid;
  @Input() isStoryAvailable = 0;
  @Output() resetComment = new EventEmitter();
  @Output() resetCommentdelete = new EventEmitter();
  /**
   * Reply comment count.
   */
  @Output() replyCommentCount = new EventEmitter();
  public replyVal = '';
  public showColor = false;
  public isConfirmModalShown = false;
  public loginUserCustomeId: string;
  public user_profile_pic_path = '';
  public isModalShown = false;
  public edit_comment = '';
  public confirmInfo = '';
  public offset = 1;
  public load_more_reply = false;
  public comment_like_loader = false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public likeObj: any;
  public showLikeListModal = false;
  public media_json = [];
  public showReply = false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public user_permission: any;
  public commentTag = [];
  public reply_commit_in_process = false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public ngbDDL: any;
  /**
   * Search Word For Tag
   * Actual Comment
   * Is Tag Connections Enabled
   * Is Suggestion List For Tag Enabled
   * Tutorial for Tag User
   * Tag User Suggestion Connect List
   * Tag User Suggestion Connection List offset
   * Tag User List for Iteration
   * Tag User Suggestion List Flag for API Call Finished
   * Comment Section Text as Html Element
   */
  public searchWordForTag = '';
  public actualComment = '';
  public isTagConnectionsEnabled = false;
  public isSuggestionListForTagEnabled = false;
  public tutorialFor = TUTORIAL_FOR;
  public tagSuggestionList = [];
  public tagSuggestionListOffSet = NUMBERS.ONE;
  public tagUserList = [];
  public tagSuggestionListfinished = false;
  public commentSectionText: HTMLElement;
  public editableTag = [];
  public tagOffSet = NUMBERS.ONE;
  /**
   * to store comment id which is coming from notifcations
   */
  public notificationCommentId: number;
  /**
   * Search Term to make a Switch Map call with Latest Search Term
   */
  searchTerm = new Subject<string>();
  /**
   * To unsubscribe data.
   */
  private subscriptions: Subscription = new Subscription();
  /**
   * Analyticsdata object.
   */
  public analyticsData: AnalyticsInterface;
  /**
   * Constant number.
   */
  public number = NUMBERS;

  /**
   * Taguser search
   */
  public tagUserSearchWord = '';
  public selectAll = false;
  subject = new Subject();


  /**
   * Auto comment carousel property.
   */
  customOptionsNudge: OwlOptions = {
    loop: false,
    touchDrag: true,
    navSpeed: 700,
    navText: ['<i class="prev-slider position-absolute"></i>', '<i class="next-slider position-absolute"></i>'],
    responsive: {
      0: {
        items: 1.75,
      },
      300: {
        items: 2,
      },
      400: {
        items: 3,
      },
      500: {
        items: 4,
      },
      650: {
        items: 5,
      },
      600: {
        items: 4,
      },
      940: {
        items: 5,
      },
    },
    margin: 6,
    autoWidth: true,
    dots: false,
    nav: true
  };
  /**
   * Auto comment array.
   */
  public postComment = [];
  /**
   * Comment string.
   */
  public comment = '';

  /**
   * Output emit auto comment click on option.
   */
  @Output() emitAutoComment = new EventEmitter();

  /**
   * Necessary instances.
   */
  constructor(
    private jwtSer: JwtDecodeService,
    public dss: DataStorageService,
    private getSer: GetService,
    public fcs: FunCollectionService,
    private postSer: PostApiService,
    private authService: AuthService,
    private masterService: MasterserviceService,
    public igs: ImageGroupService,
    public ems: EventMsgService,
    public mls: NewLanguageService,
    private config: NgbDropdownConfig,
    private storyServ: UserStoryService,
    private analyticsEventService : AnalyticsEventService) {
    this.loginUserCustomeId = this.authService.getbyName('profile_obj');
    this.user_permission = this.dss.user_profile_data.profile.permission;
    this.user_profile_pic_path = this.dss.user_profile_data.profile.profile_pic_path;
    config.placement = 'bottom-left';
  }

  /**
   * initializeNgbDdl.
   */
  initializeNgbDdl(): void {
    this.notificationCommentId = this.dss.notificationAdditionalData?.subtype_target;
    if (this.cL.is_anonymous == '1') {
      this.ngbDDL = {
        class: 'm', calling_from: 'comment card',
        data: [
          { index: 0, action: 'report', text: this.mls.languageText.comment_card.key14, product_type: 2, product_type_id: this.cL['comment_id'] },
        ]
      };
    } else if (this.cL.user_profile.connection_status == '0' || this.dss.user_profile_data.profile.permission == 'readonly' && this.cL.user_profile.is_connect_enable === CONNECTION_ENABLE.ENABLE) {
      this.ngbDDL = {
        class: 'm', calling_from: 'doctalk card',
        data: [
          { index: 0, action: 'report', text: this.mls.languageText.comment_card.key14, product_type: 2, product_type_id: this.cL['comment_id'] },
          { index: 2, action: 'viewprofile', text: this.mls.languageText.comment_card.key15, product_type: 2, product_type_id: this.cL.user_profile.custom_id },
          { index: 3, action: 'connect', text: this.mls.languageText.comment_card.key16, product_type: 2, product_type_id: this.cL.user_profile.custom_id },
        ]
      };
    } else {
      this.ngbDDL = {
        class: 'm', calling_from: 'doctalk card',
        data: [
          { index: 0, action: 'report', text: this.mls.languageText.comment_card.key14, product_type: 2, product_type_id: this.cL['comment_id'] },
          { index: 2, action: 'viewprofile', text: this.mls.languageText.comment_card.key15, product_type: 2, product_type_id: this.cL.user_profile.custom_id },
        ]
      };
    }
  }

  /**
   * ngOnInit.
   */
  ngOnInit(): void {

    this.initializeNgbDdl();

    this.comment_card_image.prflcid = this.cL.user_profile.custom_id;
    this.comment_card_image.prflpic = this.cL.user_profile.profile_pic;
    this.comment_card_image.prflrname = this.cL.user_profile.registered_name;
    this.comment_card_image.prflfname = this.cL.user_profile.first_name;
    this.comment_card_image.prflmname = this.cL.user_profile.middle_name;
    this.comment_card_image.prfllname = this.cL.user_profile.last_name;
    this.comment_card_image.full_name = this.cL.user_profile.first_name + ' ' + this.cL.user_profile.middle_name + ' ' + this.cL.user_profile.last_name;
    this.comment_card_image.badge.is_verified = (this.cL.user_profile.permission == 'all') ? 1 : 0;
    this.comment_card_image.partial_permission_state = this.cL.user_profile.partial_permission_state;
    this.comment_card_image.anonymous = this.cL.is_anonymous;

    this.comment_reply_post_prfl_image.prflcid = this.dss.user_profile_data.custom_id;

    this.comment_reply_post_prfl_image.prflpic = this.dss.user_profile_data.profile.profile_pic_path;
    this.comment_reply_post_prfl_image.prflrname = this.dss.user_profile_data.profile.registered_name;
    this.comment_reply_post_prfl_image.prflfname = this.dss.user_profile_data.profile.first_name;
    this.comment_reply_post_prfl_image.prfllname = this.dss.user_profile_data.profile.last_name;
    this.comment_reply_post_prfl_image.full_name = this.dss.user_profile_data.first_name + ' ' + this.dss.user_profile_data.middle_name + ' ' + this.dss.user_profile_data.last_name;
    this.comment_reply_post_prfl_image.badge.is_verified = (this.dss.user_profile_data.profile.permission == 'all') ? 1 : 0;
    this.comment_reply_post_prfl_image.partial_permission_state = this.dss.user_profile_data.profile.partial_permission_state;
    this.isStoryAvailable = this.cL?.user_profile?.is_story_available;
    this.uuid = this.cL?.user_profile?.uuid;
    this.subject
      .pipe(debounceTime(500))
      .subscribe(() => {
        this.resetTagSuggestion();
        if (this.tagUserSearchWord.includes('@')) {
          // tslint:disable-next-line: deprecation
          this.openTagUserSuggestionList();
        } else {
          this.replyVal = this.commentSectionText.textContent;
        }
      }
      );

  }

  openStory(uuid): void {
    this.dss.viewStoryThroughProfileUuid = uuid;
    this.dss.showStoryThroughProfile.next(true);
   }

   /**
   * 
   * @param story function to check if all stories are viewed
   * @returns 
   */
   isStoriesAvailable(): boolean {
    const apiRes = this.isStoryAvailable === 1 ? true : false;
    let realTime = false;
    // 
    if (this.uuid) {
      realTime = !this.storyServ.isAllStoryViewed(this.uuid);
    }
    return apiRes && realTime;
  }
  /**
   * view comment profile.
   */
  viewcmmntprfl(): void {
    if (this.isStoriesAvailable() && this.uuid != null && this.uuid != undefined) {
      this.openStory(this.uuid);
    }
    else if (this.cL.is_anonymous != '1' && this.cL.user_profile.custom_id != undefined && this.cL.user_profile.custom_id != '') {
      const x = { is_show: false, feed_id: 0, mediaIdx: 0 };
      this.dss.showFeedDetailInModal.next(x);
      this.fcs.navigateToUserProfie(this.cL.user_profile.custom_id);
    }
  }

  /**
   * ngOnChanges
   */
  ngOnChanges(): void {
    this.showColor = false;
  }


  /**
   * update Comment
   */
  updateComment(): void {
    this.isModalShown = true;
    this.isSuggestionListForTagEnabled = false;
    this.editableTag = [];
    setTimeout(() => {
      this.editableCommentContent.nativeElement.setAttribute('data-placeholder', this.mls?.languageText?.comment_card?.key24);
      /* Code Start to Extract Tag User Detail From API */
      const path = this.cL?.comment;
      const paramsPattern = USER_TAG_REGX.removeBracesRegex;
      const extractParams = path.match(paramsPattern);
      if (extractParams) {
        for (let i = 0; i < extractParams.length; i++) {
          this.editableTag.push(extractParams[i]);
          const words = extractParams[i].split(':');
          const url = '#/webprofile/view/info/' + words[0];
          const string = '<a class=\'user-tag-comment-detail tag-user\' href=\'' + url + '\'><b>@' + words[1] + '</b></a>';
          this.cL.comment = this.cL?.comment.replace('{' + extractParams[i] + '}', string);
        }
      }
      this.edit_comment = this.cL['comment'];
      this.commentSectionText = document.getElementById('editComment');
      this.showReply = false;
      setTimeout(() => {
        this.addAttr();
      }, 1000);
    }, NUMBERS.ZERO);


  }

  /**
   * This is used to add the attribute contenteditable.
   */
  addAttr(): void {
    const matches = document.querySelectorAll('a.user-tag-comment-detail');
    matches.forEach((el) => {
      el.setAttribute('contenteditable', 'false');
    });
  }

  /**
   * reset NgbDDL Record
   */
  resetNgbDDLRecord(data): void {
    this.cL.user_profile.connection_status = data;
    this.initializeNgbDdl();
  }

  /**
   * onUpdate Comment
   */
  onUpdateComment(): void {
    let comment =  this.commentSectionText.textContent.trim();
    for (let i = 0; i < this.tagUserList.length; i++) {
      let tagUserObj = '';
      if (comment.includes(this.tagUserList[i]['full_name'])) {
        tagUserObj = `{${this.tagUserList[i]['custom_id']}:${this.tagUserList[i]['full_name']}}`;
        comment = comment.replace(`@${this.tagUserList[i]['full_name']}`, tagUserObj);
      }
    }
    for (let i = 0; i < this.editableTag.length; i++) {
      let tagUserObj = '';
      const words = this.editableTag[i].split(':');
      if (comment.includes(words[1])) {
        tagUserObj = `{${words[0]}:${words[1]}}`;
        comment = comment.replace(`@${words[1]}`, tagUserObj);
      }
    }


    this.subscriptions.add(this.postSer.productComment(2, this.cL['comment_id'], comment, [], 'edit').subscribe(resData => {
      this.jwtSer.decodeData(resData).then(data => {
        if (data['status'] == 1) {
          this.edit_comment = comment;
          this.cL['comment'] = this.edit_comment;
          this.isModalShown = false;
          this.showColor = false;
          this.fcs.showSuccess(data['msg'], 'Success');
        } else if (data['status'] == 0) {
          this.fcs.errorHandeler(data);
          return;
        }
      });
    }));
  }

  /**
   * hide Modal
   */
  hideModal(): void {
    this.isModalShown = false;
  }

  /**
   * onHidden
   */
  onHidden(): void {
    this.isModalShown = false;
  }

  /**
   * show reply inbox
   */
  showreplyinbox(): void {
    this.showReply = true;
    setTimeout(() => {
      document.querySelector<HTMLElement>('#replycmt' + this.cL.comment_id).focus();
      this.editableContent.nativeElement.setAttribute('data-placeholder', this.mls?.languageText?.comment_card?.key24);
      this.commentSectionText = document.getElementById(`replycmt${this.cL.comment_id}`);
    }, 200);
  }

  /**
   * show Reply Box
   */
  showReplyBox(): void {
    this.showreplyinbox();
  }

  /**
   * reply Load More
   */
  replyLoadMore(data): void {
    if (this.masterService.isOnline == false) {
      this.masterService.isConnectionModalShown.next('1');
      return;
    }
    this.load_more_reply = true;
    this.subscriptions.add(this.getSer.getProductCommentList(2, data.comment_id, this.offset).subscribe(resData => {
      this.load_more_reply = false;
      this.jwtSer.decodeData(resData).then(data => {
        if (data.status == 1) {
          if (this.offset == 1) {
            this.cL.comment_reply = [];
          }

          this.offset = data.data.offset;
          this.cL.comment_reply = this.cL.comment_reply.concat(data.data.comment);
        } else if (data.status == 0) {
          this.fcs.errorHandeler(data);
        }
      }, () => {
        this.load_more_reply = false;
      });
    }));
  }

  /**
   * on Delete Comment
   */
  onDeleteComment(): boolean {
    if (this.masterService.isOnline == false) {
      this.masterService.isConnectionModalShown.next('1');
      return;
    }

    this.isConfirmModalShown = true;
    this.confirmInfo = this.mls.languageText.comment_card.key17;
    return false;
  }

  /**
   * delete Comment
   */
  deleteComment(): void {
    this.subscriptions.add(this.postSer.productComment(2, this.cL['comment_id'], this.edit_comment, [], 'delete').subscribe(resData => {
      this.jwtSer.decodeData(resData).then(data => {
        if (data['status'] == 1) {
          this.fcs.showSuccess(data['msg'], 'Success');
          this.resetComment.emit(this.index);
        } else if (data['status'] == 0) {
          this.fcs.errorHandeler(data);
          return;
        }
      });
    }));
  }

  /**
   * reset Confirm Modal
   */
  resetConfirmModal(data): void {
    this.isConfirmModalShown = false;
    this.confirmInfo = '';
    if (data == true) {
      this.edit_comment = this.cL['comment'];
      this.deleteComment();
    }
  }

  /**
   * product Like
   */
  productLike(data): boolean {
    if (this.masterService.isOnline == false) {
      this.masterService.isConnectionModalShown.next('1');
      return false;
    }

    if (this.comment_like_loader == true) { return; }
    if (this.commentObj.kind == 'cases' && data == 'upvote') {

      const action = (this.cL.comment_like_status == 1) ? 'unlike' : 'like';
      this.comment_like_loader = true;
      this.subscriptions.add(this.postSer.productLike(2, this.cL.comment_id, action).subscribe(resData => {
        this.comment_like_loader = false;
        this.jwtSer.decodeData(resData).then(data => {
          if (data['status'] == 1) {
            this.fcs.showSuccess(data['msg'], 'Success');
            this.cL.comment_like_status = (action == 'unlike') ? 0 : 1;
            this.cL.total_comment_like = (action == 'unlike') ? this.cL.total_comment_like - 1 : this.cL.total_comment_like + 1;
          } else if (data['status'] == 0) {
            this.fcs.errorHandeler(data);
            return;
          }
        }, () => {
          this.comment_like_loader = false;
        });
      }));
    } else if (this.commentObj.kind == 'cases' && data == 'like') {
      if (this.cL.comment_like_status == 1) { return; }

      const action = (this.cL.comment_like_status == 1) ? 'unlike' : 'like';
      this.comment_like_loader = true;
      this.subscriptions.add(this.postSer.productLike(2, this.cL.comment_id, action).subscribe(resData => {
        this.comment_like_loader = false;
        this.jwtSer.decodeData(resData).then(data => {
          if (data['status'] == 1) {
            this.fcs.showSuccess(data['msg'], 'Success');
            this.cL.comment_like_status = (action == 'unlike') ? 0 : 1;
            this.cL.total_comment_like = (action == 'unlike') ? this.cL.total_comment_like - 1 : this.cL.total_comment_like + 1;
          } else if (data['status'] == 0) {
            this.fcs.errorHandeler(data);
            return;
          }
        }, () => {
          this.comment_like_loader = false;
        });
      }));
    } else {
      if (this.cL.comment_like_status == 1) { return; }

      this.comment_like_loader = true;
      this.subscriptions.add(this.postSer.productLike(2, this.cL.comment_id, 'like').subscribe(resData => {
        this.comment_like_loader = false;
        this.jwtSer.decodeData(resData).then(data => {
          if (data['status'] == 1) {
            this.fcs.showSuccess(data['msg'], 'Success');
            this.cL.comment_like_status = 1;
            this.cL.total_comment_like = this.cL.total_comment_like + 1;
          } else if (data['status'] == 0) {
            this.fcs.errorHandeler(data);
            return;
          }
        }, () => {
          this.comment_like_loader = false;
        });
      }));
    }
  }

  /**
   * get Product Like List
   */
  getProductLikeList(): void {
    this.likeObj = { 'product_id': this.cL.comment_id, 'product_type': 2, 'callLikeProperty': 'list' };
    this.showLikeListModal = true;
  }

  /**
   * reset Like
   */
  resetLike(): void {
    this.showLikeListModal = false;
  }

  /**
   * Post Comment
   */
  setComment(e, product_id, product_type, file_json): void {
    let comment = this.commentSectionText.textContent;
    for (let i = 0; i < this.tagUserList.length; i++) {
      let tagUserObj = '';
      if (comment.includes(this.tagUserList[i]['full_name'])) {
        tagUserObj = `{${this.tagUserList[i]['custom_id']}:${this.tagUserList[i]['full_name']}}`;
        comment = comment.replace(`@${this.tagUserList[i]['full_name']}`, tagUserObj);
      }
    }
    if (this.masterService.isOnline == false) {
      this.masterService.isConnectionModalShown.next('1');
      return;
    }
    if (this.reply_commit_in_process == true) { return; }

    this.reply_commit_in_process = true;
    if (comment.trim() == '' && (file_json == '[]' || file_json == '')) { return; }
    this.subscriptions.add(this.postSer.productComment(2, product_id, comment.trim(), file_json, 'add').subscribe(resData => {
      // TODO: Need to remove or add alternative of settimeout.
      setTimeout(() => {
        this.resetTagBox();
      }, 1000);
      this.media_json = [];
      this.jwtSer.decodeData(resData).then(data => {
        this.replyCommentCount.emit(NUMBERS.ONE);
        this.reply_commit_in_process = false;
        if (data['status'] === 1) {
          this.replyVal = '';
          this.fcs.showSuccess(data['msg'], 'Success');
          e.target.value = '';
          this.cL.comment_reply = this.cL.comment_reply.concat(data['data']['comment']);
          comment = '';
          this.commentSectionText.innerHTML = '';
          this.cL.total_comment_reply = this.cL.comment_reply.length;
        } else if (data['status'] === 0) {
          this.fcs.errorHandeler(data);
        }
      }, () => {
        this.reply_commit_in_process = false;
      });
    }));

    /**
     * Set analytics data for analytics event.
     */
    this.sendAnalytics(EVENT_NAME.COMMENT_POST_CLICK, PRODUCT_TYPE_CONSTANT.COMMENT, product_id, this.callingfrom);

  }

  /**
   * reset Reply Comment
   */
  resetReplyComment(data): void {
    this.cL.comment_reply.splice(data, 1);
    this.cL.total_comment_reply = this.cL.comment_reply.length;

  }

  /**
   * navigateTo userDetail section
   */
  navigateTouser(customeId): boolean {
    if (customeId == '' || customeId == undefined) { return; }
    if (this.masterService.isOnline == false) {
      this.masterService.isConnectionModalShown.next('1');
      return false;
    }

    const x = { is_show: false, feed_id: 0, mediaIdx: 0 };
    this.dss.showFeedDetailInModal.next(x);
    this.fcs.navigateToUserProfie(customeId);
  }

  /**
   * isActivate Button
   */
  isActivateButton(e): void {
    if (e.target.innerText?.trim() != '') {
      this.showColor = true;
    } else {

      this.showColor = false;
    }
  }
  /**
   * Check for Default Show Tutorial for Tagged User
   */
  checkToShowTutorial(): void {
    if (this.comment_reply_post_prfl_image.badge.is_verified || (this.comment_reply_post_prfl_image.partial_permission_state.toString() === NUMBERS.TWO.toString())) {
      if (localStorage.getItem('tagConnectionInformationCardFlag') !== 'true') {
        this.isTagConnectionsEnabled = true;
      } else {
        this.isTagConnectionsEnabled = false;
      }
    } else {
      this.isTagConnectionsEnabled = false;
    }
  }

  /**
   * Reset Tag Suggestion List
   */
  resetTagSuggestion(): void {
    this.tagSuggestionList = [];
    this.tagSuggestionListOffSet = NUMBERS.ONE;
    this.isSuggestionListForTagEnabled = false;
    this.tagSuggestionListfinished = true;
  }
  /**
   * Save Selected Tagged User
   */
  saveTaggedUserDataEmit(sugg): void {
    this.tagUserList.push(sugg);
    this.isSuggestionListForTagEnabled = false;
    this.commentSectionText.innerHTML = this.commentSectionText.innerHTML + `##${sugg.full_name}##` ;
    this.commentSectionText.innerHTML = this.commentSectionText.innerHTML.replace(this.searchWordForTag+'##'+sugg.full_name+'##', '##'+sugg.full_name+'##');
    const tagUserClassName = 'tag-user';
    setTimeout(() => {
      for (let i = 0; i < this.tagUserList.length; i++) {
        this.commentSectionText.innerHTML = this.commentSectionText.innerHTML.replace(
          `@##${this.tagUserList[i]['full_name']}##` || this.tagUserList[i]['full_name'],
          `<span contenteditable=false class="${tagUserClassName}">@${this.tagUserList[i]['full_name']}</span>&nbsp;`
        );
      }
      // put the cursor to the end of field again...
      this.selectEnd();
    }, NUMBERS.TEN);
    this.replyVal = this.commentSectionText.textContent;
    this.searchWordForTag = '';
    this.tagUserSearchWord = '';
  }

  /**
   * put the cursor to the end of field again...
   */
  selectEnd(): void {
    let range, selection;
    // eslint-disable-next-line prefer-const
    range = document.createRange();
    if (document.getElementById('replycmt' + this.cL.comment_id)) {
      range.selectNodeContents(document.getElementById('replycmt' + this.cL.comment_id));
    }
    if (document.getElementById('editComment')) {
      range.selectNodeContents(document.getElementById('editComment'));
    }

    range.collapse(false);
    // eslint-disable-next-line prefer-const
    selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
  }
  /**
   * Get User List in case of Scroll down
   */
  getOffSetUserList(event): void {
    if (event) {
      this.tagSuggestionListOffSet = event;
      if (this.editableContent && this.editableContent.nativeElement) {
        this.openTagUserSuggestionList();
      }
      if (this.editableCommentContent && this.editableCommentContent.nativeElement) {
        this.openTagUserSuggestionList();
      }
    }
  }
  /**
   * Close Tag Tutorial
   */
  closeTagTutorial(): void {
    localStorage.setItem('tagConnectionInformationCardFlag', 'true');
    this.isTagConnectionsEnabled = false;
  }


  /**
   * Reset Tag Box
   */
  resetTagBox(): void {
    if (document.getElementById('editComment')) {
      document.getElementById('editComment').innerHTML = '';
    }
    if (document.getElementById('replycmt' + this.cL.comment_id)) {
      document.getElementById('replycmt' + this.cL.comment_id).innerHTML = '';
    }

    this.searchWordForTag = '';
    this.isSuggestionListForTagEnabled = false;
    this.replyVal = '';
    this.searchWordForTag = '';

    this.isSuggestionListForTagEnabled = false;
    this.tagSuggestionList = [];
    this.tagSuggestionListOffSet = NUMBERS.ONE;
    this.tagUserSearchWord = '';
  }
  /**
   * unsubscribe the subscription.
   */
  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
    this.resetTagBox();

    // setting notification dato to null on destroy
    this.dss.notificationAdditionalData = null;
  }

  /**
   * Set auto comment.
   */
  setAutoComment(commentOption): void {
    if (this.showReply && this.cL.comment_id) {
      this.commentSectionText = document.getElementById(`replycmt${this.cL.comment_id}`);
      this.commentSectionText.textContent = commentOption.title;
      this.replyVal = this.commentSectionText.textContent;
    } else {
      this.emitAutoComment.emit(commentOption);
    }
    /**
     * Set analytics data for analytics event.
     */
    this.sendAnalytics(EVENT_NAME.AUTO_SUGGESTION_CLICK, PRODUCT_TYPE_CONSTANT.COMMENT, commentOption.id, this.callingfrom);
  }

  /**
   * Delete reply comment.
   */
  deleteReplyComment(): void {
    this.replyCommentCount.emit(NUMBERS.ZERO);
  }

  /**
   * Send analytics event.
   */
  sendAnalytics(eventName: EventDataKey, productType: number, productTypeId: number, screenName: string, startTime = this.getTimeString(), endTime = this.getTimeString()): void {
    this.analyticsData = {
      event_name: eventName,
      product_type: productType,
      product_type_id: productTypeId,
      screen_name: screenName,
      start_time: startTime,
      end_time: endTime
    };
    this.analyticsEventService.logAnalyticsEvent(this.analyticsData);
  }
  /**
   * Get the current time in string.
   */
  getTimeString(): string {
    return new Date().getTime().toString();
  }


  userInput(event): void {
    switch (event.inputType) {
      case 'insertText': {
        if (this.selectAll) {
          this.tagUserSearchWord = event.data || '';
          this.selectAll = false;
        } else {
          this.tagUserSearchWord = this.tagUserSearchWord + event.data;
        }
        break;
      }
      case 'deleteContentBackward': {
        if (this.selectAll) {
          this.tagUserSearchWord = event.data || '';
          this.selectAll = false;
        } else {
          this.tagUserSearchWord = this.tagUserSearchWord.substring(0, this.tagUserSearchWord.length - 1);
        }
        break;
      }
    }
    this.subject.next();
  }

  onKeyUp(event): void {

    if (event.ctrlKey && event.key === READ_KEY.A) {
      this.selectAll = true;
    }

    if (event.target.value?.trim() != '') {
      this.showColor = true;
    } else {
      this.showColor = false;
    }
  }

  onKeyDown(event): void {
    if (this.fcs.isSelectingAll(event)) {
      this.selectAll = true;
    }
  }



  openTagUserSuggestionList(): void {
    let finalText = '';
    this.replyVal = this.commentSectionText.textContent;
    if (this.actualComment.trim() !== this.commentSectionText.textContent.trim()) {
      this.tagSuggestionListOffSet = NUMBERS.ONE;
      this.tagSuggestionList = [];
    }
    this.actualComment = this.commentSectionText.textContent;
    if (this.tagUserSearchWord.includes(USER_TAG_REGX.tag_char)) {
      finalText = this.searchWordForTag = this.tagUserSearchWord.split('@')[1];

      this.tagSuggestionListfinished = false;
      finalText = finalText.replace(USER_TAG_REGX.search_char_regex, '');
      this.subscriptions.add(this.getSer.searchUserTagEntries(this.cL.product_type, this.cL.product_id, finalText, this.tagSuggestionListOffSet).subscribe(resData => {
        if (resData && resData.data && resData.status === NUMBERS.ONE) {
          if (this.tagSuggestionListOffSet > NUMBERS.ONE) {
            this.tagSuggestionList = this.tagSuggestionList.concat(resData.data.list);
          } else {
            this.tagSuggestionList = resData.data.list;
          }
          this.tagOffSet = resData.data.offset;
          this.isSuggestionListForTagEnabled = true;
          this.tagSuggestionListfinished = true;
        } else {
          this.resetTagSuggestion();
        }
      }));
    } else {
      this.isSuggestionListForTagEnabled = false;
    }
  }

}
