import { Component, Input, Output, ViewChild, EventEmitter, OnInit, OnDestroy, AfterViewInit} from '@angular/core';
import { OwlOptions, CarouselComponent } from 'ngx-owl-carousel-o';
import { PublicAnalyticsEventService } from '../public-analytics/public-analytics-event.service';
import { NewLanguageService } from '../../dataservices/new-language/new-language.service';

import { IPublicAnalyticsData, SCREEN_NAME as screenName  } from '../public-analytics/public-analytics.model';
import { EVENT_NAME } from '../public-analytics/public-analytics.constant';

@Component({
  selector: 'app-invite-user-welcome-screen',
  templateUrl: './invite-user-welcome-screen.component.html',
  styleUrls: ['./invite-user-welcome-screen.component.scss'],
})
export class InviteUserWelcomeScreenComponent implements OnInit, OnDestroy {

  /**
   * Receiving data from parent.
   */
  @Input() drName: string;
  @Input() welcomeMsg: string;
  @Input() greetingText: string;
  @Input() detailMsg: string;
  @Input() carousaldata;
  @Input() screenName;
  @Input() unique_id: string;
  @Input() pid: number;
  @Input() ptype: number;
  @Output() closeAction = new EventEmitter();

  @ViewChild('owlItem', { static: false }) owlItem: any;
  @ViewChild('owlCarousel') owlCarousel: CarouselComponent;

  constructor(private paes: PublicAnalyticsEventService, public mls: NewLanguageService) {

  }

  public customOptionsForCarousal: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: true,
    pullDrag: false,
    dots: true,
    center: false,
    navSpeed: 150,
    // navText: ['<i class="prev-slider position-absolute"></i>', '<i class="next-slider position-absolute"></i>'],
    responsive: {
      0: {
        items: 1,
        // navSpeed: 600
      },

      600: {
        items: 1
      },
      940: {
        items: 1,
        navSpeed: 600
      }
    },
    nav: false,
    margin: 0,
    // navigation: true,
    autoplay: true,
    autoHeight: true,
    autoWidth: true
  };

  loading = true;

  ngOnInit(): void {
    this.sendEntryEvent();
    setTimeout(() => {  window.dispatchEvent(new Event('resize')); this.loading = false }, 500)
  }

  ngOnDestroy(): void {
    this.sendExitEvent();
  }

  /**
   * send entry event
   */
  private sendEntryEvent(): void {
    const record: IPublicAnalyticsData = {
      product_type: this.ptype ? this.ptype : 0,
      product_id: this.pid ? this.pid : 0,
      screen_name: screenName.WELCOME_PAGE,
      event_name: '',
      eventName: EVENT_NAME.WELCOME_PAGE_ENTRY,
      extra: JSON.stringify({'backgroundScreen': this.screenName, unique_id: this.unique_id})
    };
    this.paes.sendAnalytics(record);
  }
  /**
   * sends exit event
   */
  private sendExitEvent(): void {
    const record: IPublicAnalyticsData = {
      product_type: 0,
      product_id: 0,
      screen_name: screenName.WELCOME_PAGE,
      event_name: '',
      eventName: EVENT_NAME.WELCOME_PAGE_EXIT
    };
    this.paes.sendAnalytics(record);
  }

  /**
   * sends close icon click event
   */
  private sendCloseEvent(): void {
    const record: IPublicAnalyticsData = {
      product_type: 0,
      product_id: 0,
      screen_name: screenName.WELCOME_PAGE,
      event_name: '',
      eventName: EVENT_NAME.WELCOME_CLOSE_CLICK
    };
    this.paes.sendAnalytics(record);
  }
  /**
   * TODO : sends verify CTA click event
   */
  private sendVerifyEvent(): void {
    const record: IPublicAnalyticsData = {
      product_type: 0,
      product_id: 0,
      screen_name: screenName.WELCOME_PAGE,
      event_name: '',
      eventName: EVENT_NAME.WELCOME_VERIFY_CLICK
    };
    this.paes.sendAnalytics(record);
  }
  /**
   * TODO : sends Escape CTA click event
   */
  private sendEscapeEvent(): void {
    const record: IPublicAnalyticsData = {
      product_type: 0,
      product_id: 0,
      screen_name: screenName.WELCOME_PAGE,
      event_name: '',
      eventName: EVENT_NAME.WELCOME_ESCAPE_CLICK
    };
    this.paes.sendAnalytics(record);
  }

  /**
   * popup close
   */
  closePopup(): void {
    this.sendCloseEvent();
    this.closeAction.emit(true);
  }


}
