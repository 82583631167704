/**
 * Angular imports.
 */
import { Injectable } from '@angular/core';

/**
 * Event Msg Service
 */
@Injectable({
  providedIn: 'root'
})

export class EventMsgService {
  public HeaderComponent: object = {
    notification_clicked: 'notification clicked',
    notification_list_clicked: 'notification list clicked',
    logo_clicked: 'logo clicked',
    profile_clicked: 'profile clicked',
    explore_clicked: 'explore clicked',
    timeline_clicked: 'timeline clicked',
    language_changed: 'language changed',
    log_out_clicked: 'log out clicked'
  };

  public BannerCardComponent: object = {
    banner_clicked: 'banner clicked'
  };

  public ProductCommentComponent: object = {
    edit_delete_cmt: 'edit delete comment option clicked',
    edit_cmt: 'edit comment clicked',
    delete_cmt: 'delete comment clicked',
    like_cmt: 'like comment clicked',
    cmt_like_list: 'comment like list clicked',
    edit_delete_reply_cmt: 'edit delete reply comment clicked',
    edit_reply_cmt: 'edit reply comment clicked',
    delete_reply_cmt: 'delete reply comment clicked',
    reply_cmt_like: 'reply comment like clicked',
    reply_cmt_load_more: 'reply comment load more clicked',
    cmt_load_more: 'comment load more clicked',
    update_cmt: 'update comment clicked'
  };

  public ProfileLeftComponent: object = {
    edit_profile_pic: 'edit profile pic',
    user_profile: 'user profile',
    referal_point_clicked: 'referal point clicked',
    cme_point_clicked: 'cme point clicked',
    referal_clicked: 'referal clicked'
  };

  public SearchingComponent: object = {
    search_list_clicked: 'search list clicked',
    search_all_clicked: 'search all clicked',
    recent_search_clicked: 'recent search clicked',
  };

  public SuggestionRightComponent: object = {
    trending_cme: 'trending cme course'
  };

  public ChannelDetailComponent: object = {
    subscribe_clicked: 'subscribe clicked',
    get_sample_clicked: 'get sample clicked'
  };

  public ChannelFilterComponent: object = {
    channel_filter_clicked: 'channel filter clicked',
    channel_filter_reset_clicked: 'channel filter reset clicked'
  };

  public NewChannelCardComponent: object = {
    channel_clicked: 'channel clicked',
    channel_subscribe_clicked: 'channel subscribe clicked'
  };

  public CmeCardComponent: object = {
    cme_clicked: 'cme clicked'
  };

  public CmeCertificateComponent: object = {
    gotohome: 'goto home',
    dowload_certificate: 'download certificate'
  };

  public CmeSubcategoryComponent: object = {
    add_cme_point: 'add cme point',
    add_vault: 'add vault',
    offline_tab: 'cme offline course',
    online_tab: 'cme online course',
    online_course_detail: 'cme course detail',
    offline_course_detail: 'offline course detail'
  };

  public CourseDetailComponent: object = {
    solve_course: 'solve course',
    get_certificate: 'get certificate'
  };

  public CourseListComponent: object = {
    category_clicked: 'category clicked',
    Take_course: 'Take course',
    Retake_course: 'Retake course',
    Facebook_share: 'facebook share clicked',
    twitter_share: 'twitter share clicked',
    linkedin_share: 'linkedin share clicked'
  };

  public CourseTestComponent: object = {
    cme_course_detail: 'course detail dksndkfjgerj',
    course_video: 'course video',
    course_image: 'course image',
    course_question: 'course question',
    question_image: 'question image',
    question_video: 'question video',
    answer_image: 'answer image',
    previous_question: 'previous question',
    next_question: 'next question',
    submit_course_test: 'submit course test',
    Retake_course: 'Retake course',
    goto_home: 'goto home'
  };

  public OfflineCourseDetailComponent: object = {
    del_offline_course: 'delete offline course',
    edit_offline_course: 'edit offline course',
    view_certificate: 'view certificate',
    update_vault: 'update vault',
    deleted_offline_course: 'deleted offline course',
    cancel_for_delete_course: 'cancel for delete course'
  };

  public PointListComponent: object = {
    add_cme_point: 'add cme point',
    cme_sub_category: 'cme sub category',
    add_vault: 'add vault'
  };

  public EventCardComponent: object = {
    event_clicked: 'event clicked'
  };

  public AboutComponent: object = {
    see_more_clicked: 'see more clicked',
    see_less_clicked: 'see less clicked'
  };

  public EventDetailComponent: object = {
    about_clicked: 'about clicked',
    discussion_clicked: 'discussion clicked'
  };

  public DetailLeftComponent: object = {
    event_not_interested_clicked: 'event not interested clicked',
    event_going_clicked: 'event going clicked',
    view_tnc_clicked: 'view T & C clicked',
    event_share_fb_clicked: 'event share facebook clicked',
    event_share_tw_clicked: 'event_sharetwitter clicked',
    event_share_li_clicked: 'event share linkedin clicked',
    event_back_button_clicked: 'event back button clicked'
  };

  public ListingCardComponent: object = {
    event_clicked: 'event clicked',
    event_not_interested_clicked: 'event not interested clicked',
    event_going_clicked: 'event going clicked'
  };

  public ListingLeftComponent: object = {
    upcoming_event_clicked: 'upcoming event clicked',
    going_event_clicked: 'going event clicked',
    attended_event_clicked: 'attended event clicked'
  };

  public ProgramCardComponent: object = {
    session_clicked: 'session clicked'
  };

  public JournalCardComponent: object = {
    journal_clicked: 'journal clicked',
    journal_bookmark_clicked: 'journal bookmark clicked'
  };

  public JournalDetailComponent: object = {
    journal_subscribe: 'journal subscribe abc',
    journal_like_list: 'journal like list',
    journal_like: 'journal like',
    journal_share: 'journal share',
    journal_bookmark: 'journal bookmark',
    journal_drug_sample: 'drug sample'
  };

  public JournalListComponent: object = {
    journal_detail: 'journal detail',
    journal_bookmark: 'journal bookmark'
  };

  public NewsCardComponent: object = {
    news_clicked: 'news clicked',
    news_like_list_clicked: 'news like list clicked',
    news_like_clicked: 'news like clicked',
    news_bookmark_clicked: 'news bookmark clicked',
    news_share_opt_clicked: 'news share option clicked',
    news_share_on_fb_clicked: 'news share on facebook clicked',
    news_share_on_tw_clicked: 'news share on twitter clicked',
    news_share_on_li_clicked: 'news share on linkedin clicked'
  };

  public NewsDetailComponent: object = {
    news_read_more: 'read more clicked',
    news_like_list_clicked: 'news like list clicked',
    news_pre_clicked: 'news previous clicked',
    news_next_clicked: 'news next clicked',
    news_bookmark_clicked: 'news bookmark clicked',
    news_like_clicked: 'news like clicked',
    news_share_clicked: 'news share clicked'
  };

  public NewsFilterComponent: object = {
    news_filter_clicked: 'news filter clicked',
    news_filter_reset_clicked: 'news filter reset clicked'
  };

  public NewReferralComponent: object = {
    how_it_works: 'how it works',
    share_clicked: 'share clicked',
    copy_link_clicked: 'copy link clicked',
    joined_people: 'joined people'
  };

  public FeedCardComponent: object = {
    feed_like: 'feed like',
    feed_bookmark: 'feed bookmark',
    feed_share: 'feed share',
    copy_link: 'copy link',
    like_list: 'like list',
    feed_media_clicked: 'feed media clicked',
    feed_poll_clicked: 'feed poll clicked',
    feed_edit_del_opt_clicked: 'feed edit delete option clicked',
    feed_edit_opt_clicked: 'feed edit opt clicked',
    feed_delete_opt_clicked: 'feed delete option clicked',
    case_follow_clicked: 'case follow clicked',
    case_unfollow_clicked: 'case unfollow clicked',
    user_detail_clicked: 'user detail clicked',
    feed_subscribed_clicked: 'feed subscribed clicked',
    feed_see_more_clicked: 'feed see more clicked',
    feed_see_less_clicked: 'feed see less clicked',
    feed_get_sample_clicked: 'feed get sample clicked',
    feed_speciality_filter_clicked: 'feed speciality filter clicked',
    feed_comment_clicked: 'feed comment clicked'
  };

  public PostingComponent: object = {
    feed_posting_clicked: 'feed posting clicked'
  };

  public ProfileDetailComponent: object = {
    about_clicked: 'about clicked',
    timeline_clicked: 'timeline clicked',
    activity_clicked: 'activity_clicked',
    media_clicked: 'media clicked',
    qa_clicked: 'Q&A clicked',
    feed_bookmard_clicked: 'feed bookmark clicked',
    journal_bookmard_clicked: 'journal bookmark clicked',
    news_bookmard_clicked: 'news bookmark clicked'
  };

  public UserDetailComponent: object = {
    edit_summary_clicked: 'edit summary',
    edit_email_clicked: 'edit email',
    add_email_clicked: 'add email',
    add_speciality_clicked: 'add speciality',
    edit_speciality_clicked: 'edit speciality',
    edit_profession_clicked: 'edit profession',
    delete_profession_clicked: 'delete profession',
    add_profession_clicked: 'add profession',
    edit_education_clicked: 'edit education',
    delete_education_clicked: 'delete education',
    add_education_clicked: 'add education',
    edit_publication_clicked: 'edit publication',
    delete_publication_clicked: 'delete publication',
    add_publication_clicked: 'add publication',
    edit_certificate_clicked: 'edit certificate',
    delete_certificate_clicked: 'delete certificate',
    add_certificate_clicked: 'add certificate'
  };
}
