/**
 * Angular import.
 */
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

/**
 * Environment import.
 */
import { environment } from "../../../environments/environment";
/**
 * Interface import.
 */
import {
  IPublicAnalytics,
  IPublicAnalyticsData,
} from "./public-analytics.model";
/**
 * rxjs imports
 */
import { BehaviorSubject } from "rxjs";
/**
 * constant import
 */
import { API } from "../../constants/api.constants";

@Injectable({
  providedIn: "root",
})
/**
 * Public Analytics service.
 */
export class PublicAnalyticsEventService {
  /**
   * Analytics screen name.
   */
  private readonly supportedScreenName = [
    "LOGIN_SCREEN",
    "PRODUCT_DETAIL_PAGE",
    "RESOURCE_LISTING",
    "PRODUCT_DASHBOARD",
    "WELCOME_PAGE",
    "ROLE_SELECTION_PAGE",
    "BUSINESS_QUERY_OTP",
    "BUSINESS_QUERY_FORM",
  ];

  private analyticsSubject$ = new BehaviorSubject<IPublicAnalytics>(null);
  public analytics$ = this.analyticsSubject$.asObservable();

  private analyticRecords: IPublicAnalytics[] = [];
  public maxRecordCount = environment.publicAnalyticMaxRecordCount;
  public isXhr = environment.isXhr;
  /**
   * Calling clevertap configuration.
   */
  constructor(private httpClient: HttpClient) {
    this.analytics$.subscribe((record) => {
      if(record) {
        this.analyticRecords.push(record);
        if (this.analyticRecords.length >= this.maxRecordCount) {
          if (!this.isXhr) {
            this.sendEvent([...this.analyticRecords]);
            this.analyticRecords = [];
          } else {
            this.xhrCall([...this.analyticRecords]);
            this.analyticRecords = [];
          }
        }
      }
    });
  }

  /**
   * Check if screen name is there or not.
   */
  checkScreenName(eventData): boolean {
    return this.supportedScreenName.indexOf(eventData.screen_name) > -1;
  }

  sendAnalytics(eventData: IPublicAnalyticsData): void {
    const record = this.setData(eventData);
    this.analyticsSubject$.next(record);
  }

  private setData(eventData: IPublicAnalyticsData): IPublicAnalytics {
    const timestamp = new Date().getTime();
    let data = {startTime: timestamp, endTime: timestamp};
    if(eventData.extra) {
      let extrasData = JSON.parse(eventData.extra);
      data = {...data, ...extrasData};
     }
    const eventdata: IPublicAnalytics = {
      fingerprint_id: this.getFingerPrintUUID(),
      browser_unique_id: this.getCustomUUID(),
      open_visitor_id: "",
      product_type: eventData.product_type || 0,
      product_id: eventData.product_id || 0,
      event_name: eventData.eventName.name,
      screen_name: eventData.screen_name,
      extra: JSON.stringify(data)
    };
    return eventdata;
  }

  private sendEvent(records: IPublicAnalytics[]): void {
    const body = { analytics: records };
    this.httpClient
      .post(`${environment.API_URLS.openContent}${API.OPEN_CONTENT_ANALYTICS}`, body, {
        headers: this.setHeaders(),
      })
      .subscribe((response: any) => {
        if (response.status === 1) {
        }
      });
  }

  private xhrCall(records: IPublicAnalytics[]): void {
    const body = { analytics: records };
    const endpoint = `${environment.API_URLS.openContent}${API.OPEN_CONTENT_ANALYTICS}`;

    const xhr = new XMLHttpRequest();
    xhr.onreadystatechange = () => {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        if (xhr.status === 201) {
          const responseData = JSON.parse(xhr.responseText);
          if(responseData.status === 1) {
          }
        } else {
          console.error("HTTP Request Error:", xhr.status, xhr.statusText);
        }
      }
    };
    xhr.open("POST", endpoint, true);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader("appversion", environment.versions.apiVer46);
    xhr.setRequestHeader("devicetype", environment.deviceType);
    xhr.setRequestHeader("lang", "en");
    xhr.setRequestHeader("udid", environment.udid);
    xhr.setRequestHeader("ver", environment.versions.apiVer46);
    xhr.setRequestHeader("country_id", "1");
    xhr.setRequestHeader("Authorization", environment.appKey);
    xhr.send(JSON.stringify(body));
  }

  private setHeaders(): HttpHeaders {
    const headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("appversion", `${environment.versions.appVersion}`)
      .set("devicetype", `${environment.deviceType}`)
      .set("lang", "en")
      .set("udid", environment.udid)
      .set("ver", `${environment.versions.apiVer46}`)
      .set("country_id", "1")
      .set("Authorization", `${environment.appKey}`);
    return headers;
  }

  addAnalyticRecord(record: IPublicAnalytics): void {
    this.analyticsSubject$.next(record);
  }

  private getFingerPrintUUID(): string {
    return this.getCookieData('browserFingerprintId');
  }

  private getCustomUUID(): string {
    return this.getCookieData('browserUniqueId');
  }

 private getCookieData(name): string {
    let pairs = document.cookie.split('; '),
      count = pairs.length, parts;
    while (count--) {
      parts = pairs[count].split('=');
      if (parts[0] === name)
        return parts[1];
    }
    return '';
  }
}
