import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { MasterserviceService } from '../../dataservices/master-service/masterservice.service';
import { Router } from '@angular/router';
// new lanuguage
import { NewLanguageService } from '../../dataservices/new-language/new-language.service';
@Component({
  selector: 'app-skip-onboarding-modal',
  templateUrl: './skip-onboarding-modal.component.html',
  styleUrls: ['./skip-onboarding-modal.component.scss']
})
export class SkipOnboardingModalComponent implements OnChanges {


  @Output() resetSkipConfirmModal = new EventEmitter();
  @Input() isSkipConfirmModalShown: boolean;
  @Input() confirmInfo: string;
  @Input() benefits;
  confirmAction = false;
  skipInProcess = false;

  constructor(private router: Router, public masterService: MasterserviceService, public mls: NewLanguageService) { }

  ngOnChanges(): void {
    // console.log(this.benefits);
    if (this.isSkipConfirmModalShown === true) {
      // No Code
    }
  }

  resetConfirmBox(): void {
    this.resetSkipConfirmModal.emit(this.confirmAction);
  }

  ActivateAction(): void {
    // this.isSkipConfirmModalShown = false;
    this.skipInProcess = true;
    this.resetSkipConfirmModal.emit(1);
  }


  hideModal(): void {
    this.isSkipConfirmModalShown = false;
    this.resetSkipConfirmModal.emit(0);
  }

  onHidden(): void {
    this.isSkipConfirmModalShown = false;
    this.resetSkipConfirmModal.emit(0);
  }

}
