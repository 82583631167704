import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { ECProductFeed, EcSlideCards } from '../../app/interface/editors-choice/editors-choice.model';
/**
 * Constant import.
 */
import { NUMBERS, REPOST_STATUS } from '../constants/app.constants';


/**
 * Clevertap sdk import.
 */
import clevertap from 'clevertap-web-sdk';


@Injectable({
  providedIn: 'root'
})
export class DataStorageService {
  public feedDetailBack = true;
  public feedDetailOther = false;
  public public_profile = [];
  public chat_user_public_profile = new Object();
  public cleverTapInstance =  clevertap;
  public awsInstance =  require('aws-sdk');
  pushNotification: boolean;
  speciality_list = [];
  posting_speciality_count = 2;
  user_association_list = [];
  suggAssList = [];
  selected_suggAssList = [];
  temp_post_media = [];
  cme_source_list = [];
  is_loaded_dialogs = false;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tempsearchstorage: any;
  removed_dialog = [];

  urlsection = '';
  urlsectionId = '';
  doctalktype = '';
  registration = '';
  pse_election_url = '';

  channelfilterlist = [];
  global_selected_filter = 0;
  global_filter_list = [];
  requiredFilters = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  webinarApplySpeciality: any;
  show_feed_post_modal = new Subject();
  story_creating_type: any;
  storyDuration: number;
  userType: string;
  storyProfileChannelId: number;
  create_Story = new Subject();
  create_Template_type :number;
  templateValue: string;
  create_story_productImage = new Subject<string>();
  create_Story_productData  = new Subject<any>();
  storyType: string;
  templateRepost: boolean;
  StoryFileType: string;
  storyRepostCode: string;
  storyRepostCta: any[] = [];
  storyVideoRpostThumbnail: string;

  viewStoryThroughProfileUuid: string;
  public templateList  = new Subject<any>();
  public selectedTemplate = new Subject<number>();
  public templatePostingCode: string;
  user_story_productType: any;
  user_story_productTypeId: any;
  user_story_fileType: string;
  story_reposting_fileCode =new Subject<string>();
  story_template_code = new Subject<string>();
  tag_user_list :any[] = [];

  public showStoryThroughProfile = new Subject<boolean>();

  public showStoryThroughNotification = new Subject<boolean>();

  public viewStoryThroughNotificationUuid: string;

  public viewListByDefault: boolean;

  public currentStoryCode: string;

  /**
   * user story nudges
   */
  public userStoryNudge = new Subject<boolean>();

  public storyNudgeIcon: string;
  public storyNudgeHeading: string;
  public storyNudgeDisc: string;
  public storyNudgeButton: string;

  public isCertificateShareEnabled: any;
  public isStoryLikeShareEnabled: any;
  public isWebinarShareEnabled: any;
  public isPostSuccessShareEnabled: any;
  public isStoryIntroductionEnabled : number;
  public isStoryTutorialEnabled =  new BehaviorSubject(0);

  public story_nudge_type: string;
  uuid: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  calling_from_community: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public clinical_key = new Subject<any>(); // clinical-resource
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public specialityID = new Subject<any>(); // clinical-resource
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public drug_filter = new Subject<any>(); // drugsample
  public series_video = new Subject();
  subscribeChannelCard = new Subject();
  subscribeFeedChannelCard = new Subject();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public connectionRouteChange = new Subject<any>();

  public profile_speciality = [];
  public profile_pic_updated_flag = new Subject<any>();
  public isTagUserTutorialEnabled = new BehaviorSubject(false);  //behaviour subject for tag info view flag
  public chatWindowState = new BehaviorSubject(0);  //behaviour subject for tag info view flag
  public canvas_image_edit_obj =
    {
      index: 0,
      base64Str: '',
      is_show: false,
      calling_from: ''
    };
  // chat list response
  public chatApiResponse = {
    dialog_list_response: 0,
    message_list_response: 0,
    login_response: 0,
    session_creation_response: 0
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public bookmarkRemove = new Subject<any>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public calendar_event_list = new Subject<any>();
  /**
   * Added new parameter calendar_events_list for calendar events
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public calendar_events_list = new Subject<any>();
  public cal_selected_day_month_year = { day: 0, month: 0, year: 0 };
  public online_user_list = [];
  public profileImageApi = false;
  // public isSkpModalShown=new Subject();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public subject_var_for_last_value: any;
  /**
   * Used for calendar list title
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public calenderTitle = new Subject<any>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  currentUpladingFile = new Subject<any>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public isSkpModalShown = new BehaviorSubject<any>('');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public onchange_calender_event_data = new Subject<any>();
  /**
   * when user click on next and previous month.
   */
  public calendarMonthDate: number;
  /**
   * Added new parameter onchange_calender_events_data on change calendar
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public onchange_calender_events_data = new Subject<any>();
  /**
   * Added new parameter onchange_events_data_type on change checked data types
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public onchange_events_data_type = new Subject<any>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public userConnStatusChanged = new Subject<any>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public feedDetailChanged = new Subject<any>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public feed_filter = new Subject<any>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public drugOrderFinished = new Subject<any>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public showFeedDetailInModal = new Subject<any>();
  public ecProductFeed: ECProductFeed;

  /**
   * Used to store data for another component for tag specialty.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public specialty_tag = new Subject<any>();

  /**
   * Used to store data for another component for tag specialty when redirect from another component.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public specialty_filter: any = '';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
  public currentGeolocation = new Subject<any>();
// eslint-disable-next-line @typescript-eslint/no-explicit-any
  public globalSerchKeyword = new Subject<any>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public need_to_reload_profile_api = new Subject<any>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public is_browser_tab_active_action = new Subject<any>();
  public gr_filter = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public VaultDataSource: any;
  // public qb_user_credential={
  //   login:'42f023b42c7056499c4f692625103fba', //71d2236f9c68768ffeea9d1920da2e94
  //   password:'rBxcl4Zsvw'                     // SCSFUaEXI9
  // }

  public qb_user_credential = {
    login: '',
    password: ''
  };
  dialog_permission = {
    has_blocked: false,
    is_blocked: false
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public chats: any = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  chat_dialog_updated = new Subject<any>();
  removeFromGroup = new EventEmitter();
  create_chat_dialog_type = 3;
  selected_chat_id_for_dialog_create = 0;
  public vaultData;
  public search_keyword = '';
  public search_term = 'term';
  public cmeSpec;
  public notificationCount = 0;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  is_on_user_profile = new Subject<any>();
  public retry_submit_feed_post = new EventEmitter();

  is_cme_vault_tab_active = false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  feed_detail_action = new Subject<any>();  // its for update feed card whenevent something updated on feed detail
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  poting_api_failed = new Subject<any>();
  viewDate: Date = new Date();
  post_process_obj = { file_url: '', status: '', is_show: false, progress_width: 0 };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  phoneNumber: any = '';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  countryCode: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  countryId: any;
  onlyrunone = true;
  // console.log(this.option.category+'===='+this.option.action+"========="+this.option.product_type+"============"+this.option.product_id);
  feed_post_in_process = { file_url: '', status: '', is_show: false };


  suggestion_keys = {
    SUGGESTION_KEY_EXPLORE: 'explore',
    SUGGESTION_KEY_FEED_DETAIL: 'feed_detail',
    SUGGESTION_KEY_VERIFICATION: 'verification',
    SUGGESTION_KEY_CONNECTION: 'connection',
    SUGGESTION_KEY_CME: 'cme',
    SUGGESTION_KEY_EVENT: 'event_detail',
    SUGGESTION_KEY_SESSION: 'session_detail',
    SUGGESTION_KEY_JOURNAL_DETAIL: 'journal_detail'
  };

  browser_not_support_version_list = {
    'Safari': ['12.0.1', '1.0.3', '1.3.2', '4.1.3', '5.0.6', '5.1.10', '6.1.6', '6.2.8', '9.1.3', '10.1.2', '', '11.1', '11.1.2', '12.1.2', '3.0.3', '4.0.3', '5.1.7'],
    'Chrome': [],
    'Firefox': [],
    'MS-Edge': [],
    'MS-Edge-Chromium': [],
    'ie': [],
    'Opera': []
  };

  browser_not_support_msg = '';
  posting_data = {
    screen1: {
      posted_by: '',
      title: '',
      content: '',
      tag_to: [],
      media: [],
      poll: [{ placeholder: 1, value: '' }, { placeholder: 2, value: '' }],
      is_anonymous: 0,
      feed_kind: 'cases',
      metaData: ''

    },
    screen2: {
      is_this_case: false,
      selected_speciality: []
    },
    screen3: {
      selected_association: [],
      selected_community: []
    },
    feed_edit_mode: { is_edit: 0, feed_id: 0, feed_index: -1 },
    creation_time: NUMBERS.ZERO,
    isRepost: REPOST_STATUS.ZERO,
    repostProductType: NUMBERS.ZERO,
    repostProductId: NUMBERS.ZERO
  };
  chat_init__first_msg = '';
  postDraftData = [];
  edit_draft_index = '';
  draft_index = -1;
  public inputBoxMaxLimit = 200;
  public inputEmailMaxLimit = 256;
  // public clinicalReskey='procedural_videos';
  public clinicalReskey = '';
  public clinicalLabel = '';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public CmedetailRight = new Subject<any>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public product_comment = new Subject<any>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public user_profile_data: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public master_api_data: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public reset_session_time_for_product = new Subject<any>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public connectionList_api_data = new Subject<any>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public connection_list_var: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public connection_Sentlist_var: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public sent_counter = new Subject<any>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public connection_list_counter = new Subject<any>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public edited_feed_obj = new Subject<any>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public background_api_document_flag: any;

  // PROFILE 2.0 variables
  /**
   * parameter whose nudge is to be rendered
   */
  profileNudgeParamter = new Subject<string>();

  /**
   * parameter whose nudge is to be rendered
   */
  UnlockLinkedProduct = new Subject<any>();

  /**
   * current screen name
   */
  public currentScreenName: string;
  /**
   * Used to hold the profile deletion flag value.
   */
  public profileDeleteFlag = false;
  public isChatShow = '0';
  public tooltip_list = [];
  public exploreNewTags = [];
  public backgroundCommunity: {
    activate_deactivate_access: string,
    default_privacy_type: string,
    community_max_invite: string
  };
  /**
   * Coomunity backgroung active data.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public communityBackgroundActive = new Subject<any>();

  // hold suport media format

  public cmeFilter = '';
  public drugFilter = '';
  public clinicalFilter = '';

  public tagedUser = []; // tag user posting

  allow_media_format =
    {
      'post_media': ['video/x-matroska', '', 'video/quicktime', 'video/mp4', 'image/jpg', 'image/jpeg', 'image/png', 'application/pdf', 'image/webp', 'video/m4v', 'video/mpeg', 'video/mpg', 'video/3gp', 'video/3gpp', 'video/mkv', 'video/mov'],
      'cme_media': [],
    };
  /**
   * Used to store if webinar video is playing or not.
   */
  public isVideoPlaying = false;
  /**
   * Used to store if audio is playing or not.
   */
  public isAudioPlaying = false;
  /**
   * Used to Store User Community List
  */
  public user_community_list = [];
  /**
   * Calling From Community Detail
  */
  public calling_from_community_object = {};
  /**
   * Calling From Community Detail for edit
   */
  public callingFromCommunityForEdit = NUMBERS.ZERO;

  /**
   * Dynamic docquity logo.
   */
  public dynamicAppLogo: number;
  /**
   * Cme certificate url.
   */
  public certificateUrl: string;
  /**
   * Repost status.
   */
  public isRepostStatus: number;
  /**
   * Repost content product type.
   */
  public repostProductType: number;
  /**
   * Repost content product id.
   */
  public repostProductId: number;
  /**
   * Repost content product id.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public webinarRepostObject: any;
  /**
   * Repost assoction screen hint text.
   */
  public repostAssociationHint: string;
  /**
   * Repost tooltip status.
   */
  public reposTooltip: number;
  /**
   * Store Notification Additional Data
   */
  public notificationAdditionalData;
  /**
   * Repost enable/disable key.
   */
  public isRepostEnable: number;

  /**
   * repost as story product type
   */
  public UserStoryProductType;
  /**
   * repost as story product type
   */
  public UserStoryProductTypeId;
  public tagUserAnalyticStatus = false;
  /**
   * check if user is posting a draft
   */
  public isDraftPost = NUMBERS.ZERO ;
  /**
   * For setting default repost type
   */
  public defaultRepost : string;
  /**
   * For type of repost
   */
  public repostType : string;
  /**
   *  Community Id
   */
 public communityExists: number;

 /**
  *  Next button flag for draft
  */
 public nextButtonEnabled = false;

  public tagUserAnalytics = {
    commentId: 0,
    screenName: ''
  };
  /**
   * Subject to track notification menu selection
   */
  public isNotificationMenuSelected$ = new BehaviorSubject<boolean>(false);

  /**
   * details of Survey which is child of a CME
   */
  public cmeModuleSurveyData;
  /**
   * flag to track if a survey is opened via listing or deeplink
   */
  public isSurveyFromListing = false;
  /**
   *
   */
  public isSpecialityOn: boolean;
  /**
   * is survey being retaken
   */
  public isSurveyRetake =false;
  /**
   * Subject to track webinar is played or not.
   */
  public isLiveWebinarStartedPlaying$ = new BehaviorSubject<boolean>(false);

  /**
   * Subject to track webinar is played or not.
   */
  public webinarPlayTime$ = new BehaviorSubject<number>(0);
  constructor() {
    this.setCalDayMonthYr();
  }


  resetPostData(): void {
    this.posting_data = {
      screen1: {
        posted_by: '',
        title: '',
        content: '',
        tag_to: [],
        media: [],
        poll: [{ placeholder: 1, value: '' }, { placeholder: 2, value: '' }],
        is_anonymous: 0,
        feed_kind: 'cases',
        metaData: ''
      },
      screen2: {
        is_this_case: false,
        selected_speciality: []
      },
      screen3: {
        selected_association: [],
        selected_community: []
      },
      feed_edit_mode: { is_edit: 0, feed_id: 0, feed_index: -1 },
      creation_time: NUMBERS.ZERO,
      isRepost: REPOST_STATUS.ZERO,
      repostProductType: NUMBERS.ZERO,
      repostProductId: NUMBERS.ZERO
    };
    this.temp_post_media = [];
    this.repostType = '';
  }



  // public getPosition(): Observable<Position> {
  //   return Observable.create(
  //     (observer) => {
  //     navigator.geolocation.watchPosition((pos: Position) => {
  //       observer.next(pos);
  //     }),
  //     () => {
  //         console.log('Position is not available');
  //     },
  //     {
  //       enableHighAccuracy: true
  //     };
  //   });
  // }

  removechannelfilter(): void {
    this.channelfilterlist = [];
  }

  setCalDayMonthYr(): void {
    const dt = new Date();
    const month = (dt.getMonth()) + 1;
    const year = dt.getFullYear();
    this.cal_selected_day_month_year.month = month;
    this.cal_selected_day_month_year.year = year;
    this.viewDate = new Date();
  }

  /**
   * Set EC requited details
   * @param feed for slide card
   */
  setECDetails(feed: EcSlideCards): void {
    this.ecProductFeed = null;
    if (feed.editor_choice_data && feed.editor_choice_data.is_ec_product && feed.editor_choice_data.headers) {
      this.ecProductFeed = {
        feedId: feed.feed_id.toString(),
        isEcProduct: feed.editor_choice_data.is_ec_product,
        ecHeaderText: feed.editor_choice_data.headers.highlight_text,
        color: feed.editor_choice_data.headers.color,
        icon: feed.editor_choice_data.headers.images.icon
      };
    }
  }
  /**
   * reset story data
   */
  resetStoryData(): void {
    this.story_reposting_fileCode.next(null);
    this.user_story_productType = null;
    this.user_story_productTypeId = null;
    this.create_Story_productData.next(null);
    this.create_story_productImage.next(null);
    this.create_Story.next(0);
  }

  /**
   * Reset repost data.
   */
  resetRepostData(): void {
    this.isRepostStatus = REPOST_STATUS.ZERO;
    this.webinarRepostObject = '';
    this.certificateUrl = '';
    this.tagedUser = [];
    this.posting_data.screen1.tag_to = [];
    this.posting_data.screen1.content = '';
  }


}

