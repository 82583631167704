/**
 * Angular imports.
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

/**
 * Component imports.
 */
import { ChatConfirmModalComponent } from './components/chat-confirm-modal/chat-confirm-modal.component';
import { ChatCreatorComponent } from './components/chat-creator/chat-creator.component';
import { ChatNoInfoComponent } from './components/chat-no-info/chat-no-info.component';
import { ChatPreviewComponent } from './components/chat-preview/chat-preview.component';
import { ChatProgressComponent } from './components/chat-progress/chat-progress.component';
import { FloatingButtonsComponent } from './components/floating-buttons/floating-buttons.component';
import { IndividualInfoBoxComponent } from './components/individual-info-box/individual-info-box.component';
import { MoreOptionsComponent } from './components/more-options/more-options.component';
import { ProfileInfoComponent } from './components/profile-info/profile-info.component';
import { SearchUsersComponent } from './components/search-users/search-users.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModalModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalModule } from 'ngx-bootstrap/modal';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { FileUploadModule } from '../file-upload/file-upload.module';
import { EmojiModule } from '../emojis/emojis.module';

/**
 * Pipe imports.
 */
import { LinebreaksPipe } from '../../pipes/linebreak.pipe';
import { LinkifyPipe } from '../../pipes/linkify.pipe';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

@NgModule({
  declarations: [
    ChatProgressComponent,
    ChatConfirmModalComponent,
    IndividualInfoBoxComponent,
    ProfileInfoComponent,
    MoreOptionsComponent,
    SearchUsersComponent,
    ChatNoInfoComponent,
    ChatPreviewComponent,
    ChatCreatorComponent,
    FloatingButtonsComponent,
    LinkifyPipe,
    LinebreaksPipe
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NgbModalModule,
    InfiniteScrollModule,
    FileUploadModule,
    EmojiModule,
    NgbModule,
    ModalModule,
    TooltipModule.forRoot()
  ],
  exports: [
    ChatProgressComponent,
    ChatConfirmModalComponent,
    IndividualInfoBoxComponent,
    ProfileInfoComponent,
    MoreOptionsComponent,
    SearchUsersComponent,
    ChatNoInfoComponent,
    ChatPreviewComponent,
    ChatCreatorComponent,
    FloatingButtonsComponent,
    LinkifyPipe,
    LinebreaksPipe
  ]
})
export class ShareModule { }
