<div class="modal fade" tabindex="-1" role="dialog" [config]="{ show: true, backdrop: 'static' }"
  bsModal #autoShownModal="bs-modal">
  <div class="custom-theme-modal modal-dialog modal-md modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="heading-3">
          {{title}}
        </h3>
        <svg (click)="hideModal.emit(false)" class="close-detail" version="1.1" width="10px" viewBox="0 0 191.414 191.414" enable-background="new 0 0 191.414 191.414">
          <g>
            <path d="M107.888,96.142l80.916-80.916c3.48-3.48,3.48-8.701,0-12.181s-8.701-3.48-12.181,0L95.707,83.961L14.791,3.045   c-3.48-3.48-8.701-3.48-12.181,0s-3.48,8.701,0,12.181l80.915,80.916L2.61,177.057c-3.48,3.48-3.48,8.701,0,12.181   c1.74,1.74,5.22,1.74,6.96,1.74s5.22,0,5.22-1.74l80.916-80.916l80.916,80.916c1.74,1.74,5.22,1.74,6.96,1.74   c1.74,0,5.22,0,5.22-1.74c3.48-3.48,3.48-8.701,0-12.181L107.888,96.142z"/>
          </g>
        </svg>
      </div>
      <div class="modal-body pt-0">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
