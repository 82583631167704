/**
 * File upload constants.
 */
export const FILE_UPLOAD = {
  LARGE_FILE_SIZE: 'File size is too large.Maximum 30MB is allowed',
  NOT_VALID_FORMAT: 'Only png, jpeg are allowed',
  ALLOW_ONLY: 'Allow only',
  FILES: 'files',
  CONTAIN_SPECIAL_CHARACTER: 'Filename should only contain alphanumeric characters. Please rename the file and upload it again'
};
