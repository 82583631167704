/**
 * Angular imports
 */
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';

/**
 * Rxjs imports
 */
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

/**
 * Environment imports
 */
import { environment } from '../../environments/environment';

/**
 * Service imports
 */
import { finalize } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';

/**
 * Interface imports.
 */
import {
  AcceptConsentData,
  AcceptRejectJoinRequestCommunityResponse,
  profileSubmitResponse,
  CMESubmitAnswerData, CommentPostData, EventGoingData,
  FeedPollRecordData, FeedPostData, GenerateCMECertificateData,
  GenericResponse, PharmaChannelSubscribeData, ProfilePublicData,
  UserDocumentSubmitData,
  UserInviteCodeData
} from '../onboarding/interface/apiResponse.model';
import { AcceptRejectJoinRequestCommunity, CardRendering, ConsentFormSubmit, CreateCommunity, Post, UpdateCommunity, UpdateCommunityMember } from '../onboarding/interface/global.model';

/**
 * Constant import
 */
import { API } from '../constants/api.constants';
// import { StoreSearchResponse } from '../interface/search/search.model';
import { DEFAULT_COUNTRY_CODE, DEFAULT_IP_ADDR, DEFAULT_LANG, DEVICE_TYPE, MODULE_NAME } from '../constants/app.constants';
import * as moment from 'moment';

/**
 * Post Api Service
 */
@Injectable({
  providedIn: 'root'
})
export class PostApiService implements OnDestroy {
  public token: string;
  public lang = 'en';
  public user_auth_key: string;

  /**
   * Check the status of post button.
   * Check the status of community button.
   */
  private isPostButtonDisable: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private isCommunityButtonDisable: BehaviorSubject<boolean> = new BehaviorSubject(false);
  /*
   * Check the status of consent form button.
   */
  private isConsentButtonDisable: BehaviorSubject<boolean> = new BehaviorSubject(false);

  /**
   * subject to end data stream
   */
  private notifier$ = new Subject();

  /**
   * Necessary instances.
   */
  constructor(private httpClient: HttpClient, private authService: AuthService) {
    this.token = this.authService.getToken();
    this.user_auth_key = this.token;

    if (localStorage.getItem('lang') != null && localStorage.getItem('lang') != 'null' && localStorage.getItem('lang') != undefined) {
      this.lang = localStorage.getItem('lang');
    }
  }

  /**
   * Generate LocalId,
   * which is the combination of TimeStamp UNIX Device Type and Four digit random number.
   */
  generateLocalId(): string {
    return `${this.getCurrentTimestamp()}${environment.deviceType}${this.generateRandomFourDigits(9000)}`;
  }
  /**
   * retrieves token details from local storage
   */
  getTokenDetailsFromStorage(): string|null {
    return JSON.parse(localStorage.getItem('mcTokenDetail'))?.at || null;
  }

  /**
   * Generate the random four digits.
   */
  generateRandomFourDigits(digits: number): number {
    return Math.floor(1000 + Math.random() * digits);
  }
  /**
   * Get Current Timestamp 13 digits.
   */
  getCurrentTimestamp(): number {
    return new Date().getTime();
  }

  /**
   * Get the Post Button flag as observable.
   */
  getPostButtonStatus(): Observable<boolean> {
    return this.isPostButtonDisable.asObservable();
  }

  /**
   * Used to set the Post Button flag status true/false.
   */
  setPostButtonStatus(status: boolean): void {
    this.isPostButtonDisable.next(status);
  }

  /**
   * Get the community Button flag as observable.
   */
  getCommunityButtonStatus(): Observable<boolean> {
    return this.isCommunityButtonDisable.asObservable();
  }

  /**
   * Used to set the community Button flag status true/false.
   */
  setCommunityButtonStatus(status: boolean): void {
    this.isCommunityButtonDisable.next(status);
  }
  /*
  * Get the Consent Button flag as observable.
  */
  getConsentButtonStatus(): Observable<boolean> {
    return this.isConsentButtonDisable.asObservable();
  }

  /**
   * Used to set the Consent Button flag status true/false.
   */
  setConsentButtonStatus(status: boolean): void {
    this.isConsentButtonDisable.next(status);
  }

  /**
   * assign Association
   */
  assignAssociation(type, type_ids, section_type, is_edit = 0): Observable<GenericResponse> {
    const body = {
      type: type,
      type_ids: type_ids,
      section_type: section_type,
      is_edit: is_edit
    };
    return this.httpClient.post<GenericResponse>(API.ASSIGN_ASSOCIATION, body);
  }

  /**
   * verification Submit Doc
   */
  verificationSubmitDoc(user_input, country_id): Observable<UserDocumentSubmitData> {
    user_input = JSON.stringify(user_input);
    const body = { user_input: user_input, country_id: country_id };

    return this.httpClient.post<UserDocumentSubmitData>(API.USER_DOCUMENT_SUBMIT, body);
  }

  /**
   * feed Post
   */
  feedPost(postData: Post): Observable<FeedPostData> {
    const body = postData;

    return this.httpClient.post<FeedPostData>(API.FEED_POST, body)
      .pipe(finalize(() => this.setPostButtonStatus(false)));
  }

  /**
   * feed Edit Post
   */
  feedEditPost(postData: Post): Observable<FeedPostData> {
    const body = postData;

    return this.httpClient.post<FeedPostData>(API.FEED_EDIT, body)
      .pipe(finalize(() => this.setPostButtonStatus(false)));
  }

  /**
   * delete Feed
   */
  deleteFeed(feed_id): Observable<GenericResponse> {
    const body = { feed_id: feed_id };

    return this.httpClient.post<GenericResponse>(API.FEED_DELETE, body);
  }

  /**
   * product Like
   */
  productLike(product_type, product_id, action): Observable<GenericResponse> {
    const body = { product_type_id: product_id, product_type: product_type, action: action };

    return this.httpClient.post<GenericResponse>(API.LIKE_LIKE_V2, body);
  }

  /**
   * product Bookmark
   */
  productBookmark(product_type, product_id, action): Observable<GenericResponse> {
    const body = { product_type_id: product_id, product_type: product_type, action: action };
    return this.httpClient.post<GenericResponse>(API.BOOKMARK_PRODUCT, body);
  }

  /**
   * feed Poll
   */
  feedPoll(feed_id, option_id): Observable<FeedPollRecordData> {
    const body = { feed_id: feed_id, option_id: option_id };

    return this.httpClient.post<FeedPollRecordData>(API.FEED_POLL_RECORD, body);
  }

  /**
   * product Comment
   */
  productComment(product_type, product_type_id, comment, file_json, action): Observable<CommentPostData> {
    const body = { product_type: product_type, product_type_id: product_type_id, comment: comment, file_json: file_json, action: action };

    return this.httpClient.post<CommentPostData>(API.COMMENT_POST, body);
  }

  /**
   * submit Cme
   */
  submitCme(lesson_id, qa_json): Observable<CMESubmitAnswerData> {
    qa_json = JSON.stringify(qa_json);
    const body = { lesson_id: lesson_id, qa_json: qa_json };

    return this.httpClient.post<CMESubmitAnswerData>(API.CME_SUBMIT_ANSWER_V2, body);
  }

  /**
   * generate Cme Certificate
   */
  generateCmeCertificate(lesson_id): Observable<GenerateCMECertificateData> {
    const body = { lesson_id: lesson_id };

    return this.httpClient.post<GenerateCMECertificateData>(API.CME_GENERATE_CERTIFICATE, body);
  }

  /**
   * add Offline Cme
   */
  addOfflineCme(id, action, title, points, file_url, source_id, issue_date, end_date): Observable<GenericResponse> {
    const body = {
      id: id,
      action: action,
      title: title, points:
        points, file_url: file_url,
      source_id: source_id,
      issue_date: issue_date,
      end_date: end_date,
      category_id: '0'
    };

    return this.httpClient.post<GenericResponse>(API.CME_OFFLINE_CME, body);

  }

  /**
   * media Steaming Log
   */
  mediaSteamingLog(param): Observable<GenericResponse> {
    const body = {
      product_type: param.ptype,
      product_type_id: param.pid,
      media_id: param.media_id,
      stop_time: param.stop_time,
      is_end: param.is_end
    };

    return this.httpClient.post<GenericResponse>(API.MEDIA_STREAMING_LOG, body);
  }

  /**
   * channel Subscribe
   */
  channelSubscribe(param): Observable<PharmaChannelSubscribeData> {
    const body = { safe_url: param, action: 'subscribe' };

    return this.httpClient.post<PharmaChannelSubscribeData>(API.PHARMA_CHANNEL_SUBSCRIBE, body);
  }

  /**
   * channel Unsubscribe
   */
  channelUnsubscribe(param): Observable<PharmaChannelSubscribeData> {
    const body = { safe_url: param, action: 'unsubscribe' };

    return this.httpClient.post<PharmaChannelSubscribeData>(API.PHARMA_CHANNEL_SUBSCRIBE, body);
  }

  /**
   * post EventGoing
   */
  postEventGoing(params): Observable<EventGoingData> {
    // tslint:disable-next-line: radix
    const body = { event_id: parseInt(params) };

    return this.httpClient.post<EventGoingData>(API.EVENT_GOING, body);
  }

  /**
   * post Placeorder
   */
  postPlaceorder(dataa): Observable<GenericResponse> {
    const body = {
      drug_id: dataa.drug_id,
      name: dataa.name,
      mobile: dataa.mobile,
      email: dataa.email,
      address: dataa.address,
      postcode: dataa.postcode,
      order_date: dataa.date,
      schedule_id: dataa.timeSlot
    };

    return this.httpClient.post<GenericResponse>(API.PHARMA_DRUG_SAMPLE_REQUEST_V2, body);
  }

  /**
   * profile Update
   */
  profileUpdate(param): Observable<GenericResponse> {
    const body = { profile_data: param };

    return this.httpClient.post<GenericResponse>(API.PROFILE_UPDATE, body);
  }

  /**
   * get QB Crediential
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getQBCrediential(): Observable<any> {
    const body = new HttpParams().set('lang', this.lang)
      .set('user_auth_key', this.token);
    return this.httpClient.post(API.CONNECT_WEB_SERVICE_SET, body, {
      params: { rquest: 'get_chat_credential' }
    });
  }

  /**
   * get Public Profile
   */
  getPublicProfile(chat_ids): Observable<ProfilePublicData> {
    chat_ids = '[' + chat_ids + ']';
    const body = { chat_ids: chat_ids };

    return this.httpClient.post<ProfilePublicData>(API.PUBLIC_PROFILE, body);
  }

  /**
   * post Send Feedback
   */
  postSenfFeedback(type, type_id, reason_id, description, uuid?): Observable<GenericResponse> {
    let body = {};
    if(uuid){
      body = { type: type, uuid: uuid, reason_id: reason_id, description: description };
    } else {
      body = { type: type, type_id: type_id?.toString(), reason_id: reason_id, description: description };
    }
    return this.httpClient.post<GenericResponse>(API.REASON_CREATE_REPORT, body);
  }

  /**
   * change Notification Status
   */
  changeNotificationStatus(notification_status): Observable<GenericResponse> {
    const body = { notification_status: notification_status.toString() };

    return this.httpClient.post<GenericResponse>(API.SET_NOTIFICATION, body);
  }

  /**
   * create Chat Dialog
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createChatDialog(action, dialog): Observable<any> {
    const body = new HttpParams().set('action', action)
      .set('dialogue', dialog);
    return this.httpClient.post(API.CHAT_DIALOGUE, body);
  }

  /**
   * edit Chat Dialog
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  editChatDialog(action, dialogue_id, group_name, group_pic, custom_ids, participant_type): Observable<any> {
    const body = new HttpParams().set('action', action)
      .set('dialogue_id', dialogue_id)
      .set('group_name', group_name)
      .set('group_pic', group_pic)
      .set('custom_ids', custom_ids)
      .set('participant_type', participant_type);
    return this.httpClient.post(API.CHAT_EDIT_DIALOGUE, body);
  }

  /**
   * mark Unread Count Zero
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  markUnreadCountZero(chat_dialog_id, QB_Token): Observable<any> {
    const header = { QB_Token: QB_Token };
    const data = { chat_dialog_id: chat_dialog_id, read: 1 };
    return this.httpClient.put(API.CHAT_MESSAGE + chat_dialog_id + '.json', data,
      {
        headers: header
      });
  }

  /**
   * upload file
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  upload_file(product_type, file_type, file_name, file): Observable<any> {
    const input = new FormData();
    input.append('file', file);
    input.append('product_type', product_type);
    input.append('file_type', 'image');
    input.append('file_name', file_name);

    return this.httpClient.post(API.FILE_UPLOAD, input);
  }

  /**
   * verify document
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  verify_document(document_details): Observable<any> {
    const body = new HttpParams().set('document_details', document_details);
    return this.httpClient.post(API.CONNECT_SERVICES_AUTHENCITY_SET, body,
      {
        params: { rquest: 'submit_document' }
      }
    );

  }

  /**
   * update User Document
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateUserDocument(doc_url, ic_number): Observable<any> {
    const body = new HttpParams().set('document_url', doc_url)
      .append('user_auth_key', this.token)
      .append('ic_number', ic_number)
      .append('app_version', environment.versions.appVersion)
      .append('version', '2.0');
    return this.httpClient.post(API.CONNECT_SERVICES_AUTHENCITY_SET, body,
      {
        params: { rquest: 'update_user_document' }
      }
    );
  }

  /**
   * event Register
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  eventRegister(name, mobile, alt_mobile, email, event_id): Observable<any> {
    const body = {
      'name': name,
      mobile: 'mobile',
      'alt_mobile': 'alt_mobile',
      'email': 'email',
      'event_id':
        event_id,
      'speciality_id': '0'
    };

    return this.httpClient.post(API.EVENT_REGISTER, body);
  }

  /**
   * case Follow
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  caseFollow(feed_id): Observable<any> {
    const body = { 'type_id': feed_id, 'type': '1' };

    return this.httpClient.post(API.CASE_FOLLOW, body);
  }

  /**
   * drug Order
   */
  drugOrder(chanel_id, drug_id, name, mobile, email, address, pincode, comment, order_date, schedule_id): Observable<GenericResponse> {
    const body = new HttpParams().set('chanel_id', chanel_id)
      .append('drug_id', drug_id)
      .append('name', name)
      .append('mobile', mobile)
      .append('email', email)
      .append('address', address)
      .append('pincode', pincode)
      .append('comment', comment)
      .append('order_date', order_date)
      .append('schedule_id', schedule_id);

    return this.httpClient.post<GenericResponse>(API.PHARMA_DRUG_SIMPLE_REQUEST, body);
  }

  /**
   * set Like
   */
  setLike(product_id, product_type): Observable<GenericResponse> {
    const body = new HttpParams()
      .set('product_type_id', product_id)
      .set('product_type', product_type)
      .set('action', 'like');
    return this.httpClient.post<GenericResponse>(API.LIKE_LIKE, body);
  }

  /**
   * submit Webinar Poll
   */
  submitWebinarPoll(data): Observable<GenericResponse> {
    const body = new HttpParams()
      .set('poll_id', data.pollId)
      .set('option_id', data.optionId)
      .set('product_type', data.productType)
      .set('product_type_id', data.productTypeId);
    return this.httpClient.post<GenericResponse>(API.WEBINAR_POLL_SUBMIT, body);
  }

  /**
   * submit Nps Review
   */
  submitNpsReview(data): Observable<GenericResponse> {
    return this.httpClient.post<GenericResponse>(API.PRODUCT_FEEDBACK, data);
  }

  /**
   * submit Missing Detail
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  submitMissingDetail(productType, productId, missingData): Observable<any> {
    const body = new HttpParams().set('product_id', productId)
      .append('missing_data', JSON.stringify(missingData))
      .append('product_type', productType);

    return this.httpClient.post(API.FORM_SAVE_MISSING_DATA, body);
  }

  /**
   * Save therapeutic data api.
   */
  submitTherapeuticData(therapeutic_ids): Observable<GenericResponse> {
    const body = new HttpParams()
      .set('therapeutic_ids', JSON.stringify(therapeutic_ids));

    return this.httpClient.post<GenericResponse>(API.E_DETAILING_PHARMA_THERAPEUTIC_SUBSCRIBE, body);
  }

  /**
   * I will do it later edetail api.
   */
  IWillDoItLaterEdetail(pharma_id): Observable<GenericResponse> {
    const body = new HttpParams()
      .set('ed_phrm_id', pharma_id);

    return this.httpClient.post<GenericResponse>(API.E_DETAILING_DO_LATER, body);
  }

  /**
   * Accept T&C api.
   */
  acceptTnc(therapeutic_ids, option_ids): Observable<GenericResponse> {
    const body = new HttpParams()
      .set('therapeutic_ids', JSON.stringify(therapeutic_ids))
      .append('option_ids', JSON.stringify(option_ids));

    return this.httpClient.post<GenericResponse>(API.E_DETAILING_PHARMA_TNC_OPTION_SUBSCRIBE, body);
  }

  /**
   * Submit user missing data.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  submitUserData(userData): Observable<any> {
    const body = new HttpParams()
      .set('profile_data', JSON.stringify(userData));

    return this.httpClient.post(API.E_DETAILING_PROFILE_MISSING_UPDATE, body);
  }

  /**
   * Accept updated terms and conditions
   */
  acceptTnCUpdate(): Observable<GenericResponse> {
    const body = new HttpParams().set('identifier_type', 'tncupdate');

    return this.httpClient.post<GenericResponse>(API.USER_TNC_UPDATE, body);
  }

  /**
   * Invite Code.
   */
  inviteCode(inviteCode: string, customId: string): Observable<UserInviteCodeData> {
    const body = new HttpParams().set('code', inviteCode);

    return this.httpClient.post<UserInviteCodeData>(API.USER_INVITE_CODE, body,
      {
        headers:
        {
          customid: customId,
        }
      }
    );
  }

  /**
   * Delete user api.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  deleteUser(profile_token, timestamp, delete_reason): Observable<any> {
    const body = new HttpParams()
      .set('profile_token', profile_token)
      .append('timestamp', timestamp)
      .append('delete_reason', delete_reason);
    return this.httpClient.post(API.USER_DELETION_DELETE_ACCOUNT, body);
  }

  /**
   * Store search data.
   */
  userConnection(action, requestee_custom_id, action_type): Observable<GenericResponse> {
    const body = {
      'action': action,
      'requestee_custom_id': requestee_custom_id,
      'action_type': action_type
    };
    return this.httpClient.post<GenericResponse>(API.CHAT_USER_CONNECTION, body);
  }

  /**
   * set bookmark
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setBookmark(product_id, product_type): Observable<any> {
    const body = { product_id: product_id, product_type: product_type };

    return this.httpClient.post(API.CONNECT_SERVICES_BOOKMARK_SET, body, {
      params: { rquest: 'bookmark' }
    });
  }

  /**
   * get app
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get_app(mobile, country_code): Observable<any> {
    const body = new HttpParams()
      .set('country_code', country_code)
      .set('mobile', mobile);

    return this.httpClient.post(API.WEB_GET_APP, body);
  }

  /**
   * Used to perform meeting cancel or meeting accept
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  meetingAction(meetingId, action, slotId, rescheduleId): Observable<any> {
    if (action === 'reject') {
      const body = { meetingId: meetingId, action: action };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return this.httpClient.post<any>(API.MR_MEETING_ACTION, body);
    } else {
      const body = { meetingId: meetingId, action: action, slotId: slotId, rescheduleId: rescheduleId };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return this.httpClient.post<any>(API.MR_MEETING_ACTION, body);
    }
  }

  /**
   * Used to reschedule a meeting
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  meetingReschedule(startTime, endTime, reasonId, comment, meetingId, timezone): Observable<any> {
    const body = {
      startTime: startTime,
      endTime: endTime,
      reasonId: reasonId,
      comment: comment,
      meetingId: meetingId,
      timezone: timezone
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.httpClient.post<any>(API.MR_MEETING_RESCHEDULE, body);
  }


  /**
   * Used to reschedule a meeting
   */
  consentBanner(consent, action): Observable<AcceptConsentData> {
    const body = { consent: consent, action: action };
    return this.httpClient.post<AcceptConsentData>(API.ACCEPT_CONSENT, body);
  }

  /**
   * Store search data.
   */
  storeCurrentSearch(keyword: string): Observable<GenericResponse> {
    // const endpoint = this.getMockData('search/store-terms.json');
    const body = new HttpParams().set('keyword', keyword);
    return this.httpClient.post<GenericResponse>(API.SEARCH_STORE_TERMS, body);
  }

  /**
   * In case if we need mock data, invoke this method.
   */
  getMockData(url: string): string {
    return '../../assets/mock/' + url;
  }

  /**
   * Store card rendering data.
   */
  sendCardRendering(card_records: CardRendering[]): Observable<GenericResponse | CardRendering[]> {
    const body = { card_records };
    return this.httpClient.post<GenericResponse | CardRendering[]>(API.CARD_RENDERING, body).pipe(
      map(response => response),
      catchError(() => {
        return of(card_records);
      })
    );
  }

  /**
   * Custom Card rendering api incase of window unload(reload, tab and browser close).
   */
  cardRenderingOnWindowUnLoad(card_records): void {
    this.customXhrApiCallThroughMagistrate(API.CARD_RENDERING, { card_records }, environment.versions.apiVer70);
  }

  /**
   * Custom media streaming log api incase of window unload(reload, tab and browser close).
   */
  callMediaStreamingLogOnWindowUnLoad(param): void {
    const body = {
      product_type: param.ptype,
      product_type_id: param.pid,
      media_id: param.media_id,
      stop_time: param.stop_time,
      is_end: param.is_end.toString()
    };
    const endpoint = API.MEDIA_STREAMING_LOG;
    this.customXhrApiCall(endpoint, body, environment.versions.api_version);
  }

  /**
   * Custom xhr call in case of httpClient not work.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  customXhrApiCall(endpoint: string, body: any, ver: string): void {
    const xhr = new XMLHttpRequest();
    xhr.open('POST', endpoint, false);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('Authorization', environment.appKey);
    xhr.setRequestHeader('appversion', environment.versions.appVersion);
    xhr.setRequestHeader('devicetype', environment.deviceType);
    xhr.setRequestHeader('lang', this.lang);
    xhr.setRequestHeader('udid', environment.udid);
    xhr.setRequestHeader('userauthkey', this.token);
    xhr.setRequestHeader('ver', ver);
    xhr.send(JSON.stringify(body));
  }
  customXhrApiCallThroughMagistrate(endpoint: string, body: any, apiversion?: string): void {
    const xhr = new XMLHttpRequest();
    endpoint = `${environment.API_URLS.dfs}${endpoint}`;
    xhr.open('POST', endpoint, false);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('Application-Version', environment.versions.appVersion);
    xhr.setRequestHeader('Country-Code', localStorage.getItem('country_code') || DEFAULT_COUNTRY_CODE);
    xhr.setRequestHeader('Timezone', moment.tz.guess());
    xhr.setRequestHeader('Device-Type', environment.deviceType);
    xhr.setRequestHeader('Ip-Address', localStorage.getItem('ipAddress') || DEFAULT_IP_ADDR);
    xhr.setRequestHeader('Allow-Origin', window.location.hostname);
    xhr.setRequestHeader('Module', MODULE_NAME.DQ);
    xhr.setRequestHeader('Request-Id', this.generateLocalId());
    xhr.setRequestHeader('Accept-Language', localStorage.getItem('lang') || DEFAULT_LANG);
    xhr.setRequestHeader('Authorization', `Bearer ${this.getTokenDetailsFromStorage()}`);
    if (apiversion) {
      xhr.setRequestHeader('Ver', apiversion);
    }
    xhr.send(JSON.stringify(body));
  }

  /**
   * Create community
   */
  createCommunity(body: CreateCommunity): Observable<GenericResponse> {
    return this.httpClient.post<GenericResponse>(API.COMMUNITY, body)
      .pipe(finalize(() => this.setCommunityButtonStatus(false)));
  }
  /**
   * Update community
  */
  updateCommunity(communityId, body: UpdateCommunity): Observable<GenericResponse> {
    const headers = {
      udid: environment.udid,
      ver: environment.versions.api_url_version_updated
    };
    const endpoint = `${API.COMMUNITY_DETAIL}`;
    return this.httpClient.put<GenericResponse>(endpoint + '/' + communityId, body, { headers }).pipe(finalize(() => this.setCommunityButtonStatus(false)));
  }
  /*
   * Consent Form Submit.
   */
  consentFormSubmit(body: ConsentFormSubmit[], product_type: string, product_type_id: string, consent: string, consent_type: string, page_type: string): Observable<GenericResponse> {
    const formData = { formData: body };
    const params = {
      product_type,
      product_type_id,
      consent,
      consent_type,
      page_type
    };
    const headers = {
      udid: environment.udid,
      ver: environment.versions.apiVer44
    };
    return this.httpClient.post<GenericResponse>(API.WEBINAR_NOTIFY_ME, formData, { headers, params })
      .pipe(finalize(() => this.setConsentButtonStatus(false)));
  }

  /**
   * Update game play count.
   */
  updatePlayCount(ncp_subtype_id: number): Observable<GenericResponse> {
    const body = { ncp_subtype_id };
    return this.httpClient.post<GenericResponse>(API.GAME_PLAY_COUNT, body).pipe(
      map(response => response),
      catchError((error) => {
        return of(error);
      })
    );
  }
  /**
   * Update Community Members
   */
  updateCommunityMember(communityId, body: UpdateCommunityMember): Observable<GenericResponse> {
    const headers = {
      udid: environment.udid,
      ver: environment.versions.api_url_version_updated
    };
    const endpoint = `${API.COMMUNITY_DETAIL}`;
    return this.httpClient.post<GenericResponse>(`${endpoint}/${communityId}/addRemoveMember`, body, { headers }).pipe(finalize(() => this.setCommunityButtonStatus(false)));
  }
  /**
   * Join public community
   */
  joinPublicCommunity(communityId: string, action?: string): Observable<GenericResponse> {
    const headers = {
      udid: environment.udid,
      ver: environment.versions.api_url_version_updated
    };
    let bodyAction = null;
    if (action && action !== '') {
      bodyAction = { action: action };
    }
    const endpoint = `${API.COMMUNITY_DETAIL}`;
    return this.httpClient.post<GenericResponse>(`${endpoint}/${communityId}/join`, bodyAction, { headers })
      .pipe(
        map(response => response),
        catchError((error) => {
          return of(error);
        })
      );
  }

  /**
   * Exit Community Members
   */
  exitCommunityMember(communityId): Observable<GenericResponse> {
    const headers = {
      udid: environment.udid,
      ver: environment.versions.api_url_version_updated
    };
    const endpoint = `${API.COMMUNITY_DETAIL}`;
    return this.httpClient.post<GenericResponse>(`${endpoint}/${communityId}/exit`, '', { headers }).pipe(finalize(() => this.setCommunityButtonStatus(false)));
  }

  /**
   * Activate/Deactivate Community Members
   */
  activateInactivateCommunity(communityId, state): Observable<GenericResponse> {
    const headers = {
      udid: environment.udid,
      ver: environment.versions.api_url_version_updated
    };
    const endpoint = `${API.COMMUNITY_DETAIL}`;
    return this.httpClient.put<GenericResponse>(`${endpoint}/${communityId}/state/${state}`, '', { headers }).pipe(finalize(() => this.setCommunityButtonStatus(false)));
  }

  /**
  * Accept Reject Join Request From Community
  */
  acceptRejectJoinRequestFromCommunity(communityId: string, body: AcceptRejectJoinRequestCommunity): Observable<AcceptRejectJoinRequestCommunityResponse> {
    const headers = {
      udid: environment.udid,
      ver: environment.versions.api_url_version_updated
    };
    const endpoint = `${API.COMMUNITY_DETAIL}`;
    return this.httpClient.put<AcceptRejectJoinRequestCommunityResponse>(`${endpoint}/${communityId}/join`, body, { headers }).pipe(
      map(response => response),
      catchError((error) => {
        return of(error);
      })
    );
  }

  /**
   * Delete feed in the community
   */
  deleteCommunityPost(params: { communityId: string, productType: string, postId: string }): Observable<GenericResponse> {
    if (params && params.communityId && params.productType && params.postId) {
      const headers = {
        udid: environment.udid,
        ver: environment.versions.api_url_version_updated
      };
      const url = `${API.COMMUNITY}`;
      const endpoint = `${url}/${params.communityId}/${params.productType}/${params.postId}`;
      return this.httpClient.delete<GenericResponse>(endpoint, { headers }).
        pipe(
          map(response => response),
          catchError(error => of(error))
        );
    } else {
      return of(null);
    }
  }

  /**
   * Submit live Poll.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  submitLivePoll(pollData): Observable<any> {
    const body = pollData;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.httpClient.post<any>(API.LIVE_POLL_SUBMIT, body).pipe(
      map(response => response),
      catchError((error) => {
        return of(error);
      })
    );
  }
  /**
   * log tutorial viewed api.
   */
  logTutorialView(tutorial_type: string): Observable<GenericResponse> {
    const body = { tutorial_type };
    return this.httpClient.post<GenericResponse>(API.LOG_INFO_CARD_CROSS, body).pipe(
      map(response => response),
      catchError((error) => {
        return of(error);
      })
    );
    }
  /**
   * For pin and unpinning post
   */
    pinUnpinPost(communityId, feed_id, pin_status, product_type): Observable<GenericResponse> {
      const body = {
        'product_id': feed_id,
        'pin_status': pin_status,
        'product_type': product_type
      };
      const headers = {
        udid: environment.udid,
        ver: environment.versions.api_url_version_updated
      };
      const endpoint = `${API.COMMUNITY}`;
      return this.httpClient.post<GenericResponse>(`${endpoint}/${communityId}/pinUnpinProduct`, body , {headers}).pipe(
        map(response => response),
        catchError((error) => {
          return of(error);
        })
      );
    }
   /**
    * Data stored for search
    */
    searchCategoryFound(search_keyword:string, product_type ): Observable<GenericResponse> {
      const body = {
        'search_keyword': search_keyword,
        'product_type': product_type
      };
      const headers = {
        udid: environment.udid,
        ver: environment.versions.api_url_version_updated
      };
      const endpoint = `${API.SAVE_SEARCH_RECORD}`;
      return this.httpClient.post<GenericResponse>(endpoint, body , {headers}).pipe(
        map(response => response),
        catchError((error) => {
          return of(error);
        })
      );
    }
    /**
     * create user story
     */
    createUserStory(version = '1', createStoryData: any): Observable<any> {
      const params = {version};
      return this.httpClient.post<any>(API.CREATE_USER_STORY, createStoryData, {
        params: params
      }).pipe(
        map(res => res),
        catchError((error) => {
          return of(error);
        })
      );
    }
    /**
     * delete user story
     */
    deleteUserStory(storyCode: string): Observable<any> {
      const endpoint =  API.USER_STORY_CAROUSEL_LIST + '/' + storyCode;
      return this.httpClient.delete<any>(endpoint).pipe(
        map(response => response),
        catchError((error) => {
          return of(error);
        })
      );
    }
    /**
     * log story view api
     */
    logStoryView(storyCode: string): Observable<any> {
      const body = {storyCodes : [storyCode]};
      return this.httpClient.post<any>(API.VIEW_STORY_LOG, body).pipe(
        map(response => response),
        catchError((error) => {
          return of(error);
        })
      );
    }
    /**
     * log story view api
     */
    logStoryReaction(storyCode: string, value: string): Observable<any> {
      const body = {code : value};
      const endpoint = API.LOG_STORY_REACTION + '/' + storyCode;
      return this.httpClient.post<any>(endpoint, body).pipe(
        map(response => response),
        catchError((error) => {
          return of(error);
        })
      );
    }

    /**
   * Send logout API request
   * @returns
   */
  sendLogoutRequest(): Observable<GenericResponse> {
    return this.httpClient.post<GenericResponse>(API.USER_LOGOUT,{});
  }
  /**
   * Remove subscription or eventlistener
   */
  ngOnDestroy(): void {
    this.notifier$.next();
    this.notifier$.complete();
  }



  /**
   * log tutorial viewed api.
   */
  registerDevice(device_token: string): Observable<GenericResponse> {
    const body = { device_token };
    const headers = {
      udid: environment.udid,
      ver: environment.versions.api_url_version_updated,
      devicetype: DEVICE_TYPE.WEB_TEXT
    };

    return this.httpClient.post<GenericResponse>(API.DEVICE_TOKEN_GENERATE, body, { headers }).pipe(
      map(response => response),
      catchError((error) => {
        return of(error);
      })
    );
  }
  /**
   * Logout User
   */
  logoutUser(): Observable<GenericResponse> {
    const body = {};
    const headers = {
      udid: environment.udid,
      ver: environment.versions.api_url_version_updated,
      devicetype: DEVICE_TYPE.WEB_TEXT
    };

    return this.httpClient.post<GenericResponse>(environment.API_URLS.profile+'/4.3/user/logout', body, { headers }).pipe(
      map(response => response),
      catchError((error) => {
        return of(error);
      })
    );
  }

  /**
    *
    * to close pvs
    */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  closePVS(pvsId): Observable<any> {
    const headers = {
      udid: environment.udid,
      ver: environment.versions.api_url_version_updated
    };
    const body = {};
    const endpoint = `${environment.API_URLS.pvs}/${environment.versions.api_url_version_updated}${API.PROMO_VIEW_STRIP_CLOSE}` + '/' + pvsId;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.httpClient.put<any>(endpoint, body, {
      headers
    }).pipe(
      map(response => response),
      catchError((error) => {
        return of(error);
      })
    );
  }
  /**
   * submit data for profile nudges
   * @param body 
   * @param action 
   * @returns 
   */
  submitProfilData(body, action): Observable<profileSubmitResponse> {
    const endPoint  = API.SUBMIT_PROFIL_DATA + action;
    return this.httpClient.post<profileSubmitResponse>(endPoint, body, {
      observe: 'response'
    }).pipe(map(response => {return response}
    ),
      catchError((error) => {
        return of(error);
      }));
  }
  /**
   * Record Browser Details
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  recordBrowserDetails(browserFingerprintId, browserUniqueId, trackId, ipAddress, browserInfo): Observable<any> {
    const url = environment.API_URLS.others + '/' + environment.versions.api_url_version_updated + '/open-content/record-browser-details';
    const headers = {
      Authorization: environment.appKey,
      appversion: environment.versions.appVersion,
      devicetype: environment.deviceType,
      lang: this.lang,
      udid: '',
      'Content-Type': 'application/json',
    };
    const data = {
      browserFingerprintId,
      browserUniqueId,
      trackId,
      ipAddress,
      browserInfo
    };
    const body = JSON.stringify(data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.httpClient.post<any>(url, body,
      {
        headers: headers
      });
  }

}
