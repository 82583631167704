import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { DataStorageService } from '../../dataservices/data-storage.service';
import { NewLanguageService } from '../../dataservices/new-language/new-language.service';

@Component({
  selector: 'app-multiselect-ddl',
  templateUrl: './multiselect-ddl.component.html',
  styleUrls: ['./multiselect-ddl.component.scss']
})
export class MultiselectDdlComponent implements OnInit, OnChanges {

  @Output() resetDDLData = new EventEmitter();
  @Input() callingfrom;
  @Input() DDLData;
  @Input() hasSelected;
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  is_disabled = false;

  constructor(private dss: DataStorageService, public mls: NewLanguageService) {
    // if(this.dss.user_profile_data!=undefined)
    // {
    //   this.is_disabled=(this.dss.user_profile_data.profile.permission=='all')? true:false;
    // }
  }


  ngOnInit(): void {
    // console.log(this.DDLData)
    this.dropdownList = this.DDLData.list_data;
    this.dropdownSettings = {
      // singleSelection: this.DDLData.single_select,
      // text:this.DDLData.placeholderL,
      // selectAllText:'Select All',
      // unSelectAllText:'UnSelect All',
      // enableSearchFilter: this.DDLData.enable_search,
      // showCheckbox: this.DDLData.showCheckbox,
      // enableCheckAll  : this.DDLData.enableCheckAll,
      // enableFilterSelectAll: this.DDLData.enableFilterSelectAll,
      // disabled:this.DDLData.disabled,
      // classes: "myclass custom-class-example",
      // noDataLabel:"No result found"

      searchPlaceholderText: this.mls.languageText.multiSelectDropDown.key1,
      text: '',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: 'myclass custom-class-example',
      showCheckbox: false,
      singleSelection: true,
      badgeShowLimit: 1,
      enableCheckAll: true,
      searchAutofocus: true,
      enableFilterSelectAll: true,
      disabled: false// this.DDLData.disabled,

      //  searchBy: ['country','country_code','country+country_code'],

    };
    // console.log(this.dropdownSettings);

    this.selectedItems = (this.DDLData.selected_data);
    // console.log(this.selectedItems);
  }

  ngOnChanges(): void {

    this.selectedItems = [];
    this.dropdownList = this.DDLData.list_data;
    this.selectedItems = this.DDLData.list_data[0];
    // console.log(this.selectedItems);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onItemSelect(item: any): void {
    this.selectedItems = [];
    this.selectedItems.push(item);
    const x = { item: this.selectedItems, identifier: this.DDLData.identifier };
    this.resetDDLData.emit(x);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  OnItemDeSelect(item: any): void {
    // console.log(item)
    this.selectedItems = [];
    this.selectedItems.push(item);
    // let x={item:[], identifier:this.DDLData.identifier}
    const x = { item: this.selectedItems, identifier: this.DDLData.identifier };
    this.resetDDLData.emit(x);
  }

  onSelectAll(): void {
    // console.log(items);
  }

  onDeSelectAll(): void {
    // console.log(items);
  }


}
