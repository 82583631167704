/**
 * Angular imports.
 */
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

/**
 * Service imports.
 */
import { GetService } from '../../dataservices/get.service';
import { DqChatPanelService } from '../dq-chat-panel.service';
import { MasterserviceService } from 'src/app/dataservices/master-service/masterservice.service';
import { PostApiService } from 'src/app/dataservices/post-api.service';
import { FunCollectionService } from 'src/app/common/common-functions/fun-collection.service';
import { NewLanguageService } from 'src/app/dataservices/new-language/new-language.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AnalyticsEventService } from 'src/app/analytics-event/analytics-event.service';

/**
 * Rxjs imports.
 */
import { Subscription } from 'rxjs';

/**
 * Class imports.
 */
import { SamvaadLanguages } from '../samvaad.language';
import { SamvaadConfig } from '../../onboarding/interface/global.model';

/**
 * Constant imports.
 */
import { PIP_CLASS, PRODUCT_TYPE_CONSTANT, SCREEN_NAME } from './../../constants/app.constants';
import { EVENT_NAME } from './../../analytics-event/analytics.constant';
import { AnalyticsInterface, EventDataKey } from 'src/app/analytics-event/analytics.model';

/**
 * Chat panel for alway on when user login contains the chat panel.
 */
@Component({
  selector: 'app-dq-chat-panel',
  templateUrl: './dq-chat-panel.component.html',
  styleUrls: ['./dq-chat-panel.component.scss']
})
export class DqChatPanelComponent implements OnInit, OnDestroy {

  @ViewChild('libChat') libChat;

  /**
   * Samvaad token for the chat panel login.
   * Close chat in case of no samvaad token.
   * Is report to show the reporting.
   * Is chat detail expanded/Minimize flag.
   */
  public samToken: string;
  public authToken: string;
  public closeChat = false;
  public isReport = false;
  public isChatListExpanded = false;
  public isChatListHide = false;
  public reportProductId = '0';
  public reportProductType: number;
  public permission = '';
  public samvaadLanguageText = new SamvaadLanguages(this.mls)?.languageText;
  public productType = PRODUCT_TYPE_CONSTANT;

  /**
   * To unsubscribe data.
   */
  private subscriptions: Subscription = new Subscription();

  public displayObj = {
    isShow: false,
    display_section: '',
    data: [],
    errObj: []
  };
  public samvaadConfig: SamvaadConfig;
  public chatInfo;

  /**
   * Analyticsdata object.
   */
  private analyticsData: AnalyticsInterface;
  private productTypeId = 0;
  private chatListStartTime: Date;

  /**
   * Necessary instances created.
   */
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private getService: GetService,
    public dqChatPanelService: DqChatPanelService,
    public masterService: MasterserviceService,
    public deviceService: DeviceDetectorService,
    private postSer: PostApiService,
    private fcs: FunCollectionService,
    public mls: NewLanguageService,
    private analyticsEventService: AnalyticsEventService) {
  }

  /**
   * Chat initiate api call.
   * Flag for the expand chat.
   */
  ngOnInit(): void {
    this.subscriptions.add(this.masterService.getProfile()
      .subscribe(value => {
        if (value.code === 2000 && value.status === 1) {
          this.permission = value.data.profile.permission;
          this.closeChat = value.data.chat_allow ? false : true;
          if (this.closeChat) {
            this.samvaadConfig = undefined;
          } else {
            if (this.masterService?.masterApiData?.code === 2000) {
              this.chatInfo = this.masterService?.masterApiData?.data.page_info?.chat;
              if (this.chatInfo) {
                this.samvaadConfig = {
                  appName: this.chatInfo.samvaad.app_name,
                  host: this.chatInfo.samvaad.host,
                  apiKey: this.chatInfo.samvaad.session_key,
                  transport: this.chatInfo.samvaad.transport,
                  secure: this.chatInfo.samvaad.secure ? true : false,
                  platform: 'web',
                  persist: this.chatInfo.samvaad.persist ? true : false
                };
              } else {
                this.samvaadConfig = undefined;
              }
            }
          }
        }
      }));
    this.getOtherUserChatId();
    this.subscriptions.add(this.dqChatPanelService.isChatListHide
      .subscribe(value => {
        this.isChatListHide = value;
        this.closeChat = false;
      }));
    if (!this.closeChat) {
      this.getSamToken();
    }
  }

  /**
   * Get the sam token.
   */
  getSamToken(): void {
    const auth = JSON.parse(this.getSamTokenFromLocalStorage());
    if (!auth) {
      this.requestSamToken();
    } else {
      this.checkExpiryTime(auth);
    }
    this.subscriptions.add(this.dqChatPanelService.expandChat
      .subscribe(value => {
        if (value) {
          if (this.isChatListExpanded) {
            this.onChatListVisit(new Date());
          }
          this.isChatListExpanded = false;
        }
      }));
  }

  /**
   * Start the user chat from the website chat icon.
   */
  getOtherUserChatId(): void {
    this.fcs.chatUserId
      .subscribe(detail => {
        if (detail.isNotify) {
          this.openChatDetailByTopicId(detail.uuid);
          return;
        }
        if (detail.uuid) {
          this.libChat.startNewChat({ uuid: detail.uuid });
        }
      });
  }

  /**
   * Check the expiry time for the token.
   */
  checkExpiryTime(auth): void {
    const currentTime = new Date().getTime();
    const expiryTime = new Date(auth?.params?.expires).getTime();
    if (currentTime > (expiryTime - 100000)) {
      this.requestSamToken();
    } else {
      this.authToken = auth?.params?.token;
    }
  }

  /**
   * Request sam token in case of no auth available.
   */
  requestSamToken(): void {
    if(this.mls.featureOnOffList.featureList.chat.value || !this.mls.userLoginFlow) {
    if (this.permission === 'all') {
      this.subscriptions.add(this.getService.chatInitiate()
        .subscribe(res => {
          if (res.data && res.data.samToken) {
            this.samToken = res.data.samToken;
          } else {
            this.closeChat = true;
          }
        }));
    }}
  }

  /**
   * Get the samvaad auth from the local storage.
   */
  getSamTokenFromLocalStorage(): string {
    return localStorage.getItem('samvaadAuthData');
  }

  /**
   * Navigate to the samvaad detail.
   */
  goToSamvaadDetail(): void {
    this.onChatExpandButtonClick();
    this.router.navigate(['/samvaad/list']);
  }

  /**
   * Hide the report modal.
   */
  onReportHidden(): void {
    this.isReport = false;
  }

  /**
   * Navigate to the user profile.
   */
  redirectToProfile(uuid): void {
    this.router.navigate(['/webprofile/view/info/', uuid], { queryParams: { uuid: true } });
  }

  /**
   * Get the chat list expand flag.
   */
  onChatListExpanded(status: boolean): void {
    this.isChatListExpanded = status;
    if (this.isChatListExpanded) {
      this.onChatListLeave(new Date());
    } else {
      this.onChatListVisit(new Date());
    }
  }

  /**
   * Get the chat list hide flag.
   */
  OnChatListHide(status: boolean): void {
    this.isChatListHide = status;
  }

  /**
   * Get the unread count.
   */
  onUnreadCount(count: number): void {
    this.dqChatPanelService.unreadNotification.next(count);
  }

  /**
   * Get the reported user/group.
   */
  onReportUser(info: { id: string, type: number }): void {
    this.reportProductId = info.id;
    this.reportProductType = info.type ? PRODUCT_TYPE_CONSTANT.CHAT : PRODUCT_TYPE_CONSTANT.USER;
    this.isReport = true;
  }

  /**
   * Check if user is verified or not.
   */
  checkVerification(): void {
    this.subscriptions.add(this.postSer.assignAssociation('association', '[]', 'open_chat', 0)
      .subscribe(resData => {
        if (resData.code === 2000 && resData.status === 1) {
          this.displayObj.isShow = true;
          this.displayObj.display_section = 'verificationSuccess';
          this.displayObj.data = resData.data;
          this.displayObj.errObj = [];
          this.masterService.showWebModal.next(this.displayObj);
        } else {
          this.fcs.errorHandeler(resData, 'generate_certificate');
          return;
        }
      }));
  }

  /**
   * Set the close chat in case of failure.
   */
  setCloseChat(status: boolean): void {
    if (!status) {
      const queryParams = this.route?.snapshot?.queryParams;
      const topicId = queryParams ? queryParams['id'] : '';
      if (topicId) {
        this.openChatDetailByTopicId(topicId);
        this.router.navigateByUrl('/samvaad/list');
      }
    }
    this.closeChat = status;
  }

  /**
   * Open the chat detail using the topic id.
   */
  openChatDetailByTopicId(topicId: string): void {
    this.libChat.getTopicDetail({ topicId: topicId });
  }

  /**
   * Send chat detail visit analytic.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChatDetailVisit(event: Date): void {
    document.body?.classList?.add(PIP_CLASS);
    this.sendAnalytics(EVENT_NAME.CHAT_DETAIL_ENTRY, PRODUCT_TYPE_CONSTANT.CHAT, this.productTypeId, this.deviceService.isMobile() ? SCREEN_NAME.CHAT_DETAIL : this.checkForMiniOrMaxView(SCREEN_NAME.MINIMIZED_CHAT_DETAIL, SCREEN_NAME.EXPANDED_CHAT_DETAIL));
  }

  /**
   * Send chat detail leave analytic.
   */
  onChatDetailLeave(event: Date): void {
    document.body?.classList?.remove(PIP_CLASS);
    this.sendAnalytics(
      EVENT_NAME.CHAT_DETAIL_VISIT,
      PRODUCT_TYPE_CONSTANT.CHAT,
      this.productTypeId,
      this.deviceService.isMobile() ? SCREEN_NAME.CHAT_DETAIL : this.checkForMiniOrMaxView(SCREEN_NAME.MINIMIZED_CHAT_DETAIL, SCREEN_NAME.EXPANDED_CHAT_DETAIL),
      event.getTime().toString()
    );
  }

  /**
   * Send chat list visit analytic.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChatListVisit(event: Date): void {
    this.chatListStartTime = new Date();
    this.sendAnalytics(EVENT_NAME.CHATS_WINDOW_ENTRY, PRODUCT_TYPE_CONSTANT.CHAT, this.productTypeId, this.deviceService.isMobile() ? SCREEN_NAME.CHAT_WINDOW : this.checkForMiniOrMaxView(SCREEN_NAME.MINIMIZED_CHAT_WINDOW, SCREEN_NAME.EXPANDED_CHAT_WINDOW));
  }

  /**
   * Send chat bar click analytic.
   */
  onChatBarClick(): void {
    this.sendAnalytics(EVENT_NAME.CHATS_TAB_CLICK, PRODUCT_TYPE_CONSTANT.CHAT, this.productTypeId, SCREEN_NAME.CHAT_BAR_BOTTOM);
  }

  /**
   * Send chat list leave analytic.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChatListLeave(event: Date): void {
    this.sendAnalytics(
      EVENT_NAME.CHATS_WINDOW_VISIT,
      PRODUCT_TYPE_CONSTANT.CHAT,
      this.productTypeId,
      this.deviceService.isMobile() ? SCREEN_NAME.CHAT_WINDOW : this.checkForMiniOrMaxView(SCREEN_NAME.MINIMIZED_CHAT_WINDOW, SCREEN_NAME.EXPANDED_CHAT_WINDOW),
      this.chatListStartTime?.getTime()?.toString()
    );
  }

  /**
   * Send new chat visit analytic.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onNewChatVisit(event: Date): void {
    this.sendAnalytics(EVENT_NAME.CHATS_SEARCH_DOCTORS_ENTRY, PRODUCT_TYPE_CONSTANT.CHAT, this.productTypeId, this.deviceService.isMobile() ? SCREEN_NAME.CHATS_SEARCH_DOCTORS : this.checkForMiniOrMaxView(SCREEN_NAME.MINIMIZED_CHATS_SEARCH_DOCTORS, SCREEN_NAME.EXPANDED_CHATS_SEARCH_DOCTORS));
  }

  /**
   * Send new chat leave analytic.
   */
  onNewChatLeave(event: Date): void {
    this.sendAnalytics(
      EVENT_NAME.CHATS_SEARCH_DOCTORS_VISIT,
      PRODUCT_TYPE_CONSTANT.CHAT,
      this.productTypeId,
      this.deviceService.isMobile() ? SCREEN_NAME.CHATS_SEARCH_DOCTORS : this.checkForMiniOrMaxView(SCREEN_NAME.MINIMIZED_CHATS_SEARCH_DOCTORS, SCREEN_NAME.EXPANDED_CHATS_SEARCH_DOCTORS),
      event.getTime().toString()
    );
  }

  /**
   * Send create group leave analytic.
   */
  onGroupLeave(event: Date): void {
    this.sendAnalytics(
      EVENT_NAME.NEW_GROUP_DETAILS_VISIT,
      PRODUCT_TYPE_CONSTANT.CHAT,
      this.productTypeId,
      this.deviceService.isMobile() ? SCREEN_NAME.GROUP_INFO : this.checkForMiniOrMaxView(SCREEN_NAME.MINIMIZED_GROUP_CREATION_DETAILS, SCREEN_NAME.EXPANDED_GROUP_CREATION_DETAILS),
      event.getTime().toString()
    );
  }

  /**
   * Send create group visit analytic.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onGroupVisit(event: Date): void {
    this.sendAnalytics(EVENT_NAME.NEW_GROUP_DETAILS_ENTRY, PRODUCT_TYPE_CONSTANT.CHAT, this.productTypeId, this.deviceService.isMobile() ? SCREEN_NAME.GROUP_INFO : this.checkForMiniOrMaxView(SCREEN_NAME.MINIMIZED_GROUP_CREATION_DETAILS, SCREEN_NAME.EXPANDED_GROUP_CREATION_DETAILS));
  }

  /**
   * Send new member entry analytic.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onNewMemberVisit(event: Date): void {
    this.sendAnalytics(EVENT_NAME.NEW_GROUP_ENTRY, PRODUCT_TYPE_CONSTANT.CHAT, this.productTypeId, this.deviceService.isMobile() ? SCREEN_NAME.GROUP_MEMBERS : this.checkForMiniOrMaxView(SCREEN_NAME.MINIMIZED_GROUP_MEMBERS, SCREEN_NAME.EXPANDED_GROUP_CREATION_MEMBERS));
  }

  /**
   * Send new member leave analytic.
   */
  onNewMemberLeave(event: Date): void {
    this.sendAnalytics(
      EVENT_NAME.NEW_GROUP_VISIT,
      PRODUCT_TYPE_CONSTANT.CHAT,
      this.productTypeId,
      this.deviceService.isMobile() ? SCREEN_NAME.GROUP_MEMBERS : this.checkForMiniOrMaxView(SCREEN_NAME.MINIMIZED_GROUP_MEMBERS, SCREEN_NAME.EXPANDED_GROUP_CREATION_MEMBERS),
      event.getTime().toString()
    );
  }

  /**
   * Send start new chat click analytic.
   */
  onStartNewChatClick(): void {
    this.sendAnalytics(EVENT_NAME.START_MESSAGING_CLICK, PRODUCT_TYPE_CONSTANT.CHAT, this.productTypeId, this.deviceService.isMobile() ? SCREEN_NAME.CHAT_WINDOW : this.checkForMiniOrMaxView(SCREEN_NAME.MINIMIZED_CHAT_WINDOW, SCREEN_NAME.EXPANDED_CHAT_WINDOW));
  }

  /**
   * Send message click analytic.
   */
  onSendClick(): void {
    this.sendAnalytics(EVENT_NAME.CHAT_SEND_CLICK, PRODUCT_TYPE_CONSTANT.CHAT, this.productTypeId, this.deviceService.isMobile() ? SCREEN_NAME.CHAT_DETAIL : this.checkForMiniOrMaxView(SCREEN_NAME.MINIMIZED_CHAT_DETAIL, SCREEN_NAME.EXPANDED_CHAT_DETAIL));
  }

  /**
   * Send create new group click analytic.
   */
  onCreateNewGroupClick(): void {
    this.sendAnalytics(EVENT_NAME.NEW_GROUP_CREATE_CLICK, PRODUCT_TYPE_CONSTANT.CHAT, this.productTypeId, this.deviceService.isMobile() ? SCREEN_NAME.CHAT_DETAIL : this.checkForMiniOrMaxView(SCREEN_NAME.MINIMIZED_CHAT_WINDOW, SCREEN_NAME.EXPANDED_CHAT_WINDOW));
  }

  /**
   * Send done create new group click analytic.
   */
  onCreateGroupDoneClick(): void {
    this.sendAnalytics(EVENT_NAME.CREATE_GROUP_DONE_CLICK, PRODUCT_TYPE_CONSTANT.CHAT, this.productTypeId, this.deviceService.isMobile() ? SCREEN_NAME.GROUP_INFO : this.checkForMiniOrMaxView(SCREEN_NAME.MINIMIZED_GROUP_CREATION_DETAILS, SCREEN_NAME.EXPANDED_GROUP_CREATION_DETAILS));
  }

  /**
   * Send chat expand button click analytic.
   */
  onChatExpandButtonClick(): void {
    this.sendAnalytics(EVENT_NAME.CHAT_EXPANDED_VIEW_CLICK, PRODUCT_TYPE_CONSTANT.CHAT, this.productTypeId, SCREEN_NAME.MINIMIZED_CHAT_DETAIL);
  }

  /**
   * Send attachment click analytic.
   */
  onAttachmentClick(): void {
    this.sendAnalytics(EVENT_NAME.ATTACH_MEDIA_CLICK, PRODUCT_TYPE_CONSTANT.CHAT, this.productTypeId, this.deviceService.isMobile() ? SCREEN_NAME.CHAT_DETAIL : this.checkForMiniOrMaxView(SCREEN_NAME.MINIMIZED_CHAT_DETAIL, SCREEN_NAME.EXPANDED_CHAT_WINDOW));
  }

  /**
   * Send mute notification click analytic.
   */
  onMuteNotificationClick(): void {
    this.sendAnalytics(EVENT_NAME.MUTE_NOTIFICATION_CLICK, PRODUCT_TYPE_CONSTANT.CHAT, this.productTypeId, this.deviceService.isMobile() ? SCREEN_NAME.CHAT_DETAIL : this.checkForMiniOrMaxView(SCREEN_NAME.MINIMIZED_CHAT_DETAIL, SCREEN_NAME.EXPANDED_CHAT_DETAIL));
  }

  /**
   * Send block user click analytic.
   */
  onBlockUserClick(): void {
    this.sendAnalytics(EVENT_NAME.BLOCK_CHAT_CLICK, PRODUCT_TYPE_CONSTANT.CHAT, this.productTypeId, this.deviceService.isMobile() ? SCREEN_NAME.CHAT_DETAIL : this.checkForMiniOrMaxView(SCREEN_NAME.MINIMIZED_CHAT_DETAIL, SCREEN_NAME.EXPANDED_CHAT_DETAIL));
  }

  /**
   * Send unmute notification click analytic.
   */
  onUnmuteNotificationClick(): void {
    this.sendAnalytics(EVENT_NAME.UNMUTE_NOTIFICATION_CLICK, PRODUCT_TYPE_CONSTANT.CHAT, this.productTypeId, this.deviceService.isMobile() ? SCREEN_NAME.CHAT_DETAIL : this.checkForMiniOrMaxView(SCREEN_NAME.MINIMIZED_CHAT_DETAIL, SCREEN_NAME.EXPANDED_CHAT_DETAIL));
  }

  /**
   * Send unblock user click analytic.
   */
  onUnblockUserClick(): void {
    this.sendAnalytics(EVENT_NAME.UNBLOCK_CHAT_CLICK, PRODUCT_TYPE_CONSTANT.CHAT, this.productTypeId, this.deviceService.isMobile() ? SCREEN_NAME.CHAT_DETAIL : this.checkForMiniOrMaxView(SCREEN_NAME.MINIMIZED_CHAT_DETAIL, SCREEN_NAME.EXPANDED_CHAT_DETAIL));
  }

  /**
   * Send exit group click analytic.
   */
  onExitGroupClick(): void {
    this.sendAnalytics(EVENT_NAME.EXIT_GROUP_CLICK, PRODUCT_TYPE_CONSTANT.CHAT, this.productTypeId, this.deviceService.isMobile() ? SCREEN_NAME.CHAT_DETAIL : this.checkForMiniOrMaxView(SCREEN_NAME.MINIMIZED_CHAT_DETAIL, SCREEN_NAME.EXPANDED_CHAT_DETAIL));
  }

  /**
   * Send when member removed from the group analytic.
   */
  onMemberRemoveFromGroup(): void {
    this.sendAnalytics(EVENT_NAME.REMOVE_USER_CLICK, PRODUCT_TYPE_CONSTANT.CHAT, this.productTypeId, this.deviceService.isMobile() ? SCREEN_NAME.GROUP_INFO : this.checkForMiniOrMaxView(SCREEN_NAME.MINIMIZED_GROUP_INFO, SCREEN_NAME.EXPANDED_GROUP_INFO));
  }

  /**
   * Send when member removed from the group analytic.
   */
  onAddDoctorInGroup(): void {
    this.sendAnalytics(EVENT_NAME.GROUP_ADD_MEMBER_CLICK, PRODUCT_TYPE_CONSTANT.CHAT, this.productTypeId, this.deviceService.isMobile() ? SCREEN_NAME.GROUP_INFO : this.checkForMiniOrMaxView(SCREEN_NAME.MINIMIZED_GROUP_INFO, SCREEN_NAME.EXPANDED_GROUP_INFO));
  }

  /**
   * Send when member removed from the group analytic.
   */
  onFailedMsg(): void {
    this.sendAnalytics(EVENT_NAME.MESSAGE_FAILURE, PRODUCT_TYPE_CONSTANT.CHAT, this.productTypeId, '');
  }

  /**
   * Check for the minimize or maximize chat view.
   */
  checkForMiniOrMaxView(first: string, second: string): string {
    return this.dqChatPanelService.isChatPanelVisible ? first : second;
  }

  /**
   * Send analytics event.
   */
  sendAnalytics(eventName: EventDataKey, productType: number, productTypeId: number, screenName: string, startTime = this.getTimeString(), endTime = this.getTimeString()): void {
    this.analyticsData = {
      event_name: eventName,
      product_type: productType,
      product_type_id: productTypeId,
      screen_name: screenName,
      start_time: startTime,
      end_time: endTime
    };
    this.analyticsEventService.logAnalyticsEvent(this.analyticsData);
  }

  /**
   * Get the current time in string.
   */
  getTimeString(): string {
    return new Date().getTime().toString();
  }

  /**
   * Back to history.
   */
  backToHistory(): void {
    history.back();
  }

  /**
   * Reset samvaad chat.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  resetChat(code: number): void {
    this.closeChat = true;
    this.samToken = '';
    this.authToken = '';
    if (localStorage.getItem('samvaadAuthData')) {
      localStorage.removeItem('samvaadAuthData');
    }
    this.getSamToken();
    this.closeChat = false;
  }

  /**
   * Unsubscribe the subscription.
   */
  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
}
