/**
 * Import basic angular components for handling Input, Output and Events.
 */
import { Component, Input, Output, EventEmitter } from '@angular/core';
/**
 * Import interface for data to be displayed on search popup.
 */
import { Keyword, SearchData } from '../../interface/search/search.model';

/**
 * This component is responsible for displaying search history and suggestions in search popup.
 */
@Component({
  selector: 'app-search-history-suggestion-list',
  templateUrl: './search-history-suggestion-list.component.html',
  styleUrls: ['./search-history-suggestion-list.component.scss']
})
export class SearchHistorySuggestionListComponent {
  /**
   * Data to be displayed on search popup.
   */
  @Input() searchData: SearchData;
  /**
   * Data to be displayed on search popup.
   */
   @Input() focusItem = -1;

  /**
   * Event responsible for triggering search for given text.
   */
  @Output() searchGivenText: EventEmitter<string> = new EventEmitter<string>();
  /**
   * Event responsible for pasting given text on search field.
   */
  @Output() pasteOnSearchBar: EventEmitter<string> = new EventEmitter<string>();

  /**
   * Search text from given option on popup.
   */
  searchKeyword(event: string): void {
    this.searchGivenText.emit(event);
  }

  /**
   * Paste content in search field.
   */
  pasteKeyword(event: string): void {
    this.pasteOnSearchBar.emit(event);
  }

  /**
   * Track by Object Identity.
   */
  trackByIdentity = (index: number, item: Keyword): string => index + '-' + item.id;
}
