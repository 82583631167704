import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'roundValue'
})
export class RoundValuePipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transform (input: any): any {
    if (input != null) {
    input = input.toFixed(2);
    }
    return (input);
  }

}
