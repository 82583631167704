/**
 * Angular imports.
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

/**
 * Component imports.
 */
import { CustomTooltipComponent } from './custom-tooltip.component';



@NgModule({
  declarations: [
    CustomTooltipComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    CustomTooltipComponent
  ]
})
export class CustomTooltipModule { }
