/**
 * Angular imports.
 */
import { HttpHeaders, HttpRequest } from '@angular/common/http';

/**
 * Constant imports.
 */
import { HEADERS } from '../constants/headers-url.constants';
import { BASE_URLS } from '../constants/base-url.constants';
import { API_METHOD_TYPE } from '../constants/app.constants';

/**
 * Environment imports.
 */
import { environment } from '../../environments/environment';

/**
 * This is used to help matching the urls for the interceptors.
 */
export class MatchUrlsHelper {

  /**
   * Base urls for url versions.
   */
  private static baseUrls = BASE_URLS;

  /**
   * Urls for the headers of the api's.
   */
  private static headers = HEADERS;

  /**
   * Match the base urls for the version.
   */
  static matchBaseUrls(url: string): string {
    let baseUrl = '';
    switch (url) {
      case this.baseUrls.calendar[url]:
        baseUrl = environment.API_URLS.calendar;
        break;
      case this.baseUrls.amazon[url]:
        baseUrl = environment.API_URLS.amazon;
        break;
      case this.baseUrls.privacy_policy[url]:
        baseUrl = environment.API_URLS.privacy_policy;
        break;
      case this.baseUrls.terms_and_condition[url]:
        baseUrl = environment.API_URLS.terms_and_condition;
        break;
      case this.baseUrls.analytics[url]:
        baseUrl = environment.API_URLS.analytics;
        break;
      case this.baseUrls.chat_credentials[url]:
        baseUrl = environment.API_URLS.chat_credentials;
        break;
      case this.baseUrls.auto_verification[url]:
        baseUrl = environment.API_URLS.auto_verification;
        break;
      case this.baseUrls.onboarding[url]:
        baseUrl = environment.API_URLS.onboarding;
        break;
      case this.baseUrls.quickblox[url]:
        baseUrl = environment.API_URLS.quickblox;
        break;
      case this.baseUrls.voip_node[url]:
        baseUrl = environment.API_URLS.voip_node;
        break;
      case this.baseUrls.comment[url]:
        baseUrl = environment.API_URLS.comment;
        break;
      case this.baseUrls.bookmark[url]:
        baseUrl = environment.API_URLS.bookmark ;
        break;
      case this.baseUrls.event[url]:
        baseUrl = environment.API_URLS.event;
        break;
      case this.baseUrls.voip[url]:
        baseUrl = environment.API_URLS.voip;
        break;
      case this.baseUrls.baseUrl2_0[url]:
        baseUrl = environment.baseUrls.baseUrl2_0;
        break;
      case this.baseUrls.search[url]:
        baseUrl = environment.API_URLS.search;
        break;
      case this.baseUrls.community[url]:
        baseUrl = environment.API_URLS.community;
        break;
      case this.baseUrls.ncp[url]:
        baseUrl = environment.API_URLS.ncp;
        break;
      case this.baseUrls.card_rendering_analytics[url]:
        baseUrl = environment.API_URLS.card_rendering_analytics;
        break;
      case this.baseUrls.editor[url]:
        baseUrl = environment.API_URLS.editor;
        break;
      case this.baseUrls.user[url]:
        baseUrl = environment.API_URLS.user;
        break;
      case this.baseUrls.clinic_consent[url]:
        baseUrl = environment.API_URLS.clinic_consent;
        break;
      case this.baseUrls.chat_bot[url]:
        baseUrl = environment.API_URLS.chat_bot;
        break;
      case this.baseUrls.mr[url]:
        baseUrl = environment.API_URLS.mr;
        break;

      case this.baseUrls.webinar[url]:
        baseUrl = environment.API_URLS.webinar;
        break;

      case this.baseUrls.payment[url]:
        baseUrl = environment.API_URLS.payment;
        break;

      case this.baseUrls.nudge[url]:
        baseUrl = environment.API_URLS.nudge;
        break;

      case this.baseUrls.background[url]:
        baseUrl = environment.API_URLS.background;
        break;

      case this.baseUrls.report_user[url]:
        baseUrl = environment.API_URLS.report_user;
        break;

      case this.baseUrls.referral[url]:
        baseUrl = environment.API_URLS.referral;
        break;

      case this.baseUrls.notification[url]:
        baseUrl = environment.API_URLS.notification;
        break;

      case this.baseUrls.gr[url]:
        baseUrl = environment.API_URLS.magistrate;
        break;

      case this.baseUrls.verification[url]:
        baseUrl = environment.API_URLS.verification;
        break;

      case this.baseUrls.others[url]:
        baseUrl = environment.API_URLS.others;
        break;

      case this.baseUrls.pharma[url]:
        baseUrl = environment.API_URLS.pharma;
        break;

      case this.baseUrls.edetailing[url]:
        baseUrl = environment.API_URLS.edetailing;
        break;

      case this.baseUrls.survey[url]:
        baseUrl = environment.API_URLS.survey;
        break;
      case this.baseUrls.keeper[url]:
        baseUrl = environment.API_URLS.keeper;
        break;
      case this.baseUrls.profile[url]:
        baseUrl = environment.API_URLS.profile;
        break;

      case this.baseUrls.journal[url]:
        baseUrl = environment.API_URLS.journal;
        break;

      case this.baseUrls.feedback[url]:
        baseUrl = environment.API_URLS.feedback;
        break;

      case this.baseUrls.socket[url]:
        baseUrl = environment.API_URLS.socket;
        break;

      case this.baseUrls.doctalks[url]:
        baseUrl = environment.API_URLS.doctalks;
        break;

      case this.baseUrls.cme[url]:
        baseUrl = environment.API_URLS.cme;
        break;

      case this.baseUrls.like[url]:
        baseUrl = environment.API_URLS.like;
        break;

      case this.baseUrls.feed[url]:
        baseUrl = environment.API_URLS.feed;
        break;

      case this.baseUrls.socket_comment[url]:
        baseUrl = environment.API_URLS.socket_comment;
        break;

      case this.baseUrls.speciality[url]:
        baseUrl = environment.API_URLS.speciality;
        break;

      case this.baseUrls.chat[url]:
        baseUrl = environment.API_URLS.chat;
        break;

      case this.baseUrls.registration[url]:
        baseUrl = environment.API_URLS.registration;
        break;

      case this.baseUrls.media_upload[url]:
        baseUrl = environment.API_URLS.media_upload;
        break;

      case this.baseUrls.meta_data[url]:
        baseUrl = environment.API_URLS.meta_data;
        break;

      case this.baseUrls.forward_flow[url]:
        baseUrl = environment.API_URLS.forward_flow;
        break;

      case this.baseUrls.join_meeting_web[url]:
        baseUrl = environment.API_URLS.join_meeting_web;
        break;

      case this.baseUrls.ip_address_api[url]:
        baseUrl = environment.API_URLS.ip_address_api;
        break;

      case this.baseUrls.mr_connect_mobile[url]:
        baseUrl = environment.API_URLS.mr_connect_mobile;
        break;

      case this.baseUrls.mr_connect[url]:
        baseUrl = environment.API_URLS.mr_connect;
        break;
      case this.baseUrls.baseUrl2_0[url]:
        baseUrl = environment.API_URLS.user_stories;
        break;
      case this.baseUrls.pvs[url]:
        baseUrl = environment.API_URLS.pvs;
        break;

      case this.baseUrls.keeper[url]:
        baseUrl = environment.API_URLS.keeper;
      case this.baseUrls.openContent[url]:
        baseUrl = environment.API_URLS.openContent;
        break;
    }
    return baseUrl;
  }
  /**
   * Match urls for the headers.
   */
  static matchHeaderUrls(request: HttpRequest<unknown>): HttpHeaders {

    const url = request.url;
    let headers = request.headers;
    if (this.headers.ver_api_version[url] === url) {
      headers = headers.set('ver', environment.versions.api_version);
    }
    if (this.headers.xApiKey[url] === url) {
      headers = headers.set('x-api-key', environment.x_api_key);
    }
    if (this.headers.version_21[url] === url) {
      headers = headers.set('version', environment.versions.apiVersion21);
    }
    if (this.headers.ver_api_version_updated[url] === url) {
      headers = headers.set('ver', environment.versions.api_version_updated);
    }
    if (this.headers.ver_47[url] === url) {
      headers = headers.set('ver', environment.versions.api_version_47);
    }
    if (this.headers.releaseVersion_updatedReleaseVersion[url] === url) {
      headers = headers.set('releaseVersion', environment.versions.updatedReleaseVersion);
    }
    if (this.headers.udid_123[url] === url) {
      headers = headers.set('udid', environment.udid);
    }
    if (this.headers.version_3[url] === url) {
      headers = headers.set('version', environment.versions.apiVersion3);
    }
    if (this.headers.version_GROUP_CHAT_VERSION[url] === url) {
      headers = headers.set('version', environment.versions.GROUP_CHAT_VERSION);
    }
    if (this.headers.ver_24_28[url] === url) {
      headers = headers.set('ver', environment.versions.apiVer28);
    }
    if (this.headers.ver_1[url] === url) {
      headers = headers.set('ver', environment.versions.apiVer1);
    }
    if (this.headers.ver_27[url] === url) {
      headers = headers.set('ver', environment.versions.apiVer27);
    }
    if (this.headers.ver_23[url] === url) {
      headers = headers.set('ver', environment.versions.apiVer23);
    }
    if (this.headers.version_20[url] === url) {
      headers = headers.set('version', environment.versions.apiVersion20);
    }
    if (this.headers.releaseVersion_environment_appReleaseVersion[url] === url) {
      headers = headers.set('releaseVersion', environment.versions.appReleaseVersion);
    }
    if (this.headers.ver_41[url] === url) {
      headers = headers.set('ver', environment.versions.apiVer41);
    }
    if (this.headers.ver_52[url] === url) {
      headers = headers.set('ver', environment.versions.apiVer52);
    }
    if (this.headers.releaseVersion_releaseVersion[url] === url) {
      headers = headers.set('releaseVersion', environment.versions.releaseVersion);
    }
    if (this.headers.releaseVersion_environment_GR_RELEASE_VERSION[url] === url) {
      headers = headers.set('releaseVersion', environment.versions.GR_RELEASE_VERSION);
    }
    // TODO : Nielsh: Autoverification signup.
    if (this.headers.releaseVersion_environment_RELEASE_VERSION_17[url] === url) {
      headers = headers.set('releaseVersion', environment.versions.RELEASE_VERSION_17);
    }
    if (this.headers.releaseVersion_environment_RELEASE_VERSION_20[url] === url) {
      headers = headers.set('releaseVersion', environment.versions.RELEASE_VERSION_20);
    }
    if (this.headers.releaseVersion_environment_RELEASE_VERSION_19[url] === url) {
      headers = headers.set('releaseVersion', environment.versions.RELEASE_VERSION_19);
    }
    if (this.headers.ver_22[url] === url) {
      headers = headers.set('ver', environment.versions.apiVer22);
    }
    if (this.headers.iso_code_91[url] === url) {
      headers = headers.set('iso_code', '91');
    }
    if (this.headers.Content_Type_application_json[url] === url) {
      headers = headers.set('Content-Type', 'application/json');
    }
    if (this.headers.Content_Type_Url_encoded_UTF_8[url] === url) {
      headers = headers.set('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8');
    }

    if (url.includes('/api/v2/7.0/community/') &&  request.method === API_METHOD_TYPE.GET) {
      headers = headers.set('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8');
    }

    if (this.headers.ver_44[url] === url) {
      headers = headers.set('ver', environment.versions.apiVer44);
    }
    if (this.headers.ver_25[url] === url) {
      headers = headers.set('ver', environment.versions.apiVer25);
    }
    if (this.headers.releaseVersion_9[url] === url) {
      headers = headers.set('releaseVersion', environment.versions.releaseVersion9);
    }
    if (this.headers.otpToken[url] === url) {
      headers = headers.set('otptoken', JSON.parse(localStorage.getItem('otpToken')));
    }
    if (this.headers.ver_46[url] === url) {
      headers = headers.set('ver', environment.versions.apiVer46);
    }
    if (this.headers.ver_49[url] === url) {
      headers = headers.set('ver', environment.versions.apiVer49);
    }
    if (this.headers.ver_51[url] === url) {
      headers = headers.set('ver', environment.versions.apiVer51);
    }
    if (this.headers.ver_70[url] === url) {
      headers = headers.set('ver', environment.versions.apiVer70);
    }
    if (this.headers.ver_71[url] === url) {
      headers = headers.set('ver', environment.versions.apiVer71);
    }
    if (this.headers.ver_50[url] === url) {
      headers = headers.set('ver', environment.versions.apiVer50);
    }
    return headers;
  }

  /**
   *  to check whether access token need to be sent on the API header
   * @param request
   * @returns
   */
  static isRtPurpleApi(request: HttpRequest<unknown>): boolean {
    const url = request.url;
    if (this.headers.rt_purple_apis[url] === url) {
      return true;
    }
    return false;
  }

   /**
   *  to check whether access token need to be sent on the API header
   * @param request
   * @returns
   */
   static isAccessTokenSupportedApi(request: HttpRequest<unknown>): boolean {
    const url = request.url;
    const apiPath = /\/api\/v2\//;
    if (apiPath.test(url)) {
      return true;
    }
    return false;
  }
  /**
   *  to check whether access token need to be sent on the API header
   * @param request
   * @returns
   */
  static isPublicApi(request: HttpRequest<unknown>): boolean {
    const url = request.url;
    const apiPath = /\/api\/v2\/brahma\/public\//;
    if (apiPath.test(url)) {
      return true;
    }
    return false;
  }
}
