/**
 * Angular imports.
 */
import { Injectable } from '@angular/core';

/**
 * Environment imports.
 */
import { environment } from '../../environments/environment';

/**
 * Js imports.
 */
import * as crypto from 'crypto-js';

const hashkey = environment.hashKey;

/**
 * Encryption Service
 */
@Injectable({
  providedIn: 'root'
})
export class EncryptionService {
  public token = '';

  /**
   * navigate To Login
   */
  navigateToLogin(): void {
   // window.location.href = environment.baseUrls.webSiteUrl + '/#/login';
  }

  /**
   * encrypt Aes
   */
  encryptAes(plaintext): string {
    if (localStorage.getItem('token') != null) {
      const ciphertext = crypto.AES.encrypt(plaintext, hashkey);
      return ciphertext;
    }
  }

  /**
   * decrypt Aes
   */
  decryptAes(ciphertext): string {
    if (localStorage.getItem('token') != null) {
      const str = ciphertext.toString();
      // Decrypt
      const bytes = crypto.AES.decrypt(str, hashkey);
      const plaintext = bytes.toString(crypto.enc.Utf8);
      return plaintext;
    }

  }
    /**
   * decrypt Aes
   */
    byPassAuthDecryptAes(ciphertext): string {
      if (localStorage.getItem('token') != null) {
        const str = ciphertext.toString();
        // Decrypt
        const bytes = crypto.AES.decrypt(str, hashkey);
        const plaintext = bytes.toString(crypto.enc.Utf8);
        return plaintext;
      }

    }
}
