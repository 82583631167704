
  <!-- new coommnet design -->

  <div class="prd-cmmt-wrapper">

    <div *ngIf="commentList.length>0" class="text-left">
      <div id="commentbox-1" *ngFor="let cL of commentList; let index=index;">
        <div class="brder-bottom p-3">
          <app-comment-card [cL]="cL" [callingfrom]="callingFrom" [index]="index" (resetComment)="resetComment($event)" [commentObj]="commentObj" (replyCommentCount)="replyCommentCount($event)" [commentProfileData]="cl" (emitAutoComment)="emitAutoComment($event)" [screenName]="screenName" [comment_box_obj]="comment_box_obj"></app-comment-card>
        </div>   
      </div>
      <div class="py-2 text-center" *ngIf="page>0 && totalComment>commentList.length">
        <a [appAnalyticsEvent]='analyticsViewMoreComment' (click)="loadMore(product_type,product_id,page);"
          class=" color-black cursor-pointer"><span class="color-error">{{Retry}} &nbsp;</span>
          <span class="font-weight-600">{{mls.languageText.comment_card.key10}}
         <img class="pl-2" src="assets/themes/icons/down-arrow.svg" alt="down" />
          </span>
          <i class="pad-left-5" *ngIf="loader_comment==true"><img width="20px" height="20px" src="assets/themes/images/primary-loader.gif" alt="loader" /></i></a>
      </div>
      <div class="text-center">
        <ng-template #nocomment>{{mls.languageText.comment_card.key12}}</ng-template></div>
    </div>


    <!-- comment box  -->
    <div class="pb-2" *ngIf="showCommentBox!=0">
      <div class="row text-left">
        <div class="col-2 pr-0 profile-pic">
          <div class="mr-2 user-40 position-relative">
            <app-user-pofile-pic [profile_pic_image]="comment_post_prfl_image"></app-user-pofile-pic>
          </div>
        </div>
        <div class="col-12 pl-0 input-width">
          <div class="form-input">
          <input class="input-field" #newComment
            (keyup.enter)="setComment(product_id,product_type,newComment.value,'',0)"
            placeholder="{{mls.languageText.comment_card.key26}}" [(ngModel)]="commentVal" type="text">
          </div>
        </div>
      </div>
    </div>

    <div class="p-3 text-center"
      *ngIf="commentObj && commentList.length === 0 && (commentObj.product_type === productType.EVENT || commentObj.product_type === productType.SESSION)">
      <img src="assets/themes/icons/no-chat.svg" alt="no-comment" />
      <p class="heading-4 pt-3"><span class="color-grey-500">No comments yet</span></p>
      <p class="paragraph"><span class="color-grey-500">Be the first one to comment</span></p>
    </div>
  </div>
  <!-- end comment box -->
  <!--Eof Comment edit-->

  <!-- like list popup start**************************** -->
  <app-product-like *ngIf="showLikeListModal" (resetLikeModalList)=resetLikeModalList($event)
    [likeObj]=likeObj></app-product-like>
  <!-- like list popup end ***************************** -->

  <app-confirm-modal *ngIf="isConfirmModalShown==true" (resetConfirmModal)=resetConfirmModal($event)
    [isConfirmModalShown]=isConfirmModalShown [confirmInfo]=confirmInfo></app-confirm-modal>

  <!-- information message popup start-->
  <app-info-modal (resetInfoModal)=resetInfoModal() [isInfoModalShown]=isInfoModalShown
    [info_msg]="info_msg"></app-info-modal>
  <!-- information message popup -->