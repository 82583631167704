<div *ngIf="!fcs.device_detail.is_mobile">
  <div *ngIf="userRolePopup" class="modal fade" tabindex="-1" role="dialog"
    [config]="{ show: true, backdrop: 'static' }" bsModal #autoShownModal="bs-modal">
    <div class="custom-theme-modal modal-md modal-dialog-centered user-role-modal">
      <div class="modal-content">
        <div class="row mx-0">
          <div class="col-4 pt-5 left-section p-0">
            <div class="px-4 pb-5 pt-3 app-otph-100 left-bg-updated d-none d-md-block card-border">

              <img src="./assets/themes/icons/public/user-role-left-section.svg">
              <p class="heading-4 mt-4 text-center px-5"> <span class="">Thanks for exploring our medical
                  content.</span></p>
              <p class="primary-small text-center px-5">{{mls.languageText.open_article.key2}}</p>
            </div>
          </div>
          <div class="col-8 p-0">
            <div class="mt-3 mr-3">
            </div>
            <div class="px-5 py-2 mt-4">
              <p><img src="./assets/themes/icons/public/arrow_back.svg" /></p>
              <div class="text-right"> <img src="./assets/images/cross.svg" (click)="closePopUp()"> </div>
              <p class="heading-2">{{mls.languageText.open_article.key3}}</p>
              <div class="red-strip my-1"></div>
              <p class="paragraph">We are GDPR compliant, so your information is in a safe place. </p>
              <div>

                <div class="radio-group mt-3">
                  <button class="radio-button btn-design medium outline px-4" (click)="selectUserRole(selectedUserRole.BR)">
                    <input type="radio" name="radio-group" id="radio1">
                    <label for="radio1"><span class="secondary-paragraph-bold">{{mls.languageText.open_article.key4}}</span></label>
                  </button>
                  <button class="radio-button btn-design medium outline px-4" (click)="selectUserRole(selectedUserRole.HCP)">
                    <input type="radio" name="radio-group" id="radio2">
                    <label for="radio2"><span class="secondary-paragraph-bold">{{mls.languageText.open_article.key5}}</span></label>
                  </button>
                  <button class="radio-button btn-design medium outline px-4 mt-3" (click)="selectUserRole(selectedUserRole.OTHERS)">
                    <input type="radio" name="radio-group" id="radio3">
                    <label for="radio3"><span class="secondary-paragraph-bold">{{mls.languageText.open_article.key6}}</span></label>
                  </button>
                </div>

              </div>
              <label class="pl-1 mt-5">
                <button class="btn-design medium filled" (click)="submitUserRole()">
                  <span>{{mls.languageText.open_article.key7}}</span>
                </button>
              </label>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div *ngIf="fcs.device_detail.is_mobile">
  <div *ngIf="userRolePopup" class="modal fade" tabindex="-1" role="dialog"
    [config]="{ show: true, backdrop: 'static' }" bsModal #autoShownModal="bs-modal">
    <div class="custom-theme-modal modal-dialog modal-md modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body pt-0">

          <div class="px-2">

            <div class=" py-2 mt-4">
              <div class="text-right"> <img src="./assets/images/cross.svg"> </div>
              <p class="heading-2">Tell us about your role</p>
              <div class="red-strip my-1"></div>
              <p class="paragraph">We are GDPR compliant, so your information is in a safe place. </p>
              <div>

                <div class="radio-group mt-3">
                  <button class="radio-button btn-design medium outline px-4 mb-3"  (click)="selectUserRole(selectedUserRole.BR)">
                    <input type="radio" name="radio-group" id="radio1">
                    <label for="radio1"><span class="secondary-paragraph-bold">Business Representative</span></label>
                  </button>
                  <button class="radio-button btn-design medium outline px-4 mb-3" (click)="selectUserRole(selectedUserRole.HCP)">
                    <input type="radio" name="radio-group" id="radio2">
                    <label for="radio2"><span class="secondary-paragraph-bold">Healthcare Practitioner</span></label>
                  </button>
                  <button class="radio-button btn-design medium outline px-4 mb-3" (click)="selectUserRole(selectedUserRole.OTHERS)">
                    <input type="radio" name="radio-group" id="radio3">
                    <label for="radio3"><span class="secondary-paragraph-bold">Others</span></label>
                  </button>
                </div>

              </div>
              <div class="float-right my-4">
                <button class="btn-design medium filled " (click)="submitUserRole()" >
                  <span>Next</span>
                </button>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
  </div>

  <!-- <app-business-enquiry-form
   [enquiryForm]="oas.businessPopUp">
  </app-business-enquiry-form> -->