// TODO : Pavan: remove some knows commented code after testing done.

/**
 * Rxjs import.
 */
import { interval, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * Agora imports.
 */
// import AgoraRTC from 'agora-rtc-sdk-ng';
declare let AgoraRTC;
import { AgStream } from './agStream';
import { RtcClient, RtmClient } from './agoraClient';

/**
 * declare var AgoraRTC: any;
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let AgoraRTM: any;


export class LocalStream implements AgStream {

  /**
   * Network quality message.
   */
  public networkQualityMsg = '';
  /**
   * This will contain Agora's uid, which could be integer or string.
   */
  public id: string | number;

  /**
   * A user can initiate multiple stream, such as video and screen share.
   * This id is unique for a particular user.
   */
  public userId: string;

  /**
   * This will contain Agora's stream object.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public stream: any;

  /**
   * Name of the user who initiated Agora stream.
   */
  public name?: string;

  /**
   * Audio flag of the stream
   */
  public audioMuted: boolean;

  /**
   * Video flag of the steam
   */
  public videoMuted: boolean;

  /**
   * speaker flag.
   */
  public speaking: boolean;

  /**
   * Presenting flag.
   */
  public presenting: boolean;

  /**
   * Browser compitable flag variable.
   */
  public isCompatible = true;


  /**
   * Agora rtmclient.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private rtmClient: any;

  /**
   * Agora rtcclient.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private rtcClient: any;

  /**
   * Agora appid.
   */
  private agoraAppId: string;

  /**
   * Rtc client detail.
   */
  private rtcClientDetail: RtcClient;

  /**
   * Rtm clent detail.
   */
  private rtmClientDetail: RtmClient;


  /**
   * Subscribe variable.
   */
  public streamAdded = new ReplaySubject();
  public streamSubscribed = new ReplaySubject();
  public peerLeave = new ReplaySubject();
  public muteAudio = new ReplaySubject();
  public unmuteAudio = new ReplaySubject();
  public muteVideo = new ReplaySubject();
  public unmuteVideo = new ReplaySubject();
  public volumeIndicator = new ReplaySubject();
  public error = new ReplaySubject<{ code: number, msg: string }>();


  /**
   * Required instances and initialize variables.
   */
  constructor(agoraAppId: string, rtcClientDetail: RtcClient, rtmClientDetail: RtmClient) {
    this.agoraAppId = agoraAppId;
    this.rtcClientDetail = rtcClientDetail;
    this.rtmClientDetail = rtmClientDetail;
    this.rtcClient = this.createAgoraRtcClient(rtcClientDetail);
    this.rtmClient = this.createAgoraRtmClient(agoraAppId, rtmClientDetail);
    this.initializeRtcListeners();
    this.getClientDetail();
  }


  /**
   * Get user attribute.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getUserAttributes(uid: string): Promise<any> {
    return this.rtmClient.getUserAttributes(uid);
  }

  /**
   * Get agora client detail.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private createAgoraRtcClient(rtcClientDetail: RtcClient): any{
    const client = AgoraRTC.createClient({
      mode: rtcClientDetail.mode ? rtcClientDetail.mode : 'live',
      codec: rtcClientDetail.codec ? rtcClientDetail.codec : 'vp8'
    });
    return client;
  }

  /**
   * Set role and join channel.
   */
  async setRoleAndJoin(): Promise<void> {
    const rtcClientDetail = this.rtcClientDetail;
    await this.rtcClient.setClientRole(rtcClientDetail.role ? rtcClientDetail.role : 'audience');

    await this.rtcClient
      .join(this.agoraAppId, rtcClientDetail.channel, rtcClientDetail.token ? rtcClientDetail.token : null,
        rtcClientDetail.uid ? rtcClientDetail.uid : null);
  }

  /**
   * Enable volume indicator.
   */
  enableAudioVolumeIndicator(): void {
    this.rtcClient.enableAudioVolumeIndicator();
  }

  /**
   * Get user count.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getUserCount(): any {
    return interval(1000)
      .pipe(map(() => {
        let userCount = 0;
        const stats = this.rtcClient.getRTCStats();
        userCount = stats.UserCount;
        return userCount;
      }));
  }

  /**
   * Login to RTM.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  loginToRTM(): any {
    return this.rtmClient.login({
      token: this.rtmClientDetail.token ?
        this.rtmClientDetail.token
        : null,
      uid: this.rtmClientDetail.uid
    });
  }

  /**
   * Get rtm client.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
  private createAgoraRtmClient(agoraAppId: string, rtmClientDetail: RtmClient): any {

    const client = AgoraRTM.createInstance(agoraAppId);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    client.on('ConnectionStateChanged', (newState, reason) => {
      // Connection State Changes
    });
    return client;
  }

  /**
   * Initialize listeners.
   */
  private initializeRtcListeners(): void {

    this.rtcClient.on('user-published', async (user, mediaType) => {
      await this.rtcClient.subscribe(user, mediaType);
      const id = user.uid;

      if (mediaType === 'video') {
        const remoteVideoTrack = user.videoTrack;
        setTimeout(() => {
          const element = document.getElementById('stream_' + id.toString());
          if (typeof (element) != 'undefined' && element != null) {
            remoteVideoTrack.play('stream_' + id.toString());
          }
        }, 1000);
      }
      if (mediaType === 'audio') {
        const remoteAudioTrack = user.audioTrack;
        remoteAudioTrack.play();
      }
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    this.rtcClient.on('user-left', (user, reason) => {

      this.peerLeave.next(user);
    });
    this.rtcClient.on('user-joined', (user) => {
      this.streamSubscribed.next(user);

    });
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    this.rtcClient.on('user-unpublished', (user, mediaType) => {
      // this.peerLeave.next(user);
    });

    // this.rtcClient.on('mute-audio', (evt) => {
    //   this.muteAudio.next(evt);
    // });

    // this.rtcClient.on('unmute-audio', (evt) => {

    //   this.unmuteAudio.next(evt);
    // });

    // this.rtcClient.on('mute-video', (evt) => {
    //   this.muteVideo.next(evt);
    // });

    // this.rtcClient.on('unmute-video', (evt) => {
    //   this.unmuteVideo.next(evt);
    // });

    this.rtcClient.on('user-info-updated', (uid, msg) => {


      if (msg === 'mute-audio') {
        this.muteAudio.next({ uid });
      } else if (msg === 'unmute-audio') {
        this.unmuteAudio.next({ uid });
      } else if (msg === 'mute-video') {
        this.muteVideo.next({ uid });
      } else if (msg === 'unmute-video') {
        this.unmuteVideo.next({ uid });
      }
    });


    this.rtcClient.on('volume-indicator', (result) => {
      this.volumeIndicator.next(result);
    });

    this.rtcClient.on('network-quality', (stats) => {
      if (stats.downlinkNetworkQuality === 4) {
        this.networkQualityMsg = 'Users can communicate only not very smoothly';
      } else if (stats.downlinkNetworkQuality === 5) {
        this.networkQualityMsg = 'The network is so bad that users can hardly communicate';
      } else if (stats.downlinkNetworkQuality === 6) {
        this.networkQualityMsg = ' The network is down and users cannot communicate at all';
      } else {
        this.networkQualityMsg = '';
      }
    });
  }

  /**
   * Check compatibility for client system.
   */
  getClientDetail(): void {
    this.isCompatible = AgoraRTC.checkSystemRequirements();
  }

  /**
   * Leave Rtc client.
   */
  stopAgoraService(): void {
    this.rtcClient.leave();
  }
}
