import { Component, Input, Output, EventEmitter } from '@angular/core';
/**
 * Services import.
 */
import { MultiLangService } from '../../dataservices/master-service/multi-lang.service';
import { MasterserviceService } from '../../dataservices/master-service/masterservice.service';
import { NewLanguageService } from '../../dataservices/new-language/new-language.service';
import { FunCollectionService } from './../common-functions/fun-collection.service';
import { AnalyticsEventService } from '../../analytics-event/analytics-event.service';

/**
 * Router Import.
 */
import { Router } from '@angular/router';

/**
 * Modal imports.
 */
import { AnalyticsInterface } from '../../analytics-event/analytics.model';

/**
 * Constant imports.
 */
import { SCREEN_NAME, PRODUCT_TYPE_CONSTANT } from '../../../app/constants/app.constants';
import { EVENT_NAME } from '../../analytics-event/analytics.constant';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent {
 @Output() resetConfirmModal = new EventEmitter();
 @Input() isConfirmModalShown: boolean;
 @Input() confirmInfo: string;

 @Output() emitConfirm = new EventEmitter();
 /**
  * Contains cancel button and confirm button text
  */
 @Input() deleteConfirm = {cancelBtnText: '', confirmBtnText: ''};
 /**
  * Confirmation action.
  */
 public confirmAction = false;
 /**
  * For using analytics interface
  */
   public analyticsAcceptData: AnalyticsInterface;
 /**
  * For storing value of connect data
  */
   public analyticsRejectData: AnalyticsInterface;
 /**
  * Analytics on visit event.
  */
  public analyticsVisitData: AnalyticsInterface;

  /**
   * Input used to show delete profile modal from boolean value.
   */
  @Input() isDeleteAccountConfirmModalShown: boolean;
  /**
   * Withdraw community join request.
   * Flag to show Delete feed confirmation modal
   */
  @Input() isWithdrawRequestConfirmModalShown: boolean;
  @Input() isDeleteCommunityPostModalShown: boolean;
  /**
   * Content of modal.
   */
  @Input() confirmationModalContent = { headingText: '', cancelButtonText: '', allowButtonText: '', bodyText: '' };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() getListItem: any;
  /**
   * Emit allow or accept button.
   * Emit delete community feed action
   */
  @Output() emitAllowAction: EventEmitter<boolean> = new EventEmitter();
  @Output() emitDeleteCommunityFeed: EventEmitter<boolean> = new EventEmitter();
  /**
   * Input used to navigate anywhere from the component where it is called.
   */
  @Input() navigateToRoute = new EventEmitter();
  /**
   * Input used to get community id.
   */
  @Input() communityId: number;

  constructor(
    public multiLangService: MultiLangService,
    public masterService: MasterserviceService,
    public mls: NewLanguageService,
    public funCollectionService: FunCollectionService,
    public router: Router,
    private analyticsEventService: AnalyticsEventService) { }

  /**
   * Function used to reset the confirm box by emit action.
   */
  resetConfirmBox(): void {
    this.resetConfirmModal.emit(this.confirmAction);
  }

  ActivateAction(): void {
    this.confirmAction = true;
    this.resetConfirmBox();
    this.isConfirmModalShown = false;
    this.analyticsVisitData = {
      'event_name': EVENT_NAME.COMMUNITY_REQUEST_REJECT_CLICK,
      'product_type': PRODUCT_TYPE_CONSTANT.COMMUNITY,
      'product_type_id': this.communityId,
      'screen_name': SCREEN_NAME.COMMUNITY_DETAILS,
    };
    this.analyticsEventService.logAnalyticsEvent(this.analyticsVisitData);
  }

  showModal(): void {
    this.isConfirmModalShown = true;
    this.isDeleteAccountConfirmModalShown = true;
    this.isWithdrawRequestConfirmModalShown = true;
  }

  hideModal(): void {
    this.isConfirmModalShown = false;
    this.isDeleteAccountConfirmModalShown = false;
    this.isWithdrawRequestConfirmModalShown = false;
    this.isDeleteCommunityPostModalShown = false;
    this.resetConfirmBox();
  }

  onHidden(): void {
    this.isConfirmModalShown = false;
    this.isDeleteAccountConfirmModalShown = false;
    this.isWithdrawRequestConfirmModalShown = false;
    this.resetConfirmBox();
  }

  /**
   * Allow or Accept action emit.
   */
  allowAction(request): void {
    if (request) {
      this.emitAllowAction.emit(true);
      this.funCollectionService.communityWithDrawStatus(request);
    }
  }

  /**
   * Navigate function to change the route from the component.
   */
  navigateTo(): void {
    this.emitConfirm.emit();
    this.router.navigate([this.navigateToRoute]);
  }

  /**
   * confirm delete feed action from community.
   */
  deleteCommunityPostConfirmation(): void {
    this.emitDeleteCommunityFeed.emit(true);
  }
}
