    <div class="d-flex flex-row m-0 profile-detail">
        <div class="float-left mr-2">
            <div class="position-relative user-42">
                <img [src]="userdata?.profile_pic" class="profile-pic"
                    onerror="this.src='assets/themes/icons/user-profile-placeholder.svg'">
                <div class="position-absolute profile-badge-icon">
                    <img *ngIf="userdata?.user_profile?.permission == 'readonly'" src="assets/themes/icons/unverifiedsmall.svg" alt="unverified">

                    <img *ngIf="userdata?.user_profile?.permission != 'readonly'" width="13px"
                        src="assets/themes/icons/verified-profile-icon.svg" alt="verified" />
                </div>
            </div>
        </div>
        <div class="float-left d-flex align-items-center profile-content-width">
            <!-- <div id="comment-1" class="d-block w-100" style=""> -->
                <div class="position-relative">
                    <p><span><span class="paragraph-bold mr-2 pr-1">
                                <span class="color-grey-700">{{userdata?.full_name}}</span></span> 
                                <span class="Accepted_text"><span class="dot-creation"></span>
                                <span class="ml-3 secondary-paragraph" *ngFor="let spe of userdata?.specialities">
                                    <span class="color-grey-500">{{(spe?.is_primary==1)? spe?.speciality_name:''}}
                                    </span></span>
                        </span></span>
                    </p>
                </div>
        </div>
    </div>