/**
 * Angular imports.
 */
import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

/**
 * Service imports.
 */
import { NewLanguageService } from './../../../dataservices/new-language/new-language.service';
import { OnboardingService } from '../../../dataservices/onboarding/onboarding.service';
import { MasterserviceService } from '../../../dataservices/master-service/masterservice.service';
import { DataStorageService } from '../../../dataservices/data-storage.service';
import { JwtDecodeService } from '../../../dataservices/jwt-decode.service';

/**
 * Rxjs imports.
 */
import { Subscription } from 'rxjs';

/**
 * This component used to certified the doctor or dentist through MRN.
 */
@Component({
  selector: 'app-upload-mrn',
  templateUrl: './upload-mrn.component.html',
  styleUrls: ['./upload-mrn.component.scss']
})
export class UploadMrnComponent implements OnInit, OnDestroy {
  /**
   * Hold can Deatctivate flag
   */
  public canDeactive = true;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public req_data: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public verify_mobile: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public verify_data: any;
  public err_msg = '';
  public api_err_msg = '';
  public api_in_process = false;
  public showColor = false;
  public user_input_json =
    [{
      'key': 'verify_user',
      'value': { 'mrn': '', 'document_url': '' }
    }];
  public claim_data = { 'key': '', 'value': '' };
  public isConfirmModalShown = false;

  /**
   * To unsubscribe data.
   */
  private subscriptions: Subscription = new Subscription();


  /**
   * Necessary instances.
   */
  constructor(
    public route: ActivatedRoute,
    private jwtSer: JwtDecodeService,
    private masterService: MasterserviceService,
    public Dss: DataStorageService,
    private router: Router,
    public onboardingService: OnboardingService,
    public mls: NewLanguageService,
    private elementRef: ElementRef) { }

  /**
   * ngOnInit
   */
  ngOnInit(): boolean {
    setTimeout(() => {
      const carouselPrev = (this.elementRef.nativeElement.querySelectorAll('.carousel-control-prev'));
      carouselPrev.forEach(el => el?.classList?.add('crsl-w'));
      const carouselNext = (this.elementRef.nativeElement.querySelectorAll('.carousel-control-next'));
      carouselNext.forEach(el => el?.classList?.add('crsl-w'));
    }, 100);

    if (sessionStorage.getItem('req_data') != null) {
      this.req_data = JSON.parse(sessionStorage.getItem('req_data'));
    }
    if (sessionStorage.getItem('invite_process') == null) {
      this.router.navigate(['/login/input-mobile'], {
        queryParams: {
          redirectUrl: this.route.snapshot.queryParams.redirectUrl
        }
      });
      return false;
    } else {
      this.verify_mobile = JSON.parse(sessionStorage.getItem('invite_process'));

      if (this.verify_mobile.status == 1) {
        this.verify_data = this.verify_mobile.data;
        if (this.verify_data.key != undefined) {
          this.claim_data.key = this.verify_data.key;

          if (this.verify_data.title == '') {
            this.verify_data.title = this.mls.languageText.upload_mrn.key9;
          }
          if (this.verify_data.hint == '') {
            this.verify_data.hint = this.mls.languageText.upload_mrn.key10;
          }
        }


      }
    }
    this.canDeactive = false;
  }

  /**
   * back screen
   */
  back_screen(): void {
    this.isConfirmModalShown = true;
  }

  /**
   * user Input
   */
  userInput(event): void {
    if (event.which == 13) { return; } else {
      this.err_msg = '';
      const value = event.target.value;
      if (value.trim() != '' && value.trim() != undefined) {
        this.showColor = true;
        this.user_input_json[0].value.mrn = value.trim();
      } else {
        this.showColor = false;
        this.user_input_json[0].value.mrn = '';
      }
    }
  }

  /**
   * invite Input
   */
  inviteInput(e): void {
    if (e.which == 13) {
      this.userRegister();
      return;
    }
  }

  /**
   * user Register
   */
  userRegister(): boolean {
    this.err_msg = '';
    this.api_err_msg = '';
    const val = this.elementRef.nativeElement.querySelector('#registration_no').value;
    this.claim_data.value = val;
    if (this.claim_data.value == '') {
      this.err_msg = this.mls.languageText.upload_mrn.key11;
      return;
    }
    if (this.verify_data.key == 'email') {
      const email = this.elementRef.nativeElement.querySelector('#registration_no').value;
      // eslint-disable-next-line no-useless-escape
      const regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      if (regex.test(email) == false) {
        this.err_msg = this.mls.languageText.upload_mrn.key12;
        return;
      }
    }
    if (this.masterService.isOnline == false) {
      this.masterService.isConnectionModalShown.next('1');
      return false;
    }
    this.api_in_process = true;
    this.subscriptions.add(this.onboardingService.verifyInviteProcess(this.req_data.token_id, 'invite_process_submit', '', JSON.stringify(this.claim_data)).subscribe(resData => {
      this.api_in_process = false;
      this.jwtSer.decodeData(resData).then(data => {
        if (data['status'] == 1) {
          this.canDeactive = true;
          if (data['code'] == 2006) {
            sessionStorage.setItem('verify_mobile', JSON.stringify(data));
            localStorage.setItem('verify_mobile', JSON.stringify(data));

            if (data['data'].screen_data.identifier == 'select_profession') {
              sessionStorage.setItem('select_profession', JSON.stringify(data));
              this.router.navigate(['/login/usertype'], {
                queryParams: {
                  redirectUrl: this.route.snapshot.queryParams.redirectUrl
                }
              });
            }
            if (data['data'].screen_data.identifier == 'personal_information') {
              sessionStorage.setItem('personal_information', JSON.stringify(data));
              this.onboardingService.directToUserdetails = true;
              this.router.navigate(['/login/userdetail'], {
                queryParams: {
                  redirectUrl: this.route.snapshot.queryParams.redirectUrl
                }
              });
            }
            if (data['data'].screen_data.identifier == 'verify_user') {
              this.router.navigate(['/login/verify'], {
                queryParams: {
                  redirectUrl: this.route.snapshot.queryParams.redirectUrl
                }
              });
            }
            if (data['data'].screen_data.identifier == 'select_speciality') {
              // No Code
            }
            if (data['data'].screen_data.identifier == 'select_university') {
              sessionStorage.setItem('select_university', JSON.stringify(data));
              this.router.navigate(['/login/university'], {
                queryParams: {
                  redirectUrl: this.route.snapshot.queryParams.redirectUrl
                }
              });
              return;
            }
          }
        } else if (data['status'] == 0) {
          if (data['error'].code == 1005) {
            this.api_err_msg = data['error'].msg;
            return;
          }
        }
      },
      () => {
        this.api_in_process = false;
      }
      );
    }
    ));
  }

  /**
   * Used to show confirmation modal and no internet popup
   */
  canDeactivate(): Promise<boolean> | boolean {
    if (this.masterService.isOnline == false) {
      this.masterService.isConnectionModalShown.next('1');
      return;
    }
    this.isConfirmModalShown = true;
    return this.canDeactive;
  }

  /**
   * Used to get the click events from confirmation modal.
   */
  resetConfirmModal(cnfrm_act): void {
    this.canDeactive = cnfrm_act;
    this.isConfirmModalShown = false;
  }

  /**
   * unsubscribe the subscription.
   */
  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
}
