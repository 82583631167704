/**
 * Angular imports.
 */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

/**
 * Environment imports.
 */
import { environment } from '../../../environments/environment';

/**
 * Service imports.
 */
import { DeviceDetectorService } from 'ngx-device-detector';

/**
 * Rxjs imports.
 */
import { Observable } from 'rxjs';

/**
 * Constant imports.
 */
import { API } from '../../constants/api.constants';

/**
 * Service import.
 */
import { AuthService } from '../../auth/auth.service';
import { MobileWebViewCommunicationService } from '../mobileWebViewCommunication/mobile-web-view-communication.service';

/**
 * Analytics service.
 */
@Injectable({
  providedIn: 'root'
})
export class DocquityAnalyticsService {

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public set_time: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public event_session_id: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public all_event: any[] = [];
  public device_info = {
    'app_version': environment.versions.appVersion,
    'operating_system': '',
    'device_resolution': '',
    'device_model': '',
    'device_manufacturer': 'motorola',
    'app_identifier': 'F6E9184D2629D4E7B996E6E3B5312'
  };

  /**
   * Object used to send in SQS queue for device information
   */
  public sqsDeviceInfo = {
    // tslint:disable-next-line: deprecation
    'OS': navigator.appVersion,
    'app_version': environment.versions.appVersion,
    'operating_system': '',
    'device_resolution': '',
    'device_model': '',
    'device_manufacturer': '',
    'app_identifier': '',
    'device_type': 'web',
    'os_version': 'web-1'
  };

  /**
   * Used to hold the lang.
   */
  public lang = '';

  /**
   * Used to hold the userauthkey.
   */
  public token: string='';

  /**
   * necessary instances.
   */
  constructor(
    private deviceService: DeviceDetectorService,
    private httpClient: HttpClient,
    private authService: AuthService,
    private mvc: MobileWebViewCommunicationService
  ) {
    const d = new Date();
    this.set_time = d.getTime();
    this.init_device_info();
    this.lang = (localStorage.getItem('lang')) ? localStorage.getItem('lang') : 'en';
    this.token = this.authService.getToken();
  }

  /**
   * init device info
   */
  init_device_info(): void {
    const info = this.deviceService.getDeviceInfo();
    const innerHeight = (window.screen.height);
    const innerWidth = (window.screen.width);
    this.device_info = {
      'app_version': this.mvc.checkAuthenticByPass() ? this.mvc.getAnalyticData()?.appVersion : environment.versions.appVersion,
      'operating_system': this.mvc.checkAuthenticByPass() ? this.mvc.getAnalyticData()?.operatingSystem : info['os'],
      'device_resolution': this.mvc.checkAuthenticByPass() ? this.mvc.getAnalyticData()?.deviceResolution : (innerWidth + ' * ' + innerHeight),
      'device_model': this.mvc.checkAuthenticByPass() ? this.mvc.getAnalyticData()?.deviceModel : '',
      'device_manufacturer': this.mvc.checkAuthenticByPass() ? this.mvc.getAnalyticData()?.deviceManufacturer : '',
      'app_identifier': this.mvc.checkAuthenticByPass() ? this.mvc.getAnalyticData()?.appIdentifier : 'web'
    };
    console.log('this.device_info', this.device_info);
    /**
     * Initialize values in device info to send in sqs object
     */
    this.sqsDeviceInfo = {
      // tslint:disable-next-line: deprecation
      'OS': navigator.appVersion,
      'app_version': environment.versions.appVersion,
      'operating_system': info['os'],
      'device_resolution': innerWidth + ' * ' + innerHeight,
      'device_model': 'web',
      'device_manufacturer': 'web',
      'app_identifier': 'web',
      'device_type': 'web',
      'os_version': 'web-1'
    };

    this.setSessionId();
  }

  /**
   * set SessionId
   */
  setSessionId(): void {
    const d = new Date();
    this.event_session_id = 'web-' + d.getTime() + this.makenumericid();
    sessionStorage.setItem('event_session_id', this.event_session_id);
  }

  /**
   * docquity Analytics
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  docquityAnalytics(records): Observable<any> {
    const dataa = { device_info: this.device_info, event: records };
    const body = { 'records': JSON.stringify(dataa) };
    const headers = { 'deviceType': this.mvc.getAnalyticData()?.deviceType ||'' };

    return this.httpClient.post(API.ANALYTIC_RECORDS,body, {
      headers,
    });
  }

  xhrCall(records): void {
    const data = { device_info: this.device_info, event: records };
    const body = { 'records': JSON.stringify(data) };
    const endpoint =  API.ANALYTIC_RECORDS;

    const xhr = new XMLHttpRequest();
    xhr.open('POST', endpoint, false);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('Authorization', environment.appKey);
    xhr.setRequestHeader('appversion', environment.versions.appVersion);
    xhr.setRequestHeader('devicetype', environment.deviceType);
    xhr.setRequestHeader('lang', this.lang);
    xhr.setRequestHeader('udid', environment.udid);
    xhr.setRequestHeader('userauthkey', this.token);
    xhr.setRequestHeader('ver', environment.versions.api_version);
    xhr.send(JSON.stringify(body));
  }

  /**
   * make numeric id
   */
  makenumericid(): string {
    let text = '';
    const possible = '0123456789';
    for (let i = 0; i < 10; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }
}
