/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Angular imports.
 */
import { Pipe, PipeTransform } from '@angular/core';

/**
 * Samvaad imports.
 */
import { Drafty } from '@samvaad/client';
@Pipe({
  name: 'linebreaks'
})
export class LinebreaksPipe implements PipeTransform {

  transform(value: any): any {
    if (Drafty.format(value, this.fullFormatter, this)) {
      const formattedText = Drafty.format(value, this.fullFormatter, this);
      if (Array.isArray(formattedText)) {
        const para = document.createElement("div");
        const node = document.createTextNode(formattedText.join(''));
        para.appendChild(node);
        return para.innerText;
      } else {
        return formattedText;
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
  fullFormatter(style, data, values, key): any {
    if (!style) {
      return values;
    }
    let el = Drafty.tagName(style);
    switch (style) {
      case 'BR':
        values = '\n';
        break;
      case 'LN':
        values = data ? values[0] : null;
        break;
      case 'EX':
        // Ignore.
        break;
      case 'HL':
        break;
      case 'HD':
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        el = null;
        values = null;
        break;
    }
    return values;
  }
}
