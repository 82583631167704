/**
 * Angular imports.
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

/**
 * Module imports.
 */
import { DirectivesModule } from './../../directives/directives.module';
import { NgbModalModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';

/**
 * Component imports.
 */
import { NgbDropDownComponent } from './ngb-drop-down.component';



@NgModule({
  declarations: [
    NgbDropDownComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    DirectivesModule,
    NgbModalModule,
    NgbModule
  ],
  exports: [NgbDropDownComponent]
})
export class NgbDropDownModule { }
