/**
 * Angular imports.
 */
import { Injectable } from '@angular/core';
/**
 * Rxjs imports.
 */
import { Observable, of } from 'rxjs';
import { catchError, mergeMap, take } from 'rxjs/operators';
import { ProfileDetailData } from '../onboarding/interface/apiResponse.model';

/**
 * Service imports.
 */
import { MasterserviceService } from '../dataservices/master-service/masterservice.service';
import { AuthService } from './auth.service';
import { AccessTokenService } from './access-token.service';
import { MobileWebViewCommunicationService } from '../dataservices/mobileWebViewCommunication/mobile-web-view-communication.service';

/**
 * Auth Guard.
 */
@Injectable({
  providedIn: 'root'
})
export class AuthGuard {

  private uuid: string;
  /**
   * Necessary instances.
   */
  constructor(
    private masterService: MasterserviceService,
    private authService: AuthService,
    private accessTokenService: AccessTokenService,
    private mvc: MobileWebViewCommunicationService) { }
  canActivate(): Observable<boolean> {
    const custome_id = this.authService.getbyName('profile_obj');
    this.accessTokenService.getTokenDetailsFromStorage();
    const token = this.accessTokenService.token?.at;
    const parseJwt = (tkn) => {
      try {
        return JSON.parse(atob(tkn.split('.')[1]));
      } catch (e) {
        return null;
      }
    };
    if(token && (parseJwt(token)) && (parseJwt(token)).uuid) {
      this.uuid = (parseJwt(token)).uuid;
    }


    if(!this.mvc.externalSurveyDetail) {
      const accessToken$ = this.accessTokenService.isAccessTokenAvailable();
      if(accessToken$) {
        accessToken$.subscribe(
          () =>{
            this.accessTokenService.tokenRequest$ = undefined;
          }
        );
      }
    }
    if (this.mvc.checkAuthenticByPass()) {
      return of(true);
    }
    return this.masterService.getProfileDetail(custome_id, this.uuid)
      .pipe(
        take(1),
        mergeMap((profile: ProfileDetailData) => {
          if (profile.status === 1 && profile.code === 2000) {
            this.masterService.setProfile(profile);
            localStorage.setItem('country_code', profile?.data?.profile?.country_code);
            localStorage.setItem('userCUstomId', profile?.data?.profile?.custom_id);
            this.mvc.isAuthenticByPass = false;
            this.mvc.updateTokenRequest$.next(true);
            return of(true);
          } else {
            if(this.mvc.externalSurveyDetail) {
              return of(true);
            }
             this.authService.setLogoutRequest({callAPI: false, reload: true});
            return of(false);
          }
        }),
        catchError(() => {
          return of(false);
        })
      );
  }

}
