/**
 * Angular imports.
 */
import { Component, OnInit, Input } from '@angular/core';
/**
 * Service imports.
 */
import { FunCollectionService } from '../../common/common-functions/fun-collection.service';
import { NewLanguageService } from '../../dataservices/new-language/new-language.service';
import { OpenAccessService } from '../open-access.service';


/**
 * Constant imports
 */
import { USER_ROLE } from '../../constants/app.constants';

/**
 * Component for displaying the public user role.
 */
@Component({
  selector: 'app-public-user-role',
  templateUrl: './public-user-role.component.html',
  styleUrls: ['./public-user-role.component.scss']
})
export class PublicUserRoleComponent implements OnInit {

  

  /**
   * Property to control the visibility of the user role popup.
   */
  public userRolePopup = true;

  public selectedUserRole = USER_ROLE;
  

  /**
   * Constructor to inject FunCollectionService.
   * @param fcs - Instance of FunCollectionService.
   */
  constructor(public fcs: FunCollectionService,
    public mls : NewLanguageService,
    public oas : OpenAccessService) { }

  /**
   * Lifecycle hook, called after the component is initialized.
   */
  ngOnInit(): void {
    console.log('blue');
    // this.userRolePopup = false;
  }
  
   submitUserRole() : void {
      this.oas.userRole ;
      console.log(this.oas.userRole,'978');
      // this.closePopUp();
      this.oas.businessPopUp = true;
   }

   selectUserRole(userRole: string): void {
    this.oas.userRole = userRole;
  }

  closePopUp() : void {
    this.userRolePopup = false;
  }
}
