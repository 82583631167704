<div  class="see-more responsive-min-width"
  (click)="isMetaState ? onGenerateMeta() : $event.preventDefault();$event.stopPropagation();" [ngClass]="{'feed-meta':callingFrom === 'FEED_POSTING' || callingFrom === 'CLINICAL_RESOURCE' || callingFrom === 'DOCTALK_REPOST' , 
  'certificate-meta': callingFrom === 'CERTIFICATE_REPOST' }">
  <span class="generated-meta-wrapper">
    <span  class="generated-meta" class="text-left">
      <div>
        <div class="d-flex" (click)="$event.preventDefault();$event.stopPropagation();onMeta(repostType.REPOST_AS_STORY)">
          <img class="height-fit-content" src="assets/images/repost-as-story.png">
          <div class="d-flex justify-content-between w-100">
            <div>
              <p class="paragraph">{{mls.languageText.stories_creation.key30}}</p>
              <span class="text-medium-grey primary-small">{{mls.languageText.stories_creation.key35}}</span>
            </div>
            <img src="assets/images/greater.svg" alt="icon">
          </div>
        </div>
        <div class="d-flex mt-3" (click)="$event.preventDefault();$event.stopPropagation();onMeta(repostType.REPOST_ON_FEED)">
          <img class="height-fit-content" src="assets/images/repost-as-story.png">
          <div>
            <p class="paragraph">{{mls.languageText.stories_creation.key28}}</p>
            <span class="text-medium-grey primary-small">{{mls.languageText.stories_creation.key36}}</span>
          </div>
          <img src="assets/images/greater.svg" alt="icon">
        </div>
      </div>    
    </span>
  </span>
</div>
