  <!-- Suggestion Section -->
  <div *ngIf="suggObj!=null && suggObj!=undefined && suggObj!='' && usercardsize=='m' ">
    <div class="row connectionCard">
      <div [ngClass]="{
        'col-12':(isECEnable && calling_from === callingFrom.CASE_GLOBAL_SEARCH) && ((this.mls.featureOnOffList.featureList.connectionButtonOnCard.value || this.mls.featureOnOffList.featureList.chatButtonOnCard.value)  || !mls.userLoginFlow),
        'col-6 col-md-6':((!(isECEnable && calling_from === callingFrom.CASE_GLOBAL_SEARCH)) && !(fcs.device_detail.is_mobile)) && ((this.mls.featureOnOffList.featureList.connectionButtonOnCard.value || this.mls.featureOnOffList.featureList.chatButtonOnCard.value)  || !mls.userLoginFlow),
        'col-8 pr-0':(!(isECEnable && calling_from === callingFrom.CASE_GLOBAL_SEARCH)) && (fcs.device_detail.is_mobile)}">
        <div class="d-flex align-items-center">
          <div class="float-left mr-3 user-40" [ngClass]="{'cursor-pointer': is_anonymous!=1}"
            (click)="navigateTouser(suggObj?.custom_id,suggObj?.navigate_channel,suggObj?.chat_id)"
            [eventTracker]='{"category":callingfrom,"action":mls.googleEventText.connection_card.key4,"product_type":pType.CONNECTION, "product_id":suggObj.custom_id}'>
            <div class="position-relative">
              <!-- image -->
              <app-user-pofile-pic [profile_pic_image]="connection_pic_image" [isStoryAvailable]="isStoriesAvailable()"></app-user-pofile-pic>
            </div>
          </div>
          <div class="float-left connCardContentWidth" [ngClass]="{'cursor-pointer': is_anonymous!=1}"
            [eventTracker]='{"category":callingfrom,"action":mls.googleEventText.connection_card.key4,"product_type":pType.CONNECTION, "product_id":suggObj.custom_id}'
            (click)="navigateTouser(suggObj?.custom_id ,suggObj?.navigate_channel,suggObj?.chat_id)">
            <div >
              <div>
                <div class="cardTextContent position-relative">
                  <p class="text-capitalize card-inner-heading paragraph">
                    <span class="font-weight-600">{{suggObj?.full_name || suggObj?.name}}
                      <span
                        *ngIf="(suggObj?.community_member_type === memberType?.ADMIN && suggObj?.custom_id === loginUserCustomeId)">
                        ({{mls.languageText.community.key51}})
                      </span>
                    </span>
                    <span
                      *ngIf="((suggObj?.connection_status==1  || this.removeacceptbutton==true) && calling_from!==componentCallingFrom.GLOBAL_SEARCH)">
                    <img src="assets/themes/icons/connected.svg" alt="connected" width="16"/>
                    </span>
                  </p>
                  <p class="secondary-paragraph card-inner-heading" *ngFor="let suggSpec of suggObj?.specialities;">
                    <span *ngIf="(suggSpec?.is_primary==1)" class="color-grey-500">{{suggSpec?.speciality_name}}
                    </span>
                  </p>
                  <p class="secondary-paragraph color-grey-500 card-inner-heading" *ngIf="suggObj?.is_community_creator">
                    <span>{{mls.languageText.community.key50}}</span>
                  </p>
                  <p class="secondary-paragraph color-grey-500 card-inner-heading" *ngIf="!(suggObj?.is_community_creator)">
                    <span
                      *ngIf="suggObj?.community_member_type === memberType?.ADMIN">{{mls.languageText.community.key53 |
                      titlecase}} </span>
                    <span
                      *ngIf="suggObj?.community_member_type === memberType?.MEMBER">{{mls.languageText.community.key54 |
                      titlecase}}</span>
                  </p>
                  <!-- channel -->
                  <p class="secondary-paragraph card-inner-heading"><span class="color-grey-500"
                      *ngIf="(suggObj?.channel_powered !='')">{{suggObj?.channel_powered}}
                      {{suggObj?.powered_name}}</span></p>

                  <p *ngIf="suggObj?.suggestion_based_on_text!=''" class="secondary-paragraph card-inner-heading">
                    <span class="color-grey-500">{{suggObj?.suggestion_based_on_text}}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- channel subscribe -->

      <div class="col-5 text-right align-self-center" [ngClass]="{'col-4 mobile-button-width': fcs.device_detail.is_mobile, 'col-6 col-md-6 desktop-button-width':!fcs.device_detail.is_mobile}"
        *ngIf="(suggObj?.custom_id !='not_show' && is_anonymous!=1) && calling_from !== callingFrom.CASE_GLOBAL_SEARCH && suggObj?.is_connect_enable === connectionEnable.ENABLE">

        <button
          (click)="fcs.stopEventPropagation(); connectionWithdraw(connectionStatus.WITHDRAW.ACTION,suggObj?.custom_id,connectionStatus.WITHDRAW.ACTION_TYPE);"
          [appAnalyticsEvent]="analyticsWithdraw" [class.inactiveLink]="withdrawisValid"
          *ngIf="(suggObj?.connection_status==9 ) && showbutton != false && callingfrom !=componentCallingFrom.LIKE_CARD && calling_from!==componentCallingFrom.GLOBAL_SEARCH"
          class="btn-design outline button float-right h-auto small" [ngClass]="{'p-1': fcs.device_detail.is_mobile}">
          {{mls.languageText.connection_card.key3}}
          <span *ngIf="mls?.is_lskey_display===1">(connection_card.key3)</span>
          <img *ngIf="reject_loader " class="conn-card-loader" src="assets/themes/icons/primary-loader.gif" alt="loader">
        </button>

        <span *ngIf="(suggObj?.connection_status=== connectionStatus.RECIVED.ACTION)">
          <span *ngIf="(accept_loader === true)" class="text-muted spinner-border spinner-border-sm "></span>
          <span *ngIf="accept_loader!== true"
            [eventTracker]='{"category":callingfrom,"action":mls.googleEventText.connection_card.key1,"product_type":pType.CONNECTION, "product_id":suggObj.custom_id}'
            (click)="connectionAccept(connectionStatus.ACCEPT.ACTION,suggObj?.custom_id,connectionStatus.ACCEPT.ACTION_TYPE);"
            class="cursor-pointer">
            <img class="conn-card-icon-shadow" src="assets/themes/icons/accept.svg" alt="accept">
          </span>
        </span>
        <span
          (click)="connectionReject(connectionStatus.REJECT.ACTION,suggObj?.custom_id,connectionStatus.REJECT.ACTION_TYPE);"
          [eventTracker]='{"category":callingfrom,"action":mls.googleEventText.connection_card.key2,"product_type":pType.CONNECTION, "product_id":suggObj.custom_id}'
          *ngIf="(suggObj?.connection_status=== connectionStatus.RECIVED.ACTION)" class="cursor-pointer px-2 conn-card-icon-shadow">
          <img class="conn-card-icon-shadow" src="assets/themes/icons/Reject.svg" alt="reject">
        </span>


        <!-- Only for displaying search result. -->
        <a (click)="fcs.stopEventPropagation(); connectionWithdraw(connectionStatus.WITHDRAW.ACTION,suggObj?.custom_id,connectionStatus.WITHDRAW.ACTION_TYPE);"
          [eventTracker]='{"category":callingfrom,"action":mls.googleEventText.connection_card.key3,"product_type":pType.CONNECTION, "product_id":suggObj.custom_id}'
          [class.inactiveLink]="withdrawisValid"
          *ngIf="((suggObj?.connection_status === connectionStatus.ADD.ACTION ) && showbutton != false && calling_from===componentCallingFrom.GLOBAL_SEARCH)"
          class="btn-design small outline mr-2" [ngClass]="{'p-1': fcs.device_detail.is_mobile}">
          <img src="assets/themes/icons/connection-withdraw-icon.svg" alt="Withdraw Connection Request" />
        </a>

        <button
          (click)="fcs.stopEventPropagation(); connectionAdd(connectionStatus.ADD.ACTION,suggObj?.custom_id,connectionStatus.ADD.ACTION_TYPE);"
          [appAnalyticsEvent]="analyticsRequestSent"
          *ngIf="(suggObj?.connection_status==0 && suggObj?.is_connect_enable===connectionEnable.ENABLE && this.buttonSent==undefined && loginUserCustomeId!=suggObj?.custom_id && calling_from!==componentCallingFrom.GLOBAL_SEARCH)  && (mls.featureOnOffList.featureList.connectionButtonOnCard.value || !mls.userLoginFlow)"
          class="btn-design outline float-right button small" [ngClass]="{'p-1': fcs.device_detail.is_mobile}">
          <img class="svgBottomMargin" src="assets/themes/icons/Connect.svg" alt="connect">
          <span  *ngIf="!(fcs.device_detail.is_mobile)">
            {{mls.languageText.connection_card.key9}}
            <span *ngIf="mls?.is_lskey_display===1">(connection_card.key9)</span>
          </span>
          <img *ngIf="finished" class="conn-card-loader" src="assets/themes/icons/primary-loader.gif" alt="loader">
        </button>

        <!-- Only for displaying search result. -->
        <a (click)="connectionAdd(connectionStatus.ADD.ACTION,suggObj?.custom_id,connectionStatus.ADD.ACTION_TYPE);"
          [eventTracker]='{"category":callingfrom,"action":mls.googleEventText.connection_card.key8,"product_type":pType.CONNECTION, "product_id":suggObj.custom_id}'
          *ngIf="(suggObj?.connection_status==0 && suggObj?.is_connect_enable===connectionEnable.ENABLE && this.buttonSent==undefined && loginUserCustomeId!=suggObj?.custom_id && calling_from===componentCallingFrom.GLOBAL_SEARCH) && (mls.featureOnOffList.featureList.connectionButtonOnCard.value || !mls.userLoginFlow)"
          class="btn-design small outline mr-2" [ngClass]="{'p-1': fcs.device_detail.is_mobile}">
          <img src="assets/themes/icons/send-request-icon.svg" alt="Send Connection Request" />
        </a>

        <a class="btn btn-design medium  filled-disabled" [ngClass]="{'small': fcs.device_detail.is_mobile}"
          *ngIf="this.showRejected==1 && calling_from !== componentCallingFrom.GLOBAL_SEARCH">{{mls.languageText.connection_card.key4}}<span
            *ngIf="mls?.is_lskey_display==1">(connection_card.key4)</span> </a>
        <a type="button" *ngIf="(loginUserCustomeId!=suggObj?.custom_id &&  suggObj?.chat_allow==1) && (mls.featureOnOffList.featureList.chatButtonOnCard.value || !mls.userLoginFlow)"
          [eventTracker]='{"category":callingfrom,"action":mls.googleEventText.connection_card.key6,"product_type":pType.CONNECTION, "product_id":suggObj.custom_id}'
          (click)="navigateToUserchat()"
          [ngClass]="{'p-1': fcs.device_detail.is_mobile, 'btn-design small outline p-1': (suggObj?.connection_status==9 || suggObj?.connection_status==1 ) && showbutton != false && callingfrom ==componentCallingFrom.LIKE_CARD}"
          class="mr-2 flex-shrink-0">
          <img
          [width]="(suggObj?.connection_status==9 || suggObj?.connection_status==1 ) && showbutton != false && callingfrom ==componentCallingFrom.LIKE_CARD ? 20 : 32"
          [height]="(suggObj?.connection_status==9 || suggObj?.connection_status==1 ) && showbutton != false && callingfrom ==componentCallingFrom.LIKE_CARD ? 20 : 32"
          [src]="(suggObj?.connection_status==9 || suggObj?.connection_status==1 ) && showbutton != false && callingfrom ==componentCallingFrom.LIKE_CARD ? 'assets/images/icons/chat-icon.svg' : 'assets/themes/icons/chat-button.svg'" alt="chat">
            <span
              *ngIf="(suggObj?.connection_status==9 || suggObj?.connection_status==1 ) && showbutton != false && callingfrom ==componentCallingFrom.LIKE_CARD"
              class="text-right primary-small ml-2">{{mls.languageText.connection_card.key7}}
            </span>
        </a>

        <div ngbDropdown class="d-inline-block flex-shrink-0"
          *ngIf="(showbutton!=false && callingfrom !=componentCallingFrom.LIKE_CARD && calling_from!==componentCallingFrom.GLOBAL_SEARCH)">
          <span id="dropdownBasic1" *ngIf="(this.removeacceptbutton==true || suggObj?.connection_status==1)"
            class="" ngbDropdownToggle>
            <img *ngIf="calling_from !== componentCallingFrom.GLOBAL_SEARCH" class="cursor-pointer" src="assets/themes/icons/kabab-menu-icon.svg" alt="view more">
          </span>
          <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="ngb-dropdown-list pt-2 pb-2">
            <div [eventTracker]='{"category":callingfrom,"action":mls.googleEventText.connection_card.key4,"product_type":pType.CONNECTION, "product_id":suggObj.custom_id}'
              (click)="navigateTouser(suggObj?.custom_id,suggObj?.navigate_channel,suggObj?.chat_id)"
              class="text-left list-hover pl-0 w-100 px-3 pb-2 cursor-pointer">{{mls.languageText.connection_card.key5}}<span
                *ngIf="mls?.is_lskey_display==1">(connection_card.key5)</span></div>
            <div *ngIf="this.mls.featureOnOffList.featureList.connectionButtonOnCard.value || !mls.userLoginFlow" (click)="fcs.stopEventPropagation(); connectionWithdraw(connectionStatus.WITHDRAW.ACTION,suggObj?.custom_id,connectionStatus.WITHDRAW.ACTION_TYPE);"
              class="text-left list-hover pl-0 w-100 px-3 pb-2 cursor-pointer"
              [eventTracker]='{"category":callingfrom,"action":mls.googleEventText.connection_card.key5,"product_type":pType.CONNECTION, "product_id":suggObj.custom_id}'>{{mls.languageText.connection_card.key6}}<span
                *ngIf="mls?.is_lskey_display==1">(connection_card.key6)</span></div>
          </div>
        </div>
        <span
          *ngIf="(showbutton!=false && calling_from===componentCallingFrom.GLOBAL_SEARCH && (suggObj?.connection_status==1 || this.removeacceptbutton==true))"
          class="mr-2">
          <img src="assets/img/already_connected.svg" alt="Already Connected" />
        </span>
      </div>
    </div>
  </div>

  <!-- Connection Request -->
  <div *ngIf="suggObj!=null && suggObj!=undefined && suggObj!='' && usercardsize=='s' ">
    <div class="row connectionCard">
      <div class="col-12 reqCardWrapper">
        <div class="d-flex align-items-center">
          <div class="float-left mr-3" [ngClass]="{'cursor-pointer': is_anonymous!=1}"
            [eventTracker]='{"category":callingfrom,"action":mls.googleEventText.connection_card.key4,"product_type":pType.CONNECTION, "product_id":suggObj.custom_id}'
            (click)="navigateTouser(suggObj?.custom_id)">
            <div class="position-relative">
              <!-- image -->
              <app-user-pofile-pic [isStoryAvailable]="isStoriesAvailable()" [profile_pic_image]="connection_pic_image"></app-user-pofile-pic>
            </div>
          </div>
          <div class="float-left connCardContentWidth" [ngClass]="{'cursor-pointer': is_anonymous!=1}"
            [eventTracker]='{"category":callingfrom,"action":mls.googleEventText.connection_card.key4,"product_type":pType.CONNECTION, "product_id":suggObj.custom_id}'
            (click)="navigateTouser(suggObj?.custom_id,suggObj?.navigate_channel,suggObj?.chat_id)">
            <div class="">
                <div>
                  <p class="text-capitalize paragraph card-inner-heading">
                    <span class="font-weight-600">{{suggObj?.full_name || suggObj?.name}}<span></span></span>
                    <span class="ml-2" *ngIf="(suggObj?.connection_status==1  || this.removeacceptbutton==true)">
                        <img src="assets/themes/icons/connected.svg" alt="connected" width="16" />
                    </span>
                  </p>
                  <p class="secondary-paragraph" *ngFor="let suggSpec of suggObj?.specialities;">
                    <span *ngIf="(suggSpec?.is_primary==1)" class="color-grey-500">{{suggSpec?.speciality_name}}
                    </span>
                  </p>
                  <p *ngIf="(suggObj?.suggestion_based_on_text!='')" class="secondary-paragraph">
                    <span class="color-grey-400">{{suggObj?.suggestion_based_on_text}}</span>
                  </p>
                </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-5 text-right pl-0 pt-2 reqCardButtonContainer"
        *ngIf="suggObj?.custom_id !='not_show' && is_anonymous!=1">
        <span *ngIf="suggObj?.connection_status==8" class="mr-1">
          <img *ngIf="accept_loader==true" width="24" src="assets/themes/icons/primary-loader.gif" alt="loader">
          <span *ngIf="accept_loader!=true"
            [eventTracker]='{"category":callingfrom,"action":mls.googleEventText.connection_card.key1,"product_type":pType.CONNECTION, "product_id":suggObj.custom_id}'
            (click)="connectionAccept(connectionStatus.ACCEPT.ACTION,suggObj?.custom_id,connectionStatus.ACCEPT.ACTION_TYPE);"
            class="cursor-pointer">
            <img class="conn-card-icon-shadow" src="assets/themes/icons/accept.svg" alt="accept">
          </span>
        </span>
        <span
          (click)="connectionReject(connectionStatus.REJECT.ACTION,suggObj?.custom_id,connectionStatus.REJECT.ACTION_TYPE);"
          [eventTracker]='{"category":callingfrom,"action":mls.googleEventText.connection_card.key2,"product_type":pType.CONNECTION, "product_id":suggObj.custom_id}'
          *ngIf="suggObj?.connection_status==8" class="cursor-pointer pl-2">
          <img class="conn-card-icon-shadow" src="assets/themes/icons/Reject.svg" alt="reject">
        </span>
        <button
          (click)="fcs.stopEventPropagation(); connectionWithdraw(connectionStatus.WITHDRAW.ACTION,suggObj?.custom_id,connectionStatus.WITHDRAW.ACTION_TYPE);"
          [eventTracker]='{"category":callingfrom,"action":mls.googleEventText.connection_card.key3,"product_type":pType.CONNECTION, "product_id":suggObj.custom_id}'
          [class.inactiveLink]="withdrawisValid"
          *ngIf="((suggObj?.connection_status==9 && withdrawbuttoncon==false) || this.buttonSent==1) && (this.mls.featureOnOffList.featureList.connectionButtonOnCard.value || !mls.userLoginFlow)"
          class="btn-design small outline mr-1 float-right button" [ngClass]="{'p-1': fcs.device_detail.is_mobile==true}">
          {{mls.languageText.connection_card.key3}}<span *ngIf="mls?.is_lskey_display==1">(connection_card.key3)</span>
          <img *ngIf="reject_loader " width="24" src="assets/themes/icons/primary-loader.gif" alt="loader">

        </button>
        <span [ngClass]="{'mobile-button-width': fcs.device_detail.is_mobile==true,'desktop-button-width': fcs.device_detail.is_mobile!=true}">
        <button
          (click)="fcs.stopEventPropagation(); connectionAdd(connectionStatus.ADD.ACTION,suggObj?.custom_id,connectionStatus.ADD.ACTION_TYPE);"
          [eventTracker]='{"category":callingfrom,"action":mls.googleEventText.connection_card.key8,"product_type":pType.CONNECTION, "product_id":suggObj.custom_id}'
          *ngIf="(suggObj?.connection_status==0 && suggObj?.is_connect_enable===connectionEnable.ENABLE && this.buttonSent==undefined && loginUserCustomeId!=suggObj?.custom_id) && (mls.featureOnOffList.featureList.connectionButtonOnCard.value || !mls.userLoginFlow)"
          class="btn-design small outline float-right mr-1 button" [ngClass]="{'p-1': fcs.device_detail.is_mobile==true}">

          <img class="svgBottomMargin" src="assets/themes/icons/Connect.svg" alt="connect">
          <span *ngIf="!(fcs.device_detail.is_mobile)"> {{mls.languageText.connection_card.key9}}<span
              *ngIf="mls?.is_lskey_display==1">(connection_card.key9)</span> </span>
          <span *ngIf="finished " class="text-muted spinner-border spinner-border-sm "></span>
        </button>
        </span>

        <button class="btn-design filled-disabled small mr-1" [ngClass]="{'p-1': fcs.device_detail.is_mobile==true}"
          *ngIf="(this.showRejected==1)">{{mls.languageText.connection_card.key4}}<span
            *ngIf="(mls?.is_lskey_display==1)">(connection_card.key4)</span></button>
        <a *ngIf="(loginUserCustomeId!=suggObj?.custom_id && suggObj?.connection_status!=8) && (mls.featureOnOffList.featureList.chatButtonOnCard.value || !mls.userLoginFlow)"
          [eventTracker]='{"category":callingfrom,"action":mls.googleEventText.connection_card.key6,"product_type":pType.CONNECTION, "product_id":suggObj.custom_id}'
          (click)="navigateToUserchat()"
          class="flex-shrink-0"
          [ngClass]="{'p-1': fcs.device_detail.is_mobile==true}">
          <img width="32" height="32" src="assets/themes/icons/chat-button.svg" alt="chat">
        </a>
        <div *ngIf="(suggObj?.connection_status==1  || this.removeacceptbutton==true)" ngbDropdown
          class="d-inline-block">
          <span id="dropdownBasic1" ngbDropdownToggle>
            <img class="cursor-pointer" src="assets/themes/icons/kabab-menu-icon.svg" alt="view more">
          </span>

          <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="ngb-dropdown-list  pt-2 pb-2">
            <div [eventTracker]='{"category":callingfrom,"action":mls.googleEventText.connection_card.key4,"product_type":pType.CONNECTION, "product_id":suggObj.custom_id}'
              (click)="navigateTouser(suggObj?.custom_id,suggObj?.navigate_channel,suggObj?.chat_id)"
              class="text-left list-hover pl-0 w-100 px-3 pb-2 cursor-pointer">{{mls.languageText.connection_card.key5}}<span
                *ngIf="mls?.is_lskey_display==1">(connection_card.key5)</span></div>
            <div *ngIf="this.mls.featureOnOffList.featureList.connectionButtonOnCard.value || !mls.userLoginFlow" (click)="fcs.stopEventPropagation(); connectionWithdraw(connectionStatus.WITHDRAW.ACTION,suggObj?.custom_id,connectionStatus.WITHDRAW.ACTION_TYPE);"
              [eventTracker]='{"category":callingfrom,"action":mls.googleEventText.connection_card.key5,"product_type":pType.CONNECTION, "product_id":suggObj.custom_id}'
              class="text-left list-hover pl-0 w-100 px-3 pb-2 cursor-pointer">{{mls.languageText.connection_card.key6}}<span
                *ngIf="mls?.is_lskey_display==1">(connection_card.key6)</span></div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <!-- new small -->
  <div class="" *ngIf="(suggObj!=null && suggObj!=undefined && suggObj!='' && usercardsize=='xs')">
    <div class="row m-0 connectionCard">
      <div class="col-12 pr-0 pl-0 newSmallCardWrapper" [ngClass]="{'cursor-pointer': is_anonymous!=1}"
        (click)="navigateTouser(suggObj?.custom_id,suggObj?.navigate_channel,suggObj?.chat_id)"
        [eventTracker]='{"category":callingfrom,"action":mls.googleEventText.connection_card.key4,"product_type":pType.CONNECTION, "product_id":suggObj.custom_id}'>
        <div>
          <div class="float-left mr-3 position-relative">
            <!-- image -->
            <app-user-pofile-pic [isStoryAvailable]="isStoriesAvailable()" [profile_pic_image]="connection_pic_image"></app-user-pofile-pic>
          </div>
          <div class="float-left pl-0 connCardContentWidth" [ngClass]="{'cursor-pointer': is_anonymous!=1}"
            (click)="navigateTouser(suggObj?.custom_id,suggObj?.navigate_channel,suggObj?.chat_id)">
            <div>
              <div>
                <div class="cardTextContent position-relative">
                  <p class="text-capitalize card-inner-heading paragraph">
                    <span class="font-weight-600">{{suggObj?.full_name || suggObj?.name}}<span></span></span>
                    <span class="ml-2" *ngIf="suggObj?.connection_status==1  || this.removeacceptbutton==true">
                      <img src="assets/themes/icons/connected.svg" alt="connected" width="16" />
                    </span>
                  </p>
                  <p class="secondary-paragraph card-inner-heading" *ngFor="let suggSpec of suggObj?.specialities;"><span
                      *ngIf="suggSpec?.is_primary==1"
                      class="color-grey-500">{{suggSpec?.speciality_name}}</span></p>
                  <p *ngIf="suggObj?.suggestion_based_on_text!=''"
                    class="secondary-paragraph card-inner-heading">
                    <span class="color-grey-400"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-4 px-0 cursor-pointer pt-2 text-right rightArrow"
        (click)="navigateTouser(suggObj?.custom_id,suggObj?.navigate_channel,suggObj?.chat_id)"
        [eventTracker]='{"category":callingfrom,"action":mls.googleEventText.connection_card.key4,"product_type":pType.CONNECTION, "product_id":suggObj.custom_id}'>
        <img src="assets/themes/icons/Vector.svg" alt="Right Arrow" />
      </div>
    </div>
  </div>


  <!-- new small box for event detail-->
  <div *ngIf="(suggObj !== null && suggObj !== undefined && suggObj !== '' && usercardsize === speakerList)">
    <div class="row m-0 connectionCard">
      <div class="col-12 pr-0 pl-0 newBoxCardWrapper" [ngClass]="{'cursor-pointer': is_anonymous !== 1}">
        <div>
          <div class="float-left mr-3 position-relative">
            <!-- image -->
            <app-user-pofile-pic [isStoryAvailable]="isStoriesAvailable()"  [profile_pic_image]="connection_pic_image"
              [usedFor]="speakerList"></app-user-pofile-pic>
            <!-- <div *ngIf="suggObj.profile_pic === ''"
              class="pic-size text-center rounded-circle text-white d-table-cell align-middle">
              hi
              <img src="assets/themes/icons/user-profile-placeholder.svg" alt="profile pic">
            </div> -->
          </div>
          <div class="float-left pl-0 connCardContentWidth" [ngClass]="{'cursor-pointer': is_anonymous !== 1}">
            <div class="d-table w-100 card-layout">
              <div class="d-table-cell align-middle">
                <div class="cardTextContent position-relative">
                  <span class="text-capitalize paragraph d-block">
                    <span class="font-weight-600">{{suggObj.full_name || suggObj.name}}</span>
                  </span>
                  <p class="secondary-paragraph">
                    <span class="color-grey-700">{{suggObj.speciality_name}}</span>
                  </p>
                  <p class="secondary-paragraph">
                    <span class="color-grey-500">{{suggObj.qualification}}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-4 px-0 cursor-pointer pt-2 text-right card-link">
        <img src="assets/themes/icons/grey-right-arrow.svg" alt="Right Arrow" />
      </div>
    </div>
  </div>


  <!-- new for grandround -->
  <!---####-->
  <div *ngIf="(suggObj!=null && suggObj!=undefined && suggObj!='' && usercardsize=='gr')">
    <div class="d-flex justify-content-between connectionCard">
      <div class="pr-0">
        <div [appAnalyticsEvent]="analyticsProfileData"
          [ngClass]="{'d-flex': posted_in}">
          <div class="float-left mr-2 profile-min-width" [ngClass]="{'cursor-pointer': is_anonymous!=1}"
            (click)="navigateTouser(suggObj?.custom_id)">
            <div class="position-relative mt-1">
              <!-- image -->
              <app-user-pofile-pic [isStoryAvailable]="isStoriesAvailable()" [profile_pic_image]="connection_pic_image">
              </app-user-pofile-pic>
            </div>
          </div>
          <div class="float-left pl-0 grandroundCardTextWidth"
            [ngClass]="{'cursor-pointer': is_anonymous!=1, 'connection-name': isEditorsChoicePost && !isAddVisible}"
            (click)="navigateTouser(suggObj?.custom_id,suggObj?.navigate_channel,suggObj?.chat_id)">
            <div class="d-table w-100">
              <div class="d-table-cell align-middle position-relative">
                <div class="position-relative">
                  <p>
                    <span>
                      <span class="text-capitalize secondary-paragraph-bold">
                        <span>{{suggObj?.full_name || suggObj?.name}}</span></span>
                      <span *ngIf="suggObj?.connection_status==1 || this.removeacceptbutton==true" class="ml-2">
                        <img  src="assets/themes/icons/connected.svg" alt="connected" width="16" />
                      </span><br *ngIf="!posted_in" />
                      <span *ngIf="(posted_in && !posted_in?.isAssociation)"
                        class="ml-1">
                        <span class="secondary-paragraph-bold"><span class="color-grey-500">{{mls.languageText.community.key142}} </span></span>
                        <span class="cursor-pointer secondary-paragraph-bold" (click)="openCommunityDetail($event, posted_in?.id)">
                           <span>{{posted_in?.name}}</span>
                        </span>
                        <span *ngIf="posted_in?.count > numbers.ZERO" class="secondary-paragraph-bold">
                          <span class="color-grey-500"> {{mls.languageText.community.key146}} </span>
                          <span class="cursor-pointer underline" (click)="openFeed($event)">
                          {{posted_in?.count}} {{mls.languageText.community.key145}}
                          </span>
                        </span>
                      </span>
                      <span *ngIf="(posted_in && posted_in?.isAssociation)"
                        class="ml-1">
                        <span class="secondary-paragraph-bold"><span class="color-grey-500">{{mls.languageText.community.key142}} </span></span>
                        <span class="cursor-pointer secondary-paragraph-bold underline" (click)="openFeed($event)">
                          <span>{{posted_in?.count}} {{posted_in?.name}}</span>
                        </span>
                        <span *ngIf="posted_in?.remainingCount" class="secondary-paragraph-bold">
                          <span class="color-grey-500"> {{mls.languageText.community.key146}} </span>
                          <span class="cursor-pointer underline" (click)="openFeed($event)"
                            *ngIf="(posted_in?.count > numbers.ZERO)">
                            {{mls.languageText.community.key145}}
                          </span>
                        </span>
                      </span>
                    </span>
                  </p>
                  <p *ngFor="let suggSpec of suggObj?.specialities;" class="d-inline-flex primary-small">
                    <span *ngIf="(suggSpec?.is_primary==1)" class="color-grey-500 ">
                      {{suggSpec?.speciality_name}}
                    </span>
                  </p>
                  <p class="d-inline-flex primary-small ml-2" *ngIf="(product?.flags?.is_show_time)">
                    <span class="text-capitalize">
                      <span class="d-inline-flex dotCreation color-grey-500"></span>
                      {{product?.date_of_creation | ageing}}
                    </span>
                  </p>
                  <p *ngIf="suggObj?.suggestion_based_on_text!=''"
                    class="secondary-paragraph">
                    <span class="color-grey-500">{{suggObj?.suggestion_based_on_text}}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--  -->
      </div>

      <div class="ml-2 text-right pl-0 pt-1 grandroundCardButtonContainer"
        *ngIf="(suggObj?.custom_id !='not_show' && is_anonymous!=1 && suggObj?.is_connect_enable===connectionEnable.ENABLE)"
        [ngClass]="{'d-flex justify-content-end align-items-center': isEditorsChoicePost && !(isUserNotConnected && isConnectEnable && !isAuthorPost), 'pad-rt-8':  isEditorsChoicePost && isAcceptVisible,'mobile-button-width':fcs.device_detail.is_mobile,'desktop-button-width':!(fcs.device_detail.is_mobile)}">
        <ng-container *ngIf="isEditorsChoicePost">
          <span class="ec-badge d-flex align-items-center justify-content-start" [ngClass]="{
              'dot-visible new-dot-visible': isAddVisible || isUserConnected,
              'con-visible new-con-visible': isUserNotConnected && isConnectEnable && !isAuthorPost,
              'accept-con-visible': isAcceptVisible,
              'anonymous-user': isAnonymousUser || !isConnectEnable}" title="{{this.dss.ecProductFeed.ecHeaderText}}"
            (click)="showTitle($event)">
            <img loading="lazy" class="ec-badge-icon" [src]='this.dss.ecProductFeed.icon' alt="ec badge icon" />
            <span class="ec-text ec-text-overflow ec-hide" [ngClass]="{'hide-ec-header-text': posted_in}">
              {{this.dss.ecProductFeed.ecHeaderText}}
            </span>
            <span [class]="{'ec-gap': isUserNotConnected && isConnectEnable && !isAuthorPost}"></span>
          </span>
        </ng-container>
        <span *ngIf="suggObj?.connection_status==8" class="mr-1">
          <img *ngIf="accept_loader==true" class="conn-card-loader" src="assets/themes/icons/primary-loader.gif" alt="loader">
          <span *ngIf="accept_loader!=true"
            [eventTracker]='{"category":callingfrom,"action":mls.googleEventText.connection_card.key1,"product_type":pType.CONNECTION, "product_id":suggObj.custom_id}'
            (click)="connectionAccept(connectionStatus.ACCEPT.ACTION,suggObj?.custom_id,connectionStatus.ACCEPT.ACTION_TYPE);"
            class="cursor-pointer mr-2">

            <img class="conn-card-icon-shadow" src="assets/themes/icons/accept.svg" alt="accept">
          </span>
        </span>
        <button [eventTracker]='{"category":callingfrom,"action":mls.googleEventText.connection_card.key8,"product_type":pType.CONNECTION, "product_id":suggObj.custom_id}'
          (click)="connectionAdd(connectionStatus.ADD.ACTION,suggObj?.custom_id,connectionStatus.ADD.ACTION_TYPE);"
          *ngIf="suggObj?.connection_status==0 && suggObj?.is_connect_enable===connectionEnable.ENABLE && this.buttonSent==undefined && loginUserCustomeId!=suggObj?.custom_id  && (this.mls.featureOnOffList.featureList.connectionButtonOnCard.value || !mls.userLoginFlow)"
          class="btn-design small outline mr-1 float-right button"
          [ngClass]="{'p-1': fcs.device_detail.is_mobile==true}">
          <img class="svgBottomMargin" src="assets/themes/icons/Connect.svg" alt="">
          <span *ngIf="!(fcs.device_detail.is_mobile)"> {{mls.languageText.connection_card.key9}}<span
              *ngIf="mls?.is_lskey_display==1">(connection_card.key9)</span>
          </span>
          <img *ngIf="finished" class="conn-card-loader" src="assets/themes/icons/primary-loader.gif" alt="loader">
        </button>

        <a class="btn border sent-btn mr-1" [ngClass]="{'primary-small': fcs.device_detail.is_mobile==true}"
          *ngIf="this.showRejected==1">{{mls.languageText.connection_card.key4}}<span
            *ngIf="mls?.is_lskey_display==1">(connection_card.key4)</span></a>

        <a type="button" *ngIf="(loginUserCustomeId!=suggObj?.custom_id && suggObj?.chat_allow==1 && (this.mls.featureOnOffList.featureList.chatButtonOnCard.value || !mls.userLoginFlow))"
          [eventTracker]='{"category":callingfrom,"action":mls.googleEventText.connection_card.key6,"product_type":pType.CONNECTION, "product_id":suggObj.custom_id}'
          (click)="navigateToUserchat()"
          [ngClass]="{'p-1': fcs.device_detail.is_mobile==true, 'btn-design small outline p-1': (suggObj?.connection_status==9 && suggObj?.is_connect_enable===connectionEnable.ENABLE) || (suggObj?.connection_status==1 && suggObj?.is_connect_enable===connectionEnable.ENABLE)}"
          class="mr-4 flex-shrink-0">

          <app-custom-tooltip *ngIf="tooltip_data?.length>0" [tooltip_data]="tooltip_data"
            [view_identifier]="'START_CHAT'" [position]="'left'"></app-custom-tooltip>

            <img [width]="(suggObj?.connection_status==9 && suggObj?.is_connect_enable===connectionEnable.ENABLE) || (suggObj?.connection_status==1 && suggObj?.is_connect_enable===connectionEnable.ENABLE) ? 20 : 32"
            [height]="(suggObj?.connection_status==9 && suggObj?.is_connect_enable===connectionEnable.ENABLE) || (suggObj?.connection_status==1 && suggObj?.is_connect_enable===connectionEnable.ENABLE) ? 20 : 32"
            [src]="(suggObj?.connection_status==9 && suggObj?.is_connect_enable===connectionEnable.ENABLE) || (suggObj?.connection_status==1 && suggObj?.is_connect_enable===connectionEnable.ENABLE) ? 'assets/images/icons/chat-icon.svg' : 'assets/themes/icons/chat-button.svg'" alt="chat">

            <span
              *ngIf="(suggObj?.connection_status==9 && suggObj?.is_connect_enable===connectionEnable.ENABLE) || (suggObj?.connection_status==1 && suggObj?.is_connect_enable===connectionEnable.ENABLE)">
              {{mls.languageText.connection_card.key7}}
              <span *ngIf="mls?.is_lskey_display==1">(connection_card.key7)</span>
            </span>
          </a>

        <div *ngIf="(suggObj?.connection_status==1  || removeacceptbutton==true  )" ngbDropdown
          class="d-inline-block z-index flex-shrink-0">
          <span id="dropdownBasic1" ngbDropdownToggle>
            <img class="cursor-pointer" src="assets/themes/icons/kabab-menu-icon.svg" alt="view more">
          </span>


          <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="ngb-dropdown-list  pt-2 pb-2"
          *ngIf="(suggObj?.connection_status==1  || removeacceptbutton==true )">
            <div (click)="navigateTouser(suggObj?.custom_id,suggObj?.navigate_channel,suggObj?.chat_id)"
              class="text-left list-hover pl-0 w-100 px-3 pb-2 cursor-pointer"
              [eventTracker]='{"category":callingfrom,"action":mls.googleEventText.connection_card.key4,"product_type":pType.CONNECTION, "product_id":suggObj.custom_id}'>{{mls.languageText.connection_card.key5}}<span
                *ngIf="mls?.is_lskey_display==1">(connection_card.key5)</span></div>
            <div *ngIf="this.mls.featureOnOffList.featureList.connectionButtonOnCard.value || !mls.userLoginFlow" (click)="fcs.stopEventPropagation(); connectionWithdraw(connectionStatus.WITHDRAW.ACTION,suggObj?.custom_id,connectionStatus.WITHDRAW.ACTION_TYPE);"
              class="text-left list-hover pl-0 w-100 px-3 pb-2 cursor-pointer"
              [eventTracker]='{"category":callingfrom,"action":mls.googleEventText.connection_card.key5,"product_type":pType.CONNECTION, "product_id":suggObj.custom_id}'>{{mls.languageText.connection_card.key6}}<span
                *ngIf="mls?.is_lskey_display==1">(connection_card.key6)</span></div>
          </div>


          <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="ngb-dropdown-list pt-2 pb-2"
            *ngIf="suggObj?.connection_status==9 ">
            <div (click)="navigateTouser(suggObj?.custom_id,suggObj?.navigate_channel,suggObj?.chat_id)"
              class="text-left list-hover pl-0 w-100 px-3  pb-2 cursor-pointer"
              [eventTracker]='{"category":callingfrom,"action":mls.googleEventText.connection_card.key4,"product_type":pType.CONNECTION, "product_id":suggObj.custom_id}'>{{mls.languageText.connection_card.key5}}<span
                *ngIf="mls?.is_lskey_display==1">(connection_card.key5)</span>
            </div>
          </div>
        </div>

        <!-- only time when we connect -->
        <div *ngIf="(showthreedotsongr==true || suggObj?.connection_status==8 || suggObj?.connection_status==9 ) "
          ngbDropdown class="d-inline-block m-right">

          <span id="dropdownBasic1" ngbDropdownToggle>
            <img class="cursor-pointer" src="assets/themes/icons/kabab-menu-icon.svg" alt="view more">
          </span>

          <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="ngb-dropdown-list  pt-3 pb-2">
            <div (click)="navigateTouser(suggObj?.custom_id,suggObj?.navigate_channel,suggObj?.chat_id)"
              [eventTracker]='{"category":callingfrom,"action":mls.googleEventText.connection_card.key4,"product_type":pType.CONNECTION, "product_id":suggObj.custom_id}'
              class="text-left list-hover pl-0 w-100 px-3 pb-2 cursor-pointer">{{mls.languageText.connection_card.key5}}<span
                *ngIf="mls?.is_lskey_display==1">(connection_card.key5)</span></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!---####-->
  <app-info-modal (resetInfoModal)=resetInfoModal() [isInfoModalShown]=isInfoModalShown
    [info_msg]=info_msg></app-info-modal>



  <!-- subscribe popup start**************************** -->
  <!-- <app-product-subscribe  *ngIf="showSubscribeModal==true" (resetProductSubscribeModal) = resetProductSubscribeModal($event) [showSubscribeModal]=showSubscribeModal [JournalData]="subscribe_obj"></app-product-subscribe>  -->

  <app-product-subscribe (backClicked)=backClicked() [showSubscribeModal]=showSubscribeModal
    [channelData]="subscribe_obj"></app-product-subscribe>

  <!-- subscribe popup end**************************** -->
