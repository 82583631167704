import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ImageGroupService {

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public imageGroup: any = {
                    fitin_90x90           : '90x90',
                    fitin_50x50           : '50x50',
                    fitin_211x211         : '211x211',
                    fitin_437x810         : '437x810',
                    fitin_254x600         : '254x600',
                    fitin_125x125         : '125x125',
                    fitin_40x40           : '40x40',
                    fitin_500x500         : '500x500',
                    fitin_196x206         : '196x206',
                    fitin_32x32           : '32x32',
                    fitin_20x20           : '20x20',
                    fitin_360x810         : '360x810',
                    fitin_110x110        : '110x110',
                    fitin_14x14           : '14x14',
                    fitin_70x70           : '70x70',
                    fitin_134x134         : '134x134',
                    fitin_143x376         : '143x376',
                    fitin_209x473         : '209x473',
                    fitin_152x172         : '152x172',
                    fitin_600x600         : '600x600',
                    fitin_320x320         : '320x320',
                    fitin_800x800         : '800x800',
                    fitin_1200x1200       : '1200x1200',
                    fitin_300x200         : '300x200',
                    fitin_3x2             : '3x2'
                  };




  constructor() {
    // No Data
  }
}
