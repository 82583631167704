    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-xs-12 col-sm-12 col-md-12">
                <div class="clearfix">
                    <div>
                        <div id="jounralView">
                            <!-- jounral 404 Start -->
                            <section class="error_404">
                                <div class="center-text">
                                    <h1>404</h1>
                                    <div class="message"><strong> Oops! </strong>
                                        <span class="color-grey-700">{{mls.We_are_but_the_page_you_were_looking_was_not_found}}</span>
                                    </div>
                                    <button (click)="fcs.navigateToHome()"
                                        class="btn-design medium filled">{{mls.Go_To_Home_Page}}
                                    </button>
                                </div>
                            </section>
                            <!-- jounral end 404 -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>