/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Angular imports.
 */
import { Pipe, PipeTransform } from "@angular/core";

/**
 * Sort the array with secondary value.
 */
@Pipe({
  name: "sortedWithSecondaryBy",
  pure: false
})
export class SortedWithSecondaryByPipe implements PipeTransform {
  transform(array: any, field: string, fieldSecondary: string, reverse: boolean): any[] {
    if (!Array.isArray(array) || !field || !fieldSecondary) {
      return;
    }
    array.sort((a: any, b: any) => {
      if (a[field] && a[field][fieldSecondary] && b[field] && b[field][fieldSecondary]) {
        if (reverse) {
          if (new Date(a[field][fieldSecondary]) > new Date(b[field][fieldSecondary])) {
            return -1;
          } else if (new Date(a[field][fieldSecondary]) < new Date(b[field][fieldSecondary])) {
            return 1;
          } else {
            return 0;
          }
        } else {
          if (a[field][fieldSecondary] < b[field][fieldSecondary]) {
            return -1;
          } else if (a[field][fieldSecondary] > b[field][fieldSecondary]) {
            return 1;
          } else {
            return 0;
          }
        }
      }
    });
    return array;
  }
}
