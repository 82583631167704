import { Component } from '@angular/core';
// new lanuguage
import {NewLanguageService} from '../../../dataservices/new-language/new-language.service';
// module
@Component({
  selector: 'app-left-section',
  templateUrl: './left-section.component.html',
  styleUrls: ['./left-section.component.scss']
})
export class LeftSectionComponent {

  constructor(public mls: NewLanguageService) { }

}
