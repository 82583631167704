import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'qBReplaceImageToken'
})
export class QBReplaceImageTokenPipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  qb_sessionResponse: any;
  constructor() {
    if (localStorage.getItem('sessionResponse')) {
      this.qb_sessionResponse = JSON.parse(localStorage.getItem('sessionResponse'));

    }
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transform(value: any): any {

    let token = '';
    if (this.qb_sessionResponse != undefined && value != undefined) {

      token = this.qb_sessionResponse.token;
      const n = value.indexOf('?token');
      value = value.substring(0, n);
      value = value + '?token=' + token;
      return value;
    } else {
      return value;
    }
  }

}
