/**
 * Angular imports.
 */
import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

/**
 * Modal imports.
 */
import { Config } from './config.model';

/**
 * Helper imports.
 */
import { KeyboardUtil } from './keyboard-util';

@Component({
  selector: 'app-public-user-otp-input',
  templateUrl: './public-user-otp-input.component.html',
  styleUrls: ['./public-user-otp-input.component.css']
})
export class PublicUserOtpInputComponent  {

}