    <div [ngClass]="fromCommentUpdate ? 'tag-user-suugestion-popover-update' : 'tag-user-suggestion-popover-container'" class="suggestion-scroll mb-1"  infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="500" (scrolled)="onUserScroll()"
    [scrollWindow]="false">
        <div class="asstn-list clearfix list-hover list-wrapper" *ngFor="let sugg of suggList; let last = last">
            <div [ngClass]="{'border-0': last}" class="sugg-border-bottom pt-2 pb-2 px-3">
            <label class="checkbox-container clearfix mb-0" (click)="saveTaggedUserData(sugg)">
                <div class="cnnction-rqst">
                    <div class="row">
                        <div class="col-2 pr-2 profile-pic-avatar">
                            <div class="position-relative box-size-40">
                                <app-user-pofile-pic 
                                    [profile_pic_image]="sugg?.connection_pic_image">
                                </app-user-pofile-pic>
                            </div>
                        </div>
                        <div class="col-10 pl-0  pr-0 text-left sugg-name-width">
                            <p class="cnnction-rqst-list-name lh-1 paragraph">
                                <span class="font-weight-600">{{sugg?.full_name}}</span><br />
                                <span class="secondary-paragraph" *ngFor="let SuggSpe of sugg?.speciality">
                                    <span class="font-weight-500">{{SuggSpe?.name}}</span></span>
                            </p>
                        </div>
    
                    </div>
                </div>
            </label>
            </div>
        </div>
        <div class='text-center'>
            <img *ngIf="!finished && offSet !== 1 " class="text-center" src="assets/themes/icons/primary-loader.gif" width="20" height="20" id="loadmore_post">
        </div>
    </div>