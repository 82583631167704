import { Component ,Input, OnDestroy } from '@angular/core';


/**
 * Rxjs imports.
 */
import { of, Subscription } from 'rxjs';
import { DOC_TALK_ENDED, NUMBERS } from '../../constants/app.constants';

/**
 * Video Series Modal Component
 */

@Component({
  selector: 'app-open-access-video',
  templateUrl: './open-access-video.component.html',
  styleUrls: ['./open-access-video.component.scss']
})
export class OpenAccessVideoComponent {

  @Input() videoSeriesData;
  @Input() errorMsgShaka = '';
  @Input() isSeries;
  /**
   * Index for changing the series video.
   * Autoplay flag to controls the series playing behavior.
   */
  public index = 0;
  public autoplay = true;

  /**
   * end flag
   */
  public isEnd: string = DOC_TALK_ENDED.NO;
  /**
   * To unsubscribe data.
   */
  private subscriptions: Subscription = new Subscription();
  public mediaUrl: string;
  
  /**
   * Necessary instances.
   */
  constructor(

  ) { }

  /**
   * Conversion of media url.
   */
  ngOnInit(): void {
    if(this.isSeries === NUMBERS.ZERO){
      this.mediaUrl = this.videoSeriesData.media.media_url;
    }else {
      this.mediaUrl = this.videoSeriesData[this.index].media.media_url;
    }
  }

  /**
   * Video Series Modal Component
   */
  nextVideo(): void {  
    if (this.index < this.videoSeriesData.length - 1) {
      this.index = this.index + 1;
      this.mediaUrl = this.videoSeriesData[this.index].media.media_url;
    }
  }


  /**
   * onHidden
   */
  onHidden(): void {
    this.videoSeriesData.isShow = false;
  }



  /**
   * unsubscribe the subscription.
   */
  ngOnDestroy(): void {
  }
}
