/**
 * Angular imports.
 */
import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { HttpBackend, HttpClient } from '@angular/common/http';

/**
 * Service imports.
 */
import { DataStorageService } from '../dataservices/data-storage.service';
import { PostApiService } from '../dataservices/post-api.service';

/**
 * Rxjs imports.
 */
import { forkJoin, Subject } from 'rxjs';

/**
 * JS imports.
 */
import * as PDFJS from 'pdfjs-dist/build/pdf';
PDFJS.GlobalWorkerOptions.workerSrc = 'pdf.worker.js';

/**
 * Constant imports.
 */
import { API } from '../constants/api.constants';
import { environment } from '../../environments/environment';
import { CHUNKS_DIGITS, CHUNK_STATUS, FILES_EXTENSIONS, MEDIA_TYPE, PRODUCT_TYPE_CONSTANT, PROGRESS_STATUS, PROGRESS_WIDTH } from '../constants/app.constants';

/**
 * Upload file With ForkService.
 */
@Injectable({
  providedIn: 'root'
})
export class UploadfileWithForkService {
  public temp_post_media = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public feed_post_in_process = new Subject<any>();

  /**
   * Necessary instances.
   */
  constructor(@Inject(DOCUMENT) private document: Document, private dss: DataStorageService, private handler: HttpBackend, private postSer: PostApiService) { }

  /**
   * Generate thumbnail.
   */
  public generateThumbnail(videoFile: Blob): Promise<string> {
    const video: HTMLVideoElement = this.document.createElement('video');
    const canvas: HTMLCanvasElement = this.document.createElement('canvas');
    const context: CanvasRenderingContext2D = canvas.getContext('2d');
    return new Promise<string>((resolve, reject) => {
      canvas.addEventListener('error', reject);
      video.addEventListener('error', reject);
      video.addEventListener('canplay', () => {
        if (video.videoWidth === 0 && video.videoHeight === 0) {
          reject();
        }
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
        const time = Math.floor((video.duration * 1000));
        const x = { data: canvas.toDataURL(), duration: time };
        resolve(JSON.stringify(x));
      });
      if (videoFile.type) {
        video.setAttribute('type', videoFile.type);
      }
      video.preload = 'auto';
      video.src = window.URL.createObjectURL(videoFile);
      video.load();
      setTimeout(() => {
        // console.log(video.duration);
      }, 500);
    }).catch(() => 'Failed to Generate thumbnail');
  }

  /**
   * Create Chunks according to bytes.
   */
  createBytesPerChuck(size): number {
    let bytesPerChunks = CHUNKS_DIGITS.SEVEN; // **1MB chunk sizes.
    if (size > CHUNKS_DIGITS.SEVEN) {
      bytesPerChunks = CHUNKS_DIGITS.SEVEN;
    } else if (size > CHUNKS_DIGITS.SIX) {
      bytesPerChunks = CHUNKS_DIGITS.SIX;
    } else if (size > CHUNKS_DIGITS.FIVE) {
      bytesPerChunks = CHUNKS_DIGITS.FIVE;
    } else {
      bytesPerChunks = CHUNKS_DIGITS.FOUR;
    }
    return bytesPerChunks;
  }

  /**
   * Get the number of chunks and count.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getNumberChunks(obj): { uploadCounter: number; uploadFileArray: any; } {
    const file = obj;
    const blob = file;
    const size = blob.size;
    const bytesPerChunks = this.createBytesPerChuck(size);
    const remainingBites = size % bytesPerChunks;
    const countX = Math.floor(size / bytesPerChunks);
    const uploadFileArray = [];

    let uploadCounter = 0;
    let start = 0;
    let end = bytesPerChunks;
    let counter = 0;
    while (counter < countX) {
      const chunk = blob.slice(start, end);
      uploadFileArray[uploadCounter] = chunk;
      uploadCounter = uploadCounter + 1;
      start = end;
      end = start + bytesPerChunks;
      counter++;
    }

    if (remainingBites > 0) {
      const chunk = blob.slice(start, end);
      uploadFileArray[uploadCounter] = chunk;
      uploadCounter = uploadCounter + 1;
      start = end;
      end = remainingBites;
    }

    return { uploadCounter, uploadFileArray };
  }

  /**
   * Create the filename for the uploading.
   */
  getFileName(file): string {
    let fileName = file.type ? `.${file.type.split('/')[1]}` : `.${FILES_EXTENSIONS.PNG}`;
    const currentTimestamp = new Date().getTime();
    fileName = currentTimestamp + (fileName);
    if (file && file.type === MEDIA_TYPE.IMAGE) {
      fileName = fileName.replace(`.${FILES_EXTENSIONS.MP4}`, FILES_EXTENSIONS.PNG);
      fileName = fileName.replace(`.${FILES_EXTENSIONS.PDF}`, FILES_EXTENSIONS.PNG);
      fileName = fileName.replace(`.${FILES_EXTENSIONS.MKV.toUpperCase()}`, FILES_EXTENSIONS.PNG);
      fileName = fileName.replace(`.${FILES_EXTENSIONS.MKV}`, FILES_EXTENSIONS.PNG);
    }
    return fileName;
  }

  /**
   * Get the form data for the chunks upload api.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getFormData(file: any, fileName: string, fileType: string, num: number, numChunks: number, chunkStatus: string): FormData {
    const input = new FormData();
    input.append('file', file);
    input.append('name', fileName);
    input.append('file_type', fileType);
    input.append('num', num.toString());
    input.append('num_chunks', numChunks.toString());
    input.append('product_type', PRODUCT_TYPE_CONSTANT.FEED.toString());
    input.append('chunk_status', chunkStatus);
    return input;
  }

  /**
   * Create chunks for Files.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
  createChunksForFiles(obj, product_type = 1, fileType): Promise<any> {
    const chunksObj = this.getNumberChunks(obj); // total number of chunks
    const numChunks = chunksObj.uploadCounter;
    const uploadFileArray = chunksObj.uploadFileArray;
    const fileName = this.getFileName(obj);

    const reqArr = [];
    let uploadCounter = 0;

    for (let i = 0; i < numChunks - 1; i++) {
      const input = this.getFormData(uploadFileArray[uploadCounter], fileName, fileType, (uploadCounter + 1), numChunks, (numChunks === (i + 1) ? CHUNK_STATUS.ONE : CHUNK_STATUS.ZERO));
      const httpClient = this.createNewHttpClient();
      const headers = this.uploadFileHeaders();
      const params = { rquest: 'uploadfile' };
      const req = httpClient.post(`${environment.API_URLS.media_upload}/${API.CONNECT_SERVICE_F_UPLOAD_SET}`, input,
        {
          headers, params
        }
      );
      uploadCounter++;
      reqArr.push(req);
    }
    return this.callLastChunk(reqArr, uploadFileArray, uploadCounter, fileName, numChunks, fileType, obj);
  }

  /**
   * Call for the last chunks piece.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  callLastChunk(reqArr, uploadFileArray, uploadCounter, fileName, numChunks, fileType, obj): Promise<any> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return new Promise<any>((resolve) => {
      forkJoin(reqArr).subscribe((results: { msg: string; status: number }[]) => {
        if (this.errorStopExecution(results)) {
          return false;
        }
        reqArr = [];
        const input = this.getFormData(uploadFileArray[uploadCounter], fileName, fileType, (uploadCounter + 1), numChunks, CHUNK_STATUS.ONE);
        const httpClient = this.createNewHttpClient();
        const headers = this.uploadFileHeaders();
        const params = { rquest: 'uploadfile' };
        const req = httpClient.post( `${environment.API_URLS.media_upload}/${API.CONNECT_SERVICE_F_UPLOAD_SET}`, input,
          {
            headers, params
          }
        );
        uploadCounter++;
        reqArr.push(req);
        forkJoin(reqArr).subscribe(async results => {
          if (this.errorStopExecution(results)) {
            return false;
          }
          const fileObj = await this.lastChunkResult(fileType, results, obj);
          resolve(fileObj);
        }, () => {
          this.chunksApiFailed();
          resolve({ file_url: { status: 0 } });
        });
      }, () => {
        this.chunksApiFailed();
        resolve({ file_url: { status: 0 } });
      });
    });
  }

  /**
   * Stop the execution if one of the chunk api is failed.
   */
  errorStopExecution(element): boolean {
    return element.every(element => {
      if (element.status === 0) {
        this.chunksApiFailed();
      }
    });
  }

  /**
   * Get the last chunk result and then generate the thumbnail respectively.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async lastChunkResult(fileType, results, obj): Promise<any> {
    let fileObj = {};
    switch (fileType) {
      case MEDIA_TYPE.IMAGE:
        fileObj = { thubmnail: [], file_url: results[0] };
        break;
      case MEDIA_TYPE.VIDEO:
        await this.uploadVideoThumbnail(obj).then(data => {
          fileObj = { thumbnail: data, file_url: results[0] };
        }).catch(() => this.chunksApiFailed());
        break;
      case MEDIA_TYPE.DOCUMENT:
        await this.uploadPdfThumbnail(obj).then(data => {
          fileObj = { thumbnail: data, file_url: results[0] };
        }).catch(() => this.chunksApiFailed());
        break;

      default:
        break;
    }
    return fileObj;
  }

  /**
   * Show retry option on chunks failure.
   */
  chunksApiFailed(): void {
    this.dss.post_process_obj = { file_url: '', status: PROGRESS_STATUS.FAILED, is_show: true, progress_width: PROGRESS_WIDTH.ONE };
    this.feed_post_in_process.next(this.dss.post_process_obj);
    this.postSer.setPostButtonStatus(false);
  }


  /**
   * Data URI to Blob
   */
  dataURItoBlob(dataURI): Blob {
    const binary = atob(dataURI.split(',')[1]);
    const array = [];
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], {
      type: 'image/png'
    });
  }

  /**
   * upload Video Thumbnail
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  uploadVideoThumbnail(obj): Promise<any> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return new Promise<any>((resolve) => {
      this.generateThumbnail(obj).then(async data => {
        data = JSON.parse(data);
        const thumbnailObj = await this.createChunksForFiles(this.dataURItoBlob(data['data']), PRODUCT_TYPE_CONSTANT.FEED, MEDIA_TYPE.IMAGE);
        resolve(thumbnailObj.file_url);
      }).catch(() => this.chunksApiFailed());
    });
  }

  /**
   * upload pdf Thumbnail
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  uploadPdfThumbnail(obj): Promise<any> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return new Promise<any>((resolve) => {
      this.generatePdfThumbnail(obj).then(async data => {
        data = JSON.parse(data);
        const thumbnailObj = await this.createChunksForFiles(this.dataURItoBlob(data['data']), PRODUCT_TYPE_CONSTANT.FEED, MEDIA_TYPE.IMAGE);
        resolve(thumbnailObj.file_url);
      }).catch(() => this.chunksApiFailed());
    });
  }

  /**
   * generate pdf Thumbnail
   */
  generatePdfThumbnail(pdfFile: Blob): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const url = window.URL.createObjectURL(pdfFile);
      PDFJS.getDocument(url).promise
        .then(function (pdf) {
          return pdf.getPage(1);
        }).then(async function (page) {
          // Set scale (zoom) level
          const scale = 1.5;
          // Get viewport (dimensions)
          const viewport = (await page).getViewport({ scale: scale });

          // Get canvas#the-canvas


          const canvas = document.createElement('canvas');
          canvas.id = 'mycanvas';
          canvas.style.display = 'none';

          document.body.appendChild(canvas);
          // $("#mycanvas").display('none');

          // var canvas = <HTMLCanvasElement> document.getElementById("mycanvas");

          // Fetch canvas' 2d context
          const context = canvas.getContext('2d');
          if (viewport.height === 0 && viewport.width === 0) {
            reject();
          }
          // Set dimensions to Canvas
          canvas.height = viewport.height;
          canvas.width = viewport.width;

          // Prepare object needed by render method
          const renderContext = {
            canvasContext: context,
            viewport: viewport
          };
          // Render PDF page
          page.render(renderContext);
          (await
            page).render(renderContext);

          setTimeout(() => {
            const dataURL = canvas.toDataURL();
            const x = { data: dataURL, duration: 0 };
            resolve(JSON.stringify(x));
          }, 1000);
        });

    }).catch(() => 'Failed to Generate thumbnail');
  }

  createNewHttpClient(): HttpClient {
    return new HttpClient(this.handler);
  }

  /**
   * Common headers for the chunks upload api's.
   */
  uploadFileHeaders(): { Authorization: string; version: string; device_type: string; } {
    const headers = {
      'Authorization': environment.appKey,
      'version': environment.versions.apiVersion20,
      'device_type': environment.deviceType
    };
    return headers;
  }
}
