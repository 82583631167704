<!-- *ngIf="flag==true" -->
<ng-template reusable-outlet [reusableComponent]="reusableComponents.DQ_VIDEO_PLAYER"
  *ngIf="dqVideoPlayerService.isMiniPlayerVisible"></ng-template>
<ng-template reusable-outlet [reusableComponent]="reusableComponents.DQ_AUDIO_PLAYER"
  *ngIf="dqVideoPlayerService.isAudioMiniPlayer"></ng-template>

<ng-container *ngIf="isLoading">
  <span class="loader d-flex justify-content-center mx-auto"></span>
</ng-container>
<ng-container *ngIf="appConfigDataFlag">
  <router-outlet></router-outlet>
</ng-container>
<div *ngIf="!appConfigDataFlag" class='text-center mt-4'>
  <img src="assets/themes/images/img-video-loader.gif" width="40" height="40" id="loadmore_post" />
</div>
<!-- <div *ngIf="!flag && masterapi_exeution==true"  class='text-center'>
		<img src="./assets/images/loader-small.svg" width="40" height="100" id="loadmore_post">
    </div>
    <div class="container" *ngIf="!flag && masterapi_exeution==false">
        <div class="row">
            <div class="col-lg-12 col-xs-12 col-sm-12 col-md-12">
                <div class="si-jounral-container clearfix">
                    <div class="si-jounralDtails-col">
                        <div id="jounralView" class="jounral-container">
                    <section class="jounral_404" style="background-image:url('./assets/images/404.png')">
                    <div class="text_center">

                    <p><strong> Oops! </strong>  {{multiLangService.oops_something_went_wrong_please_try_again}}</p>
                    <a (click)="Reload_page()"  class="go_btn">{{multiLangService.Reload_page}}</a>
                    </div>
                    </section>

                         </div>
                        </div>
                    </div>
               </div>
         </div>
      </div> -->


<!-- <div class="container" *ngIf="isConnected==false">
            <div class="row">
                <div class="col-lg-12 col-xs-12 col-sm-12 col-md-12">
                    <div class="si-jounral-container clearfix">
                        <div class="si-jounralDtails-col">
                            <div id="jounralView" class="jounral-container">
                        <section class="jounral_404" style="background-image:url('./assets/images/404.png')">
                        <div class="text_center">

                        <p><strong> You are not connected to the internet </strong>  </p>
                        <h4>Please check your internet connection</h4>
                        </div>
                        </section>

                             </div>
                            </div>
                        </div>
                   </div>
             </div>
          </div> -->

<!-- <div class="container"  *ngIf="flag==false">
        <div class="row">
            <div class="col-md-4 col-lg-4">
                    <ngx-content-loading>
                            <svg:g ngx-instagram-preset></svg:g>
                        </ngx-content-loading>
            </div>
            <div class="col-md-8 col-lg-8">
                    <ngx-content-loading >
                            <svg:g ngx-facebook-preset></svg:g>
                    </ngx-content-loading>
                    <br>

                    <ngx-content-loading >
                            <svg:g ngx-list-preset></svg:g>
                    </ngx-content-loading>

                    <ngx-content-loading >
                            <svg:g ngx-facebook-preset></svg:g>
                    </ngx-content-loading>



                </div>



        </div>
  </div>   -->

<!--Loaders end-->
<!-- <app-info-modal (resetInfoModal) = resetInfoModal($event) [isInfoModalShown]=isInfoModalShown [info_msg]=info_msg></app-info-modal> -->

<!-- <app-error-modal *ngIf="isErrorModalShown==true" (resetErrorModal) = resetErrorModal($event)   [isErrorModalShown]=isErrorModalShown [error_msg]="error_msg"></app-error-modal> -->

<!-- <app-internet-connection-modal *ngIf="isConnectionModalShown==true" (resetConnectionModal) = resetConnectionModal($event)   [isConnectionModalShown]=isConnectionModalShown ></app-internet-connection-modal> -->

  <div class="bg-brown-200 fixed-bottom py-md-3 pt-4 pb-3" style="z-index: 99999999!important;"
  *ngIf="isCookies==true && !mvc.checkAuthenticByPass()">
  <div class="col-md-10 mx-auto">
    <div class="row">
      <div class="col-md-10 pl-0">
        <p class="primary-small pl-3 pb-2 pb-sm-0">
          {{mls?.languageText?.cookie_msg_info?.key1}}<span
            *ngIf="mls?.is_lskey_display == 1">(cookie_msg_info.key1)</span>
          <br>
          {{mls?.languageText?.cookie_msg_info?.key2}}<span
            *ngIf="mls?.is_lskey_display == 1">(cookie_msg_info.key2)</span>
          <!-- <a [href]="website_url + '/cookies'" target="_blank" class="color-blue"> More info </a> -->
        </p>
      </div>
      <div class="col-md-2 pl-md-0 mt-1 text-right text-md-left">
        <button class="btn-design small filled" (click)="isCookiesAccept()">
          {{mls?.languageText?.cookie_msg_info?.key3}}<span
            *ngIf="mls?.is_lskey_display == 1">(cookie_msg_info.key3)</span>
        </button>
        <img class="ml-4 cursor-pointer" (click)="isCookiesClose()" src="assets/themes/icons/cancel.svg" alt="cross"
          *ngIf="deviceType != 'mobile'" height="24px" width="24px">
      </div>
    </div>
  </div>
  <img class="cursor-pointer position-absolute cross-icon" *ngIf="deviceType == 'mobile'" (click)="isCookiesClose()"
    src="assets/themes/icons/cancel.svg" alt="cross">
</div>
