import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-conn-user-for-tag',
  templateUrl: './conn-user-for-tag.component.html',
  styleUrls: ['./conn-user-for-tag.component.scss']
})

export class ConnUserForTagComponent implements OnChanges {
  @Input() conn;

  connection_pic_image = {
    prflclass: 'user-40 text-center rounded-circle text-white d-table-cell align-middle',
    prflpic: '',
    prflfname: '',
    prflmname: '',
    prfllname: '',
    prflrname: '',
    prflcid: '',
    prfltype: '',
    prflpicclass: 'c-profile-pic rounded-circle user-40 post-pic',
    badge: { is_verified: 0, is_show: true },
    full_name: '',
    is_action: false,
    partial_permission_state: ''
  };

  ngOnChanges(): void {
    this.connection_pic_image.prflrname = this.conn.registered_name;
    this.connection_pic_image.prflpic = this.conn.profile_pic;
    this.connection_pic_image.prflcid = this.conn.custom_id;
    this.connection_pic_image.prflfname = this.conn.first_name;
    this.connection_pic_image.prfllname = this.conn.last_name;
    this.connection_pic_image.full_name = this.conn.full_name;
    this.connection_pic_image.badge.is_verified = (this.conn.permission == 'all') ? 1 : 0;
    this.connection_pic_image.partial_permission_state = this.conn.partial_permission_state;


  }

}
