<div>
    <div *ngIf="oas.skipPopUp" class="modal fade" tabindex="-1" role="dialog"
      [config]="{ show: true, backdrop: 'static', keyboard : false }" bsModal #autoShownModal="bs-modal">
      <div class="custom-theme-modal modal-md modal-dialog-centered user-role-modal">
        <div class="modal-content">
          <div class="row mx-0">
            <div class="col-md-4 left-section p-0">
                <div class="d-flex justify-content-between">
                    <img class=" docquity-image" src="./assets/themes/icons/public/docquity.svg" alt="docquity">
                    <div class="d-md-none mr-3 mt-4 cursor-pointer" (click)="closePopUp()"><span class="paragraph color-grey-400">{{mls.languageText.no_login.key10}}</span> </div>
                </div>
                <p class="heading-2 login-text "> <span class="body-text">{{mls.languageText.no_login.key9}}</span></p>
                <div class="login-container">
                    <img src="./assets/themes/icons/public/login.svg" class="login-image" alt="image">
                </div>
            </div>
            <div class="col-md-8 p-0">
              <div class="px-4">
                <div class="text-right skip-button cursor-pointer" (click)="closePopUp()"><span class="paragraph skip-text">{{mls.languageText.no_login.key10}}</span> </div>
                <p class="heading-2 login-heading">{{mls.languageText.no_login.key4}}</p>
                <div class="red-strip"></div>
                <div>
                
                <div>
                    <div class="login-content">
                        <img src="./assets/themes/icons/public/Check.svg" alt="check" class="mr-2">
                        <span class="paragraph">{{mls.languageText.no_login.key5}}</span>
                    </div>
                    <div class="login-content">
                        <img src="./assets/themes/icons/public/Check.svg" alt="check" class="mr-2">
                        <span class="paragraph">{{mls.languageText.no_login.key6}}</span>
                    </div>
                    <div class="login-content">
                        <img src="./assets/themes/icons/public/Check.svg" alt="check" class="mr-2">
                        <span class="paragraph">{{mls.languageText.no_login.key7}}</span>
                    </div>
                    <div class="login-content">
                        <img src="./assets/themes/icons/public/Check.svg" alt="check" class="mr-2">
                        <span class="paragraph">{{mls.languageText.no_login.key8}}</span>
                    </div>
                </div>
                </div>
                <label class="pl-1 my-3 close-button ">
                  <button class="btn-design medium filled login-button" (click)="loginButtonClick()">
                    <span>{{mls.languageText.no_login.key11}}</span>
                  </button>
                </label>
              </div>
  
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>