<div class="tutorial-popover">
    <div class="tutorial-popover-dim">  
        <div>          
            <div>
              <div class="bg-danger new-tag">                 
                {{mls?.languageText?.tutorial?.key10}}
              </div>
              <div class="float-right cursor-pointer" (click)="hideModal()">
                  <img class="cross-link" src="assets/images/icons/chat-cross.svg" alt="cross">
              </div>           
            </div>         
            <div class="tutorial-popover-header-text">
            <p>
              {{tutorialTitle}}
            </p>
        </div>
            <!-- <div class="tutorial-popover-body position-relative">
                <img [src]="tutorialBanner" alt="banner">  
                <img [src]="tutorialBanner" alt="banner">  
                <img [src]="tutorialBanner" alt="banner"> 
                <img [src]="tutorialBanner" alt="banner">                
            </div> -->
          <div class="tutorial-popover-body position-relative">          
            <p class="tutorial-popover-text">
              {{tutorialMsg}}.
            </p>
          </div>  
          <div class="tutorial-popover-footer">
            <p class="tutorial-popover-footer-text" (click)="startTutorial()">
              {{tutorialCta}}
            </p>
          </div>      
         
      </div>
    </div> 
  </div>
  