/**
 * Angular imports
*/
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
/**
 * Carousel imports
 */
import { OwlOptions, CarouselComponent } from 'ngx-owl-carousel-o';
/**
 * rxjs imports
 */
import { Subscription } from 'rxjs';

/**
 * Service imports.
 */
import { FunCollectionService } from '../../common/common-functions/fun-collection.service';
import { NewLanguageService } from '../../dataservices/new-language/new-language.service';
import { OpenAccessService } from '../open-access.service';
import { MasterserviceService } from '../../dataservices/master-service/masterservice.service';
import { PurpleOnboarding } from '../purple-onboarding.service';
import { PublicAnalyticsEventService } from '../public-analytics/public-analytics-event.service';

/**
 * constant imports.
 */
import { NUMBERS, SCREEN_NAME, OPEN_ACCESS, PRODUCT_TYPE_CONSTANT, LOGIN_SDK, SDK_PAGE_TYPE, LOCAL_STORAGE } from '../../constants/app.constants';
import { EVENT_NAME } from '../public-analytics/public-analytics.constant';
/**
 * Global model imports.
 */
import { LoginSdkParameter} from '../../onboarding/interface/global.model';
import { IEventName, IPublicAnalyticsData, SCREEN_NAME as screenName } from '../public-analytics/public-analytics.model';


@Component({
  selector: 'app-public-doctalk-detail',
  templateUrl: './public-doctalk-detail.component.html',
  styleUrls: ['./public-doctalk-detail.component.scss']
})
export class PublicDoctalkDetailComponent implements OnInit, OnDestroy{

  /**
   * Doctalk id
   */
  public doctalkId : string;

  /**
   * object for passing open article data
   */
    public suggestionList: any;

  /**
   * Used to hold the constant value;
   */
    public openAccessConst = OPEN_ACCESS;

  /**
   * Property to store the context from which this component is called.
   */
  public callingFrom: string;
  public speakerList = false;
  public openDoctalk : any;
  public apiInProcess = false;
  public numbers = NUMBERS;
  public doctalkDetail = { isShow: false, data: [], currentIndex: 0 };
  public restrictContent = false;
  public restrictData : any;
  public isSeries = NUMBERS.ZERO;
  public seePopUp = false;
  public record: IPublicAnalyticsData;
  public playEpisodeRecord: IPublicAnalyticsData;
  public singInRecord: IPublicAnalyticsData;
  public isCarousel= true;
  public isEpisode = true;

  @ViewChild('owlItem', { static: false }) owlItem: any;
  @ViewChild('owlCarousel') owlCarousel: CarouselComponent;

  public customOptionsForCarousal: OwlOptions = {
    loop: false,
    mouseDrag: false,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    center: false,
    navSpeed: 90,
    navText: ['<i class="prev-slider position-absolute"></i>', '<i class="next-slider position-absolute"></i>'],
    responsive: {
      0: {
        items: 1.33,
        navSpeed: 600
      },

      600: {
        items: 2
      },
      940: {
        items: 3,
        navSpeed: 600
      }
    },
    nav: true,
    margin: 8,
    // navigation: true,
    autoplay: false,
    autoHeight: true,
    autoWidth: true
  };

  /**
   * card container.
   */
  public textContainer = {width:'306px'};
  public subscription: Subscription;
  
  /**
   * Doctalk element reference for SDK
   */
  @ViewChild('doctalkloginContainer') doctalkloginContainer!: ElementRef;
 
  private readonly eventName: Map<number,IEventName> = new Map([
    [OPEN_ACCESS.productlist.community, EVENT_NAME.COMMUNITY_ICON_CLICK],
    [OPEN_ACCESS.productlist.doctalk, EVENT_NAME.DOCTALKS_ICON_CLICK],
    [OPEN_ACCESS.productlist.article, EVENT_NAME.ARTICLES_ICON_CLICK],
    [OPEN_ACCESS.productlist.webinar, EVENT_NAME.WEBINAR_ICON_CLICK]
  ]);


  constructor(
      public fcs: FunCollectionService,
      public mls: NewLanguageService,
      public oas : OpenAccessService,
      public route: ActivatedRoute,
      public masterService: MasterserviceService,
      private paes: PublicAnalyticsEventService,
      private login : PurpleOnboarding){
    this.callingFrom = SCREEN_NAME.DOCTALKS_DETAIL;
  }

  ngOnInit(): void {
    this.oas.selectedTab = '24';
    this.route.paramMap.subscribe(params => {
      this.suggestionList = null;
      this.doctalkId = params.get('id');
      this.getOpenDoctalkDetail();
      this.getProductSuggList();
      this.setAnalyticEvent();
      this.sendEntryEvent();
    });
    this.subscription = this.login.login$.subscribe(() => {
      this.fcs.navigateToDoctalkDetail(this.doctalkId , 24);
    })
  }

    /**
   * Method to fetch the list of open articles from the OpenAccessService.
   */
    getProductSuggList(): void {
      const listType = [OPEN_ACCESS.productlist.doctalk, OPEN_ACCESS.productlist.series];
      this.oas.getOpenArticleList(listType.join(',')).subscribe(
        (response: any) => {
          const res = response;
          if (res.status === NUMBERS.ONE && res.code === NUMBERS.TWO_THOUSAND) {
            res.data.response.forEach(element => {
              element.list = element.list.filter( (filterData) => filterData.id !== Number(this.doctalkId))
              if (!this.suggestionList) {
                this.suggestionList = element;
              } else {
                this.suggestionList.list = this.suggestionList.list.concat(element.list);
              }
            });
          }
        }
      );
    }

      /**
   * Method to fetch the details of the open article from the OpenAccessService.
   */
  getOpenDoctalkDetail(): void {
    this.apiInProcess = true;
    this.oas.getOpenArticleDetail(this.doctalkId,PRODUCT_TYPE_CONSTANT.DOCTALKS).subscribe(
      (response: any) => {
        this.apiInProcess =false;
        const res = response;
        if (res.status === NUMBERS.ONE && res.code === NUMBERS.TWO_THOUSAND) {
          this.doctalkDetail = res.data.response;
          this.openDoctalk = res.data.response;
          if(this.openDoctalk?.login_required == true){
            this.login.callLoginSdk(this.doctalkloginContainer);
          }
        }
        if (res.status === NUMBERS.ONE && res.code === NUMBERS.TWO_THOUSAND_ONE) {
          this.restrictData = res.data.message;
          this.restrictContent = true;
        }
      }
    );
  }

  /**
   *  View all Redirection from suggestion list.
   */
  viewAllClick(pType): void {
    if(pType) {
      this.fcs.navigateToOpenProductList(this.openAccessConst.productlist.doctalk, 36);
    } else {
      this.fcs.navigateToOpenProductList(0);
    }
  }

  /**
   * Navigate user to doctalk detail page.
   */
    navigateTologgedInArtcleDetail(): void {
      // this.fcs.navigateToclinicalDetail(this.doctalkId);
    }

  /**
   * sends share event
   * @param eventName
   */
  sendShareEvent(eventName: string): void {
    const record: IPublicAnalyticsData = {
      product_type: PRODUCT_TYPE_CONSTANT.DOCTALKS,
      product_id: +this.doctalkId,
      screen_name: screenName.PRODUCT_DETAIL_PAGE,
      event_name: ''
    };
    this.oas.sendEvent(eventName, record);
  }

  /**
   * sends header menu click event
   * @param pType
   * @returns
   */
  sendEvent(pType: number): void {
    if(!this.eventName.has(pType)) {
      return;
    }
    const record: IPublicAnalyticsData = {
      product_type: PRODUCT_TYPE_CONSTANT.DOCTALKS,
      product_id: +this.doctalkId,
      screen_name: screenName.PRODUCT_DETAIL_PAGE,
      event_name: '',
      eventName: this.eventName.get(pType)
    };
    this.paes.sendAnalytics(record);
  }
    videoPopUp() : void {
      this.doctalkDetail.isShow = true;
    }

  /**
   * show Session Speaker List
   */
  showSessionSpeakerList(): void {
    this.speakerList = true;
  }

  close() : void {
    this.speakerList = false;
  }
  ngOnDestroy(): void {
    this.sendExitEvent();
    if(this.subscription){
    this.subscription.unsubscribe();
    }
  }
   /**
   * Navigate user to article detail page.
   */
   navigateTologgedInDoctalkDetail(): void {
    let mcToken = localStorage.getItem(LOCAL_STORAGE.TOKEN_DETAIL);
    mcToken = mcToken ? JSON.parse(mcToken) : undefined; 
    if (this.oas.toCallLoginSDK() && !mcToken) {
      this.login.callLoginSdk(this.doctalkloginContainer);
    }
    else {
      this.fcs.navigateToDoctalkDetail(this.doctalkId , PRODUCT_TYPE_CONSTANT.DOCTALKS);
    }
  }

  seeMore() : void {
    this.seePopUp = true;
  }

  goBack() : void {
    this.seePopUp = false;
  }

  /**
   * sends entry event
   */
  private sendEntryEvent(): void {
    const record: IPublicAnalyticsData = {
      product_type: OPEN_ACCESS.productlist.doctalk,
      product_id: +this.doctalkId,
      screen_name: screenName.PRODUCT_DETAIL_PAGE,
      event_name: '',
      eventName: EVENT_NAME.PRODUCT_DETAIL_PAGE_ENTRY,
      extra: JSON.stringify({unique_id: this.fcs.openAccessWelcomeScreenData?.extras?.id})
    };
    this.paes.sendAnalytics(record);
  }

  /**
   * sends exit event
   */
  private sendExitEvent(): void {
    const record: IPublicAnalyticsData = {
      product_type: OPEN_ACCESS.productlist.doctalk,
      product_id: +this.doctalkId,
      screen_name: screenName.PRODUCT_DETAIL_PAGE,
      event_name: '',
      eventName: EVENT_NAME.PRODUCT_DETAIL_PAGE_EXIT
    };
    this.paes.sendAnalytics(record);
  }

  /**
   * sets analytic event
   */
  private setAnalyticEvent(): void {
    this.record = {
      product_type: OPEN_ACCESS.productlist.doctalk,
      product_id: +this.doctalkId,
      screen_name: screenName.PRODUCT_DETAIL_PAGE,
      event_name: '',
      eventName: EVENT_NAME.COUNTRY_DROPDOWN_CLICK
    };

    this.playEpisodeRecord = {
      product_type: OPEN_ACCESS.productlist.doctalk,
      product_id: +this.doctalkId,
      screen_name: screenName.PRODUCT_DETAIL_PAGE,
      event_name: '',
      eventName: EVENT_NAME.PLAY_EPISODE_CLICK
    };

    this.singInRecord = {
      product_type: OPEN_ACCESS.productlist.doctalk,
      product_id: +this.doctalkId,
      screen_name: screenName.PRODUCT_DETAIL_PAGE,
      event_name: '',
      eventName: EVENT_NAME.SIGNIN_CLICK
    };
  }
  /**
   * function to render login sdk
   */
  onCardClick():void {
    this.login.callLoginSdk(this.doctalkloginContainer);
  }

  
}
