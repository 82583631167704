/**
 * Angular imports.
 */
import { Component, EventEmitter, Input, Output } from '@angular/core';

/**
 * Service imports.
 */
import { NewLanguageService } from './../../dataservices/new-language/new-language.service';

/**
 * Used to show the walk through.
 */
@Component({
  selector: 'app-walkthrough',
  templateUrl: './walkthrough.component.html',
  styleUrls: ['./walkthrough.component.scss']
})
export class WalkthroughComponent {

  /**
   * Css for the position of the walk through.
   * Steps text of the walk through..
   * Title of the walk through.
   * Is last step flag to identify the last step.
   */
  @Input() positionCss = '';
  @Input() stepText = '';
  @Input() title = '';
  @Input() isLastStep = false;

  /**
   * Emit skip event outside.
   * Emit next event outside.
   * Emit finish event outside.
   */
  @Output() emitSkip: EventEmitter<void> = new EventEmitter();
  @Output() emitNext: EventEmitter<void> = new EventEmitter();
  @Output() emitFinish: EventEmitter<void> = new EventEmitter();

  /**
   * Necessary instances.
   */
  constructor(public mls: NewLanguageService) { }

  /**
   * Emit skip event outside.
   */
  skip(): void {
    this.emitSkip.emit();
  }

  /**
   * Emit next event outside.
   */
  next(): void {
    this.emitNext.emit();
  }

  /**
   * Emit finish event outside.
   */
  finish(): void {
    this.emitFinish.emit();
  }
}
