import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PublicAnalyticsEventDirective } from './public-analytics-event.directive';

@NgModule({
 declarations: [PublicAnalyticsEventDirective],
 imports: [
    CommonModule
 ],
 exports: [PublicAnalyticsEventDirective]
})
export class PublicAnalyticsModule { }