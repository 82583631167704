import { TemplatesInfo } from '../../hcf/templates/template.interface';

export interface WebinarStatusRecord {
  webinar_id: number;
  banner: string;
  end_image_url: string;
  link: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  linked_product: any[];
  media: MediaList[];
  poll: Poll[];
  show_end_image: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  is_poll_submit: any[];
  status: string;
  type: string;
}

export interface HistoricalPoll {
  poll_id: number;
  title: string;
  state: string;
  can_alter: number;
  user_selected_option: number;
  options: Option[];
}

export interface Option {
  option_id: number;
  value: string;
  percentage: number;
}


export interface MappedProductsList {
  product_type: number;
  check_time: string;
  product_id: number;
  title: string;
  timezone: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  start_datetime: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  end_datetime: any;
  streaming_status: number;
  logo: string;
  total_points: TotalPoints;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  state: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  user_status: any;
  remark: string;
}

export interface Speakers {
  email: string;
  name: string;
  profile_pic: string;
  qualification: string;
  role: string;
  speaker_custom_id: string;
  speaker_id: number;
  speciality_name: string;
  synopsis: string;
}

export interface CertificateDetail {
  title: string;
  certificate_url: string;
  total_points: TotalPoints;
}

export interface Activity {
  title: string;
  total: number;
  earned: number;
  identifier: string;
}

export interface MeetingDetail {
  meetingId: number;
  meetingSafeId: string;
  speakerId: string;
  name: string;
  description: string;
  userStatus: number;
  meetingStatus: number;
  role: string;
  meetingType: MeetingType;
  participants: Participant[];
  timeSlots: TimeSlot[];
  rescheduleTimeSlots: TimeSlot[];
  organization: Organization;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tag?: any;
  startInSeconds: number;
  bannerUrl: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  attachments: any[];
  therapeutics: Therapeutic[];
  conferenceStatus: ConferenceStatus;
  isFirstActionTaken: number;
  joinMeeting: JoinMeeting;
  location: string;
}

export interface Participant {
  role: string;
  data: ParticipantDetail;
}

export interface ParticipantDetail {
  custom_id: string;
  chat_id: number;
  permission: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  registered_name: string;
  full_name: string;
  profile_pic?: string;
  profilePic?: string;
  connection_status: number;
  chat_allow: number;
  is_connect_enable: number;
  is_call_allow: number;
  specialities: Speciality[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  associations: any[];
  is_email: number;
  is_user_blocked?: number;
}

export interface TimeSlot {
  slotId: number;
  startTime: number;
  endTime: number;
  timeZone: string;
}

export interface Organization {
  id: number;
  name: string;
  logo: string;
}

export interface Therapeutic {
  id: number;
  title: string;
  logo: string;
}

export interface ConferenceStatus {
  status: number;
  tag: string;
}

export interface JoinMeeting {
  meetingId: string;
  speakerId: string;
  type: string;
  isJoinMeetingEnabled: number;
}

export interface MeetingType {
  iconUrl: string;
  label: string;
  fgColor: string;
  typeId: number;
  bgColor?: string;
}
export interface promoStrip {
  pvs_id: number;
  title: string;
  content: string;
  close_cta_type: string;
  start_time: number;
  end_time: number;
}
export interface PharmaSection {
  title: string;
  key: string;
  value: Value[];
}

export interface Value {
  title: string;
  key: string;
  value: PharmaInfo[];
}

export interface PharmaInfo {
  etpm_id: number;
  title: string;
  logo: string;
  is_subscribed: number;
  total_drug: number;
  total_drug_with_text: string;
  therapeutic_drugs: DrugDetail[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  therapeutic_channels: any[];
  option_flag: OptionFlag;
  total_channel?: number;
  total_channel_with_text: string;
  channel_list: Channel[];
}

export interface EdetailingPharmaDetail {
  id: number;
  powered_name: string;
  description: string;
  powered_by: string;
  pharma_classification: string;
  website_url: string;
  header_image: string;
  label: string;
  total_channel: string;
  no_of_members: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  share_content?: any;
  share_url: string;
  product_type: number;
  product_type_id: number;
  flags: ProfileFlags;
}

export interface PharmaTnc {
  title: string;
  tnc: string;
  tnc_option: TncOption[];
}

export interface TncOption {
  id: number;
  option_key: string;
  option_text: string;
}

export interface TherapeuticList {
  etpm_id: number;
  title: string;
  logo: string;
  is_subscribed: number;
  total_drug: number;
  total_drug_with_text: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  therapeutic_drugs: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  therapeutic_channels: any[];
  option_flag: OptionFlag;
}

export interface OptionFlag {
  option_keys: string[];
  is_edetailing_subscribed: number;
}

export interface EdetailingPharmaList {
  id: number;
  powered_name: string;
  description: string;
  powered_by: string;
  pharma_classification: string;
  website_url: string;
  header_image: string;
  label: string;
  total_channel: string;
  no_of_members: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  share_content?: any;
  share_url: string;
  product_type: number;
  product_type_id: number;
  flags: ProfileFlags;
}

export interface Tnc {
  tnc_text: string;
}

export interface ReferralContent {
  referal_link: string;
  view_credit_score_url: string;
  referal_msg: string;
  referal_tc: string;
  total_point: number;
  referal_image_url: string;
  referal_email_msg: string;
  referal_sms_msg: string;
  referal_email_title: string;
  social_msg: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  content_media: any[];
  peersAppList: string[];
  shareAppList: string[];
}

export interface TotalPoint {
  point: string;
  point_label: string;
  title: string;
}

export interface HowItWorks {
  voucher_text: string;
  tnc: string;
  how_it_work_image: string;
}

export interface TooltipList {
  id: number;
  screen_identifier: string;
  view_identifier: string;
  tooltip_message: string;
  priority: number;
}

export interface AgoraData {
  app_id: string;
}

export interface DegreeList {
  degree_id: number;
  degree_name: string;
}

export interface UniversityList {
  university_id: number;
  university_name: string;
  country_code: string;
}

export interface HospitalList {
  hospital_id: number;
  hospital_name: string;
}

export interface ReasonList {
  id: number;
  reason: string;
}

export interface Trend {
  search_key: string;
  search_value: string;
  target: Target;
}

export interface NotificationList {
  notification_title: string;
  read_status: string;
  message: string;
  message_id: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  date_of_creation: any;
  notification_pic: string;
  profile_pic_path: string;
  target: Target;
}

export interface NotificationBand {
  total_text: string;
  icon_identifier: string;
  button: Button;
}

export interface HeaderTab {
  header_text: string;
  product_type: number;
}

export interface Profile {
  title: string;
  user_type: string;
  referal_id: string;
  last_update: string;
  custom_id: string;
  track_id: string;
  permission: string;
  first_name: string;
  last_name: string;
  user_auth_key: string;
  middle_name: string;
  date_of_birth: string;
  gender: string;
  country_code: string;
  country: string;
  city: string;
  state: string;
  bio: string;
  jabber_name: string;
  chat_password: string;
  chat_id: number;
  profile_pic_path: string;
  city_id: number;
  country_id: number;
  mobile: string;
  email: string;
  registered_name: string;
  mrn: string;
  profile_views: string;
  ic_number: string;
  notification_status: number;
  is_docquity_team: string;
  language: string;
  profile_view: ProfileView;
  is_verified: number;
  mrn_text: MrnText;
  user_code?: string;
}

export interface ProfileView {
  total_view: number;
  total_view_text: string;
}

export interface MrnText {
  placeholder: string;
  title: string;
}

export interface UserAssociationList {
  association_id: number;
  profile_pic_path: string;
  association_name: string;
  is_member: number;
  is_verified: string;
  is_public: string;
}

export interface Education {
  education_id: number;
  school: string;
  university_id: number;
  start_date: number;
  end_date: number;
  degree: string;
  degree_id: number;
  current_pursuing: number;
}

export interface Profession {
  profession_id: number;
  profession_name: string;
  hospital_id: number;
  position: string;
  time_period_start_date: string;
  time_period_end_date: string;
  current_prof: number;
}

export interface Reward {
  score: number;
  point_label: string;
  label_action: string;
  icon_url: string;
  title: string;
  label: string;
  target: Target;
}

export interface InfoUpdate {
  key: string;
  last_update: string;
}

export interface UserConnection {
  total_connections: number;
  total_connections_text: string;
}

export interface UserPosts {
  total_post: number;
  text: string;
}

export interface RequestedData {
  pkey: string;
  pvalue: string;
}

export interface StatsCount {
  total_comment_view: number;
  total_upvote: number;
}

export interface SearchList {
  header: string;
  subtitle: string;
  view_more_text: string;
  total_connection_request: number;
  total_connections: number;
  key: string;
  list: SearchDetail[];
}

export interface SearchDetail {
  custom_id: string;
  chat_id: number;
  permission: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  registered_name: string;
  full_name: string;
  profile_pic: string;
  connection_status: number;
  chat_allow: number;
  is_connect_enable: number;
  is_call_allow: number;
  specialities: Speciality[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  associations: any[];
  is_email: number;
  suggestion_based_on_text: string;
}

export interface FilterData {
  id: number;
  value: string;
}

export interface Filters {
  filter_name: string;
  filter_key: string;
  filter_data: FilterData[];
}

export interface PreferredTimeSlot {
  id: number;
  slot_one: string;
  slot_two: string;
}

export interface AutoFillDetail {
  key: string;
  value: string;
  label: string;
  placeholder: string;
  validation: {
    editable: number;
    required: number;
  };
}

export interface DrugValidationFlags {
  is_name_required: number;
  is_mobile_required: number;
  is_email_required: number;
  is_address_required: number;
  is_postcode_required: number;
  is_date_required: number;
  is_time_range_required: number;
  is_comment_required: number;
}

export interface DrugDetail {
  drug_id: number;
  pharma_id: number;
  name: string;
  logo: string;
  safe_url: string;
  drug_strength: string;
  medicine_type: string;
  description: string;
  pharma_name: string;
  pharma_logo: string;
  pharma_website_url: string;
  pharma_label: string;
  salt: string;
  is_chatbot_enable: string;
  chatbot_button_name: string;
  order_message: string;
  order_allow: OrderAllow;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  flags?: any;
  product_type: number;
  product_type_id: number;
  paid_text: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  composition: any[];
}

export interface OrderAllow {
  order_button_flag: number;
  msg: string;
  order_button_text: string;
  delivered_status: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rules: any[];
}

export interface SessionDetail {
  program_id: number;
  program_name: string;
  program_description: string;
  points: number;
  accreditation_name: string;
  accreditation_id: number;
  parent_id: number;
  credit: number;
  score_id: number;
  tab_name: string;
  tag_name: string;
  tag_color_code: string;
  event_id: number;
  program_type: string;
  is_accreditated: number;
  share_url: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  share_content?: any;
  point_name: string;
  session_banner_list: SessionBannerList[];
  min_session_msg: string;
  session_date_list: SessionDateList[];
  attendence_flag: number;
  is_certificate: number;
  attendance_button_enable_flag: number;
  total_session_media: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  session_speaker_list: any[];
  product_type: number;
  product_type_id: number;
  product_id?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  startDate: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  endDate: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  time_schedule: any;
  speciality: Speciality[];
}

export interface SessionBannerList {
  id: number;
  file_type: string;
  file_url: string;
  preview_url: string;
  caption: string;
  event_type_id: number;
}

export interface SessionDateList {
  id: number;
  program_id: number;
  location: string;
  latitude: number;
  longitude: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  start_time: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  end_time: any;
  time_zone: string;
}

export interface EventDetail {
  event_id: number;
  event_name: string;
  title: string;
  time_zone: string;
  tab_name: string;
  created_by: number;
  hosted_by: string;
  user_type: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  start_date: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  end_date: any;
  is_program: string;
  event_description: string;
  location: string;
  latitude: number;
  longitude: number;
  contact_us: string;
  number_days: number;
  credit: number;
  score_id: number;
  tnc_id: number;
  is_paid: string;
  is_going: number;
  point_name: string;
  share_url: string;
  product_type: number;
  product_type_id: number;
  is_event_ended: number;
  min_session_msg: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  share_content?: any;
  event_banner_list: EventBannerList[];
  total_registered: number;
  event_days_list: EventDaysList[];
  attendence_flag: number;
  attendance_button_enable_flag: number;
  is_certificate: number;
  total_media: number;
  event_speaker_list: EventSpeakerList[];
  channel: Channel;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payment_info?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  event_speciality?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  user_status: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registered_user_count: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  startDate: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  time_schedule: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  endDate: any;
}

export interface EventSpeakerList {
  id: number;
  name: string;
  role: string;
  synopsis: string;
  event_info: string;
  speaker_doc: string;
  profile_pic: string;
  type_id: number;
  custom_id: string;
  qualification: string;
  speciality_name: string;
  slots: Slot[];
}

export interface EventBannerList {
  id: number;
  file_type: string;
  file_url: string;
  preview_url: string;
  caption: string;
  event_type_id: number;
}

export interface EventDaysList {
  program_name: string;
  program_id: number;
  event_id: number;
  total_session: number;
}

export interface Slot {
  start_time: string;
  end_time: string;
  topic_name: string;
  id: number;
}

export interface Filter {
  key: string;
  tab_name: string;
  filter_type: string;
  list: FilterList[];
}

export interface FilterList {
  value: string;
  name: string;
  logo: string;
}

export interface PharmaDetail {
  id: number;
  powered_name: string;
  description: string;
  powered_by: string;
  pharma_classification: string;
  website_url: string;
  header_image: string;
  label: string;
  total_channel: string;
  no_of_members: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  share_content?: any;
  share_url: string;
  product_type: number;
  product_type_id: number;
  flags: ProfileFlags;
  subscribed_channel: Channel[];
  explore_channel: Channel[];
}

export interface ChannelHomeList {
  section_key: string;
  section_name: string;
  list: Channel[];
}

export interface ShareContent {
  email_title: string;
  email_content: string;
  other_content: string;
  share_link: string;
}

export interface Popup {
  url: string;
}

export interface WebinarRecord {
  section_key: string;
  section_name: string;
  list: WebinarDetail[];
  offset: number;
  limit: number;
}

export interface WebinarDetail {
  moderator_id: number;
  webinar_id: number;
  title: string;
  banner: string;
  comment_flag: number;
  is_live_comment: number;
  type: string;
  absolute_url: string;
  release_on: number;
  start_datetime: number;
  end_datetime: number;
  date_of_updation: number;
  like_count: number;
  description: string;
  link: string;
  aws_chanel_server_id: number;
  timezone: string;
  show_live_count: number;
  end_image_url: string;
  show_end_image: number;
  is_paid: string;
  minimum_watch_time: number;
  share_detail_checkbox: number;
  is_dstream_secure: string;
  notify_me: number;
  product_type: number;
  product_type_id: number;
  status: string;
  is_like: number;
  is_bookmark: number;
  flags: NotifyCheckBoxFlags;
  live_user: number;
  channel: Channel;
  views: number;
  views_string: string;
  live_user_string: string;
  dstream_data: DstreamData;
  speciality: Speciality[];
  speaker: Speaker[];
  media: Medium[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  share?: any;
  enable_product_feedback_time_in_sec: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  poll: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  is_poll_submit: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sponsor: any[];
  product_feedback_flags: ProductFeedbackFlags;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  product_feedback_popup_text: any[];
  live_count_prefix: string;
  is_feedback_given: number;
  color_code: ColorCode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ticker: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  linked_product: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  notify_me_flags?: any;
  is_watch_time_applicable: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  total_points?: any;
}

export interface ProductFeedbackFlags {
  rating_with_text: number;
  text: number;
  rating: number;
}

export interface ColorCode {
  header_code: string;
  footer_code: string;
}

export interface Doctalks {
  doctalks: DoctalksDetail;
  related_list: RelatedList;
}

export interface RelatedList {
  section_key: string;
  section_name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  list: any[];
}

export interface Doctalk {
  section_key: string;
  section_name: string;
  list: DoctalksDetail[];
}

export interface DoctalksDetail {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  doctalks_id: any;
  title: string;
  description: string;
  summary: string;
  publish_date: number;
  end_date: number;
  is_visible: number;
  comment_flag: string;
  episode_index: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  series_status?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  banner?: any;
  total_episode: number;
  series_id: number;
  flags: ShowTimeFlags;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  series?: any;
  in_series: number;
  speaker: Speaker[];
  like: number;
  is_like: number;
  like_status: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  views?: any;
  views_string: string;
  is_bookmarks: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  product_type: any;
  product_type_id: number;
  commentCount: number;
  speciality: Speciality[];
  channel: Channel;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  media: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  share?: any;
  id?: number;
  name: string;
  profile_pic: string;
  synopsis: string;
  qualification: string;
  custom_id: string;
  speciality_name: string;
  permission: string;
  speciality_id?: number;
  logo: string;
  videoCount?: number;
}

export interface Source {
  source_id: number;
  source_type: string;
}

export interface Survey {
  survey_id: number;
  survey_kind: string;
  tnc_type: string;
  tnc: string;
}

export interface CommentTag {
  accepted_solution_button_flag: number;
  tags: Tag[];
}

export interface Tag {
  tag_key: string;
  tag_name: string;
  tag_color: string;
  comment_id: number;
}

export interface LikeList {
  like_id: number;
  user_id: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  date_of_updation: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  date_of_creation: any;
  user_profile: UserProfile;
}

export interface Course {
  certificate: number;
  course_id: number;
  course_code: string;
  course_name: string;
  course_summary: string;
  start_date: number;
  expiry_date: number;
  total_points: TotalPoints;
  resource_url: string;
  number_of_user: string;
  classification: string;
  posted_by: string;
  cme_type: string;
  timer: number;
  banner_url: string;
  is_paid: number;
  retake: number;
  tnc: string;
  is_bookmarked: number;
  score: number;
  cme_detail_url: string;
  media: Medium[];
  channel: Channel;
  attempts: Attempts;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  publisher?: any;
  quiz_leaderboard_url: string;
  is_reattempt_allow: number;
  accreditated: Accreditated;
  cme_name: string;
  remark: string;
  date_of_submission: number;
  certificate_url: string;
  cme_share_url: string;
  share_content: ShareContent;
  speciality: Speciality[];
  associations: Association[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  question_option: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rules: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payment_info?: any;
  achieve_points: AchievePoints;
  is_cme_in_progress?: string;
  points_int?: number;
  type?: string;
  institution_id?: number;
  source_id?: number;
  source_type?: string;
  owner_id?: string;
  is_editable?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  passing_msg?: any;
  is_sample?: number;
  product_type?: number;
  product_id?: number;
  product_type_id?: number;
  repost_default_image?: string;
  module_validation: ModuleValidation;
  has_parent?: number;
  module_count? :number
}

export interface ModuleValidation {
  is_allowed: number
  title: string
  target: Target
}

export interface Attempts {
  total_attempt: number;
  attempted: number;
  score_percentage: number;
}

export interface ShareContent {
  email_title: string;
  email_content: string;
  other_content: string;
}

export interface Association {
  association_id: number;
  association_name: string;
  association_pic: string;
}

export interface AchievePoints {
  score: number;
  point_label: string;
  label_action: string;
}

export interface CmePointData {
  score: number;
  point_label: string;
  label_action: string;
}

export interface CMEList {
  certificate: number;
  product_type_id: number;
  product_type: number;
  remark: string;
  cme_detail_url: string;
  score: number;
  total_points: TotalPoints;
  course_id: number;
  course_code: string;
  course_name: string;
  course_summary: string;
  course_description: string;
  cme_type: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  start_date: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  expiry_date: any;
  resource_url: string;
  accreditated: Accreditated;
  timer: number;
  banner_url: string;
  number_of_user: string;
  is_bookmarked: number;
  channel: Channel;
  speciality: Speciality[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  share_content?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payment_info?: any;
  quiz_leaderboard_url: string;
}

export interface HeaderText {
  online_header: string;
  offline_header: string;
}

export interface OfflineCme {
  total_offline: number;
  total_offline_text: string;
}

export interface OnlineCme {
  total_online: number;
  total_online_text: string;
}

export interface TotalPoints {
  score: number;
  point_label: string;
  label_action: string;
  is_cme_under_progress: string;
  is_cme_under_progress_label: string;
}

export interface Accreditated {
  accreditation: string;
  accreditation_url: string;
  prefix_label: string;
}

export interface CmeTab {
  key: string;
  name: string;
  value: number;
}

export interface ClinicalNote {
  key: string;
  label: string;
  list: ClinicalList[];
  offset: number;
}

export interface ClinicalList {
  banner: string;
  categories: Category[];
  channel: Channel;
  classification: string;
  clinical_resource_type: string;
  comment_count: number;
  comment_flag: number;
  content_type: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  creation_date: any;
  description: string;
  drug_sample: number;
  flags: ShowTimeFlags;
  id: number;
  institution_name: string;
  is_activity_allowed: number;
  is_bookmarked: number;
  is_guideline: string;
  is_header_enable: number;
  is_liked: number;
  is_paid: number;
  like_count: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pharma?: any;
  product_type: number;
  product_type_id: number;
  publication_profile: PublicationProfile;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  publish_date: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  share_content?: any;
  share_link: string;
  title: string;
  total_views: number;
  urlsafe: string;
}

export interface Category {
  category_id: number;
  category_name: string;
  banner_url: string;
}

export interface PublicationProfile {
  publication_id: number;
  publication_name: string;
  publication_logo: string;
}

export interface ProfileTimeList {
  channel?: Channel;
  comment_flag: number;
  content: string;
  date_of_creation: number;
  date_of_updation: number;
  feed_comment_list: FeedCommentList[];
  feed_id: number;
  feed_kind: string;
  feed_share_url: string;
  flags: ShowTimeFlags;
  html_content: string;
  is_anonymous: number;
  is_bookmarked: number;
  like_status: number;
  media_list: MediaList[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  meta_array: any;
  meta_url: string;
  poll: Poll[];
  poll_status: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  share_content?: any;
  speciality: Speciality[];
  title: string;
  total_comments: number;
  total_like: number;
  product_type_id: number;
  product_type: number;
  user_feed_association_list: UserFeedAssociationList[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  verify_card?: any;
  user_profile: UserProfile;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  user_tagged: any[];
}

export interface MixList {
  text_highlight: string;
  product_type: number;
  products: Product[];
}

export interface Product {
  absolute_url?: string;
  attributes?: Attributes;
  aws_chanel_server_id?: number;
  banner_id?: number;
  banner?: string;
  button?: Button;
  channel?: Channel;
  comment_flag?: number;
  community: GrCommunity[];
  content?: string;
  created_by?: CreatedBy;
  da_id?: number;
  date_of_creation?: number;
  date_of_updation?: number;
  description_text?: string;
  description?: string;
  dstream_data?: DstreamData;
  end_datetime?: number;
  end_image_url?: string;
  feed_comment_list?: FeedCommentList[];
  feed_id?: number;
  feed_kind?: string;
  feed_share_url?: string;
  file_type?: string;
  file_url?: string;
  flags?: NotifyCheckBoxFlags | CommunityFlags;
  heading_text?: string;
  html_content?: string;
  is_anonymous?: number;
  is_bookmark?: number;
  is_bookmarked?: number;
  is_dstream_secure?: string;
  is_like?: number;
  is_live_comment?: number;
  is_paid?: string;
  is_watch_time_applicable?: number;
  join_type?: number;
  like_count?: number;
  like_status?: number;
  link?: string;
  live_user_string?: string;
  live_user?: number;
  mapping_count?: MappingCount;
  media_list?: MediaList[];
  media?: Medium[];
  member_type?: string;
  meta_array?: string;
  meta_url?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  micro_site?: any;
  minimum_watch_time?: number;
  moderator_id?: number;
  notify_me_flags?: NotifyMeFlags;
  notify_me?: number;
  poll_status?: number;
  poll?: Poll[];
  pre_autoload_detail?: PreAutoloadDetail;
  privacy?: string;
  product_type_id?: number;
  product_type?: number;
  promotional_text?: string;
  release_on?: number;
  request?: CommunityRequest;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  share_content?: any;
  share_detail_checkbox?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  share?: any;
  show_end_image?: number;
  show_live_count?: number;
  speaker?: Speaker[];
  specialities?: SearchSpeciality[];
  speciality?: Speciality[];
  start_datetime?: number;
  status?: string;
  target_id?: number;
  target_type?: string;
  target_url?: string;
  timezone?: string;
  title?: string;
  total_comments?: number;
  total_like?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  total_points?: any;
  type?: string;
  user_feed_association_list?: UserFeedAssociationList[];
  user_profile?: UserProfile;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  user_tagged?: any[];
  subtitle?: string;
  target?: Target;
  classification?: string;
  draw_pattern?: string;
  list?: List;
}

export interface List {
  template: number;
  product_type: number;
  product_id: number;
  info: TemplatesInfo;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  verify_card?: any;
  views_string?: string;
  views?: number;
  webinar_id?: number;
}

export interface CommunityFlags {
  show_join_button: number;
  is_community_creator: number;
}
export interface Attributes {
  text: string;
  background_color: string;
}

export interface MappingCount {
  community: number;
  channel: number;
  association: number;
}
export interface AutoPlayFlags {
  is_audio_auto_play: number;
  is_video_auto_play: number;
}

export interface Target {
  id: number | string;
  type: string;
  url: string;
  enabled?: string;
  text?: string;
  button?: Button;
}

export interface Button {
  text?: string;
  target?: Target;
  param?: Param[];
  title?: string;
}

export interface Param {
  key: string;
  value: string;
}

export interface NotifyCheckBoxFlags {
  is_show_time: number;
  notify_checkbox_en: string;
  notify_checkbox_in: string;
  notify_checkbox_vi: string;
  notify_checkbox_ko: string;
  notify_checkbox_zh: string;
  show_poll: number;
}

export interface ContentVisibility {
  display: number;
  verify_msg: string;
  verify_title: string;
}

export interface Channel {
  channel_id: number;
  safe_url: string;
  title: string;
  banner: string;
  channel_logo: string;
  description: string;
  no_of_members: string;
  powered_by: string;
  powered_name: string;
  powered_by_label: string;
  is_subscribed: number;
  is_drug_sample: number;
  tnc_url: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  share_content?: any;
  share_url: string;
  product_type: number;
  product_type_id: number;
  sponsor_footer_text: string;
  is_product_view: number;
  subscribed_button_flag: number;
  pharma_classification: string;
  content_visibility: ContentVisibility;
  pharma_id: number;
  id?: number;
  website_url?: string;
  header_image?: string;
  label?: string;
  total_channel?: string;
  flags: ProfileFlags;
  is_story_available: number;
  target_id?:number,
}
export interface channelFaqData {

}

export interface ProfileFlags {
  is_profile_missing: number;
  is_edetailing: number;
  tnc_flag: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  profile_missing_keys: any[];
}

export interface DstreamData {
  is_dstream_secure: number;
  token: string;
  stream_key: string;
  base_url: string;
}

export interface Speciality {
  speciality_id: number;
  speciality_name: string;
  logo: string;
  banner: string;
  name?: string;
  is_selected: number;
  is_primary: number;
  feed_id?: number;
}

export interface Speaker {
  id: number;
  name: string;
  profile_pic: string;
  synopsis: string;
  qualification: string;
  speciality_name: string;
  permission: string;
  custom_id: string;
}

export interface Medium {
  id: number;
  type: string;
  name: string;
  url: string;
  thumbnail: string;
  resume_on_in_sec: number;
  play_time: number;
  optimize_url: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  partner?: any;
  preview_url?: any,
  file_type?: any,
  file_url?: any
}

export interface NotifyMeFlags {
  show_checkbox: number;
  message: string;
  notify_me_buton_text: string;
  notify_me_buton_after_click: string;
  show_button: number;
  notify_me: number;
}

export interface PreAutoloadDetail {
  api_refresh_interval: number;
  api_refresh_count: number;
}

export interface ShowTimeFlags {
  is_show_time: number;
}

export interface FeedCommentList {
  comment_id: number;
  comment: string;
  product_type: number;
  product_id: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  date_of_updation: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  date_of_creation: any;
  file_type: string;
  temp_url: string;
  is_anonymous: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  file: any[];
  user_profile: UserProfile;
  total_comment_reply: number;
  total_comment_like: number;
  comment_like_status: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  comment_reply: any[];
  is_expert: number;
  expert_solution: number;
  flags: ShowTimeFlags;
}

export interface MediaList {
  media_id: number;
  media_type: string;
  media_name: string;
  media_url: string;
  media_thumbnail: string;
  media_original_url: string;
  media_time_duration: number;
  media_width: number;
  media_height: number;
}

export interface Poll {
  option_id: number;
  options: string;
  per: string;
  mypoll: number;
  option_format: string;
  file_url: string;
}

export interface UserFeedAssociationList {
  association_pic: string;
  association_id: number;
  association_name: string;
}

export interface UserProfile {
  custom_id: string;
  chat_id: number;
  permission: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  registered_name: string;
  full_name: string;
  profile_pic: string;
  connection_status: number;
  chat_allow: number;
  is_connect_enable: number;
  is_call_allow: number;
  specialities: Speciality[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  associations: any[];
  is_email: number;
}

export interface AssociationList {
  association_id: number;
  profile_pic_path: string;
  association_name: string;
  is_member: number;
  is_verified: string;
  is_public: string;
}

export interface InviteData {
  title: string;
  description: string;
  button: ButtonObject;
}
export interface UserStatus {
  iconUrl: string;
  label: string;
  fgColor: string;
  bgColor: string;
  code: number;
}
export interface ButtonObject {
  title: string;
  target: Target;
}


export interface ProfileInfo {
  profile: Profile;
  user_association_list: UserAssociationList[];
  chat_allow: number;
  is_connect_enable: number;
  speciality: Speciality[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  educations: any[];
  professions: Profession[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  publications: any[];
  certifications: Certification[];
  connection_status: number;
  rewards: Reward[];
  info_update: InfoUpdate[];
  user_connection: UserConnection;
  user_posts: UserPosts;
  profile_completed: ProfileCompletedObject;
  requested_data: RequestedData[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  address: any[];
  share_content: ShareContent;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  verifiy_card?: any;
  saved: Saved[];
  is_root_enable: number;
  stats_count: StatsCount;
  is_call_allow: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  consent_data?: any;
}

export interface Saved {
  key: string;
  value: string;
}

export interface ProfileCompletedObject {
  percentage: number;
  profile_completed: ProfileCompleted[];
}

export interface ProfileCompleted {
  icon_url: string;
  title: string;
  key: string;
  label: string;
  base_score: number;
  carousel_seq: number;
}

export interface Certification {
  certificate_id: number;
  certificate_name: string;
  year_of_received: number;
}

export interface ScreenDataObject {
  identifier: string;
  is_skippable: string;
  data: ScreenData;
}

export interface ScreenData {
  profession: Profession[];
}

export interface CountryList {
  id: number;
  country: string;
  country_code: number;
  flag_url: string;
}

export interface UserBasicInfo {
  custom_id: string;
}

export interface SurveyList {
  redirect_url: string;
  safe_url: string;
  survey_type: string;
  product_type: number;
  product_type_id: number;
  is_reset: number;
  point: string;
  remark: string;
  tnc: string;
  title: string;
  description: string;
  sponsored: Sponsored;
  end_date: number;
  number_of_user: string;
  total_question_left: string;
  total_question_left_count: number;
  total_questions: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  share_content?: any;
}

export interface Sponsored {
  accreditation: string;
  accreditation_url: string;
  prefix_label: string;
}

export interface Sidebar {
  id: number;
  value: string;
  sort_order: number;
  name: string;
  screen_name: string;
  name_message_id: number;
  screen_message_id: number;
  group_id: number;
  product_id: number;
  icon_url: string;
  target_url: string;
  color_code: string;
}

export interface SearchTrend {
  id: number;
  value: string;
  sort_order?: number;
  name: string;
  screen_name: string;
  name_message_id?: number;
  screen_message_id?: number;
  group_id: number;
  product_id: number;
  icon_url: string;
  target_url: string;
  color_code: string;
}
export interface IncludeFiles {
  js: string;
  css: string;
}
export interface Explore {
  identifier: string;
  group_name: string;
  group_sort_order: number;
  products: Product[];
}
export interface Product {
  id: number;
  value: string;
  sort_order?: number;
  name: string;
  screen_name: string;
  name_message_id?: number;
  screen_message_id?: number;
  group_id: number;
  product_id: number;
  icon_url: string;
  target_url?: string;
  color_code: string;
}
export interface PageInfo {
  cme: CMEText;
  feed: FeedText;
  event: EventText;
  point: Point;
  chat: Chat;
  channel: ChannelText;
  web: Web;
  analytics: Analytics;
  comment: Comment;
  sampling: Sampling;
  posting: Posting;
  profile: ProfileAction;
  search: Search;
  bookmark: Bookmark;
  support: Support;
  call: Call;
}
export interface NavNew {
  id: number;
  value: string;
  sort_order?: number;
  name: string;
  screen_name: string;
  name_message_id: number;
  screen_message_id: number;
  group_id: number;
  product_id: number;
  icon_url: string;
  target_url: string;
  color_code: string;
}

export interface CMEText {
  vaultTitle: string;
  vaultOfflineTabName: string;
  addcmeBtn: string;
  screenTitle: string;
  addcmeBtnVisibility: string;
  courseForYouTitle: string;
  cmeFormTitle: string;
  cmeFormPointTitle: string;
  cmeFormPointPlaceHolder: string;
  cmeCourseDetailTitle: string;
  cmeFormPointBlankDilog: string;
  vaultBlankText: string;
  isCMEVaultCatScreenVisibilty: string;
  cme_download_title: string;
}
export interface FeedText {
  feedRecommendedCMETitle: string;
  feedRecommendedJournalTitle: string;
  feedPostInititate: string;
  feedPostButtonText: string;
  feedAutoPlayAudio: string;
}
export interface EventText {
  eventTitle: string;
  eventAttendenceButton: string;
  eventAttendeceVisibility: string;
  eventVisibility: string;
  eventLogDefault: string;
}

export interface Point {
  pointRewardVisibility: string;
  pointRewardName: string;
  pointRewardTabName: string;
  pointToolBarTitle: string;
  pointHowRewardWork: string;
  rewardVisibility: string;
  pointHowRewardWorkVisibility: string;
  pointRewardScreenName: string;
  redeemTabVisibility: string;
  statCTAmessage: string;
  historyCTAmessage: string;
}
export interface Chat {
  hideChat: string;
  isAPNSupdateQB: string;
  chat_calling_enable: string;
}
export interface ChannelText {
  channelSceenIndex: string;
  channelListScreenName: string;
  channelCategoriesScreenName: string;
  channelTABName: string;
  channelTABVisibility: string;
  popDialogTimer: string;
  isPopUpVisible: string;
}
export interface Web {
  web_Already_have_an_account: string;
  web_Sign_In: string;
  web_Register_as_a_student: string;
  web_Register_as_a_Doctor: string;
  web_Select_Your_Country: string;
  web_Select_your_Associations: string;
  web_Choose_Your_medical_Association_from: string;
  web_Next: string;
  web_Skip: string;
  web_If_you_are_a_doctor: string;
  web_login_msg: string;
  web_Mobile_number: string;
  web_Login_through_OTP: string;
  web_Sign_Up_through_otp: string;
  web_You_will_receive_one_time: string;
  web_ENTER_OTP: string;
  web_Resend: string;
  web_Submit: string;
  web_Membership_ID: string;
  web_to_verify_your_membership: string;
  web_Input_your: string;
  web_TRY_AGAIN: string;
  web_CONTINUE: string;
  web_User_Detail: string;
  web_First_Name: string;
  web_Last_Name: string;
  web_Email_ID: string;
  web_ok: string;
  web_Select_Your_Specialization: string;
  web_Select_Your_Interest: string;
  web_Profile_Picture: string;
  web_Upload_your_recent_photograph: string;
  web_Upload_Picture: string;
  web_Proof_of_Identity: string;
  web_Upload_the_scanned_copy_of_ID: string;
  web_Edit: string;
  web_Upload_your_Id: string;
  web_Select_Institute_Name: string;
  web_Please_enter_your_mo: string;
  web_Not_a_valid_mobile_number: string;
  web_Not_a_mobile_number: string;
  web_Mobile_number_is_not_link: string;
  web_cancel: string;
  web_signup_to_docquity: string;
  web_Please_enter: string;
  web_is_not_linked_to_anyaccount: string;
  web_Please_Enter_First_Name: string;
  web_Please_Enter_Valid_Email: string;
  web_Select_atleast_one_Interest: string;
  web_Select_speciality: string;
  web_Please_Select_Institute: string;
  web_Please_Select_Image: string;
  web_Search_your_Specilizatio: string;
  web_Search_your_university: string;
  web_Institute_name_is_too_short: string;
  web_Do_you_want_to_add_you_institute_name: string;
  web_Sign_Up: string;
  web_Download_App: string;
  web_Search_your_country: string;
  web_Optional: string;
  web_Registration_No: string;
  web_select_only_jpg_or_png: string;
  web_Enter_Invite_Code: string;
  web_Join_through_Invitation_Code: string;
  web_Verify_Yourself: string;
  web_You_referred_by: string;
  web_Enter_your_phone_number_and_install_the_Docquity_app: string;
  web_How_Invites_Work: string;
  web_Dear_Doctor_You_were_invited: string;
  web_Docquity_Point: string;
  web_New: string;
  web_Taken: string;
  web_Doctor_have_taken_this_course: string;
  web_Points: string;
  web_SOLVE: string;
  web_Take_course: string;
  web_Expired: string;
  web_Not_passed: string;
  web_Completed: string;
  web_back: string;
  web_question: string;
  web_view_course: string;
  web_Expire_on: string;
  web_RETAKE: string;
  web_REVIEW_EXAM: string;
  web_Course_Description: string;
  web_Do_you_really_want_to_exit: string;
  web_You_will_loss_all: string;
  web_You_have_not_met_the_minimum: string;
  web_you_have_successfully_passed: string;
  web_pass: string;
  web_fail: string;
  web_Exit_Course: string;
  web_Previous: string;
  web_Reward_Points: string;
  web_CME_Points: string;
  web_View_Detail: string;
  web_Web_Trends: string;
  web_Trending_Course: string;
  web_Clinical_Case: string;
  web_Insight: string;
  web_Start_A_poll: string;
  web_What_do_you_want_to_share_today: string;
  web_Share_a_clinical_experience_with_peers: string;
  web_Ask_a_Question: string;
  web_Share_a_research: string;
  web_Get_opinion_from_your_community: string;
  web_Add: string;
  web_Photo_Video: string;
  web_or_PDF: string;
  web_To: string;
  web_Tag_to: string;
  web_Association: string;
  web_Specialization: string;
  web_POST: string;
  web_Add_field: string;
  web_Option_1_required: string;
  web_Option_2_required: string;
  web_Edit_Post: string;
  web_posted_in: string;
  web_Delete_Post: string;
  web_more: string;
  web_More: string;
  web_Like: string;
  web_Comment: string;
  web_Share: string;
  web_Facebook: string;
  web_Twitter: string;
  web_Linkedin: string;
  web_Bookmark: string;
  web_People_Like: string;
  web_Update_Comment: string;
  web_Thank_You: string;
  web_Error_msg: string;
  web_Are_you_sure_want_to_delete_this_post: string;
  web_Yes: string;
  web_No: string;
  web_Are_you_sure_want_to_delete_this_comment: string;
  web_Please_provide_the_required_information: string;
  web_Upload_your_ID: string;
  web_Delete: string;
  web_Load_more_comments: string;
  web_Reply: string;
  web_Write_a_comment: string;
  web_like: string;
  web_likes: string;
  web_Load_more_reply: string;
  web_Update: string;
  web_Please_select_Association: string;
  web_Please_tag_to_a_Specialization: string;
  web_case_err_msg: string;
  web_que_err_msg: string;
  web_insight_err_msg: string;
  web_poll_err_msg: string;
  web_Please_input_at_least_2_options: string;
  web_Timeline: string;
  web_Media: string;
  web_About: string;
  web_ABOUT: string;
  web_Education: string;
  web_Add_a_new_education: string;
  web_Add_a_new_professional_experience: string;
  web_Add_Interests: string;
  web_Add_Speciality: string;
  web_Professional_Experience: string;
  web_Basic_Information: string;
  web_Personal_Information: string;
  web_Edit_Profile: string;
  web_country: string;
  web_city: string;
  web_state: string;
  web_Save: string;
  web_Add_Education: string;
  web_University_Institute_Name: string;
  web_Enter_Your_University_Institute: string;
  web_Degree: string;
  web_Enter_Your_Degree: string;
  web_Date_of_Graduation: string;
  web_Currently_Pursuing: string;
  web_Add_Professional_Experience: string;
  web_Hospital_Clinic: string;
  web_Hospital_Name_Clinic_Name: string;
  web_Title_Position: string;
  web_Enter_Your_Title_Position: string;
  web_Location: string;
  web_Enter_Your_Location: string;
  web_Time_Period: string;
  web_Starting_Month: string;
  web_Ending_Month: string;
  web_Starting_Year: string;
  web_Ending_Year: string;
  web_No_Media_posted: string;
  web_You_haven_not_bookmarked_any_Post: string;
  web_No_Timeline_Available: string;
  web_events: string;
  web_Upcomming_events: string;
  web_Hosting: string;
  web_Create_Event: string;
  web_Categories: string;
  web_Going: string;
  web_Not_Interest: string;
  web_Sponsored_by: string;
  web_Edit_Event: string;
  web_Send_invite: string;
  web_Event_QR_code: string;
  web_Show_Map: string;
  web_Edit_QR_Code: string;
  web_Programme: string;
  web_Suggestion_for_you: string;
  web_Created_By: string;
  web_Show_Packages: string;
  web_Log: string;
  web_Packages_for_event: string;
  web_Add_image: string;
  web_Event_Name: string;
  web_Select_Your_Category: string;
  web_Select_Sponsor: string;
  web_Event_Description: string;
  web_Start_date: string;
  web_End_date: string;
  web_Start_time: string;
  web_End_time: string;
  web_Do_you_have_programs: string;
  web_Do_you_want_to_use_your_link_for_event_registration: string;
  web_Enter_event_Register_URL: string;
  web_Please_enter_Event_Name: string;
  web_Please_enter_the_location_of_the_event: string;
  web_Please_select_Event_Category: string;
  web_Please_select_at_least_one_sponsored_by: string;
  web_Please_write_some_description_about_the_event: string;
  web_Please_enter_minimum_five_character_in_description: string;
  web_Please_select_start_date: string;
  web_Please_select_start_time: string;
  web_Program_Detail: string;
  web_Program_Name: string;
  web_Program_Description: string;
  web_Use_event_address_for_this_program: string;
  web_List_of_Program: string;
  web_ADD_MORE: string;
  web_Payment: string;
  web_Packages_Detail_for_Event: string;
  web_Select_your_currency: string;
  web_Please_select_currency: string;
  web_Packages_Detail: string;
  web_Payment_Category: string;
  web_Event_Amount: string;
  web_Early_Bird_Amount: string;
  web_Early_Bird_Date: string;
  web_Add_Package: string;
  web_Packages_Detail_for_Program: string;
  web_Are_you_sure_you_want_to_proceed_next_without_add_any_package: string;
  web_Please_select_payment_category: string;
  web_Price: string;
  web_Please_enter_price: string;
  web_Early_Bird: string;
  web_Early_bird_Date: string;
  web_Please_select_date: string;
  web_Early_bird_Price: string;
  web_Please_enter_early_bird_price: string;
  web_Early_bird_Description: string;
  web_Please_enter_early_bird_description: string;
  web_Payment_for_program: string;
  web_Add_ticket: string;
  web_Name: string;
  web_QR_Code: string;
  web_Event_QR_Code: string;
  web_Generate_QR: string;
  web_use_event_code_for_all_programs: string;
  web_Program_QR_Code: string;
  web_finish: string;
  web_Time: string;
  web_How_Rewards_work: string;
  web_Ways_to_invite_your_friend: string;
  web_Share_on_LinkedIn: string;
  web_Share_on_faceBook: string;
  web_Invite_Via_Email: string;
  web_Share_on_Twitter: string;
  web_Please_Enter_sponsor_Name: string;
  web_Please_enter_sponsor_description: string;
  web_Earned_This_Year: string;
  web_earned: string;
  web_Retake_course: string;
  web_Expired_on: string;
  web_Date: string;
  web_Upload_certificate: string;
  web_Upload: string;
  web_Enter_Your_Points: string;
  web_Title: string;
  web_Enter_title: string;
  web_Select_Source: string;
  web_Total_Score: string;
  web_Select_Category: string;
  web_Add_Course_Points: string;
  web_Select_Sub_Category: string;
  web_Course_Offline: string;
  web_Source: string;
  web_Points_you_earned: string;
  web_View_certificate: string;
  web_Selected_date: string;
  web_Update_CME_Details: string;
  web_Do_you_really_want_to_delete_this_course: string;
  web_View_PDF: string;
  web_Get_Certificate: string;
  web_Hint: string;
  web_Questions: string;
  web_Congratulations: string;
  web_Go_to_Home: string;
  web_Download: string;
  web_No_course_is_available: string;
  web_Confirm: string;
  web_Drug_Sample_List: string;
  web_No_more_drug_sample: string;
  web_No_more_CME_Course: string;
  web_plz_select_tnc: string;
  web_Trending_Cases: string;
  web_Get_Sample: string;
  web_Subscribe: string;
  web_Likes: string;
  web_Error: string;
  web_Add_a_Comment: string;
  web_You_will_lose_all_your_previous_responses: string;
}

export interface Analytics {
  session_time: string;
}

export interface Comment {
  commentExpertLabel: string;
  commentExpertColor: string;
  commentAcceptOpenion: string;
}

export interface Sampling {
  sampleButtonLabel: string;
  samplingFormSubmit: string;
  samplingDrugTitle: string;
  samplingDrugDialogMsg: string;
  sampleButtonBanner: string;
}

export interface Posting {
  showReport: string;
}
export interface Search {
  showSearch: string;
  searchPlaceholder: string;
}

export interface Bookmark {
  showNews: string;
}

export interface Support {
  support_email: string;
}
export interface Call {
  is_call_enable: string;
}
export interface Filesizelimit {
  video_maxlimit: number;
}
export interface ConsentData {
  consent: number;
  target: Target;
}

export interface ProgramList {
  product_id: number;
  display_name: string;
  time_zone: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  start_datetime: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  end_datetime: any;
  product_type: number;
  is_active: boolean;
  hall_list: HallList[];
  session_list: SessionList[];
}

export interface HallList {
  id: number;
  name: string;
  priority: number;
}

export interface SessionList {
  product_id: number;
  display_name: string;
  time_zone: string;
  start_datetime: number;
  end_datetime: number;
  product_type: number;
  mapped_products_list: MappedProductsList[];
}

export interface SearchData {
  header_text: string;
  isStatic: number;
  keywords: Keyword[];
}

export interface Keyword {
  id: number;
  keyword: string;
}

/**
 * Model for Delete profile info items.
 */
export interface DeleteProfileInfoItems {
  id: number;
  text: string;
}

/**
 * Model for Profile hash token.
 */
export interface ProfileHashToken {
  profile_token: string;
  timestamp: number;
}

/**
 * Card rendering data for records.
 */
export interface CardRendering {
  local_id: string;
  retry: boolean;
  track_id: string;
  product_id: number | string;
  product_type: number;
  start_timestamp: number;
  end_timestamp: number;
  session_id: string;
  screen_name: string;
  scroll_type: string;
}

export interface ProfileAction {
  is_profile_deletion_enable: string;
  is_block_user_enable: number;
}

export interface ViewMoreFlags {
  view_more: boolean;
}

export interface Speciality {
  specialty_id: number;
  banner: string;
  logo: string;
  speciality_name: string;
  is_selected: number;
  is_primary: number;
}

export interface Community {
  id: string;
  privacy: string;
  title: string;
  description: string;
  member_type: string;
  flags: CommunityFlags;
  media_list: MediaInfo[];
  specialties: Speciality[];
  members: MemberCountData;
  created_by: CreatedByInfo;
  join_type: string;
  request: CommunityRequest;
  da_id: number;
  loading?: boolean;
  withdrawRequest?: boolean;
  community_id?: string;
  pulsating_post: number;
}

export interface CommunityRequest {
  response: number;
  type: string;
}

export interface CommunityConfirmation {
  openModal: boolean;
  community: Community | GrCommunity;
  callingFrom?: string;
}

export interface CommunityFlags {
  is_active: number;
  is_community_creator: number;
  is_past_community: number;
  show_join_button: number;
}

export interface CreatedByInfo {
  custom_id: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  registered_name: string;
  full_name: string;
  profile_pic: string;
}

export interface MediaInfo {
  media_id: number;
  media_type: string;
  media_name: string;
  media_url: string;
  media_thumbnail: string;
  media_original_url: string;
  media_time_duration: number;
  media_width: number;
  media_height: number;
}
export interface MemberCountData {
  count: number;
  text: string;
}

export interface CommunityList {
  flags: ViewMoreFlags;
  key: string;
  label: string;
  sub_text: string;
  list: Community[];
}

export interface MyCommunityList {
  flags: ViewMoreFlags;
  key: string;
  label: string;
  sub_text: string;
  list: Community[];
}

export interface BreadCrumb {
  name: string;
  path: string;
}

export interface CreateCommunity {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  banner: any[];
  title: string;
  description: string;
  privacy: string;
  specialties: number[];
  members: Members[];
  join_type: string;
}

export interface Members {
  custom_id: string;
  type: string;
}

export interface Banner {
  id: number;
  path: string;
  name: string;
  height: number;
  width: number;
  resolution: number;
}

export interface Member {
  custom_id: string;
  chat_id: number;
  permission: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  registered_name: string;
  connection_status: number;
  is_call_allow: number;
  associations: string;
  chat_allow: number;
  is_connect_enable: number;
  is_user_blocked: number;
  profile_pic: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  specialities: any[];
  full_name: string;
  is_email: number;
  is_community_member: number;
}

export interface UpdateCommunity {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  banner: any[];
  title: string;
  description: string;
  privacy: string;
  specialties: number[];
  join_type: string;

}
/**
 * Create/Edit post interface.
 */
export interface Post {
  feed_type_id: string;
  meta_url: string;
  feed_kind: string;
  media_json: string;
  poll_options: string;
  speciality_json_array: string;
  title: string;
  content: string;
  classification: string;
  meta_array: string;
  comment_flag: number;
  custom_ids: string;
  is_anonymous: number;
  feed_id?: number;
}

export interface ConsentFormValidators {
  required?: boolean;
  minLength?: number;
  maxLength?: number;
  pattern?: string;
  editable: boolean;
}
export interface DynamicFormControl {
  name: string;
  label: Label;
  value: FormValue;
  type: string;
  required: boolean;
  validations: ConsentFormValidators;
  code?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pty?: any;
  title?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: any;
}

export interface Label {
  accept: string;
  reject: string;
  form_title: string;
}

export interface CountryCodes {
  id: number;
  code: number;
  name: string;
}

export interface ConsentFormSubmit {
  name: string;
  value: FormValue;
}

export interface FormValue {
  text: string | number;
  code?: number;
}

export interface NotifyMeConsent {
  consent_stage: string;
  is_consent: number;
  message: string;
  show_checkbox: number;
  is_consent_data_exist: number;
}

export interface CommunityMemberDetail {
  custom_id: string;
  type: string;
  action: string;
}

export interface UpdateCommunityMember {
  members: CommunityMemberDetail[];
}

export interface CommunityMember {
  custom_id: string;
  chat_id: number;
  permission: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  registered_name: string;
  full_name: string;
  profile_pic: string;
  connection_status: number;
  chat_allow: number;
  is_connect_enable: number;
  is_call_allow: number;
  specialities: Speciality[];
  associations: Association[];
  is_email: number;
  is_user_blocked: number;
  is_community_member: number;
  community_member_type: string;
}

export interface TextToSpeech {
  product_id: number;
  product_type: number;
  title: string;
  description: string;
  model: string;
  status: string;
  status_code: number;
  language_id: string;
  text: string;
  s3_mp3_url: string;
  s3_m3u8_url: string;
  markers: Markers[];
  m3u8_signed_cookie: Cookies;
  mp3_signed_cookie: Cookies;
}

export interface Cookies {
  'CloudFront-Policy': string;
  'CloudFront-Signature': string;
  'CloudFront-Key-Pair-Id': string;
}

export interface Markers {
  value: string;
  model_input: string;
  type: string;
  start: number;
  end: number;
  start_time: number;
  end_time: number;
}
export interface AcceptRejectCommunityJoinRequestMember {
  custom_id: string;
  action: string;
}

export interface AcceptRejectJoinRequestCommunity {
  members: AcceptRejectCommunityJoinRequestMember[];
}

export interface ConfirmationModalData {
  headingText: string;
  cancelButtonText: string;
  allowButtonText: string;
  bodyText: string;
}

export interface VerificationSuccessData {
  title: string;
  description: string;
  icon: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  target: any;
}

export interface ContinueWatchList {
  info: {
    title: string;
    banner: string;
    card_target: {
      id: string;
      url: string;
      type: string;
    }
    time: {
      total: number;
      watch: number;
    }
  };
  product_id: number;
  product_type: number;
  template: number;
}

export interface SearchCommunity {
  id: string;
  da_id: number;
  title: string;
  description: string;
  privacy: string;
  media_list: MediaList[];
  specialities: SearchSpeciality[];
  created_by: CreatedBy;
  member_count: number;
}

export interface MediaList {
  media_id: number;
  media_type: string;
  media_name: string;
  media_url: string;
  media_time_duration: number;
  media_thumbnail: string;
  media_original_url: string;
  media_width: number;
  media_height: number;
}
export interface SearchSpeciality {
  speciality_id: number;
  speciality_name: string;
}
export interface CreatedBy {
  custom_id: string;
  full_name: string;
  profile_pic: string;
}

export enum CommunityType {
  MY_COMMUNITY = 'myCommunity',
  PUBLIC_COMMUNITY = 'publicCommunity'
}

export enum COMMUNITY_PAGE {
  COMMUNITY_LIST = 'COMMUNITY_LIST',
  PUBLIC_LIST = 'PUBLIC_LIST',
  MY_COMMUNITY_LIST = 'MY_COMMUNITY_LIST',
  PAST_INACTIVE_COMMUNITY = 'PAST_INACTIVE_COMMUNITY'
}

export interface CommunityFilter {
  id: string;
  value: string;
  checked: string;
  labelText: string;
  page: COMMUNITY_PAGE;
  enable: number;
  isPastCommunity: boolean;
}



export interface GrFlags {
  show_join_button: number;
  is_community_creator: number;
}

export interface GrRequest {
  response: number;
  type: string;
}

export interface GrCreatedBy {
  custom_id: string;
  full_name: string;
  profile_pic: string;
}

export interface PostedIn {
  isAssociation: boolean;
  name: string;
  count: number;
  remainingCount?: number;
  id: string;
}
export interface CarousalRoute {
  view: string;
}

export interface HMargin {
  ICCurrentHRootMargin: string;
  bannerCurrentHRootMargin: string;
  ncpCurrentHRootMargin: string;
  drugCurrentHRootMargin: string;
  channelCurrentHRootMargin: string;
  publicCommunityCurrentHRootMargin: string;
}

export interface GrCommunity {
  attributes?: Attributes;
  community_id?: string;
  created_by: GrCreatedBy;
  da_id: number;
  description: string;
  flags: GrFlags;
  id?: string;
  join_type: string;
  loading?: boolean;
  media_list: MediaList[];
  member_type?: string;
  privacy: string;
  product_id: number;
  product_type: number;
  promotional_text?: string;
  request: GrRequest;
  specialities?: SearchSpeciality[];
  title: string;
  withdrawRequest?: boolean;
}

// tslint:disable-next-line: class-name
export interface userComment {
  title: string;
  id: number;
}
export interface Lc {
  app_config: number;
  user_config: number;
  gr_refresh: number;
  update: Update;
  analytics: Analytics;
  flags: Flags;
  urls: Urls;
  maintenance: Maintenance;
  upload_limit: UploadLimit;
  media_urls: MediaUrls;
  support: Support;
}

export interface Update {
  app: App;
  lang: Lang;
}

export interface App {
  hard_update_version: string;
  soft_update_mandatory: string;
  soft_update_optional: string;
}

export interface Lang {
  version: Version;
  base_url: string;
}

export interface Version {
  IN: string;
  EN: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  VI: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  KO: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ZH: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  JA: any;
}

export interface Analytics {
  record: number;
  time: number;
  BG_session: number;
}

export interface Flags {
  default_icon: boolean;
  contact_on_login: boolean;
}

export interface Urls {
  calendar: string;
  amazon: string;
  privacy_policy: string;
  terms_and_condition: string;
  analytics: string;
  chat_credentials: string;
  auto_verification: string;
  onboarding: string;
  quickblox: string;
  voip_node: string;
  comment: string;
  bookmark: string;
  event: string;
  default: string;
  voip: string;
  search: string;
  community: string;
  ncp: string;
  card_rendering_analytics: string;
  editor: string;
  user: string;
  clinic_consent: string;
  chat_bot: string;
  mr: string;
  webinar: string;
  payment: string;
  nudge: string;
  background: string;
  report_user: string;
  referral: string;
  notification: string;
  gr: string;
  verification: string;
  others: string;
  pharma: string;
  edetailing: string;
  survey: string;
  profile: string;
  journal: string;
  feedback: string;
  socket: string;
  doctalks: string;
  cme: string;
  like: string;
  feed: string;
  socket_comment: string;
  speciality: string;
  chat: string;
  registration: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  media_upload: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  meta_data: any;
}

export interface Maintenance {
  enable: boolean;
  message: string;
  banner: string;
}

export interface UploadLimit {
  chat: number;
  comment: number;
  post: number;
  deafult: number;
}

export interface MediaUrls {
  default_image: string;
  default_video: string;
  default_document: string;
  default_icon: string;
  optimize_image: string;
  optimize_video: string;
  optimize_document: string;
  optimize_doc: string;
  optimize_icon: string;
}
export interface Lc {
  app_config: number;
  user_config: number;
  gr_refresh: number;
  update: Update;
  analytics: Analytics;
  flags: Flags;
  urls: Urls;
  maintenance: Maintenance;
  upload_limit: UploadLimit;
  media_urls: MediaUrls;
  support: Support;
}

export interface Update {
  app: App;
  lang: Lang;
}

export interface App {
  hard_update_version: string;
  soft_update_mandatory: string;
  soft_update_optional: string;
}

export interface Lang {
  version: Version;
  base_url: string;
}

export interface Version {
  IN: string;
  EN: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  VI: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  KO: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ZH: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  JA: any;
}

export interface Analytics {
  record: number;
  time: number;
  BG_session: number;
}

export interface Flags {
  default_icon: boolean;
  contact_on_login: boolean;
}

export interface Urls {
  calendar: string;
  amazon: string;
  privacy_policy: string;
  terms_and_condition: string;
  analytics: string;
  chat_credentials: string;
  auto_verification: string;
  onboarding: string;
  quickblox: string;
  voip_node: string;
  comment: string;
  bookmark: string;
  event: string;
  default: string;
  voip: string;
  search: string;
  community: string;
  ncp: string;
  card_rendering_analytics: string;
  editor: string;
  user: string;
  clinic_consent: string;
  chat_bot: string;
  mr: string;
  webinar: string;
  payment: string;
  nudge: string;
  background: string;
  report_user: string;
  referral: string;
  notification: string;
  gr: string;
  verification: string;
  others: string;
  pharma: string;
  edetailing: string;
  survey: string;
  profile: string;
  journal: string;
  feedback: string;
  socket: string;
  doctalks: string;
  cme: string;
  like: string;
  feed: string;
  socket_comment: string;
  speciality: string;
  chat: string;
  registration: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  media_upload: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  meta_data: any;
}

export interface Maintenance {
  enable: boolean;
  message: string;
  banner: string;
}

export interface UploadLimit {
  chat: number;
  comment: number;
  post: number;
  deafult: number;
}

export interface MediaUrls {
  default_image: string;
  default_video: string;
  default_document: string;
  default_icon: string;
  optimize_image: string;
  optimize_video: string;
  optimize_document: string;
  optimize_doc: string;
  optimize_icon: string;
}

export enum ToastrType {
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info',
  WARNING = 'warning',
  PROGRESS = 'progress',
  NOTIFICATION = 'notification',
}
export interface ToastButton {
  primaryCTAText: string;
  secondaryCTAText: string;
}
export interface ToastrConfig {
  toastType: ToastrType;
  timeout: number;
  message?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  title?: any;
  toastButtons?: ToastButton;
}
export interface TokenDetail {
  at: string;
  rt: string;
  expin: number;
  rexpin: number;
}
export interface Signature extends TokenDetail {
  mc?: string;
  m?: string;
  ttype: string;
}

export interface CommunityObject {
  product_id: number, 
  product_type: number, 
  comment: any, 
  comment_count: number, 
  kind: string
}

/**
 * Samvaad login configuration.
 */
export interface SamvaadConfig {
  appName: string;
  host: string;
  persist: boolean;
  secure: boolean;
  transport: 'ws' | 'lp';
  apiKey: string;
  platform: 'web' | 'ios' | 'android';
}
export interface Ratings {
  id: number;
  text?: string;
  name?: string;
  class?: string;
  activeClass?: string;
  activeUrl?: string;
  url?: string;
  isActive: number;
}

export interface SurveyData {
  ptype: string;
  screen: string;
  surveyId: string;
  uuid: string;
  deviceType: string;
  lang: string,
  surveyType: string;
}

export interface BackPressEvent {
  deviceType: string;
  isBackPressClickedFromAndroid: string;
}

export interface openArticle {
  id: number;
  type: number;
  title: string;
  banner: string;
  speciality: Specialities[];
}

export interface Specialities {
  id: number;
  name: string;
}

export interface openArticleDetail {
  id: number;
  type: number;
  title: string;
  description: string;
  banner_image: string;
  login_required: boolean;
  publish_date: number;
  speciality: Specialities[];
  share_url: string;
}

export interface openAccessBranch {
  extras? : Extras,
  pid : number,
  ptype : number,
}

export interface Extras {
  welcome_popup : number,
  name: string,
  id: string
}

export interface SdkCallback {
  element: HTMLElement,                 //SDK parent element
  view: boolean,                        //SDK visibility flag
  tokenResponse?: LoginSdkAuthDataResponse,  //access token response from Magistrate
  properties : SdkCallbackProperties
}

export interface SdkCallbackProperties {
  newUser: boolean,
  language: string,
  isSocialLogin: boolean
}

export interface LoginSdkParameter {
  country: string,        //consumer country code
  language: string,       //consumer language
  version: string,        //consumer version
  module: string,         //client module code
  client: string,         //consumer client
  page: string,           //default page to load
  defaultAction: boolean  //global default action
  disableClose: boolean //disable close button on sdk
}

export interface LoginSdkAuthDataResponse extends Signature {
  newUser?: boolean;
  otp_valid?: boolean;
}

export interface LoginSdkResponse extends LoginSdkAuthDataResponse {
  newUser : boolean;
  otp_valid : boolean;
}