import { Injectable } from '@angular/core';
import io from 'socket.io-client';
import { environment } from '../../environments/environment';
import { AuthService } from '../auth/auth.service';
// TODO: Pavan Need remove commented code and unused code in upcoming sprint.
// import { HistoricalPollResponseData } from 'src/app/onboarding/interface/apiResponse.model';
import { HistoricalPoll } from '../onboarding/interface/global.model';
/**
 * constant import
 */
import { NUMBERS, WEBINAR_POLL_STATUS } from '../constants/app.constants';

/**
 * This service is used for live poll.
 */
@Injectable({
  providedIn: 'root'
})

// TODO:Pavan Need to remove all console from this service.
export class PollSocketService {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  socket: any;
  roomName = '';
  nameSpace = '/docquity_v3/ns_poll';
  /**
   * Flag for socket connection.
   */
  public isConnected = false;
  public newJoinRequest = 'yes';
  public token = '';
  public pType = NUMBERS.ZERO;
  public pId = NUMBERS.ZERO;
  pollStart = '';
  pollEnd = '';
  pollResponse = '';
  public livePollIndex = NUMBERS.MINUS_ONE;
  public historicalPollData: HistoricalPoll[];
  public isLivePollModalShown = false;
  webinarStatus = '';
  public showLivePollButton = NUMBERS.ZERO;
  public hasLivePoll = false;
  public livePollUserActivity = false;

  /**
   * Created all required instances.
   */
  constructor(private authService: AuthService) {
  }

  /**
   * Socket connection.
   */
  connectToSocket(): void {
    if (!this.token) {
      this.token = this.authService.getToken();
    }
    // eslint-disable-next-line no-constant-condition
    if (this.isConnected === false || 1) {
      this.socket = io.connect(
        environment.API_URLS.socket_comment + this.nameSpace,
        {
          upgrade: false,
          transports: ['websocket'],
          forceNew: false
        });

      this.isConnected = true;
    }

    this.socket.on('reconnect', () => {
      this.isConnected = false;
      this.socket?.disconnect();
      this.connectToSocket();
      this.joinRoom(this.pType, this.pId );
    });

  }

  resetSocketService(): void {
    this.livePollIndex = NUMBERS.MINUS_ONE;
    this.isLivePollModalShown = false;
    this.pType = NUMBERS.ZERO;
    this.pId = NUMBERS.ZERO;
    this.hasLivePoll = false;
    this.historicalPollData = [];
  }

  /**
   * Join Room
   */
  joinRoom(pType, pId): void {
    this.livePollUserActivity = !this.livePollUserActivity;
    const lang = 'en';
    this.pType = pType;
    this.pId = pId;
    this.pollStart = pType + '_' + pId + '_poll_start';
    this.pollEnd = pType + '_' + pId + '_poll_end';
    this.pollResponse = pType + '_' + pId + '_poll_response';
    const roomName = pType + '-' + pId + '-' + lang;
    this.socket.emit('joinRoom', {

      'A1': this.token,
      'S1': roomName,
      'S2': pType,
      'S3': pId,
      'S4': this.newJoinRequest,
    }, (db) => {
      this.newJoinRequest = 'no';

    });

    this.socket.on(this.pollStart, (res) => {
      this.livePollUserActivity = !this.livePollUserActivity;
      this.showLivePollButton = NUMBERS.ONE;

      if (this.historicalPollData.findIndex(obj => obj.poll_id === res.data.poll_id) === NUMBERS.MINUS_ONE) {
        this.historicalPollData = this.historicalPollData.concat(res.data);
        this.livePollIndex = this.historicalPollData.findIndex(obj => obj.poll_id === res.data.poll_id);
      }
      this.hasLivePoll = true;
      if (!this.isLivePollModalShown) {
        this.isLivePollModalShown = true;
      }
    });


    this.socket.on(this.pollEnd, (res) => {
      this.livePollUserActivity = !this.livePollUserActivity;
      this.hasLivePoll = false;
      this.historicalPollData.find(obj => {
        if (obj.poll_id === res.data.poll_id) {
          obj.state = WEBINAR_POLL_STATUS.END;
          obj.options.map(opt => {
            const updatedPollObj = res.data.options.find(updatedOpt => updatedOpt.option_id === opt.option_id);
            if (updatedPollObj) {
              opt.percentage = updatedPollObj.percentage;
            }
          });
        }
      });
    });

    this.socket.on(this.pollResponse, (res) => {
      this.livePollUserActivity = !this.livePollUserActivity;

      this.historicalPollData.find(obj => {
        if (obj.poll_id === res.data.poll_id) {
          obj.options.map(opt => {
            const updatedPollObj = res.data.options.find(updatedOpt => updatedOpt.option_id === opt.option_id);
            if (updatedPollObj) {
              opt.percentage = updatedPollObj.percentage;
            }
          });
        }
      });

    });
  }

  disconnectSocket(): void {
    this.socket?.close();
    this.isConnected = false;
    this.newJoinRequest = 'yes';
    this.connectToSocket();
  }
}
