/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Angular core imports
 */
import { Injectable, OnDestroy } from '@angular/core';
import { HttpBackend, HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';

/**
 * rxjs import
 */
import { BehaviorSubject, EMPTY, Observable, Subject } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';

/**
 * external dependency
 */
import * as moment from 'moment';
/**
 * service imports
 */
import { LocalstorageService } from './localstorage.service';
import { AuthService } from './auth.service';
import { MobileWebViewCommunicationService } from '../dataservices/mobileWebViewCommunication/mobile-web-view-communication.service';

/**
 * model imports
 */
import { UserTokenResponse } from '../onboarding/interface/apiResponse.model';
import { Signature, TokenDetail } from '../onboarding/interface/global.model';

/**
 * constant imports
 */
import { API } from '../constants/api.constants';
import { DEFAULT_COUNTRY_CODE, DEFAULT_IP_ADDR, DEFAULT_TIMEZONE, ERROR_CODES, GATEWAY_STATUS_CODE, MODULE_NAME, NUMBERS, WEBVIEW_MOBILE_METHOD_POST, WEBVIEW_MOBILE_PARAMS } from '../constants/app.constants';

/**
 * Environment imports.
 */
import { environment } from '../../environments/environment';



@Injectable({
  providedIn: 'root'
})
export class AccessTokenService implements OnDestroy{

  /**
   * Device type.
   */
  private DEVICE_TYPE = environment.deviceType;

  /**
   * to store access and refresh token detail
   */
  public token: TokenDetail;

  /**
   * subject to store token detail
   */
  private tokenDetailSubject = new BehaviorSubject<TokenDetail>(null);
  /**
   * Observable to store latest value from tokenDetailSubject
   */
  public tokenDetail$ = this.tokenDetailSubject.asObservable();

  /**
  * time interval to renew the access token
  */
  private accessTokenRenewInterval = localStorage.getItem('renewInterval') || 0;
  /**
   * to store environment configuration
   */
  private refreshIntervalPercentage = environment.accessTokenRenewIntervalPercentage;
  /**
   * stream to cache API request
   */
  public tokenRequest$: Observable<any>;
  /**
   * keep track of error count
   */
  private caughtErrorCount = 0;
  /**
   * notifier subject to remove subscription
   */
  private notifier$ = new Subject();

  constructor(
    private storageService: LocalstorageService,
    private httpClient: HttpClient,
    private authService: AuthService,
    private handler: HttpBackend,
    private mvc: MobileWebViewCommunicationService
  ) { }

  /**
   * checks access token detail availability in storage
   * and trigger request if not available or when
   * access token and refresh token already expired
   */
  isAccessTokenAvailable(): Observable<Signature> {
    this.getTokenDetailsFromStorage();
    // this.token is magistrate token of docquity 2.0
    const user_auth_key = localStorage.getItem('token');
    if( user_auth_key &&  (!this.token || !this.token?.at || this.isAccessTokenExpired)) {
        return this.handleAccessTokenRequest();
    } else {
      if(this.isAccessTokenRenewRequired && !this.isAccessTokenExpired && !this.isRefreshTokenExpired ) {
        return this.getAccessTokenByRefreshToken(); // generate new token 
      } else if(this.isAccessTokenRenewRequired && (this.isAccessTokenExpired || this.isRefreshTokenExpired)){
        //logout
         this.authService.setLogoutRequest({callAPI: false, reload: true});
      }else{
       // this.authService.setLogoutRequest({callAPI: false, reload: true});
        return EMPTY;
      }
    }
  }

  /**
   * initiate access token request
   * @returns
   */
  handleAccessTokenRequest(): Observable<Signature|any> {
    let userAuthKey = this.authService.getToken();
    if(!userAuthKey) {
      userAuthKey = this.authService.byPassAuthGetbyName('token');
    }
    return userAuthKey && this.getAccessToken().pipe(
      map(response => {
          if(response.status === NUMBERS.ONE
            && response?.data?.signature
            && response?.data?.signature?.mc === GATEWAY_STATUS_CODE.GATEWAY_1003
          ) {
            // eslint-disable-next-line no-unsafe-optional-chaining
            const {at, rt, expin, rexpin, ttype} = response?.data?.signature;
            this.setTokenDetailsToStorage({at, rt, expin, rexpin});
            this.calculateRefreshInterval(expin);
            if(this.mvc.checkAuthenticByPass()) {
              this.mvc.sendInfoToBothMobile({
                method: WEBVIEW_MOBILE_METHOD_POST,
                params: WEBVIEW_MOBILE_PARAMS.TOKEN_GENERATED,
                body: {
                  at, rt, expin, rexpin, ttype
                }
              });
            }
            this.tokenRequest$ = undefined;
            return response?.data?.signature;
          } else {
            if(response.status === NUMBERS.ZERO) {
              if(response.error.code === ERROR_CODES.USER_NOT_AUTHORISED) {
                this.authService.setLogoutRequest({callAPI: false, reload: true});
              }
            }
          }
      })
    );
  }

  /**
   * sends API request for access token
   */
  getAccessToken(): Observable<UserTokenResponse> {
    if(!this.tokenRequest$) {
      this.tokenRequest$ = this.httpClient.get<UserTokenResponse>(API.USER_TOKEN).pipe(shareReplay(1));
    }
    return this.tokenRequest$;
  }

  /**
   * sends refresh token API request to Magistrate Gateway
   * @returns
   */
  getAccessTokenByRefreshToken(): Observable<Signature|any> {
    return this.token?.at && this.getATbyRT().
      pipe(
        map(response => {
          if(response.mc === GATEWAY_STATUS_CODE.GATEWAY_1003) {
            const {at, rt, expin, rexpin, ttype} = response;
            this.setTokenDetailsToStorage({at, rt, expin, rexpin});
            this.calculateRefreshInterval(expin);
            if(this.mvc.checkAuthenticByPass()) {
              this.mvc.sendInfoToBothMobile({
                method: WEBVIEW_MOBILE_METHOD_POST,
                params: WEBVIEW_MOBILE_PARAMS.REFRESH_TOKEN_GENERATED,
                body: {
                  at, rt, expin, rexpin, ttype
                }
              });
            }
            return response;
          }
        }),
        catchError((error: HttpErrorResponse): Observable<void> => {
          // Handle the error as needed
          if (error.status == ERROR_CODES.UNAUTHORIZED) {
            //clear the cache and Logout the user when API returns unauthorized
            if(error.error?.mc == GATEWAY_STATUS_CODE.GATEWAY_1010 || this.authService.getToken().length == 0 ) {
              this.authService.setLogoutRequest({callAPI: false, reload: true});
            }
            this.caughtErrorCount++;
            if(this.caughtErrorCount === NUMBERS.ONE) {
              this.tokenRequest$ = undefined;
            }
            return this.handleAccessTokenRequest();
          }
          return EMPTY;
        })
      );
  }

  /**
   * sends API request for access token
   */
  getATbyRT(): Observable<Signature> {
    const httpClient = new HttpClient(this.handler);
    const headers = this.refreshTokenHeader();
    const payload = new HttpParams()
          .set('token', this.token.rt);

    if(!this.tokenRequest$) {
      this.tokenRequest$ = httpClient.post<Signature>(`${environment.API_URLS.magistrate}${API.REFRESH_TOKEN}`, payload, {headers}).pipe(shareReplay(1));
    }
    return this.tokenRequest$;
  }
  /**
   * property to check access token expiration
   */
  get isAccessTokenExpired(): boolean {
    const currentUtcTimestamp = moment.utc().unix();
    const diff = this.token.expin - currentUtcTimestamp;
    
    return diff < NUMBERS.ONE ;
  }
  /**
   * property to check access token expiration
   */
  get isAccessTokenRenewRequired(): boolean {
    return this.compareTimestamp(this.token.expin, +this.accessTokenRenewInterval);
  }

  /**
   * property to check refresh token expiration
   */
  get isRefreshTokenExpired(): boolean {
    return this.compareTimestamp(this.token.rexpin, NUMBERS.ZERO);
  }

  /**
   * set magistrate token details to local storage
   * @param data
   */
  setTokenDetailsToStorage(data: TokenDetail): void {
    this.storageService.setInLocalstorage('mcTokenDetail', JSON.stringify(data));
    this.token = {...data};
    this.tokenDetailSubject.next(this.token);
  }

  /**
   * retrieves token details from local storage
   */
  getTokenDetailsFromStorage(): void {
    this.token = JSON.parse(this.storageService.getInLocalStorage('mcTokenDetail')) || null;
  }

  /**
   * Generate LocalId,
   * which is the combination of TimeStamp UNIX Device Type and Four digit random number.
   */
  generateLocalId(): string {
    return `${this.getCurrentTimestamp()}${this.DEVICE_TYPE}${this.generateRandomFourDigits(9000)}`;
  }

  /**
   * Get Current Timestamp 13 digits.
   */
  getCurrentTimestamp(): number {
    return new Date().getTime();
  }

  /**
   * Generate the random four digits.
   */
  generateRandomFourDigits(digits: number): number {
    return Math.floor(1000 + Math.random() * digits);
  }

  /**
   * Compare two UTC timestamp and returs a boolean
   * @param utcTimestamp
   * @param timeDelayInSeconds
   * @returns
   */
  compareTimestamp(utcTimestamp: number, timeDelayInSeconds: number): boolean {
    const localUtcTimestamp = moment.utc().unix();
    const isPast = (utcTimestamp - localUtcTimestamp) <= timeDelayInSeconds;
    return isPast;
  }

  /**
   * set httpHeader for API request
   * @returns
   */
  refreshTokenHeader(): HttpHeaders {
    const headers = new HttpHeaders()
    .set('Content-Type', 'application/x-www-form-urlencoded')
    .set('Accept-Language', this.storageService.getInLocalStorage('lang'))
    .set('Allow-Origin', window.location.hostname)
    .set('Timezone', DEFAULT_TIMEZONE)
    .set('Country-Code', this.storageService.getInLocalStorage('country_code') || DEFAULT_COUNTRY_CODE)
    .set('Device-Type', this.DEVICE_TYPE)
    .set('IP-Address', this.storageService.getInLocalStorage('ip_addr') || DEFAULT_IP_ADDR)
    .set('Application-Version', environment.versions.appVersion)
    .set('module', MODULE_NAME.DQ)
    .set('Request-Id', this.generateLocalId())
    .set('Authorization', `Bearer ${this.token.at}`)
    return headers;
  }

  /**
   * calculates timeinterval in seconds
   * for access token renewal
   * @param timestamp
   */
  calculateRefreshInterval(timestamp: number): void {
    const localUtcTimestamp = moment.utc().unix();
    this.accessTokenRenewInterval = (this.refreshIntervalPercentage/100) * (timestamp - localUtcTimestamp);
    localStorage.setItem('renewInterval', Math.floor(this.accessTokenRenewInterval).toString());
  }
  /**
   * removed subscription and event listeners
   */
  ngOnDestroy(): void {
    this.notifier$.next();
    this.notifier$.complete();
  }
}
