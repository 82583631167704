/**
 * Angular imports.
 */
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

/**
 * Component imports.
 */
import { MultiselectDdlComponent } from './multiselect-ddl.component';
import { BasicSelectComponent } from './basic-select/basic-select.component';

/**
 * Module imports.
 */
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';


@NgModule({
  declarations: [
    MultiselectDdlComponent,
    BasicSelectComponent
  ],
  imports: [
    CommonModule,
    AngularMultiSelectModule,
    FormsModule
  ],
  exports: [
    MultiselectDdlComponent,
    BasicSelectComponent
  ]
})
export class MultiselectDdlModule { }
