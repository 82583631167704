/**
 * Angular Import.
 */
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

/**
 * Services Import.
 */
import { OnboardingService } from '../../../dataservices/onboarding/onboarding.service';
import { MasterserviceService } from '../../../dataservices/master-service/masterservice.service';
import { MultiLangService } from '../../../dataservices/master-service/multi-lang.service';
import { NewLanguageService } from '../../../dataservices/new-language/new-language.service';
import { JwtDecodeService } from '../../../dataservices/jwt-decode.service';
import { ButtonDirective } from './../../../directives/ga.directive';
import { DataStorageService } from './../../../dataservices/data-storage.service';
import { FunCollectionService } from './../../../common/common-functions/fun-collection.service';

/**
 * Rxjs Import.
 */
import { Subscription } from 'rxjs';

/**
 * Student type Component
 */
@Component({
  selector: 'app-studenttype',
  templateUrl: './studenttype.component.html',
  styleUrls: ['./studenttype.component.scss']
})
export class StudenttypeComponent implements OnInit, OnDestroy {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public verify_mobile: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public profession_data: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public user_mob_data: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public req_data: any;

  /**
   * Object for start and end time used for analytics.
   */
  public sessiontime = { 'start_time': '', 'end_time': '' };

  /**
   * To unsubscribe data.
   */
   private subscriptions: Subscription = new Subscription();


  /**
   * Key is used to get page from for analytics.
   */
  public page_from = this.mls.googleEventText.studenttype_screen_visit.category;


  /**
   * Necessary instances
   */
  constructor(public route: ActivatedRoute,
              private jwtSer: JwtDecodeService,
              private masterService: MasterserviceService,
              public multiLangService: MultiLangService,
              private router: Router,
              public fcs: FunCollectionService,
              public Dss: DataStorageService,
              private directive_ser: ButtonDirective,
              public onboardingService: OnboardingService,
              public mls: NewLanguageService) { }


  /**
   * ngOnInit
   */
  ngOnInit(): boolean {
    if (sessionStorage.getItem('user_mob_data') != null) {
      this.user_mob_data = JSON.parse(sessionStorage.getItem('user_mob_data'));
    }
    if (sessionStorage.getItem('req_data') != null) {
      this.req_data = JSON.parse(sessionStorage.getItem('req_data'));
    }

    if (sessionStorage.getItem('select_profession') == null) {
      this.router.navigate(['/login/input-mobile'], {
        queryParams: {
          redirectUrl: this.route.snapshot.queryParams.redirectUrl
        }
      });

      return false;
    } else {
      this.verify_mobile = JSON.parse(sessionStorage.getItem('select_profession'));
      if (this.verify_mobile.status == 1) {
        this.profession_data = Object.entries(this.verify_mobile.data.screen_data.data.profession[this.user_mob_data.usertype_idx].sub_profession);

      }
    }

    this.fcs.IsUserActiveOnApp(this.page_from);
    this.subscriptions.add(this.Dss.is_browser_tab_active_action.subscribe(data => {
      if (data.page_from == this.page_from && data.is_visible == 0) {
        const d = new Date();
        this.sessiontime.end_time = d.getTime().toString();
        this.directive_ser.productDetailEvent(Object.assign({}, this.sessiontime), this.mls.googleEventText.studenttype_screen_visit.key1, '', '', this.page_from, '');
      } else if (data.page_from == this.page_from && data.is_visible == 1) {
        setTimeout(() => {
          const dd = new Date();
          this.sessiontime.start_time = dd.getTime().toString();
        }, 1000);
      }
    }));
  }

  /**
   * screen back
   */
  screen_back(): void {
    window.history.back();
  }

  /**
   * select Profession
   */
  selectProfession(data): boolean {
    if (this.masterService.isOnline == false) {
      this.masterService.isConnectionModalShown.next('1');
      return false;
    }
    const user_input_json = [{
      'key': 'select_profession',
      'value': { 'user_type_id': data.id }
    }];
    this.subscriptions.add(this.onboardingService.userRegister(this.req_data.token_id, user_input_json, this.verify_mobile.data.screen_data.is_skippable).subscribe(registerPromise => {
      registerPromise.then(data => {
        if (data['status'] == 1) {
          if (data['code'] == 2006) {
            sessionStorage.setItem('verify_mobile', JSON.stringify(data));
            if (data['data'].screen_data.identifier == 'select_profession') {
              this.router.navigate(['/login/usertype'], {
                queryParams: {
                  redirectUrl: this.route.snapshot.queryParams.redirectUrl
                }
              });
            }
            if (data['data'].screen_data.identifier == 'personal_information') {
              sessionStorage.setItem('personal_information', JSON.stringify(data));
              this.router.navigate(['/login/userdetail'], {
                queryParams: {
                  redirectUrl: this.route.snapshot.queryParams.redirectUrl
                }
              });
            }
            if (data['data'].screen_data.identifier == 'verify_user') {
              this.router.navigate(['/login/verify'], {
                queryParams: {
                  redirectUrl: this.route.snapshot.queryParams.redirectUrl
                }
              });
            }
            if (data['data'].screen_data.identifier == 'select_university') {
              this.router.navigate(['/login/university'], {
                queryParams: {
                  redirectUrl: this.route.snapshot.queryParams.redirectUrl
                }
              });
            }
          }
        }
      });
    }));
  }

  /**
   * Function is used to Destroy running component functionality when component changes.
   */
  ngOnDestroy(): void {
    const d = new Date();
    this.sessiontime.end_time = d.getTime().toString();
    this.directive_ser.productDetailEvent(Object.assign({}, this.sessiontime), this.mls.googleEventText.studenttype_screen_visit.key1, '', '', this.page_from, '');
    this.fcs.IsUserActiveOnApp('');
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
}
