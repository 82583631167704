/**
 * Angular imports.
*/
import { Component, EventEmitter, Output, Input, OnInit, OnDestroy } from '@angular/core';
/**
 * Service imports.
*/
import { NewLanguageService } from './../../dataservices/new-language/new-language.service';
import { AnalyticsEventService } from '../../analytics-event/analytics-event.service';
import { PostApiService } from '../../dataservices/post-api.service';
import { DataStorageService } from '../../dataservices/data-storage.service';
/**
 * Constant imports.
 */
import { TUTORIAL_FOR } from './../../constants/app.constants';
/**
 * model imports
 */
import { EventDataKey } from '../../analytics-event/analytics.model';
/**
 * analytic constant import
 */
import { EVENT_NAME } from '../../analytics-event/analytics.constant';
/**
 * rxjs import
 */
import { Subscription } from 'rxjs';

/**
 * constant imports
 */
import { SCREEN_NAME } from './../../constants/app.constants';
/**
 * InfoModal Component
 */
@Component({
  selector: 'app-tag-connections-pop-over',
  templateUrl: './tag-connections-pop-over.component.html',
  styleUrls: ['./tag-connections-pop-over.component.scss']
})
export class TagConnectionsPopOverComponent implements OnInit, OnDestroy {
  /**
   * Title of the tutorial.
   * Banner of the tutorial.
   * Message of the tutorial.
   * CTA of the tutorial.
   * Tutorial is for.
   * Arrow position css.
   * Tutorial position css.
   */
   @Input() tutorialTitle = this.mls?.languageText?.tutorial?.key1;
   @Input() tutorialMsg = this.mls?.languageText?.tutorial?.key2;
   @Input() tutorialCta = this.mls?.languageText?.tutorial?.key3;
   @Input() isTutorialFor = TUTORIAL_FOR.TAG_CONNECTION;
   @Input() comment_box_obj;
   @Input() callingFrom;
    
   /**
    * Emit start request outside.
    * Emit hide request outside.
    */
   @Output() emitStartRequest: EventEmitter<void> = new EventEmitter();
   @Output() emitHideModal: EventEmitter<void> = new EventEmitter();

  /**
   * Flag for the tutorial constant.
   */
  public tutorialFor = TUTORIAL_FOR;

  /**
   * flag variabele for tag info card
   */
  public isTagUserTutorialEnabled = false;

  /**
   * screen name for doctalk detail and webinar detail
   */
  public doctalkDetail = SCREEN_NAME.DOCTALKS_DETAIL;

  public webinarDetail = SCREEN_NAME.WEBINAR_DETAIL;
  /**
   * to unsubscribe data
   */
  private subscriptions: Subscription = new Subscription();
  /**
   * Necessary instances
   */
  constructor(
    public mls: NewLanguageService,
    private analyticsEventService: AnalyticsEventService,
    private postService: PostApiService,
    private dss: DataStorageService
  ) { }


  /**
   * function to log analytics event
   */
  sendAnalytics(eventName: EventDataKey, screenName: string, productType: number, productTypeId: number, startTime = this.getTimeString(), endTime = this.getTimeString()): void {
    const analyticsTagSpecilizationData = {
      event_name: EVENT_NAME.TOOLTIP_CROSS_TU_CLICK,
      product_type: productType,
      product_type_id: productTypeId,
      screen_name: screenName,
      start_time: startTime,
      end_time: endTime
    };
    this.analyticsEventService.logAnalyticsEvent(analyticsTagSpecilizationData);
  }
  /**
   * hide Modal
   */
  hideModal(): void {
    this.dss.isTagUserTutorialEnabled.next(false);
    // logging api
    this.subscriptions.add(this.postService.logTutorialView('tag_user').subscribe(data => {
      if (data?.status) {
        this.sendAnalytics(EVENT_NAME.TOOLTIP_CROSS_TU_CLICK, this.callingFrom, this.comment_box_obj?.product_type_id, this.comment_box_obj?.product_id);
        this.emitHideModal.emit();
      }
    })
    );
  }
  /**
   * Request for pip.
   */
  startTutorial(): void {
    this.emitStartRequest.emit();
  }
  /**
   * Get the current time in string.
   */
  getTimeString(): string {
    return new Date().getTime().toString();
  }

  ngOnInit(): void {
    this.subscriptions.add(this.dss.isTagUserTutorialEnabled.subscribe(status => {
      if (status) {
        this.isTagUserTutorialEnabled = true;
      } else {
        this.isTagUserTutorialEnabled = false;
      }
    }));
  }
  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

}
