// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

const auth_pass = generateRandStr(15); // pass the length of random generated string
// const authkey = 'Basic ' + btoa('H2OH2SO4OOHCH4H2O2:' + auth_pass);

export const environment = {
  production: false,
  appKey: 'Basic SDJPSDJTTzRPT0hDSDRIMk8yOg==',
  deviceType: 'web',
  x_api_key: 'nXRrGi2bbMafA8fp7voIL9CwopSxmGH15MSRBIcG',
  hashKey: '@lb3rt!Th0m@52310n3$r!n!v@s@R@m@nuj@n',
  immunityKey: 'D0ct0r0p3r@t!0nC3nt3rQu35t!0nUNity',
  device_info: '',
  jwtSecretKey: 'EsFiEBY0I4TeVI5GVoRSmxYJ61HDiXxCVNdB4bG7xW2zc5pNvacsup5LPI5T557IYkD3ki76fMGjSbeYUZhuThSgSNLxvC0L05OE',
  googleMasApi: 'AIzaSyC4PtVTJCYWSRuCkXChpku1unvN5MH-SXc',
  api_debugger: 0,          // 0 for off and 1 for on
  is_encoded_response: 0,
  session_id_reset_time: 2400000, // 5 min
  event_tracking: 1,            // analytic flag on/off
  is_check_beta_ver: 0,
  ideal_state: {
    ideal_state_time: 120000,
    ideal_state_timer: 0,
    is_ideal_state: false,
    current_page: '',
    user_inactive_time: 600000, // 10 min (in seconds)
    user_inactive_timer: 0
  },
  recall_live_webinar_comment_list_time: 240, // in seconds
  credentials: {
    appId: '73213',
    authKey: 'srnpuycDV2NLMT4',
    authSecret: 'J8VSygRvZfwR-YH'
  },
  survey_base_href: 'surveyv1',
  hCaptchaSitekey: 'a6e531b6-d6ba-4977-a9d9-8fbd2c0083fd',
  clevertapConfigKey: '654-99W-R45Z',
  branchIOKey: 'key_test_nhDXy5IvQDFm1wa6QSBZtjpetwjfwi7r',
  tncUrl: 'https://docquity.com/termsandconditions/',
  privacyPolicyUrl: 'https://web.docquity.com/privacypolicy/',
  pif: 'BRBWWsp4lRy-53809',
  pffCode: 'BRkSP4LfEqo-53810',
  local_nav: [
    { id: 1, url: '/web', value: 'gr', module_name: 'Web Trends', menu_icon: 'icon-home' },
    { id: 2, url: '/cme', value: 'cme', module_name: 'Cme', menu_icon: 'icon-cpd' },
    { id: 8, url: '/clinical', value: 'journal', module_name: 'clinical', menu_icon: 'icon-cme' },
    { id: 9, url: '/channel/list', value: 'channel', module_name: 'Channel', menu_icon: 'icon-channel' },
    { id: 4, url: '/refer-and-earn/referal', value: 'rewards', module_name: 'refer-and-earn', menu_icon: 'icon-channel3' },
    { id: 29, url: '/connection/suggestion', value: 'connection', module_name: 'connection', menu_icon: 'icon-connection' },
    { id: 24, url: '/doctalk', value: 'doctalk', module_name: 'doctalk', menu_icon: 'icon-docktalks' },
    { id: 25, url: '/survey', value: 'survey', module_name: 'survey', menu_icon: 'icon-survey' },
    { id: 31, url: '/webinar/list', value: 'webinar', module_name: 'webinar', menu_icon: 'icon-webinar' }
  ],
  baseUrls: {
    sqsQueueURL: 'https://sqs.ap-southeast-1.amazonaws.com/254528787632/VideoWatchLog',
    webSiteUrl: 'http://website.docquity.com',
    JOIN_MEETING_URL: 'https://dmrconnect.docquity.com/panel',
    main_website_url: 'http://website.docquity.com',
    WHATS_MY_IP: 'https://docquity.com/detect/whatsmyip.php',
    nodeApiUrl: 'https://nodeapi.docquity.com/',
    nestApiUrl: 'https://dnestapi.docquity.com/',
    externalSurveyResourceUrl: 'https://www.docquity.com/resources',
    baseUrl2_0: 'https://dus.dev.docquity.com/'
  },
  APP_CONFIG_URL: 'https://id.dev.docquity.com/api/v2/config/app',
  API_URLS: {
    'calendar': 'https://dapi.docquity.com',
    'amazon': 'https://dadxa6z0p3.execute-api.ap-southeast-1.amazonaws.com/staging/docquityststoken',
    'privacy_policy': 'https://dev.docquity.com/privacypolicy',
    'terms_and_condition': 'https://dev.docquity.com/termsandconditions',
    'analytics': 'https://dapi.docquity.com',
    'chat_credentials': 'https://dev.docquity.com',
    'auto_verification': 'https://dapi.docquity.com',
    'onboarding': 'https://dapi.docquity.com',
    'quickblox': 'https://dev.docquity.com',
    'voip_node': 'https://dapi.docquity.com',
    'comment': 'https://dapi.docquity.com',
    'bookmark': 'https://dapi.docquity.com',
    'event': 'https://dapi.docquity.com',
    'voip': 'https://devnodeapi.docquity.com',
    'search': 'https://dapi.docquity.com',
    'community': 'https://dnestapi.docquity.com',
    'ncp': 'https://dapi.docquity.com',
    'card_rendering_analytics': 'https://dnestapi.docquity.com',
    'editor': 'https://dapi.docquity.com',
    'user': 'https://dapi.docquity.com',
    'clinic_consent': 'https://dapi.docquity.com',
    'chat_bot': 'https://socket.dev.docquity.com',
    'mr': 'https://socket.dev.docquity.com',
    'webinar': 'https://dapi.docquity.com',
    'payment': 'https://dapi.docquity.com',
    'nudge': 'https://dapi.docquity.com',
    'background': 'https://dapi.docquity.com',
    'report_user': 'https://dapi.docquity.com',
    'referral': 'https://dapi.docquity.com',
    'notification': 'https://dapi.docquity.com',
    'gr': 'https://dapi.docquity.com',
    'verification': 'https://dapi.docquity.com',
    'others': 'https://dapi.docquity.com',
    'pharma': 'https://dapi.docquity.com',
    'edetailing': 'https://dapi.docquity.com',
    'survey': 'https://dapi.docquity.com',
    'profile': 'https://dapi.docquity.com',
    'journal': 'https://dapi.docquity.com',
    'feedback': 'https://dapi.docquity.com',
    'socket': 'https://socket.dev.docquity.com',
    'doctalks': 'https://dapi.docquity.com',
    'cme': 'https://dapi.docquity.com',
    'like': 'https://dapi.docquity.com',
    'feed': 'https://dapi.docquity.com',
    'socket_comment': 'https://socket.dev.docquity.com',
    'speciality': 'https://dapi.docquity.com',
    'chat': 'https://dapi.docquity.com',
    'registration': 'https://dapi.docquity.com',
    'media_upload': 'https://dev.docquity.com',
    'meta_data': 'https://dev.docquity.com',
    'forward_flow': 'https://dnestapi.docquity.com',
    'join_meeting_web': 'https://dmrconnect.docquity.com',
    'ip_address_api': 'https://docquity.com',
    'mr_connect_mobile': 'https://dmrconnectapi.docquity.com',
    'mr_connect': 'https://dapi.docquity.com',
    'pvs': 'https://dnestapi.docquity.com',
    'onboarding_v1': 'https://api.dev.docquity.com',
    'magistrate': 'https://id.dev.docquity.com',
    'keeper': 'https://id.dev.docquity.com',
    'dfs': 'https://id.dev.docquity.com',
    'user_stories': 'https://id.dev.docquity.com',
    'record_browser_details': 'https://dapi.docquity.com',
    'survey_webview': 'https://website.docquity.com/#/survey/list',
    'openContent': 'https://dnestapi.docquity.com',
  },
  versions: {
    api_url_version: '4.1',
    api_url_version_updated: '4.3',
    api_version_47: '4.7',
    api_version: '4.0',
    api_version_updated: '4.3',
    webinar_api_version: '4.2',
    GROUP_CHAT_VERSION: '1',
    releaseVersion: 'V 4.0',
    GR_RELEASE_VERSION: '16',
    RELEASE_VERSION_17: '17',
    RELEASE_VERSION_18: '18',
    RELEASE_VERSION_20: '20',
    RELEASE_VERSION_19: '19',
    updatedReleaseVersion: '12',
    appReleaseVersion: '11',
    appVersion: '1110',
    apiVersion21: '2.1',
    apiVersion3: '3',
    apiVer28: '2.8',
    apiVer1: '1',
    apiVer27: '2.7',
    apiVer23: '2.3',
    apiVer41: '4.1',
    apiVer22: '2.2',
    apiVer25: '2.5',
    apiVer44: '4.4',
    apiVer45: '4.5',
    releaseVersion9: '9',
    apiVersion20: '2.0',
    apiVer46: '4.6',
    apiVer49: '4.9',
    apiVer52: '5.2',
    apiVer51: '5.1',
    apiVer70: '7.0',
    apiVer71: '7.1',
    apiVer50: '5.0',
  },
  udid: '123',
  accessTokenRenewIntervalPercentage: 40,
  keeperPublicId: 'WPECQOL25R',
  firebase: {
    apiKey: 'AIzaSyC4PtVTJCYWSRuCkXChpku1unvN5MH-SXc',
    authDomain: 'docquity-staging.firebaseapp.com',
    databaseURL: 'https://docquity-staging.firebaseio.com',
    projectId: 'docquity-staging',
    storageBucket: 'docquity-staging.appspot.com',
    messagingSenderId: '958906455863',
    appId: '1:958906455863:web:b6e4403798dbd1add26cb2',
    measurementId: 'G-LBKWDP28E7',
    vapidKey: 'BACFtMcXw_NXMowUktueNaL6r3R80c90K9qscVs-BUc9zjvATGj9zxwzzeWfpuQDEoGxtSkN1VSHt-TbKkbp6TU'
  },
  publicAnalyticMaxRecordCount: 1,
  isXhr: false,
  azFeatureOnOffData: 'https://docquity-bucket-dev.s3.ap-southeast-1.amazonaws.com/web-app/dq-az-feature-on-off.json',
  enableRedeemSurveyForCountryCodes : ['91','62']
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function generateRandStr(length) {
  let text = '';
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  for (let i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
}
