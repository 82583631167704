import { Directive } from '@angular/core';
import {
    ElementRef,
    Output,
    AfterViewInit,
    EventEmitter,
    Input
} from '@angular/core';
@Directive({
    selector: '[appViewVisibility]'
})
export class ViewVisibilityDirective implements AfterViewInit {
    @Input() rootMargin = '0px 0px 0px 0px';
    @Input() root = null;
    @Input() index;
    /**
     * Emit all the appeared cards.
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Output() visibleProduct = new EventEmitter<any>();
    /**
     * Creates a new IntersectionObserver object which will execute a specified callback function
     * when it detects that a target element's visibility has crossed one or more thresholds.
    */
    private observer: IntersectionObserver;
    /**
     * isIntersecting flag to get the visible elements.
     */
    private isIntersecting: boolean;
    constructor(private element: ElementRef) { }

    ngAfterViewInit(): void {
        this.initializeIntersectionObserver();

    }
    /**
   * Initialize the Intersection Observer api with options.
   */
    initializeIntersectionObserver(): void {
        const options = {
            root: this.root,
            rootMargin: this.rootMargin,
            threshold: 0,
        };

        this.observer = new IntersectionObserver((entries: IntersectionObserverEntry[]) => {
            entries.forEach(this.intersectionObserverEntryCallback);
        }, options);

        this.observer.observe(this.element.nativeElement);
    }
    /**
     * callback eventhandler for intersection observar api
     */
    intersectionObserverEntryCallback = (entry: IntersectionObserverEntry): void => {
        if (entry && entry.isIntersecting) {
            this.isIntersecting = true;
            this.visibleProduct.emit({ index: this.index, visibility: true });
        } else {
            this.isIntersecting = false;
            this.visibleProduct.emit({ index: this.index, visibility: false });
        }
    // tslint:disable-next-line: semicolon
    };
}
