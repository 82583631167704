/**
 * Angular imports.
*/
import { Component, EventEmitter, Output, Input, OnInit } from '@angular/core';

/**
 * Service imports.
*/
import { NewLanguageService } from './../../dataservices/new-language/new-language.service';
import { PostApiService } from '../../dataservices/post-api.service';
import { DqVideoPlayerService } from '../../dataservices/dqVideoPlayer/dq-video-player.service';

/**
 * Constant imports.
 */
import { TUTORIAL_FOR } from './../../constants/app.constants';
/**
 * rxjs import
 */
import { Subscription } from 'rxjs';
/**
 * InfoModal Component
 */
@Component({
  selector: 'app-tutorial-popover',
  templateUrl: './tutorial-popover.component.html',
  styleUrls: ['./tutorial-popover.component.scss']
})
export class TutorialPopoverComponent implements OnInit {

  /**
   * Title of the tutorial.
   * Banner of the tutorial.
   * Message of the tutorial.
   * CTA of the tutorial.
   * Tutorial is for.
   * Arrow position css.
   * Tutorial position css.
   */
  @Input() tutorialTitle = this.mls?.languageText?.tutorial?.key1;
  @Input() tutorialBanner = 'assets/images/pip-banner.png';
  @Input() tutorialMsg = this.mls?.languageText?.tutorial?.key2;
  @Input() tutorialCta = this.mls?.languageText?.tutorial?.key3;
  @Input() isTutorialFor = TUTORIAL_FOR.PIP;
  @Input() arrowPositionCss = '';
  @Input() tutorialPositionCss = '';

  /**
   * Emit start request outside.
   * Emit hide request outside.
   */
  @Output() emitStartRequest: EventEmitter<void> = new EventEmitter();
  @Output() emitHideModal: EventEmitter<void> = new EventEmitter();

  /**
   * Flag for the tutorial constant.
   */
  public tutorialFor = TUTORIAL_FOR;

  public isTextToSpeechTutorialEnabled = false;

  private subscriptions: Subscription = new Subscription();
  /**
   * Necessary instances
   */
  constructor(
    public mls: NewLanguageService,
    private postService: PostApiService,
    public dqVideoPlayerService: DqVideoPlayerService,
  ) { }

  /**
   * hide Modal
   */
  hideModal(): void {
    this.dqVideoPlayerService.isTextToSpeechTutorialEnabled.next(false);
    // api call for logging cross event
    this.subscriptions.add(this.postService.logTutorialView('tts').subscribe(() => {
      // No Code
    })
    );
    this.emitHideModal.emit();
  }

  /**
   * Request for pip.
   */
  startTutorial(): void {
    this.emitStartRequest.emit();
  }

  ngOnInit(): void {
    this.subscriptions.add(this.dqVideoPlayerService.isTextToSpeechTutorialEnabled.subscribe(status => {
      if (status) {
        this.isTextToSpeechTutorialEnabled = true;
      } else {
        this.isTextToSpeechTutorialEnabled = false;
      }
    }));
  }
}
