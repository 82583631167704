<div *ngIf="restrictedPopup" class="modal fade" tabindex="-1" role="dialog" [config]="{ show: true, backdrop: 'static' }"
  bsModal #autoShownModal="bs-modal">
  <div class="custom-theme-modal modal-dialog modal-md modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body pt-0">
        <div class="row">
          <div class="col-12 text-center mt-4">
            <img src="assets/images/no-access.svg" alt="">
            <p class="heading-4 mt-2">
              {{restrictData?.title}}
            </p>
            <p class="secondary-paragraph color-grey-700 mt-3">
              {{restrictData?.body}}
            </p>
            <p class="text-center redirection-text secondary-paragraph mt-3" >
                <span>{{restrictData?.footer}}</span>
            </p>
          </div>
        </div>
      </div>
      <div class="modal-footer px-3 text-center pt-2 pb-4 mx-auto">
        <button class="btn-design filled medium px-4" (click)="continueToDocquity()">
          {{mls.languageText.open_article.key17}}
        </button>
      </div>
    </div>
  </div>
</div>
