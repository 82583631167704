<div *ngIf="isGetsampleModal" [config]="{ show: true, backdrop: 'static' }" (onHidden)="onHidden()"  bsModal #autoShownModal="bs-modal" class="modal fade getSampledtails" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
           <button type="button" class="close" (click)="hideModal()" aria-label="Close"><span aria-hidden="true">&times;</span></button>
          <h4 class="modal-title"> <small (click)="showDrugList()"><img src="assets/images/back.svg" *ngIf="((drugDetail!='' || drugOrder!='') && (sampleOrderList.data.drug_list.length>1) ) "></small>
            <label *ngIf="drugOrder=='' ">{{mls?.languageText?.prod_get_sample?.key1}}<span *ngIf="mls?.is_lskey_display == 1">(prod_get_sample.key1)</span> 
            </label>
            <label *ngIf="drugOrder!='' ">{{mls?.languageText?.prod_get_sample?.key2}}<span *ngIf="mls?.is_lskey_display == 1">(prod_get_sample.key2)</span> </label>
            
          </h4>
         </div>
  <div class="modal-body" >
                            <div *ngIf="drugDetail=='' && drugOrder == '' "infiniteScroll
                               nfinite-scroll
                               [infiniteScrollDistance]="100"
                               [infiniteScrollThrottle]="100"
                               [scrollWindow]="false"
                               (scrolled)="onScrollDown();">
                               <div class="getSmaple_list" *ngFor=" let drugList of sampleOrderList?.data?.drug_list;">
                                <a  class="view_Drug_Details" (click)="showDrug_detail(drugList.safeUrl,'')"> <img src="assets/images/click_dtails.png"></a>
                                   <div class="samplelisting" style="cursor: pointer;" (click)="showDrug_detail(drugList.safeUrl,'')">
                                      <h4>{{drugList.drug_name}} <span><!-- ({{drugList.drug_strength}}) -->
                                       {{drugList.drug_strength!='' ? '('+drugList.drug_strength+')':''}} 
                                      </span> </h4>
                                       <h5 class="medicine_text">{{drugList.medicine_type}}</h5>
                                      <p>{{drugList.description | slice:0:130 }}{{drugList.description.length>130 ? '...See Detail':''}}</p>
                                   </div>
                                   <a class="btn btn-primary btn-default"  (click)="getSample(drugList)">{{mls?.languageText?.prod_get_sample?.key3}}<span *ngIf="mls?.is_lskey_display == 1">(prod_get_sample.key3)</span> </a>
                                 </div> 
                            </div>
                          <div class="DrugDtails" *ngIf="drugDetail!='' ">
                              <a class="btn btn-primary btn-default"  (click)="getSample(drugDetail)">{{mls?.languageText?.prod_get_sample?.key4}}<span *ngIf="mls?.is_lskey_display == 1">(prod_get_sample.key4)</span> </a>
                              <h4>{{drug_detail_data?.drug_name}} <!-- ({{drug_detail_data?.drug_strength}})   -->
  {{drug_detail_data.drug_strength!='' ? '('+drug_detail_data.drug_strength+')':''}} 
                               {{drug_detail_data?.medicine_type}}</h4> 
                               <p innerHTML="{{drug_detail_data?.description}}"></p>
                              <div class="dtailImage">
                                <img *ngIf="drug_detail_data?.logo!='' " src="{{drugDetail.logo | fitInImg :igs.imageGroup.fitin_500x500}}" onerror="this.src='assets/images/grandround/image_placeholder.svg';">
                              </div>
                              <accordion>
                                  <accordion-group *ngFor="let composition_list of drug_detail_data?.composition" (click)="callAccordian(isShowComposition)" >
                                    <div accordion-heading class="clearfix">
                                      {{composition_list?.composition_name}}
                                      <span class="float-right pull-right"><img  src='./assets/images/caret_drop.png' ></span>
                                    </div>
                                    {{composition_list?.composition_description}} 
                                  </accordion-group>
                                </accordion>
                          </div>
                          
          <div class="formElements row" *ngIf="drugOrder!='' ">
            <br>
            <div class="col-md-6 col-sm-6">
            <div class="contentbox mb-0">
              <label for="e-birddate" id="err_points"> {{mls?.languageText?.prod_get_sample?.key7}}<span *ngIf="mls?.is_lskey_display == 1">(prod_get_sample.key7)</span>  </label>
              <input class="date form-control" type="text" placeholder="{{mls?.languageText?.prod_get_sample?.key8}}" id="frm_mobile" value="{{user_profile.mobile}}" pattern="[0-9]+"  (keyup)="validateUserMobile($event)">
                 <div class="help-block" >{{errfrm_name}}</div>
          </div>
        </div>
        <div class="col-md-6 col-sm-6">
          <div class="contentbox mb-0">
            <label for="e-birddate" id="err_points">{{mls?.languageText?.prod_get_sample?.key9}}<span *ngIf="mls?.is_lskey_display == 1">(prod_get_sample.key9)</span> </label>
            <input class="date form-control" type="text" placeholder="{{mls?.languageText?.prod_get_sample?.key10}}" id="frm_email" value="{{user_profile.email}}">
              <div class="help-block" >{{errfrm_mobile}}</div>
          </div>
        </div>
        <div class="col-md-6 col-sm-6">
            <div class="contentbox mb-0">
              <label for="e-birddate" id="err_points"> {{mls?.languageText?.prod_get_sample?.key11}}<span *ngIf="mls?.is_lskey_display == 1">(prod_get_sample.key11)</span>  </label>
              <input class="date form-control" type="text" placeholder="{{mls?.languageText?.prod_get_sample?.key12}}" id="frm_address" >
                <div class="help-block" >{{errfrm_email}}</div>
            </div>
        </div>
        <div class="col-md-6 col-sm-6">
            <div class="contentbox mb-0">
            <label for="e-birddate" id="err_points"> {{multiLangService.Address}} </label>
               <input class="date form-control" type="text" placeholder="{{multiLangService.enter_address}}" id="frm_address" >
                <div class="help-block" >{{errfrm_address}}</div>
            </div>
        </div>          
        <div class="col-md-6 col-sm-6">
             <div class="contentbox mb-0">
              <label for="e-birddate" id="err_points"> {{mls?.languageText?.prod_get_sample?.key13}}<span *ngIf="mls?.is_lskey_display == 1">(prod_get_sample.key13)</span>  </label>
              <input class="date form-control" type="text" placeholder="{{mls?.languageText?.prod_get_sample?.key14}}" id="frm_pincode" (keyup)="validate_pincode($event)">
              <div class="help-block" >{{errfrm_pincode}}</div>
            </div>
        </div>
        <div class="col-md-6 col-sm-6">

             <div class="contentbox mb-0">
              <label for="e-birddate" id="err_date">{{mls?.languageText?.prod_get_sample?.key15}}<span *ngIf="mls?.is_lskey_display == 1">(prod_get_sample.key15)</span> </label>
              <input type="text" readonly 
              class="form-control"
              [minDate]="minDate"
              [maxDate]="maxDate"
              #dp="bsDatepicker"
              [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY'}"
              bsDatepicker [(bsValue)]="myDateValue" id="frm_date" placeholder="{{mls?.languageText?.prod_get_sample?.key16}}" >
              <div class="help-block" >{{errfrm_date}}</div>
          </div>
        </div>
        <div class="col-md-6 col-sm-6">
          <div class="contentbox mb-0" id="err_help_timerange">
            <label for="subcategory" id="err_subcategory">{{mls?.languageText?.prod_get_sample?.key17}}<span *ngIf="mls?.is_lskey_display == 1">(prod_get_sample.key17)</span> </label>
            <app-drop-down (resetDDLData) = resetDDLData($event) [DDLData]="TimeRange_data" [hasSelected]="hasSelected"></app-drop-down>
            <div class="help-block">{{errfrm_time_range}}</div>
          </div>
        </div>
        <div class="col-md-6 col-sm-6">
          <div class="contentbox mb-0">
            <label for="e-birddate" id="err_points"> {{mls?.languageText?.prod_get_sample?.key18}}<span *ngIf="mls?.is_lskey_display == 1">(prod_get_sample.key18)</span>  </label>
            <input type="text" class="form-control" type="text" placeholder="{{mls?.languageText?.prod_get_sample?.key19}}" id="frm_comment" > 
                <div class="help-block">{{errfrm_comment}}</div>
            </div>
          </div>

            
         
          </div>
           <div *ngIf="!finished" class='text-center'>
            <img src="./assets/images/loader-small.svg" width="40" height="100" id="loadmore_post">
        </div>

                             </div>

             <div class="modal-footer text-center" *ngIf="drugOrder!='' ">
              <button type="button" class="btn btn-primary" (click)="drugOrderForm()" >{{mls?.languageText?.prod_get_sample?.key20}}<span *ngIf="mls?.is_lskey_display == 1">(prod_get_sample.key20)</span> </button>
            </div>
            <div style="text-align: center;" *ngIf="offset==0 && !finished">{{mls?.languageText?.prod_get_sample?.key21}}<span *ngIf="mls?.is_lskey_display == 1">(prod_get_sample.key21)</span> </div>

     </div>
  </div>
</div>                        


<div *ngIf="sampleOrderInfoModalShown" id="SubscribeBoxModal" class="modal fade success-popup" tabindex="-1" role="dialog" [config]="{ show: true }" (onHidden)="onHidden()" bsModal #autoShownModal="bs-modal">
   <div class="modal-dialog modal-md">
    <div class="modal-content">
          <div class="modal-body">
           <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true" (click)="hideModal()">×</span></button>
              <div class="msg-symbol">
                <img width="90" src="assets//images/error.svg">
              </div>
              <div class="confirm_messege_text">
                <h5>{{mls?.languageText?.prod_get_sample?.key22}}<span *ngIf="mls?.is_lskey_display == 1">(prod_get_sample.key22)</span> </h5>
                <p> {{sample_order_data.msg}}</p>
             
               <div class="btn-inline clearfix">
              <button type="button" class="btn btn-primary" (click)="hideModal()">{{sample_order_data.data.button_name}}</button>
            </div>
           </div>
          </div> 
          <!--  <div class="modal-footer">
          
          <button type="button" class="btn btn-primary" (click)="hideModal()">{{sample_order_data.data.button_name}}</button>
      </div>              -->
        </div>
  </div>
  </div>


<!-- confirm modal for drug order **************** -->
     <div *ngIf="isConfirmModalShown" [config]="{ show: true, backdrop: 'static' }" (onHidden)="onHidden()"  bsModal #autoShownModal="bs-modal" class="modal fade success-popup" tabindex="-1" role="dialog" aria-hidden="true" >
     <div class="modal-dialog modal-md" role="document">
       <div class="modal-content">
          <div class="modal-body">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cancel_confirm_modal()"><span aria-hidden="true">×</span></button>
             <div class="msg-symbol">
               <img width="90" src="assets/images/question.svg">
             </div>
             <div class="confirm_messege_text">
              <h5>{{mls?.languageText?.prod_get_sample?.key23}}<span *ngIf="mls?.is_lskey_display == 1">(prod_get_sample.key23)</span> </h5>
              <p>{{confirmInfo}}</p>
             <div class="btn-inline clearfix">
              <a class="btn btn-primary" (click)="confirmDrugOrder()">{{mls?.languageText?.prod_get_sample?.key24}}<span *ngIf="mls?.is_lskey_display == 1">(prod_get_sample.key24)</span> </a>
              <a class="btn btn-default" (click)="cancel_confirm_modal()" >{{mls?.languageText?.prod_get_sample?.key25}}<span *ngIf="mls?.is_lskey_display == 1">(prod_get_sample.key25)</span> </a>

               </div>
             </div>
         </div>          
       </div>
     </div>
   </div>
