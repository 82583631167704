  <!-- without user image -->
  <div [appAnalyticsEvent]="analyticsData"
    (click)="navigateToProfile()" 
    class="position-relative user-profile-pic-container" 
    [ngClass]="{'cursor-pointer': profile_pic_image?.is_action==true, 'has-story': isStoryAvailable}">
    <ng-container *ngIf="profile_pic_image?.prflpic=='' && profile_pic_image?.anonymous!='1'">
      <div [ngClass]="profile_pic_image?.prflclass">
        <img src="assets/themes/icons/user-profile-placeholder.svg" alt="user profile pic" />
      </div>
    </ng-container>
      <!-- user image -->
    <img *ngIf="!!profile_pic_image?.prflpic && +profile_pic_image?.anonymous!==number.ONE" 
      [src]="profile_pic_image?.prflpic | fitInImg :igs.imageGroup.fitin_110x110" 
      [ngClass]="profile_pic_image?.prflpicclass" 
      onError="this.src='assets/themes/icons/user-profile-placeholder.svg';" />
    <img *ngIf="+profile_pic_image?.anonymous===number.ONE"
      src="assets/themes/icons/user-profile-red-placeholder.svg"
      class="anonymous-user"
      alt="anonymous user profile pic" [ngClass]="profile_pic_image?.prflpicclass"/>
  
  <!-- badge -->
    <ng-container *ngIf="profile_pic_image?.partial_permission_state === partial_verified_user_state.TWO; else showInitialStateForBadge">
      <div class="position-absolute profile-badge-container">
        <img class="user-profile-badge" 
          src="assets/themes/icons/verified-profile-icon.svg" alt="verified profile icon"/>
      </div>  
    </ng-container>
    <ng-template #showInitialStateForBadge>
      <ng-container *ngIf="profile_pic_image?.badge!=undefined">
        <div *ngIf="profile_pic_image?.badge.is_show!=false && usedFor !== speakerList" 
          class="position-absolute profile-badge-container">
          <img *ngIf="profile_pic_image?.badge.is_verified==number.ZERO"  
            class="user-profile-badge" 
            src="assets/themes/icons/unverified-profile-icon.svg" 
            alt="not verified profile icon" />
          <img *ngIf="profile_pic_image?.badge.is_verified==number.ONE" 
            class="user-profile-badge" 
            src="assets/themes/icons/verified-profile-icon.svg" 
            alt="verified profile icon"/>
        </div>
        <div *ngIf="profile_pic_image?.badge.is_show!=false && usedFor === speakerList" 
          class="position-absolute profile-badge-container">
          <img *ngIf="profile_pic_image?.badge.is_verified==number.ONE" 
            class="user-profile-badge" 
            src="assets/themes/icons/verified-profile-icon.svg" 
            alt="verified profile icon"/>
        </div>
      </ng-container>
    </ng-template>
  <!-- end badge -->
  </div>