/**
 * This class is used to whold sponsor variables and methods.
 */
export class Sponsor {
    /**
     * This will contain sponsor id.
     */
    private _sponsorId: number;

    /**
     * This will contain sponsor name.
     */
    private _name: string;

    /**
     * This will contain sponsor logo.
     */
    private _logo: string;

    /**
     * This will contain sponsor website url.
     */
    private _website: string;

    /**
     * This will contain sponsor display start time.
     */
    private _startTime: number;

    /**
     * This will contain sponsor display end time.
     */
    private _endTime: number;

    /**
     * This will contain sponsor's  primary/secondary flag.
     */
    private _isPrimary: number;

    public get sponsorId(): number {
        return this._sponsorId;
    }
    public set sponsorId(v: number) {
        this._sponsorId = v;
    }

    public get name(): string {
        return this._name;
    }
    public set name(v: string) {
        this._name = v;
    }

    public get logo(): string {
        return this._logo;
    }
    public set logo(v: string) {
        this._logo = v;
    }

    public get website(): string {
        return this._website;
    }
    public set website(v: string) {
        this._website = v;
    }

    public get startTime(): number {
        return this._startTime;
    }
    public set startTime(v: number) {
        this._startTime = v;
    }

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    public get endTime() {
        return this._endTime;
    }
    public set endTime(v: number) {
        this._endTime = v;
    }

    public get isPrimary(): number {
        return this._isPrimary;
    }
    public set isPrimary(v: number) {
        this._isPrimary = v;
    }

    /**
     * This is user to initialize the class variables while creating the class object.
     */
    constructor(sponsorId, name, logo, website, startTime, endTime, isPrimary) {
        this.sponsorId = sponsorId;
        this.name = name;
        this.logo = logo;
        this.website = website;
        this.startTime = startTime;
        this.endTime = endTime;
        this.isPrimary = isPrimary;
    }
}

