   <!-- modal-sm -->
  <div class="overlay">
    <!-- border-0 -->
    <div class="w-100 h-100 d-flex align-items-center">
        <div class="bg-white w-100 mx-auto border-radius-4 mx-width-350">
          <div class="p-2 border-bottom">
              <div class="row">
                <div class="col-9">

                  <p class="fnt-weight500 fnt-size12px text-medium-grey pt-1"> {{mls?.languageText?.poll_modal?.key1}}<span *ngIf="mls?.is_lskey_display == 1">(poll_modal.key1)</span> </p>

                </div>
                <div class="col-3 text-right">
                  <svg class="cursor-pointer " (click)="hideModal()" id="prefix__Group_2080" width="25" height="25"
                    viewBox="0 0 25 25">
                    <defs class="cross-pop-2"></defs>
                    <circle class="fill-white" id="prefix__Ellipse_250" cx="12.5" cy="12.5" r="12.5" data-name="Ellipse 250"/>
                    <g id="prefix__Group_2079" data-name="Group 2079" transform="translate(8 8)">
                      <path id="prefix__Line_57" d="M0 0L10 10" class="cross-pop-2" data-name="Line 57" />
                      <path id="prefix__Line_58" d="M10 0L0 10" class="cross-pop-2" data-name="Line 58" />
                    </g>
                  </svg>
                </div>
              </div>

            </div>

              <div class="p-2 pt-3">
                <div class="question pb-3">
                  <h6 class="fnt-weight500 fnt-size14px text-very-dark-grey">
                      {{recievePollData.question}}
                    </h6>
                  </div>

                  <div  class='text-center' *ngIf="isLoading==true">
                    <img src="assets/images/loader-small.svg" width="40" height="100">
                  </div>

               <div  *ngIf="isLoading==false">
                <button *ngFor="let data of recievePollData.options;let i = index" class="btn btn-outline-success btn-block fnt-size12px border-outline-success border-radius-28 fnt-weight500 mb-3 p-2" (click)="submitPoll(i)">{{data.option_value}}</button>
                </div>
             </div>
           </div>
     </div>
     </div>
