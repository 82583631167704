<div *ngIf="isHintModalShown" id="SubscribeBoxModal" class="modal fade success-popup" tabindex="-1" role="dialog" [config]="{ show: true,backdrop: relative}" (onHidden)="onHidden()" bsModal #autoShownModal="bs-modal">
    <div class="modal-dialog modal-sm modal-dialog-centered">
     <div class="modal-content">
           <div class="modal-body">
            <!-- <button type="button" class="close position-absolute" style="right: 0; top: 0;" data-dismiss="modal" aria-label="Close" (click)="hideModal()"><span aria-hidden="true">×</span></button> -->
            <span (click)="hideModal()" data-dismiss="modal" aria-label="Close" class="position-absolute border rounded-circle cursor-pointer text-center" style="background-color: rgba(0,0,0,0.6); width: 24px; height: 24px; right: 3px; top: 3px;"><svg  data-name="Layer 1" id="Layer_1" viewBox="0 0 12 12" width="10px"><defs ><style >.cross-prev{fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;}</style></defs><line  class="cross-prev" x1="1" x2="11" y1="1.04" y2="10.96"></line><line  class="cross-prev" x1="1" x2="11" y1="10.96" y2="1.04"></line></svg></span>

                 <p>{{info_data?.text}}</p>
                 <img [src]="info_data?.url" class="w-100" onerror="this.src='assets/images/grandround/image_placeholder.svg';">
              
           </div>          
         </div>
   </div>
   </div>