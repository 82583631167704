import { Component, OnInit, Input, Output, EventEmitter, ElementRef } from '@angular/core';
import { REPOST_TYPE_ITEM } from 'src/app/constants/app.constants';
import { NewLanguageService } from 'src/app/dataservices/new-language/new-language.service';

@Component({
  selector: 'app-repost-type-list-meta',
  templateUrl: './repost-type-list-meta.component.html',
  host: {
    '(document:click)': 'handleClick($event)',
  },
  styleUrls: ['./repost-type-list-meta.component.scss']
})
export class RepostTypeListMetaComponent implements OnInit {

  constructor( myElement: ElementRef,
    public mls:NewLanguageService) {
    this.elementRef = myElement;
  }

  @Input() isMetaState;

  @Input() callingFrom;

  @Input() productId;

  @Output() emitRepostType: EventEmitter<string> = new EventEmitter();

  @Output() closeToolTip: EventEmitter<boolean> = new EventEmitter();

  public repostType = REPOST_TYPE_ITEM;

  public elementRef;





  /**
   * function to emit selected function
   */
  onMeta(type: string): void {
    this.emitRepostType.emit(type);
  }


  // TODO: Rahul: need to remove uneccessary functions
  onGenerateMeta(): void {
    // 
  }


  ngOnInit(): void {
    // 
  }
  handleClick(event): void {
    // this.closeToolTip.emit(true);

    let clickedComponent = event.target;
    let inside = false;
    do {
      if (clickedComponent === this.elementRef.nativeElement) {
        inside = true;
      }
      clickedComponent = clickedComponent.parentNode;
    } while (clickedComponent);
    if (inside == true) {
      // 
    } else {
      this.closeToolTip.emit(true);
    }
  }

}
