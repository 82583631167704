/**
 * Angular imports.
 */
 import { NgModule } from '@angular/core';
 import { Routes, RouterModule } from '@angular/router';

 /**
  * Component imports.
  */
import { SamvaadComponent } from './samvaad.component';
import { SamvaadChatFullViewComponent } from './samvaad-chat-full-view/samvaad-chat-full-view.component';

 const samvaadRoutes: Routes = [
   {
     path: '', component: SamvaadComponent,
     children: [
        {
          path: 'list', component: SamvaadChatFullViewComponent,
          data: { screen_name: 'samvaad chat', module_name: 'Samvaad' }
        },

     ]
   },
 ];

 @NgModule({
   imports: [
     RouterModule.forChild(samvaadRoutes)
   ],
   exports: [RouterModule]
 })
 export class SamvaadRoutingModule { }
