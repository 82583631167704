import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'imagefilter'
})
export class ImagefilterPipe implements PipeTransform {

  transform(value: string, args?: string): string {
    let image = '';
    if (value) {
      image = value;
    } else {
      image = args;
    }

    return image;
  }

}
