/**
 * Angular imports.
 */
import { Component, OnInit, Input, OnChanges, ElementRef } from '@angular/core';

/**
 * Custom Tooltip Component
 */
@Component({
  selector: 'app-custom-tooltip',
  templateUrl: './custom-tooltip.component.html',
  styleUrls: ['./custom-tooltip.component.scss']
})
export class CustomTooltipComponent implements OnInit, OnChanges {
  @Input() tooltip_data;
  @Input() view_identifier;
  @Input() position;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public tooltip_msg_obj: any;

  constructor(
    private elementRef: ElementRef
    ) {}

  /**
   * ngOnInit.
   */
  ngOnInit(): void {
    setTimeout(() => {
      this.elementRef.nativeElement.querySelector('#clickpopup' + this.tooltip_msg_obj.id)?.click();
    }, 200);
  }

  /**
   * ngOnChanges.
   */
  ngOnChanges(): void {
    if (this.tooltip_data.length > 0) {
      this.tooltip_msg_obj = this.tooltip_data.find(obj => obj.view_identifier == this.view_identifier);
    }
  }

}
