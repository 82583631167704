  <span *ngIf="comment_box_obj.product_type_id === 7">
    <div class="pb-3 paragraph-bold">{{mls.languageText.event_detail.key9}}</div>
  </span>
  <div class="row text-left pb-2 align-items-center">
    <div class="col-2 pr-0 profile-pic">
      <div class="user-40 position-relative mr-3">
        <app-user-pofile-pic [profile_pic_image]="cmmnt_box_pic_image"></app-user-pofile-pic>
      </div>
    </div>

    <div class="col-12 pl-0  txtarea-container">
      <div class="form-textarea with-icon w-100">
        <span class="icon-in-field ">
          <span [dataTestId]="comment_box_obj.product_type_id"
            [dataTestIdName]="dataTestId.COMMENT_BOX_SEND + '-' + callingFrom" class=" comment-box-arrow">
            <span *ngIf="comment.trim()==='' && tagUserSearchWord === ''" class="cursor-auto">
              <img width="24px" src="assets/themes/icons/disabled-arrow.svg" alt="icon" />
            </span>
            <span *ngIf="comment.trim()!='' || tagUserSearchWord !== ''" class="cursor-pointer" (click)="setComment()"
              [eventTracker]='{"category":callingfrom,"action":mls.googleEventText.comment_box.key1,"product_type":comment_box_obj?.product_type_id, "product_id":comment_box_obj?.product_id}'>
              <img src="assets/themes/icons/arrow.svg" alt="icon" />
            </span>
          </span>
        </span>
      </div>

      <div #editableContent 
        id="editableContent{{commentModelId}}{{comment_box_obj?.product_id}}"
        class="editable w-100"
        contenteditable="true"
        data-placeholder=""
        (click)="checkToShowTutorial();generateAnalytic();"
        (input)="userInput($event)"
        (keydown)="onKeyDown($event)"
        (keyup)="onKeyUp($event)">
      </div>

      <app-tag-connections-pop-over *ngIf="isTagConnectionsEnabled"
        [isInfoModalShown]="isTagConnectionsEnabled"
        [tutorialTitle]="mls?.languageText?.tutorial?.key15"
        [tutorialMsg]="mls?.languageText?.tutorial?.key16"
        [tutorialCta]="mls?.languageText?.tutorial?.key17"
        [isTutorialFor]="tutorialFor.TAG_CONNECTION"
        (emitStartRequest)="closeTagTutorial()"
        (emitHideModal)="closeTagTutorial()"
        [screenName]="screenName"
        [comment_box_obj]="comment_box_obj"
        [callingFrom]="callingFrom">
      </app-tag-connections-pop-over>

      <div *ngIf="isSuggestionListForTagEnabled">
        <app-suggestion-list-for-tag-users
          [suggList]="tagSuggestionList"
          [offSet]="tagOffSet"
          [finished]="tagSuggestionListfinished"
          (getOffSetUserList)="getOffSetUserList($event)"
          (saveTaggedUserDataEmit)="saveTaggedUserDataEmit($event)"
          (outSideClicked)="outSideClicked($event)">
        </app-suggestion-list-for-tag-users>
      </div>
    </div>
  </div>