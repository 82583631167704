<div class="mt-3" *ngIf="shareUrl !== ''">
  <p>{{title}}:</p>
  <div class="button-container mt-3">

    <button (click)="productShare('fb')" class="facebook-button btn-design medium filled width cursor-pointer d-flex align-items-center justify-content-center ">
      <img class="mr-2" src="assets/themes/icons/facebook-icon.svg" alt="facebook"> {{fbText}}
    </button>

    <button (click)="productShare('tw')" class="twitter-button btn-design medium filled width cursor-pointer d-flex align-items-center justify-content-center">
      <img class="mr-2" src="assets/themes/icons/X.svg" alt="twitter"> {{twText}}
    </button>


    <button (click)="productShare('li')" class="linkedin-button btn-design medium filled width cursor-pointer d-flex align-items-center justify-content-center">
      <img class="mr-2" src="assets/themes/icons/linkedin.svg" alt="linkedin"> {{liText}}
    </button>
  </div>
</div>
