/**
 * Angular imports.
 */
import { Component, OnInit, ElementRef, Input, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

/**
 * Environment imports.
 */
import { environment } from '../../../environments/environment';

/**
 * Modal imports.
 */
import { Profile, Speciality } from '../../interface/userprofile/userprofile';
import { VerificationSuccessData } from './../../onboarding/interface/global.model';
import { EVENT_NAME } from './../../analytics-event/analytics.constant';
import { EventDataKey } from './../../analytics-event/analytics.model';

/**
 * Animation imports.
 */
import { slideInOut } from '../../animations/utility.animation';

/**
 * Rxjs imports.
 */
import { Subscription } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
/**
 * Service imports.
 */
import { PostApiService } from '../../dataservices/post-api.service';
import { AuthService } from '../../auth/auth.service';
import { LocalstorageService } from '../../auth/localstorage.service';
import { EncryptionService } from '../../auth/encryption.service';
import { MasterserviceService } from '../../dataservices/master-service/masterservice.service';
import { EventMsgService } from '../../dataservices/master-service/event-msg.service';
import { FunCollectionService } from '../../common/common-functions/fun-collection.service';
import { NotificationListService } from '../../dataservices/notification/notification-list.service';
import { DataStorageService } from '../../dataservices/data-storage.service';
import { GetService } from '../../dataservices/get.service';
import { JwtDecodeService } from '../../dataservices/jwt-decode.service';
import { NewLanguageService } from '../../dataservices/new-language/new-language.service';
import { DqChatPanelService } from 'src/app/samvaad/dq-chat-panel.service';
import { MobileWebViewCommunicationService } from './../../dataservices/mobileWebViewCommunication/mobile-web-view-communication.service';

/**
 * header imports.
 */
import { AnalyticsEventService } from '../../analytics-event/analytics-event.service';
import { DeviceDetectorService } from 'ngx-device-detector';
/**
 * Constants imports.
 */
import { ISFROMDEEPLINK, LOCAL_STORAGE, MAX_UNREAD_COUNT, NUMBERS, PRODUCT_TYPE_CONSTANT, SCREEN_NAME, LOGOUT_TYPE, PRODUCT_TYPE_CONSTANT_NAME, CTA_TYPE, ACTION_TYPE, ACTION_ON, PRODUCT_TYPE_NUMBER_MAPPING } from './../../constants/app.constants';
import { HEADER } from './../../constants/data-testId.constants';
import { Analytics } from 'analytics2.0';
import { ENTRY, EXIT, NAVIGATION_BAR_CLICK } from 'analytics2.0/dist/core/constants/analytic.interface';


@Component({
  selector: 'app-header',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '(document:click)': 'handleClick($event)',
  },
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [slideInOut]
})
export class HeaderComponent implements OnInit, OnDestroy {
  public displaySection = '';
  /**
   * Used for website URL.
   */
  public website_url = environment.baseUrls.main_website_url;
  /**
   * Flag used for terms and conditions popup if content updates.
   */
  public termsConditionsUpdate = false;
  public displayObj = {
    isShow: false,
    display_section: '',
    data: [],
    errObj: []
  };
  public profile_pic_image = {
    prflclass: 'profilepicImageheader rounded-circle  text-center text-white d-table-cell align-middle box-size-40',
    prflpic: '',
    prflfname: '',
    prflmname: '',
    prfllname: '',
    prflrname: '',
    prflcid: '',
    prfltype: '',
    prflpicclass: 'rounded-circle post-pic box-size-40',
    badge: { is_verified: 0, is_show: true },
    full_name: '',
    is_action: false,
    anonymous: '0',
    partial_permission_state: ''
  };
  public hamburgerStartTime = 0;
  public hamburgur_pic_image = {
    prflclass: 'rounded-circle text-white text-center d-table-cell align-middle',
    prflpic: '',
    prflfname: '',
    prflmname: '',
    prfllname: '',
    prflrname: '',
    prflcid: '',
    prfltype: '',
    prflpicclass: 'user-40 post-pic rounded-circle',
    badge: { is_verified: 0, is_show: true },
    full_name: '',
    is_action: false,
    anonymous: '0',
    partial_permission_state: ''
  };
  public tooltip_data = [];
  public docquity_logo_src = 'assets/images/grandround/logo_dark.svg';

  public elementRef;
  public userSpeciality: Speciality[];
  public finished = false;
  public isSpecialityShown = false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public activeModule: any;
  public selectedval: string;
  public module_name: string;
  public webSiteUrl = environment.baseUrls.webSiteUrl;
  public profile_pic_path: Subscription;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public masterData: any;
  public nav_new = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public userProfileData: any;
  public profileInfo: Profile;
  public loginUserCustomeId: string;
  public custom_id: string;
  public proPicPath: string;
  public countryId: string;
  public isErrorModalShown = false;
  public error_msg: string;
  public open_explore = false;
  public notification_list = [];
  public primary_speciality = '';
  public searchkey = '';
  public userAssList = [];
  public selectedAss = [];
  public AssSettings = {};
  public appVersion: string;
  public releaseVersion: string;
  public group_sort_arr = [];
  public no_record = '';
  public isGetsampleModal = false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public notificationObj: any;
  public offset = 1;
  public no_active = false;
  public notification_count = 0;
  public show_notification_count = true;
  public deviceInfo = null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public isDisabled: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public notification_length: any;
  public profile_api_exe = false;
  public speciality_select_type = 'single';
  public offsetVal = 1;
  @Input() callingfrom;

  public profileDropdown = false;

  /**
   * To unsubscribe data.
   */
  private subscriptions: Subscription = new Subscription();
  private componentActive = true;

  /**
   * Check the status of post button.
   */
  public isPostButtonDisable = false;

  /**
   * Assigning value from json from header_popup
   */
  public dataTestId = HEADER;

  /**
   * Verification success modal status.
   */
  public isVerificationOkay: boolean;
  /**
   * Verification Data.
   */
  public verificationContent: VerificationSuccessData[];
  /**
   * Numbers constant.
   */
  public numbers = NUMBERS;

  /**
   * To show/hide the tutorial for chat.
   */
  public isChatTutorial: boolean;
  public chatUnreadCount: number;
  public chatAllow: boolean;
  public chatWindowState: number;
  public maxUnreadCount = MAX_UNREAD_COUNT;

  /**
   * Necessary instances.
   */
  constructor(
    private postSer: PostApiService,
    public ems: EventMsgService,
    public funCollectionService: FunCollectionService,
    public masterService: MasterserviceService,
    private authService: AuthService,
    private localstorage: LocalstorageService,
    private router: Router,
    private route: ActivatedRoute,
    private myElement: ElementRef,
    private notificationListService: NotificationListService,
    public dss: DataStorageService,
    private GetSer: GetService,
    private jwtSer: JwtDecodeService,
    public mls: NewLanguageService,
    private encrypt: EncryptionService,
    private clevertap: AnalyticsEventService,
    public dqChatPanelService: DqChatPanelService,
    public deviceService: DeviceDetectorService,
    public mvc: MobileWebViewCommunicationService,
    private analyticSdk: Analytics
  ) {
  }

  /**
   * ngOnInit
   */
  ngOnInit(): void {
    this.subscriptions.add(this.dqChatPanelService.unreadNotification
      .subscribe(count => {
        this.chatUnreadCount = count;
      }));
    this.subscriptions.add(this.dss.chatWindowState
      .subscribe((state: number) => {
        if (state) {
          this.chatWindowState = state;
        } else {
          this.chatWindowState = state;
        }
      }));
    this.headerLoad();
    this.tncUpdate();
    this.tooltip_data = this.dss.tooltip_list.filter(obj => obj.screen_identifier == 'POSTING_TAGGING');
    this.funCollectionService.closeFeedDetailModal();
    this.funCollectionService.navigateToTop();
    if (environment.is_check_beta_ver == 1 && this.dss.user_profile_data.profile.is_docquity_team == 1) {
      window.location.href = 'https://app.docquity.com/beta/#/web/list';
    }
    const temp = this.router.url.split('/');
    this.profileDropdown = true;
    if (temp.length > 4) {
      this.no_active = true;
    } else {
      this.no_active = false;
    }

    this.custom_id = temp[3];
    this.getProfile();

    if(!this.mls.userLoginFlow) {
      this.notitificationCount();
    }

    this.module_name = this.route.snapshot.firstChild.data['module_name'];
    this.activeModule = (this.module_name);
    this.selectedval = localStorage.getItem('lang');
    if (this.selectedval === undefined || this.selectedval === 'en') {
      this.selectedval = 'English';
    } else {
      this.selectedval = 'Bahasa';
    }

    this.getStoredMasterData();
    /**
     * Function call after reloading location.
     */
    // TODO : Nielsh: Autoverification signup.
    if (this.localstorage.getInLocalStorage(LOCAL_STORAGE.SUCCESS_SCREEN)) {
      this.registrationStatus();
    }
  }

  /**
   * getProfile
   */
  getProfile(): void {
    this.subscriptions.add(this.masterService.getProfile()
      .subscribe(rootObject => {
        if (this.custom_id == undefined || this.custom_id == this.loginUserCustomeId) {
          if (rootObject['status'] === 1) {
            this.masterService.profile_api_data.next(rootObject);
            this.chatAllow = this.masterService?.masterApiData?.data.page_info?.chat?.hideChat === '0' ? true : false;
            this.profileInfo = rootObject['data'].profile;
          } else if (rootObject['status'] === 0 && rootObject['code'] == 2000) {
            this.ErrorHandler(rootObject['error'].msg);
          }
        } else {
          if (rootObject['status'] === 1) {
            this.masterService.profile_api_data.next(rootObject);
            this.profileInfo = rootObject['data'].profile;
            this.chatAllow = this.masterService?.masterApiData?.data.page_info?.chat?.hideChat === '0' ? true : false;
            this.localstorage.setInSessionstorage('userProfileData', this.encrypt.encryptAes(JSON.stringify(rootObject['data'])));
          }
        }
      }));
  }

  /**
   * will active when user click on anywhere on page
   */
  handleClick(event): void {
    let clickedComponent = event.target;
    let inside = false;
    do {
      if (clickedComponent === this.elementRef.nativeElement) {
        inside = true;
      }
      clickedComponent = clickedComponent.parentNode;
    } while (clickedComponent);
    if (inside) {
      // No Code
    } else {
      this.profileDropdown = true;
    }
  }

  /**
   * toggle search icon
   */
  activateSearch(): void {
    this.profileDropdown = true;
    document.getElementById('showsearch')?.classList?.toggle('showsearch');
  }

  /**
   * headerLoad
   */
  headerLoad(): void {
    if (environment.is_check_beta_ver == 0) {
      this.docquity_logo_src = 'assets/images/grandround/logo_dark.svg';
    } else if (environment.is_check_beta_ver == 1 && (this.dss.user_profile_data.profile.is_docquity_team == 1)) {
      this.docquity_logo_src = 'assets/images/grandround/Docquity_beta.png';
    }

    this.isOnUserProfile();

    this.masterProfileAPiData();
    this.elementRef = this.myElement;
    this.releaseVersion = environment.versions.releaseVersion;
    this.masterService.newslist = [];
    this.verifyMobile();

    this.loginUserCustomeId = this.authService.getbyName('profile_obj');

    this.isDisabled = this.funCollectionService.epicFunction();
    this.profilePicImage();

    this.subscriptions.add(this.dss.profile_pic_updated_flag.subscribe(() => {
      this.profile_pic_image.prflpic = this.dss.user_profile_data.profile.profile_pic_path;
    }));
  }

  /**
   * profilePicImage
   */
  profilePicImage(): void {
    this.profile_pic_image.prflcid = this.loginUserCustomeId;
    this.hamburgur_pic_image.prflcid = this.loginUserCustomeId;
    if (this.dss.user_profile_data) {
      this.profile_pic_image.prflpic = this.dss.user_profile_data.profile.profile_pic_path;

      this.profile_pic_image.prflrname = this.dss.user_profile_data.profile.registered_name;
      this.profile_pic_image.prflfname = this.dss.user_profile_data.profile.first_name;
      this.profile_pic_image.prfllname = this.dss.user_profile_data.profile.last_name;
      this.profile_pic_image.full_name = this.dss.user_profile_data.full_name;
      this.profile_pic_image.badge.is_verified = (this.dss.user_profile_data.profile.permission == 'all') ? 1 : 0;
      this.profile_pic_image.partial_permission_state = this.dss.user_profile_data.profile.partial_permission_state;
      this.hamburgur_pic_image.prflpic = this.dss.user_profile_data.profile.profile_pic_path;

      this.hamburgur_pic_image.prflrname = this.dss.user_profile_data.profile.registered_name;
      this.hamburgur_pic_image.prflfname = this.dss.user_profile_data.profile.first_name + '#123';
      this.hamburgur_pic_image.prfllname = this.dss.user_profile_data.profile.last_name;
      this.hamburgur_pic_image.full_name = this.dss.user_profile_data.full_name;
      this.hamburgur_pic_image.badge.is_verified = (this.dss.user_profile_data.profile.permission == 'all') ? 1 : 0;
      this.hamburgur_pic_image.partial_permission_state = this.dss.user_profile_data.profile.partial_permission_state;
    }

  }

  /**
   * verifyMobile
   */
  verifyMobile(): void {
    if (localStorage.getItem('verify_mobile') != null) {
      const temp = JSON.parse(localStorage.getItem('verify_mobile'));
      if (temp['status'] == 1 && temp['data'].screen_data != null) {
        this.dss.isSpecialityOn = true;
        if (temp['data'].screen_data.identifier == 'select_speciality' || temp['data'].screen_data.identifier == 'select_interest') {
          if (sessionStorage.getItem('req_data') != null) {
            localStorage.setItem('temp_req', sessionStorage.getItem('req_data'));

          }

          if (localStorage.getItem('temp_req') != null) {
            const x = localStorage.getItem('temp_req');
            sessionStorage.setItem('req_data', x);
          }

          if (temp['data'].screen_data.identifier == 'select_speciality') {
            this.speciality_select_type = 'single';
          } else if (temp['data'].screen_data.identifier == 'select_interest') {
            this.speciality_select_type = 'multiple';
          }
          this.isSpecialityShown = true;
        }
      } else {
        this.dss.isSpecialityOn = false;
      }
    }
  }

  /**
   * master Profile APi Data
   */
  masterProfileAPiData(): void {
    this.subscriptions.add(this.masterService.profile_api_data.subscribe(data => {
      if (!Object.keys(data).length) {
        return;
      }
      this.userProfileData = data;
      this.userSpeciality = this.userProfileData.data['speciality'];
      for (let i = 0; i < this.userSpeciality.length; i++) {
        if (this.userSpeciality[i].is_primary == 1) {
          this.primary_speciality = this.userSpeciality[i].speciality_name;
          break;
        }
      }
      this.userProfileData = data.data;
      const temp = this.router.url.split('/');
      this.profileDropdown = true;
      if (temp.length > 4) {
        this.no_active = true;
      } else {
        this.no_active = false;
      }


      this.custom_id = temp[3];

      if (this.custom_id == undefined || this.custom_id == this.loginUserCustomeId) {
        this.profileInfo = this.userProfileData.profile;
        this.localstorage.setInSessionstorage('userProfileData', this.encrypt.encryptAes(JSON.stringify(this.userProfileData)));
        this.clevertap.logClevertapProfileEvent(this.dss.user_profile_data);
      }
    }));

    this.postDisableCheck();
  }

  /**
   * get the post enable/disable flag.
   */
  postDisableCheck(): void {
    const checkDisableSub = this.postSer.getPostButtonStatus()
      .subscribe((res: boolean) => this.isPostButtonDisable = res);
    this.subscriptions.add(checkDisableSub);
  }

  /**
   * is On User Profile
   */
  isOnUserProfile(): void {
    this.subscriptions.add(this.dss.is_on_user_profile.subscribe(() => {
      this.profile_pic_image.prflpic = this.dss.user_profile_data.profile.profile_pic_path;
      this.profile_pic_image.prflcid = this.loginUserCustomeId;
      this.profile_pic_image.prflrname = this.dss.user_profile_data.profile.registered_name;
      this.profile_pic_image.prflfname = this.dss.user_profile_data.profile.first_name;
      this.profile_pic_image.prfllname = this.dss.user_profile_data.profile.last_name;
      this.profile_pic_image.full_name = this.dss.user_profile_data.full_name;
      this.profile_pic_image.badge.is_verified = (this.dss.user_profile_data.profile.permission == 'all') ? 1 : 0;
      this.hamburgur_pic_image.prflpic = this.dss.user_profile_data.profile.profile_pic_path;
      this.hamburgur_pic_image.prflcid = this.loginUserCustomeId;
      this.hamburgur_pic_image.prflrname = this.dss.user_profile_data.profile.registered_name;
      this.hamburgur_pic_image.prflfname = this.dss.user_profile_data.profile.first_name + '#123';
      this.hamburgur_pic_image.prfllname = this.dss.user_profile_data.profile.last_name;
      this.hamburgur_pic_image.full_name = this.dss.user_profile_data.full_name;
      this.hamburgur_pic_image.badge.is_verified = (this.dss.user_profile_data.profile.permission == 'all') ? 1 : 0;
    }));
  }

  /**
   * call Notification List
   */
  callNotificationList(): void {
    if (this.masterService.isOnline == false) {
      this.masterService.isConnectionModalShown.next('1');
      return;
    }
    this.profileDropdown = true;
    document.getElementById('showsearch')?.classList?.toggle('showsearch');
    this.show_notification_count = false;
    this.finished = false;

    if (this.offset >= 1) {
      this.finished = false;
      this.notification_list = [];
      this.getNotificationList();
    }

  }

  /**
   * call profile section
   */
  callprofilesection(): void {
    this.profileDropdown = !this.profileDropdown;
  }

  /**
   * on Scroll Down
   */
  onScrollDown(): void {
    if (this.offset > 1) {
      this.getNotificationList();
    }
  }

  /**
   * notitification Count
   */
  notitificationCount(): void {
    this.subscriptions.add(this.notificationListService.getNotificationCunt().subscribe(d => {
      if (d.status == 1) {
        this.dss.notificationCount = d.data.notification_count;
        if (this.callingfrom == 'notification') {
          this.notification_count = 0;
        } else {
          this.notification_count = this.dss.notificationCount;
        }
      }
    }));
  }

  /**
   * navigate to notification this
   */
  navigateToNotification(): void {
    this.notification_count = 0;
    this.analyticSdk.analyticService.CLICK(PRODUCT_TYPE_CONSTANT_NAME.NOTIFICATION,0, this.funCollectionService.currentScreename, CTA_TYPE.TOP_BAR, ACTION_TYPE.CLICK, ACTION_ON.NOTIFICATION_BELL, null)
    this.funCollectionService.navigateToNotification();
  }

  /**
   * get notification list
   */
  getNotificationList(): void {
    this.hideExpore();
    this.subscriptions.add(this.GetSer.getNotificationList(1).subscribe(resData => {
      this.finished = true;

      this.jwtSer.decodeData(resData).then(notification_data => {
        if (notification_data['status'] === 1) {
          this.offset = this.offset + 1;
          this.notification_list = this.notification_list.concat(notification_data['data'].notification_list);
          this.notification_length = this.notification_list.length;
        } else {
          this.funCollectionService.errorHandeler(notification_data);
        }
      },
        () => {
          this.profileDropdown = true;
          this.finished = true;
          this.httpErrorHandeler();
        });
    }));
  }

  /**
   * onScrollNoti
   */
  onScrollNoti(): void {
    this.getNotificationList();
  }

  /**
   * navigate from notification
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  notificationAction(notification_data: any, idx: number): void {
    if (this.masterService.isOnline == false) {
      this.masterService.isConnectionModalShown.next('1');
      return;
    }
    {
      this.notification_list[idx].read_status = 'read';
      switch (this.notification_list[idx].target.type) {
        case 'feed': {
          this.profileDropdown = true;
          this.funCollectionService.navigateToFeedDetail(this.notification_list[idx].target.id);
          break;
        }

        case 'channel': {
          this.profileDropdown = true;
          this.funCollectionService.navigateToChannelDetail(this.notification_list[idx].target.id);
          break;
        }
        case 'invite': {
          this.funCollectionService.navigateToRefetal();
          break;
        }

        case 'link': {
          this.funCollectionService.navigateToLink(this.notification_list[idx].target.url);
          break;
        }

        case 'cme': {
          this.funCollectionService.navigateToCmeDetail(this.notification_list[idx].target.id);
          break;
        }
        case 'drugsample': {
          this.notificationObj = notification_data;
          this.showGetsamplePopup();
          break;
        }
        case 'journal': {
          this.profileDropdown = true;
          this.funCollectionService.navigateToclinicalDetail(this.notification_list[idx].target.id);
          break;
        }
        case 'news': {
          this.funCollectionService.navigateToNews(this.notification_list[idx].target.id);
          break;
        }

      }
    }
  }

  /**
   * get master data
   */
  getStoredMasterData(): void {
    this.masterData = this.masterService.masterApiData;
    this.userAssList = [];
    const temp = { id: 100001, association_name: 'Apply filter' };
    this.userAssList.push(temp);
    this.nav_new = [];
    if (this.masterData && this.masterData.status === 1) {

      for (let ii = 0; ii < this.masterData.data.nav_new.length; ii++) {
        let navigate_url = environment.baseUrls.webSiteUrl;
        let module_name = '';
        let menu_icon = '';
        if (this.masterData['data'].nav_new[ii].value == 'gr' || this.masterData['data'].nav_new[ii].value == 'cme' || this.masterData['data'].nav_new[ii].value == 'channel' || this.masterData['data'].nav_new[ii].value == 'connection') {
          for (let jj = 0; jj < environment.local_nav.length; jj++) {
            if (environment.local_nav[jj].value === this.masterData.data.nav_new[ii].value) {

              navigate_url = environment.local_nav[jj].url;
              module_name = environment.local_nav[jj].module_name;
              menu_icon = environment.local_nav[jj].menu_icon;
              break;
            }
          }

          const x = {
            id: this.masterData.data.nav_new[ii].id,
            name: this.masterData.data.nav_new[ii].name,
            screen_name: this.masterData.data.nav_new[ii].screen_name,
            navigate_url: navigate_url,
            module_name: module_name,
            menu_icon: menu_icon,
            value: this.masterData.data.nav_new[ii].value
          };

          this.nav_new.push(x);
        }
      }

      // tslint:disable-next-line: forin
      // for (const i in this.masterData.data.page_info.cme) {
      //   const keyvalue = this.masterData.data.page_info.cme[i];
      // }

      // tslint:disable-next-line: forin
      for (const i in this.masterData.data.explore) {
        this.group_sort_arr.push(this.masterData.data.explore[i].group_sort_order);
      }
      this.group_sort_arr.sort();

      let sort_order = 0;
      this.masterService.explore_array = [];
      while ((this.masterService.explore_array.length) != (this.masterData.data.explore.length)) {
        for (const i in this.masterData.data.explore) {
          if (this.masterData.data.explore[i].group_sort_order == this.group_sort_arr[sort_order]) {
            this.masterService.explore_array.push(this.masterData.data.explore[i]);
            break;
          }
        }
        sort_order = sort_order + 1;
      }
      // set default image of products
      for (const i in this.masterService.explore_array) {
        if (this.masterService.explore_array[i].group_sort_order == this.group_sort_arr[0]) {

          for (const j in this.masterService.explore_array[i].products) {
            if (this.masterService.explore_array[i].products[j].icon_url == '' || this.masterService.explore_array[i].products[j].icon_url == undefined) {
              switch (this.masterService.explore_array[i].products[j].value) {
                case 'news': {
                  this.masterService.explore_array[i].products[j].icon_url = 'assets/images/news.png';
                  break;
                }
                case 'channel': {
                  this.masterService.explore_array[i].products[j].icon_url = 'assets/images/channel.svg';
                  break;
                }
                case 'cme': {
                  this.masterService.explore_array[i].products[j].icon_url = 'assets/images/cme-wallet.svg';
                  break;
                }
                case 'event': {
                  this.masterService.explore_array[i].products[j].icon_url = 'assets/images/event.svg';
                  break;
                }
                case 'journal': {
                  this.masterService.explore_array[i].products[j].icon_url = 'assets/images/journal.png';
                  break;
                }
                case 'qna': {
                  this.masterService.explore_array[i].products[j].icon_url = 'assets/images/q_a.svg';
                  break;
                }

              }
            }
          }
        } else if (this.masterService.explore_array[i].group_sort_order == this.group_sort_arr[1]) {
          for (const j in this.masterService.explore_array[i].products) {
            if (this.masterService.explore_array[i].products[j].icon_url == '' || this.masterService.explore_array[i].products[j].icon_url == undefined) {
              switch (this.masterService.explore_array[i].products[j].value) {
                case 'clinicalcases': {
                  this.masterService.explore_array[i].products[j].icon_url = 'assets/images/clinical_cases1.svg';
                  break;
                }
                case 'insights': {
                  this.masterService.explore_array[i].products[j].icon_url = 'assets/images/insights-1.svg';
                  break;
                }
                case 'poll': {
                  this.masterService.explore_array[i].products[j].icon_url = 'assets/images/poll-1.svg';
                  break;
                }
                case 'chats': {
                  this.masterService.explore_array[i].products[j].icon_url = 'assets/images/chat.svg';
                  break;
                }
              }
            }
          }
        } else if (this.masterService.explore_array[i].group_sort_order == this.group_sort_arr[2]) {
          for (const j in this.masterService.explore_array[i].products) {
            if (this.masterService.explore_array[i].products[j].icon_url == '' || this.masterService.explore_array[i].products[j].icon_url == undefined) {

              switch (this.masterService.explore_array[i].products[j].value) {
                case 'mydownload': {
                  this.masterService.explore_array[i].products[j].icon_url = 'assets/images/downloads.svg';
                  break;
                }
                case 'mybookmarks': {
                  this.masterService.explore_array[i].products[j].icon_url = 'assets/images/bookmarks.svg';
                  break;
                }
                case 'qna': {
                  this.masterService.explore_array[i].products[j].icon_url = 'assets/images/q_a.svg';
                  break;
                }
                case 'settings': {
                  this.masterService.explore_array[i].products[j].icon_url = 'assets/images/Untitled-1.png';
                  break;
                }
                case 'rewards': {
                  this.masterService.explore_array[i].products[j].icon_url = 'assets/images/referal_explore.svg';
                  break;
                }
                case 'contributor': {
                  this.masterService.explore_array[i].products[j].icon_url = 'assets/images/contributor.svg';
                  break;
                }

              }
            }
          }
        }
      }
    }

  }

  /**
   * onSelect
   */
  onSelect(countryId): void {
    localStorage.setItem('lang', countryId);
    if (countryId === 'en') {
      this.selectedval = 'English';
    } else if (countryId === 'id') {
      this.selectedval = 'Bhasa';
    }
    this.subscriptions.add(this.masterService.getMasterDetail().subscribe(resData => {
      this.jwtSer.decodeData(resData).then(data => {
        this.masterData = data;
        this.nav_new = [];
        if (this.masterData.posts.status === 1) {

          this.nav_new = [];
          for (let ii = 0; ii < this.masterData.posts.data.nav_new.length; ii++) {
            let navigate_url = environment.baseUrls.webSiteUrl;
            let module_name = '';
            let menu_icon = '';
            if (this.masterData.posts.data.nav_new[ii].value == 'gr' || this.masterData.posts.data.nav_new[ii].value == 'cme' || this.masterData.posts.data.nav_new[ii].value == 'channel') {


              for (let jj = 0; jj < environment.local_nav.length; jj++) {
                if (environment.local_nav[jj].value === this.masterData.posts.data.nav_new[ii].value) {

                  navigate_url = environment.local_nav[jj].url;
                  module_name = environment.local_nav[jj].module_name;
                  menu_icon = environment.local_nav[jj].menu_icon;
                  break;
                }

              }

              const x = {
                id: this.masterData.posts.data.nav_new[ii].id,
                name: this.masterData.posts.data.nav_new[ii].name,
                screen_name: this.masterData.posts.data.nav_new[ii].screen_name,
                navigate_url: navigate_url,
                module_name: module_name,
                menu_icon: menu_icon,
                value: this.masterData.posts.data.nav_new[ii].value
              };

              this.nav_new.push(x);
            }
          }
        }
      });
    }));
  }

  /**
   * navigate To explore
   */
  navigateToexplore(): void {
    this.analyticSdk.analyticService.CLICK(PRODUCT_TYPE_CONSTANT_NAME.OTHER, 0,SCREEN_NAME.HAMBURGER_MENU, CTA_TYPE.TEXT, ACTION_TYPE.CLICK, ACTION_ON.EXPLORE, null);
    this.router.navigateByUrl('/web/explore');
    this.hideExpore();
  }
  /**
    * Get the current time in string.
    */
  getTimeString(): string {
    return new Date().getTime().toString();
  }
  /**
   * navigate To mobile search
   */
  navigateTomobilesearch(): void {
    this.router.navigateByUrl('/web/mobile-search');
    this.clevertap.sendAnalytics(EVENT_NAME.EXPLORE_BUTTON_CLICK, 0 , 0, '');
    this.hideExpore();
  }

  /**
   * navigate To Home
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  navigateToHome(): any {
    if (this.masterService.isOnline == false) {
      this.masterService.isConnectionModalShown.next('1');
      return;
    }
    this.notification_count = 0;
    const analyticsData = {
      event_name: EVENT_NAME.EXPLORE_BUTTON_CLICK,
      product_type: 0,
      product_type_id: 0,
      screen_name: SCREEN_NAME.GRAND_ROUND,
      start_time: this.getTimeString(),
      end_time:   this.getTimeString()
    };
    this.clevertap.logAnalyticsEvent(analyticsData, false, false);
    this.dss.gr_filter = [];
    this.dss.setCalDayMonthYr();
    this.hideExpore();
    this.profileDropdown = true;
    document.getElementById('showsearch')?.classList?.toggle('showsearch');
    this.masterService.url_after_leave_cme = '/web';
    this.dss.feed_filter.next('reset');
    this.router.navigateByUrl('/web');
    /**
      * Removing the specialty filter.
      */
    this.dss.specialty_filter = '';
  }

  /**
   * navigate To Channel
   */
  navigateToChannel(): void {
    if (this.masterService.isOnline == false) {
      this.masterService.isConnectionModalShown.next('1');
      return;
    }
    this.dss.setCalDayMonthYr();
    window.location.href = this.webSiteUrl + '/web/#/channel';
  }

  /**
   * navigate To Section
   */
  navigateToSection(url, id, value, isFromHamBurger = false): void {
    if (this.masterService.isOnline == false) {
      this.masterService.isConnectionModalShown.next('1');
      return;
    }
    if (isFromHamBurger) {
      this.analyticSdk.analyticService.CLICK(PRODUCT_TYPE_CONSTANT_NAME.OTHER, 0, SCREEN_NAME.HAMBURGER_MENU, CTA_TYPE.TEXT, ACTION_TYPE.CLICK, value, null);
      this.funCollectionService.currentScreename = SCREEN_NAME.HAMBURGER_MENU;
    }
    else {
      if (value === 'connection') {
        const analyticsData = {
          event_name: EVENT_NAME.CONNECTION_DASHBOARD_ENTRY,
          product_type: 0,
          product_type_id: 0,
          screen_name: SCREEN_NAME.GRAND_ROUND,
          start_time: this.getTimeString(),
          end_time:  this.getTimeString()
        };
        this.clevertap.logAnalyticsEvent(analyticsData, false, true);
      }
    }
    this.dss.setCalDayMonthYr(); // set default day month year in event calendar
    this.masterService.url_after_leave_cme = url;
    this.masterService.scroll_position = 0;
    this.hideExpore();
    this.router.navigateByUrl(url);
    if (url == '/web') {
      this.dss.feed_filter.next('reset');
      /**
       * Removing the specialty filter.
       */
      this.dss.specialty_filter = '';
    }
  }

  /**
   * hambuger
   */
  hambuger(): void {
    if (document.querySelector('.nav-menu')) {
      document.querySelector('.nav-menu')?.classList?.toggle('show-right-links');
    }
  }

  /**
   * search
   */
  search(): void {
    document.querySelector('#sreach_bar')?.classList?.toggle('search_show');
  }

  /**
   * http Error Handeler
   */
  httpErrorHandeler(): void {
    // Handle Error
  }

  /**
   * Error Handler
   */
  ErrorHandler(Err_msg): void {

    this.isErrorModalShown = true;
    this.error_msg = Err_msg;
  }

  /**
   * reset ErrorModal
   */
  resetErrorModal(): void {
    this.isErrorModalShown = false;
  }

  /**
   * close hamburgur
   */
  closehamburgur(): void {
    this.open_explore = false;
  }

  /**
   * open Explore
   */
  openExplore(): void {
    this.analyticSdk.analyticService.CLICK(PRODUCT_TYPE_CONSTANT_NAME.OTHER, 0, this.funCollectionService.currentScreename, CTA_TYPE.ICON, ACTION_TYPE.CLICK, ACTION_ON.HAMBURGER_MENU, null);
    if (this.masterService.isOnline == false) {
      this.masterService.isConnectionModalShown.next('1');
      return;
    }
    this.profileDropdown = true;
    if (this.open_explore == false) {
      this.open_explore = true;
      this.hamburgerStartTime = new Date().getTime();
      const entryData: ENTRY = {
        entity_type: PRODUCT_TYPE_CONSTANT_NAME.OTHER,
        entity_code: 0,
        current_screen_name: SCREEN_NAME.HAMBURGER_MENU,
        source: this.funCollectionService.currentScreename,
        start_time: this.hamburgerStartTime
      }
      this.analyticSdk.analyticService.ENTRY(entryData);
    } else {
      this.open_explore = false;
      if (this.hamburgerStartTime > 0) {
        const exitData: EXIT = {
          entity_type: PRODUCT_TYPE_CONSTANT_NAME.OTHER,
          entity_code: 0,
          current_screen_name: SCREEN_NAME.HAMBURGER_MENU,
          start_time: this.hamburgerStartTime,
          end_time: new Date().getTime()
        }
        this.analyticSdk.analyticService.EXIT(exitData);
        this.hamburgerStartTime = 0;
      }
      document.body.querySelector('body')?.classList?.remove('scrollHide');
    }
  }

  /**
   * navigate To User Profile
   */
  navigateToUserProfile(): void {
    this.hideExpore();
    this.profileDropdown = true;
    if (this.masterService.isOnline == false) {
      this.masterService.isConnectionModalShown.next('1');
      return;
    }
    this.masterService.url_after_leave_cme = '/web/profile/' + this.loginUserCustomeId + '/user';
    this.funCollectionService.navigateToUserProfie(this.loginUserCustomeId);
  }

  /**
   * navigate To User Vault
   */
  navigateToUserVault(): void {
    if (this.masterService.isOnline == false) {
      this.masterService.isConnectionModalShown.next('1');
      return;
    }
    this.hideExpore();
    this.profileDropdown = true;
    this.funCollectionService.navigateToVault();
  }

  /**
   * navigate To User Calender
   */
  navigateToUserCalender(): void {
    this.analyticSdk.analyticService.CLICK(PRODUCT_TYPE_CONSTANT_NAME.OTHER, 0, SCREEN_NAME.HAMBURGER_MENU, CTA_TYPE.TEXT, ACTION_TYPE.CLICK, ACTION_ON.CALENDAR, null);
    if (this.masterService.isOnline == false) {
      this.masterService.isConnectionModalShown.next('1');
      return;
    }
    this.hideExpore();
    this.profileDropdown = true;
    this.funCollectionService.navigateToCalenderModule();
  }

  /**
   * navigate To User Bookmark
   */
  navigateToUserBookmark(): void {
    if (this.masterService.isOnline == false) {
      this.masterService.isConnectionModalShown.next('1');
      return;
    }
    this.analyticSdk.analyticService.CLICK(PRODUCT_TYPE_CONSTANT_NAME.OTHER, 0, SCREEN_NAME.HAMBURGER_MENU, CTA_TYPE.TEXT,ACTION_TYPE.CLICK, ACTION_ON.BOOKMARK, null);
    this.hideExpore();
    this.profileDropdown = true;
    this.funCollectionService.navigateToBookmark();
  }

  /**
   * navigate To Chat
   */
  navigateToChat(): void {
    if (this.masterService.isOnline == false) {
      this.masterService.isConnectionModalShown.next('1');
      return;
    }
    if (this.dss.user_profile_data.profile.permission != 'all') {
      this.assignAssociation();
      return;

    }
    this.funCollectionService.navigateToChat();
  }

  /**
   * navigate To Settings
   */
  navigateToSettings(): void {
    if (this.masterService.isOnline == false) {
      this.masterService.isConnectionModalShown.next('1');
      return;
    }
    this.analyticSdk.analyticService.CLICK(PRODUCT_TYPE_CONSTANT_NAME.OTHER, 0, SCREEN_NAME.HAMBURGER_MENU, CTA_TYPE.TEXT, ACTION_TYPE.CLICK, ACTION_ON.APP_SETTINGS, null);
    this.hideExpore();
    this.profileDropdown = true;
    this.funCollectionService.navigateToSetting();
  }

  /**
   * navigate To Feedback
   */
  navigateToFeedback(): void {
    if (this.masterService.isOnline == false) {
      this.masterService.isConnectionModalShown.next('1');
      return;
    }
    this.analyticSdk.analyticService.CLICK(PRODUCT_TYPE_CONSTANT_NAME.OTHER, 0, SCREEN_NAME.HAMBURGER_MENU, CTA_TYPE.TEXT, ACTION_TYPE.CLICK, ACTION_ON.SEND_SUPPORT_FEEDBACK, null);
    this.hideExpore();
    this.profileDropdown = true;
    this.funCollectionService.navigateToFeedback();
  }

  /**
   * navigate To tnc
   */
  navigateTotnc(): void {
    if (this.masterService.isOnline == false) {
      this.masterService.isConnectionModalShown.next('1');
      return;
    }
    this.hideExpore();
    this.analyticSdk.analyticService.CLICK(PRODUCT_TYPE_CONSTANT_NAME.OTHER, 0, SCREEN_NAME.HAMBURGER_MENU, CTA_TYPE.TEXT, ACTION_TYPE.CLICK, ACTION_ON.DQ_TnC, null);
    this.profileDropdown = true;
    this.funCollectionService.navigateTotnc();
  }

  /**
   * navigate To Connection
   */
  navigateToConnection(): void {
    const analyticsData = {
      event_name: EVENT_NAME.CONNECTION_DASHBOARD_ENTRY,
      product_type: 0,
      product_type_id: 0,
      screen_name: SCREEN_NAME.GRAND_ROUND,
      start_time: this.getTimeString(),
      end_time:  this.getTimeString()
    };
    this.clevertap.logAnalyticsEvent(analyticsData, false, true);
    if (this.masterService.isOnline == false) {
      this.masterService.isConnectionModalShown.next('1');
      return;
    }

    this.funCollectionService.navigateToConnection();
  }

  /**
   * hide Expore
   */
  hideExpore(): void {
    this.open_explore = false;
    if (this.hamburgerStartTime > 0) {
      const exitData: EXIT = {
        entity_type: PRODUCT_TYPE_CONSTANT_NAME.OTHER,
        entity_code: 0,
        current_screen_name: SCREEN_NAME.HAMBURGER_MENU,
        start_time: this.hamburgerStartTime,
        end_time: new Date().getTime()
      }
      this.analyticSdk.analyticService.EXIT(exitData);
    }
    document.querySelector('body')?.classList?.remove('scrollHide');
  }

  /**
   * navigate From Explore
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  navigateFromExplore(exp: any): void {
    if (this.masterService.isOnline == false) {
      this.masterService.isConnectionModalShown.next('1');
      return;
    }
    switch (exp.value) {
      /**********************group sort order 1*****************/
      case 'news': {
        this.hideExpore();
        this.masterService.url_after_leave_cme = '/news/list';
        this.router.navigateByUrl('/news/list');
        break;
      }
      case 'cme': {
        this.hideExpore();
        this.masterService.url_after_leave_cme = '/cme';
        this.router.navigateByUrl('/cme');
        break;
      }
      case 'channel': {
        this.hideExpore();
        this.masterService.url_after_leave_cme = '/channel/detail/0';
        this.router.navigateByUrl('/channel/detail/0');
        break;
      }
      case 'productchannel': {
        this.hideExpore();
        this.funCollectionService.navigateToChannelDetail(exp.product_id);
        break;
      }
      case 'event': {
        this.hideExpore();
        this.masterService.url_after_leave_cme = '/event/list/upcoming';
        this.router.navigateByUrl('/event/list/upcoming');
        break;
      }
      case 'journal': {
        this.hideExpore();
        this.masterService.url_after_leave_cme = '/journal';
        this.router.navigateByUrl('/journal');
        break;
      }
      /**********************group sort order 2*****************/
      case 'clinicalcases': {
        this.hideExpore();
        sessionStorage.removeItem('feed_kind_filter');
        sessionStorage.removeItem('speciality_filter');
        const feed_kind_filter = { filter_type: 'kind', filter_id: ['cases'] };
        this.localstorage.setInSessionstorage('feed_kind_filter', this.encrypt.encryptAes(JSON.stringify(feed_kind_filter)));
        setTimeout(() => {
          this.masterService.url_after_leave_cme = 'web/filter/' + this.funCollectionService.makeid();
          this.router.navigateByUrl('web/filter/' + this.funCollectionService.makeid());
        }, 1000);
        break;
      }
      case 'insights': {
        this.hideExpore();
        sessionStorage.removeItem('feed_kind_filter');
        sessionStorage.removeItem('speciality_filter');
        const feed_kind_filter = { filter_type: 'kind', filter_id: ['post'] };
        this.localstorage.setInSessionstorage('feed_kind_filter', this.encrypt.encryptAes(JSON.stringify(feed_kind_filter)));
        this.masterService.url_after_leave_cme = 'web/filter/' + this.funCollectionService.makeid();

        this.router.navigateByUrl('web/filter/' + this.funCollectionService.makeid());
        break;
      }
      case 'poll': {
        this.hideExpore();
        sessionStorage.removeItem('feed_kind_filter');
        sessionStorage.removeItem('speciality_filter');
        const feed_kind_filter = { filter_type: 'kind', filter_id: ['poll'] };
        this.localstorage.setInSessionstorage('feed_kind_filter', this.encrypt.encryptAes(JSON.stringify(feed_kind_filter)));
        setTimeout(() => {
          this.masterService.url_after_leave_cme = 'web/filter/' + this.funCollectionService.makeid();

          this.router.navigateByUrl('web/filter/' + this.funCollectionService.makeid());
        }, 1000);
        break;
      }
      case 'chats': {
        this.hideExpore();
        break;
      }
      /**********************group sort order 3*****************/
      case 'mydownload': {
        this.hideExpore();
        break;
      }
      case 'mybookmarks': {
        this.hideExpore();

        this.funCollectionService.navigateToUserProfie_spe_sec(this.loginUserCustomeId, 'bookmark/feed');
        break;
      }
      case 'qna': {
        this.hideExpore();
        this.masterService.url_after_leave_cme = '/web/profile/' + this.loginUserCustomeId + '/qa';

        this.funCollectionService.navigateToUserProfie_spe_sec(this.loginUserCustomeId, 'qa');
        break;
      }
      case 'settings': {
        this.hideExpore();
        break;
      }
      case 'rewards': {
        this.hideExpore();
        this.masterService.url_after_leave_cme = 'refer-and-earn/referral';
        this.funCollectionService.navigateToReferal();
        break;
      }
    }
  }

  /**
   * on Item Select
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onItemSelect(item: any): void {

    if (item.id == 100001) {
      this.selectedAss.splice(this.userAssList.length - 1, 1);
    }
  }

  /**
   * navigate To Profile
   */
  navigateToProfile(): void {
  
    const analyticsData = {
      event_name: EVENT_NAME.PROFILE_ENTRY,
      product_type: 0,
      product_type_id: 0,
      screen_name: SCREEN_NAME.GRAND_ROUND,
      start_time: this.getTimeString(),
      end_time:   this.getTimeString()
    };
    this.clevertap.logAnalyticsEvent(analyticsData, false, false);
    if (this.masterService.isOnline == false) {
      this.masterService.isConnectionModalShown.next('1');
      return;
    }
    this.hideExpore();
    this.profileDropdown = true;
    if( localStorage.getItem("fromOpenAccess") == '1'){
      this.loginUserCustomeId = localStorage.getItem("userCUstomId") 
    }
    this.funCollectionService.navigateToUserProfie(this.loginUserCustomeId);
  }

  /**
   * navigate To Time line
   */
  navigateToTimeline(): void {
    if (this.masterService.isOnline == false) {
      this.masterService.isConnectionModalShown.next('1');
      return;
    }
    this.hideExpore();
    this.masterService.url_after_leave_cme = '/web/profile/' + this.loginUserCustomeId + '/timeline';
    this.funCollectionService.navigateToUserProfie_spe_sec(this.loginUserCustomeId, 'timeline');
  }

  /**
   * show Get sample Popup
   */
  showGetsamplePopup(): void {
    this.isGetsampleModal = true;
  }

  /**
   * reset Product Get sample Modal
   */
  resetProductGetsampleModal(): void {
    this.isGetsampleModal = false;
  }

  /**
   * navigate To Referal
   */
  navigateToReferal(): void {
    this.funCollectionService.navigateToReferal();
  }

  /**
   * navigateTo
   */
  navigateTo(section): void {
    this.router.navigateByUrl(section);
  }

  /**
   * back clicked
   */
  backclicked(): void {
    this.funCollectionService.navigateTohistoryback();
  }

  /**
   * assign Association
   */
  assignAssociation(): void {
    this.dss.selected_suggAssList = [];
    this.subscriptions.add(this.postSer.assignAssociation('association', '[]', 'open_chat', 0).subscribe(resData => {
      this.jwtSer.decodeData(resData).then(data => {
        if (data['status'] == 1) {
          if (data['code'] == 2000) {
            this.displayObj.isShow = true;
            this.displayObj.display_section = 'verificationSuccess';
            this.displayObj.data = data['data'];
            this.displayObj.errObj = [];
            this.masterService.showWebModal.next(this.displayObj);
          }
        } else {
          this.funCollectionService.errorHandeler(data, 'generate_certificate');
          return;
        }
      });
    }));
  }

  /**
   * Accept updated terms and conditions and privacy policy
   */
  tncUpdate(): void {
    this.GetSer.getTermsPolicyUpdate(this.offsetVal)
      .pipe(takeWhile(() => this.componentActive))
      .subscribe(data => {
        if (
          data
          && data['status'] === 1
          && data['code'] === 2000
          && data['data'].high_priority_in_app !== null
          && data['data'].high_priority_in_app.inapp_type === 'tncupdate') {
          this.termsConditionsUpdate = true;
        } else {
          this.termsConditionsUpdate = false;
        }
      });
  }

  /**
   * Accept updated terms and conditions and privacy policy
   */
  acceptConditions(): void {
    this.subscriptions.add(this.postSer.acceptTnCUpdate().subscribe(data => {
      if (data && data['status'] === 1 && data['code'] === 2000) {
        this.termsConditionsUpdate = false;
      }
    }));
  }

  /**
   * Get registration status.
   */
  // TODO: Nilesh: AUto verification.
  registrationStatus(): void {
     const a = '';
    this.subscriptions.add(this.GetSer.getRegistrationStatus().subscribe(screenData => {
      localStorage.setItem('newUser','1');
      if (screenData && screenData['status'] === NUMBERS.ONE && screenData['code'] === NUMBERS.TWO_THOUSAND_ONE) {
        this.localstorage.removeItemLocalStorage(LOCAL_STORAGE.SUCCESS_SCREEN);
        if(localStorage.getItem('isComingFromDeepLink') != ISFROMDEEPLINK.TRUE){
          const a = localStorage.getItem('utmSourceData')
        } else {
          localStorage.setItem('removeUtmData', '1')
        }
      }
      if (screenData && screenData['status'] === NUMBERS.ONE && screenData['code'] === NUMBERS.TWO_THOUSAND) {
        if (screenData.data.screen_data.length && localStorage.getItem('isComingFromDeepLink') != ISFROMDEEPLINK.TRUE) {
          this.isVerificationOkay = true;
          this.verificationContent = screenData.data.screen_data;
        }
      }
    }));
  }

  /**
   * Expand the chat list panel.
   */
  expandChat(): void {
    this.dqChatPanelService.expandChat.next(true);
    this.dqChatPanelService.isChatListHide.next(false);
    localStorage.setItem('isChatTutorial', 'true');
    this.isChatTutorial = false;
    if (!this.deviceService.isDesktop() || this.chatWindowState) {
      this.navigateToSamvaad();
    }
  }

  /**
   * Start the chat.
   */
  triggerChat(): void {
    this.sendAnalytics(EVENT_NAME.CHAT_ICON_CLICK, PRODUCT_TYPE_CONSTANT.CHAT, 0, SCREEN_NAME.TOP_CHAT_ICON)
    if (this.profileInfo.permission !== 'all') {
      this.checkVerification();
    } else {
      if (this.funCollectionService.isChatMaintenance) {
        this.funCollectionService.isSamvaadMaintanceModal = true;
        return;
      }
      const isChatTutorial = localStorage.getItem('isChatTutorial');
      if (isChatTutorial) {
        this.dqChatPanelService.isChatListHide.next(false);
        this.dqChatPanelService.expandChat.next(true);
        if (!this.deviceService.isDesktop() || this.chatWindowState) {
          this.navigateToSamvaad();
        }
      } else {
        this.isChatTutorial = true;
      }
    }
  }

  /**
   * Close the samvaad maintance modal.
   */
  closeSamvaadMaintanceModal(): void {
    this.funCollectionService.isSamvaadMaintanceModal = false;
    this.sendAnalytics(EVENT_NAME.SAMVAAD_MAINTENANCE_POPUP_VISIT, PRODUCT_TYPE_CONSTANT.CHAT, 0, SCREEN_NAME.SAMVAAD_MAINTENANCE_POPUP)
  }

  /**
   * Send analytics event.
   */
  sendAnalytics(eventName: EventDataKey, productType: number, productTypeId: number, screenName: string): void {
    const analyticsData = {
      event_name: eventName,
      product_type: productType,
      product_type_id: productTypeId,
      screen_name: screenName,
      start_time: new Date().getTime().toString(),
      end_time: new Date().getTime().toString()
    };
    this.clevertap.logAnalyticsEvent(analyticsData);
  }

  /**
   * Check if user is verified or not.
   */
  checkVerification(): void {
    this.subscriptions.add(this.postSer.assignAssociation('association', '[]', 'open_chat', 0)
      .subscribe(resData => {
        if (resData.code === 2000 && resData.status === 1) {
          this.displayObj.isShow = true;
          this.displayObj.display_section = 'verificationSuccess';
          this.displayObj.data = resData.data;
          this.displayObj.errObj = [];
          this.masterService.showWebModal.next(this.displayObj);
        } else {
          this.funCollectionService.errorHandeler(resData, 'generate_certificate');
          return;
        }
      }));
  }

  /**
   * Navigate to the samvaad detail page.
   */
  navigateToSamvaad(): void {
    if (this.profileInfo.permission !== 'all') {
      this.checkVerification();
    } else {
      this.router.navigateByUrl('/samvaad/list');
    }
  }
  logutHamburger(): void {
    this.analyticSdk.analyticService.CLICK(PRODUCT_TYPE_CONSTANT_NAME.OTHER, 0, SCREEN_NAME.HAMBURGER_MENU, CTA_TYPE.TEXT, ACTION_TYPE.CLICK, ACTION_ON.LOGOUT, null);
    const utmObject = { 'UTM_Medium':  'NU', 'UTM_Source': 'NU', 'UTM_Campaign': 'NU' };
    localStorage.setItem('utmSourceData',  JSON.stringify(utmObject));
    localStorage.setItem('isComingFromDeepLink',  '0');
    sessionStorage.setItem('logoutType', LOGOUT_TYPE.MANUAL);
    this.funCollectionService.initiateLogout();
  }
  /**
   * Logout user
   */
  userlogout():void {
    this.analyticSdk.analyticService.CLICK(PRODUCT_TYPE_CONSTANT_NAME.OTHER, 0, this.funCollectionService.currentScreename, CTA_TYPE.TEXT, ACTION_TYPE.CLICK, ACTION_ON.LOGOUT, null);
    const utmObject = { 'UTM_Medium':  'NU', 'UTM_Source': 'NU', 'UTM_Campaign': 'NU' };
    localStorage.setItem('utmSourceData',  JSON.stringify(utmObject));
    localStorage.setItem('isComingFromDeepLink',  '0');
    sessionStorage.setItem('logoutType', LOGOUT_TYPE.MANUAL);
    this.funCollectionService.initiateLogout();
  }

  /**
   * unsubscribe the subscription.
   */
  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
    this.componentActive = false;
  }
}

