/**
 * Angular import.
 */
import { Directive, HostListener, Input, NgZone } from '@angular/core';
/**
 * Service import.
 */
import { AnalyticsEventService } from './analytics-event.service';
/**
 * Interface import.
 */
import { AnalyticsInterface } from './analytics.model';

/**
 * Analytics directive.
 */
@Directive({
  selector: '[appAnalyticsEvent]'
})
export class AnalyticsEventDirective {

  /**
   * Instance created.
   */
  constructor(private analyticsEventService: AnalyticsEventService,
    private zone: NgZone) { }

  /**
   * Analytics data.
   */
  @Input('appAnalyticsEvent') analyticsData: AnalyticsInterface;

  /**
   *Click event that is called from directive.
   */
  @HostListener('click', ['$event']) onClick() {
    //TO-DO: Tech Debt: below code need to be refactor 
    this.zone.runOutsideAngular(() => {
      if (this.analyticsData && this.analyticsData.screen_name) {
        this.analyticsEvent();
      }
    });
  }
  /**
   * Deside which analytics events we need to call.
   */
  analyticsEvent(): void {
    if (this.analyticsEventService.checkScreenName(this.analyticsData)) {
      if (this.analyticsData.event_name.hasCL) {
        this.clevertapEvent();
      }

      if (this.analyticsData.event_name.hasDQ) {
        this.analyticsData.start_time = new Date().getTime().toString();
        this.analyticsData.end_time = new Date().getTime().toString();
        this.analyticsEventService.setDocquityAnalytic(this.analyticsData);
      }
      if (this.analyticsData.event_name.hasGA) {
        this.analyticsEventService.setGA(this.analyticsData);
      }
    }
  }

  /**
   * Calling cleveratp event.
   */
  clevertapEvent(): void {
    const analyticsCTData = {
      event_name: this.analyticsData.event_name.name,
      product_type: this.analyticsData.product_type,
      product_type_id: this.analyticsData.product_type_id,
      screen_name: this.analyticsData.screen_name,
      PropertyType: this.analyticsData.PropertyType,
      Specialty: this.analyticsData.Specialty,
      start_time: this.analyticsEventService.getCTTimeFormat(new Date()),
      end_time: this.analyticsEventService.getCTTimeFormat(new Date())
    };
    /**
     * Analytics params handling for Live Poll Data
     */
    if (this.analyticsData && this.analyticsData['option_id'] !== '' && this.analyticsData['poll_id'] !== '') {
      analyticsCTData['option_id'] = this.analyticsData['option_id'];
      analyticsCTData['poll_id'] = this.analyticsData['poll_id'];
    }
    this.analyticsEventService.logClevertapEvent(analyticsCTData);
  }
}
