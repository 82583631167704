/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Angular imports.
 */
import { HttpBackend, HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';


/**
 * Constant imports.
 */
import { environment } from '../../../environments/environment';
import { API } from '../../constants/api.constants';
/**
 * rxjs imports
 */
import { Observable, Subject, of, throwError } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';
/**
 * model imports
 */
import { DEFAULT_COUNTRY_CODE, DEFAULT_IP_ADDR, DEFAULT_LANG, FILE_TYPE_ACCESS, FILE_TYPE_EXTENSIONS, MODULE_NAME, STATUS_CODE, SURVEY_ACCESS_TYPE, SURVEY_STATUS_CODE, WEBVIEW_MOBILE_METHOD_POST, WEBVIEW_MOBILE_PARAMS } from '../../constants/app.constants';
import { ShareContentData } from '../../onboarding/interface/apiResponse.model';
import {
  Footer,
  PayOutLimitResponse,
  PostConsent,
  ShareContent,
  SurveyDetailResponse,
  SurveyDiscardResponse,
  SurveyQuestionSubmitResponse,
  SurveySectionDetailResponse,
  SurveySubmit
} from '../../survey/model/survey.model';

/**
 * Service imports
 */
import { AuthService } from '../../auth/auth.service';
import { FunCollectionService } from '../../common/common-functions/fun-collection.service';
import { FILE_TYPE } from '../../interface/keeperResponseConstant/responseConst';
import { SurveyHelperService } from '../../survey/survey-helper.service';
import { MobileWebViewCommunicationService } from '../mobileWebViewCommunication/mobile-web-view-communication.service';
/**
 * library imports
 */
import * as moment from 'moment';
import { SURVEY_REWARD_TAB_TYPE } from 'src/app/constants/featureConstants/survey.constants';
import { MasterserviceService } from '../master-service/masterservice.service';

/**
 * survey service.
 */
@Injectable({
  providedIn: 'root'
})
export class SurveyService implements OnDestroy {

  /**
   * property for surveyId
   */
  public surveyId = '';

  /**
   * property for uuid
   */
  public uuid: string;

  /**
   * property for deviceType
   */
  public deviceType: string;

  /**
   * property for surveyType
   */
  public surveyType: SURVEY_ACCESS_TYPE;

  /**
   * property for mcToken
   */
  public isMcTokenAvailable: boolean;

  /**
   * tracks channel redirection
   */
  public channelRedirection = false;

  /**
   * for max respondant pop up
   */
  public maxPopup = false;
  /**
   * for survey expity pop up
   */
  public isExpired = false;
  public currentDate: Date;
  public moment = moment;
  public isRetake = false;

  public PifCourseData:any;

  public patCode='';

  /**
   * notifier subject to close data stream
   */
  private notifier$ = new Subject();
  /**
   * Create necessary instances.
   */
  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
    private mvc: MobileWebViewCommunicationService,
    private fcs: FunCollectionService,
    private router: Router,
    private helper: SurveyHelperService,
    private masterService: MasterserviceService,
    private httpBackend: HttpBackend
  ) {
    this.currentDate = moment().toDate();
    this.setToken();
    this.mvc.updateTokenRequest$.subscribe((flag: boolean) => {
      if (flag) {
        this.setToken();
      }
    });
  }

  /**
   * get survey listing.
   */
  // eslint-disable-next-line @typescript-eslint/ban-types
  getSurveyList(submitStatus: string, pageNumber: string, pageSize: string): Observable<HttpResponse<Object>> {
    const params = {
      submitStatus,
      pageNumber,
      pageSize
    };
    const endPoint = this.isPublicSource ? `${API.PUBLIC_SURVEY_LIST}` : `${API.SURVEY_LIST}`;
    return this.httpClient.get(endPoint,
      {
        observe: 'response',
        params: params,
      });
  }
  /**
   * get pending survey listing.
   */
  // @typescript-eslint/no-explicit-any
  getPendingSurveyList(pageNumber: string, pageSize: string): Observable<any> {

    const params = {
      pageNumber,
      pageSize
    };

    const endPoint = this.isPublicSource ? `${API.PUBLIC_SURVEY_PENDING_LIST}` : `${API.SURVEY_PENDING_LIST}`;
    return this.httpClient.get(endPoint,
      {
        observe: 'response',
        params,
      });
  }

  /**
   * Survey detail api
   */
  // eslint-disable-next-line @typescript-eslint/ban-types
  getSurveyDetails(surveyId: string): Observable<HttpResponse<Object>> {
    const headers = {
      udid: environment.udid,
    };

    let endPoint = (this.isPublicSource || this.mvc.authenticatedExternalSurvey) ? `${API.PUBLIC_SURVEY_DETAIL}` : `${API.SURVEY_DETAIL}`;
    endPoint += `/${surveyId}`;
    return this.httpClient.get<SurveyDetailResponse>(endPoint, {
      headers,
      observe: 'response'
    }).pipe(
      map(response => response),
      catchError((error) => {
        console.log(error);
        return throwError(error);
      })
    );
  }

  /**
   * footer detail api for surveys
   */
  getFooterDetails(fcode: string): Observable<Footer> {
    const headers = {
      udid: environment.udid,
    };

    let endPoint = (this.isPublicSource || this.mvc.authenticatedExternalSurvey) ? `${API.PUBLIC_SURVEY_FOOTER}` : `${API.SURVEY_FOOTER}`;
    endPoint += `/${fcode}`;
    return this.httpClient.get<Footer>(endPoint, {
      headers
    });
  }

  /**
   * Consent detail api for surveys
   */
  getConsentDetails(ccode: string): Observable<any> {
    const headers = {
      udid: environment.udid,
    };

    let endPoint = (this.isPublicSource || this.mvc.authenticatedExternalSurvey) ? `${API.PUBLIC_SURVEY_CONSENT}` : `${API.SURVEY_CONSENT}`;
    endPoint = `${endPoint}/${ccode}`;

    return this.httpClient.get<any>(endPoint, {
      headers
    });
  }

  /**
   * get survey rewards listing.
   */
  // eslint-disable-next-line @typescript-eslint/ban-types
  getSurveyRewardsList(rewardStatus: string, pageNumber: string, pageSize: string): Observable<HttpResponse<Object>> {
    const params = {
      rewardStatus,
      pageNumber,
      pageSize
    };
    const endPoint = API.SURVEY_REWARDS_LIST;
    return this.httpClient.get(endPoint,
      {
        observe: 'response',
        params: params,
      });
  }

  /**
   * rewards total value
   * @param rewardsStatus
   * @returns
   */
  // eslint-disable-next-line @typescript-eslint/ban-types
  getSurveyRewardsTotal(rewardsStatus: string = SURVEY_REWARD_TAB_TYPE.PENDING): Observable<HttpResponse<Object>> {
    const params = {
      rewardsStatus
    };
    const endPoint = API.SURVEY_REWARDS_TOTAL;
    return this.httpClient.get(endPoint,
      {
        observe: 'response',
        params: params,
      });
  }

  /**
   * survey redeem rewards
   * @param code
   * @returns
   */
  // eslint-disable-next-line @typescript-eslint/ban-types
  public redeemSurveyRewards(surveyData): Observable<Object> {
    const endPoint = surveyData.code ? API.SURVEY_REWARD_REDEEM.replace(':code', surveyData.code) : API.SURVEY_REWARDS_REDEEM_ALL;
    return this.httpClient.post(endPoint, surveyData.data,
      {
        observe: 'response',
      });
  }

  /**
   * submit consent
   * @param consentCode
   * @param entityCode
   * @param entityType
   * @returns
   */
  submitSurveyConsent(consentCode: string, entityCode: string, entityType: string): Observable<any> {
    const body = {
      consentCode,
      entityCode,
      entityType
    };
    const headers = {
      udid: environment.udid
    };

    const endPoint = (this.isPublicSource || this.mvc.authenticatedExternalSurvey) ? `${API.PUBLIC_SURVEY_CONSENT_SUBMIT}` : `${API.SURVEY_CONSENT_SUBMIT}`;
    return this.httpClient.post<any>(endPoint, body, { headers }).pipe(
      map(response => response),
      catchError((error) => {
        return of(error);
      })
    );
  }

  /**
   * Get the survey detail for the section.
   */
  getNewSurveyDetail(code: string, sc?: string, viewMode?: string, attemptIdentifier?: string): Observable<SurveySectionDetailResponse> {
    let endPoint = (this.isPublicSource || this.mvc.authenticatedExternalSurvey) ? `${API.PUBLIC_SURVEY_DETAIL_SECTION}` : `${API.SURVEY_DETAIL_SECTION}`;
    endPoint += `/${code}/section${viewMode ? viewMode : ''}`;
    const params = { sectionCode: sc ? sc : '' , attemptIdentifier: attemptIdentifier ? attemptIdentifier : '' };
    return this.httpClient.get<SurveySectionDetailResponse>(endPoint, {
      params
    });
  }

  /**
   * Discard the survey response.
   */
  discardSurveyDetail(code: string): Observable<SurveyDiscardResponse> {
    let endPoint = this.isPublicSource ? `${API.PUBLIC_DISCARD_SURVEY}` : `${API.DISCARD_SURVEY}`;
    endPoint += `/${code}/discard`;
    return this.httpClient.delete<SurveyDiscardResponse>(endPoint);
  }

  /**
   * Custom media streaming log api incase of window unload(reload, tab and browser close).
   */
  callSubmitSurveyOnWindowUnLoad(bodyData: SurveySubmit, surveyDetailId: string): void {
    bodyData.userCode = this.authService.custom_id;
    const body = bodyData;
    let endPoint = (this.isPublicSource || this.mvc.authenticatedExternalSurvey) ? `${environment.API_URLS.dfs}${API.PUBLIC_SURVEY_DETAIL_SUBMIT}` : `${environment.API_URLS.dfs}${API.SURVEY_DETAIL_SUBMIT}`;
    endPoint += `/${surveyDetailId}`;
    this.customXhrApiCall(endPoint, body);
  }

  /**
 * Get payment gateway bank details
 */
  getPaymentGatewayDetails(currency: string ): Observable<PayOutLimitResponse> {
    const endPoint = API.XENDIT_PAYMENT_GATEWAY;
    const params ={
      'currency' : currency,
      'channel_category' : 'BANK'
    }

    return this.httpClient.get<PayOutLimitResponse>(endPoint,{
        'params' : params
    });
  }

  /**
   * retrieves token details from local storage
   */
  getTokenDetailsFromStorage(): string | null {
    return JSON.parse(localStorage.getItem('mcTokenDetail'))?.at || null;
  }

  /**
   * Custom xhr call in case of httpClient not work.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  customXhrApiCall(endpoint: string, body: any): void {
    const xhr = new XMLHttpRequest();
    xhr.open('POST', endpoint, false);
    xhr.setRequestHeader('Content-Type', 'application/json');
    if (!this.isPublicSource && !this.mvc.authenticatedExternalSurvey) {
      xhr.setRequestHeader('Authorization', `Bearer ${this.getTokenDetailsFromStorage()}`);
    } else {
      if (this.mvc.authenticatedExternalSurvey && localStorage.getItem('externalUuid')) {
        xhr.setRequestHeader('uuid', localStorage.getItem('externalUuid'));
      } else {
        xhr.setRequestHeader('uuid', localStorage.getItem('uuid') || '');
      }
    }
    xhr.setRequestHeader('Application-Version', environment.versions.appVersion);
    xhr.setRequestHeader('Country-Code', localStorage.getItem('country_code') || DEFAULT_COUNTRY_CODE);
    xhr.setRequestHeader('Timezone', moment.tz.guess());
    xhr.setRequestHeader('Device-Type', environment.deviceType);
    xhr.setRequestHeader('Ip-Address', DEFAULT_IP_ADDR);
    xhr.setRequestHeader('Allow-Origin', window.location.hostname);
    xhr.setRequestHeader('Module', MODULE_NAME.DQ);
    xhr.setRequestHeader('Request-Id', this.generateLocalId());
    xhr.setRequestHeader('Accept-Language', localStorage.getItem('lang') || DEFAULT_LANG);

    xhr.send(JSON.stringify(body));
  }

  /**
   * Generate LocalId,
   * which is the combination of TimeStamp UNIX Device Type and Four digit random number.
   */
  generateLocalId(): string {
    return `${this.getCurrentTimestamp()}${environment.deviceType}${this.generateRandomFourDigits(9000)}`;
  }

  /**
   * Get Current Timestamp 13 digits.
   */
  getCurrentTimestamp(): number {
    return new Date().getTime();
  }

  /**
   * Generate the random four digits.
   */
  generateRandomFourDigits(digits: number): number {
    return Math.floor(1000 + Math.random() * digits);
  }

  /**
   * Submit the survey
   */
  submitSurvey(bodyData: SurveySubmit, surveyDetailId: string): Observable<SurveyQuestionSubmitResponse> {
    bodyData.userCode = this.authService.custom_id;
    const body = bodyData;
    let endPoint = (this.isPublicSource || this.mvc.authenticatedExternalSurvey) ? `${API.PUBLIC_SURVEY_DETAIL_SUBMIT}` : `${API.SURVEY_DETAIL_SUBMIT}`;
    endPoint += `/${surveyDetailId}`;
    return this.httpClient.post<SurveyQuestionSubmitResponse>(endPoint,
      body, {});
  }

  formatDate(date): string {
    return (
      [
        date.getFullYear(),
        this.padTo2Digits(date.getMonth() + 1),
        this.padTo2Digits(date.getDate()),
      ].join('-') +
      ' ' +
      [
        this.padTo2Digits(date.getHours()),
        this.padTo2Digits(date.getMinutes()),
        this.padTo2Digits(date.getSeconds()),
      ].join(':')
    );
  }

  toDateFromTime(date): Date {
    const tempTime = date.split(':');
    this.currentDate.setHours(tempTime[0]);
    this.currentDate.setMinutes(tempTime[1]);
    this.currentDate.setSeconds(0);
    return this.currentDate;
  }

  padTo2Digits(num): string {
    return num.toString().padStart(2, '0');
  }
  /**
   * getter to check public api to be invoked or not
   */
  get isPublicSource(): boolean {
    return !this.isMcTokenAvailable && this.surveyType === SURVEY_ACCESS_TYPE.EXTERNAL;
  }

  /**
   * get Share
   */
  getShare(product_type, product_type_id): Observable<ShareContentData> {
    const params = { product_type, product_type_id };
    return this.httpClient.get<ShareContentData>(API.LIST_SHARE_CONTENT, {
      params: params
    }).pipe(
      map(response => {
        if (response.code === STATUS_CODE.TWO_THOUSAND &&
          response.error.code === STATUS_CODE.ONE_THOUSAND) {
          this.mvc.sendInfoToBothMobile({
            method: WEBVIEW_MOBILE_METHOD_POST,
            params: WEBVIEW_MOBILE_PARAMS.SHARE_CLICK,
            body: {
              ...response.data.share_content
            }
          });
          return response;
        }
      }),
      catchError(error => {
        throw new Error(error);
      })
    );
  }

  shareExternalSurvey(data: ShareContent): void {
    if (data !== undefined && data !== null) {
      const shareProduct = {
        email_content: data.ect,
        email_title: data.etl,
        other_content: data.oct,
        share_link: data.dpl,
      };
      this.mvc.sendInfoToBothMobile({
        method: WEBVIEW_MOBILE_METHOD_POST,
        params: WEBVIEW_MOBILE_PARAMS.SHARE_CLICK,
        body: {
          ...shareProduct
        }
      });
    }
  }

  /**
   * check if file is Video
   */
  public isVideo(url: string): boolean {
    const videoExtensions = FILE_TYPE.ACCEPT_VIDEO;
    const extension = this.getFileExtension(url);
    return extension ? videoExtensions.some(item => extension.toLowerCase().includes(item)) : false;
  }

  /**
   * check if file is image
   */
  public isImage(url: string): boolean {
    const imageExtensions = FILE_TYPE.ACCEPT_IMAGE;
    const extension = this.getFileExtension(url);
    return extension ? imageExtensions.some(item => extension.toLowerCase().includes(item)) : false;
  }

  /**
   * get extension of file
   */
  getFileExtension(url: string): any {
    if (url) {
      const parts = url.split('?');
      return parts[0];
    }
  }

  /**
   * Get valid file name.
   */
  getFileName(url): string {
    if (url) {
      const regex = /dfs\/([^_]+)_\d+\.([^.?]+)\?Expires=/i;
      const match = url.match(regex);

      if (match && match[1] && match[2]) {
        const fileName = match[1];
        const extension = match[2];
        const result = `${fileName}.${extension}`;
        return (result);
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  /**
   * Get file types.
   */
  getFileType(type = ''): string {
    let fileType = '';
    switch (type.toLowerCase()) {
      case FILE_TYPE_EXTENSIONS.MP4:
      case FILE_TYPE_ACCESS.VIDEO_MP4:
        fileType = FILE_TYPE_ACCESS.VIDEO_MP4;
        break;
      case FILE_TYPE_EXTENSIONS.PNG:
      case FILE_TYPE_ACCESS.IMAGE_PNG:
        fileType = FILE_TYPE_ACCESS.IMAGE_PNG;
        break;
      case FILE_TYPE_EXTENSIONS.JPG:
      case FILE_TYPE_EXTENSIONS.JPEG:
      case FILE_TYPE_ACCESS.IMAGE_JPEG:
        fileType = FILE_TYPE_ACCESS.IMAGE_JPEG;
        break;
      case FILE_TYPE_EXTENSIONS.GIF:
        fileType = FILE_TYPE_ACCESS.IMAGE_GIF;
        break;
      case FILE_TYPE_EXTENSIONS.SVG:
        fileType = FILE_TYPE_ACCESS.IMAGE_SVG;
        break;
      case FILE_TYPE_ACCESS.APP_PDF:
      case FILE_TYPE_EXTENSIONS.PDF:
        fileType = FILE_TYPE_ACCESS.APP_PDF;
        break;
      case FILE_TYPE_ACCESS.APP_PPT:
      case FILE_TYPE_EXTENSIONS.PPT:
        fileType = FILE_TYPE_ACCESS.APP_PPT;
        break;
      case FILE_TYPE_ACCESS.APP_DOC:
      case FILE_TYPE_EXTENSIONS.DOCX:
      case FILE_TYPE_EXTENSIONS.DOC:
        fileType = FILE_TYPE_ACCESS.APP_DOC;
        break;
      default:
        fileType = '';
        break;
    }
    return fileType;
  }

  /**
   * getter to support old app or not
   */
  get supportOldApp(): boolean {
    const deviceType = this.mvc.externalSurveyDetail?.deviceType;
    const flag = deviceType && this.isPublicSource &&
      this.mvc.isAuthenticByPass &&
      !this.mvc.checkForDesktop() &&
      this.mvc.checkForMobilePlatform(deviceType);
    return flag;
  }

  /**
   * to retriev survey details and navigate to retake
   * @param code
   */
  getSurveyInfo(code: string): void {
    this.getSurveyDetails(code).pipe(
      takeUntil(this.notifier$)
    ).subscribe(
      (response: any) => {
        const res = response.body;
        if (res.mc === SURVEY_STATUS_CODE.SUCCESS) {
          const detailData = res;
          if (!this.helper.checkForTheExpiry(detailData.lc.edms)) {
            this.setDataToStorage(res);
            if (detailData.lc.att === true) {
              localStorage.setItem('ts', detailData?.lc?.tsec);
              localStorage.removeItem('currentProgress');
              this.submitSurveyConsent(detailData.lc.ccode, detailData.lc.code, 'SURVEY').subscribe((res: PostConsent) => {
                if (res.mc === SURVEY_STATUS_CODE.SUCCESS) {
                  this.router.navigate(['/survey/question', detailData.lc.code]);
                  localStorage.setItem('ts', detailData?.lc?.tsec);
                }
              })
            } else {
              this.maxPopup = true;
            }
          } else {
            this.isExpired = true;
          }
        }
      }, (error) => {
        this.fcs.surveyErrorHandler(error);
      });
  }

  /**
   * set data to storage
   */
  private setDataToStorage(data: SurveyDetailResponse): void {
    localStorage.setItem('edms', data.lc.edms.toString());
    localStorage.setItem('surveyDetail', data.lc.code);
    if (data.lc?.vsblty) {
      localStorage.setItem('surveyTitle', data.lc?.ttl);
    } else if (localStorage.getItem('surveyTitle')) {
      localStorage.removeItem('surveyTitle');
    }
    // eslint-disable-next-line no-unsafe-optional-chaining
    localStorage.setItem('surveyNumberId', (data?.lc?.mi).toString());
  }

  /**
   * remove subscription and event listener
   */
  ngOnDestroy(): void {
    this.notifier$.next();
    this.notifier$.complete();
  }

  /**
   * Navigates to docquity resource
   */
  navigateToResource(): void {
    localStorage.removeItem('previousRoute');
    window.location.href = environment.baseUrls.externalSurveyResourceUrl;
  }

  /**
   * external survey share
   * @param shareContent
   * @param shareProduct
   * @returns
   */
  externalSurveyShare(shareContent: ShareContent, shareProduct): void {
    if (this.mvc.isExternalSurvey || this.mvc.authenticatedExternalSurvey) {
      this.shareExternalSurvey(shareContent);
      const displayObj = {
        isShow: true,
        display_section: 'share',
        data: shareProduct,
        share_content: {
          email_content: shareContent.ect,
          email_title: shareContent.etl,
          other_content: shareContent.oct,
          share_link: shareContent.dpl,
        }
      }
      this.masterService.showWebModal.next(displayObj);
      return;
    }
  }
  /**
   * set access token
   */
  setToken(): void {
    const mcTokenDetail = localStorage.getItem('mcTokenDetail')
    this.isMcTokenAvailable = !!(mcTokenDetail && mcTokenDetail !== null);
    console.log('isMCToken available', this.isMcTokenAvailable);
  }





  /**
   * rewards total value
   */
  // eslint-disable-next-line @typescript-eslint/ban-types
  getPatientList(pageNumber: number , pageSize: any, query: string, patientCode:string): Observable<HttpResponse<Object>> {
    const params = {
      pageNumber,
      pageSize,
      query,
      patientCode
    };
    const endPoint = API.SURVEY_PATIENT_LIST;
    return this.httpClient.get(endPoint,
      {
        observe: 'response',
        params: params,
      });
  }

  /**
   * PIF cme status
   */
  // eslint-disable-next-line @typescript-eslint/ban-types
  getPIFCmeStatus(lesson_id: string): Observable<HttpResponse<Object>> {
    const params = {
      lesson_id
    };
    const endPoint = API.PIF_CME_STATUS;
    return this.httpClient.get(endPoint,
      {
        observe: 'response',
        params: params,
      });
  }


  /**
   * Patient follow up
   */
  // eslint-disable-next-line @typescript-eslint/ban-types
  getPatientListFollowUp( patCode:string,ppfcode): Observable<HttpResponse<Object>> {
    const endPoint = `${API.SURVEY_PATIENT_LIST}/pff/${patCode}/${ppfcode}`;
    return this.httpClient.get(endPoint,
      {
        observe: 'response'
      });
  }

}
