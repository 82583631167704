  <div *ngIf="isInfoModalShown" id="SubscribeBoxModal" class="modal fade success-popup" tabindex="-1" role="dialog" [config]="{ show: true, backdrop: 'relative' }" (onHidden)="onHidden()" bsModal #autoShownModal="bs-modal">
     <div class="custom-theme-modal modal-dialog modal-md modal-dialog-centered">
      <div class="modal-content">
            <div class="modal-body">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="hideModal()"><img src="assets/themes/icons/cancel.svg" alt="cross-icon"></button>

                <div class="msg-symbol">
                  <img width="90" src="assets/images/error.svg">
                </div>
                <div class="messege_text">
                <h5>{{mls.Message}}</h5>
                <p innerHTML="{{info_msg}}"> </p>
                </div>
            </div>
          </div>
    </div>
    </div>