<div class="px-4 pb-5 pt-3 app-otph-100 left-bg-updated d-none d-md-block card-border">
  <p class="mb-3 secondary-paragraph">
    <img src="assets/themes/icons/gr/clinical-case.svg" fetchpriority="medium" class="mr-2" height="24px" width="24px"/>
    <span class="color-grey-500">{{mls.languageText.left_section.key1}}
    <span *ngIf="mls.is_lskey_display==1">(left_section.key1)</span></span>
  </p>
  <p class="mb-3 secondary-paragraph">
    <img  src="assets/themes/icons/gr/cme.svg" fetchpriority="medium" class="mr-2" height="24px" width="24px"/>
    <span class="color-grey-500">{{mls.languageText.left_section.key2}}
    <span *ngIf="mls.is_lskey_display==1">(left_section.key2)</span></span>
  </p>
  <p class="mb-3 secondary-paragraph">
    <img src="assets/themes/icons/gr/reference.svg" fetchpriority="medium" class="mr-2" height="20px" width="24px"/>
    <span class="color-grey-500">{{mls.languageText.left_section.key3}}
    <span *ngIf="mls.is_lskey_display==1">(left_section.key3)</span></span>
  </p>
  <p class="secondary-paragraph">
    <img src="assets/themes/icons/gr/webinar.svg" fetchpriority="medium" class="mr-2" height="24px" width="24px"/>
    <span class="color-grey-500">{{mls.languageText.left_section.key4}}
    <span *ngIf="mls.is_lskey_display==1">(left_section.key4)</span></span>
  </p>
</div>
