export class Ticker {
    /**
     * This will contain title of ticker.
     */
    private _title: string;

    /**
     * This will contain start time of ticker.
     */
    private _startDateTime: number;

    /**
     * This will contain end time of ticker.
     */
    private _endDateTime: number;

    /**
     * This will contain if we want to show ticker all time or not.
     */
    private _beginingToEnd: number;

    public get title(): string {
        return this._title;
    }
    public set title(v: string) {
        this._title = v;
    }

    public get startDateTime(): number {
        return this._startDateTime;
    }
    public set startDateTime(v: number) {
        this._startDateTime = v;
    }

    public get endDateTime(): number {
        return this._endDateTime;
    }
    public set endDateTime(v: number) {
        this._endDateTime = v;
    }


    public get beginingToEnd(): number {
        return this._beginingToEnd;
    }
    public set beginingToEnd(v: number) {
        this._beginingToEnd = v;
    }

    /**
     * This is user to initialize the class variables while creating the class object.
     */

    constructor(title, startDateTime, endDateTime, beginingToEnd) {
        this.title = title;
        this.startDateTime = startDateTime;
        this.endDateTime = endDateTime;
        this.beginingToEnd = beginingToEnd;
    }

}
