import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimedifferencePipe } from './timedifference.pipe';
import { ImagefilterPipe } from './imagefilter.pipe';
import { SafehtmlPipe } from './safehtml.pipe';
import { FitInImgPipe } from './brokenimage.pipe';
import { AddTargetBlankPipe } from './add-target-blank.pipe';
import { DefaultMediaPipe } from './default-media.pipe';
import { SafeurlPipe } from './safeurl.pipe';
import { LinkifyPipe } from './linkify.pipe';
import { RoundValuePipe } from './round-value.pipe';
import { SearchFilterPipe } from './search-filter.pipe';

import { FilterPipe } from './filter.pipe';
import { GetDateTimeOnTimezonePipe } from './get-date-time-on-timezone.pipe';
import { IsTimeExpiredPipe } from './is-time-expired.pipe';
import { DotedAfterSlicePipe } from './doted-after-slice.pipe';
import { FilterUniquePipe } from './filter-unique.pipe';
import { FilterConnectionPipe } from './filter-connection.pipe';
import { ImageSizePipe } from './image-size.pipe';
import { CustomePipeDateTimePipe } from './custome-pipe-date-time.pipe';
import { QBReplaceImageTokenPipe } from './qb-replace-image-token.pipe';
import { ChatProfileNamePipe } from './chat-profile-name.pipe';
import { DateTimezonePipe } from './date-timezone.pipe';
import { NewLineBreaksPipe } from './new-line-breaks.pipe';
import { LikeOrLikesTextPipe } from './like-or-likes-text.pipe';
import { TextTruncatePipe } from './text-truncate.pipe';
import { GradientPipe } from './gradient.pipe';
import { CalculateAgeingPipe } from './ageing.pipe';
import { HighlightPipe } from './highlight';
import { NewTagsPipe } from './new-tags.pipe';
import { NewTagImagePipe } from './new-tag-image.pipe';
import { KeysPipe } from './keys.pipe';
import { TagUserPipe } from './tag-user.pipe';
import { FormatDatePipe } from './format-date.pipe';
import { CustomLinkPipe } from './custom-link.pipe';
import { FileSizePipe } from './fileSize.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    TimedifferencePipe,
    ImagefilterPipe,
    SafehtmlPipe,
    FitInImgPipe,
    AddTargetBlankPipe,
    DefaultMediaPipe,
    SafeurlPipe,
    LinkifyPipe,
    RoundValuePipe,
    SearchFilterPipe,
    FilterPipe,
    GetDateTimeOnTimezonePipe,
    IsTimeExpiredPipe,
    DotedAfterSlicePipe,
    FilterUniquePipe,
    FilterConnectionPipe,
    ImageSizePipe,
    CustomePipeDateTimePipe,
    QBReplaceImageTokenPipe,
    ChatProfileNamePipe,
    DateTimezonePipe,
    NewLineBreaksPipe,
    LikeOrLikesTextPipe,
    TextTruncatePipe,
    GradientPipe,
    CalculateAgeingPipe,
    HighlightPipe,
    NewTagsPipe,
    NewTagImagePipe,
    KeysPipe,
    TagUserPipe,
    FormatDatePipe,
    CustomLinkPipe,
    FileSizePipe
  ],

  exports: [
    TimedifferencePipe,
    ImagefilterPipe,
    SafehtmlPipe,
    FitInImgPipe,
    AddTargetBlankPipe,
    SafeurlPipe,
    LinkifyPipe,
    RoundValuePipe,
    SearchFilterPipe,
    FilterPipe,
    GetDateTimeOnTimezonePipe,
    IsTimeExpiredPipe,
    DotedAfterSlicePipe,
    FilterUniquePipe,
    FilterConnectionPipe,
    CustomePipeDateTimePipe,
    QBReplaceImageTokenPipe,
    ChatProfileNamePipe,
    DateTimezonePipe,
    NewLineBreaksPipe,
    LikeOrLikesTextPipe,
    TextTruncatePipe,
    GradientPipe,
    CalculateAgeingPipe,
    HighlightPipe,
    NewTagsPipe,
    NewTagImagePipe,
    KeysPipe,
    TagUserPipe,
    FormatDatePipe,
    CustomLinkPipe,
    FileSizePipe
  ]
})
export class PipesModule { }
